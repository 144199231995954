import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	padding: 0 30px;
	/* background-color: #f3f6fb; */
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	.go_back {
		padding: 0 10px;
		cursor: pointer;
		color: #1a2cce;
		span {
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}
	}
`;
