import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Layout from '../../../components/layouts';
import { Spacer } from '../../../components/spacer/styles';
import { WalletTableHead } from '../../../utils/constants';
import { StatisticStyle, Styles } from './styles';
import CustomTable from './table';
// import { getAllBalances } from '../../../services/Balance.service';
import { Loader } from '../../../components/loader';
// import { useAuthState } from '../../../context';
import { Card, Col, Row, Statistic } from 'antd';
// import Filters from '../../../components/filters/Filters';
import { Container } from '../../../components/box';
import Search from '../../../components/search';
import {
	getAllWallet,
	getTotalTransactions,
	getTotalWalletBalance
} from '../../../services/Wallet.service';

const Wallets = () => {
	// const state = useAuthState();
	const [input, setInput] = useState('');
	const [filter, setFilter] = useState({ page: 1 });
	const [balance, setBalance] = useState();
	const [currencySign, setCurrencySign] = useState();

	const totalBalance = useQuery(
		'getAllWalletBalance',
		async () => await getTotalWalletBalance(),
		{
			onSuccess: (res) => {
				setBalance(res?.data?.data?.result);
				setCurrencySign(res?.data?.data?.currency?.code);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: 'always'
		}
	);
	const allWallets = useQuery(
		'getAllWallets',
		async () => await getAllWallet(filter),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false
		}
	);
	const allWalletTransactionCount = useQuery(
		'getAllWalletsTransactionCount',
		async () => await getTotalTransactions(),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false
		}
	);

	// if (totalBalance.status === 'error') {
	// 	return <span>Error: {totalBalance.error.message}</span>;
	// }

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, ref: value });
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => allWallets.refetch());
	};

	return (
		<Layout title={'Wallets'}>
			{totalBalance?.isFetching ? (
				<Loader />
			) : (
				<Styles>
					<Spacer height='32px' />
					<StatisticStyle>
						<Row
						// gutter={{
						// 	xs: 8,
						// 	sm: 16,
						// 	md: 24,
						// 	lg: 32
						// }}
						//  justify='space-between'
						>
							<Col>
								<Card>
									<Statistic
										title='Total Wallets Created'
										value={allWallets?.data?.data?.data?.meta?.itemCount}
									/>
								</Card>
							</Col>
							<Col>
								<Card>
									<Statistic
										title='Total Number of Transactions'
										value={allWalletTransactionCount?.data?.data?.data?.result}
									/>
								</Card>
							</Col>
						</Row>
					</StatisticStyle>
					<Spacer height='44px' />
					<Container className='input_container' width='20%'>
						<Search
							placeholder={'Search reference no.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>

					<Spacer height='32px' />

					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={WalletTableHead}
						tableBody={allWallets?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#000A62'
						tbodyColor='#141414'
						pageSize={10}
						paginator
						meta={allWallets?.data?.data?.data?.meta}
						handlePageChange={handlePageChange}
					/>
				</Styles>
			)}
		</Layout>
	);
};

export default Wallets;
