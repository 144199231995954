import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CustomTable from '../table';

import { Form, Input } from 'antd';
import { Container } from '../../../../components/box';
import Button from '../../../../components/button';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import Search from '../../../../components/search';
import { getWalletsBusinessCompliance } from '../../../../services/Compliance.service';
import { approveRejectWalletCompliance } from '../../../../services/Wallet.service';
import { ToastMessage, type } from '../../../../utils/toastr';
import { Container1, Styles } from './styles';

const { TextArea } = Input;

const Index = () => {
	const queryClient = useQueryClient();

	const [statusValue, setStatusValue] = useState('All');
	const [countryValue, setCountryValue] = useState('All');
	const [input, setInput] = useState('');
	const [clicked, setClicked] = useState('false');
	const [walletRef, setWalletRef] = useState('');
	const [showRejectInput, setShowRejectInput] = useState(false);
	const [approve, setApprove] = useState(false);

	const [filter, setFilter] = useState({ page: 1 });
	const pendingBusinessCompliance = useQuery(
		'getBusinessCompliancePending',
		async () => await getWalletsBusinessCompliance(filter),
		{
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const rejectComplianceMutation = useMutation(
		async (data) => {
			await approveRejectWalletCompliance(walletRef, data);
		},
		{
			onSuccess: () => {
				// queryClient.invalidateQueries('getWalletKYC');
				// queryClient.invalidateQueries('getPersonalWalletsCompliancePending');
				queryClient.invalidateQueries('getBusinessCompliancePending');

				ToastMessage(type.SUCCESS, 'compliance rejected');
				// navigate(-1);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const approveComplianceMutation = useMutation(
		async (data) => {
			await approveRejectWalletCompliance(walletRef, data);
		},
		{
			onSuccess: () => {
				// queryClient.invalidateQueries('getWalletKYC');
				// queryClient.invalidateQueries('getPersonalWalletsCompliancePending');
				queryClient.invalidateQueries('getBusinessCompliancePending');

				ToastMessage(type.SUCCESS, 'compliance approved!');
				// navigate(-1);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const handleSelectStatus = (e) => {
		setStatusValue(e);
		setFilter({ ...filter, status: e });
	};

	const handleSelectCountry = (e) => {
		setCountryValue(e);
		setFilter({ ...filter, country: e });
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, search: value });
	};

	const handleSubmit = () => {
		if (filter.status === 'All') {
			delete filter.status;
		}
		if (filter.country === 'All') {
			delete filter.country;
		}
		setClicked('true');
		pendingBusinessCompliance.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => pendingBusinessCompliance.refetch());
	};
	const showModal = (ref) => {
		setWalletRef(ref);
		setShowRejectInput(true);
	};
	const showAccept = (ref) => {
		setWalletRef(ref);
		setApprove(true);
	};
	const handleCancel = () => {
		setShowRejectInput(false);
		setApprove(false);
	};

	const handleApproveComplianceWallet = () => {
		const payload = {
			status: 'verified',
			level: 'PERSONAL',
			type: ['CRYPTO', 'FIAT']
		};

		approveComplianceMutation?.mutate(payload);
		setTimeout(() => {
			setApprove(false);
		}, 500);
	};
	const handleRejectComplianceWallet = (values) => {
		const payload = {
			status: 'rejected',
			level: 'BUSINESS',
			type: ['CRYPTO', 'FIAT'],
			reject_reason: values.reason
		};
		rejectComplianceMutation?.mutate(payload);
		setTimeout(() => {
			setShowRejectInput(false);
		}, 500);
	};

	if (pendingBusinessCompliance.isLoading) {
		return <Loader />;
	}

	if (pendingBusinessCompliance.status === 'error') {
		return <span>Error: {pendingBusinessCompliance.error.message}</span>;
	}

	return (
		<Styles>
			<Container1>
				<Container className='input_container' width={'20%'}>
					<Search
						placeholder={'Search by name.'}
						input={input}
						setInput={setInput}
						handleSearch={handleSearch}
					/>
				</Container>
				<Filters
					source={'wallet-compliance'}
					// handleSelectCountry={handleSelectCountry}
					onChangeStatus={handleSelectStatus}
					refetch={handleSubmit}
					statusValue={statusValue}
					// countryValue={countryValue}
					loading={clicked === 'true' && pendingBusinessCompliance.isRefetching}
				/>
			</Container1>
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={[
					'business name',
					'email address',
					'full name',
					'phone no',
					'status',
					''
				]}
				tableBody={pendingBusinessCompliance?.data?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#8e8e8e'
				tbodyColor='#141414'
				pageSize={10}
				paginator
				source='business'
				handlePageChange={handlePageChange}
				meta={pendingBusinessCompliance?.data?.data?.data?.meta}
				handleReject={showModal}
				handleApprove={showAccept}
				// handleApprove={handleApproveComplianceWallet}
				setRef={setWalletRef}
				walletRef={walletRef}
			/>
			<ModalFrame
				visible={showRejectInput}
				// handleOk={handleOk}

				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						// type='button'
						type='submit'
						key='submit'
						form='rejectWalletComp'
						// onClick={handleRejectComplianceWallet}
						loading={rejectComplianceMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'left' }}>
					Kindly provide a reason for rejection.
				</h3>

				<Form
					layout='vertical'
					onFinish={handleRejectComplianceWallet}
					id='rejectWalletComp'
				>
					<Form.Item
						label='Reason'
						name='reason'
						rules={[{ required: true, message: 'Reason  is required' }]}
					>
						<TextArea
							showCount
							style={{
								height: 120
							}}
							placeholder='Rejection reason'
						/>
					</Form.Item>
				</Form>
			</ModalFrame>
			<ModalFrame
				visible={approve}
				// handleOk={handleApproveComplianceWallet}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleApproveComplianceWallet}
						loading={approveComplianceMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Confirm</h3>
				<p>Are you sure you want to approve</p>
			</ModalFrame>
		</Styles>
	);
};

export default Index;
