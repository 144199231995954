import React from "react";
import { CryptoIcon, RightArrowIcon, WalletIcon } from "../../../assets";
import { Flex } from "../../../components/box";
import { StylesFundAccount } from "./styles";
import { StylesAccount } from "./accountDetail/styles";
import Button from "../../../components/button";
import { useCopy } from "../../../customHooks/useCopyToClipBoard";

const Fund = ({ handleBank, handleCrypto }) => {
  return (
    <StylesFundAccount
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <h3>Fund your balance</h3>
      <p>Choose a funding method to complete your deposit</p>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        className="main-box"
        marginTop="30px"
        onClick={handleBank}
      >
        <div
          style={{
            background: "#D3E9FF",
            borderRadius: "100%",
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WalletIcon />
        </div>
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginLeft="10px"
          className="text"
        >
          <h4>Bank transfer</h4>
          <p>Fund your account using a virtual account</p>
        </Flex>
        <RightArrowIcon />
      </Flex>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        className="main-box"
        onClick={handleCrypto}
        marginTop="20px"
      >
        <div
          style={{
            background: "#D3E9FF",
            borderRadius: "100%",
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CryptoIcon />
        </div>
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginLeft="10px"
          className="text"
        >
          <h4>Wallet Address</h4>
          <p>Generate a QR code for USDC deposit </p>
        </Flex>
        <RightArrowIcon />
      </Flex>
    </StylesFundAccount>
  );
};

const BankDetails = ({ accountDetails, handleFund, handleCancel }) => {
  const { copySuccess, copyToClipboard } = useCopy();
  const { copySuccess: copySuccess1, copyToClipboard: copyToClipboard1 } =
    useCopy();
  const { copySuccess: copySuccess2, copyToClipboard: copyToClipboard2 } =
    useCopy();

  return (
    <>
      <h2
        style={{
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "21px",
        }}
      >
        Deposit
      </h2>
      <p
        style={{
          textAlign: "left",
          paddingBottom: "30px",
        }}
      >
        Make your deposit to the account below
      </p>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        paddingBottom="30px"
      >
        <div
          style={{
            background: "#D3E9FF",
            borderRadius: "100%",
            width: 40,
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CryptoIcon />
        </div>
        <h5
          style={{
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "14px",
            letterSpacing: "0.145em",
            color: "#000A62",
            marginLeft: "20px",
          }}
        >
          BANK ACCOUNT
        </h5>
      </Flex>
      <StylesAccount>
        <div>
          <h3>Account Number</h3>
          <p>
            {accountDetails?.beneficiaryBank.accountNumber}
            <span
              onClick={() =>
                copyToClipboard1(accountDetails?.beneficiaryBank.accountNumber)
              }
            >
              {copySuccess1}
            </span>
          </p>
        </div>
        <div>
          <h3>Bank Name</h3>
          <p>
            {accountDetails?.beneficiaryBank.name}
            <span
              onClick={() =>
                copyToClipboard2(accountDetails?.beneficiaryBank.name)
              }
            >
              {copySuccess2}
            </span>
          </p>
        </div>
        <div>
          <h3>Swift Code</h3>
          <p>
            {accountDetails?.beneficiaryBank.swiftCode}
            <span
              onClick={() =>
                copyToClipboard(accountDetails?.beneficiary.swiftCode)
              }
            >
              {copySuccess}
            </span>
          </p>
        </div>
      </StylesAccount>
      <Button
        Styles
        text="I have done this"
        bgColor={"#1A2CCE"}
        color={"#FFFFFF"}
        size="md"
        fullwidth
        type="submit"
        key="submit"
        form="editMerchant"
        style={{
          marginTop: "12px",
        }}
        onClick={handleCancel}
      />
      <Button
        Styles
        text="Change payment method"
        color="#676767"
        size="md"
        fullwidth
        type="submit"
        key="submit"
        form="editMerchant"
        style={{
          padding: "12px 0 0 0",
          marginTop: "12px",
        }}
        onClick={handleFund}
      />
    </>
  );
};

const CryptoDetails = ({ addressDetails, handleFund, handleCancel }) => {
  return (
    <>
      <StylesAccount>
        <Flex flexDir="row" justifyContent="space-between" className="crypto">
          <div>
            <svg
              width="149"
              height="148"
              viewBox="0 0 149 148"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="149" height="148" rx="4" fill="white" />
              <rect
                x="16"
                y="16"
                width="113"
                height="114"
                fill="url(#pattern0)"
              />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_1369_4394"
                    transform="translate(-0.0707965 -0.0789474) scale(0.0490413 0.0486111)"
                  />
                </pattern>
                <image
                  id="image0_1369_4394"
                  width="24"
                  height="24"
                  xlinkHref={addressDetails.qr}
                />
              </defs>
            </svg>
          </div>
          <div style={{ width: "50%" }}>
            <div>
              <h3>Network</h3>
              <p>{addressDetails?.chain}</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <h3>Deposit Address</h3>
              <p>{addressDetails?.address}</p>
            </div>
          </div>
        </Flex>
      </StylesAccount>
      <Button
        Styles
        text="I have done this"
        bgColor={"#1A2CCE"}
        color={"#FFFFFF"}
        size="md"
        fullwidth
        type="submit"
        key="submit"
        form="editMerchant"
        // loading={createMutation.isLoading}
        style={{
          marginTop: "12px",
        }}
        // borderRadius='5px'
        onClick={handleCancel}
      />
      <Button
        Styles
        text="Change payment method"
        color="#676767"
        size="md"
        fullwidth
        type="submit"
        key="submit"
        form="editMerchant"
        // loading={createMutation.isLoading}
        style={{
          padding: "12px 0 0 0",
          marginTop: "12px",
        }}
        // borderRadius='5px'
        onClick={handleFund}
      />
    </>
  );
};

export { Fund, BankDetails, CryptoDetails };
