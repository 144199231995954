import styled from 'styled-components';
import { Flex } from '../box';

// margin-bottom: 30px;
export const AccordionStyles = styled.div`
	width: 100%;
`;

export const AccordionButton = styled(Flex)`
	background: #f9f9f9;
	border-radius: 1px;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	padding: ${({ pad }) => pad || '15px 30px'};
	p {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700 !important;
		margin-bottom: 0px;
	}
`;
export const AccordionContent = styled(Flex)`
	flex-direction: column;
	padding: ${({ pad }) => pad || '30px 30px 0 30px'};
	background: #f9f9f9;
	border-radius: 1px;
	justify-content: start;
	align-items: column;
`;
