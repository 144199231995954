import PropTypes from "prop-types";
import React from "react";

import { generateID } from "../../../../../../utils/generateID";
import Pagination from "../../../../../../components/paginator";
import { EmptyState } from "../../../../../../components/emptyState";
import { OverFlowScrollBar } from "../../../../../../components/overflowScroll/styles";

import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { DeleteIcon, EditIcon } from "../../../../../../assets";
import { Flex } from "../../../../../../components/box";
import Button from "../../../../../../components/button";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  handlePageChange,
  meta,
  showDelete,
  handleEdit,
}) => {
  const returnTableRow = (data, i) => {
    return (
      <tr key={generateID(10)}>
        <Td>{data.currency}</Td>
        <Td>{data?.method}</Td>
        <Td>{data?.fee_type}</Td>
        <Td> {`${data?.fee}${data?.fee_type === "FLAT" ? "" : "%"}`}</Td>
        <CustomTd>
          <Flex dir="row">
            <EditIcon color="#8D8EA2" />
            <Button
              Styles
              text={"Edit"}
              color={"#878787"}
              size="sm"
              type="button"
              onClick={() => handleEdit(data?.id)}
            />
          </Flex>
        </CustomTd>
        <CustomTd>
          <Flex dir="row">
            <DeleteIcon color="#8D8EA2" />
            <Button
              Styles
              text={"Delete"}
              color={"#878787"}
              size="sm"
              type="button"
              onClick={() => showDelete(data?.id)}
            />
          </Flex>
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead.map((head, i) => (
                    <th key={generateID(11)}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination handlePageChange={handlePageChange} meta={meta} />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
};

export default CustomTable;
