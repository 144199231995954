import styled from "styled-components";
import { Flex } from "../../../../../../components/box";

export const Styles = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  margin-top: 2em;
  background-color: #f8f9fd;
  padding: 1.3em 3em;
`;
export const Container = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #000a62;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4d4d4d;
  }
`;

export const Box = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  > label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #323232;
    text-transform: capitalize;
  }
  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 10px 0;
  }
  > a {
    color: #1a2cce;
  }
`;
