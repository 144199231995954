import React from "react";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";

import { Styles, Card } from "./styles";
import { Flex, Grid } from "../../../../components/box";

const Index = ({
  amountSettledSummary,
  amountUsedSummary,
  requestSummary,
  activeSummary,
  loading,
}) => {
  const settlement = amountUsedSummary?.result - amountSettledSummary?.result;

  return (
    <Styles>
      <Grid
        justifyContent={"space-around"}
        gap="10px"
        gridCol="repeat(auto-fit, minmax(200px, 1fr))"
        bgColor="#fff"
        pad="30px 20px"
        border="1px solid rgba(223, 223, 223, 0.39)"
        borderRadius="6px"
      >
        <Card>
          <Flex
            className="container"
            alignItem={"baseline"}
            justifyContent={"space-between"}
            flexDir="row"
          >
            <span>Active Liquidity</span>
          </Flex>

          {loading ? (
            <Skeleton paragraph={{ rows: 1 }} />
          ) : (
            <>
              <Flex
                className="viewall"
                alignItem={"flex-start"}
                flexDir={"column"}
              >
                <span>{activeSummary?.result}</span>
              </Flex>
              <Flex alignItem={"flex-start"} flexDir={"column"}>
                <Link to={`/dashboard/liquidity/`}>view all</Link>
              </Flex>
            </>
          )}
        </Card>

        <Card>
          <Flex
            className="container"
            alignItem={"baseline"}
            justifyContent={"space-between"}
            flexDir="row"
          >
            <span>Requested Liquidity</span>
          </Flex>
          {loading ? (
            <Skeleton paragraph={{ rows: 1 }} />
          ) : (
            <>
              <Flex
                className="viewall"
                alignItem={"flex-start"}
                flexDir={"column"}
              >
                <span>{requestSummary?.result}</span>
              </Flex>
              <Flex alignItem={"flex-start"} flexDir={"column"}>
                <Link to={`/dashboard/liquidity/request`}>view all</Link>
              </Flex>
            </>
          )}
        </Card>

        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>AMOUNT DISBURSED</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span> {amountUsedSummary?.result.toLocaleString()}</span>
            )}
          </Flex>
        </Card>
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>PENDING SETTLEMENTS </span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>{settlement.toLocaleString()}</span>
            )}
          </Flex>
        </Card>
      </Grid>
    </Styles>
  );
};

export default Index;
