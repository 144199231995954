import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import Pagination from '../../../../components/paginator';
import { generateID } from '../../../../utils/generateID';
import { CustomTableMain, Td, Paginator, CustomTd } from './styles';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import { EmptyState } from '../../../../components/emptyState';
import Countdown from 'react-countdown';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handlePageChange,
	meta,
	currencies
}) => {
	let currentPath = useLocation().pathname;
	const path = '/dashboard/balances/pending';

	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.currency}>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						{currencies?.map(
							(item) =>
								item?.code === data?.currency && (
									<div
										className='frame'
										style={{
											borderRadius: '16.9228px',
											background: '#F3F3F3',
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											height: '30px',
											width: '30px'
										}}
									>
										<img src={item?.icon} alt='flag' />
									</div>
								)
						)}
						{currencies?.map(
							(item) =>
								item?.code === data?.currency && (
									<strong style={{ color: '#1A1F36', margin: '0 10px 0 16px' }}>
										{item?.name}
									</strong>
								)
						)}
						<span style={{ color: '#8D8D8D' }}>({data?.currency})</span>
					</div>
				</Td>

				<Td>
					<div>
						<span style={{ color: '#767676' }}>{data?.currency}</span>
						<strong>
							{data?.balance?.toLocaleString()}
							{data?.sum?.toLocaleString()}
						</strong>
					</div>
				</Td>
				{currentPath === path && (
					<Td>
						<span style={{ color: 'red' }}>
							<Countdown
								date={
									data.time === null ? '1918-09-08T09:55:54.000Z' : data?.time
								}
							/>
						</span>
					</Td>
				)}
				{currentPath !== path && (
					<CustomTd>
						<Link
							to={`/dashboard/transactions/deposits`}
							state={{
								selectedCurrency: data?.currency
							}}
						>
							<div>
								<p>View transactions</p>
							</div>
						</Link>
					</CustomTd>
				)}
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination meta={meta} handlePageChange={handlePageChange} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;

// <Countdown
// 	date={
// 		data.settlement_date === null
// 			? '1918-09-08T09:55:54.000Z'
// 			: data?.settlement_date
// 	}
// />
