import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

import { generateID } from '../../../../utils/generateID';
import Pagination from '../../../../components/paginator';
import PopOver from '../../../../components/popover/PopOver';
import { EmptyState } from '../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';

import {
	CustomTableMain,
	Td,
	Paginator,
	CustomTd,
	BottomPanel
} from './styles';
import {
	BadgeCheck,
	DeleteIcon,
	EyeIcon,
	PreFundIcon,
	UnsuspendUser,
	UserAvatar,
	ViewTransactionIcon,
	ResetUser,
	ResendEmailIcon
} from '../../../../assets';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	showSuspendModal,
	showClearLimitModal,
	showResetLoginModal,
	handleResendEVM,
	handlePageChange,
	meta
}) => {
	const navigate = useNavigate();

	const returnTableRow = (data, i) => {
		const checkVerifed = data.kyc;
		return (
			<tr
				key={data.id}
				// onClick={() => navigate(`/dashboard/merchants/${data?.id}`)}
			>
				<Td>
					<div>
						<span>{data?.business_name}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.business_email}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.country}</span>
					</div>
				</Td>
				<CustomTd status={checkVerifed}>
					<div>
						<span>{checkVerifed}</span>
					</div>
				</CustomTd>

				<Td>
					<div>
						<span
							style={
								data?.status === 'active'
									? { color: '#027200' }
									: { color: '#CE1A1A' }
							}
						>
							{data?.status}
						</span>
					</div>
				</Td>
				<CustomTd>
					<PopOver key={data?.id} placement='leftTop' i={data?.id}>
						<Link to={`/dashboard/merchants/${data?.id}`}>
							<div>
								<UserAvatar />
								<p>Merchant Info</p>
							</div>
						</Link>

						<Link to={`#`} onClick={() => showClearLimitModal(data?.id)}>
							<div>
								<PreFundIcon />
								<p>Clear Limit</p>
							</div>
						</Link>
						<Link to={`#`} onClick={() => showResetLoginModal(data?.id)}>
							<div>
								<ResetUser />
								<p>Reset Login</p>
							</div>
						</Link>
						<Link to={`#`} onClick={() => handleResendEVM(data?.id)}>
							<div>
								<ResendEmailIcon />
								<p>Verify Email</p>
							</div>
						</Link>
						<BottomPanel>
							<Link
								to={`#`}
								onClick={() => showSuspendModal(data?.id, data?.status)}
							>
								<div>
									{data?.status === 'suspended' ? (
										<UnsuspendUser color={''} />
									) : (
										<DeleteIcon color={'#C10606'} />
									)}
									<p
										style={
											data?.status === 'suspended'
												? { color: '#027200' }
												: { color: '#C10606' }
										}
									>
										{data.status === 'active'
											? 'Suspend User'
											: 'Unsuspend User'}
									</p>
								</div>
							</Link>
						</BottomPanel>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead.map((head, i) => (
										<th key={generateID(11)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number
};

export default CustomTable;
