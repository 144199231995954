import { Switch } from "antd";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Flex } from "../../../components/box";
import { Loader } from "../../../components/loader";
import { useCopy } from "../../../customHooks/useCopyToClipBoard";
import {
  FxOpsAccountStatus,
  getSingleFxOpsAccount,
} from "../../../services/fxOps.services";
import { ToastMessage, type as toasterType } from "../../../utils/toastr";
import { StylesAccountStatus } from "./accountDetail/styles";
import { StylesCreateNew } from "./styles";

const FxOpsAccountDetails = ({ type, id }) => {
  const queryClient = useQueryClient();
  const { copySuccess, copyToClipboard } = useCopy();
  const { copySuccess: copySuccess1, copyToClipboard: copyToClipboard1 } =
    useCopy();
  const { copySuccess: copySuccess2, copyToClipboard: copyToClipboard2 } =
    useCopy();
  const { copySuccess: copySuccess3, copyToClipboard: copyToClipboard3 } =
    useCopy();
  const { copySuccess: copySuccess4, copyToClipboard: copyToClipboard4 } =
    useCopy();

  const result = useQuery(
    ["getSingleFxOpsAccount", id],
    async () => await getSingleFxOpsAccount(id),
    {
      enabled: !!id,
    }
  );

  const accountStatusMutation = useMutation(
    async (data) => {
      await FxOpsAccountStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAccounts");
        ToastMessage(toasterType.SUCCESS, "Status changed successfully");
      },
      onError: (error) => {
        ToastMessage(toasterType.ERROR, error.response.data.message);
      },
    }
  );

  const handleSwitched = (checked) => {
    const payload = checked === true ? "enable" : "disable";
    accountStatusMutation.mutate({ status: payload });
  };

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result?.error.message}</span>;
  }

  const accountDetails = result?.data?.data?.data;

  return (
    <>
      <h2
        style={{
          textAlign: "left",
          textTransform: "capitalize",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "21px",
          marginBottom: "20px",
        }}
      >
        {!type ? "Create saved account" : "Account Details"}
      </h2>
      <StylesAccountStatus
        justifyContent="space-between"
        pad={"20px"}
        style={{}}
      >
        <p>Account Status</p>
        <Switch
          defaultChecked={accountDetails.status}
          size="large"
          onChange={handleSwitched}
        />
      </StylesAccountStatus>
      <StylesCreateNew>
        <h4>Instructions</h4>
        <h6>{accountDetails?.description}</h6>
      </StylesCreateNew>
      <StylesCreateNew
        style={{
          marginTop: "20px",
        }}
        width="450px"
      >
        <h4 className="bank-wire">Beneficiary</h4>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <div>
            <h5>Address</h5>
            <p>
              {accountDetails?.data?.beneficiary?.address1}
              <span
                onClick={() =>
                  copyToClipboard3(
                    accountDetails?.data?.beneficiaryBank?.address1
                  )
                }
              >
                {copySuccess3}
              </span>
            </p>
          </div>
          <div>
            <h5>Account Name</h5>
            <p>
              {accountDetails?.data?.beneficiary?.name}
              <span
                onClick={() =>
                  copyToClipboard4(
                    accountDetails?.data?.beneficiaryBank?.accountNumber
                  )
                }
              >
                {copySuccess4}
              </span>
            </p>
          </div>
          {/* <div>
            <h5>Swift Code</h5>
            <p>
              {accountDetails?.data?.beneficiaryBank?.swiftCode}
              <span
                onClick={() =>
                  copyToClipboard(
                    accountDetails?.data?.beneficiaryBank?.swiftCode
                  )
                }
              >
                {copySuccess}
              </span>
            </p>
          </div> */}
        </Flex>
      </StylesCreateNew>
      <StylesCreateNew
        style={{
          marginTop: "20px",
        }}
      >
        <h4 className="bank-wire">Saved account details</h4>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <div>
            <h5>Account Number</h5>
            <p>
              {accountDetails?.data?.beneficiaryBank?.accountNumber}
              <span
                onClick={() =>
                  copyToClipboard1(
                    accountDetails?.data?.beneficiaryBank?.accountNumber
                  )
                }
              >
                {copySuccess1}
              </span>
            </p>
          </div>
          <div>
            <h5>Bank Name</h5>
            <p>
              {accountDetails?.data?.beneficiaryBank?.name}
              <span
                onClick={() =>
                  copyToClipboard2(accountDetails?.data?.beneficiaryBank?.name)
                }
              >
                {copySuccess2}
              </span>
            </p>
          </div>
          <div>
            <h5>Swift Code</h5>
            <p>
              {accountDetails?.data?.beneficiaryBank?.swiftCode}
              <span
                onClick={() =>
                  copyToClipboard(
                    accountDetails?.data?.beneficiaryBank?.swiftCode
                  )
                }
              >
                {copySuccess}
              </span>
            </p>
          </div>
        </Flex>
      </StylesCreateNew>
    </>
  );
};

export default FxOpsAccountDetails;
