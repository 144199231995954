import React from "react";
import { Styles } from "./styles";
import { useQuery } from "react-query";
import { Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Flex } from "../../../../components/box";
import { getFxCountries } from "../../../../services/Others.service";

const FxOpsForm = ({ handleSubmit, type }) => {
  const result = useQuery("getFxCountries", async () => await getFxCountries());

  const countries = result?.data?.data;

  return (
    <>
      <Styles>
        <h3 style={{ textAlign: "left", textTransform: "capitalize" }}>
          {type === "" && "Create new saved account"}
          {type === "wallet" && "Create new wallet account"}
          {type === "payout" && "Make payout"}
          {type === "fund" && "Choose a currency type"}
        </h3>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          id="editMerchant"
          initialValues={{
            layout: "vertical",
            size: "large",
          }}
        >
          {type === "" && (
            <>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="Label"
                  name="label"
                  rules={[
                    {
                      required: true,
                      message: "Label  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="Currency"
                  name="currency"
                  rules={[{ required: true, message: "Currency is required" }]}
                >
                  <Select placeholder="Select Currency">
                    <Select.Option value={"USD"}>USD</Select.Option>
                    <Select.Option value={"EUR"}>EUR</Select.Option>
                  </Select>
                </Form.Item>
              </Flex>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="Beneficiary Name"
                  name="beneficiaryName"
                  rules={[
                    {
                      required: true,
                      message: "Beneficiary Name  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="Beneficiary Email"
                  name="beneficiaryEmail"
                  rules={[
                    {
                      required: true,
                      message: "Beneficiary Email is required",
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Flex>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: "Bank Name  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: "Account Number  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Flex>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="Routing No (SWIFT/BIC Code)"
                  name="routingNumber"
                  rules={[
                    {
                      required: true,
                      message: "Routing Number  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="IBAN"
                  name="iban"
                  rules={[
                    {
                      required: true,
                      message: "IBAN  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Flex>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="Country Code"
                  name="countryCode"
                  rules={[
                    { required: true, message: "Country Code is required" },
                  ]}
                >
                  <Select placeholder="USA">
                    {countries?.map((item) => (
                      <Select.Option value={item?.iso2}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "city  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Flex>
              <Form.Item
                label="Address"
                name="address"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "address is required",
                  },
                ]}
              >
                <TextArea rows={6} showCount maxLength={100} />
              </Form.Item>
              <Flex flexDir="row" justifyContent="space-between">
                <Form.Item
                  label="District"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "District  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="Postal code"
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: "Postal Code  is required",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Flex>
            </>
          )}
          {type === "wallet" && (
            <>
              <Form.Item
                label="Label"
                name="label"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Label  is required",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                style={{ width: "100%" }}
              >
                <Select placeholder="Select Currency">
                  <Select.Option value={"USD"}>USD</Select.Option>
                  <Select.Option value={"BTC"}>BTC</Select.Option>
                  <Select.Option value={"ETH"}>ETH</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Network"
                name="chain"
                rules={[{ required: true, message: "network is required" }]}
                style={{ width: "100%" }}
              >
                <Select placeholder="eth">
                  <Select.Option value={"eth"}>eth</Select.Option>
                  <Select.Option value={"btc"}>btc</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
          {type === "payout" && (
            <>
              <Form.Item
                label="Amount"
                style={{ width: "100%" }}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount  is required",
                  },
                ]}
              >
                <Input addonBefore="USD" type="number" />
              </Form.Item>
              <Form.Item
                label="Fee Type"
                name="fee_type"
                rules={[{ required: true, message: "Fee Type is required" }]}
                style={{ width: "100%" }}
              >
                <Select placeholder="Select Fee Type">
                  <Select.Option value={"FLAT"}>FLAT</Select.Option>
                  <Select.Option value={"PERCENTAGE"}>PERCENTAGE</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Fee Value"
                style={{ width: "100%" }}
                name="fee"
                rules={[
                  {
                    required: true,
                    message: "Fee Value  is required",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </>
          )}
          {type === "fund" && (
            <>
              <Form.Item
                label="Currency"
                name="currency"
                rules={[{ required: true, message: "Currency is required" }]}
                style={{ width: "100%" }}
              >
                <Select placeholder="Select Currency">
                  <Select.Option value={"USD"}>USD</Select.Option>
                  <Select.Option value={"BTC"}>BTC</Select.Option>
                  <Select.Option value={"ETH"}>ETH</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Styles>
    </>
  );
};

export default FxOpsForm;
