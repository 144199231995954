import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import { getSettingsConfig } from '../../../../../services/Settings.service';
import { ApproveTransactionSummary } from '../../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import { Styles } from './styles';

const Index = () => {
	const [form] = Form.useForm();

	const [visible, setVisible] = React.useState(false);

	const [fData, setFData] = React.useState({});
	const [imgUpload, setImgUpload] = useState({});
	// const checkToken = localStorage.getItem('access_token');

	const config = useQuery(
		'getAppConfig',
		async () => await getSettingsConfig(),
		{
			onSuccess: (res) => {
				form.resetFields();
				form.setFieldsValue({
					name: res?.data?.data?.config?.name,
					title: res?.data?.data?.config?.title,
					website: res?.data?.data?.config?.website,
					merchant_url: res?.data?.data?.config?.merchant_url,
					api_base_url: res?.data?.data?.config?.api_base_url,
					support_email: res?.data?.data?.config?.support_email,
					support_url: res?.data?.data?.config?.support_url,
					admin_email: res?.data?.data?.config?.admin_email,
					support_phone: res?.data?.data?.config?.support_phone
				});
			},
			onError: (error) => <p> {error.response.data.message}</p>,
			// enabled: !!checkToken,
			// refetchOnMount: false,
			refetchOnMount: 'always',
			refetchOnWindowFocus: false
		}
	);

	// useEffect(() => form.resetFields(), [props.initialValues]);
	const handleCancel = () => {
		setVisible(false);
	};

	const handleChange = (e) => {
		if (e.target.files) {
			const file = e.target.files[0];
			setImgUpload((prevState) => ({
				...prevState,
				[e.target.id]: file
			}));
		}
	};
	const handleSubmit = (values) => {
		values.alt_logo =
			values?.alt_logo === undefined
				? config?.data?.data?.data?.config?.alt_logo
				: imgUpload.alt_logo;
		values.logo =
			values?.logo === undefined
				? config?.data?.data?.data?.config?.logo
				: imgUpload.logo;
		values.favicon =
			values?.favicon === undefined
				? config?.data?.data?.data?.config?.favicon
				: imgUpload.favicon;
		values.logo_icon =
			values?.logo_icon === undefined
				? config?.data?.data?.data?.config?.logo_icon
				: imgUpload.logo_icon;
		values.email_logo =
			values?.email_logo === undefined
				? config?.data?.data?.data?.config?.email_logo
				: imgUpload.email_logo;

		setVisible(true);
		setFData(values);
	};

	if (config.isFetching) {
		return <Loader />;
	}

	if (config.isError) {
		return <span>Error: {config.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='overall'
					>
						<div>
							<h3>Application Configuration</h3>
						</div>
					</Flex>
				</Container>
				<Form
					layout={'vertical'}
					form={form}
					size='large'
					onFinish={handleSubmit}
				>
					<Flex justifyContent='space-between' gap='18px'>
						<Form.Item
							name='name'
							label='App Name'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'App name is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='title'
							label='App Title'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'App title is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='website'
							label='App Website'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'App website is required' }]}
						>
							<Input />
						</Form.Item>
					</Flex>
					<Flex justifyContent='space-between' gap='18px'>
						<Form.Item
							name='merchant_url'
							label='App Merchant URL'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Merchant url is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='api_base_url'
							label='App API Base URL'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Api base url is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='support_email'
							label='Support Email'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Support Email is required' }]}
						>
							<Input />
						</Form.Item>
					</Flex>

					<Flex justifyContent='space-between' gap='18px'>
						<Form.Item
							name='support_url'
							label='Support Url'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Support Url is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='support_phone'
							label='Support phone'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Support phone is required' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='admin_email'
							label='Admin email'
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Admin email is required' }]}
						>
							<Input />
						</Form.Item>
					</Flex>
					<Flex justifyContent='space-between' gap='18px'>
						<Form.Item name='logo' label='App Logo' style={{ width: '100%' }}>
							<Input type='file' onChange={handleChange} />
						</Form.Item>
						<Form.Item
							name='alt_logo'
							label='App Alt Logo'
							style={{ width: '100%' }}
						>
							<Input type='file' onChange={handleChange} />
						</Form.Item>
						<Form.Item
							name='logo_icon'
							label='App Logo Icon'
							style={{ width: '100%' }}
						>
							<Input type='file' onChange={handleChange} />
						</Form.Item>
					</Flex>
					<Flex justifyContent='space-between' gap='18px'>
						<Form.Item
							name='favicon'
							label='App Favicon'
							style={{ width: '100%' }}
						>
							<Input type='file' onChange={handleChange} />
						</Form.Item>
						<Form.Item
							name='email_logo'
							label='App Email Icon'
							style={{ width: '100%' }}
						>
							<Input type='file' onChange={handleChange} />
						</Form.Item>
					</Flex>
					<Button
						Styles
						text={'Save changes'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						// fullwidth
						size='md'
						type='submit'
					/>
				</Form>
			</Styles>
			<ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
				<ApproveTransactionSummary
					status={'app_config'}
					setVisible={setVisible}
					appConfigData={fData}
					resetForm={form.resetFields}
				/>
				{/* <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame> */}
			</ModalFrame>
		</>
	);
};

export default Index;
