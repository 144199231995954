import React, { useEffect, useState } from 'react';
import styles from './Pagination.module.css';
import doubleArrow from '../../assets/images/doubleArrow.svg';
import backarrow from '../../assets/images/backarrow.svg';
import { ToastMessage, type as TYPE } from '../../utils/toastr';

const Pagination = ({ meta, type, handlePageChange }) => {
	const [mapper] = useState([1, 2, 3]);
	const [toDisplay, setTodisplay] = useState([]);
	const [goto, setGoto] = useState('');

	useEffect(() => {}, [meta]);

	useEffect(() => {
		let i;
		const tempTodisplay = [];
		if (meta?.page === meta?.pageCount) {
			mapper.map((item, index) => {
				i = meta?.page;
				if (i - index >= 1) {
					tempTodisplay.push(i - index);
				}
				return '';
			});
			setTodisplay(tempTodisplay.sort((a, b) => a - b));
		} else {
			mapper.map((item, index) => {
				i = meta?.page;
				if (i + index <= meta?.pageCount) {
					tempTodisplay.push(i + index);
				}
				return '';
			});
			setTodisplay(tempTodisplay.sort((a, b) => a - b));
		}
	}, [meta, mapper]);

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name === 'page') {
			setGoto(value);
		}
	};

	const handleValidatePageNumber = (e) => {
		e.preventDefault();
		if (goto > meta?.pageCount) {
			ToastMessage(TYPE.ERROR, 'invalid page');
		} else {
			if (goto <= 0) {
				ToastMessage(TYPE.ERROR, 'invalid page');
			} else {
				handlePageChange(goto, type);
			}
		}
	};

	return (
		<div className={styles.pagination}>
			<div className={styles.pagination_left}>
				<div className={styles.showing_numbers}>
					<p>
						Showing {meta?.page * meta?.take - meta?.take + 1} to{' '}
						{meta?.page * meta?.take > meta?.itemCount
							? meta?.itemCount
							: meta?.page * meta?.take}{' '}
						of {meta?.itemCount}
					</p>
				</div>
				<div className={styles.goto}>
					<p>Go to:</p>
					<form
						onSubmit={(e) => {
							handleValidatePageNumber(e);
						}}
						action=''
					>
						<input
							name='page'
							value={goto}
							onChange={handleChange}
							type='text'
						/>
					</form>
				</div>
			</div>
			<div className={styles.pagination_right}>
				{toDisplay.at(0) !== 1 && (
					<button
						onClick={() => {
							handlePageChange(1, type);
						}}
					>
						1
					</button>
				)}
				{toDisplay.at(0) >= 3 ? (
					<button
						onClick={() => {
							handlePageChange(toDisplay[0] - 1, type);
						}}
					>
						<img
							style={{ transform: 'rotate(180deg)' }}
							src={backarrow}
							alt=''
						/>
					</button>
				) : null}

				{toDisplay.map((item) => {
					return (
						<button
							className={item === meta?.page ? styles.active_page : null}
							onClick={() => {
								handlePageChange(item, type);
							}}
							key={item}
						>
							{item}
						</button>
					);
				})}
				{toDisplay.at(-1) + 1 >= meta?.pageCount ? null : (
					<button
						onClick={() => {
							handlePageChange(toDisplay[2] + 1, type);
						}}
					>
						<img src={doubleArrow} alt='' />
					</button>
				)}
				{toDisplay.at(-1) >= Math.ceil(meta?.itemCount / meta?.take) ? null : (
					<button
						onClick={() => {
							handlePageChange(Math.ceil(meta?.itemCount / meta.take), type);
						}}
					>
						{Math.ceil(meta?.itemCount / meta?.take)}
					</button>
				)}
			</div>
		</div>
	);
};

export default Pagination;
