import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { useViewportWidth } from '../../utils/helpers';
import { Flex } from '../box';
import Button from '../button';
import CustomDropdown from '../customdropdown/CustomDropdown';
import { TabWrapper, TabWrapperMerchant } from './styles';
const Index = ({
	links,
	routes,
	handleSwapOverview,
	handleProcessorStatus,
	handleDownload,
	type,
	handleWalletAddress,
	handleSavedAccount,
	children
}) => {
	let currentPath = useLocation().pathname;
	const ref = useRef(null);
	const mainRef = useRef(null);
	const [start, setStart] = useState(0);

	const scrollRight = (scrollOffset) => {
		setStart((ref.current.scrollLeft += scrollOffset));
	};
	const scrollLeft = (scrollOffset) => {
		setStart((ref.current.scrollLeft += scrollOffset));
	};

	const isDesktopOrLaptop = useViewportWidth();
	// console.log(isDesktopOrLaptop);
	return (
		<>
			{type === 'merchant' && (
				<TabWrapperMerchant ref={mainRef}>
					{isDesktopOrLaptop <= 1680 && (
						<>
							{isDesktopOrLaptop && start > 0 && (
								<div
									style={{
										maxWidth: '100%',
										cursor: 'pointer'
									}}
									onClick={() => scrollLeft(-20)}
								>
									<LeftOutlined
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#ffffff',
											fontWeight: 'bold',
											background: '#1A2CCE',
											maxWidth: '100%',
											width: '35px',
											height: '35px',
											borderRadius: '50%'
										}}
									/>
								</div>
							)}
						</>
					)}
					<ol className='tab-list' ref={ref}>
						{links.map(({ path, title }) => (
							<NavLink
								key={path.toString()}
								to={`${path}`}
								className={`${'tab-list-item'} ${
									currentPath === path ? 'tab-list-active' : null
								}`}
							>
								{title}
							</NavLink>
						))}
					</ol>
					{isDesktopOrLaptop <= 1680 && (
						<>
							{start < ref?.current?.scrollWidth && (
								<span
									style={{
										maxWidth: '100%',
										cursor: 'pointer'
									}}
									onClick={() => scrollRight(20)}
								>
									<RightOutlined
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#ffffff',
											fontWeight: 'bold',
											background: '#1A2CCE',
											maxWidth: '100%',
											width: '35px',
											height: '35px',
											borderRadius: '50%'
										}}
									/>
								</span>
							)}
						</>
					)}
				</TabWrapperMerchant>
			)}
			<TabWrapper>
				{type === 'transactions' && (
					<Flex justifyContent='space-between' alignItems='baseline'>
						<ol className='tab-list'>
							{links.map(({ path, title }) => (
								<NavLink
									key={path.toString()}
									to={`${path}`}
									className={`${'tab-list-item'} ${
										currentPath === path ? 'tab-list-active' : null
									}`}
								>
									{title}
								</NavLink>
							))}
						</ol>

						<Flex
							width='30%'
							alignItems='center'
							justifyContent='flex-end'
							className='tab-button'
						>
							{currentPath === '/dashboard/transactions' && (
								<Button
									Styles
									text={'Swap overview'}
									color={'#1A2CCE'}
									size='sm'
									type='button'
									onClick={handleSwapOverview}
								/>
							)}
							{(currentPath === '/dashboard/transactions' ||
								currentPath === '/dashboard/transactions/deposits' ||
								currentPath === '/dashboard/transactions/withdrawals') && (
								<Link
									style={{
										// fontWeight: 500,
										fontSize: '14px',
										lineHeight: '18px',
										textAlign: 'right',
										textDecoration: 'underline',

										color: '#1A2CCE'
									}}
									to='/dashboard/transactions/provider-status'
								>
									Provider status
								</Link>
							)}
							{(currentPath === '/dashboard/transactions' ||
								currentPath === '/dashboard/transactions/deposits' ||
								currentPath === '/dashboard/transactions/withdrawals' ||
								currentPath === '/dashboard/balances') && (
								<Button
									Styles
									text={'Download Report'}
									color={'#1A2CCE'}
									size='sm'
									type='button'
									onClick={handleDownload}
								/>
							)}
						</Flex>
					</Flex>
				)}

				{type === 'refund' && (
					<Flex justifyContent='space-between' alignItems='baseline'>
						<ol className='tab-list'>
							{links.map(({ path, title }) => (
								<NavLink
									key={path.toString()}
									to={`${path}`}
									className={`${'tab-list-item'} ${
										currentPath === path ? 'tab-list-active' : null
									}`}
								>
									{title}
								</NavLink>
							))}
						</ol>
					</Flex>
				)}

				{type === 'fxOps' && (
					<>
						<Flex justifyContent='space-between'>
							<Flex flexDir='row' width='100%' justifyContent='space-between'>
								<ol className='tab-list'>
									{links.map(({ path, title }) => (
										<NavLink
											key={path.toString()}
											to={`${path}`}
											className={`${'tab-list-item'} ${
												currentPath === path ? 'tab-list-active' : null
											}`}
										>
											{title}
										</NavLink>
									))}
								</ol>
							</Flex>

							<CustomDropdown
								handleSavedAccount={handleSavedAccount}
								handleWalletAddress={handleWalletAddress}
								source='fx'
							/>
						</Flex>
					</>
				)}

				{type === 'primary-tab' && (
					<ol className='tab-list'>
						{links.map(({ path, title }) => (
							<NavLink
								key={path.toString()}
								to={`${path}`}
								className={`${'tab-list-item'} ${
									currentPath === path ? 'tab-list-active' : null
								}`}
							>
								{title}
							</NavLink>
						))}
					</ol>
				)}

				<div className='tabs'>
					<Routes>
						{routes.map(({ path, component }) => (
							<Route
								exact
								key={path.toString()}
								path={`${path}`}
								element={component}
							/>
						))}
					</Routes>
				</div>
			</TabWrapper>
		</>
	);
};

export default Index;
