import { Skeleton } from 'antd';
import React from 'react';
import { Flex, Grid } from '../../../../components/box';
import { Spacer } from '../../../../components/spacer/styles';
import AddNewMerchant from '../addNewMerchant';
import InviteMerchant from '../inviteMerchant/InviteMerchant';
import { Card, Styles } from './styles';

const Index = ({
	merchantsCount,
	merchantsSuspended,
	merchantsSingupMonthly,
	loading
}) => {
	return (
		<Styles>
			<Flex justifyContent='flex-end'>
				<AddNewMerchant />
				<InviteMerchant />
			</Flex>
			<Spacer height='10px' />
			<Grid
				justifyContent={'space-around'}
				gap='10px'
				gridCol='repeat(auto-fit, minmax(270px, 1fr))'
				bgColor='#fff'
				pad='30px 20px'
			>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span> SIGNUPS THIS MONTH </span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{merchantsSingupMonthly?.result}</span>
						)}
						<span>{/* <TriangleIcon /> +4 from last week */}</span>
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>TOTAL MERCHANTS</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{merchantsCount?.result}</span>
						)}
						<span>{/* <TriangleIcon /> +201 from last week */}</span>
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span> SUSPENDED MERCHANTS</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{merchantsSuspended?.result}</span>
						)}
						<span>{/* <TriangleIcon /> +2 from last week */}</span>
					</Flex>
				</Card>
			</Grid>
		</Styles>
	);
};

export default Index;
