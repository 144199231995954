import logo from '../../assets/images/logo.svg';
import { Flex, Frame } from '../box';
import { ListStyles, SectionName, Styles } from './styles';

import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuthState } from '../../context';
import { env } from '../../services/Auth.service';
import { getUserprofile } from '../../services/Settings.service';
import { ToastMessage, type } from '../../utils/toastr';
import Switch from '../switch';
import {
	Approvals,
	Balances,
	Compliance,
	Fees,
	FxOps,
	Liquidity,
	Markets,
	Merchants,
	Overview,
	Refunds,
	Settings,
	Transactions,
	Wallets
} from './sideBarItems';

const Drawer = () => {
	const queryClient = useQueryClient();
	const { state } = useAuthState();
	const [role, setRole] = useState();
	const [mode, setMode] = useState();
	const [permissions, setPermissions] = useState();
	const [approver, setApprover] = useState();

	useQuery(
		['getUserprofile', localStorage.getItem('access_token')],
		async () => await getUserprofile(),
		{
			onSuccess: (res) => {
				setApprover(res?.data?.data?.approval);
			},
			refetchOnMount: 'always',
			enabled: !!localStorage.getItem('access_token')
		}
	);

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setMode(state?.user?.mode);
		setPermissions(state?.user?.role?.permitions);
		// setApprover(state?.type);
	}, [state, mode, permissions, role]);

	const mutation = useMutation(
		async (data) => {
			await env(data);
		},
		{
			onSuccess: () => {
				queryClient.resetQueries('getUserprofile');
				ToastMessage(type.SUCCESS, 'Admin environment successfully changed');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const handleSwitch = async (e) => {
		const payload = !e === false ? 'live' : 'test';
		try {
			await mutation.mutate({ mode: payload });
		} catch (error) {
			ToastMessage(type.SUCCESS, error.response.data.message);
		}
	};

	return (
		<Styles className='App'>
			<Frame className='logo_box' height={'110px'} width={'110px'}>
				<img alt={'logo'} src={logo} />
			</Frame>
			<div className='drawer'>
				<ul>
					<SectionName>DASHBOARD</SectionName>
					<Overview />
					<Balances />
					{/* <Approvals /> */}
					{approver === true ? <Approvals /> : null}

					{/* transactions  */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />
							<Refunds />
							<Fees />
							<Transactions />
						</>
					) : null}
					{role === 'Admin' && permissions?.includes('transactions') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />

							<Transactions />
						</>
					) : null}

					{role === 'Treasury' && permissions?.includes('transactions') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />

							<Transactions />
						</>
					) : null}
					{role === 'Finance' && permissions.includes('transactions') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />
							<Transactions />
						</>
					) : null}
					{role === 'Compliance' && permissions.includes('transactions') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />
							<Transactions />
						</>
					) : null}
					{role === 'Support' && permissions.includes('transactions') ? (
						<>
							<SectionName>OPERATIONS</SectionName>
							<Wallets />
							<Transactions />
						</>
					) : null}

					{role === 'Admin' && permissions?.includes('refunds') ? (
						<Refunds />
					) : null}
					{role === 'Treasury' && permissions?.includes('refunds') ? (
						<Refunds />
					) : null}
					{role === 'Finance' && permissions?.includes('refunds') ? (
						<Refunds />
					) : null}
					{role === 'Compliance' && permissions?.includes('refunds') ? (
						<Refunds />
					) : null}
					{role === 'Support' && permissions?.includes('refunds') ? (
						<Refunds />
					) : null}

					{/* markets  */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<Markets />
					) : null}
					{role === 'Admin' && permissions.includes('markets') ? (
						<Markets />
					) : null}
					{role === 'Treasury' && permissions.includes('markets') ? (
						<Markets />
					) : null}
					{role === 'Finance' && permissions.includes('markets') ? (
						<Markets />
					) : null}
					{role === 'Compliance' && permissions.includes('markets') ? (
						<Markets />
					) : null}
					{role === 'Support' && permissions.includes('markets') ? (
						<Markets />
					) : null}

					{/* Liquidity */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<Liquidity />
					) : null}
					{role === 'Admin' && permissions.includes('liquidity') ? (
						<Liquidity />
					) : null}
					{role === 'Treasury' && permissions.includes('liquidity') ? (
						<Liquidity />
					) : null}
					{role === 'Finance' && permissions.includes('liquidity') ? (
						<Liquidity />
					) : null}
					{role === 'Compliance' && permissions.includes('liquidity') ? (
						<Liquidity />
					) : null}
					{role === 'Support' && permissions.includes('liquidity') ? (
						<Liquidity />
					) : null}

					{/* FxOps */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<FxOps />
					) : null}
					{role === 'Admin' && permissions?.includes('fxops') ? (
						<FxOps />
					) : null}
					{role === 'Treasury' && permissions?.includes('fxops') ? (
						<FxOps />
					) : null}
					{role === 'Finance' && permissions?.includes('fxops') ? (
						<FxOps />
					) : null}
					{role === 'Compliance' && permissions?.includes('fxops') ? (
						<FxOps />
					) : null}
					{role === 'Support' && permissions?.includes('fxops') ? (
						<FxOps />
					) : null}

					{/* Merchants */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<>
							<SectionName>USERS MANAGMENT</SectionName>

							<Merchants />
						</>
					) : null}
					{role === 'Admin' && permissions?.includes('merchants') ? (
						<>
							<SectionName>USERS MANAGMENT</SectionName>

							<Merchants />
						</>
					) : null}
					{role === 'Treasury' && permissions?.includes('merchants') ? (
						<>
							<SectionName>USERS MANAGEMENT</SectionName>

							<Merchants />
						</>
					) : null}
					{role === 'Finance' && permissions?.includes('merchants') ? (
						<>
							<SectionName>USERS MANAGEMENT</SectionName>

							<Merchants />
						</>
					) : null}
					{role === 'Compliance' && permissions?.includes('merchants') ? (
						<>
							<SectionName>USERS MANAGMENT</SectionName>

							<Merchants />
						</>
					) : null}
					{role === 'Support' && permissions?.includes('merchants') ? (
						<>
							<SectionName>USERS MANAGMENT</SectionName>

							<Merchants />
						</>
					) : null}

					{/* Compliance */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<Compliance />
					) : null}
					{role === 'Admin' && permissions?.includes('compliance') ? (
						<Compliance />
					) : null}
					{role === 'Treasury' && permissions?.includes('compliance') ? (
						<Compliance />
					) : null}
					{role === 'Finance' && permissions?.includes('compliance') ? (
						<Compliance />
					) : null}
					{role === 'Compliance' && permissions?.includes('compliance') ? (
						<Compliance />
					) : null}
					{role === 'Support' && permissions?.includes('compliance') ? (
						<Compliance />
					) : null}

					{/* Settings */}
					{role === 'Super Admin' && permissions?.includes('all') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}
					{role === 'Admin' && permissions?.includes('settings') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}
					{role === 'Treasury' && permissions?.includes('settings') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}
					{role === 'Finance' && permissions?.includes('settings') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}
					{role === 'Compliance' && permissions?.includes('settings') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}
					{role === 'Support' && permissions?.includes('settings') ? (
						<>
							<SectionName>CONTROLS</SectionName>
							<Settings />
						</>
					) : null}

					<ListStyles
						style={{
							width: '100%'
						}}
					>
						<Flex className='status_container'>
							<div className='status'>
								<Switch
									color={'#69ce1a'}
									click={handleSwitch}
									loading={mutation.isLoading}
								/>
								<span>{mode} mode</span>
							</div>
						</Flex>
					</ListStyles>
				</ul>
			</div>
		</Styles>
	);
};

export default Drawer;
