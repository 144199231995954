import React, { useState } from "react";
import { getBankName } from "../services/Others.service";

export const useBankName = () => {
  const [bankName, setBankName] = useState([]);

  React.useEffect(() => {
    getBankName().then((data) => setBankName(data?.data?.data));
  }, []);

  const handleSelectBankName = (bankCode) => {
    const res = bankName?.find((item) => item?.code === bankCode);
    return res?.name;
  };
  return { handleSelectBankName };
};
