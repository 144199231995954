import React from 'react';
import { Spacer } from '../../../../components/spacer/styles';
import { BalancesTableHead } from '../../../../utils/constants';
import { Styles } from './styles';
import CustomTable from '../table';
import { useQuery } from 'react-query';
import { getAllBalances } from '../../../../services/Balance.service';
import { Loader } from '../../../../components/loader';
import { useAuthState } from '../../../../context';

const Balances = () => {
	const state = useAuthState();

	const balances = useQuery(
		'getAllBalances',
		async () => await getAllBalances()
	);

	if (balances.status === 'loading') {
		return <Loader />;
	}

	if (balances.status === 'error') {
		return <span>Error: {balances.error.message}</span>;
	}
	return (
		<Styles>
			<Spacer height='30px' />
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={BalancesTableHead}
				tableBody={balances?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#000A62'
				tbodyColor='#141414'
				currencies={state?.currencies}

				// pageSize={10}
				// paginator

				//   meta={approvalsFiat?.data?.data?.data.meta}
				//   handlePageChange={handlePageChange}
			/>
		</Styles>
	);
};

export default Balances;
