import PropTypes from 'prop-types';
import React from 'react';
import { EmptyState } from '../../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../../components/paginator';
import { generateID } from '../../../../../../utils/generateID';
import { CustomTableMain, Paginator, Td } from './styles';

import { Link } from 'react-router-dom';
import { formattedDate } from '../../../../../../utils/helpers';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handlePageChange,
	meta
}) => {
	const returnTableRow = (data, i) => {
		return (
			<React.Fragment key={i}>
				<tr key={generateID(10)}>
					<Td>{formattedDate(data?.created_at)}</Td>
					<Td>
						<span
							style={{
								fontWeight: 500,
								fontSize: '14px',
								lineHeight: '16px',
								letterSpacing: '0.04em',
								color: '#5E5E5E'
							}}
						>
							{data?.event}
						</span>
						: <span>{data?.description}</span>
						{data?.object === 'transaction' && (
							<Link
								to={`/dashboard/transactions/${data.objectId}/transaction`}
								style={{
									marginLeft: '10px',
									textDecoration: 'underline',
									color: '#1A2CCE'
								}}
							>
								View transaction
							</Link>
						)}
					</Td>
					<Td>{data?.channel}</Td>
				</tr>
			</React.Fragment>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={i}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
