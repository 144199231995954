import React from 'react';
import { Form, Input, Select } from 'antd';
import { InputHolderStyle, RateStyle } from '../styles';
import { Flex } from '../../../../components/box';

function Add({
	handelSubmit,
	handleInputValueChange,
	action,
	currencyPair,
	loading,
	rates,
	source
}) {
	const [form] = Form.useForm();
	// console.log(rates);
	return (
		<Form
			id='marketForm'
			// form={form}
			onFinish={handelSubmit}
			layout={'vertical'}
		>
			<h3>{action === 'add' ? 'Add new rate' : 'Edit rate'}</h3>
			<RateStyle>
				<p>Currency pair</p>
				<Form.Item
					name={'currency_pair'}
					rules={[{ required: true, message: 'Currency pair is Required' }]}
				>
					<Select
						size='large'
						style={{ width: '100%' }}
						title='currency_pair'
						placeholder='select currency pair'
						onChange={(e, name) => {
							handleInputValueChange(name);
						}}
					>
						{currencyPair?.map((item) => (
							<Select.Option key={item.id} value={item.pair}>
								{item.pair}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='source'
					label='Rate Source'
					rules={[{ required: true, message: 'Rate Source is Required' }]}
				>
					<Select
						placeholder='select rate source'
						size='large'
						style={{ width: '100%' }}
					>
						{source?.map((item) => (
							<Select.Option key={item.id}>{item.name}</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Flex justifyContent='space-between' className='display'>
					<p>Currency rate</p>
					{loading ? (
						'loading'
					) : rates?.type === 'SELL' ? (
						<p>
							{rates !== undefined || null
								? `${rates.rate}
            ${rates.from_currency}/${rates.to_currency}`
								: 'rates will appear here'}
						</p>
					) : (
						<p>
							{rates !== undefined || null
								? `${rates?.rate}
            ${rates?.from_currency}/${rates?.to_currency}`
								: 'rates will appear here'}
						</p>
					)}
				</Flex>
				<Form.Item name={'source_currency'} label='Source Currency'>
					<Input type='text' placeholder='Enter source Currency' />
				</Form.Item>
				<InputHolderStyle>
					<Form.Item name={'offset'} label='Offset'>
						<Select
							size='large'
							style={{ width: '100%' }}
							placeholder='select offset'
						>
							<Select.Option value='FLAT'>FLAT</Select.Option>
							<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
							<Select.Option value='MANUAL'>MANUAL</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						name={'buying'}
						type='number'
						label='Buying price markup'
						rules={[
							{
								required: true,
								message: 'Buying price markup is Required'
							}
						]}
					>
						<Input type='number' placeholder='15' />
					</Form.Item>
					<Form.Item
						name={'selling'}
						type='number'
						label='Selling price markup'
						rules={[
							{
								required: true,
								message: 'Selling price markup is Required'
							}
						]}
					>
						<Input type='number' placeholder='10' />
					</Form.Item>
				</InputHolderStyle>
			</RateStyle>
		</Form>
	);
}

export default Add;
