import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const StylesAccount = styled.div`
  background: #f3f6fb;
  border-radius: 4px;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }

  div {
    margin-bottom: 40px;
  }
  h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    color: #000000;
    text-align: left;
    padding-bottom: 16px;
  }
  p {
    margin: 0;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #000000;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    span {
      background: #3ab75d;
      cursor: pointer;
      border: 1px solid #3ab75d;
      border-radius: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: #ffffff;
      padding: 5px 8px;
      margin-left: 8px;
    }
  }

  .crypto {
    margin-bottom: 0;
    div:nth-of-type(1),
    div:nth-of-type(2) {
      margin-bottom: 0;
    }
    h3 {
      padding-bottom: 12px;
    }
  }
`;

export const StylesAccountStatus = styled(Flex)`
background: #F4FFEF;
                border-radius: 5px;
                    margin-bottom:12px;
p{
    color: #166F00;
    font-weight: 500,
    fontSize: '16px',
    lineHeight: '21px'
}

.ant-switch{
    width:40px;
}
.ant-switch-checked {
    background-color: #69CE1A;
}
`;
