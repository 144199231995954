import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DangerIcon } from '../../../../../assets';
import { Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import { getMerchantById } from '../../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import BankInfo from './bank-info';
import BusinessInfo from './business-info';
import DoucmentUpload from './doc-upload';
import { Styles, VerificationProgress } from './styles';

import { Form, Input } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import {
	addCompilanceVba,
	approveCompilanceBankInfo,
	approveCompilanceBusiness,
	approveCompilanceDocumennt,
	revokeCompilance
} from '../../../../../services/Compliance.service';

const Index = () => {
	let params = useParams();
	const queryClient = useQueryClient();
	const [vbaUser, setVbaUser] = useState(false);
	const result = useQuery(
		'getMerchantById',
		async () => await getMerchantById(params.merchant)
	);

	const approveComplianceBusinessMutation = useMutation(
		async (data) => {
			await approveCompilanceBusiness(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				ToastMessage(
					type.SUCCESS,
					'business informations successfully approved'
				);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const approveCompilanceDocumenntMutation = useMutation(
		async (data) => {
			await approveCompilanceDocumennt(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				ToastMessage(type.SUCCESS, 'business documents successfully approved');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const approveCompilanceBankInfoMutation = useMutation(
		async (data) => {
			await approveCompilanceBankInfo(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				ToastMessage(type.SUCCESS, 'bank informations successfully approved');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const approveComplianceVba = useMutation(
		async (data) => {
			await addCompilanceVba(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				handleOk();
				ToastMessage(
					type.SUCCESS,
					'Virtual Bank Name and BVN successfully Saved'
				);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const revokeCompilanceMutation = useMutation(
		async (data) => {
			await revokeCompilance(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				ToastMessage(type.SUCCESS, 'merchant kyc sucessfully revoked');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	// functions triggers onClick//
	const handleApproveComplianceBusiness = async () => {
		try {
			await approveComplianceBusinessMutation.mutate(params.merchant);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message.response.error);
		}
	};

	const handleApproveComplianceDoc = async () => {
		try {
			await approveCompilanceDocumenntMutation.mutate(params.merchant);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message.response.error);
		}
	};

	const handleApproveComplianceBankInfo = async () => {
		try {
			await approveCompilanceBankInfoMutation.mutate(params.merchant);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message.response.error);
		}
	};

	const handleRevokeCompliance = async () => {
		try {
			await revokeCompilanceMutation.mutate(params.merchant);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message.response.error);
		}
	};

	const showVbaModal = () => {
		setVbaUser(true);
	};
	const handleVba = async (values) => {
		try {
			await approveComplianceVba.mutate(values);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message.response.error);
		}
	};
	const handleOk = () => {
		setTimeout(() => {
			setVbaUser(false);
		}, 200);
	};

	const handleCancel = () => {
		setVbaUser(false);
	};

	const [sidebarTop, setSidebarTop] = useState(undefined);

	useEffect(() => {
		const chatEl = document
			.querySelector('.fixed-top')
			?.getBoundingClientRect();
		setSidebarTop(chatEl?.top);
	}, []);

	useEffect(() => {
		if (!sidebarTop) return;
		window.addEventListener('scroll', isSticky);
		return () => {
			window.removeEventListener('scroll', isSticky);
		};
	}, [sidebarTop]);

	const isSticky = () => {
		const chatEl = document.querySelector('.fixed-top');
		const scrollTop = window.scrollY;
		if (scrollTop >= sidebarTop - 10) {
			chatEl.classList.add('is-sticky');
		} else {
			chatEl.classList.remove('is-sticky');
		}

		if (scrollTop >= 2284) {
			chatEl.classList.remove('is-sticky');
		}
		// console.log(window.scrollY, sidebarTop, sidebarTop - 10);
	};

	if (result.isLoading) {
		return <Loader full />;
	}

	if (result.isError) {
		return <span>Error: {result.error}</span>;
	}

	const merchant = result?.data?.data?.data;
	const apppprovedBuisness = merchant?.verified_business === true;
	const apppprovedDoc = merchant?.verified_document === true;
	const apppprovedBankInfo = merchant?.verified_bank === true;
	const apppprovedVba = merchant?.vba_name !== null;

	return (
		<>
			<Styles alignItem={'flex-start'} flexDir={'column'}>
				<Flex
					alignItem='flex-start'
					width='100%'
					justifyContent='space-between'
					className='container'
					flexDir={'row'}
					gap={2}
				>
					<Flex
						flexDir='column'
						alignItems='flex-start'
						max-width='450px'
						className='fixed-top'
					>
						<VerificationProgress>
							<h5>Verification Progress</h5>
							<Flex alignItem={'flex-start'} flexDir={'column'}>
								<Flex justifyContent={'space-between'}>
									<Flex alignItem={'flex-start'} width='70%' flexDir={'column'}>
										<h5>Business Information</h5>
										<span>Approve Merchant Business Information</span>
									</Flex>
									<Button
										Styles
										text={apppprovedBuisness ? 'Approved' : 'Approve'}
										bgColor={apppprovedBuisness ? '#E2E2E2' : '#00B428'}
										color={apppprovedBuisness ? '#6C6C6C' : '#fff'}
										size='sm'
										border='border: 0.8px solid #1A2CCE;'
										borderRadius={'5px'}
										onClick={handleApproveComplianceBusiness}
										disabled={apppprovedBuisness}
										loading={approveComplianceBusinessMutation.isLoading}
									/>
								</Flex>
							</Flex>
							<Flex alignItem={'flex-start'} flexDir={'column'}>
								<Flex justifyContent={'space-between'}>
									<Flex alignItem={'flex-start'} width='70%' flexDir={'column'}>
										<h5>Document Upload</h5>
										<span>Approve KYC/AML documents</span>
									</Flex>
									<Button
										Styles
										text={apppprovedDoc ? 'Approved' : 'Approve'}
										bgColor={apppprovedDoc ? '#E2E2E2' : '#00B428'}
										color={apppprovedDoc ? '#6C6C6C' : '#fff'}
										size='sm'
										borderRadius={'5px'}
										disabled={apppprovedDoc}
										onClick={handleApproveComplianceDoc}
										loading={approveCompilanceDocumenntMutation.isLoading}
									/>
								</Flex>
							</Flex>

							<Flex alignItem={'flex-start'} flexDir={'column'}>
								<Flex justifyContent={'space-between'}>
									<Flex alignItem={'flex-start'} width='70%' flexDir={'column'}>
										<h5> Bank Information</h5>
										<span>Approve Bank information</span>
									</Flex>
									<Button
										Styles
										text={apppprovedBankInfo ? 'Approved' : 'Approve'}
										bgColor={apppprovedBankInfo ? '#E2E2E2' : '#00B428'}
										color={apppprovedBankInfo ? '#6C6C6C' : '#fff'}
										size='sm'
										borderRadius={'5px'}
										onClick={handleApproveComplianceBankInfo}
										disabled={apppprovedBankInfo}
										loading={approveCompilanceBankInfoMutation.isLoading}
									/>
								</Flex>
							</Flex>
							<Flex alignItem={'flex-start'} flexDir={'column'}>
								<Flex justifyContent={'space-between'}>
									<Flex alignItem={'flex-start'} width='70%' flexDir={'column'}>
										<h5>Virtual Account Name</h5>
										<span>Add virtual account name</span>
									</Flex>
									<Button
										Styles
										text={apppprovedVba ? 'Approved' : 'Add'}
										bgColor={apppprovedVba ? '#E2E2E2' : '#00B428'}
										color={apppprovedVba ? '#6C6C6C' : '#fff'}
										size='sm'
										borderRadius={'5px'}
										onClick={showVbaModal}
										disabled={apppprovedVba}
									/>
								</Flex>
							</Flex>
						</VerificationProgress>
						<Flex justifyContent='flex-start' className='revoke'>
							<DangerIcon />
							<Button
								Styles
								text={'Revoke user KYC'}
								color={'#ce1a1a'}
								size='sm'
								borderRadius={'5px'}
								onClick={handleRevokeCompliance}
							/>
						</Flex>
					</Flex>
					<Flex width='57%' flexDir='column' style={{ marginLeft: 'auto' }}>
						<BusinessInfo merchant={merchant} />
						<DoucmentUpload merchant={merchant} />
						<BankInfo merchant={merchant} />
					</Flex>
				</Flex>
			</Styles>
			<ModalFrame
				visible={vbaUser}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
			>
				<h3 style={{ textAlign: 'center' }}>Add Virtual Account Name</h3>
				<Form layout='vertical' onFinish={handleVba}>
					<Form.Item
						label='Account Name'
						name='vba_name'
						rules={[{ required: true, message: 'Account Name is Required' }]}
						type='text'
					>
						<Input />
					</Form.Item>
					<Form.Item
						label='BVN'
						name='bvn'
						rules={[{ required: true, message: 'BVN is Required' }]}
						type='text'
					>
						<Input />
					</Form.Item>
					<Button
						Styles
						text={'Save'}
						bgColor={'#1A2CCE'}
						fullwidth
						color='#FFFFFF'
						size='md'
						type='submit'
						loading={approveComplianceVba.isLoading}
					/>
				</Form>
			</ModalFrame>
		</>
	);
};

export default Index;
