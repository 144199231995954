import React from 'react';
import { Modal } from 'antd';
import { FormContainer } from './styles';

const ModalFrame = (
	{
		visible,
		children,
		title,
		width,
		footer,
		handleOk,
		handleCancel,
		confirmLoading,
		pad,
		key,
		destroyOnClose,
		...props
	},
	ref
) => {
	return (
		<Modal
			onClick={(e) => e.stopPropagation()}
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
			footer={footer}
			centered={true}
			width={width}
			
			title={title}
			destroyOnClose={true}
			key={key}
			{...props}
		>
			<FormContainer key={key} pad={pad}>
				{children}
			</FormContainer>
		</Modal>
	);
};

export default ModalFrame;
