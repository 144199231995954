import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { generateID } from "../../../../../../utils/generateID";
import Pagination from "../../../../../../components/paginator";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { OverFlowScrollBar } from "../../../../../../components/overflowScroll/styles";
import {
  UserAvatar,
  DeleteIcon,
  Reset2faIcon,
  LockIcon,
  SessionIcon,
  ClearLimitIcon,
} from "../../../../../../assets";
import { EmptyState } from "../../../../../../components/emptyState";
import PopOver from "../../../../../../components/popover/PopOver";
import { BottomPanel } from "../../../table/styles";
import { formattedDate } from "../../../../../../utils/helpers";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  handleEdit,
  handlePageChange,
  meta,
  handlePasswordReset,
  handleRemove,
  handleReset2fa,
  showClearLimit,
}) => {
  const returnTableRow = (data, i) => {
    return (
      <tr key={i}>
        <Td>
          <div
            style={{
              display: "flex",
            }}
          >
            {data.full_name}
            {data?.own_merchant === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "4px 7px",
                  gap: "8px",

                  width: "55px",
                  height: "24px",

                  background: "#C6DDFF",
                  borderRadius: "8px",
                  marginLeft: "16px",
                }}
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.05em",
                    color: "#1A2CCE",
                  }}
                >
                  Owner
                </span>
              </div>
            )}
          </div>
        </Td>
        <Td>{data.email}</Td>

        <Td>{formattedDate(data?.created_at)}</Td>
        <CustomTd>
          <PopOver key={data?.id} placement="rightTop">
            <Link to={`#`} onClick={() => handleEdit(data.id)}>
              <div>
                <UserAvatar />
                <p>Edit details</p>
              </div>
            </Link>

            <Link to={`#`} onClick={() => handleReset2fa(data?.id)}>
              <div>
                <Reset2faIcon />
                <p>Reset 2FA</p>
              </div>
            </Link>

            <Link to={`#`} onClick={() => showClearLimit(data?.id)}>
              <div>
                <ClearLimitIcon />
                <p>Clear Limit</p>
              </div>
            </Link>
            <Link to={`#`} onClick={() => handlePasswordReset(data.id)}>
              <div>
                <LockIcon />
                <p>Reset password</p>
              </div>
            </Link>
            <Link
              to={`./sessions/${data.id}`}
              //  onClick={() => showClearLimitModal(data?.id)}
            >
              <div>
                <SessionIcon />
                <p>View Sessions</p>
              </div>
            </Link>
            <BottomPanel>
              <Link to={`#`} onClick={() => handleRemove(data.id)}>
                <div>
                  <DeleteIcon color={"#C10606"} />
                  <p
                    style={{
                      color: "#C10606",
                    }}
                  >
                    Remove user
                  </p>
                </div>
              </Link>
            </BottomPanel>
          </PopOver>
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead?.map((head, i) => (
                    <th key={generateID(11)}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination meta={meta} handlePageChange={handlePageChange} />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
  firstLast: PropTypes.any,
  paginator: PropTypes.any,
  prevNext: PropTypes.any,
};

export default CustomTable;
