import { Form, Input } from "antd";
import React from "react";
import { ToastMessage, type } from "../../utils/toastr";
import { FormStyle } from "./styles";

const TopUp = ({ form, mutation }) => {
  const handleSubmit = async (values) => {
    const payload = { amount: Number(values.amount) };
    try {
      await mutation.mutate(payload);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "left" }}>Top Up</h3>
      <FormStyle>
        <Form
          onFinish={handleSubmit}
          form={form}
          layout={"vertical"}
          size="large"
          id="topup"
        >
          <Form.Item
            label="Enter amount to top up"
            className="amount"
            name="amount"
            rules={[{ required: true, message: "Amount is Required" }]}
            type="number"
          >
            <Input
              addonBefore="USDT"
              type="number"
              //   placeholder="0.00"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <small>Existing Balance: 1,293,492.09 USDT</small>
        </Form>
      </FormStyle>
    </>
  );
};

export default TopUp;
