import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { generateID } from '../../../../utils/generateID';
import Pagination from '../../../../components/paginator';
import { CustomTableMain, Td, Paginator, CustomTd } from './styles';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import {
	AcceptTransactionIcon,
	DotIcon,
	// RejectTransactionIcon,
	UserAvatar,
	ViewTransactionIcon
} from '../../../../assets';
import PopOver from '../../../../components/popover/PopOver';
import { Link, useLocation } from 'react-router-dom';

import { formattedDate, formattedTime } from '../../../../utils/helpers';
import { EmptyState } from '../../../../components/emptyState';
import { useAuthState } from '../../../../context';
// import { RoleCheck } from '../../../../routes/privateRoute';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	tableHead2,
	widthdrawnTo,
	meta,
	handlePageChange,
	handleRequestRefund,
	handleRefundComplete
}) => {
	const { state } = useAuthState();
	const [role, setRole] = useState();
	const [mode, setMode] = useState();
	const [permissions, setPermissions] = useState();
	const [approver, setApprover] = useState();
	const rejected = '/dashboard/refund';
	const refunding = '/dashboard/refund/request';
	// const completed = '/dashboard/refund/complete';
	let path = useLocation().pathname;

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setMode(state?.user?.mode);
		setPermissions(state?.user?.role?.permitions);
		setApprover(state?.user?.approver);
	}, [state, mode, permissions, role, approver]);

	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.id}>
				<Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'column'
						}}
					>
						<span style={{ color: '#1A1F36' }}>
							{formattedDate(data?.created_at)}
						</span>
						<span style={{ color: '#6F6F6F' }}>
							{formattedTime(data?.created_at)}
						</span>
					</div>
				</Td>
				<Td>
					<span>{data?.to_currency}</span>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>
							{data?.from_merchant?.business_name
								? data?.from_merchant?.business_name
								: data.to_merchant?.business_name}
						</span>
					</div>
				</Td>

				<Td>
					<span>{data.to_currency}</span>
					<strong>{data.amount.toLocaleString()}</strong>
				</Td>

				<CustomTd status={data?._status}>
					<div>
						<span>{data?._status}</span>
					</div>
				</CustomTd>
				<CustomTd>
					<PopOver
						placement='rightTop'
						main={
							<div className='icon_box'>
								<DotIcon />
							</div>
						}
					>
						<Link to={`/dashboard/transactions/deposits/${data.id}`}>
							<div>
								<ViewTransactionIcon />
								<p>Transaction details</p>
							</div>
						</Link>
						<Link
							to={`/dashboard/merchants/${
								data?.to_merchantId !== null
									? data?.to_merchantId
									: data?.from_merchantId
							}`}
						>
							<div>
								<UserAvatar />
								<p>Merchant info</p>
							</div>
						</Link>
						{rejected === path && (
							<Link to='#' onClick={() => handleRequestRefund(data?.id)}>
								<div>
									<AcceptTransactionIcon />
									<p>Request refund</p>
								</div>
							</Link>
						)}
						{refunding === path && (
							<Link to='#' onClick={() => handleRefundComplete(data?.id)}>
								<div>
									<AcceptTransactionIcon />
									<p>Complete refund</p>
								</div>
							</Link>
						)}
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
