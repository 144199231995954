import React, { useEffect } from "react";
import { LoginInContainer, LoginTitle } from "./styles";
import { Flex, Frame } from "../../../components/box";
import logo from "../../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import Fields from "./fields";

const Signup = () => {
  const token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate, token]);

  return (
    <LoginInContainer>
      <Flex
        width={"530px"}
        flexDir={"column"}
        margin={"auto"}
        className="form-container"
      >
        <Flex>
          <Frame height={"150px"} width={"150px"}>
            <img alt="logo" src={logo} />
          </Frame>
        </Flex>
        <LoginTitle>Sign into your account </LoginTitle>
        <Fields />
      </Flex>
    </LoginInContainer>
  );
};

export default Signup;
