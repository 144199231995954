import styled from "styled-components";

export const Styles = styled.div`
  width: 100%;
  padding: 30px 30px 0;
  h2 {
    color: #000000;
  }
  @media (max-width: 700px) {
    padding: 20px 4%;
  }
`;

export const Wrapper = styled.div`
  .select {
    .ant-select-selector {
      background-color: #1a2cce;
    }
    .ant-select-arrow {
      color: #fff;
    }
  }
`;
