import styled from "styled-components";

export const Styles = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h6 {
    margin-top: 23px;
    font-size: 14px;
    font-weight: 500;
    color: #0f0f0f;
  }

  p {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 40px;
    color: #9b9b9b;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #1a2cce;
  }
`;
