import styled from 'styled-components';
import { Flex } from '../../../../../components/box';
import bgImg from '../../../../../assets/images/bgImg.png';

export const Styles = styled.div`
	background-color: #eef2f7;
	padding: 25px;
	border-radius: 10px;
	padding: 0 auto;
	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		color: #1a1f36;
	}
	.credit-cap {
		background-image: url(${bgImg});
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		/* margin-right: 30px; */
	}
`;

export const Card = styled(Flex)`
	padding: 20px;
	border: 1px solid rgba(223, 223, 223, 0.39);
	box-sizing: border-box;
	background: #ffffff;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.container {
		span:first-child {
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			text-transform: uppercase;
			color: ${({ color }) => (color ? color : '#7171a6')};
			padding-bottom: 20px;
		}
	}
	.viewall {
		span:first-child {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 21px;
			color: ${({ color }) => (color ? color : '#33334f')};
			padding-bottom: 20px;
		}
	}
`;

export const SummaryToggle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	> span {
		color: #8f8f8f;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 17px;
		letter-spacing: 0.27em;
		text-align: left;
		text-transform: uppercase;
		/* padding: 0; */
	}

	.summary_options {
		display: flex;
		margin-left: 10px;
		background-color: white;
		border-radius: 22px;
		height: 39px;
		align-items: center;
		width: 136px;
		justify-content: center;
		padding: 0 6px;
	}

	.summary_option {
		width: 68px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-transform: uppercase;
	}

	.summary_option p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #9f9f9f;
	}

	.summary_option_active {
		background: #c6ddff;
		border-radius: 22px;
		color: #1a2cce;
	}

	.summary_option_active p {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
	}
`;

export const SelectContainer = styled(Flex)`
	justify-content: space-between;
	background-color: #fff;
	padding: 0 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
	border: 0.7px solid #dfdfdf;
	width: max-content;
	> span {
		font-size: 12px;
		color: #969696;
	}
`;

export const IconAndExport = styled(Flex)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;

	Button {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		text-align: right;
		text-decoration-line: underline;
	}
	.icon_box_container {
		display: flex;
		// padding: 2rem;
	}

	.icon_box {
		margin-right: 21px;
		display: flex;
		// align-items: center;
		// justify-content: center;
	}

	.icon_box_text {
		font-size: 19px;
		font-weight: bold;
		margin-bottom: 0;
	}

	.flag {
		background: white;
		width: 30px;
		height: 30px;
		// padding: 5px;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		// display: inline-flex;
		margin-right: -5px;
		img {
			width: 18px;
		}
	}
`;
