import apiClient from './axios';

const EndPoints = {
	getCompliancePendingRecent: `/admin/merchants?page=1&take=10&kyc=pending&order=DESC`,
	getComplianceSummaryVerified: `/admin/summary/merchants?math=count&kyc=verified`,
	getComplianceSummaryPending: `/admin/summary/merchants?math=count&kyc=pending`,
	getComplianceSummaryRejected: `/admin/summary/merchants?math=count&kyc=rejected`,

	getComplianceApproved(filterData) {
		let query = `/admin/merchants?kyc=verified&order=DESC&take=10`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.kyc) {
			query += `&kyc=${filterData.kyc}`;
		}
		if (filterData.country) {
			query += `&country=${filterData.country}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.search) {
			query += `&search=${filterData.search}`;
		}
		return query;
	},
	getBusinessCompliance(filterData) {
		let query = `/admin/wallets?compliance_level=BUSINESS&fiat_compliance_status=pending&order=DESC&take=10`;
		// let query = `/merchants?kyc=verified&order=DESC&take=10`;

		if (filterData.status) {
			query = `/admin/wallets?compliance_level=BUSINESS&fiat_compliance_status=${filterData?.status?.toLowerCase()}&order=DESC&take=10`;
		}
		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.kyc) {
		// 	query += `&kyc=${filterData.kyc}`;
		// }
		// if (filterData.country) {
		// 	query += `&country=${filterData.country}`;
		// }

		// if (filterData.search) {
		// 	query += `&search=${filterData.search}`;
		// }
		return query;
	},
	getPersonalCompliance(filterData) {
		let query = `/admin/wallets?compliance_level=PERSONAL&fiat_compliance_status=pending&order=DESC&take=10`;
		// let query = `/merchants?kyc=verified&order=DESC&take=10`; fiat_compliance_status=pending&

		if (filterData.status) {
			query = `/admin/wallets?compliance_level=PERSONAL&fiat_compliance_status=${filterData?.status?.toLowerCase()}&order=DESC&take=10`;
		}
		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.kyc) {
		// 	query += `&kyc=${filterData.kyc}`;
		// }
		// if (filterData.country) {
		// 	query += `&country=${filterData.country}`;
		// }
		// if (filterData.search) {
		// 	query += `&search=${filterData.search}`;
		// }
		return query;
	},

	getCompliancePending(filterData) {
		let query = `/admin/merchants?kyc=pending&order=DESC`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.kyc) {
			query += `&kyc=${filterData.kyc}`;
		}
		if (filterData.country) {
			query += `&country=${filterData.country}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.search) {
			query += `&search=${filterData.search}`;
		}
		return query;
	},

	getComplianceSuspended(filterData) {
		let query = `/admin/merchants?kyc=rejected&order=DESC`;

		if (filterData.kyc) {
			query += `&kyc=${filterData.kyc}`;
		}
		if (filterData.country) {
			query += `&country=${filterData.country}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.search) {
			query += `&search=${filterData.search}`;
		}
		return query;
	},

	approveCompilanceBusiness(id) {
		return `/admin/compliance/business/${id}`;
	},
	addComplianceVba(id) {
		return `/admin/compliance/approve/${id}`;
	},
	approveCompilanceDocumennt(id) {
		return `/admin/compliance/document/${id}`;
	},
	approveCompilanceBankInfo(id) {
		return `/admin/compliance/bank/${id}`;
	},
	revokeCompilance(id) {
		return `/admin/compliance/revoke/${id}`;
	}
};

export const getComplianceApproved = async (filterData) => {
	const res = await apiClient.get(EndPoints.getComplianceApproved(filterData));
	return res;
};
export const getWalletsBusinessCompliance = async (filterData) => {
	const res = await apiClient.get(EndPoints.getBusinessCompliance(filterData));
	return res;
};
export const getWalletsPersonalCompliance = async (filterData) => {
	const res = await apiClient.get(EndPoints.getPersonalCompliance(filterData));
	return res;
};

export const getCompliancePending = async (filterData) => {
	const res = await apiClient.get(EndPoints.getCompliancePending(filterData));
	return res;
};
export const getCompliancePendingRecent = async () => {
	const res = await apiClient.get(EndPoints.getCompliancePendingRecent);
	return res;
};

export const getComplianceSuspended = async (filterData) => {
	const res = await apiClient.get(EndPoints.getComplianceSuspended(filterData));
	return res;
};

export const getComplianceSummaryVerified = async () => {
	const res = await apiClient.get(EndPoints.getComplianceSummaryVerified);
	return res;
};

export const getComplianceSummaryPending = async () => {
	const res = await apiClient.get(EndPoints.getComplianceSummaryPending);
	return res;
};

export const getComplianceSummaryRejected = async () => {
	const res = await apiClient.get(EndPoints.getComplianceSummaryRejected);
	return res;
};

export const approveCompilanceBusiness = async (id) => {
	const res = await apiClient.get(EndPoints.approveCompilanceBusiness(id));
	return res;
};
export const addCompilanceVba = async (id, payload) => {
	const res = await apiClient.post(EndPoints.addComplianceVba(id), payload);
	return res;
};

export const approveCompilanceDocumennt = async (id) => {
	const res = await apiClient.get(EndPoints.approveCompilanceDocumennt(id));
	return res;
};

export const approveCompilanceBankInfo = async (id) => {
	const res = await apiClient.get(EndPoints.approveCompilanceBankInfo(id));
	return res;
};

export const revokeCompilance = async (id) => {
	const res = await apiClient.get(EndPoints.revokeCompilance(id));
	return res;
};
