import React, { useState } from "react";
import { Skeleton, Input } from "antd";
import { SwitchWrap } from "./switch.styles";
import { useAuthState } from "../../context";

const Switch = ({ click, color, loading }) => {
  const { state } = useAuthState();
  const mode = state?.user?.mode;

  const [switched, setSwitched] = useState(mode !== "live" ? false : true);

  const handleSwitch = (e) => {
    setSwitched(e.target.checked);
    click(e.target.checked);
  };

  return (
    <SwitchWrap switched={switched} color={color}>
      {loading ? (
        <Skeleton.Button active={loading} size={"default"} shape="circle" />
      ) : (
        <div className="switch-main">
          <div className="switch-btn">
            <span>
              <input
                type="checkbox"
                className="switch-check"
                defaultChecked={switched}
                onClick={handleSwitch}
              />
              <span className="switch-ball" />
            </span>
          </div>
          <div className="switch-overlay" />
        </div>
      )}
    </SwitchWrap>
  );
};

export default Switch;
