import { Checkbox, Form, Input, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { Flex } from '../../../../components/box';
import { Styles } from './styles';

import { useMutation, useQueryClient } from 'react-query';
import Button from '../../../../components/button';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { useAuthState } from '../../../../context/context';
import { createMerchant } from '../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../utils/toastr';

const { TextArea } = Input;

const AddNewMerchant = () => {
	const [visible, setVisible] = useState(false);
	const [switched, setSwitched] = useState(false);

	const [form] = Form.useForm();

	const queryClient = useQueryClient();

	const createMutation = useMutation(
		async (data) => {
			await createMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getAllMerchants');
				queryClient.invalidateQueries('getTotalMerchantSummaryCount');
				queryClient.invalidateQueries('getTotalMerchantSignup');
				ToastMessage(type.SUCCESS, 'merchant info sucessfully updated');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const show = () => setVisible(true);

	const { countries } = useAuthState();

	const handleCancel = () => {
		setVisible(false);
	};

	const handleSwitched = (checked) => {
		setSwitched(checked);
	};

	const handleSubmit = (values) => {
		const payload = {
			...values,
			kyc: switched,
			full_name: values.business_name
		};
		createMutation.mutate(payload);
	};
	return (
		<>
			<Button
				text='Add a new merchant'
				color='#1A2CCE'
				size='sm'
				borderRadius='5px'
				onClick={show}
				style={{ textDecoration: 'underline' }}
			/>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text='Add merchant'
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						key='submit'
						form='editMerchant'
						loading={createMutation.isLoading}
						style={{ padding: '0.85rem 50px' }}
					/>
				]}
				width={650}
			>
				<Styles>
					<h3 style={{ textAlign: 'left' }}>Add new merchant</h3>
					<Form
						form={form}
						layout='vertical'
						onFinish={handleSubmit}
						id='editMerchant'
						initialValues={{
							layout: 'vertical',
							size: 'large',
							mail: false
						}}
					>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item
								label='Business Name'
								name='business_name'
								rules={[
									{ required: true, message: 'Business Name is required' }
								]}
							>
								<Input type='text' />
							</Form.Item>
							<Form.Item
								label='Address'
								name='address'
								rules={[{ required: true, message: 'Address is required' }]}
							>
								<Input type='text' />
							</Form.Item>
						</Flex>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item
								label='Email Address'
								name='email'
								rules={[
									{ required: true, message: ' Email Address is required' }
								]}
							>
								<Input type='email' />
							</Form.Item>
							<Form.Item
								label='Phone Number'
								name='phone'
								rules={[
									{ required: true, message: 'Phone Number is required' }
								]}
							>
								<Input type='text' />
							</Form.Item>
						</Flex>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item
								label='Website '
								name='website'
								rules={[{ required: true, message: 'Website is required' }]}
							>
								<Input type='text' />
							</Form.Item>
							<Form.Item
								label='Region/Country'
								name='country'
								rules={[
									{ required: true, message: 'Region/Country is required' }
								]}
							>
								<Select placeholder='Select Region/Country'>
									{countries.map((item) => (
										<Select.Option value={item.name} key={item.name}>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Flex>
						<Form.Item
							label='Business Description'
							name='business_description'
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: 'Business Description is required'
								}
							]}
						>
							<TextArea rows={6} showCount maxLength={100} />
						</Form.Item>

						<Flex
							bgColor='#F4FFEF'
							justifyContent='space-between'
							pad='20px'
							marginTop='35px'
						>
							<p>KYC Status</p>
							<Switch defaultChecked={switched} onChange={handleSwitched} />
						</Flex>
						<Form.Item name='mail' valuePropName='checked'>
							<Flex justifyContent='space-between' pad='10px' marginTop='15px'>
								<Checkbox>Notify Merchant</Checkbox>
							</Flex>
						</Form.Item>
					</Form>
				</Styles>
			</ModalFrame>
		</>
	);
};

export default AddNewMerchant;
