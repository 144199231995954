import toastr from "toastr";
import { toast } from "react-toastify";

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  preventDuplicates: true,
  positionClass: "toast-top-right",
  onclick: () => true,
  showDuration: "2000",
  hideDuration: "5000",
  timeOut: "5000",
  extendedTimeOut: "500",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export const ToastMessage = (header, message) => {
  let type = "";
  switch (header) {
    case "SUCCESS":
      type = "success";
      break;

    case "INFO":
      type = "info";
      break;

    case "ERROR":
      type = "error";
      break;

    case "WARN":
      type = "warning";
      break;

    default:
      type = "info";
      break;
  }

  toast[type](message);
};

export const type = {
  SUCCESS: "SUCCESS",
  INFO: "INFO",
  ERROR: "ERROR",
  WARN: "WARN",
};
