import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Container, Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import {
	addMerchantFees,
	deleteMerchantFees,
	editMerchantFees,
	merchantFees
} from '../../../../../services/Merchants.service';
import { getMerchantCurrencies } from '../../../../../services/Others.service';
import { merchantPaymentMethod } from '../../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import { FormLayout } from './form';
import { Styles } from './styles';
import CustomTable from './table';

const { Option } = Select;

const Index = () => {
	const queryClient = useQueryClient();

	const [visible, setVisible] = useState(false);
	const [visibleEdit, setVisibleEdit] = useState(false);
	const [visibleDelete, setVisibleDelete] = useState(false);
	const [initialEditValues, setInitialEditValues] = React.useState({});
	const [feeId, setFeeId] = React.useState(null);
	const [action, setAction] = useState('add');

	const [method, setMethod] = useState(null);
	const [currencyType, setCurrencyType] = useState('');
	const [initialCurrency, setInitialCurrency] = useState('');
	const [selectFields, setSelectFields] = useState(null);
	const [selectFieldsAdd, setSelectFieldsAdd] = useState(null);
	const [addPUser, setAddPUser] = useState('');
	let params = useParams();

	const result = useQuery(
		'merchantFees',
		async () => await merchantFees(params.merchant)
	);

	const merchantCurrency = useQuery(
		'getMerchantCurrencies',
		async () => await getMerchantCurrencies(params.merchant)
	);

	const PaymentMethods = useQuery(
		'merchantPaymentMethod',
		async () => await merchantPaymentMethod(params.merchant)
	);

	const feeMutation = useMutation(
		async (data) => {
			await addMerchantFees(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('merchantFees');
				ToastMessage(type.SUCCESS, 'Fee successfully added');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const editFeeMutation = useMutation(
		async (data) => {
			await editMerchantFees(params.merchant, feeId, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('merchantFees');
				ToastMessage(type.SUCCESS, 'Fee successfully updated');
				setTimeout(() => setVisibleEdit(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const deleteMutation = useMutation(
		async () => {
			await deleteMerchantFees(feeId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('merchantFees');
				ToastMessage(type.SUCCESS, 'fee sucessfully deleted');
				setTimeout(() => setVisibleDelete(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const showFee = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setVisibleDelete(false);
		setVisibleEdit(false);
		setAction('add');
		setFeeId(null);
		setSelectFieldsAdd(null);
	};

	const showDelete = (id) => {
		setFeeId(id);
		setVisibleDelete(true);
	};

	const handleDelete = () => {
		deleteMutation.mutate(feeId);
	};

	const handleEdit = (id) => {
		setAction('edit');
		setFeeId(id);

		setTimeout(() => setVisibleEdit(true), 200);
		result?.data?.data?.data?.find((item) => {
			if (item.id === id) {
				setInitialEditValues(item);
				setMethod(item?.method);
			}
		});

		const code = result?.data?.data?.data?.find(
			(item) => item.id === id && item
		);

		merchantCurrency?.data?.data?.data?.filter((c) => {
			if (c?.code === code.currency) {
				setInitialCurrency(c?.type);
				setCurrencyType(c?.type);
			}
		});
	};

	const handleInputValueChange = (id) => {
		PaymentMethods?.data?.data?.data?.find((item) => {
			if (item.id === id) {
				setMethod(item?.method);
			}
		});
		setSelectFields(
			PaymentMethods?.data?.data?.data?.find((item) => item.id === id)?.fields
		);
	};

	const handelSubmitFee = async (values) => {
		const addField =
			values?.fieldsetting === undefined
				? []
				: values?.fieldsetting === 1
				? []
				: selectFieldsAdd;

		let payloadAdd = {
			currency: values?.currency,
			method: method,
			method_id: +values?.method_id,
			fields: addField,
			p_user: addPUser,
			fee_type: values.fee_type,
			fee: +values.fee,
			min_fee: values?.min === undefined ? null : +values?.min,
			max_fee: values?.max === undefined ? null : +values?.max,
			use_global_fees:
				values.use_global_fees === undefined ? false : values.use_global_fees,
			use_global_fields:
				values.use_global_fields === undefined
					? false
					: values.use_global_fields,
			chain: currencyType !== 'CRYPTO' ? null : values.chain
		};

		let payloadEdit = {
			currency: values?.currency,
			method: method,
			method_id: values?.method_id,
			fee_type: values.fee_type,
			fee: +values.fee,
			min_fee: values?.min === undefined ? null : +values?.min,
			max_fee: values?.max === undefined ? null : +values?.max,
			fields: values?.fields,
			p_user: values?.p_user,
			use_global_fees:
				values.use_global_fees === undefined ? false : values.use_global_fees,
			use_global_fields:
				values.use_global_fields === undefined
					? false
					: values.use_global_fields,
			chain: currencyType !== 'CRYPTO' ? null : values.chain
		};

		if (action === 'edit') {
			editFeeMutation.mutate(payloadEdit);
		} else {
			feeMutation.mutate(payloadAdd);
		}
	};

	if (result.isLoading) {
		return <Loader />;
	}
	if (result.isError) {
		return <span>Error: {result?.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='baseline'
						style={{
							marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Input
							placeholder='Search'
							size='large'
							style={{
								width: '271px'
							}}
						/>
						<Flex width='30%'>
							<Button
								Styles
								text={'Add new fees'}
								color={'#1A2CCE'}
								size='md'
								type='button'
								onClick={showFee}
							/>

							<Input.Group compact>
								<Input
									placeholder='Sort By'
									disabled
									style={{
										width: '30%'
									}}
								/>
								<Select
									defaultValue='Payment method'
									style={{
										height: '46px !important'
									}}
								>
									<Option
										style={{
											height: '46px !important'
										}}
										value='Payment method'
									>
										Payment method
									</Option>
								</Select>
							</Input.Group>
						</Flex>
					</Flex>
					<CustomTable
						theadBkColor='#F1F4F8'
						tableHead={['Currency', 'Method', 'Fee type', 'Fee amount', '', '']}
						tableBody={result?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						pageSize={5}
						showDelete={showDelete}
						handleEdit={handleEdit}
						type='deposit'
						key={'deposit'}
					/>
				</Container>
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : visibleEdit}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						form='MerchantFee'
						loading={feeMutation.isLoading || editFeeMutation.isLoading}
					/>
				]}
			>
				<FormLayout
					action={action}
					currencies={merchantCurrency?.data?.data?.data}
					initialEditValues={initialEditValues}
					PaymentMethods={PaymentMethods?.data?.data?.data}
					fieldsSelected={selectFields}
					handelSubmitFee={handelSubmitFee}
					handleInputValueChange={handleInputValueChange}
					currencyType={setCurrencyType}
					initialCurrency={initialCurrency}
					setAddPUser={setAddPUser}
					setSelectFieldsAdd={setSelectFieldsAdd}
				/>
			</ModalFrame>
			<ModalFrame
				visible={visibleDelete}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Delete'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='button'
						onClick={handleDelete}
						loading={deleteMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Delete Fee?</h3>
				<p>
					By deleting this fee, all transactions via
					<br /> this means would no longer function
				</p>
			</ModalFrame>
		</>
	);
};

export default Index;
