import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { Flex } from '../box';
import Button from '../button';
import { Styles } from './styles';

const CustomDropdown = ({
	showModal,
	actionTaken,
	handleToggle,
	selectedIndex,
	handleSavedAccount,
	handleWalletAddress,
	source
}) => {
	return (
		<>
			<Styles>
				{source === 'fx' && (
					<Dropdown
						// .Button
						// onClick={
						//   selectedIndex?.length > 0
						//     ? showModal
						//     : () => ToastMessage(type.INFO, "Please select transactions")
						// }
						sizes='large'
						overlay={
							<Menu>
								<Menu.Item key='0' onClick={handleSavedAccount}>
									New saved account
								</Menu.Item>
								<Menu.Item key='1' onClick={handleWalletAddress}>
									New wallet address
								</Menu.Item>
							</Menu>
						}
						placement='bottomLeft'
						trigger={['click']}
						// icon={<DownOutlined />}
					>
						<Button
							Styles
							text={'Create New'}
							bgColor='#1a2cce'
							color={'#fff'}
							size='sm'
							type='button'
						/>
					</Dropdown>
				)}
				{source === '' && (
					<Dropdown.Button
						// onClick={
						//   selectedIndex?.length > 0
						//     ? showModal
						//     : () => ToastMessage(type.INFO, "Please select transactions")
						// }
						sizes='large'
						overlay={
							<Menu
								style={{
									width: 138
								}}
							>
								<Menu.Item key='0' onClick={handleSavedAccount}>
									New deposit
								</Menu.Item>
								<Menu.Item key='1' onClick={handleWalletAddress}>
									Make a payout
								</Menu.Item>
							</Menu>
						}
						placement='bottomLeft'
						trigger={['click']}
						icon={<DownOutlined />}
					>
						Create New
					</Dropdown.Button>
				)}
			</Styles>
		</>
	);
};

export default CustomDropdown;
