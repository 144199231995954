import apiClient from './axios';

const EndPoints = {
	getBankName: `/misc/banks`,
	getAllCurrencyPair: `/admin/settings/pairs`,
	getSingleCurrencyPair: `/admin/settings/currencies/single-pairs`,
	getCountries: `/misc/countries`,
	getFxCountries: `/misc/fx/countries`,
	getBanksGHS: `/misc/banks?country=GH&provider=finc`,
	getMobileGHS: `/misc/mobile-money?country=GH`,
	getBanksKES: `/misc/mobile-money?country=KE`,
	getMerchantCurrencies(id) {
		return `/admin/settings/currencies?merchantId=${id}`;
	},
	freezeAmount(merchant) {
		return `/admin/balance/freeze/${merchant}`;
	},
	unfreezeAmount(merchant) {
		return `/admin/balance/unfreeze/${merchant}`;
	},
	getMerchant(search) {
		// return `/admin/merchants?order=DESC&page=1&take=17`;
		return `/admin/merchants/search?search=${search}`;
		// return `/admin/merchants/${search}`;
	}
};

export const getSingleCurrencyPair = async () => {
	const res = await apiClient.get(EndPoints.getSingleCurrencyPair);
	return res;
};

export const getAllCurrencyPair = async () => {
	const res = await apiClient.get(EndPoints.getAllCurrencyPair);
	return res;
};

export const getBankName = async () => {
	const res = await apiClient.get(EndPoints.getBankName);
	return res;
};

export const getBanksGHS = async () => {
	const res = await apiClient.get(EndPoints.getBanksGHS);
	return res;
};
export const getMobileGHS = async () => {
	const res = await apiClient.get(EndPoints.getMobileGHS);
	return res;
};
export const getBanksKES = async () => {
	const res = await apiClient.get(EndPoints.getBanksKES);
	return res;
};

export const getCountries = async () => {
	const res = await apiClient.get(EndPoints.getCountries);
	return res;
};

export const getFxCountries = async () => {
	const res = await apiClient.get(EndPoints.getFxCountries);
	return res;
};

export const getMerchantCurrencies = async (id) => {
	const res = await apiClient.get(EndPoints.getMerchantCurrencies(id));
	return res;
};

export const getMerchantSearch = async (search) => {
	const res = await apiClient.get(EndPoints.getMerchant(search));
	return res;
};

export const addFreezeAmount = async (id, payload) => {
	const res = await apiClient.post(EndPoints.freezeAmount(id), payload);
	return res;
};
export const unFreezeAmount = async (id, payload) => {
	const res = await apiClient.post(EndPoints.unfreezeAmount(id), payload);
	return res;
};
