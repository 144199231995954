import styled from "styled-components";
// import { Flex } from '../../../../components/box';

export const Styles = styled.div`
  .rate,
  .source {
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #1a1f36;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #636363;
    }
    Input {
      // width: 212px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      // border-radius: 5px;
    }

    padding-bottom: 65px;
  }
  .source {
    div:last-child {
      width: 235px;
    }
    .ant-select .ant-select-selector {
      // width: 212px;
      height: 46px !important;
      border-radius: 5px 0 0 5px;
    }

    .ant-select .ant-select-selector .ant-select-selection-item {
      line-height: 40px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1a1f36;
  }

  hr {
    border: 0.3px solid #c4c4c4;
    margin-bottom: 40px;
  }
  .signatures {
    padding-bottom: 20px;
    h3 {
      width: 30%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #1a1f36;

      // :nth-child(2) {
      // 	text-align: left;
      // 	background: red;
      // 	// padding-left: 80px;
      // }
    }

    Button {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .btn {
    .add-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      padding: 0;
      text-align: right;
      text-decoration-line: underline;
    }

    .save {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 15px 55px;
    }
  }
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .fiter_container {
    gap: 30px;
    justify-content: space-between;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    /* Normal-side-panel text */

    color: #1a1f36;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px 10px;
    .fiter_container {
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      flex-wrap: wrap;
      gap: 15px 5px;
      /* border: 1px solid red; */
    }
    .input_container {
      width: 100%;
    }
  }
`;
