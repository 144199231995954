import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { generateID } from '../../../utils/generateID';
import Pagination from '../../paginator';
import { CustomTableMain, Paginator, Td } from './styles';

import { Button } from 'antd';
import { OverFlowScrollBar } from '../../overflowScroll/styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handleApprove,
	fiat,
	handleReject,
	setSelectedIndex,
	selectedIndex,
	setActionTaken,
	actionTaken,
	setVisible,
	handlePageChange,
	meta
}) => {
	let path = useLocation().pathname;

	const [actionValue, setActionValue] = useState('');
	const [fileExport, setFileExport] = useState('Select');
	const [marketRate, setMarketRate] = useState('0');

	const handleSelectedItem = (e, index) => {
		const prevId = selectedIndex.filter((id) => id === index);
		if (e.target.checked && index !== prevId) {
			setSelectedIndex((prevSelectedIndex) => [...prevSelectedIndex, index]);
		} else {
			setSelectedIndex(selectedIndex.filter((item) => item !== index));
		}
	};

	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.id}>
				<Td tbodyColor={data?.status === true && '#C00000'}>
					<span>{data?.name}</span>
				</Td>
				<Td tbodyColor={data?.status === true && '#C00000'}>
					<span>{data?.balance}</span>
				</Td>
				<Td tbodyColor={data?.status !== true && '#BCBDDC'}>
					<Button
						disabled={data?.status !== true}
						// {...(data?.status !== 'low' && disabled)}
					>
						{data?.status !== true ? 'Active' : 'Activate'}
					</Button>
				</Td>
			</tr>
		);
	};
	return (
		<CustomTableMain
			theadColor={theadColor}
			theadBkColor={theadBkColor}
			bottomGap={bottomGap}
			tbodyColor={tbodyColor}
			rowHovColor={rowHovColor}
			paginator={paginator}
		>
			<OverFlowScrollBar className='container'>
				<table>
					<thead>
						<tr>
							{tableHead?.map((head, i) => (
								<th key={generateID(11)}>
									{head === '' && i === 0 && <input type={'checkbox'} />}
									{head.toUpperCase()}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{tableBody?.map((data, idx) => returnTableRow(data, idx))}
					</tbody>
				</table>
			</OverFlowScrollBar>

			<Paginator className='paginator' paginator={paginator}>
				<Pagination meta={meta} handlePageChange={handlePageChange} />
			</Paginator>
		</CustomTableMain>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;
