import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	background-color: #eef2f7;
	padding: 25px;
	border-radius: 10px;
	& > div > h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		color: #1a1f36;
	}
	.btn_container {
		> button {
			background: #e2e9ff;
			border-radius: 4px;
			color: #394dff;
			text-transform: uppercase;
			&:focus {
				border: 1px solid #929ae0;
			}
			&:hover {
				border: 1px solid #929ae0;
			}
		}
	}
	.chart_container {
		width: 100%;
		height: 300px;
		padding-top: 20px;
	}
`;

export const Card = styled.div`
	padding: 20px 0px;
	width: 450px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.container {
		span:first-child {
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			text-transform: uppercase;
			color: #5f5f5f;
			padding-bottom: 10px;
		}
	}
	.viewall {
		span:first-child {
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
			color: #000107;
			padding-bottom: 20px;
		}
		span:last-child {
			> svg {
				margin-right: 3px;
			}
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: #4d4d4d;
		}
	}
`;
