import styled from 'styled-components';

export const CustomTableMain = styled.section`
	.container {
		// height: 450px;
		overflow: auto;
		/* @media (max-width: 600px) {
      height: 350px;
    } */
	}

	background: #fff;
	padding-bottom: ${({ paginator }) => !paginator && '20px'};
	width: 100%;
	table {
		background: #fff;
		border-collapse: collapse;
		text-align: start;
		width: 100%;
		overflow: auto;
		@media (max-width: 600px) {
			width: 700px;
		}
		thead {
			// background: ${({ theadBkColor }) => theadBkColor || '#F1F4F8'};
			position: sticky;
			top: 0;
			z-index: 1;
			tr {
				th {
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 21px;
					text-align: left;
					color: #061f39;
					// :nth-child(2) {
					// 	text-align: center;
					// }
					// text-align: start;
					// color: #000a62;
					// font-size: 12px;
					padding: 1.2rem 0;
					// /* min-width: 160px; */
				}
			}
		}
		tbody {
			tr {
				background: white;
				cursor: pointer;
				border: none;
				overflow: auto;
				border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
				/* border-bottom: ${({ bottomGap }) =>
					bottomGap ? `${bottomGap} solid   #fff` : '5px solid  #fff '}; */
				/*&:hover {
					box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.27);
					background: ${({ rowHovColor }) => rowHovColor || '#fff7ee !important'};
				}
				.readAll {
					color: #17a2b8;
					cursor: pointer;
					font-size: 0.75rem;
				}
				.empty {
					width: max-content;
					.showMoreModal {
						bottom: -2px;
						top: unset;
					}
				}
				 &:nth-child(2n) {
        			  background: #d2ccc626;
       			 } */
			}
		}
	}
`;

export const Td = styled.td`
	padding: 1.2rem 0;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #1a1f36;
	text-align: left;

	:nth-child(2) {
		padding: 1.2rem 1.8rem;
		// text-align: center;
	}
`;

export const CustomTd = styled.td`
	text-align: right;
	padding: 1.2rem 0;

	Button {
		text-align: center !important;
		padding: 0 0.5px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		/* identical to box height */

		text-decoration-line: underline;
		:nth-child(2) {
			text-align: right;
			margin-left: -80px;
		}
	}
`;

export const Paginator = styled('div')`
	background: white;
	padding: 1em 5px;
	display: ${({ paginator }) => (paginator ? 'block' : 'none')};
	ul {
		justify-content: flex-end;
	}
`;
