import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Container, Flex } from '../../../../../components/box';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import Search from '../../../../../components/search';
import { PendingApprovalsTableHead } from '../../../../../utils/constants';
import CustomDropdown from '../../customdropdown/CustomDropdown';
import CustomTable from '../../table';
import { Wrapper } from '../styles';

import { Form, Input } from 'antd';
import { Loader } from '../../../../../components/loader';
import { getPendingApprovalsFiat } from '../../../../../services/Approval.service';
import { addSettlementTime } from '../../../../../services/Transactions.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import {
	ApproveTransactionSummary,
	QRScreen
} from '../../approvalTransactionSummary/ApproveTransactionSummary';

const Index = () => {
	const [visible, setVisible] = useState(false);
	const [qrCode, setQrCode] = useState(false);
	const [actionTaken, setActionTaken] = useState('approve');
	const [id, setId] = useState(null);
	const [status, setStatus] = useState('approve');
	const [selectedIndex, setSelectedIndex] = useState([]);
	const [settlementVisible, setSettlementVisible] = React.useState(false);
	const [input, setInput] = useState('');

	const [filter, setFilter] = useState({ page: 1 });
	const queryClient = useQueryClient();
	const [form] = Form.useForm();

	const approvalsFiat = useQuery(
		['getAllPendingApprovals'],
		async () => await getPendingApprovalsFiat(filter)
	);

	const mutation = useMutation(
		async (data) => {
			await addSettlementTime(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getPendingApprovalsSwap');
				ToastMessage(type.SUCCESS, 'settlement time has been added');
				setTimeout(() => {
					setSettlementVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, ref: value });

		setTimeout(() => approvalsFiat.refetch());
	};

	const showModalBulk = (e) => {
		// console.log(e);
		setVisible(true);
		setId(selectedIndex);
	};
	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
		setSelectedIndex([]);
	};

	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleOk = () => {
		setTimeout(() => {
			setVisible(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleCancelqr = () => {
		setQrCode(false);
	};

	const handleToggle = (value) => {
		console.log(value);
		setStatus(value);
		setActionTaken(value);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => approvalsFiat.refetch());
	};

	const handleAddSettlementTime = (e) => {
		setSettlementVisible(true);
		setId(e);
	};

	const handleSubmit = (values) => {
		const payload = { time: Number(values.time) };
		mutation.mutate(payload);
	};
	if (approvalsFiat.isLoading) {
		return <Loader />;
	}

	if (approvalsFiat.status === 'error') {
		return <span>Error: {approvalsFiat.error.message}</span>;
	}

	// console.log(selectedIndex);
	return (
		<>
			<Wrapper>
				<Flex
					justifyContent='space-between'
					pad='20px 0'
					className='input_container'
				>
					<Container width={'30%'}>
						<Search
							placeholder={'Search reference no.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>

					<CustomDropdown
						showModal={showModalBulk}
						handleToggle={handleToggle}
						getApprovalsFiat={getPendingApprovalsFiat}
						setVisible={setVisible}
						actionTaken={actionTaken}
						selectedIndex={selectedIndex}
					/>
				</Flex>
				<CustomTable
					theadBkColor='#F1F4F8'
					bottomGap='10px'
					tableHead={PendingApprovalsTableHead}
					tableBody={approvalsFiat?.data?.data?.data?.data}
					rowHovColor='#d2ccc626'
					theadColor='#000A62'
					tbodyColor='#141414'
					pageSize={10}
					paginator
					handleApprove={showModal}
					handleReject={showModal}
					fiat
					setSelectedIndex={setSelectedIndex}
					selectedIndex={selectedIndex}
					actionTaken={actionTaken}
					setActionTaken={setActionTaken}
					setVisible={setVisible}
					meta={approvalsFiat?.data?.data?.data?.meta}
					handlePageChange={handlePageChange}
					handleAddSettlementTime={handleAddSettlementTime}
					// isCheckAll={isCheckAll}
					// handleSelectAll={showModalBulk}
				/>
			</Wrapper>

			<ModalFrame
				visible={visible}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					approvalsFiat={approvalsFiat?.data?.data?.data?.data}
					id={id}
					handleQrScreen={handleqrCode}
					status={status}
					setVisible={setVisible}
					// actionTaken={actionTaken}
					selectedIndex={selectedIndex}
				/>
			</ModalFrame>
			<ModalFrame
				visible={qrCode}
				handleOk={handleOk}
				handleCancel={handleCancelqr}
				footer={null}
			>
				<QRScreen />
			</ModalFrame>
			<ModalFrame
				visible={settlementVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<FormLayout
					form={form}
					handleSubmit={handleSubmit}
					// mutation={mutation}
				/>
			</ModalFrame>
		</>
	);
};

export default Index;

const FormLayout = ({ form, handleSubmit }) => {
	// const handleSubmit = async (values) => {
	// 	const payload = { time: Number(values.time) };
	// 	console.log(id, payload);
	// 	try {
	// 		await mutation.mutate(payload);
	// 	} catch (error) {
	// 		ToastMessage(type.ERROR, error.response.data.message);
	// 	}
	// };

	return (
		<>
			<Form
				layout={'vertical'}
				form={form}
				initialValues={{
					layout: 'vertical',
					size: 'large'
				}}
				size='large'
				onFinish={handleSubmit}
				id='addTime'
			>
				<Form.Item
					label='Add time'
					style={{ marginBottom: '0' }}
					name={'time'}
					rules={[{ required: true, message: 'Time is Required' }]}
				>
					<Input
						placeholder='input placeholder'
						type='number'
						addonAfter='MINS'
						width={'100%'}
					/>
				</Form.Item>
			</Form>
		</>
	);
};
