import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	AcceptTransactionIcon,
	AddSettlementIcon,
	RejectTransactionIcon,
	UserAvatar,
	ViewTransactionIcon
} from '../../../../assets';
import BulkAction from '../../../../components/bulkaction/BulkAction';
import { EmptyState } from '../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import Pagination from '../../../../components/paginator';
import PopOver from '../../../../components/popover/PopOver';
import { generateID } from '../../../../utils/generateID';
import { formattedDate, formattedTime } from '../../../../utils/helpers';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handleApprove,
	fiat,
	handleReject,
	setSelectedIndex,
	selectedIndex,
	setActionTaken,
	actionTaken,
	setVisible,
	handlePageChange,
	meta,
	handleAddSettlementTime
	// handleSelectAll
	// isCheckAll
}) => {
	let path = useLocation().pathname;

	const [actionValue, setActionValue] = useState('');
	const [fileExport, setFileExport] = useState('Select');
	const [marketRate, setMarketRate] = useState('0');
	const [isCheckAll, setIsCheckAll] = useState(false);
	// const { handleSelectBankName } = useBankName();

	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setSelectedIndex(tableBody.map((li) => li.id));
	};

	const handleSelectedItem = (e, index) => {
		const prevId = selectedIndex.filter((id) => id === index);
		if (e.target.checked && index !== prevId) {
			setSelectedIndex((prevSelectedIndex) => [...prevSelectedIndex, index]);
		} else {
			setSelectedIndex(selectedIndex.filter((item) => item !== index));
		}
	};

	const handleSelectedClear = () => {
		setSelectedIndex([]);
		setIsCheckAll(false);
	};

	const handleSetTransaction = (e) => {
		setActionTaken(e.target.value);
	};

	const handleExport = (e) => {
		setFileExport(e);
	};

	const handleChangeRate = (e) => {
		setMarketRate(e);
	};

	const handleAction = (e) => {
		setActionValue(e.target.value);
	};

	const fiatPending = '/dashboard/approvals/pending';
	const swap = '/dashboard/approvals/pending/swap';
	const hideMessage = `/dashboard/approvals/rejected/crypto`;
	// const cryptoPending = '/dashboard/approvals/pending/crypto';

	const returnTableRow = (data, i) => {
		const pathLocator =
			data?.type === 'WITHDRAWAL'
				? 'withdraws'
				: data?.type === 'SWAP'
				? 'swap'
				: 'deposits';

		return (
			<tr key={data?.id}>
				{/* {path === fiatPending && (
					<Td>
						<div>
							<input
								type={'checkbox'}
								onChange={(e) => handleSelectedItem(e, data?.id)}
								key={i}
								checked={selectedIndex?.includes(data?.id) ? true : false}
							/>
						</div>
					</Td>
				)}
				{path === swap && (
					<Td>
						<div>
							<input
								type={'checkbox'}
								onChange={(e) => handleSelectedItem(e, data?.id)}
								key={i}
								checked={selectedIndex?.includes(data?.id) ? true : false}
							/>
						</div>
					</Td>
				)} */}
				<Td>
					<div>
						<input
							type={'checkbox'}
							onChange={(e) => handleSelectedItem(e, data?.id)}
							key={i}
							checked={selectedIndex?.includes(data?.id) ? true : false}
						/>
					</div>
				</Td>

				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'column'
						}}
					>
						<strong style={{ color: '#1A1F36' }}>
							{formattedDate(data?.created_at)}
						</strong>
						<span style={{ color: '#6F6F6F' }}>
							{formattedTime(data?.created_at)}
						</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>
							{data?.from_merchant?.business_name}
						</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#767676' }}>{data?.currency}</span>
						<strong>{data?.amount.toLocaleString()}</strong>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>
							{data?._withdrawal_id !== '' ? data?.type + '-WTH' : data?.type}
						</span>
					</div>
				</Td>
				{hideMessage !== path ? (
					<Td>
						<div>
							<span>{data?._message}</span>
						</div>
					</Td>
				) : null}

				<CustomTd status={data?.status}>
					<div>
						<span>{data?.status}</span>
					</div>
				</CustomTd>

				<CustomTd>
					<PopOver key={i}>
						<Link to={`/dashboard/transactions/${pathLocator}/${data.id}`}>
							<div>
								<ViewTransactionIcon />
								<p>Transaction details</p>
							</div>
						</Link>

						<Link
							to={`/dashboard/merchants/${
								data?.to_merchantId !== null
									? data?.to_merchantId
									: data?.from_merchantId
							}`}
						>
							<div>
								<UserAvatar />
								<p>Merchant info</p>
							</div>
						</Link>
						{data?.type === 'SWAP' && (
							<Link to={'#'} onClick={() => handleAddSettlementTime(data?.id)}>
								<div>
									<AddSettlementIcon />
									<p>Add settlement time</p>
								</div>
							</Link>
						)}
						{data?.status === 'pending' && (
							<>
								<Link
									to={'#'}
									onClick={() => handleApprove(data?.id, 'approve')}
								>
									<div>
										<AcceptTransactionIcon />
										<p>Approve transaction</p>
									</div>
								</Link>
								<Link to={'#'} onClick={() => handleReject(data?.id, 'reject')}>
									<div>
										<RejectTransactionIcon />
										<p>Reject transaction</p>
									</div>
								</Link>
							</>
						)}
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && (
												<input
													type={'checkbox'}
													checked={isCheckAll}
													onChange={handleSelectAll}
												/>
											)}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					{selectedIndex?.length > 0 && (
						<BulkAction
							selectedItemCount={selectedIndex.length}
							handleSelectedClear={handleSelectedClear}
							transactionStatusValue={actionTaken}
							onChangeTransaction={handleSetTransaction}
							onChangeAction={handleAction}
							actionValue={actionValue}
							onChangeExport={handleExport}
							onChangeRate={handleChangeRate}
							marketRate={marketRate}
							fileExport={fileExport}
							setVisible={setVisible}
							approval
						/>
					)}

					<Paginator className='paginator' paginator={paginator}>
						<Pagination meta={meta} handlePageChange={handlePageChange} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;
