import { Form, Input } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Button from "../../../../../components/button";
import { merchantEditTeams } from "../../../../../services/Merchants.service";

import { ToastMessage, type } from "../../../../../utils/toastr";

const FormLayout = ({ setVisible, initialEditValues }) => {
  const queryClient = useQueryClient();
  let { merchant } = useParams();

  const editMutation = useMutation(
    async (data) => {
      await merchantEditTeams(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "Details updated successfully");
        setTimeout(() => setVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSubmit = (values) => {
    console.log(values);
    const payload = {
      ...values,
      user_id: initialEditValues?.id,
    };
    editMutation.mutate(payload);
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        layout={"vertical"}
        size="large"
        initialValues={{
          layout: "vertical",
          size: "large",
          full_name: initialEditValues?.full_name,
          email: initialEditValues?.email,
        }}
      >
        <Form.Item label="Full Name" name="full_name">
          <Input placeholder="input Full Name" type="text" />
        </Form.Item>
        <Form.Item label="Email Address" name="email">
          <Input placeholder="input Email Address" type="email" />
        </Form.Item>
        <Form.Item
          label="2FA Code"
          name="twoFactorAuthenticationCode"
          rules={[
            {
              required: true,
              message: "Please input 2FA!",
            },
          ]}
        >
          <Input placeholder="input 2FA" type="number" />
        </Form.Item>
        <Button
          Styles
          text={"Update Details"}
          bgColor={"#1A2CCE"}
          color="#FFFFFF"
          size="md"
          fullwidth
          type="submit"
          // key="submit"
          // form="newLiquidity"
          loading={editMutation.isLoading}
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            marginTop: "38px",
          }}
        />
      </Form>
    </>
  );
};

export { FormLayout };
