import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DeleteIcon, EditIcon } from '../../../../assets/';
import { Flex, Frame } from '../../../../components/box';
import { EmptyState } from '../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import Pagination from '../../../../components/paginator';
import { generateID } from '../../../../utils/generateID';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	handleEdit,
	handleDelete,
	editFee,
	handleEditFees,
	currencies
}) => {
	const [pageOfItems, setPageOfItems] = useState([]);

	const onChangePage = (items) => {
		setPageOfItems(items);
	};
	const returnTableRow = (data) => {
		// console.log(currencies, "currency");
		// console.log(data, "date");
		return (
			<tr key={data?.id}>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<Flex justifyItems='space-between'>
						<div style={{ marginRight: '15px' }}>
							{currencies?.map(
								(item) =>
									item?.code === data?.to_currency && (
										<div className='frame'>
											<Frame height={'30px'} width={'30px'}>
												<img src={item?.icon} alt='' />
											</Frame>
										</div>
									)
							)}
							{currencies?.map(
								(item) =>
									item?.code === data?.from_currency && (
										<div className='frame'>
											<Frame height={'30px'} width={'30px'}>
												<img src={item?.icon} alt='' />
											</Frame>
										</div>
									)
							)}
						</div>
						{data?.currency_pair}
					</Flex>
				</Td>
				<Td>
					<div>
						<span>{data?.base_asset}</span>
						<span>{data?.buying}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.quote_asset}</span>
						<span>{data?.selling}</span>
					</div>
				</Td>

				<Td>
					<div>
						<span>{data?.offset}</span>
					</div>
				</Td>

				<CustomTd>
					<div>
						<span />
					</div>
				</CustomTd>
				<CustomTd>
					<div onClick={() => handleEdit(data.id)}>
						<span className='icon_box'>
							<EditIcon />
						</span>
						<span>Edit</span>
					</div>
				</CustomTd>
				{editFee && (
					<CustomTd>
						<div onClick={() => handleEditFees(data.id)}>
							<span className='icon_box'>
								<EditIcon />
							</span>
							<span>Edit Fees</span>
						</div>
					</CustomTd>
				)}

				<CustomTd>
					<div onClick={() => handleDelete(data.id)}>
						<span className='icon_box'>
							<DeleteIcon color='#8D8EA2' />
						</span>
						<span> Delete</span>
					</div>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(12)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{paginator
									? pageOfItems?.map((data, idx) => returnTableRow(data))
									: tableBody?.map((data, idx) => returnTableRow(data))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination
							items={tableBody}
							pageSize={pageSize || 5}
							prevNext={prevNext || 'PrevNext'}
							onChangePage={onChangePage}
							firstLast={firstLast || null}
						/>
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
