import styled from 'styled-components';
import { Flex } from '../box';

export const Styles = styled(Flex)`
	align-items: baseline;
	justify-content: flex-start;
	margin-top: 18px;
	margin-bottom: 60px;
	// @media (max-width: 1440px) {
	// 	flex-direction: column;
	// 	.selection {
	// 		width: 30% !important;
	// 	}
	// }
	.selection {
		background: #e0ecfe;
		border-radius: 4px;
		padding: 14px 25px;
		width: fit-content;
		p {
			margin: 0 15px 0 0;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.05em;
			color: #00063f;
		}
		Button {
			padding: 0;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.05em;
			border: none;
			text-decoration: underline;
		}
	}

	.select-content {
		margin-left: 15px;
		// width: 220px;
		justify-content: center;
		align-items: center;

		padding: 14px 15px;
		background: #fafafa;
		border: 0.7px solid #c5c5c5;
		box-sizing: border-box;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
		border-radius: 3px;

		span {
			margin-right: 15px;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #2b2b2b;
		}

		.formatText {
			width: fit-content;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.apply-action {
		margin-left: 30px;
		border: 0.7px solid #6978ff;
		box-sizing: border-box;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
		border-radius: 3px;
		text-decoration: none;
	}
`;

export const PopDetails = styled.div`
	padding: 20px;
	width: ${({ width }) => width || 'fit-content'};
	cursor: pointer;

	.ant-radio-wrapper {
		.ant-radio {
			margin-right: 10px;
		}
		span {
			font-style: normal;
			font-style: normal;
			font-weight: 400;
			font-size: 11.9412px;
			line-height: 14px;
			/* identical to box height */

			text-align: center;
			letter-spacing: 0.05em;
			margin-left: 2px;
			// color: #434343;
		}
	}

	#status {
		.ant-space-item {
		}
		.ant-space-item:nth-child(1) {
			.ant-radio-wrapper {
				.ant-radio + span {
					text-align: center;
					padding: 5px 20px !important;
					border-radius: 2.55882px !important;
					background: #fff2d9 !important;
					color: #9c5502 !important;
				}
			}
		}
		.ant-space-item:nth-child(2) {
			.ant-radio-wrapper {
				.ant-radio + span {
					text-align: center;
					padding: 5px 15px !important;
					border-radius: 2.55882px !important;
					background: #d9edff !important;
					color: #05029c !important;
				}
			}
		}
		.ant-space-item:nth-child(3) {
			.ant-radio + span {
				padding: 5px 20px;
				color: #027200;
				background: #c8ffc7;
				border-radius: 2.55882px;
			}
		}
		.ant-space-item:nth-child(4) {
			.ant-radio + span {
				padding: 5px 27px;
				color: #721400;
				background: #ffe2d9;
				border-radius: 2.55882px;
			}
		}
	}

	.picker_holder {
		background: #ffffff;
		border: 0.7px solid #cfcfcf;
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
		border-radius: 3px;
		height: 44px;

		padding: 10px;
	}

	.ant-picker {
		padding: 0 !important;
		.ant-picker-input input {
			text-align: center;
		}
	}
`;
export const SelectContainer = styled(Flex)`
	// width: 100%;
	cursor: pointer;
	// width: 150px;

	// width: 150px;
	// justify-content: space-between;
	// gap: 20px;

	@media (max-width: 1200px) {
		.select-content {
			// align-self: flex-end;
			// width: 0;
			// justify-content: flex-end !important;
		}
	}

	align-items: center;
	background: #ffffff;
	// border: 0.7px solid #dfdfdf;
	box-sizing: border-box;
	// box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
	p {
		font-style: normal;
		font-weight: 400;
		font-size: 11.9412px;
		line-height: 14px;
		// padding: 8px 18px;
		margin: 0;
	}
	// .default {
	// 	color: #000000;
	// 	// margin-right: 10px;
	// 	background: #ffffff;
	// 	border-radius: 3.41176px;
	// 	padding: 8px 18px;
	// }

	.pending {
		background: #fff2d9;
		color: #9c5502;
		padding: 8px 12px;
	}
	.approved {
		background: #d9edff;
		color: #05029c;
		padding: 8px 8px;
	}
	.success {
		color: #027200;
		background: #c8ffc7;
		padding: 8px 11px;
	}

	.failed {
		color: #721400;
		background: #ffe2d9;
		padding: 8px 18px;
	}
`;
