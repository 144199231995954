import React from "react";
import { Skeleton } from "antd";
import { Styles, Card } from "./styles";
import { Flex, Grid } from "../../../../../../components/box";

const Index = ({
  source,
  amountSettledSummary,
  amountUsedSummary,
  requestSummary,
  loading,
}) => {
  return (
    <Styles>
      <Grid
        justifyContent={"space-around"}
        gap="10px"
        gridCol="repeat(auto-fit, minmax(200px, 1fr))"
        bgColor="#fff"
        pad="30px 20px"
        border="1px solid rgba(223, 223, 223, 0.39)"
        borderRadius="6px"
      >
        <Card>
          <Flex
            className="container"
            alignItem={"baseline"}
            justifyContent={"space-between"}
            flexDir="row"
          >
            <span>Requests</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>{requestSummary.result}</span>
            )}
          </Flex>
        </Card>
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>PAYOUT</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span> {amountUsedSummary?.result}</span>
            )}
          </Flex>
        </Card>
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>PENDING SETTLEMENTS </span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>
                {amountUsedSummary?.result - amountSettledSummary?.result}
              </span>
            )}
          </Flex>
        </Card>
      </Grid>
    </Styles>
  );
};

export default Index;
