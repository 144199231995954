import { Checkbox, Divider, Form, Input, Radio, Select } from 'antd';
import React, { useState } from 'react';
import { Flex } from '../../../../../components/box';
import CustomAccordion from '../../../../../components/customAccordion/CustomAccordion';
import { RateStyle } from './styles';

function FormLayout({
	handelSubmitFee,
	action,
	initialEditValues,
	currencies,
	PaymentMethods,
	handleInputValueChange,
	fieldsSelected,
	currencyType,
	initialCurrency,
	setAddPUser,
	setSelectFieldsAdd
}) {
	const [currency, setCurrency] = useState('NGN');
	const [selectFields, setSelectFields] = useState([]);
	const [selectPUser, setSelectPUser] = useState('');
	const [curType, setCurType] = React.useState(initialCurrency);
	const [minMaxToggle, setMinMaxToggle] = useState(
		action === 'edit'
			? initialEditValues?.fee_type === 'PERCENTAGE'
				? 'PERCENTAGE'
				: ''
			: ''
	);
	const handleInputChange = (value) => {
		setCurrency(value);

		currencies?.filter((c) => {
			if (c.code === value) {
				setCurType(c.type);
				currencyType(c.type);
			}
		});
	};
	const handlePUserSelect = (e) => {
		setSelectPUser(e.target.value);

		setAddPUser(e.target.value);
	};

	const [value, setValue] = useState(0);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const handleChange = (event, index, key) => {
		const values = [...selectFields];
		const updatedValue = key;
		values[index][updatedValue] = event;
		setSelectFieldsAdd(values);
		setSelectFields(values);
	};

	return (
		<Form
			id='MerchantFee'
			onFinish={handelSubmitFee}
			layout={'vertical'}
			initialValues={{
				currency: action === 'edit' ? initialEditValues?.currency : null,
				fee_type: action === 'edit' ? initialEditValues?.fee_type : null,
				fee: action === 'edit' ? initialEditValues?.fee : null,
				fields: action === 'edit' ? initialEditValues?.fields : null,
				method_id: action === 'edit' ? initialEditValues?.method_data.id : null,
				method: action === 'edit' ? initialEditValues?.method : null,
				min: action === 'edit' ? initialEditValues?.min_fee : null,
				max: action === 'edit' ? initialEditValues?.max_fee : null,
				p_user:
					action === 'edit' ? initialEditValues?.method_data?.p_user : null,
				use_zero_value:
					action === 'edit' ? initialEditValues?.use_zero_value : null,
				chain: action === 'edit' ? initialEditValues?.chain : null
			}}
		>
			<>
				<h3 style={{ textAlign: 'left' }}>
					{action === 'add'
						? 'Custom Fees and Payment Method'
						: 'Edit Custom Fees and Payment Method'}
				</h3>
				<RateStyle>
					<Form.Item label='Currency' name='currency'>
						<Select
							placeholder='Select currency'
							defaultValue='NGN'
							onChange={(value) => handleInputChange(value)}
						>
							{currencies?.map((item) => (
								<Select.Option value={item.code} key={item?.code}>
									{item.code}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label='Payment Method' name='method_id'>
						<Select
							onChange={(value) => {
								const m_id = Number(value);
								let fieldsValue = PaymentMethods?.find(({ id }) => id === m_id);
								setSelectFieldsAdd(fieldsValue?.fields);
								setSelectFields(fieldsValue?.fields);
								setSelectPUser(fieldsValue?.p_user?.toLocaleString());
								setAddPUser(fieldsValue?.p_user?.toLocaleString());
								handleInputValueChange(m_id);
								setValue(1);
							}}
							placeholder='Select Payment Method'
							showSearch
						>
							{PaymentMethods?.map(
								(item) =>
									item?.currencies?.includes(currency) && (
										<Select.Option
											children={item?.method}
											value={item?.id}
											key={item?.id}
											// value={`${[item?.method, item?.id]}`}
										>
											{item.name}:{item?.provider}
										</Select.Option>
									)
							)}
						</Select>
					</Form.Item>

					{action === 'edit' ? (
						<Form.Item
							label='User'
							name='p_user'
							style={{
								width: '100%'
								// , marginBottom: 0
							}}
						>
							<Input />
						</Form.Item>
					) : (
						<Flex
							flexDir='column'
							alignItems='start'
							style={{
								marginBottom: '24px'
							}}
						>
							<p
								style={{
									marginBottom: '8px'
								}}
							>
								User
							</p>
							<Input
								value={selectPUser?.toLocaleString()}
								onChange={handlePUserSelect}
							/>
						</Flex>
					)}

					<CustomAccordion title='Field Config'>
						<Flex flexDir='column' justifyContent='flex-start'>
							{action !== 'edit' && (
								<>
									<Form.Item label='Fields Settings' name='fieldsetting'>
										<Radio.Group onChange={onChange} value={value}>
											<Radio value={1}>Use Default Setting</Radio>
											<Radio value={2}>Use Custom Setting</Radio>
										</Radio.Group>
									</Form.Item>
									{/* {(value === 2 || action === 'edit') && (
								<Form.Item
								label='User'
								name='p_user'
									style={{ width: '100%', marginBottom: 0 }}
								>
									<Input />
								</Form.Item>
							)} */}

									<Divider />
								</>
							)}
							{value === 2 && (
								<>
									{selectFields?.map((item, index) => (
										<CustomAccordion
											key={index}
											title={'Field-' + index}
											// pad={'10px 0px'}
											pad='15px 0'
											padC='15px'
										>
											<Form.Item
												label='Label'
												name={[index, 'label']}
												initialValue={item?.label}
												// defaultValue={item?.label}
												style={{ width: '100%' }}
												// value={item?.label}
											>
												<Input
													onChange={(e) =>
														handleChange(e?.target.value, index, 'label')
													}
												/>
											</Form.Item>

											<Form.Item
												label='Name'
												name={[index, 'name']}
												initialValue={item.name}
												style={{ width: '100%' }}
											>
												<Input
													onChange={(e) =>
														handleChange(e?.target.value, index, 'name')
													}
												/>
											</Form.Item>
											<Form.Item
												label='Value'
												name={[index, 'value']}
												initialValue={item.value}
												style={{ width: '100%' }}
											>
												<Input
													onChange={(e) =>
														handleChange(e?.target.value, index, 'value')
													}
												/>
											</Form.Item>
										</CustomAccordion>
									))}
								</>
							)}

							{/* value === 0 &&  */}
							{action === 'edit' ? (
								<Form.List name={'fields'}>
									{(fields, { add, remove }) => {
										return (
											<>
												{fields.map((fieldSetting, index) => (
													<CustomAccordion
														key={index}
														title={'Field-' + index}
														// pad={'10px 0px'}
														pad='15px 0'
														padC='15px'
													>
														<Flex
															key={fieldSetting.key}
															flexDir='column'
															style={{ width: '100%' }}
														>
															<Form.Item
																name={[index, 'label']}
																label='Label'
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Label is required'
																// 	}
																// ]}
																style={{ width: '100%' }}
															>
																<Input placeholder='Enter label' />
															</Form.Item>
															<Form.Item
																name={[index, 'name']}
																label='Name'
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Name is required'
																// 	}
																// ]}
																style={{ width: '100%' }}
															>
																<Input placeholder='Enter name' />
															</Form.Item>
															<Form.Item
																name={[index, 'value']}
																label='Value'
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Value is required'
																// 	}
																// ]}
																style={{ width: '100%' }}
															>
																<Input placeholder='Enter value' />
															</Form.Item>
														</Flex>

														{/* <Flex
															justifyContent='flex-start'
															flexDir='column'
															alignItems='flex-start'
														>
															<Button
																Styles
																text='Remove'
																color='#DB2700'
																size='md'
																type='button'
																onClick={() => {
																	remove(fieldSetting.name);
																	// handleFieldsRemove(fieldSetting.label);
																}}
																style={{
																	padding: '0',
																	textDecoration: 'underline'
																}}
															/>
														</Flex> */}
													</CustomAccordion>
												))}
											</>
										);
									}}
								</Form.List>
							) : null}
						</Flex>
					</CustomAccordion>

					<Divider />
					{curType === 'CRYPTO' ? (
						<Form.Item
							label='Chain'
							name='chain'
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Chain is required'
							// 	}
							// ]}
						>
							<Input placeholder='Enter chain' type='text' />
						</Form.Item>
					) : null}
					<Form.Item
						name='fee_type'
						label='Fee Type'
						rules={[
							{
								required: true,
								message: 'Fee type is required'
							}
						]}
					>
						<Select
							placeholder='Select Fee Type'
							// showSearch
							onChange={(e) => setMinMaxToggle(e)}
						>
							<Select.Option value='FLAT'>FLAT</Select.Option>
							<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label='Fee amount'
						name={'fee'}
						rules={[
							{
								required: true,
								message: 'Fee amount is required'
							}
						]}
					>
						<Input placeholder='1.0' />
					</Form.Item>
					{minMaxToggle === 'PERCENTAGE' ? (
						<>
							<Form.Item label='Minimum Fee' name='min'>
								<Input placeholder='Enter min fee' />
							</Form.Item>
							<Form.Item label='Maximum Fee' name='max'>
								<Input placeholder='Enter max fee' />
							</Form.Item>
						</>
					) : null}
					<Form.Item name={'use_global_fees'} valuePropName='checked'>
						<Checkbox>Use global fee for selected currency</Checkbox>
					</Form.Item>
					<Form.Item name={'use_global_fields'} valuePropName='checked'>
						<Checkbox>Use global fields for selected payment method.</Checkbox>
					</Form.Item>
				</RateStyle>
			</>
		</Form>
	);
}

export { FormLayout };
