import React from "react";
import { Form, Input, Select } from "antd";
import { InputHolderStyle, RateStyle } from "./styles";
import { Flex } from "../../../../../components/box";

function FormLayout({
  handelSubmit,
  handleInputValueChange,
  action,
  currencyPair,
  loading,
  rates,
  initialEditValues,
  rateSource,
}) {
  return (
    <Form
      id="marketForm"
      onFinish={handelSubmit}
      layout={"vertical"}
      initialValues={{
        currency_pair:
          action === "edit" ? initialEditValues?.currency_pair : null,
        source: action === "edit" ? initialEditValues?.source : null,
        source_currency:
          action === "edit" ? initialEditValues?.source_currency : null,
        offset: action === "edit" ? initialEditValues?.offset : null,
        buying: action === "edit" ? initialEditValues?.buying : null,
        selling: action === "edit" ? initialEditValues?.selling : null,
      }}
    >
      <h3>{action === "add" ? "Add new rate" : "Edit rate"}</h3>
      <RateStyle>
        <p>Currency pair</p>
        <Form.Item
          name={"currency_pair"}
          rules={[{ required: true, message: "Currency pair is Required" }]}
        >
          <Select
            size="large"
            style={{ width: "100%" }}
            title="currency_pair"
            placeholder="select currency pair"
            onChange={(e, name) => {
              handleInputValueChange(name);
            }}
          >
            {currencyPair &&
              currencyPair?.map((item) => (
                <Select.Option key={item.id} value={item.pair}>
                  {item.pair}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="source"
          label="Rate Source"
          rules={[{ required: true, message: "Rate Source is Required" }]}
        >
          <Select
            placeholder="select rate source"
            size="large"
            style={{ width: "100%" }}
          >
            {rateSource &&
              rateSource?.map((item) => (
                <Select.Option value={item.name}>{item.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Flex justifyContent="space-between" className="display">
          <p>Currency rate</p>
          {loading ? (
            "loading"
          ) : rates?.type === "SELL" ? (
            <p>
              {rates !== undefined || null
                ? `${rates.rate}
            ${rates.from_currency}/${rates.to_currency}`
                : "rates will appear here"}
            </p>
          ) : (
            <p>
              {rates !== undefined || null
                ? `${rates?.rate}
            ${rates?.from_currency}/${rates?.to_currency}`
                : "rates will appear here"}
            </p>
          )}
        </Flex>
        <Form.Item name={"source_currency"} type="text" label="Source Currency">
          <Input type="number" placeholder="Enter source Currency" />
        </Form.Item>
        <InputHolderStyle>
          <Form.Item name={"offset"} label="Offset">
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="select offset"
            >
              <Select.Option value="FLAT">FLAT</Select.Option>
              <Select.Option value="PERCENTAGE">PERCENTAGE</Select.Option>
              <Select.Option value="MANUAL">MANUAL</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"buying"}
            type="number"
            label="Buying price markup"
            rules={[
              {
                required: true,
                message: "Buying price markup is Required",
              },
            ]}
          >
            <Input type="number" placeholder="15" />
          </Form.Item>
          <Form.Item
            name={"selling"}
            type="number"
            label="Selling price markup"
            rules={[
              {
                required: true,
                message: "Selling price markup is Required",
              },
            ]}
          >
            <Input type="number" placeholder="10" />
          </Form.Item>
        </InputHolderStyle>
      </RateStyle>
    </Form>
  );
}

export { FormLayout };
