import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Container } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import Search from '../../../../components/search';
import { getCompliancePending } from '../../../../services/Compliance.service';
import { ComplianceTableHead } from '../../../../utils/constants';
import CustomTable from '../table';
import { Container1, Styles } from './styles';

const Index = () => {
	const [statusValue, setStatusValue] = useState('All');
	const [countryValue, setCountryValue] = useState('All');
	const [input, setInput] = useState('');
	const [clicked, setClicked] = useState('false');

	const [filter, setFilter] = useState({ page: 1 });

	const pendingCompliance = useQuery(
		'getCompliancePending',
		async () => await getCompliancePending(filter)
	);

	const handleSelectStatus = (e) => {
		setStatusValue(e);
		setFilter({ ...filter, status: e });
	};

	const handleSelectCountry = (e) => {
		setCountryValue(e);
		setFilter({ ...filter, country: e });
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, search: value });
	};

	const handleSubmit = () => {
		if (filter.status === 'All') {
			delete filter.status;
		}
		if (filter.country === 'All') {
			delete filter.country;
		}
		setClicked('true');
		pendingCompliance.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => pendingCompliance.refetch());
	};

	if (pendingCompliance?.isFetching) {
		return <Loader />;
	}

	if (pendingCompliance.status === 'error') {
		return <span>Error: {pendingCompliance.error.message}</span>;
	}
	return (
		<Styles>
			<Container1>
				<Container className='input_container' width={'20%'}>
					<Search
						placeholder={'Search by name.'}
						input={input}
						setInput={setInput}
						handleSearch={handleSearch}
					/>
				</Container>
				<Filters
					source={'compliance'}
					handleSelectCountry={handleSelectCountry}
					onChangeStatus={handleSelectStatus}
					refetch={handleSubmit}
					statusValue={statusValue}
					countryValue={countryValue}
					loading={clicked === 'true' && pendingCompliance.isRefetching}
				/>
			</Container1>
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={ComplianceTableHead}
				tableBody={pendingCompliance?.data?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#8e8e8e'
				tbodyColor='#141414'
				handlePageChange={handlePageChange}
				meta={pendingCompliance?.data?.data?.data?.meta}
				paginator
				source='pending'
			/>
		</Styles>
	);
};

export default Index;
