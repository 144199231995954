import { LoaderIcon } from '../../assets';
import { ButtonStyle, Loader } from './styles';

const ButtonWithIcon = (props) => {
	return (
		<ButtonStyle
			type={props.type}
			disabled={props.loading}
			{...props}
			style={{
				alignItems: 'center',
				gap: '10px'
			}}
		>
			{props.text}
			{props.loading ? (
				<Loader>
					<LoaderIcon color={'#1A2CCE'} width='30px' height='30px' />
				</Loader>
			) : (
				props?.icon
			)}
		</ButtonStyle>
	);
};
export default ButtonWithIcon;
