import apiClient from './axios';

const EndPoints = {
	createFXopsAccount: '/admin/fx-ops/account',
	createFXopsAddress: '/admin/fx-ops/address',
	makePayout: '/admin/fx-ops/payout',
	fxOpsSummaryBalance: '/admin/fx-ops/balances',
	fxOpsSummaryPAYOUT: '/admin/fx-ops/summary?type=PAYOUT',
	fxOpsSummaryDeposit: '/admin/fx-ops/summary?type=DEPOSIT',
	getDefaultbankDetails: '/admin/fx-ops/account/default?currency=USD',

	getFxOpsTransactions(filterData) {
		let query = `/admin/fx-ops/transaction?order=DESC`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	getFxOpsAccounts(filterData) {
		let query = `/admin/fx-ops/account?order=DESC`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	getFxOpsAddress(filterData) {
		let query = `/admin/fx-ops/address?order=DESC`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	setDefaultFxOpsAccount(id) {
		return `/admin/fx-ops/account/${id}/default`;
	},

	setDefaultFxOpsAddress(id) {
		return `/admin/fx-ops/address/${id}/default`;
	},

	getSingleFxOpsAccount(id) {
		return `/admin/fx-ops/account/${id}`;
	},

	getSingleFxOpsTransaction(id) {
		return `/admin/fx-ops/transaction/${id}`;
	},

	getSingleFxOpsAddress(id) {
		return `/admin/fx-ops/address/${id}`;
	},

	FxOpsAccountStatus(id) {
		return `/admin/fx-ops/account/${id}/status`;
	},

	FxOpsAddressStatus(id) {
		return `/admin/fx-ops/address/${id}/status`;
	},

	FxOpsTransactionPdfDownload(id) {
		return `/admin/fx-ops/transaction/${id}/download`;
	},

	getDefaultAddressDetails(currency) {
		return `/admin/fx-ops/address/default?currency=${currency}`;
	},

	getFxopsReportType(from, to, type) {
		return `/admin/fx-ops/report?type=${type}&to=${to}&from=${from}`;
	}
};

export const getFxOpsAccounts = async (filterData) => {
	const res = await apiClient.get(EndPoints.getFxOpsAccounts(filterData));
	return res;
};

export const getFxOpsAddress = async (filterData) => {
	const res = await apiClient.get(EndPoints.getFxOpsAddress(filterData));
	return res;
};

export const getFxOpsTransactions = async (filterData) => {
	const res = await apiClient.get(EndPoints.getFxOpsTransactions(filterData));
	return res;
};

export const getSingleFxOpsAccount = async (id) => {
	const res = await apiClient.get(EndPoints.getSingleFxOpsAccount(id));
	return res;
};

export const FxOpsTransactionPdfDownload = async (id) => {
	const res = await apiClient.get(EndPoints.FxOpsTransactionPdfDownload(id), {
		responseType: 'blob'
	});
	return res;
};

export const FxOpsAccountStatus = async (id, status) => {
	const res = await apiClient.patch(EndPoints.FxOpsAccountStatus(id), status);
	return res;
};

export const FxOpsAddressStatus = async (id, status) => {
	const res = await apiClient.patch(EndPoints.FxOpsAddressStatus(id), status);
	return res;
};

export const getSingleFxOpsTransaction = async (id) => {
	const res = await apiClient.get(EndPoints.getSingleFxOpsTransaction(id));
	return res;
};

export const getSingleFxOpsAddress = async (id) => {
	const res = await apiClient.get(EndPoints.getSingleFxOpsAddress(id));
	return res;
};

export const makePayout = async (payload) => {
	const res = await apiClient.post(EndPoints.makePayout, payload);
	return res;
};

export const fxOpsSummaryBalance = async () => {
	const res = await apiClient.get(EndPoints.fxOpsSummaryBalance);
	return res;
};

export const getDefaultbankDetails = async () => {
	const res = await apiClient.get(EndPoints.getDefaultbankDetails);
	return res;
};

export const fxOpsSummaryPAYOUT = async () => {
	const res = await apiClient.get(EndPoints.fxOpsSummaryPAYOUT);
	return res;
};

export const fxOpsSummaryDeposit = async () => {
	const res = await apiClient.get(EndPoints.fxOpsSummaryDeposit);
	return res;
};

export const createFXopsAccount = async (payload) => {
	const res = await apiClient.post(EndPoints.createFXopsAccount, payload);
	return res;
};

export const createFXopsAddress = async (payload) => {
	const res = await apiClient.post(EndPoints.createFXopsAddress, payload);
	return res;
};

export const setDefaultFxOpsAccount = async (id) => {
	const res = await apiClient.patch(EndPoints.setDefaultFxOpsAccount(id));
	return res;
};

export const setDefaultFxOpsAddress = async (id) => {
	const res = await apiClient.patch(EndPoints.setDefaultFxOpsAddress(id));
	return res;
};

export const getDefaultAddressDetails = async (currency) => {
	const res = await apiClient.get(EndPoints.getDefaultAddressDetails(currency));
	return res;
};

export const getFxopsReportType = async (from, to, type) => {
	const res = await apiClient.get(EndPoints.getFxopsReportType(from, to, type));
	return res;
};
