import {
	Button as DeleteButton,
	Form,
	Input,
	Select,
	Tabs,
	Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BackIcon, LinkIcon, UploadIcon } from '../../../../../assets';
import { Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import ButtonWithIcon from '../../../../../components/buttonWithIcon';
import Layout from '../../../../../components/layouts';
import { Loader } from '../../../../../components/loader';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import { Spacer } from '../../../../../components/spacer/styles';
import {
	approveRejectWalletCompliance,
	getWalletByRef,
	updateWalletKYC
} from '../../../../../services/Wallet.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import { StylesCompliance } from './styles';

const { TextArea } = Input;

const WalletCompliance = ({ merchantID }) => {
	let { ref } = useParams();
	const queryClient = useQueryClient();
	const [showRejectInput, setShowRejectInput] = useState(false);
	const [route, setRoute] = useState('');

	const location = useLocation();
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);

	const walletInfo = useQuery(
		'getWalletKYC',
		async () => await getWalletByRef(ref),
		{
			refetchOnWindowFocus: false
		}
	);
	const rejectComplianceMutation = useMutation(
		async (data) => {
			await approveRejectWalletCompliance(ref, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getWalletKYC');
				queryClient.invalidateQueries('getPersonalWalletsCompliancePending');
				queryClient.invalidateQueries('getBusinessCompliancePending');

				ToastMessage(type.SUCCESS, 'compliance rejected');
				navigate(-1);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const approveComplianceMutation = useMutation(
		async (data) => {
			await approveRejectWalletCompliance(ref, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getWalletKYC');
				queryClient.invalidateQueries('getPersonalWalletsCompliancePending');
				queryClient.invalidateQueries('getBusinessCompliancePending');

				ToastMessage(type.SUCCESS, 'compliance approved!');
				navigate(-1);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	useEffect(() => {
		setRoute(location?.state?.prevRoute);
	}, [location?.state]);

	const show = (id) => {
		setVisible(true);
	};
	const handleCancel = () => {
		setVisible(false);
		setShowRejectInput(false);
	};

	const showModal = () => {
		setShowRejectInput(true);
	};

	const handleApproveComplianceWallet = () => {
		const payload = {
			status: 'verified',
			level: route === 'personal' ? 'PERSONAL' : 'BUSINESS',
			type: ['CRYPTO', 'FIAT']
		};

		// console.log(payload);
		approveComplianceMutation.mutate(payload);
	};
	// type: [route === 'personal' ? 'CRYPTO' : 'FIAT']
	const handleRejectComplianceWallet = (values) => {
		const payload = {
			status: 'rejected',
			level: route === 'personal' ? 'PERSONAL' : 'BUSINESS',
			type: ['CRYPTO', 'FIAT'],
			reject_reason: values.reason
		};

		rejectComplianceMutation?.mutate(payload);
		setTimeout(() => {
			setShowRejectInput(false);
		}, 500);
	};

	if (walletInfo.status === 'error') {
		return <span>Error: {walletInfo.error.message}</span>;
	}

	const checKPersonalComp =
		walletInfo?.data?.data?.data?.crypto_compliance_status === 'verified'
			? true
			: false;
	const checKBusinessComp =
		walletInfo?.data?.data?.data?.fiat_compliance_status === 'verified'
			? true
			: false;
	const checKPersonalCompRej =
		walletInfo?.data?.data?.data?.crypto_compliance_status === 'rejected'
			? true
			: false;
	const checKBusinessCompRej =
		walletInfo?.data?.data?.data?.fiat_compliance_status === 'rejected'
			? true
			: false;
	return (
		<Layout
			title={
				location?.state?.merchantName ||
				walletInfo?.data?.data?.data?.business?.name
			}
		>
			<StylesCompliance>
				<Flex
					justifyContent='flex-end'
					style={{
						borderBottom: '0.4px solid #C4C4C4'
					}}
				>
					<Flex
						onClick={() => navigate(-1)}
						className='go_back'
						alignItems='center'
						justifyContent='flex-start'
					>
						<BackIcon />
						<span style={{ paddingLeft: '20px' }}>Go Back</span>
					</Flex>

					<DeleteButton
						type='link'
						style={{
							paddingLeft: '20px',
							color: '#D80027',
							fontWeight: 400,
							fontSize: '15px',
							lineHeight: '20px',
							textDecoration: 'underline'
						}}
						// onClick={}
					>
						Delete wallet
					</DeleteButton>
				</Flex>
				{walletInfo?.isFetching ? (
					<Loader />
				) : (
					<>
						<Spacer height='32px' />
						<Flex justifyContent='space-between' alignItems='center'>
							<Flex alignItems='center' justifyContent='start'>
								<div
									style={{
										borderRight: '1px solid #E0E0E0',
										paddingRight: '35px'
									}}
								>
									<h3
										style={{
											marginBottom: 0
										}}
									>
										Compliance
									</h3>
								</div>
								{walletInfo?.data?.data?.data?.compliance !== null && (
									<Flex
										style={{
											paddingLeft: '25px'
										}}
										justifyContent='start'
										gap='16px'
									>
										<ButtonWithIcon
											Styles
											text='Approve'
											bgColor={'#E4F7E3'}
											// <AcceptTransactionIcon color='#00B428' />
											icon={
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M8.525 14.55L15.6 7.475L14.45 6.35L8.525 12.275L5.525 9.275L4.4 10.4L8.525 14.55ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
														fill='#027200'
													/>
												</svg>
											}
											color='#027200'
											size='sm'
											borderRadius={'4px'}
											onClick={handleApproveComplianceWallet}
											disabled={
												route === 'personal'
													? checKPersonalComp
													: checKBusinessComp
											}
											// walletInfo?.data?.data?.data?.fiat_compliance_status
											loading={approveComplianceMutation.isLoading}
										/>

										<ButtonWithIcon
											Styles
											text='Reject'
											color={'#C12300'}
											bgColor='#FCE9E3'
											size='sm'
											borderRadius={'4px'}
											icon={
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M6.25 14.8L10 11.05L13.75 14.8L14.8 13.75L11.05 10L14.8 6.25L13.75 5.2L10 8.95L6.25 5.2L5.2 6.25L8.95 10L5.2 13.75L6.25 14.8ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
														fill='#C12300'
													/>
												</svg>
											}
											onClick={showModal}
											disabled={
												route === 'personal'
													? checKPersonalCompRej
													: checKBusinessCompRej
											}
											// loading={approveComplianceMutation.isFetching}
										/>
									</Flex>
								)}
							</Flex>

							<Flex
								justifyContent='end'
								// marginBottom='20px'
							>
								<Button
									Styles
									text='Update KYC'
									color={'#fff'}
									bgColor='#1A2CCE'
									// text={apppprovedBuisness ? 'Approved' : 'Approve'}
									// bgColor={apppprovedBuisness ? '#E2E2E2' : '#00B428'}
									// color={apppprovedBuisness ? '#6C6C6C' : '#fff'}
									// color='#6C6C6C'
									size='sm'
									// border='border: 0.8px solid #1A2CCE;'
									borderRadius={'5px'}
									onClick={show}
									// disabled={apppprovedBuisness}
									// loading={approveComplianceBusinessMutation.isLoading}
								/>
							</Flex>
						</Flex>
						<Spacer height='32px' />
						<div className='compliance'>
							{walletInfo?.data?.data?.data?.compliance !== null ? (
								<>
									<div className='comp-details'>
										<p>FIRST NAME</p>
										{/* <p>{location?.state?.complianceData?.firstname}</p> */}
										<p>{walletInfo?.data?.data?.data?.compliance?.firstname}</p>
										<hr />
									</div>
									<div className='comp-details'>
										<p>LAST NAME</p>
										<p>{walletInfo?.data?.data?.data?.compliance?.lastname}</p>
										<hr />
									</div>
									<div className='comp-details'>
										<p>PHONE NUMBER</p>
										<p>{walletInfo?.data?.data?.data?.compliance?.phone}</p>
										<hr />
									</div>
									<div className='comp-details'>
										<p>EMAIL</p>
										<p>{walletInfo?.data?.data?.data?.compliance?.email}</p>
										<hr />
									</div>
									<div className='comp-details'>
										<p>BVN</p>
										<p>{walletInfo?.data?.data?.data?.compliance?.bvn}</p>
										<hr />
									</div>
									{walletInfo?.data?.data?.data?.compliance?.hasOwnProperty(
										'business'
									) ? (
										<div className='comp-details'>
											<Flex
												flexDir='column'
												justifyContent='flex-start'
												alignItems='flex-start'
											>
												<p>Business Details</p>
												<Flex
													justifyContent='space-between'
													alignItems='flex-start'
												>
													<div className='comp-details'>
														<p>Name</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.name
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>Phone</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.phone
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>Address</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.address
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>City</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.city
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>State</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.state
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>Country</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.country
															}
														</span>
													</div>
													<div className='comp-details'>
														<p>Reg number</p>
														<span>
															{
																walletInfo?.data?.data?.data?.compliance
																	?.business?.reg_number
															}
														</span>
													</div>
												</Flex>
											</Flex>
											<hr />
										</div>
									) : null}
									{walletInfo?.data?.data?.data?.compliance?.hasOwnProperty(
										'documents'
									) && (
										<div className='comp-details'>
											{walletInfo?.data?.data?.data?.compliance?.documents?.map(
												(document, index) => (
													<Flex
														flexDir='column'
														key={index}
														marginBottom='12px'
														justifyContent='flex-start'
														alignItems='flex-start'
													>
														<p>
															DOCUMENT{' '}
															{walletInfo?.data?.data?.data?.compliance
																?.documents?.length > 1 && index + 1}
														</p>
														<Flex
															className='proof'
															justifyContent='flex-start'
															key={index}
														>
															<span>{document?.document_type}</span>
															<span
																style={{
																	marginLeft: '20px',
																	marginRight: '20px',
																	borderLeft: '1px solid gray',
																	height: '20px'
																}}
															></span>
															<span style={{ marginRight: '20px' }}>
																{document.document_no}
															</span>
															{document?.hasOwnProperty('document_file') ? (
																<>
																	{/* {document?.document_ext === 'PDF' || 'pdf' ? (
															<>
																<span
																	className='lightBox'
																	onClick={() => setToggler(!toggler)}
																	key={index}
																>
																	View Document
																</span>
																<FsLightbox
																	toggler={toggler}
																	sources={[
																		<iframe
																			src={document?.document_file}
																			width='1080px'
																			height='1920px'
																			title={document.type}
																		/>
																	]}
																	type='pdf'
																/>
															</>
														) : (
															<>
																<span
																	className='lightBox'
																	onClick={() => setToggler2(!toggler2)}
																	key={document.document_no}
																>
																	View Document
																</span>
																<FsLightbox
																	toggler={toggler2}
																	sources={[document?.document_file]}
																	type='image'
																/>
															</>
														)} */}
																	<a
																		href={document?.document_file}
																		target='_blank'
																		rel='noreferrer'
																		className='lightBox'
																		// onClick={() => setToggler2(!toggler2)}
																		key={document.document_no}
																	>
																		View Document
																	</a>
																	{/* <span
															className='lightBox'
															onClick={() => setToggler2(!toggler2)}
															key={document.document_no}
														>
															View Document
														</span>
														<FsLightbox
															toggler={toggler2}
															sources={[document?.document_file]}
															type='image'
														/> */}
																</>
															) : (
																'File not found'
															)}
														</Flex>
													</Flex>
												)
											)}
										</div>
									)}
								</>
							) : (
								'No compliance details found'
							)}
						</div>
					</>
				)}
			</StylesCompliance>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={null}
				width={850}
				pad={0}
			>
				<p
					style={{
						textAlign: 'left',
						fontWeight: 500,
						fontSize: '18px',
						lineHeight: '23px',
						letterSpacing: '0.01em',
						color: '#000000',
						margin: 0,
						padding: 0
					}}
				>
					Update KYC
				</p>

				<Spacer height='40px' />
				<UpdateForm
					walletRef={ref}
					visible={setVisible}
					cancel={handleCancel}
					currentData={walletInfo?.data?.data?.data}
				/>
			</ModalFrame>
			<ModalFrame
				visible={showRejectInput}
				// handleOk={handleOk}

				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						// type='button'
						type='submit'
						key='submit'
						form='rejectWalletComp'
						// onClick={handleRejectComplianceWallet}
						loading={rejectComplianceMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'left' }}>
					Kindly provide a reason for rejection.
				</h3>

				<Form
					layout='vertical'
					onFinish={handleRejectComplianceWallet}
					id='rejectWalletComp'
				>
					<Form.Item
						label='Reason'
						name='reason'
						rules={[{ required: true, message: 'Reason  is required' }]}
					>
						<TextArea
							showCount
							style={{
								height: 120
							}}
							placeholder='Rejection reason'
						/>
					</Form.Item>
				</Form>
			</ModalFrame>
		</Layout>
	);
};

export default WalletCompliance;

const UpdateForm = ({ walletRef, cancel, visible, currentData }) => {
	const queryClient = useQueryClient();

	const updateMutation = useMutation(
		async (data) => {
			await updateWalletKYC(walletRef, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getWalletKYC');
				queryClient.invalidateQueries('getMerchantWallets');
				queryClient.invalidateQueries('getMerchantWalletBalance');
				queryClient.invalidateQueries('getMerchantTotalTransactions');

				setTimeout(
					() => {
						visible(false);
						// finished(false);
						// setSuccess(true);
					},

					500
				);
				ToastMessage(type.SUCCESS, 'KYC successfully updated');
				// form.resetFields('');
			},
			onError: (error) => {
				// console.log(error);
				// if (error?.status === 413) {
				// 	ToastMessage(type.ERROR, 'File size should be less than 4mb');
				// } else {
				ToastMessage(type.ERROR, error.response.data.message);
				// }
			}
		}
	);
	const [form] = Form.useForm();

	const fileTypes = ['JPEG', 'JPG', 'PNG', 'PDF', 'DOC', 'DOCX'];
	const [file, setFile] = useState('');
	const [fileError, setFileError] = useState('');
	const [document_file_type, setDocument_file_type] = useState('FILE');
	const [document_file, setDocument_file] = useState('');
	const [document_link, setDocument_link] = useState('');
	const [extension, setExtension] = useState('');

	const check =
		currentData?.compliance?.documents[0]?.document_file_type === 'FILE';
	console.log(check);
	const [showImage, setShowImage] = useState(check);
	const handleInputChange2 = (event) => {
		const d = event === '1' ? 'FILE' : 'LINK';
		setDocument_file_type(d);
		// console.log(event);
	};
	const handleInputChangeLink = (event) => {
		// console.log(event);
		const values = event?.target?.value;
		setDocument_link(values);
	};
	const handleSubmit = (data) => {
		const file = document_file_type === 'FILE' ? document_file : document_link;
		const ext =
			document_file_type === 'FILE'
				? extension
				: document_link?.split('.')?.pop();
		const compliance = {
			phone: data.phone,
			firstname: data?.firstname,
			lastname: data?.lastname,
			email: data?.email,
			bvn: data?.bvn,
			// values?.name === ''
			// 	? {}
			business: {
				name: data?.name,
				phone: data?.bPhone,
				address: data?.address,
				city: data?.city,
				state: data?.state,
				country: data?.country,
				reg_number: data?.reg_number
			},
			documents: [
				{
					document_type: data?.document_type,
					document_no: data?.document_no,
					// document_ext: ext,
					document_ext:
						ext === ''
							? currentData?.compliance?.documents[0]?.document_ext
							: ext,
					document_file:
						file === ''
							? currentData?.compliance?.documents[0]?.document_file
							: file,
					document_file_type:
						document_file_type === undefined || null
							? currentData?.compliance?.documents[0]?.document_file_type
							: document_file_type
					// document_file_type
				}
			]
		};

		delete data.document_file;
		delete data.document_no;
		delete data.document_type;
		delete data.firstname;
		delete data.lastname;

		console.log({ compliance: compliance }, ext, document_file_type);
		updateMutation.mutate({ compliance });
	};
	// console.log(currentData?.compliance?.documents[0]?.document_ext);

	const handleFileChange = (file) => {
		const extension = file?.name?.split('.')?.pop();
		setExtension(extension);
		const fsize = file?.size;
		const fileSizeCheck = fsize / 1024;

		console.log(file, file?.name);
		console.log(extension);
		// console.log(fileSizeCheck);
		if (fileSizeCheck >= 500) {
			// console.log('File larger than 500kb');
			setFileError(
				'File size larger than 500kb, Please select a file or image less than 500kb'
			);
		} else {
			setFileError('');
			const reader = new FileReader();
			reader.readAsDataURL(file);
			setFile(file.name);
			reader.onload = () => {
				const base64 = reader?.result?.split(',')?.pop();
				// const base64 = reader.result.replace('data:', '');

				setDocument_file(base64);
				// const values = [...compliance];
				// const updatedValue = 'document_file';
				// values[index][updatedValue] = base64;
				// setCompliance(values);
				// setFile((prev) => [...prev, base64]);
			};

			reader.onerror = (error) => {
				console.log(error);
			};
		}
		// }
	};

	return (
		<Form
			form={form}
			layout={'vertical'}
			onFinish={handleSubmit}
			initialValues={{
				email: currentData?.compliance?.email,
				bvn: currentData?.compliance?.bvn,
				phone: currentData?.compliance?.phone,
				name: currentData?.compliance?.business?.name,
				bPhone: currentData?.compliance?.business?.phone,
				address: currentData?.compliance?.business?.address,
				city: currentData?.compliance?.business?.city,
				state: currentData?.compliance?.business?.state,
				country: currentData?.compliance?.business?.country,
				reg_number: currentData?.compliance?.business?.reg_number,
				firstname: currentData?.compliance?.firstname,
				lastname: currentData?.compliance?.lastname,
				document_type: currentData?.compliance?.documents[0]?.document_type,
				document_no: currentData?.compliance?.documents[0]?.document_no,
				document_link:
					currentData?.compliance?.documents[0]?.document_file_type === 'LINK'
						? currentData?.compliance?.documents[0]?.document_file
						: ''
			}}
		>
			<Flex
				style={{
					borderTop: '1px solid #D7D7D7'
				}}
				gap='25px'
				alignItems='start'
				justifyContent='start'
			>
				<Flex
					width='100%'
					flexDir='column'
					justifyContent='space-between'
					style={{
						borderRight: '1px solid #D7D7D7',
						paddingTop: '25px',
						paddingBottom: '25px',
						paddingRight: '25px'
					}}
				>
					<Form.Item
						label='Email'
						name='email'
						// rules={[{ required: true, message: 'Email  is required' }]}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Email'
							// defaultValue={values.email}
							type='email'
							// value={}
							// onChange={handleFormData('email')}
						/>
					</Form.Item>

					<Form.Item
						label='Phone number'
						name='phone'
						// rules={[{ required: true, message: 'Phone number is required' }]}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Phone number'
							// defaultValue={values.phone}
							// onChange={handleFormData('phone')}
						/>
					</Form.Item>
					<Form.Item
						label='Business Name'
						name='name'
						// rules={[
						// 	{
						// 		required: true,
						// 		message: 'Business Name  is required'
						// 	},
						// 	{ min: 3, message: 'Business Name must be minimum 3 characters.' }
						// ]}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Business Name'
							// defaultValue={values.name}
							// onChange={handleFormData('name')}
						/>
					</Form.Item>
					<Form.Item
						label='Business Registration Number'
						name='reg_number'
						// rules={[
						// 	{
						// 		required: true,
						// 		message: 'Business Name  is required'
						// 	},
						// 	{ min: 3, message: 'Business Name must be minimum 3 characters.' }
						// ]}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Business Registration Number'
							// defaultValue={values.name}
							// onChange={handleFormData('name')}
						/>
					</Form.Item>
					<Form.Item
						label='Business Phone Number'
						name='bPhone'
						// rules={[
						// 	{
						// 		required: true,
						// 		message: 'Business Name  is required'
						// 	},
						// 	{ min: 3, message: 'Business Name must be minimum 3 characters.' }
						// ]}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Business Phone Number'
							// defaultValue={values.name}
							// onChange={handleFormData('name')}
						/>
					</Form.Item>
					<Form.Item
						label='Business Address'
						name='address'
						style={{
							width: '100%'
						}}
					>
						<Input placeholder='Enter Address' />
					</Form.Item>
					<Form.Item
						label='City'
						name='city'
						style={{
							width: '100%'
						}}
					>
						<Input placeholder='Enter City' />
					</Form.Item>
					<Form.Item
						label='State'
						name='state'
						style={{
							width: '100%'
						}}
					>
						<Input placeholder='Enter State' />
					</Form.Item>
					<Form.Item
						label='Country'
						name='country'
						style={{
							width: '100%'
						}}
					>
						<Input placeholder='Enter Country' />
					</Form.Item>
				</Flex>
				<Flex
					width='100%'
					flexDir='column'
					justifyContent='space-between'
					style={{
						paddingTop: '25px',
						paddingBottom: '25px'
						// height: 'fit-content'
					}}
				>
					<Form.Item
						label='First Name'
						name='firstname'
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter First Name'
							// defaultValue={values.firstname}
							// onChange={handleFormData('firstname')}
						/>
					</Form.Item>
					<Form.Item
						label='Last Name'
						name='lastname'
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter Last Name'
							// defaultValue={values.lastname}
							// onChange={handleFormData('lastname')}
						/>
					</Form.Item>
					<Form.Item
						label='BVN'
						name='bvn'
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter BVN'
							// defaultValue={values.bvn}
							type='text'
							// onChange={handleFormData('bvn')}
						/>
					</Form.Item>
					<SelectStyle>
						<Form.Item
							label='Verification ID'
							// name='doc_type'
							name='document_type'
							style={{
								width: '100%'
								// height: '46px'
							}}
						>
							<Select
								placeholder='Choose ID type'
								style={{
									width: '100%',
									height: '46px'
								}}
								// defaultValue={values.document_type}
								// onChange={handleSelectFormData('document_type')}
							>
								<Select.Option
									className='select'
									value='INTERNATIONAL_PASSPORT'
								>
									INTERNATIONAL PASSPORT
								</Select.Option>
								<Select.Option className='select' value='DRIVERS_LICENSE'>
									DRIVERS LICENSE
								</Select.Option>
								<Select.Option className='select' value='UTILITY_BILL'>
									UTILITY BILL
								</Select.Option>
								<Select.Option className='select' value='NATIONAL_CARD'>
									NATIONAL ID CARD
								</Select.Option>
							</Select>
						</Form.Item>
					</SelectStyle>
					<Form.Item
						label='ID number'
						name={'document_no'}
						style={{
							width: '100%'
						}}
					>
						<Input
							placeholder='Enter ID number'
							// defaultValue={values.document_no}
							// onChange={handleFormData('document_no')}
						/>
					</Form.Item>
					<StylesTab>
						<Tabs
							onTabClick={(key, event) => {
								handleInputChange2(key);
								// console.log(key);
							}}
							activeKey={
								currentData?.compliance?.documents[0]?.document_file_type ===
								'LINK'
									? '2'
									: '1'
							}
						>
							<Tabs.TabPane
								tab={
									<>
										<UploadIcon />
										<p>Upload ID</p>
									</>
								}
								key='1'
							>
								{showImage ? (
									<FileUploadStyle border={'1px dashed #AAAAAA'}>
										{currentData?.compliance?.documents[0]?.document_ext !==
										'pdf' ? (
											<img
												src={
													currentData?.compliance?.documents[0]?.document_file
												}
												height={'120px'}
												alt=''
											/>
										) : (
											'documents'
										)}
										<DeleteButton
											type='link'
											style={{
												paddingLeft: '20px',
												color: '#D80027',
												fontWeight: 400,
												fontSize: '15px',
												lineHeight: '20px',
												textDecoration: 'underline'
											}}
											onClick={() => setShowImage(false)}
										>
											Delete
										</DeleteButton>
									</FileUploadStyle>
								) : (
									<Form.Item label='Upload ID'>
										<FileUploadStyle
											border={
												fileError
													? '1px dashed #d80027'
													: file
													? '1px dashed #1A2CCE'
													: '1px dashed #AAAAAA'
											}
										>
											<FileUploader
												handleChange={(event) => {
													// console.log({ event });
													handleFileChange(event);
												}}
												name='file'
												types={fileTypes}
												multiple={false}
												// maxSize={0.5}

												children={
													<>
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
																marginBottom: '18px'
															}}
														>
															{/* <img src={fileUploadIcon} alt='file upload' /> */}
														</div>

														<Typography
															style={{
																color: '#1A2CCE'
															}}
														>
															{fileError !== ''
																? ''
																: file
																? file?.name
																: 'Select a JPEG, JPG, PNG, DOC, DOCX or PDF file to upload'}
														</Typography>
														<Typography
															style={{
																textAlign: 'center',
																color: fileError !== '' ? '#d80027' : 'black'
															}}
														>
															{fileError !== ''
																? fileError
																: file
																? 'Upload completed!'
																: 'or drag and drop it here'}
														</Typography>
													</>
												}
												className='image'
											/>
										</FileUploadStyle>
									</Form.Item>
								)}
							</Tabs.TabPane>
							<Tabs.TabPane
								tab={
									<>
										<LinkIcon />
										<p>Provide Link</p>
									</>
								}
								key='2'
							>
								<Form.Item
									label='Document link'
									name={'document_link'}
									// document_no
									// name='idNumber'
									// name={`document_file_${index}`}
									// defaultValue={field.document_file}
									style={{
										width: '100%'
									}}
									rules={[
										// {
										// 	required: true,
										// 	message: 'Document link  is required'
										// },
										{
											type: 'url',
											message: 'Please enter a valid url.'
										}
									]}
									onChange={(event) => handleInputChangeLink(event)}
								>
									<Input
										placeholder='https://'
										// type='text'
										// type='url'
										// defaultValue={values.lastname}
										// onChange={handleFormData('document_file')}
									/>
								</Form.Item>
							</Tabs.TabPane>
						</Tabs>
					</StylesTab>
					{/* {currentData?.compliance?.documents[0]?.document_file_type ===
					'FILE' ? (
						currentData?.compliance?.documents[0]?.document_ext !== 'pdf' ? (
							<img
								src={currentData?.compliance?.documents[0]?.document_file}
								alt=''
							/>
						) : (
							'documents'
						)
					) : (
						currentData?.compliance?.documents[0]?.document_file
					)} */}
				</Flex>
			</Flex>

			<Flex
				justifyContent='flex-end'
				// marginTop='40px'
				bgColor='#F2F2F2'
				pad={'14px 26px'}
				gap='16px'
			>
				<Button
					Styles
					text={'Cancel'}
					bgColor={'#ffffff'}
					size='md'
					type='button'
					onClick={cancel}
				/>

				<Button
					Styles
					text='Update'
					bgColor={'#1A2CCE'}
					color='#FFFFFF'
					size='md'
					type='submit'
					// form='createWallet'
					borderRadius='5px'
					// disabled={disabled}
					loading={updateMutation.isLoading}
				/>
			</Flex>
		</Form>
	);
};

const StylesTab = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	.ant-tabs {
		width: 100%;
	}
	.ant-tabs-top > .ant-tabs-nav:before {
		border: none;
	}
	// background: red;
	.ant-tabs-nav-list {
		width: 100%;
		padding: 0;
		background: transparent;
		// min-width: 200px;
		// width: fit-content;
		justify-content: space-around;
		height: 100px;
	}
	.ant-tabs-tab-btn {
		// text-align: center;
		width: 100px;
		// padding: 0 75px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// width: 20px;
		// width: fit-content;

		height: 84px;
		// padding: 16px 16px;
		// padding: 16px 21.5p÷x;
		background: #fafafa;
		border: 1px solid #d7d7d7;
		border-radius: 8px;

		cursor: pointer;
		p {
			padding-top: 7px;
		}
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab-btn:hover {
		color: #000 !important;
		background: #ffffff;
		border: 1px solid #1a2cce;

		// color: #1a2cce;
		// width: 100%;
	}
	.ant-tabs-ink-bar,
	.ant-tabs-ink-bar:hover {
		background: #1a2cce;
		border: 2px solid #1a2cce;
	}

	.ant-select,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 46px;
		// width: 100%;

		background: #ffffff;
		border: 0.7px solid #dfdfdf;
		border-radius: 5px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 46px;
	}
`;

const FileUploadStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 217px;
	border: ${({ border }) => border};

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.02em;
		margin-bottom: 0;
	}

	p:last-child {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.02em;
		color: #727272;
	}

	.ant-form input[type='file'],
	.ant-form-item-control-input-content input {
		display: hidden !important;
	}

	// input[name='file'],
	// .image
	.ant-form-item-control-input-content label input {
		display: hidden !important;
	}
`;

const SelectStyle = styled.div`
	width: 100%;
	.ant-select {
		// 	border: 1px solid #d7d7d7;
		border-radius: 5px;
	}
	.ant-select,
	.ant-select-selector {
		width: 100%;
		height: 46px !important;
	}
	.ant-select-selection-item {
		line-height: 46px !important;
	}
`;
