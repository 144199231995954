import styled from 'styled-components';

export const Styles = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	width: 240px;
	bottom: 0;
	background-color: #f3f6fb;
	border-right: 0.1px solid #b1b1b1;
	z-index: 2;
	.logo_box {
		margin-left: 30px;
		padding-bottom: 20px;
		cursor: pointer;
	}
	ul {
		overflow-y: auto;
		max-height: 85vh;
		display: flex;
		flex-direction: column;
	}
	@media (max-width: 700px) {
		position: fixed;
		width: 100%;
		z-index: 99;
		right: 0;
		left: 0;
		bottom: 0;
		top: unset;
		border-top: 0.5px solid #b1b1b1;
		border-right: none;
		.logo_box {
			display: none;
		}
		.drawer {
			margin: 0;
			ul {
				flex-direction: row;
				display: flex;
				justify-content: space-between;
				overflow-x: auto;
			}
		}
	}
`;

export const ListStyles = styled.li`
	margin: 15px 0;
	border-left: ${({ active }) =>
		active ? '3px solid #1A2CCE' : '3px solid transparent'};
	width: fit-content;
	font-size: 14px;
	&:hover {
		.drawerText {
			color: #1a2cce;
		}
		.icon_container {
			background-color: #c6ddff;
			color: #1a2cce;
		}
	}
	a,
	button {
		margin-left: 30px;
		font-weight: normal;
		color: #5b5551;
		text-align: start;
		display: inline-block;
		background-color: transparent;
		.icon_container {
			width: 40px;
			height: 40px;
			margin-right: 20px;
			color: ${({ active }) => (active ? '#1A2CCE' : '#fff')};
			background-color: ${({ active }) => (active ? '#C6DDFF' : '#8D8EA2')};
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.drawerText {
			color: ${({ active }) => (active ? '#1A2CCE' : '#000000')};
			font-weight: ${({ active }) => (active ? '700' : 'normal')};
		}
		.subDrawerText {
			color: #000000;
		}
	}
	.status_container {
		justify-content: center;
		padding-right: 20px;
		/* margin-top: 40px; */
	}
	.status {
		height: 57px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 30px;

		> span {
			margin-left: 15px;
			margin-right: 2px;
			text-transform: capitalize;
			font-size: 14px;
		}
		.ant-switch {
			width: 37.89px;
			height: 22.33px;
		}

		.ant-switch-checked {
			background: #69ce1a;
			border-radius: 24.2105px;
		}
	}
	@media (max-width: 700px) {
		.link_container {
			display: flex;
			flex-direction: column;
		}

		margin-right: 25px;
		width: unset;
		border-bottom: ${({ active }) => active && '2px solid #1A2CCE'};
		border-left: none;

		> a {
			margin-bottom: 10px;
			margin-left: 2px;
			white-space: nowrap;
			.icon_container {
				border-bottom: '2px solid';
				margin: 0 !important;
				padding: 7px;
				svg {
					height: 12px;
					width: 12px;
				}
			}
		}
		.drawerText {
			font-size: 9px;
			margin-top: 5px;
		}
	}
`;

export const SectionName = styled.span`
	margin-left: 30px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.155em;
	text-transform: uppercase;
	color: #7171a6;
	width: auto;
	@media (max-width: 700px) {
		display: none;
	}
`;
