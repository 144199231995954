import styled from 'styled-components';
import { Flex } from '../box';

export const PopDetails = styled.div`
	padding: 20px;
	width: ${({ width }) => width || 'fit-content'};
	cursor: pointer;

	.ant-radio-wrapper {
		.ant-radio {
			margin-right: 10px;
		}
		span {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			/* or 150% */
			margin-left: 2px;
			color: #434343;
		}
	}

	#status {
		.ant-space-item {
		}
		.ant-space-item:nth-child(1) {
			.ant-radio + span {
				padding: 5px 20px;
				/* color: #027200;  */
				background: #c6ddff;
				border-radius: 2.55882px;
			}
		}
		.ant-space-item:nth-child(2) {
			.ant-radio-wrapper {
				.ant-radio + span {
					padding: 5px 20px !important;
					border-radius: 2.55882px !important;
					background: #fff2d9 !important;
					color: #9c5502 !important;
				}
			}
		}
		.ant-space-item:nth-child(3) {
			.ant-radio + span {
				padding: 5px 20px;
				color: #027200;
				background: #c8ffc7;
				border-radius: 2.55882px;
			}
		}
		.ant-space-item:nth-child(4) {
			.ant-radio + span {
				padding: 5px 27px;
				color: #1a2cce;
				background: #d9edff;
				border-radius: 2.55882px;
			}
		}
		.ant-space-item:nth-child(5) {
			.ant-radio + span {
				padding: 5px 27px;
				color: #ffffff;
				background: #ff4848;
				border-radius: 2.55882px;
			}
		}
		.ant-space-item:nth-child(6) {
			.ant-radio + span {
				padding: 5px 27px;
				color: #721400;
				background: #ffe2d9;
				border-radius: 2.55882px;
			}
		}
	}

	.picker_holder {
		background: #ffffff;
		border: 0.7px solid #cfcfcf;
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
		border-radius: 3px;
		height: 44px;

		padding: 10px;
	}

	.ant-picker {
		padding: 0 !important;
		.ant-picker-input input {
			text-align: center;
		}
	}
`;

export const SelectContainer = styled(Flex)`
	width: 260px;
	// width: 170px;
	// width: fit-content;

	// justify-content: space-between;
	// align-items: center;

	gap: 20px;

	// @media (max-width: 1200px) {
	// 	.select-content {
	// 		// align-self: flex-end;
	// 		// width: 0;
	// 		// justify-content: flex-end !important;
	// 	}
	// }

	align-items: center;
	background: #ffffff;
	border: 0.7px solid #dfdfdf;
	box-sizing: border-box;
	padding: 5px 15px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 3px;

	.ant-select {
		width: 100%;

		.ant-select-selector {
			text-align: right !important;
		}
	}
	> span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #969696;
		// margin-right: 10px;
	}

	.select-content {
		// width: 20px !important;
		// 	justify-content: flex-end !important;
		// 	// margin-left: 10px;
		// 	span {
		// 		font-style: normal;
		// 		font-weight: 400;
		// 		font-size: 14px;
		// 		line-height: 17px;
		// 		color: #000000;
		// 		margin-right: 15px;
		// 	}

		// 	.formatText {
		// 		width: fit-content;
		// 		white-space: nowrap;
		// 		overflow: hidden;
		// 		text-overflow: ellipsis;
		// 	}
	}
`;
