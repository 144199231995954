import { DatePicker } from 'antd';
import React from 'react';
import { CalendarIcon } from '../../assets';
import { Flex } from '../box';
import styles from './customStyle.module.css';

export const CustomPicker = ({
	handleSelectFromChange,
	handleSelectToChange,
	source
}) => {
	return (
		<Flex
			alignItem='center'
			justifyContent='space-between'
			className={styles.select_date}
		>
			<Flex flexDir='column' alignItem='flex-start' width='45%'>
				<p>From</p>
				<Flex className={styles.picker_holder} justifyContent='space-around'>
					<CalendarIcon />
					<DatePicker
						size={'large'}
						suffixIcon={null}
						allowClear={false}
						bordered={false}
						onChange={handleSelectFromChange}
					/>
				</Flex>
			</Flex>

			{source === 'download' && (
				<hr width='40' style={{ margin: '30px 10px 10px 10px' }} />
			)}
			<Flex flexDir='column' alignItem='flex-start' width='45%'>
				<p>To</p>
				<Flex className={styles.picker_holder} justifyContent='space-around'>
					<CalendarIcon />
					<DatePicker
						size={'large'}
						suffixIcon={null}
						allowClear={false}
						bordered={false}
						onChange={handleSelectToChange}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};
