import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import {
	DotIcon,
	EyeIcon,
	UserAvatar,
	ViewTransactionIcon
} from '../../../../../../assets';
import { OverFlowScrollBar } from '../../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../../components/paginator';
import PopOver from '../../../../../../components/popover/PopOver';
import { generateID } from '../../../../../../utils/generateID';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

import { EmptyState } from '../../../../../../components/emptyState';
import { formattedDate, formattedTime } from '../../../../../../utils/helpers';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	tableHead2,
	meta,
	handlePageChange
}) => {
	const returnTableRow = (data, i) => {
		// console.log(data?.type);
		return (
			<tr key={data.id}>
				<Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'column'
						}}
					>
						<strong style={{ color: '#1A1F36' }}>
							{formattedDate(data?.created_at)}
						</strong>
						<span style={{ color: '#6F6F6F' }}>
							{formattedTime(data?.created_at)}
						</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>
							{data?.from_merchant !== null
								? data?.from_merchant?.business_name
								: data?.to_merchant?.business_name}
						</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#767676' }}>
							{data?.type === 'DEPOSIT'
								? data?.to_currency
								: data?.type === 'WITHDRAWAL'
								? data?.from_currency
								: data?.type === 'SWAP'
								? data?.from_currency + ' - ' + data?.to_currency
								: ''}
						</span>
					</div>
				</Td>
				<Td>
					{
						<div>
							<span>
								{data.to_currency !== null
									? data.to_currency
									: data.from_currency}
							</span>
							<strong>{data.amount.toLocaleString()}</strong>
						</div>
					}
				</Td>

				<CustomTd status={data.status}>
					<div>
						<span>{data.status}</span>
					</div>
				</CustomTd>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>{data?.type}</span>
					</div>
				</Td>
				<CustomTd>
					<PopOver
						placement='rightTop'
						main={
							<div className='icon_box'>
								<DotIcon />
							</div>
						}
					>
						<Link
							to={`/dashboard/transactions/${data?.type?.toLowerCase()}/${
								data.id
							}`}
						>
							<div>
								<ViewTransactionIcon />
								<p>Transaction details</p>
							</div>
						</Link>
						<Link to={`/dashboard/merchants/${data?.to_merchant?.id}`}>
							<div>
								<UserAvatar />
								<p>User info</p>
							</div>
						</Link>
						<Link to={`/dashboard/merchants/${data?.to_merchant?.id}/balances`}>
							<div>
								<EyeIcon />
								<p>View balances</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{(tableHead2 ? tableHead2 : tableHead).map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
