import styled from 'styled-components';
import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	width: 100%;
	background-color: #eef2f7;
	padding: 25px;
	border-radius: 10px;
	.header-container {
		.options {
			@media (min-width: 1300px) {
				flex-basis: 27%;
			}
		}
		.dot {
			border: 0.7px solid #e7e7e7;
			box-sizing: border-box;
			box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			min-height: 34px;
			width: 40px;
			cursor: pointer;
		}
		h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
			color: #1a1f36;
		}
	}

	.picker_holder {
		background: #ffffff;
		border: 0.7px solid #cfcfcf;
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
		border-radius: 3px;
		// height: 44px;

		padding: 1px 10px;
	}

	.ant-picker {
		padding: 0 !important;
		.ant-picker-input input {
			text-align: center;
		}
	}
`;

export const Card = styled(Flex)`
	padding: 30px;
	border-right: 0.4px solid rgba(181, 181, 181, 0.52);
	/* width: 450px; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.container {
		span:first-child {
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			text-transform: uppercase;
			color: #7171a6;
			padding-bottom: 20px;
		}
		span:last-child {
			padding-bottom: 20px;
			a {
				text-decoration: underline;
				font-size: 14px;
				font-weight: 500;
				color: #1a2cce;
			}
		}
	}
	.viewall {
		span:first-child {
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
			color: #1a2cce;
			padding-bottom: 20px;
		}
		span:last-child {
			> svg {
				margin-right: 3px;
			}
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: #000000;
		}
	}
`;
