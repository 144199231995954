import { ErrorMessage, Field, Form, Formik, replace } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { HiddenVisibilityIcon, PasswordEyeIcon } from '../../../assets/';
import { Flex } from '../../../components/box';
import Button from '../../../components/button';
import { loginUser, useAuthDispatch } from '../../../context';
import { FormContainer } from './styles';

import {
	FieldWrapper,
	InputStyle,
	Label
} from '../../../components/input/styles';
import { ToastMessage, type } from '../../../utils/toastr';

const Fields = () => {
	const [visibility, setVisibility] = useState(false);

	const initialValues = {
		email: '',
		password: ''
	};

	const validateSchema = yup.object().shape({
		email: yup.string().email().required('please provide an email'),
		password: yup.string().min(5).required('please provide a password')
	});

	const dispatch = useAuthDispatch();
	let navigate = useNavigate();

	return (
		<FormContainer>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					try {
						setSubmitting(true);
						let success = await loginUser(dispatch, values);
						setSubmitting(false);
						resetForm();
						if (success?.data?.data?.two_factor_enabled === false) {
							navigate('/dashboard', replace);
							ToastMessage(type.SUCCESS, success?.data?.message);
						} else if (success?.data?.data) {
							navigate('/2fa-auth');
						}
					} catch (error) {
						ToastMessage(type.ERROR, error.response.data.message);
					}
				}}
				validationSchema={validateSchema}
			>
				{({
					isSubmitting,
					handleChange,
					values: { email, password },
					dirty,
					handleBlur
				}) => (
					<Form>
						<Flex flexDir={'column'} gap={'30px'}>
							<InputStyle>
								<Label htmlFor='fullName'>Email</Label>
								<FieldWrapper>
									<Field
										type={'text'}
										onChange={handleChange}
										name={'email'}
										value={email}
										onBlur={handleBlur}
									/>
								</FieldWrapper>
								<ErrorMessage name={'email'} component={'div'} />
							</InputStyle>
							<InputStyle>
								<Label htmlFor='fullName'>password</Label>
								<Link to={'/forgot-password'}>Forgot password?</Link>
								<FieldWrapper btn={'btn'}>
									<Field
										type={visibility ? 'text' : 'password'}
										onChange={handleChange}
										name={'password'}
										value={password}
									/>
									<button
										type='button'
										onClick={() => setVisibility(!visibility)}
									>
										{visibility ? (
											<PasswordEyeIcon width={'20px'} height={'20px'} />
										) : (
											<HiddenVisibilityIcon width={'20px'} height={'20px'} />
										)}
									</button>
								</FieldWrapper>
								<ErrorMessage name={'password'} component={'div'} />
							</InputStyle>
							<Button
								text={'Login to account'}
								bgColor={'#1A2CCE'}
								color={'#fff'}
								size='md'
								fullwidth
								type='submit'
								borderRadius={'5px'}
								disable={!dirty || isSubmitting}
								loading={isSubmitting}
							/>
						</Flex>
					</Form>
				)}
			</Formik>
		</FormContainer>
	);
};

export default Fields;
