import { Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Flex } from '../../../../../../components/box';
import { Styles } from './styles';

import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../components/button';
import ModalFrame from '../../../../../../components/modal/ModalFrame';
import { useAuthState } from '../../../../../../context/context';
import { editMerchantById } from '../../../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../../../utils/toastr';

const { TextArea } = Input;

const EditProfileInfo = ({ initialEditValue }) => {
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	let params = useParams();

	const editMutation = useMutation(
		async (data) => {
			await editMerchantById(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantById');
				ToastMessage(type.SUCCESS, 'merchant info sucessfully updated');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const show = () => setVisible(true);

	const { countries } = useAuthState();

	const handleCancel = () => {
		setVisible(false);
	};

	const handleSubmit = (values) => {
		editMutation.mutate(values);
	};

	return (
		<>
			<Button
				text={'Edit info'}
				bgColor={'#1A2CCE'}
				color={'#fff'}
				size='sm'
				borderRadius={'5px'}
				onClick={show}
			/>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text='Save'
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						key='submit'
						form='editMerchant'
						loading={editMutation.isLoading}
						style={{ padding: '0.85rem 50px' }}
					/>
				]}
				width={650}
			>
				<Styles>
					<h3 style={{ textAlign: 'left' }}>Edit merchant info</h3>
					<Form
						form={form}
						layout='vertical'
						onFinish={handleSubmit}
						id='editMerchant'
						initialValues={{
							layout: 'vertical',
							size: 'large',
							business_name: initialEditValue?.business_name,
							business_email: initialEditValue?.business_email,
							phone: initialEditValue?.phone,
							country: initialEditValue?.country,
							business_description: initialEditValue?.business_description,
							website: initialEditValue?.website,
							address: initialEditValue?.address
						}}
					>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item label='Business Name' name='business_name'>
								<Input type='text' />
							</Form.Item>
							<Form.Item label='Address' name='address'>
								<Input type='text' />
							</Form.Item>
						</Flex>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item label='Email Address' name='business_email'>
								<Input type='email' />
							</Form.Item>
							<Form.Item label='Phone Number' name='phone'>
								<Input type='text' />
							</Form.Item>
						</Flex>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item label='Website ' name='website'>
								<Input type='text' />
							</Form.Item>
							<Form.Item label='Region/Country' name='country'>
								<Select placeholder='Select Region/Country'>
									{countries.map((item) => (
										<Select.Option value={item.name} key={item.name}>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Flex>
						<Form.Item
							label='About'
							name='business_description'
							style={{ width: '100%' }}
						>
							<TextArea rows={6} showCount maxLength={100} />
						</Form.Item>
					</Form>
				</Styles>
			</ModalFrame>
		</>
	);
};

export default EditProfileInfo;
