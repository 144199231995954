import { Skeleton } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Flex, Grid } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
import { Spacer } from '../../../../components/spacer/styles';
import { getAllMerchantsName } from '../../../../services/Merchants.service';
import {
	getMerchantTransactionsSummaryDeposit,
	getMerchantTransactionsSummarySwap,
	getMerchantTransactionsSummaryWithdrawal
} from '../../../../services/Transactions.service';
import { formatType } from '../../../../utils/helpers';
import { Card, Styles } from './styles';

const Index = () => {
	const [value, setValue] = React.useState('All');
	const [statusValue, setStatusValue] = React.useState('All');
	const [selectedDateTo, setSelectedDateTo] = React.useState('');
	const [selectedDateFrom, setSelectedDateFrom] = React.useState('');
	const [merchantList, setMerchantList] = React.useState([]);

	const [filter, setFilter] = React.useState({});

	const [clicked, setClicked] = React.useState('false');

	const swap = useQuery(
		'getMerchantTransactionsSummarySwap',
		async () => await getMerchantTransactionsSummarySwap(filter)
	);

	const withdrawal = useQuery(
		'getMerchantTransactionsSummaryWithdrawal',
		async () => await getMerchantTransactionsSummaryWithdrawal(filter)
	);

	const deposit = useQuery(
		'getMerchantTransactionsSummaryDeposit',
		async () => await getMerchantTransactionsSummaryDeposit(filter)
	);

	useQuery('getAllMerchantsName', async () => await getAllMerchantsName(), {
		onSuccess: (res) => {
			setMerchantList(res?.data?.data?.data);
		},
		onError: (error) => <p> {error.response.data.mesaage}</p>,
		refetchOnMount: 'always',
		refetchOnWindowFocus: true
	});

	const onChangeMerchant = (e) => {
		console.log(e);
		// const { value } = e.target;
		setStatusValue(e);
		setFilter({ ...filter, merchantId: e });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		// if (filter.currency === 'All') {
		// 	delete filter.currency;
		// }
		if (filter.merchantId === 'All') {
			delete filter.merchantId;
		}
		setClicked('true');
		console.log(filter);
		swap.refetch(filter);
		deposit.refetch(filter);
		withdrawal.refetch(filter);
	};

	return (
		<Styles>
			<Flex className='header-container' justifyContent={'space-between'}>
				<h3>Trading Volumes</h3>

				<Filters
					value={value}
					onChange={onChange}
					handleSelectFromChange={handleSelectFromChange}
					handleSelectToChange={handleSelectToChange}
					selectedDateFrom={selectedDateFrom}
					selectedDateTo={selectedDateTo}
					source='overview'
					merchantList={merchantList}
					statusValue={statusValue}
					onChangeStatus={onChangeMerchant}
					setFilter
					refetch={handleSubmit}
					loading={
						clicked === 'true' &&
						(swap.isRefetching ||
							withdrawal.isRefetching ||
							deposit.isRefetching)
					}
				/>
			</Flex>
			<Spacer height='10px' />
			<Grid
				justifyContent={'space-around'}
				gap='10px'
				gridCol='repeat(auto-fit, minmax(270px, 1fr))'
				bgColor='#fff'
				pad='30px 20px'
			>
				<Card
					style={{
						alignItems: 'flex-start'
					}}
				>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>DEPOSITS</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{deposit.isFetching ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>
								{deposit?.data?.data?.data?.currency?.symbol}
								{deposit?.data?.data?.data?.result?.toLocaleString()}
							</span>
						)}
						<span></span>
					</Flex>
					<Link
						to='transactions/deposits'
						style={{
							color: '#1a2cce'
						}}
					>
						View all deposits
					</Link>
				</Card>
				<Card
					style={{
						alignItems: 'flex-start'
					}}
				>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>WITHDRAWALS</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{withdrawal.isFetching ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>
								{withdrawal?.data?.data?.data?.currency?.symbol}
								{withdrawal?.data?.data?.data?.result?.toLocaleString()}
							</span>
						)}
					</Flex>
					<Link
						to='transactions/withdrawals'
						style={{
							color: '#1a2cce'
						}}
					>
						View all withdrawals
					</Link>
				</Card>
				<Card
					style={{
						alignItems: 'flex-start'
					}}
				>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Swap </span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{swap.isFetching ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>
								{swap?.data?.data?.data?.currency?.symbol}
								{swap?.data?.data?.data?.result?.toLocaleString()}
							</span>
						)}
					</Flex>
					<Link
						to='transactions'
						style={{
							color: '#1a2cce'
						}}
					>
						View all swaps
					</Link>
				</Card>
			</Grid>
		</Styles>
	);
};

export default Index;
