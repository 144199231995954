import styled from 'styled-components';
import { Flex } from '../../../../../components/box';

export const Styles = styled.div`
	.select {
		.ant-select-selector {
			color: #000000;
			border: none;
		}
	}
`;

export const Container1 = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	.fiter_container {
		background: #f8f9;
		// background: #f8f9fd;
		// padding: 46px 61px;

		// gap: 30px;
		// justify-content: space-between;
	}

	// .input-main-container {
	// 	display: flex;
	// 	width: 800px;
	// }
	// @media (max-width: 1200px) {
	// 	flex-direction: column;
	// 	gap: 20px 10px;
	// 	.fiter_container {
	// 		// width: 100%;
	// 		justify-content: space-between;
	// 		overflow: auto;
	// 		flex-wrap: wrap;
	// 		gap: 15px 5px;
	// 		/* border: 1px solid red; */
	// 	}

	// 	.input-main-container {
	// 		width: auto;
	// 		// flex-direction: column;
	// 	}
	// 	.input_container {
	// 		width: 100%;
	// 	}
	// }

	// @media (max-width: 800px) {
	// 	.fiter_container {
	// 		padding: 46px 21px;
	// 		flex-wrap: wrap;
	// 	}
	//   .input-main-container {
	//     display: flex;
	//     width: 800px;
	//   }
	// }
`;

export const StylesContainer = styled('div')`
	.btnContainer {
		display: flex;
		align-items: baseline;
		color: #1a2cce;

		.icon {
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			/* identical to box height */
			letter-spacing: 0.02em;
		}
		.link {
			color: #1a2cce;
			margin-left: 10px;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			/* identical to box height */

			letter-spacing: 0.02em;
		}
		margin-bottom: 42px;
	}
	.searchContainer {
		// padding: 0 20px;
		width: 800px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.selectWithIcon {
			margin-right: 24px;
			margin-left: 24px;
			// background: red;
			width: 170px;
			display: flex;
			padding: 0 19px;
			align-items: center;
			justify-contents: space-between;
			background: #ffffff;
			border: 0.7px solid #dfdfdf;
			border-radius: 5px;
			.filterIcon {
				margin-right: 10px;
			}
			// .ant-select {
			// 	border: none;
			// }
		}

		.ant-input {
			height: 30px;
		}
		margin-bottom: 32px;
	}
`;
export const MainContainer = styled('div')`
	margin-bottom: 40px;
	background: #f8f9fd;
	padding: 46px 61px;
	@media (max-width: 1200px) {
		padding: 20px 30px;
	}
`;

export const FormContainer = styled('div')`
	// display: flex;
	// flex-direction: column;
	// width: fit-content;
	// padding: 42px 50px;
	// h3 {
	// 	font-style: normal;
	// 	font-weight: 700;
	// 	font-size: 18px;
	// 	line-height: 23px;

	// 	color: #1a1f36;
	// }
	@media (max-width: 1200px) {
		// justify-content: center;
	}

	// #change-password {
	// 	.ant-input-affix-wrapper,
	// 	.ant-input-password,
	// 	.ant-input-affix-wrapper-lg {
	// 		// width: 350px;
	// 		height: 46px;
	// 		background: #ffffff;
	// 		border: 1px solid #d7d7d7;
	// 		box-sizing: border-box;
	// 		border-radius: 5px;
	// 		// margin-bottom: 15px;

	// 		font-size: 18px;
	// 		padding: 6.5px 15px 6.5px 11px;
	// 	}
	// }
	// margin-bottom: 40px;
`;

export const InputFlexBox = styled(Flex)`
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.icon-box {
		Button {
			// padding-left: 1rem;
			font-style: normal;
			font-weight: 400;
			font-size: 16.5529px;
			line-height: 22px;
			text-decoration-line: underline;
			color: #1a2cce;
		}
	}

	.holder {
		padding-top: 2rem;
		// width: 1200px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		.button-box,
		.button-box-password {
			margin: 13px 0;
			Button {
				padding: 15px 50px !important;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				text-decoration: none;
			}
		}

		.button-box-password {
			Button {
				padding: 15px 32px !important;
			}
		}

		.box,
		.box-password {
			// padding: 0 24px;
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: space-between;
			width: 45%;

			.fa-box {
				padding: 0 24px;

				.fa-text {
					p:nth-child(1) {
						font-size: 16px;
						color: #000a62;
						font-weight: bold;
						ine-height: 21px;
					}
					p:nth-child(2) {
						margin-left: 11px;
						background: #daffd9;
						border-radius: 3px;
						padding: 6px 12px;
					}
				}
				p {
					font-style: normal;
					margin-bottom: 0 !important;
				}
				p:nth-child(3) {
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					/* identical to box height */

					/* text color */

					color: #000a62;
					// padding-right: 150px;
				}
			}
		}

		.box-password {
			.fa-box {
				p:nth-child(1) {
					font-size: 16px !important;
					color: #000a62 !important;
					font-weight: bold;
					ine-height: 21px;
					padding-bottom: 5px;
				}
			}
		}
	}

	@media (max-width: 1200px) {
		justify-content: center;
		.holder {
			flex-direction: column;

			.button-box,
			.button-box-password {
				width: 100%;
				Button {
					width: 100% !important;
				}
			}
			.box,
			.box-password {
				width: 100%;
			}
		}
	}
`;
