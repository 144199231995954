import { Styles } from './styles';

import Drawer from '../drawer';
import Header from '../header';

const Layout = ({ children, title, bgColor }) => {
	return (
		<div>
			<Header title={title} />
			<Drawer />
			<Styles className='App' bgColor={bgColor}>
				<section className='layout'>{children}</section>
			</Styles>
		</div>
	);
};

export default Layout;
