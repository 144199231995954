import apiClient from './axios';

const EndPoints = {
	getTransactionsSummarySwap: `/admin/summary/transactions?column=amount&math=sum&type=swap`,
	getTransactionsSummaryDeposit: `/admin/summary/transactions?column=amount&math=sum&type=deposit`,
	getTransactionsSummaryWithdrawal: `/admin/summary/transactions?column=amount&math=sum&type=withdrawal`,

	getMerchantTransactionsSummarySwap(filterData) {
		let query = `/admin/summary/transactions?column=amount&math=sum&type=swap`;
		if (filterData?.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData?.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData?.merchantId) {
			query += `&merchantId=${filterData.merchantId}`;
		}
		return query;
	},
	getMerchantTransactionsSummaryDeposit(filterData) {
		let query = `/admin/summary/transactions?column=amount&math=sum&type=deposit`;
		if (filterData?.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData?.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData?.merchantId) {
			query += `&merchantId=${filterData.merchantId}`;
		}
		return query;
	},
	getTransactionsCount(merchantId) {
		// let query =
		return `/admin/summary/transactions?column=amount&math=count&merchantId=${merchantId}`;

		// 	if (filterData.merchantId) {
		// 		query += `&merchantId=${filterData.merchantId}`;
		// 	}
		// 	return query;
	},
	getMerchantTransactionsSummaryWithdrawal(filterData) {
		let query = `/admin/summary/transactions?column=amount&math=sum&type=withdrawal`;
		if (filterData?.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData?.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData?.merchantId) {
			query += `&merchantId=${filterData.merchantId}`;
		}
		return query;
	},
	// `/admin/summary/transactions?column=amount&math=sum&type=deposit`
	getTransactionsSummaryFeeCollected: `/admin/summary/transactions?column=fee&math=sum`,
	getTransactionsSummaryTotalTransaction: `/admin/summary/transactions?column=amount&math=sum`,
	summarySwap: `/admin/summary/swap`,
	approveSwapTransaction: `/admin/transactions/accept`,
	providerStatus: `/admin/transactions/withdrawal-methods/status`,

	receipt(id) {
		return `/admin/transactions/download/${id}`;
	},
	// approveSwapTransaction: `transactions/approve`,

	getTransactionsSwap(ref, filterData) {
		let query = `/admin/transactions?type=swap&order=DESC&take=10&approvals=false`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},

	getTransactionsDeposit(ref, filterData) {
		let query = `/admin/transactions?type=deposit&order=DESC&take=10&approvals=false`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},
	getRejectedDeposit(ref, filterData) {
		let query = `/admin/transactions?type=deposit&order=DESC&take=10&_status=rejected`;

		// /admin/transactions?order=DESC&page=1&take=10&type=deposit&_status=rejected
		// 		GET /admin/transactions?_status=rejected
		// GET /admin/transactions?_status=refunding
		// GET /admin/transactions?_status=refunded
		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.status) {
		// 	query += `&status=${filterData.status}`;
		// }
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},
	getRequestDeposit(ref, filterData) {
		let query = `/admin/transactions?type=deposit&order=DESC&take=10&_status=refunding`;

		// /admin/transactions?order=DESC&page=1&take=10&type=deposit&_status=rejected
		// 		GET /admin/transactions?_status=rejected
		// GET /admin/transactions?_status=refunding
		// GET /admin/transactions?_status=refunded
		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.status) {
		// 	query += `&status=${filterData.status}`;
		// }
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},
	getRefundCompleteDeposit(ref, filterData) {
		let query = `/admin/transactions?type=deposit&order=DESC&take=10&_status=refunded`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.status) {
		// 	query += `&status=${filterData.status}`;
		// }
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},

	getTransactionsWithdrawers(ref, filterData) {
		let query = `/admin/transactions?type=withdrawal&order=DESC&take=10&approvals=false`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.take) {
			query += `&take=${filterData.take}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (ref) {
			query += `&ref=${ref}`;
		}

		return query;
	},

	getSummarySwap(time) {
		return `/admin/summary/swap?time=${time}`;
	},

	createTransactionsForMerchant(id) {
		return `/admin/transactions/create/${id}`;
	},

	getTransactionsReport(from, to, merchantId) {
		let query = `/admin/transactions/report?to=${to}&from=${from}`;
		if (merchantId !== 0) {
			query += `&merchantId=${merchantId}`;
		}
		return query;
	},

	getTransactionsReportType(from, to, type, expectedTrans, merchantId) {
		let query = `/admin/transactions/report?type=${type}&to=${to}&from=${from}&expected=${expectedTrans}`;
		if (merchantId !== 0) {
			query += `&merchantId=${merchantId}`;
		}
		return query;
	},

	addSettlementTime(id) {
		return `/admin/transactions/${id}/time`;
	},
	refreshTime(id) {
		return `/admin/transactions/${id}/refresh`;
	},
	requeue(id) {
		return `/admin/transactions/${id}/re-queue`;
	},

	getSingleTransactionById(id) {
		return `/admin/transactions/${id}`;
	},
	refundRequested(id) {
		return `/admin/transactions/${id}/request-refund`;
	},
	refundCompleted(id) {
		return `/admin/transactions/${id}/complete-refund`;
	},

	getTransactionsByMerchantId(id, filterData) {
		let query = `/admin/transactions?merchantId=${id}&order=DESC&approvals=false`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.type) {
			query += `&type=${filterData.type}`;
		}

		if (filterData.take) {
			query += `&take=${filterData.take}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	}
};

export const getTransactionsSummarySwap = async () => {
	const res = await apiClient.get(EndPoints.getTransactionsSummarySwap);
	return res;
};
export const getMerchantTransactionsSummarySwap = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getMerchantTransactionsSummarySwap(filterData)
	);
	return res;
};

export const createTransactionsForMerchant = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.createTransactionsForMerchant(id),
		payload
	);
	return res;
};

export const getTransactionsSummaryDeposit = async () => {
	const res = await apiClient.get(EndPoints.getTransactionsSummaryDeposit);
	return res;
};
export const getMerchantTransactionsSummaryDeposit = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getMerchantTransactionsSummaryDeposit(filterData)
	);
	return res;
};
export const getTransactionsCount = async (merchantId) => {
	const res = await apiClient.get(EndPoints.getTransactionsCount(merchantId));
	return res;
};

export const getSummarySwap = async (time) => {
	const res = await apiClient.get(EndPoints.getSummarySwap(time));
	return res;
};

export const getTransactionsSummaryWithdrawal = async () => {
	const res = await apiClient.get(EndPoints.getTransactionsSummaryWithdrawal);
	return res;
};
export const getMerchantTransactionsSummaryWithdrawal = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getMerchantTransactionsSummaryWithdrawal(filterData)
	);
	return res;
};

export const getTransactionsSummaryFeeCollected = async () => {
	const res = await apiClient.get(EndPoints.getTransactionsSummaryFeeCollected);
	return res;
};

export const getTransactionsSummaryTotalTransaction = async () => {
	const res = await apiClient.get(
		EndPoints.getTransactionsSummaryTotalTransaction
	);
	return res;
};

export const getTransactionsSwap = async (ref, filterData) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsSwap(ref, filterData)
	);
	return res;
};

export const getTransactionsDeposit = async (ref, filterData) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsDeposit(ref, filterData)
	);
	return res;
};
export const getRejectedDeposit = async (ref, filterData) => {
	const res = await apiClient.get(
		EndPoints.getRejectedDeposit(ref, filterData)
	);
	return res;
};
export const getRequestedRefundDeposit = async (ref, filterData) => {
	const res = await apiClient.get(EndPoints.getRequestDeposit(ref, filterData));
	return res;
};
export const getRefundCompleteDeposit = async (ref, filterData) => {
	const res = await apiClient.get(
		EndPoints.getRefundCompleteDeposit(ref, filterData)
	);
	return res;
};

export const getTransactionsWithdrawers = async (ref, filterData) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsWithdrawers(ref, filterData)
	);
	return res;
};

export const getSingleTransactionById = async (id) => {
	const res = await apiClient.get(EndPoints.getSingleTransactionById(id));
	return res;
};

export const getTransactionsByMerchantId = async (id, filterData) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsByMerchantId(id, filterData)
	);
	return res;
};

export const addSettlementTime = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.addSettlementTime(id), payload);
	return res;
};

export const refreshTransactionTime = async (id) => {
	const res = await apiClient.patch(EndPoints.refreshTime(id));
	return res;
};
export const requeueTransaction = async (id) => {
	const res = await apiClient.patch(EndPoints.requeue(id));
	return res;
};

export const getTransactionsReportType = async (
	from,
	to,
	type,
	expectedTrans,
	merchantId
) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsReportType(
			from,
			to,
			type,
			expectedTrans,
			merchantId
		)
	);
	return res;
};

export const getTransactionsReport = async (from, to, merchantId) => {
	const res = await apiClient.get(
		EndPoints.getTransactionsReport(from, to, merchantId)
	);
	return res;
};

export const approveSwapTransaction = async (payload) => {
	const res = await apiClient.patch(EndPoints.approveSwapTransaction, payload);
	return res;
};

export const getProviderStatus = async () => {
	const res = await apiClient.get(EndPoints.providerStatus);
	return res;
};
export const activateOrDeactivateProviderStatus = async (payload) => {
	const res = await apiClient.post(EndPoints.providerStatus, payload);
	return res;
};

export const getReceipt = async (id) => {
	const res = await apiClient.get(EndPoints.receipt(id));
	return res;
};

export const refundRequest = async (id) => {
	const res = await apiClient.post(EndPoints.refundRequested(id));
	return res;
};
export const refundCompleted = async (id) => {
	const res = await apiClient.post(EndPoints.refundCompleted(id));
	return res;
};
