import styled from "styled-components";

export const Styles = styled.div`
  .currencies,
  settlement {
    .search {
      width: 243px;
      .ant-input-affix-wrapper {
        background: #ffffff;
        border: 0.7px solid #dfdfdf;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
      }
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #1a1f36;
    }
    Button {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      padding: 0;
      text-decoration: underline;
    }
    padding-bottom: 20px;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #061f39;
    margin-bottom: 20px;
  }
  .crypto,
  .local {
    .ant-checkbox-wrapper {
      .ant-checkbox + span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #061f39;
        padding-right: 0;
      }
      .ant-checkbox-inner {
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #1a2cce;
        border-radius: 4px;
      }
    }
  }

  .crypto,
  .local {
    .ant-checkbox-wrapper {
      margin-left: 0;
      margin-bottom: 32px;
      width: 30%;

      @media (max-width: 1200px) {
        width: 50%;
      }
    }
  }
  .local {
    padding-bottom: 40px;
  }

  .local-text {
    padding-top: 40px;
  }

  .btn {
    .add-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      padding: 0;
      text-align: right;
      text-decoration-line: underline;
    }

    .save {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 15px 55px;
    }
  }
`;

export const FormContainer = styled("div")`
  .ant-input,
  .ant-select .ant-select-selector {
    width: 250px !important;
    height: 45px !important;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 40px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 2px;
  }
  .ant-select-selection-search-input {
    line-height: 21px;
  }
  h4 {
    padding-bottom: 25px;
  }
`;
