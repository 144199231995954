import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	// padding: 30px 30px 0;
	padding: 0 35px;
	h2 {
		color: #000000;
	}
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	.wallet-owner {
		display: flex;
		align-items: baseline;
		.icon-box {
			display: flex;
			width: 43px;
			height: 43px;
			border-radius: 100%;
			background: #d7e6ff;
			justify-content: center;
			align-items: center;
			color: #1a2cce;
		}

		p {
			margin-left: 13px;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.02em;

			/* Normal-side-panel text */

			color: #1a1f36;
		}
	}

	.card-container {
		// background: red;
		.ant-col {
			width: 320px;
			// margin-right: 43px;

			// :last-child {
			// 	margin-right: 0px;
			// }
		}

		.transaction-value {
			.ant-card-bordered {
				border: 1px solid transparent;
			}
			.ant-statistic {
				.ant-statistic-title {
					color: #dee1fc;
				}

				.ant-statistic-content {
					color: #ffffff;
				}
			}
		}
		.ant-card-bordered {
			border: 1px solid #dfdfdf;
			border-radius: 6px;
			.ant-card-body {
				padding: 36px;
			}
		}
		.ant-statistic {
			.ant-statistic-title {
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;

				letter-spacing: 0.02em;

				color: #6f6f6f;
				margin-bottom: 20px;
			}

			.ant-statistic-content {
				font-weight: 700;
				font-size: 24px;
				line-height: 31px;
				letter-spacing: 0.06em;
				color: #0f0f0f;
			}
		}
	}
	.table-caption {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		/* identical to box height */

		letter-spacing: 0.02em;

		color: #6f6f6f;
	}
	.searchAdd {
		.ant-btn {
			width: 131px;
			height: 42px;

			/* Blue-100% */
			color: #ffffff;
			background: #1a2cce;
			border-radius: 5px;
			border: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}
	}
`;

export const Wrapper = styled.div`
	.select {
		.ant-select-selector {
			background-color: #1a2cce;
		}
		.ant-select-arrow {
			color: #fff;
		}
	}
`;

export const StatisticStyle = styled.div`
	// padding: 30px;
	// background: #ececec;

	.ant-col {
		width: 340px;
		margin-right: 43px;

		:last-child {
			margin-right: 0px;
		}
	}
	.ant-card-bordered {
		border: 1px solid #dfdfdf;
		border-radius: 6px;

		.ant-card-body {
			padding: 24px 32px;
		}
	}

	.ant-statistic {
		.ant-statistic-title {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;

			letter-spacing: 0.02em;

			color: #6f6f6f;
			margin-bottom: 19px;
		}

		.ant-statistic-content {
			font-weight: 700;
			font-size: 23px;
			line-height: 30px;
			letter-spacing: 0.02em;
			color: #0f0f0f;
		}
	}
`;

export const TabStyles = styled.div`
	// background: red;
	.ant-tabs-nav-list {
		width: 100%;
		// min-width: 200px;
		// width: fit-content;
		// justify-content: space-around;
	}
	.ant-tabs-tab-btn {
		// text-align: center;
		// width: 200px;
		// padding: 0 75px;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab-btn:hover {
		color: #000 !important;
		// color: #1a2cce;
		// width: 100%;
	}
	.ant-tabs-ink-bar,
	.ant-tabs-ink-bar:hover {
		background: #1a2cce;
		border: 2px solid #1a2cce;
	}

	.ant-select,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 46px;
		// width: 100%;

		background: #ffffff;
		border: 0.7px solid #dfdfdf;
		border-radius: 5px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 46px;
	}

	.fiat {
		padding-top: 81px;
		text-align: center;
		h3 {
			margin-top: 32px;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			/* identical to box height */

			letter-spacing: 0.04em;

			/* Normal-side-panel text */

			color: #1a1f36;
		}

		p {
			margin-top: 32px;

			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			/* identical to box height */

			letter-spacing: 0.04em;

			color: #7b7b7b;
		}
	}

	.compliance {
		background: #f5f7fa;
		border-radius: 8px;
		padding: 32px;
		.comp-details {
			p:first-child {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.05em;
				color: #8f8f8f;
				padding-bottom: 12px;
				margin: 0;
			}
			p:last-child {
				font-weight: 500;
				font-size: 15px;
				line-height: 18px;
				letter-spacing: 0.04em;
				color: #1a1f36;
				padding-bottom: 24px;
				margin: 0;
			}
			hr {
				border: 0.3px solid #c4c4c4;
				margin-bottom: 24px;
			}
		}
		.comp-details:last-child {
			.proof {
				p:last-child {
					padding-bottom: 0px;
				}
			}
			.lightBox {
				color: #1a2cce !important;
				// background:
				margin: 0 0 0 10px;
				cursor: pointer;
			}
		}
	}
`;

export const FilterContainerStyles = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;

	.two-input {
		width: 340px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// align-items: flex-end;
		background: red;
		margin-left: 43px;
	}
`;

export const InputStyles = styled.div`
	display: flex;
	flex-direction: column;

	.ant-btn {
		// margin-top: 26px;
		// padding: 11px 18px 11px 14px;
		// gap: 8px;

		width: 160px;
		height: 51px;

		/* Blue-100% */
		color: #ffffff;
		background: #1a2cce;
		border-radius: 5px;
		border: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
	}
	.ant-typography {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.04em;

		color: #505050;
		margin-bottom: 8px;
	}

	.input-box {
		width: 340px;
		.ant-input-affix-wrapper {
			// background: #ffffff;
			border: 0.7px solid #dfdfdf;
			border-radius: 5px;
			.ant-input {
				height: 41px;
			}
		}
	}
	.input-box2 {
		width: 160px;
		height: 41px;
		.ant-input-affix-wrapper {
			border: 0.7px solid #dfdfdf;
			border-radius: 5px;
			.ant-input {
				height: 41px;
			}
		}

		.ant-select,
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			height: 51px;

			background: #ffffff;
			width: 160px;
			border: 0.7px solid #dfdfdf;
			border-radius: 5px;
		}
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 50px;
	}
`;

export const TabStyles2 = styled.div`
	// background: red;
	.ant-tabs-nav-list {
		background: #f9f9f9;
		border-radius: 6px;
		// width: 377px;
		width: 100%;
		height: 48px;
		padding: 6px;
		// min-width: 260px;
		// width: fit-content;
		justify-content: space-between;
	}
	// .ant-tabs-tab {
	// 	padding: 12px 15px;
	// }
	.ant-tabs-tab-btn {
		// text-align: center; // width: 200px;
		padding: 0 73px;
		// width: 180px;
		height: 40px;
		display: flex;
		align-items: center;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.02em;
		color: #6d6d6d;
		opacity: 0.84;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #1a2cce !important;
		display: flex;
		align-items: center;
		text-align: center;
		background: #ffffff;
		box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
	}

	.ant-tabs-tab:hover .ant-tabs-tab-btn:hover {
		color: #1a2cce !important;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-ink-bar,
	.ant-tabs-ink-bar:hover {
		display: none;
		color: #1a2cce;
		background: #1a2cce;
	}

	.ant-select,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 46px;
		// width: 100%;

		background: #ffffff;
		border: 0.7px solid #dfdfdf;
		border-radius: 5px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 46px;
	}

	.fiat {
		padding-top: 81px;
		text-align: center;
		h3 {
			margin-top: 32px;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			/* identical to box height */

			letter-spacing: 0.04em;

			/* Normal-side-panel text */

			color: #1a1f36;
		}

		p {
			margin-top: 32px;

			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			/* identical to box height */

			letter-spacing: 0.04em;

			color: #7b7b7b;
		}
	}
`;
