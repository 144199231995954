import { Input, InputNumber, Switch } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
// import PopOver from '../../../../../components/popover/PopOver';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import {
	getSettings,
	toggleWalletCreation,
	toggleWalletCreationBvn,
	updateDynamicAccountLimit,
	updateGeneralWalletLimit
} from '../../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import { Styles } from './styles';
const Wallet = ({ status }) => {
	const queryClient = useQueryClient();
	const [expireAt, setExpireAt] = useState(Number);
	const [fiatLimit, setFiatLimit] = useState(Number);
	const [fiatAmountLimit, setFiatAmountLimit] = useState(Number);
	const [cryptoAmountLimit, setCryptoAmountLimit] = useState(Number);
	const [cryptoLimit, setCryptoLimit] = useState(Number);
	const checkToken = localStorage.getItem('access_token');
	const settings = useQuery(
		['getSettings', checkToken],
		async () => await getSettings(),
		{
			onError: (error) => <p> {error.response.data.message}</p>,
			enabled: !!checkToken,
			// refetchOnMount: true,
			refetchOnMount: 'always',
			refetchOnWindowFocus: false
		}
	);
	const createMutation = useMutation(
		'createWalletCreation',
		async (data) => await toggleWalletCreation(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getSettings');
				setTimeout(() => {
					ToastMessage(
						type.SUCCESS,
						'Wallet compliance document status set successfully'
					);
				});
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const createMutationBvn = useMutation(
		'createWalletCreationBvn',
		async (data) => await toggleWalletCreationBvn(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getSettings');
				setTimeout(() => {
					ToastMessage(
						type.SUCCESS,
						'Wallet compliance bvn status set successfully'
					);
				});
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const updateDynamicAccountLimitMutation = useMutation(
		'updateGeneralWalletLimit',
		async (data) => await updateDynamicAccountLimit(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getSettings');
				setTimeout(() => {
					ToastMessage(
						type.SUCCESS,
						'Dynamic Accounts timer updated successfully'
					);
				}, 500);
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const updateWalletLimitMutation = useMutation(
		'updateGeneralWalletLimit',
		async (data) => await updateGeneralWalletLimit(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getSettings');
				setTimeout(() => {
					ToastMessage(type.SUCCESS, 'Wallet settings updated successfully');
				}, 500);
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const handleSwitched = (checked) => {
		const statusChecked = checked === false ? { value: 0 } : { value: 1 };
		createMutation.mutate(JSON.stringify(statusChecked));
	};
	const handleSwitchedBvn = (checked) => {
		const statusChecked = checked === false ? { value: 0 } : { value: 1 };
		createMutationBvn.mutate(JSON.stringify(statusChecked));
	};

	const handleExpireAt = () => {
		updateDynamicAccountLimitMutation.mutate({
			value: expireAt
		});
	};
	const handleFiatChange = () => {
		updateWalletLimitMutation.mutate({
			fiat_account_limit: fiatLimit,
			crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit
		});
	};

	const handleCryptoChange = () => {
		updateWalletLimitMutation.mutate({
			fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
			crypto_account_limit: cryptoLimit
		});
	};
	const handleChange = (name) => {
		if (name === 'fiat_personal') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: fiatAmountLimit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'crypto_personal') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit: cryptoAmountLimit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'fiat_business') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: fiatAmountLimit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'crypto_business') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: cryptoAmountLimit
			});
		}
	};

	if (settings.isFetching) {
		return <Loader />;
	}

	if (settings.isError) {
		return <span>Error: {settings.error.message}</span>;
	}

	return (
		<Styles>
			<div className='notification'>
				<div className='info'>
					<h3>Compliance</h3>
					<p>Request compliance for wallet creation</p>
				</div>
				<div className='info2'>
					<p>Document required</p>
					<Switch
						checked={settings?.data?.data?.data?.validate_wallet_compliance}
						onChange={handleSwitched}
					/>
				</div>
				<div className='info2'>
					<p>BVN required</p>
					<Switch
						checked={settings?.data?.data?.data?.wallet_compliance_bvn}
						onChange={handleSwitchedBvn}
					/>
				</div>
			</div>

			<div className='notification-block'>
				<div className='info'>
					<h3>Dynamic Accounts timer</h3>
					<p>Set the limit for dynamic accounts in minutes</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							defaultValue={
								settings?.data?.data?.data?.dynamic_account_expire_min
							}
							style={{
								height: '50px !important'
							}}
							type='number'
							onChange={(e) => setExpireAt(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleExpireAt}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Fiat Account Limit</h3>
					<p>Set limit for amount of fiat account created</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={3}
							defaultValue={settings?.data?.data?.data?.fiat_account_limit}
							type='number'
							onChange={(e) => setFiatLimit(e)}
							// name='fiat'
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleFiatChange}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Crypto Account Limit</h3>
					<p>Set limit for amount of crypto account created</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={3}
							defaultValue={settings?.data?.data?.data?.crypto_account_limit}
							type='number'
							onChange={(e) => setCryptoLimit(e)}
							// name='fiat'
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleCryptoChange}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Fiat Personal Limit</h3>
					<p>Set limit for amount in a personal fiat account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.fiat_personal_limit}
							type='number'
							onChange={(e) => setFiatAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('fiat_personal')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Crypto Personal Limit</h3>
					<p>Set limit for amount in a personal crypto account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.crypto_personal_limit}
							type='number'
							onChange={(e) => setCryptoAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('crypto_personal')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Fiat Business Limit</h3>
					<p>Set limit for amount in a business fiat account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.fiat_business_limit}
							type='number'
							onChange={(e) => setFiatAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('fiat_business')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification-block'>
				<div className='info'>
					<h3>Crypto Business Limit</h3>
					<p>Set limit for amount in a business crypto account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.crypto_business_limit}
							type='number'
							onChange={(e) => setCryptoAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('crypto_business')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
		</Styles>
	);
};

export default Wallet;
