import styled from 'styled-components';

export const Styles = styled.div`
	// .ant-input-affix-wrapper,
	.ant-input-affix-wrapper-lg {
		padding: 6.5px 16px !important;
	}
	.ant-input-affix-wrapper > input.ant-input {
		padding-left: 12px;
	}
	.ant-input-affix-wrapper {
		width: ${({ width }) => width || '100%'};
		background: #ffffff;
		border: 0.7px solid #dfdfdf;
		box-sizing: border-box;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
	}
`;
