import React, { useState } from "react";
import Layout from "../../../components/layouts";
import { Spacer } from "../../../components/spacer/styles";
import { Styles } from "./styles";
import RouterTabs from "../../../components/Tabs/routerTab";
import Overview from "./overview";
import Payout from "./transactions/index";
import SavedAccount from "./saveAccount/index";
import SavedAddress from "./saveAddress/index";
import ModalFrame from "../../../components/modal/ModalFrame";
import FxOpsForm from "./form";
import Button from "../../../components/button";
import { ApproveTransactionSummary } from "./summary/Summary";
import { useMutation, useQueryClient } from "react-query";
import { ToastMessage, type } from "../../../utils/toastr";
import {
  createFXopsAccount,
  createFXopsAddress,
} from "../../../services/fxOps.services";

const links = [
  {
    path: "/dashboard/fxOps",
    title: "Transactions",
  },
  {
    path: "/dashboard/fxOps/saved-accounts",
    title: "Saved accounts",
  },
  {
    path: "/dashboard/fxOps/saved-address",
    title: "Saved address",
  },
];

const routes = [
  {
    path: "/",
    component: <Payout />,
  },
  {
    path: "/saved-accounts",
    component: <SavedAccount />,
  },
  {
    path: "/saved-address",
    component: <SavedAddress />,
  },
];

const Index = () => {
  const queryClient = useQueryClient();

  const [visibleSavedAccount, setVisibleSavedAccount] = useState(false);
  const [visibleWalletAddress, setVisibleWalletAddress] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const createAccountMutation = useMutation(
    async (data) => {
      await createFXopsAccount(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAccounts");
        ToastMessage(type.SUCCESS, "Account successfully created");
        setVisibleSavedAccount(false);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const createAddressMutation = useMutation(
    async (data) => {
      await createFXopsAddress(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAddress");
        ToastMessage(type.SUCCESS, "Address successfully created");
        setVisibleWalletAddress(false);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showDeposit = () => {
    setVisibleSavedAccount(true);
  };
  const showPayout = () => {
    setVisibleWalletAddress(true);
  };

  const showConfirm = () => {
    setVisibleWalletAddress(false);
    setVisibleConfirm(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setVisibleSavedAccount(false);
    setVisibleWalletAddress(false);
    setVisibleConfirm(false);
  };

  const handleCreateAccount = (values) => {
    console.log(values);
    createAccountMutation.mutate(values);
  };

  const handleCreateaddress = (values) => {
    console.log(values,'fx');
    createAddressMutation.mutate(values);
  };

  return (
    <>
      <Layout title={"FX Ops"}>
        <Styles>
          <Overview />
          <Spacer height="30px" />
          <RouterTabs
            routes={routes}
            links={links}
            type="fxOps"
            handleSavedAccount={showDeposit}
            handleWalletAddress={showPayout}
            // han
            //   handleDownload={handleDownload}
            //   handleSwapOverview={handleSwapOverview}
          />
        </Styles>
      </Layout>
      <ModalFrame
        visible={visibleSavedAccount}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
            borderRadius="5px"
          />,
          <Button
            Styles
            text="Create"
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="editMerchant"
            loading={createAccountMutation.isLoading}
            style={{ padding: "0.85rem 50px" }}
            borderRadius="5px"
          />,
        ]}
        width="600px"
        pad=".5rem"
      >
        <FxOpsForm handleSubmit={handleCreateAccount} type="" />
      </ModalFrame>
      <ModalFrame
        visible={visibleWalletAddress}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
            borderRadius="5px"
          />,
          <Button
            Styles
            text="Create"
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="editMerchant"
            loading={createAddressMutation.isLoading}
            style={{ padding: "0.85rem 50px" }}
            borderRadius="5px"
          />,
        ]}
        pad="1rem"
      >
        <FxOpsForm type="wallet" handleSubmit={handleCreateaddress} />
      </ModalFrame>

      <ModalFrame
        visible={visibleConfirm}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={null}
      >
        <ApproveTransactionSummary />
      </ModalFrame>
      <ModalFrame handleOk={handleOk} handleCancel={handleCancel} footer={null}>
        <ApproveTransactionSummary />
      </ModalFrame>
    </>
  );
};

export default Index;
