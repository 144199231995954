import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { SearchOutlined } from '@ant-design/icons';
import { Container } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { getTransactionsSwap } from '../../../../services/Transactions.service';
import { TransactionTableHeadSwap } from '../../../../utils/constants';
import { formatType } from '../../../../utils/helpers';
import { ToastMessage, type } from '../../../../utils/toastr';
import {
	ApproveTransactionSummary,
	QRScreen
} from '../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import CustomTable from '../table';
import { SearchInputStyles } from '../withdraws/styles';
import { Container1, Styles } from './styles';

const Index = () => {
	const queryClient = useQueryClient();

	const [visible, setVisible] = React.useState(false);

	const [value, setValue] = React.useState('All');
	const [currency, setCurrency] = React.useState('All');
	const [statusValue, setStatusValue] = React.useState('All');
	const [selectedDateTo, setSelectedDateTo] = React.useState('All');
	const [selectedDateFrom, setSelectedDateFrom] = React.useState('All');
	const [settlementVisible, setSettlementVisible] = React.useState(false);
	const [id, setId] = React.useState(null);

	const [filter, setFilter] = useState({ page: 1 });

	const [clicked, setClicked] = useState('false');

	const [status, setStatus] = React.useState('');
	const [qrCode, setQrCode] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const [selectedIndex, setSelectedIndex] = useState([]);

	const [form] = Form.useForm();

	const result = useQuery(
		'getTransactionsSwap',
		async () => await getTransactionsSwap(search, filter),
		{
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			},
			refetchOnWindowFocus: false
		}
	);

	// const mutation = useMutation(
	// 	async (transId) => {
	// 		await addSettlementTime(transId);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('getTransactionsSwap');
	// 			ToastMessage(type.SUCCESS, 'settlement time has been added');
	// 			// setTimeout(() => {
	// 			// 	setSettlementVisible(false);
	// 			// }, 1000);
	// 			// form.resetFields();
	// 		},
	// 		onError: (error) => {
	// 			ToastMessage(type.ERROR, error.response.data.message);
	// 		}
	// 	}
	// );
	// const refreshMutation = useMutation(
	// 	async (transId) => {
	// 		await refreshTransactionTime(transId);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('getTransactionsSwap');
	// 			ToastMessage(type.SUCCESS, 'settlement time has been added');
	// 			// setTimeout(() => {
	// 			// 	setSettlementVisible(false);
	// 			// }, 1000);
	// 			// form.resetFields();
	// 		},
	// 		onError: (error) => {
	// 			ToastMessage(type.ERROR, error.response.data.message);
	// 		}
	// 	}
	// );

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const onChangeStatus = (e) => {
		const { value } = e.target;
		setStatusValue(value);
		setFilter({ ...filter, status: value });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const handleCurrencyChange = (e) => {
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		console.log('value:', value);
		setSearch(value);
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.status === 'All') {
			delete filter.status;
		}
		setClicked('true');
		console.log(filter);
		result.refetch(filter);
	};

	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
	};

	const handleCancel = () => {
		setSettlementVisible(false);
		setVisible(false);
	};

	// const handleAddSettlementTime = (e) => {
	// 	// setSettlementVisible(true);
	// 	// setId(e);
	// 	// try {
	// 	mutation.mutate(e);
	// 	// } catch (error) {
	// 	// 	ToastMessage(type.ERROR, error.response.data.message);
	// 	// }
	// 	console.log(e);
	// };

	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleCancelqr = () => {
		setVisible(false);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	// if (result.isLoading) {
	// 	return <Loader />;
	// }

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}

	return (
		<>
			<Styles>
				<Container1>
					<Container className='input_container' width='20%'>
						<SearchInputStyles>
							<Input
								// size='large'
								placeholder='Search reference no.'
								bordered={false}
								style={{
									border: '0.7px solid #dfdfdf',
									boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
									height: '45px',
									background: 'transparent'
								}}
								onChange={handleSearch}
								onPressEnter={() => result?.refetch(search)}
								prefix={
									<SearchOutlined
										style={{
											padding: 0
										}}
										onClick={() => result?.refetch(search)}
									/>
								}
							/>
						</SearchInputStyles>
					</Container>
					<Filters
						source='transaction'
						currency={currency}
						setCurrency={setCurrency}
						statusValue={statusValue}
						value={value}
						handleSelectFromChange={handleSelectFromChange}
						handleSelectToChange={handleSelectToChange}
						onChange={onChange}
						onChangeStatus={onChangeStatus}
						selectedDateFrom={selectedDateFrom}
						selectedDateTo={selectedDateTo}
						setFilter
						handleCurrencyChange={handleCurrencyChange}
						refetch={handleSubmit}
						loading={clicked === 'true' && result.isRefetching}
					/>
				</Container1>
				{result?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={TransactionTableHeadSwap}
						tableBody={result?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						// handleReadAll={() => []}
						pageSize={10}
						paginator
						// handleAddSettlementTime={handleAddSettlementTime}
						handleApprove={showModal}
						handleReject={showModal}
						handlePageChange={handlePageChange}
						meta={result?.data?.data?.data?.meta}
						selectedIndex={selectedIndex}
						setSelectedIndex={setSelectedIndex}
					/>
				)}
			</Styles>

			<ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
				<ApproveTransactionSummary
					id={id}
					handleQrScreen={handleqrCode}
					swapAction={status}
					setVisible={setVisible}
				/>
				<ModalFrame
					visible={qrCode}
					handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame>
		</>
	);
};

export default Index;

const FormLayout = ({ form, mutation }) => {
	const handleSubmit = async (values) => {
		const payload = { time: Number(values.time) };
		try {
			await mutation.mutate(payload);
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message);
		}
	};

	return (
		<>
			<Form
				layout={'vertical'}
				form={form}
				initialValues={{
					layout: 'vertical',
					size: 'large'
				}}
				size='large'
				onFinish={handleSubmit}
				id='addTime'
			>
				<Form.Item
					label='Add time'
					style={{ marginBottom: '0' }}
					name={'time'}
					rules={[{ required: true, message: 'Time is Required' }]}
				>
					<Input
						placeholder='input placeholder'
						type='number'
						addonAfter='MINS'
						width={'100%'}
					/>
				</Form.Item>
			</Form>
		</>
	);
};
