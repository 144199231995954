import styled from 'styled-components';
// import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	.currencies,settlement {
		.search {
			
			width: 243px;
			.ant-input-affix-wrapper {
				width: 243px;
				// height: 48px;
				background: #FFFFFF;
				border: 0.7px solid #DFDFDF;
				box-sizing: border-box;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
				border-radius: 8px;
			}
		}
		h3 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #1a1f36;
		}
		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			padding:0;
			text-decoration: underline;∂
		}
		padding-bottom: 20px;
	}

	h3 {
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		color: #061F39;
		margin-bottom:20px;
	}
	.crypto,.local {
		.ant-checkbox-wrapper {
			.ant-checkbox+span {
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 21px;
					color: #061f39;
					padding-right:0;
				}
				.ant-checkbox-inner {
					border: 1px solid #ccc
					border-radius: 4px;
				}
			}
			.ant-checkbox-checked {
				.ant-checkbox-inner {
					background: #1a2cce;
					border-radius: 4px;
				}
			}
		}
	}
	.crypto,.local{
		.ant-checkbox-wrapper {
			margin-left:0;
			// padding-right:80px;
			margin-bottom:32px;
			width:30%;
			
			@media(max-width: 1200px) {
				width:50%;
			}
		}
	}
	.local {
		padding-bottom: 40px;
	}

	.local-text{
		padding-top: 40px;
	}

	.btn {
		.add-btn {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			padding:0;
			text-align: right;
			text-decoration-line: underline;
		}

		.save {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			padding: 15px 55px;
		}
	}
`;

export const FormContainer = styled('div')`
	.upload {
		svg {
			margin-right: 24px;
		}

		h4 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
			margin-bottom: 0;
			padding-bottom: 1.5px;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #797979;
			margin-bottom: 0;
		}

		padding-bottom: 50px;
	}

	// .ant-row
	.currency,
	.deposit,
	.withdraw,
	.swap {
		border-bottom: 0.3px solid #bfbfbf;

		.ant-form-item {
			// width: 48%;
			width: 392px;
			.ant-input,
			.ant-select .ant-select-selector {
				height: 51.52px;
				background: #ffffff;
				border: 1px solid #d7d7d7;
				box-sizing: border-box;
				border-radius: 5px;
				line-height: 40px;
				padding-top: 5px;
			}

			// .ant-select-single.ant-select-show-arrow
			// 	.ant-select-selection-placeholder {
			// 	// padding-top: 5px;
			// }
			// // .ant-select .ant-select-selector .ant-select-selection-item
			// .ant-select-selection-search-input {
			// 	line-height: 21px;
			// }
		}
	}
	.deposit,
	.withdraw,
	.swap {
		padding-top: 30px;
		h3 {
			padding-bottom: 1rem;
		}
	}

	.swap {
		border: none;
	}
`;
