import styled from "styled-components";

export const Styles = styled.div`
  width: 100%;
  padding: 5px 45px;
  @media (max-width: 700px) {
    padding: 20px 4%;
  }
`;

export const FormStyle = styled.div`
  .currency {
    margin-bottom: 0;
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 5px;
      height: 46px;
      padding: 2px 11px;
    }
  }

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5b5b5b;
  }

  .amount {
    margin-top: 20px;
    margin-bottom: 0;

    // .ant-input-number-wrapper
    .ant-input-number-group {
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      border-left-style: none !important;
      border-right-style: none !important;
    }

    // .ant-col ant-form-item-control
    .ant-input-number-lg input {
      background: #ffffff;
      box-sizing: border-box;
      height: 46px;
      border-top-style: none !important;
      border-bottom-style: none !important;
      border-left-style: none !important;
    }

    .ant-input-number-group-addon {
      border: 1px solid #d7d7d7;
      border-top-style: none !important;
      border-bottom-style: none !important;
      padding: 12px 15px !important;
      margin-right: 5px;
      font-weight: 700;
    }
  }
  .rate {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .fund {
    margin-top: 20px;
    margin-bottom: 0;

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #1a2cce;
        :after {
          background: #1a2cce;
        }
      }
    }
  }
`;
