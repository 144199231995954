import { useCallback, useState } from 'react';

export const toTitleCase = (text) => {
	return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
};

export const formattedDate = (
	date,
	dateFormat = {
		day: 'numeric',
		month: 'short',
		year: 'numeric'
	}
) => {
	const format = new Date(date);
	return format.toLocaleDateString('en-GB', dateFormat);
};
export const formattedTransactionDate = (
	date,
	dateFormat = {
		day: 'numeric',
		month: 'long',
		year: 'numeric'
	}
) => {
	const format = new Date(date);
	return format.toLocaleDateString('en-GB', dateFormat);
};

export const getTime = (minutes) => {
	let hours = Math.floor(minutes / 60);
	hours = hours < 10 ? `0${hours}` : hours;
	let min = minutes % 60;
	min = min < 10 ? `0${min}` : min;

	return `${hours}:${min}`;
};

export const truncateStr = (str, n) => {
	return str?.length > n ? str.substr(0, n - 1) + '...' : str;
};

export function formatType(date) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}

export function useViewportWidth() {
	const [width, setWidth] = useState(window.innerWidth);

	const setSize = useCallback(() => {
		setWidth(window.innerWidth || 0);
	}, []);

	window.addEventListener('resize', setSize, { passive: true });
	window.addEventListener('orientationchange', setSize, { passive: true });
	return width;
}

export const formattedTime = (time) => {
	const format = new Date(time);
	return format.toLocaleTimeString('en-US');
};
