import React from "react";
import Fields from "./fields";
import { Flex, Frame } from "../../../components/box";
import logo from "../../../assets/images/logo.svg";
import { ForgotPasswordContainer, ForgotPasswordText } from "./styles";

const Signup = () => {
  return (
    <ForgotPasswordContainer>
      <Flex
        className="form-container"
        width={"530px"}
        flexDir={"column"}
        margin={"auto"}
      >
        <Flex>
          <Frame height={"150px"} width={"150px"}>
            <img alt="logo" src={logo} />
          </Frame>
        </Flex>
        <ForgotPasswordText>Security Verification</ForgotPasswordText>
        <Fields />
      </Flex>
    </ForgotPasswordContainer>
  );
};

export default Signup;
