import * as yup from "yup";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import React from "react";
import { Flex } from "../../../components/box";
import Button from "../../../components/button";
import { ForgotPasswordLink, FormContainer } from "./styles";
import { Form, Field, ErrorMessage, Formik } from "formik";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../components/input/styles";
import { forgotPassword } from "../../../services/Auth.service";
import { ToastMessage, type } from "../../../utils/toastr";

const Fields = () => {
  const initialValues = {
    email: "",
  };

  const validateSchema = yup.object().shape({
    email: yup.string().email().required("please provide an email"),
  });
  let navigate = useNavigate();
  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={async (email, { setSubmitting, resetForm }) => {
          try {
            const success = await forgotPassword(email);
            if (success.data)
              navigate({
                pathname: "/forgotpassword/sent/",
                search: `?${createSearchParams({
                  query: success.data.message,
                })}`,
              });
          } catch (error) {
            ToastMessage(type.ERROR, error.response.data.message);
          }
        }}
        validationSchema={validateSchema}
      >
        {({ isSubmitting, handleChange, values: { email }, handleBlur }) => (
          <Form>
            <Flex flexDir={"column"} gap={"30px"}>
              <InputStyle>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type={"text"}
                    onChange={handleChange}
                    name={"email"}
                    value={email}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
                <ErrorMessage name={"email"} component={"div"} />
              </InputStyle>
              <Button
                text={"Send me a reset link"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type="submit"
                borderRadius={"5px"}
                loading={isSubmitting}
              />
              <ForgotPasswordLink>
                <Link to={"/"}>Return to Login</Link>
              </ForgotPasswordLink>
            </Flex>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default Fields;
