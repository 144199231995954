import React from 'react';
import ModalFrame from '../modal/ModalFrame';
import { AiFillWarning } from 'react-icons/ai';
import { Button, Space } from 'antd';
import styled from 'styled-components';

const OnLoadPopUp = ({
	visible,
	handleCancel,
	handleOk,
	handleProcessorDetails
}) => {
	return (
		<ModalFrame
			visible={visible}
			handleOk={handleOk}
			handleCancel={handleCancel}
			footer={null}
			width={350}
			pad='0'
		>
			<Styles>
				<p>
					<AiFillWarning
						style={{
							fontSize: '150px',
							borderRadius: '30px',
							color: '#CE1A1A'
						}}
					/>
				</p>

				<h3
					style={{
						// marginTop: '12px',
						// marginBottom: '8px',
						fontWeight: 500,
						fontSize: '24px',
						lineHeight: '32px',
						color: '#212121',
						paddingBottom: 2
					}}
				>
					Low Balance!
				</h3>
				<p
					style={{
						fontWeight: '400',
						fontSize: '15px',
						lineHeight: '22px',
						letterSpacing: '0.03em',
						color: '#6F6F6F'
					}}
				>
					Provider balance is low
				</p>
				<Space
					style={{
						display: 'flex',
						flexDirection: 'column'
						// marginBottom: 8
					}}
					align='center'
				>
					<Button
						style={{
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '17px',
							textDecoration: 'underline',
							color: '#1a2cce',
							marginTop: '8px',
							border: 'none',
							background: 'transparent',
							boxShadow: 'none'
						}}
						onClick={handleProcessorDetails}
					>
						View status
					</Button>

					<Button
						style={{
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '17px',
							textDecoration: 'underline',
							color: '#212121',
							// marginTop: '8px',
							border: 'none',
							background: 'transparent',
							boxShadow: 'none'
						}}
						onClick={handleCancel}
					>
						Dismiss
					</Button>
				</Space>
			</Styles>
		</ModalFrame>
	);
};

export default OnLoadPopUp;

const Styles = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	Button {
		span {
			text-decoration: underline;
		}
	}

	.ant-space {
		gap: 0;
	}
`;
