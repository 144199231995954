import { InputNumber } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Container, Flex } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
import { Loader } from '../../../../../../components/loader';
import {
	merchantRateRefresh,
	merchantRateRefreshUpdate
} from '../../../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../../../utils/toastr';
import { Styles } from './styles';

const Index = ({ merchantID }) => {
	const queryClient = useQueryClient();

	const [refresh, setRefresh] = useState(Number);

	const refreshRate = useQuery(
		'getRefreshRate',
		async () => await merchantRateRefresh(merchantID),
		{
			onError: (error) => <p> {error.response.data.mesaage}</p>
		}
	);

	const refreshMutation = useMutation(
		async (data) => {
			await merchantRateRefreshUpdate(merchantID, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getRefreshRate');
				setTimeout(() => {
					ToastMessage(
						type.SUCCESS,
						'Merchant rate refresh time successfully set'
					);
				}, 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const handleChange = (e) => {
		setRefresh(e);
	};

	const handleSubmitRefreshRate = async () => {
		try {
			await refreshMutation.mutate({
				rate_refresh: Number(refresh)
			});
		} catch (error) {
			ToastMessage(type.ERROR, error.response.data.message);
		}
	};

	if (refreshRate.isFetching) {
		return <Loader />;
	}

	if (refreshRate.isError) {
		return <span>Error: {refreshRate?.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container>
					<Flex flexDir='row' justifyContent='space-between' className='rate'>
						<div>
							<h3>Market refresh rate</h3>
							<p>Add rate refresh for asset swap</p>
						</div>
						<div>
							<InputNumber
								className='refresh-rate'
								min={0}
								defaultValue={refreshRate?.data?.data?.data?.rate_refresh}
								type='number'
								onChange={handleChange}
								addonAfter='Secs'
							/>
						</div>
					</Flex>

					<Flex flexDir='row' justifyContent='flex-end' className='btn'>
						<Button
							Styles
							text={'Save changes'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							onClick={handleSubmitRefreshRate}
							size='md'
							type='submit'
							// className='save'
							style={{ padding: '0.9375rem 60px' }}
							loading={refreshMutation.isLoading}
						/>
					</Flex>
				</Container>
			</Styles>
		</>
	);
};

export default Index;
