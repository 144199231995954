import styled from 'styled-components';

export const ApprovedStyle = styled.div`
	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;

		/* Normal-side-panel text */

		color: #1a1f36;
	}
	h4 {
		font-style: normal;
		font-weight: 700;
		font-size: 19px;
		line-height: 23px;
		/* identical to box height */

		text-align: right;

		color: #1a2cce;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;

		color: #535353;
		text-align: left;
		padding-bottom: 37px;
	}
	.selected-transaction {
		background: #f3f6fb;
		border-radius: 2px;
		padding: 20px 15px;
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			/* identical to box height */

			letter-spacing: 0.05em;
			padding-bottom: 0;

			color: #00063f;
			:last-child {
				font-weight: 700;
			}
		}
		margin-bottom: 30px;
	}
	.ant-form-item-control-input {
		// border: 2px solid #ccc !important;
		background: #ffffff;
		border: 1px solid #d7d7d7;
		box-sizing: border-box;
		border-radius: 5px;
	}

	.ant-input {
		// border-right-style: none;
		border: none !important;

		:hover {
			border-color: none;
			outline: 0;
		}
		:focus {
			border: none;
			outline: 0;
		}
	}

	.ant-btn {
		height: 46px !important;
		padding: 15px;
		width: 10%;
		border: none;
		:focus {
			outline: 0;
		}
		:hover {
			border-color: none;
			outline: 0;
		}
	}

	.ant-tooltip-open {
		border: none;
	}

	.code {
		margin-top: 30px;
		background: #f3f6fb;
		border-radius: 2px;
		padding: 80px auto;
		height: 280px;
	}
`;
