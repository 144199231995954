import React, { useEffect } from 'react';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { CrossFeesTableHead } from '../../../../utils/constants';
import { Container1, FormContainer, Styles } from './styles';
import CustomTable from './table';
// import { ToastMessage, type } from '../../../../utils/toastr';
import { Input, Select } from 'antd';
import { useState } from 'react';
import {
	useMutation,
	useQuery,
	useQueryClient
	// useQueryClient
} from 'react-query';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import {
	addCrossFee,
	deleteCrossFee,
	disableCrossFee,
	editCrossFee,
	enableCrossFee,
	getAllCurrencies,
	getCrossFee,
	getpaymentMethodDeposit,
	getpaymentMethodWithdrawal
} from '../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../utils/toastr';
import { FormLayout } from './form';

const { Option } = Select;
const Index = () => {
	// const { currencies } = useAuthState();

	const queryClient = useQueryClient();
	const crossFee = useQuery('crossfees', async () => await getCrossFee());

	const [action, setAction] = React.useState('add');
	const [feeId, setFeeId] = React.useState('');
	const [visible, setVisible] = React.useState(false);
	const [otpVisible, setOtpVisible] = React.useState(false);
	const [currency, setCurrency] = React.useState('All');

	const [editVisible, setEditVisible] = React.useState(false);
	const [resetVisible, setResetVisible] = React.useState(false);
	const [deleteVisible, setDeleteVisible] = React.useState(false);
	const [suspendVisible, setSuspendVisible] = React.useState(false);
	const [confirmVisible, setConfirmVisible] = React.useState(false);
	const [initialEditValues, setInitialEditValues] = React.useState({});
	const [requiredAction, setRequiredAction] = React.useState('');
	const [formData, setFormData] = React.useState({});
	const [suspendAction, setSuspendAction] = useState('');
	const [isActive, setIsActive] = useState();
	const [filter, setFilter] = React.useState({
		page: 1
	});
	const [search, setSearch] = React.useState('');
	const [deposit, setDeposit] = useState([]);
	const [withdraw, setWithdraw] = useState([]);
	const [methods, setMethods] = useState([]);
	const [optionValue, setOptionValue] = useState([]);
	const [statusValue, setStatusValue] = React.useState('All');
	const [typeValue, setTypeValue] = React.useState('All');
	const [envValue, setEnvValue] = React.useState([]);
	const [merchantId, setMerchantId] = React.useState();
	const [clicked, setClicked] = useState('false');

	const deleteCrossFeeMutation = useMutation(
		async (data) => {
			await deleteCrossFee(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('crossfees');
				ToastMessage(type.SUCCESS, 'cross fee successfully deleted');
				// setDeleteVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const crossFeesMutation = useMutation(
		async (data) => {
			await addCrossFee(merchantId, data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully created');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const editCrossFeesMutation = useMutation(
		async (data) => {
			await editCrossFee(feeId, data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setEditVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully updated');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const enableCrossFeesMutation = useMutation(
		async (data) => {
			await enableCrossFee(data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setEditVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully updated');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const disableCrossFeesMutation = useMutation(
		async (data) => {
			await disableCrossFee(data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setEditVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully updated');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	useQuery(
		'getpaymentMethodDeposit',
		async () => await getpaymentMethodDeposit(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Deposit'));
				setDeposit(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);

	useQuery(
		'getpaymentMethodWithdrawal',
		async () => await getpaymentMethodWithdrawal(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Withdraw'));
				setWithdraw(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);
	const checkToken = localStorage.getItem('access_token');

	const currencies = useQuery(
		'getAllCurrencies',
		async () => await getAllCurrencies(),
		{
			// onSuccess: (res) => {
			// 	setCurrencies(res?.data?.data);
			// },
			onError: (error) => <p> {error.response.data.mesaage}</p>,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: !!checkToken
		}
	);

	useEffect(() => {
		setMethods([...deposit, ...withdraw]);
	}, [deposit, withdraw]);

	const showModal = () => {
		setVisible(true);
	};

	const handleOk = () => {
		if (resetVisible === true) {
			setRequiredAction('reset');
			setResetVisible(false);
			setOtpVisible(true);
		} else if (suspendVisible === true) {
			setRequiredAction(suspendAction);
			setSuspendVisible(false);
			setOtpVisible(true);
		} else if (deleteVisible === true) {
			setRequiredAction('password');
			setDeleteVisible(false);
			setOtpVisible(true);
		}
		setAction('add');
	};

	const handleCancel = () => {
		setVisible(false);
		setConfirmVisible(false);
		setEditVisible(false);
		setSuspendVisible(false);
		setResetVisible(false);
		setDeleteVisible(false);
		setOtpVisible(false);
		setAction('add');
	};

	const handleEdit = (id) => {
		setAction('edit');
		setFeeId(id);

		crossFee?.data?.data?.data?.find(
			(item) => item.id === id && setInitialEditValues(item)
		);

		setTimeout(() => setEditVisible(true), 500);
	};

	const onChangeType = (e) => {
		const { value } = e.target;
		setTypeValue(value);
		setFilter({ ...filter, type: value });
	};

	const handleCurrencyChange = (e) => {
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleEnableDisable = (id, status) => {
		// setFeeId(id);
		if (status === true) {
			console.log(id, status, 'false');
			disableCrossFeesMutation.mutate(id);
		} else {
			enableCrossFeesMutation.mutate(id);
			console.log(id, status, 'true');
		}
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		// setTimeout(() => result.refetch());
	};
	const handleSubmitFilter = () => {
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.type === 'All') {
			delete filter.status;
		}
		setClicked('true');
		crossFee.refetch(filter);
	};
	const handleDelete = (id) => {
		deleteCrossFeeMutation.mutate(id);
	};

	const handleSubmit = (values) => {
		const data = {
			to_currency: values?.to_currency,
			from_currency: values?.from_currency,
			transaction_type:
				values?.transaction_type === null
					? null
					: values?.transaction_type === 'withdraw'
					? 'withdrawal'
					: 'deposit',
			payment_methods: [values?.payment_method?.toString()],
			fees: [
				{
					fee_type:
						action === 'edit' && values?.fee_type === undefined
							? initialEditValues?.fees[0]?.fee_type
							: values?.fee_type,

					fee:
						action === 'edit' && values?.fee === undefined
							? initialEditValues?.fees[0]?.fee
							: values?.fee,

					chain:
						action === 'edit' && values?.chain === undefined
							? initialEditValues?.fees[0]?.chain
							: values?.chain,
					max_fee:
						action === 'edit' && values?.max === undefined
							? initialEditValues?.fees[0]?.max
							: values?.max,
					min_fee:
						action === 'edit' && values?.min === undefined
							? initialEditValues?.fees[0]?.min
							: values?.min
				}
			],
			fee_currency: values?.fee_currency,
			optional_transaction_type:
				values?.optional_transaction_type === undefined
					? null
					: values?.optional_transaction_type === 'withdraw'
					? 'withdrawal'
					: 'deposit',
			optional_payment_methods: [
				values?.optional_payment_method === undefined
					? null
					: values?.optional_payment_method?.toString()
			],

			optional_fees: [
				{
					fee_type:
						values?.optional_fee_type === undefined
							? null
							: values?.optional_fee_type,
					fee:
						values?.optional_fee_amount === undefined
							? null
							: values?.optional_fee_amount,
					chain:
						values?.optional_chain === undefined
							? null
							: values?.optional_chain,
					max_fee:
						values?.optional_max === undefined ? null : values?.optional_max,
					min_fee:
						values?.optional_min === undefined ? null : values?.optional_min
				}
			],
			optional_fee_currency: values?.fee_currency,
			env: values?.env
		};

		console.log(data);

		if (action === 'add') {
			crossFeesMutation.mutate(data);
		} else if (action === 'edit') {
			editCrossFeesMutation.mutate(data);
		}
	};

	if (crossFee.status === 'error') {
		return <span>Error: {crossFee.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container1>
					<Flex
						justifyContent='end'
						flex-direction='row'
						style={{
							// marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Button
							Styles
							text={'New Fee'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='submit'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							borderRadius={'3px'}
							onClick={showModal}
						/>
					</Flex>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='center'
						style={{
							// marginTop: '44px',
							marginBottom: '21px'
						}}
						gap={80}
					>
						<Flex
							justifyContent='start'
							// gap={40}
							width='auto'
						>
							<Input
								placeholder='Search'
								size='large'
								style={{
									width: '271px'
								}}
							/>
						</Flex>
						<Filters
							source='crossFee'
							currency={currency}
							setCurrency={setCurrency}
							statusValue={statusValue}
							typeValue={typeValue}
							onChangeType={onChangeType}
							setFilter
							handleCurrencyChange={handleCurrencyChange}
							refetch={handleSubmitFilter}
							loading={clicked === 'true' && crossFee.isRefetching}
						/>
					</Flex>
				</Container1>

				{crossFee?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={CrossFeesTableHead}
						tableBody={crossFee?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={5}
						handleEdit={handleEdit}
						handleEnableDisable={handleEnableDisable}
						handlePageChange={handlePageChange}
						paginator
						meta={crossFee?.data?.data?.data?.meta}
						handleDelete={handleDelete}
						paymentMethod={methods}
					/>
				)}
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : editVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
						key='back'
					/>,
					<Button
						Styles
						text={action === 'add' ? 'Confirm' : 'Update'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						// fullwidth
						size='md'
						type='submit'
						border={'0.7px solid #BFBFBF'}
						boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
						borderRadius={'3px'}
						id='cross-fee'
						key='submit'
						form='cross-fee'
						loading={
							crossFeesMutation.isLoading || editCrossFeesMutation.isLoading
						}
					/>
				]}
				destroyOnClose={true}
				width={'750px'}
			>
				<FormContainer>
					<h3 style={{ textAlign: 'left' }}>
						{action === 'add' ? 'Add New Cross Fees' : 'Edit Cross Fees'}
					</h3>
					<FormLayout
						action={action}
						initialEditValues={initialEditValues}
						paymentMethod={methods}
						currencies={currencies?.data?.data?.data}
						handleSubmit={handleSubmit}
						onChange={setEnvValue}
						setMerchantId={setMerchantId}
					/>
				</FormContainer>
			</ModalFrame>
			{/* <ModalFrame
				visible={confirmVisible}
				handleOk={(e) => setConfirmVisible(false)}
				handleCancel={handleCancel}
				footer={null}
			>
				<FormContainer>
					<ConfirmMessage />
				</FormContainer>
			</ModalFrame>
			<ModalFrame
				visible={
					suspendVisible === true
						? suspendVisible
						: resetVisible === true
						? resetVisible
						: deleteVisible
				}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleOk}
					/>
				]}
			>
				{suspendVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>
							{isActive === true ? 'Suspend user' : 'Unsuspend User'}
						</h3>
						<p>
							{`Are you sure you want to ${
								isActive === true ? 'suspend' : 'unsuspend'
							} the user`}
						</p>
					</>
				) : resetVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>Reset User 2FA</h3>
						<p>Are you sure you want to reset user 2fa</p>
					</>
				) : (
					<>
						<h3 style={{ textAlign: 'center' }}>Change User Password</h3>
						<p>Are you sure you want to change this user's password </p>
					</>
				)}
			</ModalFrame>
			<ModalFrame
				visible={otpVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					id={userId}
					// handleQrScreen={handleqrCode}
					// swapAction={status}
					status={requiredAction}
					setVisible={setOtpVisible}
					addEditData={formData}
					showConfirmModal={showConfirmModal}
				/>
				 <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame> */}
		</>
	);
};

export default Index;
