import styled from "styled-components";

export const CustomTableMain = styled.section`
  .container {
    /* height: 450px; */
    overflow: auto;
    /* @media (max-width: 600px) {
      height: 350px;
    } */
  }
  background: #fff;
  padding-bottom: ${({ paginator }) => !paginator && "20px"};
  width: 100%;
  table {
    background: #fff;
    border-collapse: collapse;
    text-align: start;
    width: 100%;
    overflow: auto;
    @media (max-width: 600px) {
      width: 700px;
    }
    thead {
      background: ${({ theadBkColor }) => theadBkColor || "#F1F4F8"};
      position: sticky;
      top: 0;
      z-index: 1;
      tr {
        th {
          text-align: start;
          color: #000a62;
          font-size: 12px;
          padding: 2em 0 1rem 1.8rem;
          /* min-width: 160px; */
        }
      }
    }
    tbody {
      tr {
        background: white;
        cursor: pointer;
        border: none;
        overflow: auto;
        border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
        /* border-bottom: ${({ bottomGap }) =>
          bottomGap ? `${bottomGap} solid   #fff` : "5px solid  #fff "}; */
        &:hover {
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.27);
          background: ${({ rowHovColor }) =>
            rowHovColor || "#fff7ee !important"};
        }
        .readAll {
          color: #17a2b8;
          cursor: pointer;
          font-size: 0.75rem;
        }
        .empty {
          width: max-content;
          .showMoreModal {
            bottom: -2px;
            top: unset;
          }
        }
        /* &:nth-child(2n) {
          background: #d2ccc626;
        } */
      }
    }
  }
`;

export const Td = styled.td`
  padding: 1.2rem 1.8rem;
  font-weight: 400;
  font-size: 14px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    strong {
      font-style: normal;
      font-weight: 4w00;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  span {
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
    color: ${({ tbodyColor }) => tbodyColor || "#1A1F36"};
  }
`;

export const CustomTd = styled.td`
  padding: 1.2rem 1.8rem;
  div {
    padding: 7px;
    text-align: center;
    background-color: ${({ status }) =>
      (status === "pending" && "#FFF2D9") ||
      (status === "success" && "#C8FFC7")};
    color: ${({ status }) =>
      (status === "pending" && "#9C5502") ||
      (status === "success" && "#027200")};
    > span {
      font-size: 14px;
    }
  }
  a {
    text-decoration: underline;
    color: #1a2cce;
  }
  .icon_box {
    border: 0.7px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 34px;
    width: 40px;
    cursor: pointer;
  }
`;

export const Paginator = styled("div")`
  background: white;
  padding: 1em 5px;
  display: ${({ paginator }) => (paginator ? "block" : "none")};
  ul {
    justify-content: flex-end;
  }
`;
