import React from 'react';

import { Styles } from './styles';

import Layout from '../../../components/layouts';
import { Spacer } from '../../../components/spacer/styles';
import RouterTabs from '../../../components/Tabs/routerTab';
// import PaymentSummary from './paymentSummary/PaymentSummary';
// import ModalFrame from '../../../components/modal/ModalFrame';
// import DownloadReport from '../../../components/downloadReport/DownloadReport';
// import {
// 	getTransactionsSummarySwap,
// 	getTransactionsSummaryDeposit,
// 	getTransactionsSummaryWithdrawal,
// 	getTransactionsSummaryFeeCollected,
// 	getTransactionsReportType,
// 	getTransactionsReport
// } from '../../../services/Transactions.service';
import { getBalancesReport } from '../../../services/Balance.service';
import { ToastMessage, type } from '../../../utils/toastr';
import Balances from './allBalances/Balances';
import Pending from './pending/Pending';

const Index = () => {
	const links = [
		{
			path: '/dashboard/balances',
			title: 'All'
		},
		{
			path: '/dashboard/balances/pending',
			title: 'Pending'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Balances />
		},
		{
			path: '/pending',
			component: <Pending />
		}
	];

	// if (deposit.isError || withdrawal.isError || swap.isError) {
	//   return <Flex>Unexpected Error please check</Flex>;
	// }

	const handleDownload = async () => {
		getBalancesReport()
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(`download`, `balances-report.${'csv'}`);
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.log(error);
				ToastMessage(type.ERROR, error?.response?.data?.message);

				if (error.response) {
				} else if (error.request) {
				} else {
				}
			});
	};

	return (
		<>
			<Styles>
				<Layout title={'Balances'}>
					<Spacer height='30px' />

					<RouterTabs
						routes={routes}
						links={links}
						type='transactions'
						handleDownload={handleDownload}
					/>
				</Layout>
			</Styles>
		</>
	);
};

export default Index;
