import React, { useState } from "react";
import { useQuery } from "react-query";
import CustomTable from "../table";

import { Styles, Container1 } from "./styles";
import Search from "../../../../components/search";
import { Loader } from "../../../../components/loader";
import { Container } from "../../../../components/box";
import Filters from "../../../../components/filters/Filters";
import { ToastMessage, type } from "../../../../utils/toastr";
import { ComplianceTableHead } from "../../../../utils/constants";
import { getComplianceApproved } from "../../../../services/Compliance.service";

const Index = () => {
  const [statusValue, setStatusValue] = useState("All");
  const [countryValue, setCountryValue] = useState("All");
  const [input, setInput] = useState("");
  const [clicked, setClicked] = useState("false");

  const [filter, setFilter] = useState({ page: 1 });
  const approvedCompliance = useQuery(
    "getComplianceApproved",
    async () => await getComplianceApproved(filter),
    {
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSelectStatus = (e) => {
    setStatusValue(e);
    setFilter({ ...filter, status: e });
  };

  const handleSelectCountry = (e) => {
    setCountryValue(e);
    setFilter({ ...filter, country: e });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setInput(value);
    setFilter({ ...filter, search: value });
  };

  const handleSubmit = () => {
    if (filter.status === "All") {
      delete filter.status;
    }
    if (filter.country === "All") {
      delete filter.country;
    }
    setClicked("true");
    approvedCompliance.refetch(filter);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => approvedCompliance.refetch());
  };

  if (approvedCompliance.isLoading) {
    return <Loader />;
  }

  if (approvedCompliance.status === "error") {
    return <span>Error: {approvedCompliance.error.message}</span>;
  }

  return (
    <Styles>
      <Container1>
        <Container className="input_container" width={"20%"}>
          <Search
            placeholder={"Search by name."}
            input={input}
            setInput={setInput}
            handleSearch={handleSearch}
          />
        </Container>
        <Filters
          source={"compliance"}
          handleSelectCountry={handleSelectCountry}
          onChangeStatus={handleSelectStatus}
          refetch={handleSubmit}
          statusValue={statusValue}
          countryValue={countryValue}
          loading={clicked === "true" && approvedCompliance.isRefetching}
        />
      </Container1>
      <CustomTable
        theadBkColor="#F1F4F8"
        bottomGap="10px"
        tableHead={ComplianceTableHead}
        tableBody={approvedCompliance?.data?.data?.data?.data}
        rowHovColor="#d2ccc626"
        theadColor="#8e8e8e"
        tbodyColor="#141414"
        pageSize={10}
        paginator
        source="approval"
        handlePageChange={handlePageChange}
        meta={approvedCompliance?.data?.data?.data?.meta}
      />
    </Styles>
  );
};

export default Index;
