import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Wrapper } from '../styles';
import CustomTable from '../../table';
import Search from '../../../../../components/search';
import { Flex, Container } from '../../../../../components/box';
import CustomDropdown from '../../customdropdown/CustomDropdown';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import { PendingApprovalsTableHead } from '../../../../../utils/constants';

import {
	ApproveTransactionSummary,
	QRScreen
} from '../../approvalTransactionSummary/ApproveTransactionSummary';
import { Loader } from '../../../../../components/loader';
import {
	getPendingApprovalsDeposit,
	getPendingApprovalsFiat,
	getPendingApprovalsSwap,
	getPendingApprovalsWithdrawals
} from '../../../../../services/Approval.service';

const Index = () => {
	const [visible, setVisible] = useState(false);
	const [qrCode, setQrCode] = useState(false);
	const [actionTaken, setActionTaken] = useState('approve');
	const [id, setId] = useState(null);
	const [status, setStatus] = useState('approve');
	const [selectedIndex, setSelectedIndex] = useState([]);
	const [input, setInput] = useState('');

	const [filter, setFilter] = useState({ page: 1 });

	const approvalsFiat = useQuery(
		['getPendingApprovalsWithdrawals'],
		async () => await getPendingApprovalsWithdrawals(filter)
	);

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, ref: value });

		setTimeout(() => approvalsFiat.refetch());
	};

	const showModalBulk = (e) => {
		setVisible(true);
		setId(null);
	};
	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
		setSelectedIndex([]);
	};

	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleOk = () => {
		setTimeout(() => {
			setVisible(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleCancelqr = () => {
		setQrCode(false);
	};

	const handleToggle = (value) => {
		console.log(value);
		setStatus(value);
		setActionTaken(value);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => approvalsFiat.refetch());
	};

	if (approvalsFiat.isLoading) {
		return <Loader />;
	}

	if (approvalsFiat.status === 'error') {
		return <span>Error: {approvalsFiat.error.message}</span>;
	}

	// console.log(status);
	return (
		<>
			<Wrapper>
				<Flex
					justifyContent='space-between'
					pad='20px 0'
					className='input_container'
				>
					<Container width={'30%'}>
						<Search
							placeholder={'Search reference no.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>

					<CustomDropdown
						showModal={showModalBulk}
						handleToggle={handleToggle}
						getApprovalsFiat={getPendingApprovalsFiat}
						setVisible={setVisible}
						actionTaken={actionTaken}
						selectedIndex={selectedIndex}
					/>
				</Flex>
				<CustomTable
					theadBkColor='#F1F4F8'
					bottomGap='10px'
					tableHead={PendingApprovalsTableHead}
					tableBody={approvalsFiat?.data?.data?.data?.data}
					rowHovColor='#d2ccc626'
					theadColor='#000A62'
					tbodyColor='#141414'
					pageSize={10}
					paginator
					handleApprove={showModal}
					handleReject={showModal}
					fiat
					setSelectedIndex={setSelectedIndex}
					selectedIndex={selectedIndex}
					actionTaken={actionTaken}
					setActionTaken={setActionTaken}
					setVisible={setVisible}
					meta={approvalsFiat?.data?.data?.data?.meta}
					handlePageChange={handlePageChange}
				/>
			</Wrapper>

			<ModalFrame
				visible={visible}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					approvalsFiat={approvalsFiat?.data?.data?.data?.data}
					id={id}
					handleQrScreen={handleqrCode}
					status={status}
					setVisible={setVisible}
					// actionTaken={actionTaken}
					selectedIndex={selectedIndex}
				/>
			</ModalFrame>
			<ModalFrame
				visible={qrCode}
				handleOk={handleOk}
				handleCancel={handleCancelqr}
				footer={null}
			>
				<QRScreen />
			</ModalFrame>
		</>
	);
};

export default Index;
