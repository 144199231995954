import React, { useState } from "react";
import { Form } from "antd";
import Button from "../../../../../components/button";
import { Container, Flex } from "../../../../../components/box";
import CustomTable from "./table/";
import SearchBox from "./searchbox/";
import { Styles, FormContainer } from "./styles";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import { Loader } from "../../../../../components/loader";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddNew } from "../../../../../assets";
import { FormLayout } from "./forms/formLayout";
import {
  addCurrencyPair,
  deleteCurrencyPair,
  editCurrencyPair,
  getCurrencyPair,
} from "../../../../../services/Settings.service";

const Index = () => {
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [initialEditValues, setInitialEditValues] = useState({});

  const [form] = Form.useForm();

  const result = useQuery(
    "getCurrencyPair",
    async () => await getCurrencyPair()
  );

  const deleteMutation = useMutation(
    async (data) => {
      await deleteCurrencyPair(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCurrencyPair");
        ToastMessage(type.SUCCESS, "currency pair deleted successfully");
        setTimeout(() => {
          setDeleteVisible(false);
          setId("");
          form.resetFields();
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editMutation = useMutation(
    async (data) => {
      await editCurrencyPair(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCurrencyPair"]);
        ToastMessage(type.SUCCESS, "currency pair edited successfully");
        setTimeout(() => {
          setVisible(false);
          setId("");
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const addMutation = useMutation(
    async (data) => {
      await addCurrencyPair(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCurrencyPair");
        ToastMessage(type.SUCCESS, "currency pair added successfully");
        setTimeout(() => {
          setVisible(false);
          setId("");
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSubmit = async (values) => {
    const payload = {
      to_currency: values?.to_currency,
      from_currency: values?.from_currency,
      min_buy: Number(values?.min_buy),
      max_buy: Number(values?.max_buy),
      min_sell: Number(values?.min_sell),
      max_sell: Number(values?.max_sell),
      buy_fee_type: values?.buy_fee_type,
      sell_fee_type: values?.sell_fee_type,
      sell_fee: Number(values?.sell_fee),
      buy_fee: Number(values?.buy_fee),
    };
    if (action === "add") {
      addMutation.mutate(payload);
    } else if (action === "edit") {
      editMutation.mutate(payload);
    }
  };

  const showModal = (method) => {
    setVisible(true);
    setAction(method);
  };

  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
      setEditVisible(false);
      setDeleteVisible(false);
    }, 1000);
  };

  const showEdit = (id) => {
    setAction("edit");
    setEditVisible(true);
    setId(id);

    result?.data?.data?.data?.find(
      (item) => item.id === id && setInitialEditValues(item)
    );
    setTimeout(() => setEditVisible(true), 500);
  };

  const showDelete = (i) => {
    setDeleteVisible(true);
    setId(i);
  };
  const handleCancel = () => {
    setVisible(false);
    setEditVisible(false);
    setDeleteVisible(false);
    setAction("add");
    form.resetFields();
  };

  const handleDelete = () => {
    deleteMutation.mutate(id);
  };

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>;
  }
  const currencyPair = result?.data?.data?.data;

  return (
    <>
      <Styles>
        <Container>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            className="currencies settlement"
          >
            <h3>Existing currency pairs </h3>
            <div className="search">
              <SearchBox handleChange />
            </div>
          </Flex>

          <CustomTable
            theadBkColor="#F1F4F8"
            bottomGap="10px"
            tableHead={dataHead}
            tableBody={currencyPair}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            showEdit={showEdit}
            showDelete={showDelete}
          />
          <Flex flexDir="row" justifyContent="space-between" className="btn">
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              width="40%"
              className="add-btn"
            >
              <AddNew />
              <Button
                Styles
                text={"Add new currency pair"}
                color={"#1A2CCE"}
                size="md"
                type="button"
                onClick={() => showModal("add")}
              />
            </Flex>
          </Flex>
        </Container>
      </Styles>
      <ModalFrame
        visible={action === "add" ? visible : editVisible}
        handleCancel={handleCancel}
        width={680}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={action === "add" ? "Save" : "Update"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="currencyPair"
            style={{ padding: "0.85rem 50px" }}
            loading={addMutation.isLoading || editMutation.isLoading}
          />,
        ]}
      >
        <FormContainer>
          <FormLayout
            action={action}
            itemId={id}
            initialEditValues={initialEditValues}
            setVisible={setVisible}
            setId={setId}
            handleSubmit={handleSubmit}
          />
        </FormContainer>
      </ModalFrame>

      <ModalFrame
        visible={deleteVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="button"
            key="submit"
            onClick={handleDelete}
            style={{ padding: "0.85rem 50px" }}
            loading={deleteMutation.isLoading}
          />,
        ]}
      >
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete this</p>
      </ModalFrame>
    </>
  );
};

export default Index;

const dataHead = ["Asset", "", "", ""];
