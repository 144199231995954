import React from 'react';
import { Styles, Card } from './styles';
import { Flex, Grid } from '../../../../components/box';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import { Spacer } from '../../../../components/spacer/styles';

const Index = ({ rejected, pending, approved, feeCollected, loading }) => {
	return (
		<>
			<Spacer height='28px' />
			<Styles>
				<Grid
					justifyContent={'space-around'}
					gap='10px'
					gridCol='repeat(auto-fit, minmax(200px, 1fr))'
					bgColor='#fff'
					pad='30px 20px'
				>
					<Card>
						<Flex
							className='container'
							alignItem={'center'}
							justifyContent={'space-between'}
						>
							<span>PENDING</span>
						</Flex>
						<Flex
							className='viewall'
							alignItem={'flex-start'}
							flexDir={'column'}
						>
							{loading ? (
								<Skeleton paragraph={{ rows: 1 }} />
							) : (
								<>
									<span>{pending?.result}</span>
									<span>
										<Link to='/dashboard/approvals/pending'>View pending</Link>
									</span>
								</>
							)}
						</Flex>
					</Card>
					<Card>
						<Flex
							className='container'
							alignItem={'center'}
							justifyContent={'space-between'}
						>
							<span>APPROVED</span>
						</Flex>
						<Flex
							className='viewall'
							alignItem={'flex-start'}
							flexDir={'column'}
						>
							{loading ? (
								<Skeleton paragraph={{ rows: 1 }} />
							) : (
								<>
									<span>{approved?.result}</span>
									<span>
										<Link to='/dashboard/approvals/approved/'>
											View approved
										</Link>
									</span>
								</>
							)}
						</Flex>
					</Card>
					<Card>
						<Flex
							className='container'
							alignItem={'center'}
							justifyContent={'space-between'}
						>
							<span>REJECTED </span>
						</Flex>
						<Flex
							className='viewall'
							alignItem={'flex-start'}
							flexDir={'column'}
						>
							{loading ? (
								<Skeleton paragraph={{ rows: 1 }} />
							) : (
								<>
									<span>{rejected?.result}</span>
									<span>
										<Link to='/dashboard/approvals/rejected/'>
											View rejected
										</Link>
									</span>
								</>
							)}
						</Flex>
					</Card>
					<Card style={{ borderRight: 'none' }}>
						<Flex
							className='container'
							alignItem={'center'}
							justifyContent={'space-between'}
						>
							<span>FEES COLLECTED</span>
						</Flex>
						<Flex
							className='viewall'
							alignItem={'flex-start'}
							flexDir={'column'}
						>
							{loading ? (
								<Skeleton paragraph={{ rows: 0 }} />
							) : (
								<>
									<span>
										{feeCollected?.currency?.symbol}
										{feeCollected?.result?.toLocaleString()}
									</span>
								</>
							)}
						</Flex>
					</Card>
				</Grid>
			</Styles>
		</>
	);
};

export default Index;
