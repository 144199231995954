import { Switch } from "antd";
import { Flex } from "../../../../components/box";
import { StylesAccount, StylesAccountStatus } from "./styles";
import { StylesCreateNew } from "../styles";
import {
  FxOpsAddressStatus,
  getSingleFxOpsAddress,
} from "../../../../services/fxOps.services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Loader } from "../../../../components/loader";
import { ToastMessage, type } from "../../../../utils/toastr";

export const Details = ({ accountDetails }) => {
  return (
    <>
      <StylesAccountStatus
        justifyContent="space-between"
        pad={"20px"}
        style={{}}
      >
        <p>Account Status</p>
        <Switch
          defaultChecked
          size="large"
          // onChange={onChange}
        />
      </StylesAccountStatus>
      <StylesAccount>
        <div>
          <h3>Account Name</h3>
          <p>
            Christain Jumbo <span>Copy</span>
          </p>
        </div>
        <div>
          <h3>Account Number</h3>
          <p>
            0083775638 <span>Copy</span>
          </p>
        </div>
        <div>
          <h3>Bank Name</h3>
          <p>
            Providus Bank <span>Copy</span>
          </p>
        </div>
      </StylesAccount>
    </>
  );
};

export const CryptoAccountDetails = ({ id }) => {
  const queryClient = useQueryClient();

  const result = useQuery(
    ["getSingleFxOpsAddress", id],
    async () => await getSingleFxOpsAddress(id),
    {
      enabled: !!id,
    }
  );
  const accountStatusMutation = useMutation(
    async (data) => {
      await FxOpsAddressStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAddress");
        ToastMessage(type.SUCCESS, "Status changed successfully");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSwitched = (checked) => {
    const payload = checked === true ? "enable" : "disable";
    accountStatusMutation.mutate({ status: payload });
  };

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result?.error.message}</span>;
  }
  const wallectDetails = result?.data?.data?.data;

  return (
    <>
      <StylesAccountStatus justifyContent="space-between" pad={"20px"}>
        <p>Account Status</p>
        <Switch
          defaultChecked={wallectDetails?.status}
          size="large"
          onChange={handleSwitched}
        />
      </StylesAccountStatus>
      <StylesAccount style={{ marginTop: "20px" }}>
        <Flex flexDir="row" justifyContent="space-between" className="crypto">
          <div>
            <svg
              width="149"
              height="148"
              viewBox="0 0 149 148"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="149" height="148" rx="4" fill="white" />
              <rect
                x="16"
                y="16"
                width="113"
                height="114"
                fill="url(#pattern0)"
              />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_1369_4394"
                    transform="translate(-0.0707965 -0.0789474) scale(0.0490413 0.0486111)"
                  />
                </pattern>
                <image
                  id="image0_1369_4394"
                  width="24"
                  height="24"
                  xlinkHref={wallectDetails?.qr}
                />
              </defs>
            </svg>
          </div>
          <div style={{ width: "50%" }}>
            <div>
              <h3>Network</h3>
              <p>{wallectDetails?.chain}</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <h3>Deposit Address</h3>
              <p>{wallectDetails?.address}</p>
            </div>
          </div>
        </Flex>
      </StylesAccount>
    </>
  );
};
