import styled from 'styled-components';
// import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	.overall {
		h3 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #1a1f36;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #636363;
		}
		.ant-input-number,
		.ant-input-number-input {
			width: 212px;
			height: 48px;
			background: #ffffff;
			border: 1px solid #d7d7d7;
			box-sizing: border-box;
			border-radius: 5px;
			line-height: 40px;
		}
		padding-bottom: 65px;
	}

	h3 {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: #1a1f36;
	}

	hr {
		border: 0.3px solid #c4c4c4;
		margin-bottom: 40px;
	}
	.signatures {
		padding-bottom: 20px;
		h3 {
			/* width: 30%; */
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #1a1f36;
			text-align: left;

			// :nth-child(2) {
			// 	text-align: left;
			// 	background: red;
			// 	// padding-left: 80px;
			// }
		}

		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
		}
	}
	.btn {
		.add-btn {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			padding: 0;
			text-align: right;
			text-decoration-line: underline;
		}

		.save {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			padding: 15px 55px;
		}
	}
`;

export const Container1 = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	.fiter_container {
		gap: 30px;
		justify-content: space-between;
	}
	h2 {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 23px;

		/* Normal-side-panel text */

		color: #1a1f36;
	}
	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 20px 10px;
		.fiter_container {
			width: 100%;
			justify-content: space-between;
			overflow: auto;
			flex-wrap: wrap;
			gap: 15px 5px;
			/* border: 1px solid red; */
		}
		.input_container {
			width: 100%;
		}
	}
`;

// export const SelectContainer = styled(Flex)`
// 	justify-content: space-between;
// 	background-color: #fff;
// 	padding: 0 10px;
// 	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
// 	border-radius: 3px;
// 	border: 0.7px solid #dfdfdf;
// 	> span {
// 		font-size: 12px;
// 		color: #969696;
// 	}
// `;

export const FormContainer = styled('div')`
	.signatory-header {
		text-align: left !important;
		font-weight: 700 !important;
		margin: 0;
	}

	#signatory {
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-align: left;
			padding-bottom: 10px;
			color: #000000;
		}
		.add-as {
			margin-top: 32px;
		}
		// .ant-form-item {
		// width: 48%;
		// width: 392px;
		// .ant-input,
		.ant-select .ant-select-selector {
			height: 51.52px;
			background: #ffffff;
			border: 1px solid #d7d7d7;
			box-sizing: border-box;
			border-radius: 5px;
			line-height: 40px;
			padding-top: 5px;
		}

		.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		}
	}
`;
