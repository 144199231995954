import apiClient from './axios';

const EndPoints = {
	getApprovalsFiat: `/admin/transactions?type=withdrawal&currency=NGN&approvals=true`,
	getApprovalsCrypto: `/admin/transactions?type=withdrawal&currency=USDT&approvals=true`,
	approveTransaction: `/admin/transactions/approve`,
	superApproveTransaction: `/admin/transactions/super-approve`,
	// approveTransaction: `/admin/transactions/withdrawal/approve`,
	// /admin/transactions/withdrawal/approve

	rejectTransaction: `/admin/transactions/reject`,
	//pending//
	getPendingApprovalsFiat(filterData) {
		let query = `/admin/transactions?status=pending&approvals=true`;
		// let query = `/transactions?type=withdrawal&currency=NGN&status=pending&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}

		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},
	getPendingApprovalsSwap(type, filterData) {
		let query = `/admin/transactions?type=${type}&status=pending&approvals=true`;
		// let query = `/transactions?type=swap&currency=NGN&status=pending&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}

		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	getPendingApprovalsCrypto(filterData) {
		// let query = `/transactions?status=pending&approvals=true`;
		// let query = `/transactions?currency=USDT&status=pending&approvals=true`;
		let query = `/admin/transactions?type=withdrawal&currency=USDT&status=pending&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	//approved//
	getApprovedApprovalsFiat(filterData) {
		let query = `/admin/transactions?type=withdrawal&currency=NGN&status=success&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}

		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},
	getApprovedApprovalsCrypto(filterData) {
		let query = `/admin/transactions?type=withdrawal&currency=USDT&status=success&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}

		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	//rejected//
	getRejectedApprovalsFiat(filterData) {
		let query = `/admin/transactions?type=withdrawal&currency=NGN&status=failed&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},
	getRejectedApprovalsCrypto(filterData) {
		let query = `/admin/transactions?type=withdrawal&currency=USDT&status=failed&approvals=true`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.ref) {
			query += `&ref=${filterData.ref}`;
		}

		return query;
	},

	getApprovalSummaryPending: `/admin/summary/transactions?math=count&type=withdrawal&status=pending&approvals=true`,
	getApprovalSummaryApproved: `/admin/summary/transactions?math=count&type=withdrawal&status=success&approvals=true`,
	getApprovalSummaryRejected: `/admin/summary/transactions?math=count&type=withdrawal&status=failed&approvals=true`,
	getApprovalSummaryFeeCollected: `/admin/summary/transactions?column=fee&math=sum&type=withdrawal&approvals=true`
};

//pending//
export const getPendingApprovalsFiat = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getPendingApprovalsFiat(filterData)
	);
	return res;
};
export const getPendingApprovalsSwap = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getPendingApprovalsSwap('swap', filterData)
	);
	return res;
};

export const getPendingApprovalsDeposit = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getPendingApprovalsSwap('deposit', filterData)
	);
	return res;
};
export const getPendingApprovalsWithdrawals = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getPendingApprovalsSwap('withdrawal', filterData)
	);
	return res;
};
export const getPendingApprovalsCrypto = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getPendingApprovalsCrypto(filterData)
	);
	return res;
};

//approved//
export const getApprovedApprovalsFiat = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getApprovedApprovalsFiat(filterData)
	);
	return res;
};
export const getApprovedApprovalsCrypto = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getApprovedApprovalsCrypto(filterData)
	);
	return res;
};

//rejected//
export const getRejectedApprovalsFiat = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getRejectedApprovalsFiat(filterData)
	);
	return res;
};
export const getRejectedApprovalsCrypto = async (filterData) => {
	const res = await apiClient.get(
		EndPoints.getRejectedApprovalsCrypto(filterData)
	);
	return res;
};

//all//
export const getApprovalsFiat = async () => {
	const res = await apiClient.get(EndPoints.getApprovalsFiat);
	return res;
};

export const getApprovalsCrypto = async () => {
	const res = await apiClient.get(EndPoints.getApprovalsCrypto);
	return res;
};

export const getApprovalSummaryPending = async () => {
	const res = await apiClient.get(EndPoints.getApprovalSummaryPending);
	return res;
};
export const getApprovalSummaryApproved = async () => {
	const res = await apiClient.get(EndPoints.getApprovalSummaryApproved);
	return res;
};

export const getApprovalSummaryRejected = async () => {
	const res = await apiClient.get(EndPoints.getApprovalSummaryRejected);
	return res;
};

export const getApprovalSummaryFeeCollected = async () => {
	const res = await apiClient.get(EndPoints.getApprovalSummaryFeeCollected);
	return res;
};

export const approveTransaction = async (payload) => {
	const res = await apiClient.patch(EndPoints.approveTransaction, payload);
	return res;
};
export const superApproveTransaction = async (payload) => {
	const res = await apiClient.patch(EndPoints.superApproveTransaction, payload);
	return res;
};

export const rejectTransaction = async (payload) => {
	const res = await apiClient.patch(EndPoints.rejectTransaction, payload);
	return res;
};
