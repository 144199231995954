import { Tabs } from 'antd';
import React from 'react';
// import { Flex } from '../../../../../components/box';
import { Spacer } from '../../../../../components/spacer/styles';
import {
	Styles
	// , Container1
} from './styles';
import MarketRate from './market-rate';
import SettlementTime from './settlement-time';
import { useParams } from 'react-router-dom';
import Wallet from './wallet/Wallet';

const Preferences = () => {
	let { merchant } = useParams();
	const { TabPane } = Tabs;

	const tabLink = [
		{
			path: 'Market settings',
			component: <MarketRate merchantID={merchant} />
		},

		{
			path: 'Settlement time',
			component: <SettlementTime merchantID={merchant} />
		},
		{
			path: 'Wallet',
			component: <Wallet merchantID={merchant} />
		}
	];

	//   if (result.isLoading) {
	//     return <Loader />;
	//   }

	//   if (result.isError) {
	//     return <span>Error: {result?.error.message}</span>;
	//   }
	return (
		<>
			<Styles>
				<Spacer height='52px' />

				<Tabs defaultActiveKey='0' tabPosition={'left'} id='general-tabs'>
					{tabLink?.map((data, index) => (
						<TabPane tab={data.path} key={index}>
							{data.component}
						</TabPane>
					))}
				</Tabs>
			</Styles>
		</>
	);
};

export default Preferences;
