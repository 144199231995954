import React, { useState } from 'react';
import { Styles } from './styles';
import CustomTable from './table';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../components/loader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import AccountForm from './newAccount';
import {
	getMerchantAccount,
	createMerchantAccount,
	editMerchantAccount,
	deleteMerchantAccount,
	generateMerchantAccount
} from '../../../../../services/Merchants.service';
import { getpaymentMethodDeposit } from '../../../../../services/Settings.service';
import { AccountDetails, CryptoAccountDetails } from './accountDetails';
import { ToastMessage, type } from '../../../../../utils/toastr';
import { getBankName } from '../../../../../services/Others.service';

const tableHead = ['ASSET', 'PROVIDER', ''];

const Index = () => {
	const queryClient = useQueryClient();

	const [visible, setVisible] = useState(false);
	const [visibleEdit, setVisibleEdit] = useState(false);
	const [visibleDelete, setVisibleDelete] = useState(false);
	const [visibleDetails, setVisibleDetails] = useState(false);
	const [action, setAction] = useState('add');
	const [id, setId] = useState('add');

	const [initialEditValues, setInitialEditValues] = useState({});

	let params = useParams();

	const result = useQuery(
		'getMerchantAccount',
		async () => await getMerchantAccount(params.merchant)
	);

	const depositMethod = useQuery(
		'getpaymentMethodDeposit',
		async () => await getpaymentMethodDeposit()
	);
	const bankNames = useQuery('getbankName', async () => await getBankName());

	const createAccountMutation = useMutation(
		async (data) => {
			await createMerchantAccount(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantAccount');
				ToastMessage(type.SUCCESS, 'Merchant account created successfully');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const editAccountMutation = useMutation(
		async (data) => {
			await editMerchantAccount(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantAccount');
				ToastMessage(type.SUCCESS, 'Merchant account updated successfully');
				setTimeout(() => setVisibleEdit(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const deleteAccountMutation = useMutation(
		async (data) => {
			await deleteMerchantAccount(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantAccount');
				ToastMessage(type.SUCCESS, 'Merchant account deleted successfully');
				setTimeout(() => setVisibleDelete(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const regenerateAccountMutation = useMutation(
		async (data) => {
			await generateMerchantAccount(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantAccount');
				ToastMessage(type.SUCCESS, 'Merchant account regenerated successfully');
				setTimeout(() => setVisibleDelete(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const show = () => {
		setAction('add');
		setVisible(true);
	};

	const showDelete = (id) => {
		setVisibleDelete(true);
		setId(id);
	};

	const showEdit = (id) => {
		setAction('Edit');
		setVisibleEdit(true);
		setId(id);
		result?.data?.data?.data?.find(
			(item) => item.id === id && setInitialEditValues(item)
		);
	};

	const showDetails = (id) => {
		setVisibleDetails(true);
		result?.data?.data?.data?.find(
			(item) => item.id === id && setInitialEditValues(item)
		);
	};

	const handleCancel = () => {
		setVisible(false);
		setAction('add');
		setVisibleEdit(false);
		setVisibleDelete(false);
		setVisibleDetails(false);
	};

	const handleDelete = () => {
		deleteAccountMutation.mutate(id);
	};

	const handleRegenerate = (id) => {
		regenerateAccountMutation.mutate(id);
	};

	const handleSubmit = (values) => {
		const payload = {
			...values,
			merchant_id: Number(params.merchant)
		};
		if (action === 'add') {
			createAccountMutation.mutate(payload);
		}
		if (action === 'Edit') {
			editAccountMutation.mutate(payload);
		}
	};

	if (result.isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Styles>
				<h3>Virtual Accounts</h3>
				<CustomTable
					theadBkColor='#F1F4F8'
					bottomGap='5px'
					tableHead={tableHead}
					tableBody={result?.data?.data?.data.filter(
						(item) => item.depositMethod.type === 'AUTO'
					)}
					rowHovColor='#d2ccc626'
					theadColor='#000A62'
					tbodyColor='#1A1F36'
					handleReadAll={() => []}
					pageSize={10}
					showDetails={showDetails}
					handleRegenerate={handleRegenerate}
				/>

				<Flex
					alignItem='baseline'
					justifyContent='space-between'
					style={{ marginTop: '50px' }}
				>
					<h3>Manual Account</h3>
					<Button
						Styles
						text={'Create new account'}
						color='#1A2CCE'
						bgColor={'#E7F1FF'}
						border='0.8px solid #1A2CCE'
						border-radius='3px'
						size='md'
						type='button'
						onClick={show}
					/>
				</Flex>
				<CustomTable
					type='manual'
					theadBkColor='#F1F4F8'
					bottomGap='5px'
					tableHead={tableHead}
					tableBody={result?.data?.data?.data.filter(
						(item) => item.depositMethod.type === 'MANUAL'
					)}
					rowHovColor='#d2ccc626'
					theadColor='#000A62'
					tbodyColor='#1A1F36'
					handleReadAll={() => []}
					pageSize={10}
					showDelete={showDelete}
					showEdit={showEdit}
					showDetails={showDetails}
				/>
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : visibleEdit}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						bgColor={'#1A2CCE'}
						text={action === 'add' ? 'Add' : 'Save'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						loading={
							createAccountMutation.isLoading || editAccountMutation.isLoading
						}
						key='submit'
						form='editMerchant'
						style={{ padding: '0.85rem 50px' }}
					/>
				]}
			>
				<AccountForm
					action={action}
					depositMethod={depositMethod?.data?.data?.data}
					initialEditValues={initialEditValues}
					handleSubmit={handleSubmit}
					bankNames={bankNames}
				/>
			</ModalFrame>
			<ModalFrame
				visible={visibleDelete}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='button'
						loading={deleteAccountMutation.isLoading}
						onClick={handleDelete}
						style={{
							paddingLeft: '50px',
							paddingRight: '50px'
						}}
					/>
				]}
			>
				<h3>Confirm Delete</h3>
				<p>Are you sure you want to delete this manual account?</p>
			</ModalFrame>
			<ModalFrame
				visible={visibleDetails}
				handleCancel={handleCancel}
				footer={null}
			>
				<h3 style={{ textAlign: 'left' }}>Account details</h3>
				{initialEditValues?.wallet_address === null ? (
					<AccountDetails accountDetails={initialEditValues} />
				) : (
					<CryptoAccountDetails accountDetails={initialEditValues} />
				)}
			</ModalFrame>
		</>
	);
};

export default Index;
