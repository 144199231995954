import React from 'react';
import { Container, Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { UsersRolesTableHead } from '../../../../utils/constants';
import { Container1, FormContainer, Styles } from './styles';
import CustomTable from './table';
// import { ToastMessage, type } from '../../../../utils/toastr';
import { useState } from 'react';
import {
	useQuery
	// useQueryClient
} from 'react-query';
import {
	// deleteStaff,
	getRoles,
	getStaffs
	// reset2fa,
	// suspendStaff
} from '../../../../services/Settings.service';
import { ApproveTransactionSummary } from '../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import { FormLayout } from './form';

const Index = () => {
	// const queryClient = useQueryClient();
	const result = useQuery(
		'getStaffs',
		async () => await getStaffs(search, filter)
	);
	const roles = useQuery('getRoles', async () => await getRoles());
	const [action, setAction] = React.useState('add');
	const [userId, setUserId] = React.useState('');
	const [visible, setVisible] = React.useState(false);
	const [otpVisible, setOtpVisible] = React.useState(false);
	const [editVisible, setEditVisible] = React.useState(false);
	const [resetVisible, setResetVisible] = React.useState(false);
	const [deleteVisible, setDeleteVisible] = React.useState(false);
	const [suspendVisible, setSuspendVisible] = React.useState(false);
	const [confirmVisible, setConfirmVisible] = React.useState(false);
	const [initialEditValues, setInitialEditValues] = React.useState({});
	const [requiredAction, setRequiredAction] = React.useState('');
	const [formData, setFormData] = React.useState({});
	const [suspendAction, setSuspendAction] = useState('');
	const [isActive, setIsActive] = useState();
	const [filter, setFilter] = React.useState({
		page: 1
	});
	const [search, setSearch] = React.useState('');

	// const deleteStaffMutation = useMutation(
	// 	async (data) => {
	// 		await deleteStaff(data);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('getStaffs');
	// 			ToastMessage(type.SUCCESS, 'Admin sucessfully deleted');
	// 			setDeleteVisible(false);
	// 		},
	// 		onError: (error) => {
	// 			ToastMessage(type.ERROR, error.response.data.message);
	// 		}
	// 	}
	// );

	const showModal = () => {
		setVisible(true);
	};

	const showConfirmModal = () => {
		setConfirmVisible(true);
	};

	const handleOk = () => {
		if (resetVisible === true) {
			setRequiredAction('reset');
			setResetVisible(false);
			setOtpVisible(true);
		} else if (suspendVisible === true) {
			setRequiredAction(suspendAction);
			setSuspendVisible(false);
			setOtpVisible(true);
		} else if (deleteVisible === true) {
			setRequiredAction('password');
			setDeleteVisible(false);
			setOtpVisible(true);
		}
		setAction('add');
	};

	const handleCancel = () => {
		setVisible(false);
		setConfirmVisible(false);
		setEditVisible(false);
		setSuspendVisible(false);
		setResetVisible(false);
		setDeleteVisible(false);
		setOtpVisible(false);
		setAction('add');
	};

	const handleEdit = (id) => {
		setAction('edit');
		setUserId(id);

		result?.data?.data?.data?.data?.find(
			(item) => item.id === id && setInitialEditValues(item)
		);
		setTimeout(() => setEditVisible(true), 500);
	};

	const handleSuspend = (id, userStatus) => {
		setSuspendVisible(true);
		if (userStatus === false) {
			setIsActive(userStatus);
			setSuspendAction('unsuspend');
		} else if (userStatus === true) {
			setIsActive(userStatus);
			setSuspendAction('suspend');
		}

		setUserId(id);
	};

	const handleReset2fa = (id) => {
		setResetVisible(true);
		setUserId(id);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};
	// const handleDelete = (id) => {
	// 	console.log(id);
	// 	setDeleteVisible(true);
	// 	setUserId(id);
	// };

	// if (result.isLoading && roles.isLoading) {
	// 	return <Loader />;
	// }

	if (result.status === 'error') {
		return <span>Error: {result.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container1>
					<Flex justifyContent='space-between' flex-direction='row'>
						<h2>Manage your team and their account permissions here</h2>
						<Button
							Styles
							text={'Invite new member'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='submit'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							borderRadius={'3px'}
							onClick={showModal}
						/>
					</Flex>
				</Container1>
				{/* <Container1>
					<Container className='input_container' width={'26%'}>
						<Search placeholder={'Search reference no.'} />
						<Search
							placeholder={'Search by name.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>
				</Container1>
				<Container className='input_container' width={'20%'}>
					</Container> */}

				{result?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={UsersRolesTableHead}
						tableBody={result?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={5}
						handleEdit={handleEdit}
						handleSuspend={handleSuspend}
						handleReset2fa={handleReset2fa}
						handlePageChange={handlePageChange}
						paginator
						meta={result?.data?.data?.data?.meta}
						// handleDelete={handleDelete}
						// handleChangePassword={handleDelete}
					/>
				)}
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : editVisible}
				handleCancel={handleCancel}
				footer={null}
				destroyOnClose={true}
			>
				<FormContainer>
					<h3 style={{ textAlign: 'left' }}>
						{action === 'add' ? 'Invite new member' : 'Edit member'}
					</h3>
					<FormLayout
						setEditVisible={setEditVisible}
						setVisible={setVisible}
						// showConfirmModal={showConfirmModal}
						action={action}
						userId={userId}
						initialEditValues={initialEditValues}
						roles={roles?.data?.data?.data}
						setFormData={setFormData}
						setOtpVisible={setOtpVisible}
						setRequiredAction={setRequiredAction}
					/>
				</FormContainer>
			</ModalFrame>
			<ModalFrame
				visible={confirmVisible}
				handleOk={(e) => setConfirmVisible(false)}
				handleCancel={handleCancel}
				footer={null}
			>
				<FormContainer>
					<ConfirmMessage />
				</FormContainer>
			</ModalFrame>
			<ModalFrame
				visible={
					suspendVisible === true
						? suspendVisible
						: resetVisible === true
						? resetVisible
						: deleteVisible
				}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleOk}
					/>
				]}
			>
				{suspendVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>
							{isActive === true ? 'Suspend user' : 'Unsuspend User'}
						</h3>
						<p>
							{`Are you sure you want to ${
								isActive === true ? 'suspend' : 'unsuspend'
							} the user`}
						</p>
					</>
				) : resetVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>Reset User 2FA</h3>
						<p>Are you sure you want to reset user 2fa</p>
					</>
				) : (
					<>
						<h3 style={{ textAlign: 'center' }}>Change User Password</h3>
						<p>Are you sure you want to change this user's password </p>
					</>
				)}
			</ModalFrame>
			<ModalFrame
				visible={otpVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					id={userId}
					// handleQrScreen={handleqrCode}
					// swapAction={status}
					status={requiredAction}
					setVisible={setOtpVisible}
					addEditData={formData}
					showConfirmModal={showConfirmModal}
				/>
				{/* <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame> */}
			</ModalFrame>
		</>
	);
};

export default Index;

const ConfirmMessage = () => (
	<>
		<Container>
			<Flex flexDir='column' justifyContent='center' alignItems='center'>
				<h3>Invite sent</h3>
				<p>
					Your new team member will receive
					<br /> your invite and a 2FA code generated
					<br /> for them
				</p>
			</Flex>
		</Container>
	</>
);
