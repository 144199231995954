import React from "react";
import { saveAs } from "file-saver";

import { useParams, useNavigate } from "react-router-dom";
import { BackIcon } from "../../../../../assets";
import Layout from "../../../../../components/layouts";
import { Loader } from "../../../../../components/loader";
import { Flex } from "../../../../../components/box";
import { useCopy } from "../../../../../customHooks/useCopyToClipBoard";

import {
  Styles,
  Container2,
  Container3,
  TransactionDetailsContainer,
} from "./styles";
import {
  formattedDate,
  formattedTime,
  truncateStr,
} from "../../../../../utils/helpers";
import { useQuery } from "react-query";
import {
  FxOpsTransactionPdfDownload,
  getSingleFxOpsTransaction,
} from "../../../../../services/fxOps.services";
import Button from "../../../../../components/button";
import { ToastMessage, type } from "../../../../../utils/toastr";

const Index = () => {
  const { fxOpsTransactionID } = useParams();

  const navigate = useNavigate();

  const { copySuccess, copyToClipboard } = useCopy();

  const result = useQuery(
    ["getSingleFxOpsTransaction", fxOpsTransactionID],
    async () => await getSingleFxOpsTransaction(fxOpsTransactionID)
  );

  const handleDownload = () => {
    FxOpsTransactionPdfDownload(fxOpsTransactionID)
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        saveAs(pdfBlob, "receipt.pdf");
      })
      .catch((error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      });
  };

  if (result.isLoading) {
    return <Loader full />;
  }

  if (result.isError) {
    return <span>Error: {result.error}</span>;
  }
  const transactionData = result?.data?.data?.data;
  const metaData = transactionData?.metadata
    ? JSON.parse(transactionData?.metadata)
    : {};
  const status = transactionData?.status;

  return (
    <Layout title={"Transaction"}>
      <Styles>
        <Flex
          onClick={() => navigate(-1)}
          className="go_back"
          alignItems="center"
          justifyContent="flex-start"
        >
          <BackIcon />
          <span style={{ paddingLeft: "20px" }}>Go Back</span>
        </Flex>
        <Container2 status={status}>
          <Flex justifyContent={"flex-start"}>
            <span>{transactionData?.currency}</span>
            <span>
              {transactionData?.source_type === "blockchain"
                ? transactionData?.amount
                : transactionData?.amount.toLocaleString()}
            </span>
            <span>{status}</span>
          </Flex>
          {transactionData?.type === "PAYOUT" && (
            <Button
              text={"Download Receipt"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="sm"
              type="button"
              onClick={handleDownload}
              borderRadius={"5px"}
            />
          )}
        </Container2>
        <Container3 gap={"10px"} gridCol={"repeat(3,1fr)"}>
          <span>DATE</span>
          <span>TNX REFERENCE</span>
          <span> Transaction TYPE</span>
          <div>
            <span>
              {formattedDate(transactionData?.created_at)}
              <small> {formattedTime(transactionData?.created_at)}</small>
            </span>
          </div>
          <div>
            <span> {truncateStr(transactionData?.reference, 15)}</span>
            <button onClick={() => copyToClipboard(transactionData?.reference)}>
              {copySuccess}
            </button>
          </div>
          <div>
            <span>{transactionData?.type}</span>
          </div>
        </Container3>
        <TransactionDetailsContainer status={status}>
          <div className="title">
            <h1>TRANSACTION DETAILS</h1>
          </div>
          <Flex className="details">
            <span>Amount Deposited</span>
            <span>
              <span>
                {transactionData?.source_type === "blockchain"
                  ? transactionData?.amount
                  : transactionData?.amount.toLocaleString()}
              </span>
            </span>
          </Flex>
          <Flex className="details">
            <span>Currency</span>
            <span>{transactionData?.currency}</span>
          </Flex>
          <Flex className="details">
            <span>Transaction Reference</span>
            <span>{truncateStr(transactionData?.reference, 15)}</span>
          </Flex>

          <Flex className="details">
            <span>Date</span>
            <span>
              {formattedDate(transactionData?.created_at)}
              <small> {formattedTime(transactionData?.created_at)}</small>
            </span>
          </Flex>
          {transactionData?.source_type === "card" && (
            <Flex className="details">
              <span> Phone Number</span>
              <span>{metaData?.phoneNumber}</span>
            </Flex>
          )}

          {transactionData?.source_type === "card" && (
            <Flex className="details">
              <span> Email</span>
              <span>{metaData?.email}</span>
            </Flex>
          )}

          <Flex className="details">
            <span>Status</span>
            <span>
              <div className="dot" />
              {status}
            </span>
          </Flex>
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Transaction Fees</span>
              <span>{transactionData?.fee?.toLocaleString()}</span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span>Label</span>
              <span>{transactionData?.fx_account?.label}</span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span>Email</span>
              <span>{transactionData?.fx_account?.email}</span>
            </Flex>
          )}
          <section />
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Bank Name</span>
              <span>
                {transactionData?.fx_account?.data?.beneficiaryBank?.name}
              </span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Account Number</span>
              <span>
                {
                  transactionData?.fx_account?.data?.beneficiaryBank
                    ?.accountNumber
                }
              </span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Swift Code</span>
              <span>
                {transactionData?.fx_account?.data?.beneficiaryBank?.swiftCode}
              </span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Routing Number </span>
              <span>
                {
                  transactionData?.fx_account?.data?.beneficiaryBank
                    ?.routingNumber
                }
              </span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span> Location </span>
              <span>
                {`${transactionData?.fx_account?.data?.beneficiaryBank?.city},${transactionData?.fx_account?.data?.beneficiaryBank?.country}`}
              </span>
            </Flex>
          )}
          {transactionData?.type === "PAYOUT" && (
            <Flex className="details">
              <span>Description</span>
              <span>{transactionData?.fx_account?.description}</span>
            </Flex>
          )}
        </TransactionDetailsContainer>
      </Styles>
    </Layout>
  );
};

export default Index;
