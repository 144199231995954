import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	AcceptTransactionIcon,
	DeleteIcon2,
	DotIcon,
	EyeIcon,
	RejectTransactionIcon
} from '../../../../assets';
import { EmptyState } from '../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import Pagination from '../../../../components/paginator';
import PopOver from '../../../../components/popover/PopOver';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	source,
	handlePageChange,
	meta,
	handleApprove,
	handleReject,
	setRef,
	walletRef
}) => {
	// const navigate = useNavigate();
	const location = useLocation();
	const prevRoute = location?.pathname?.includes('business')
		? 'business'
		: 'personal';
	const returnTableRow = (data) => {
		return (
			<tr key={data.id}>
				{source === 'personal' && (
					<>
						<Td>
							<div>
								<span>{data?.compliance?.email}</span>
							</div>
						</Td>
						<Td>
							<div>
								<span>
									{data?.compliance?.firstname} {data?.compliance?.lastname}
								</span>
							</div>
						</Td>
						<Td>
							<div>
								<span>{data?.compliance?.phone}</span>
							</div>
						</Td>
						<CustomTd status={data?.fiat_compliance_status}>
							<div>
								<span>{data?.fiat_compliance_status}</span>
							</div>
						</CustomTd>
						<CustomTd>
							<PopOver
								placement='rightTop'
								main={
									<div className='icon_box'>
										<DotIcon />
									</div>
								}
							>
								<Link
									to={`/dashboard/merchants/wallets/${data?.wallet_reference}/wallet-compliance`}
									// to={`/dashboard/merchants/${data?.merchantId}/wallets/${data?.wallet_reference}/wallet-compliance`}
									state={{
										merchantName: data?.business_name,
										prevRoute
										// complianceData: data?.compliance,
									}}
								>
									<div>
										<EyeIcon />
										<p>View Details</p>
									</div>
								</Link>
								<Link
									to={'#'}
									onClick={() => handleApprove(data?.wallet_reference)}
								>
									<div>
										<AcceptTransactionIcon />
										<p>Approve Compliance</p>
									</div>
								</Link>
								<Link
									to={'#'}
									onClick={() => handleReject(data?.wallet_reference)}
								>
									<div>
										<RejectTransactionIcon />
										<p>Reject Compliance</p>
									</div>
								</Link>
								<Link
									to={'#'}
									// onClick={() => handleReject(data?.id, 'reject')}
								>
									<div>
										<DeleteIcon2 />
										<p>Delete Wallet</p>
									</div>
								</Link>
							</PopOver>
						</CustomTd>
					</>
				)}
				{source === 'business' && (
					<>
						<Td>
							<div>
								<span>
									{data?.compliance?.business?.name || data?.business_name}
								</span>
							</div>
						</Td>

						<Td>
							<div>
								<span>{data?.compliance?.email}</span>
							</div>
						</Td>
						<Td>
							<div>
								<span>
									{data?.compliance?.firstname} {data?.compliance?.lastname}
								</span>
							</div>
						</Td>
						<Td>
							<div>
								<span>{data?.compliance?.phone}</span>
							</div>
						</Td>

						<CustomTd status={data?.fiat_compliance_status}>
							<div>
								<span>{data?.fiat_compliance_status}</span>
							</div>
						</CustomTd>

						<CustomTd>
							<PopOver
								placement='rightTop'
								main={
									<div className='icon_box'>
										<DotIcon />
									</div>
								}
							>
								<Link
									to={`/dashboard/merchants/wallets/${data?.wallet_reference}/wallet-compliance`}
									state={{
										merchantName: data?.business_name,
										prevRoute
									}}
								>
									<div>
										<EyeIcon />
										<p>View Details</p>
									</div>
								</Link>
								<Link
									to={'#'}
									onClick={() => handleApprove(data?.wallet_reference)}
								>
									<div>
										<AcceptTransactionIcon />
										<p>Approve Compliance</p>
									</div>
								</Link>
								<Link
									to={'#'}
									onClick={() => handleReject(data?.wallet_reference)}
								>
									<div>
										<RejectTransactionIcon />
										<p>Reject Compliance</p>
									</div>
								</Link>
								<Link
									to={'#'}
									// onClick={() => handleReject(data?.id, 'reject')}
								>
									<div>
										<DeleteIcon2 />
										<p>Delete Wallet</p>
									</div>
								</Link>
							</PopOver>
						</CustomTd>
					</>
				)}
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead.map((head, i) => (
										<th
											key={i}
											// key={generateID(11)}
										>
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination meta={meta} handlePageChange={handlePageChange} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;
