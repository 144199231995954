import { Input, Spin, Tabs } from "antd";
import React, { useState } from "react";
import { Flex, Frame } from "../../../../../../components/box";
import { Styles } from "./styles";
import ModalFrame from "../../../../../../components/modal/ModalFrame";
import Button from "../../../../../../components/button";
import { SearchOutlined } from "@ant-design/icons";

import { EmptyState } from "../../../../../../components/emptyState";
import { useAuthState } from "../../../../../../context/context";
import { useMutation, useQueryClient } from "react-query";
import { ToastMessage, type } from "../../../../../../utils/toastr";
import { enableMerchantCurrency } from "../../../../../../services/Merchants.service";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;

const ChooseAssets = () => {
  const queryClient = useQueryClient();
  let { merchant } = useParams();

  const { currencies } = useAuthState();
  const [visible, setVisible] = useState(false);

  const addCurrencyMutation = useMutation(
    async (data) => {
      await enableMerchantCurrency(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantCurrencies");
        ToastMessage(type.SUCCESS, "Asset sucessfully enabled");
        setTimeout(() => setVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const show = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSelect = (code) => {
    addCurrencyMutation.mutate({ code });
  };

  return (
    <>
      <Button
        text={"Add new asset"}
        bgColor={"#E7F1FF"}
        color={"#1A2CCE"}
        size="sm"
        border="border: 0.8px solid #1A2CCE;"
        borderRadius={"5px"}
        onClick={show}
      />
      <ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
        <h3 style={{ textAlign: "left" }}>Choose Assets</h3>
        <Styles>
          <Flex
            justifyContent="space-between"
            alignItem="baseline"
            style={{
              border: "1px solid #d7d7d7",
              borderRadius: "5px",
              marginBottom: "20px",
            }}
          >
            <Input
              bordered={false}
              style={{ border: "none" }}
              placeholder="Search"
              addonBefore={<SearchOutlined />}
            />
          </Flex>
          <Tabs defaultActiveKey="1" height="100px">
            <TabPane tab="Fiat" key="1" style={{ overflowY: "auto" }}>
              {addCurrencyMutation.isLoading ? (
                <Flex className="spin">
                  <Spin />
                </Flex>
              ) : currencies.length > 0 ? (
                currencies.map(
                  (item) =>
                    item.type === "FIAT" && (
                      <div
                        className="container"
                        key={item.id}
                        onClick={() => handleSelect(item.code)}
                      >
                        <Flex flexDir="row" justifyContent="flex-start">
                          <Flex justifyContent="flex-start">
                            <Frame
                              height={"30px"}
                              width={"30px"}
                              style={{
                                background: "#F3F3F3",
                                borderRadius: "16.9228px",
                                padding: "5px",
                              }}
                            >
                              <img src={item.icon} alt="" />
                            </Frame>
                            <Flex
                              flexDir="column"
                              justifyContent="flex-start"
                              alignItem="flex-start"
                              className="currency_holder"
                            >
                              <p>{item.name}</p>
                            </Flex>
                          </Flex>
                          <Flex justifyContent="flex-end" className="value_box">
                            <p>{item?.code}</p>
                          </Flex>
                        </Flex>
                      </div>
                    )
                )
              ) : (
                <EmptyState />
              )}
            </TabPane>
            <TabPane tab="Crypto" key="2" style={{ overflowY: "auto" }}>
              {addCurrencyMutation.isLoading ? (
                <Flex className="spin">
                  <Spin />
                </Flex>
              ) : currencies?.length > 0 ? (
                currencies?.map(
                  (item) =>
                    item.type === "CRYPTO" && (
                      <div
                        className="container"
                        onClick={() => handleSelect(item.code)}
                        key={item.id}
                      >
                        <Flex flexDir="row" justifyContent="flex-start">
                          <Flex justifyContent="flex-start">
                            <Frame
                              height={"30px"}
                              width={"30px"}
                              style={{
                                background: "#F3F3F3",
                                borderRadius: "16.9228px",
                                padding: "5px",
                              }}
                            >
                              <img src={item.icon} alt="" />
                            </Frame>
                            <Flex
                              flexDir="column"
                              justifyContent="flex-start"
                              alignItem="flex-start"
                              className="currency_holder"
                            >
                              <p>{item?.name}</p>
                            </Flex>
                          </Flex>
                          <Flex justifyContent="flex-end" className="value_box">
                            <p>{item?.code}</p>
                          </Flex>
                        </Flex>
                      </div>
                    )
                )
              ) : (
                <EmptyState />
              )}
            </TabPane>
          </Tabs>
        </Styles>
      </ModalFrame>
    </>
  );
};

export default ChooseAssets;
