import { Form, Input } from 'antd';
// import { message, Upload } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
// import { InboxOutlined } from '@ant-design/icons';

// const { Text } = Typography;

const StepTwoForm = ({
	handleFormData,
	handleSelectFormData,
	nextStep,
	values,
	merchantID,
	finished,
	handleCancel
}) => {
	const [form] = Form.useForm();

	const handleSubmit = (data) => {
		nextStep();
	};

	return (
		<Form layout='vertical' form={form} onFinish={handleSubmit}>
			<Form.Item
				label='Business Name'
				name='name'
				rules={[
					{
						required: true,
						message: 'Business Name  is required'
					},
					{ min: 3, message: 'Business Name must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter Business Name'
					defaultValue={values.name}
					onChange={handleFormData('name')}
				/>
			</Form.Item>
			<Form.Item
				label='Business Phone'
				name='bPhone'
				rules={[
					{
						required: true,
						message: 'Business Phone is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter Phone'
					defaultValue={values.bPhone}
					onChange={handleFormData('bPhone')}
				/>
			</Form.Item>
			<Form.Item
				label='Business Address'
				name='address'
				rules={[
					{
						required: true,
						message: 'Business Address is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter Address'
					defaultValue={values.address}
					onChange={handleFormData('address')}
				/>
			</Form.Item>

			<Form.Item
				label='Business City'
				name='city'
				rules={[
					{
						required: true,
						message: 'Business city is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter city'
					defaultValue={values.city}
					onChange={handleFormData('city')}
				/>
			</Form.Item>
			<Form.Item
				label='Business State'
				name='state'
				rules={[
					{
						required: true,
						message: 'Business state is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter state'
					defaultValue={values.state}
					onChange={handleFormData('state')}
				/>
			</Form.Item>
			<Form.Item
				label='Business Country'
				name='country'
				rules={[
					{
						required: true,
						message: 'Business country is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter country'
					defaultValue={values.country}
					onChange={handleFormData('country')}
				/>
			</Form.Item>
			<Form.Item
				label='Business Reg Number'
				name='reg_number'
				rules={[
					{
						required: true,
						message: 'Business Reg Number is required'
					}
					// { min: 3, message: 'B must be minimum 3 characters.' }
				]}
			>
				<Input
					placeholder='Enter registration number'
					defaultValue={values.reg_number}
					onChange={handleFormData('reg_number')}
				/>
			</Form.Item>

			<Flex justifyContent='flex-end' marginTop='40px'>
				<Button
					Styles
					text={'Cancel'}
					bgColor={'#ffffff'}
					size='md'
					type='button'
					onClick={handleCancel}
				/>

				<Button
					Styles
					text='Next - Compliance'
					bgColor={'#1A2CCE'}
					color='#FFFFFF'
					size='md'
					type='submit'
					// form='createWallet'
					borderRadius='5px'
				/>
			</Flex>
		</Form>
	);
};

export default StepTwoForm;

export const AddMoreStyle = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	margin: 10px 0 10px 0;
	.ant-btn-link {
		color: #1a2cce;
	}
	.danger {
		color: #d80027;
	}
`;
