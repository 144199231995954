import { Checkbox, Divider, Form, Input, Select, Switch } from 'antd';
import { useState } from 'react';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import CustomAccordion from '../../../../components/customAccordion/CustomAccordion';
import { OptionalStyle } from './styles';
const FormLayout = ({
	action,
	initialEditValues,
	paymentMethod,
	currencies,
	handleSubmit,
	optionValue
}) => {
	const [curType, setCurType] = useState('');
	const [optional, setOptional] = useState(false);

	const [formPaymentMethod, setFormPaymentMethod] = useState([]);
	const [optionalPaymentMethod, setOptionalPaymentMethod] = useState([]);
	const [search, setSearch] = useState('');

	const handleSearch = (e) => {
		// setSearch(e?.target?.value);
		console.log(e?.target?.value);
	};
	const handleInputChange = (value) => {
		// setCurrency(value);

		currencies?.filter((c) => {
			if (c.code === value) {
				setCurType(c.type);
				// currencyType(c.type);
			}
		});
	};
	console.log(initialEditValues, Number(initialEditValues?.payment_methods));

	return (
		<>
			<Form
				layout={'vertical'}
				onFinish={handleSubmit}
				size='large'
				initialValues={{
					layout: 'vertical',
					size: 'large',
					merchant: action === 'edit' ? initialEditValues?.merchant : null,
					transaction_type:
						action === 'edit' ? initialEditValues?.transaction_type : null,
					payment_method:
						action === 'edit'
							? Number(initialEditValues?.payment_methods)
							: null,
					fee_currency:
						action === 'edit' ? initialEditValues?.fee_currency : null,
					from_currency:
						action === 'edit' ? initialEditValues?.from_currency : null,
					to_currency:
						action === 'edit' ? initialEditValues?.to_currency : null,
					fee_type:
						action === 'edit' ? initialEditValues?.fees[0]?.fee_type : null,
					fee: action === 'edit' ? initialEditValues?.fees[0]?.fee : null,
					chain: action === 'edit' ? initialEditValues?.chain : null,
					optional_transaction_type:
						action === 'edit'
							? initialEditValues?.optional_transaction_type
							: null,
					optional_payment_method:
						action === 'edit'
							? Number(initialEditValues?.optional_payment_method)
							: null,
					optional_fee_amount:
						action === 'edit' ? initialEditValues[0]?.optional_fee : null,
					optional_fee_type:
						action === 'edit' ? initialEditValues[0]?.optional_fee_type : null,
					optional_chain:
						action === 'edit' ? initialEditValues?.optional_chain : null,
					// env:
					// 	action === 'edit'
					// 		? initialEditValues?.env === 'test' ||
					// 		  initialEditValues?.env === 'live'
					// 			? true
					// 			: false
					// 		: null,
					status: action === 'edit' ? initialEditValues?.status : null
					// role_id: action === 'edit' ? initialEditValues?.role?.name : null
				}}
				id='cross-fee'
			>
				{action === 'edit' && (
					<Flex
						// bgColor={
						// 	initialEditValues?.status === false ? '#fff4f3' : '#F4FFEF'
						// }
						justifyContent='space-between'
						pad='20px'
						marginBottom='35px'
					>
						<p
							style={{
								color: initialEditValues?.status === false && '#f44336'
							}}
						>
							Status
						</p>
						<Form.Item name='status'>
							<Switch
								// defaultChecked={true}
								defaultChecked={initialEditValues?.status}
								// onChange={handleSwitched}
							/>
						</Form.Item>
					</Flex>
				)}
				<Form.Item
					label='Merchant'
					name='merchant'
					// rules={[
					// 	{
					// 		required: true,
					// 		message: 'Please input full name!'
					// 	}
					// ]}
				>
					{/* <Select
						showSearch
						filterOption={
							(input, option) => {
								console.log(input, option);
							}
							// (option ?? '').toLowerCase().includes(input.toLowerCase())
						}
					>
						{merchant?.data?.data?.data?.data?.map((item) => (
							<Select.Option key={item?.id} v>
								{item?.business_name}
							</Select.Option>
						))}
					</Select> */}
					<Select
						showSearch
						placeholder='Search'
						optionFilterProp='children'
						// onChange={onChange}
						// onSearch={onSearch}
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={optionValue}
					/>
					{/* <Container className='input_container' width={'20%'}>
						<Search
							placeholder={'Search by name.'}
							// input={input}
							// setInput={setInput}
							// handleSearch={handleSearch}
						/>
					</Container> */}
					{/* <Input
						placeholder='Search for a merchant'
						// prefix={<SearchOutlined />}

						type='text'
						onChange={handleSearch}
					/> */}
					{/* <Input size="large" placeholder="large size" prefix={<UserOutlined />} /> */}
				</Form.Item>
				<Form.Item
					label='Transaction Type'
					name={'transaction_type'}
					// initialValue={item?.label}
					// defaultValue={item?.label}
					style={{ width: '100%' }}
					// value={item?.label}
				>
					<Select
						placeholder='Select transaction type'
						onChange={(val) => {
							const methods = paymentMethod?.filter(
								({ method }) => method?.toLowerCase() === val
							);
							setFormPaymentMethod(methods);
						}}
						options={[
							{
								value: 'deposit',
								label: 'Deposit'
							},
							{
								value: 'withdraw',
								label: 'Withdraw'
							}
						]}
					/>
				</Form.Item>
				<Form.Item
					label='Payment Method'
					name={'payment_method'}
					style={{ width: '100%' }}
				>
					<Select placeholder='Select payment method' showSearch>
						{formPaymentMethod?.map((item) => (
							<Select.Option
								children={item?.method}
								value={item?.id}
								key={item?.id}
							>
								{item.name}:{item?.provider}
								{/* {action !== 'edit'
									? `${item.name}:${item?.provider}`
									: item.id === Number(initialEditValues?.payment_methods)
									? `${item.name}:${item?.provider}`
									: `${item.name}:${item?.provider}`} */}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name='fee_currency' label='Fee Currency'>
					<Select
						placeholder='Select fee currency'
						// showSearch
						// filterOption={(input, option) =>
						// 	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						// }
						onChange={handleInputChange}
					>
						{currencies?.map((item) => (
							<Select.Option value={item.code} key={item?.id}>
								{item.code}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Flex justifyContent='space-between' width='100%' gap='20px'>
					<Form.Item
						label='From Currency'
						name='from_currency'
						style={{
							width: '80%'
						}}
					>
						<Select
							placeholder='Select from currency'
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{currencies?.map((item) => (
								<Select.Option value={item.code}>{item.code}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Flex width='20%'>
						<Divider
							style={{
								// marginLeft: 8,
								// width: '150px',
								background: 'black'
							}}
						/>
					</Flex>
					<Form.Item
						label='To Currency'
						name='to_currency'
						style={{
							width: '80%'
						}}
					>
						<Select
							placeholder='Select to currency'
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{currencies?.map((item) => (
								<Select.Option value={item.code}>{item.code}</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Flex>
				<CustomAccordion title='Add Fees Settings' type='cross'>
					<Flex justifyContent='start' gap={'50px'}>
						<Form.Item
							label='Fee type'
							name='fee_type'
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Fee type is required'
							// 	}
							// ]}
						>
							<Select placeholder='Select fee type'>
								<Select.Option value='FLAT'>FLAT</Select.Option>
								<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item name='fee' label='Fee Amount'>
							<Input placeholder='Enter fee' />
						</Form.Item>
						{curType === 'CRYPTO' ? (
							<Form.Item label='Chain' name='chain'>
								<Input placeholder='Enter chain' type='text' />
							</Form.Item>
						) : null}
					</Flex>
				</CustomAccordion>

				{!optional ? (
					<Button
						Styles
						text='Add Optional Transaction'
						color={'#1A2CCE'}
						size='md'
						type='button'
						// onClick={() => add()}
						onClick={() => setOptional(true)}
						style={{
							padding: '0',
							textDecoration: 'underline',
							marginTop: '15px'
						}}
					/>
				) : (
					<>
						<OptionalStyle>
							<p>Add Optional Transaction</p>
							<Form.Item
								label='Transaction Type'
								name={'optional_transaction_type'}
								// initialValue={item?.label}
								// defaultValue={item?.label}
								style={{ width: '100%' }}
								// value={item?.label}
							>
								<Select
									placeholder='Select transaction type'
									onChange={(val) => {
										const methods = paymentMethod?.filter(
											({ method }) => method?.toLowerCase() === val
										);
										setOptionalPaymentMethod(methods);
									}}
									options={[
										{
											value: 'deposit',
											label: 'Deposit'
										},
										{
											value: 'withdraw',
											label: 'Withdraw'
										}
									]}
								/>
							</Form.Item>
							<Form.Item
								label='Payment Method'
								name={'optional_payment_method'}
								// initialValue={item?.label}
								// defaultValue={item?.label}
								style={{ width: '100%' }}
								// value={item?.label}
							>
								<Select placeholder='Select payment method' showSearch>
									{optionalPaymentMethod?.map((item) => (
										<Select.Option
											children={item?.method}
											value={item?.id}
											key={item?.id}
										>
											{item.name}:{item?.provider}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Flex justifyContent='start' gap='50px'>
								<Form.Item label='Fee type' name='optional_fee_type'>
									<Select placeholder='Select fee type'>
										<Select.Option value='FLAT'>FLAT</Select.Option>
										<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item name='optional_fee_amount' label='Fee Amount'>
									<Input placeholder='Enter fee' />
								</Form.Item>
								{curType === 'CRYPTO' ? (
									<Form.Item label='Chain' name='optional_chain'>
										<Input placeholder='Enter chain' type='text' />
									</Form.Item>
								) : null}
							</Flex>
						</OptionalStyle>
						<Button
							Styles
							text='Remove optional setting'
							color='#DB2700'
							size='md'
							type='button'
							onClick={() => {
								setOptional(false);
								// remove(currencyField.name);
								// handleFieldsRemove(fieldSetting.label);
							}}
							style={{
								marginTop: '15px',
								padding: '0',
								textDecoration: 'underline'
							}}
						/>
					</>
				)}

				<Flex
					flexDir='row'
					justifyContent='flex-start'
					// pad='30px'
					alignItems='baseline'
					gap={'20px'}
					width='100%'
					marginTop='10px'
				>
					<p style={{ fontSize: '15px', lineHeight: '20px' }}>
						Set Environment
					</p>
					<Form.Item name='env'>
						<Checkbox.Group
							style={{ width: '100%' }}
							options={['Live', 'Test']}
							// defaultValue={initialEditValues}
						/>
					</Form.Item>
				</Flex>

				{/* <Button
					Styles
					text={action === 'add' ? 'Send Invite' : 'Update'}
					bgColor={'#1A2CCE'}
					color={'#FFFFFF'}
					fullwidth
					size='md'
					type='submit'
					border={'0.7px solid #BFBFBF'}
					boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
					borderRadius={'3px'}
					// loading={addMutation.isLoading || editStaffMutation.isLoading}
				/> */}
			</Form>
		</>
	);
};

export { FormLayout };
