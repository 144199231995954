import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Flex } from '../../../../../components/box';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import { getMerchantCurrencies } from '../../../../../services/Others.service';
import { balanceTableHead } from '../../../../../utils/constants';
import { ApproveTransactionSummary } from '../../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import { Styles } from './styles';
import CustomTable from './table';

const Index = () => {
	let params = useParams();
	const [visible, setVisible] = useState(false);
	const [otpVisible, setOtpVisible] = useState(false);
	const [visibleUnfreeze, setVisibleUnfreeze] = useState(false);
	const [bal, setBal] = useState(Number);
	const [pendingVal, setPendingVal] = useState(Number);
	const [sym, setSym] = useState('');
	const [requiredAction, setRequiredAction] = useState('');
	const [code, setCode] = useState('');
	const [amount, setAmount] = useState(Number);

	const result = useQuery(
		'getMerchantCurrencies',
		async () => await getMerchantCurrencies(params.merchant)
	);

	const showFreeze = (data, action) => {
		setSym(data?.symbol);
		setBal(data?.balance);
		setRequiredAction(action);
		setCode(data?.code);
		setPendingVal(data?.pending_balance);
		setVisible(true);
		// setPendingVal(0);
	};
	const showUnfreeze = (data, action) => {
		setSym(data?.symbol);
		setBal(data?.balance);
		setRequiredAction(action);
		setCode(data?.code);
		setPendingVal(data?.pending_balance);
		setVisibleUnfreeze(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setVisibleUnfreeze(false);
		setOtpVisible(false);
	};

	const handelSubmit = (values) => {
		if (requiredAction === 'freeze') {
			setVisible(false);
		} else if (requiredAction === 'unfreeze') {
			setVisibleUnfreeze(false);
		}
		values.code = code;
		setAmount(values);
		setOtpVisible(true);
	};

	if (result.status === 'loading') {
		return <Loader />;
	}

	if (result.status === 'error') {
		return <span>Error: {result.error.message}</span>;
	}

	return (
		<Styles>
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='5px'
				tableHead={balanceTableHead}
				tableBody={result?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#000A62'
				tbodyColor='#1A1F36'
				handleReadAll={() => []}
				pageSize={10}
				showFreeze={showFreeze}
				showUnfreeze={showUnfreeze}
			/>
			<ModalFrame
				visible={requiredAction === 'freeze' ? visible : visibleUnfreeze}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						form={requiredAction === 'freeze' ? 'freeze' : 'unfreeze'}
					/>
				]}
			>
				<h3 style={{ textAlign: 'left' }}>
					{requiredAction === 'freeze' ? 'Freeze' : 'Unfreeze'} Amount
				</h3>
				<Flex justifyContent='start'>
					<p style={{ textAlign: 'left', marginBottom: 10 }}>
						Available balance: {sym + bal?.toLocaleString()}
					</p>
					{pendingVal !== 0 && (
						<p style={{ textAlign: 'left', marginBottom: 10 }}>
							Pending balance: {sym + pendingVal?.toLocaleString()}
						</p>
					)}
				</Flex>
				<FormLayout
					handelSubmit={handelSubmit}
					action={requiredAction === 'freeze' ? 'freeze' : 'unfreeze'}
					pendingVal={pendingVal}
				/>
			</ModalFrame>

			<ModalFrame
				visible={otpVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					id={params.merchant}
					// handleQrScreen={handleqrCode}
					// swapAction={status}
					status={requiredAction}
					setVisible={setOtpVisible}
					amountData={amount}
					// addEditData={formData}
					// showConfirmModal={showConfirmModal}
				/>
				{/* <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame> */}
			</ModalFrame>
		</Styles>
	);
};

export default Index;

const FormLayout = ({ pendingVal, action, handelSubmit }) => {
	return (
		<Form
			id={action === 'freeze' ? 'freeze' : 'unfreeze'}
			onFinish={handelSubmit}
			layout={'vertical'}
			initialValues={{
				amount: action === 'unfreeze' ? pendingVal : null
			}}
		>
			<Form.Item
				label={`Amount to ${action === 'unfreeze' ? 'Unfreeze' : 'Freeze'}`}
				name='amount'
				style={{ width: '100%', marginBottom: 0 }}
			>
				<Input type='number' />
			</Form.Item>
		</Form>
	);
};
