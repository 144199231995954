import styled from "styled-components";

export const InputStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  position: relative;
  width: 100%;
  a {
    color: #1a2cce;
    position: absolute;
    right: 3px;
    text-decoration: underline;
    font-size: 14px;
  }
  > div:last-child {
    color: red;
    margin-top: 7px;
    font-size: 12px;
    font-weight: normal;
  }
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
`;

export const FieldWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  width: 100%;
  height: 46px;
  padding-right: ${({ btn }) => (btn && "50px") || " 0px"};
  position: relative;
  &:focus {
    border: 1px solid #449eff;
  }
  button {
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 2px;
  }
  input {
    width: 100%;
    padding: 10px;
    color: #000000;
    height: 100%;
  }
`;

export const TextArea = styled.div`
  border: 1px solid #d7d7d7;
  width: 100%;
  textarea {
    background: #ffffff;
    width: 100%;
    color: #000000;
    resize: none;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 15px;
    &::placeholder {
      color: #a8a8a8;
    }
  }
`;

export const Select = styled.select`
  div {
    border: 2px solid #dde2ec;
    padding: 20px;
    margin-left: 10px;
    background-color: white;
  }

  select {
    border: none;
    background-color: white;
    outline: none;
    box-shadow: none;
    padding: 20px;
  }
`;
