import PropTypes from "prop-types";
import React from "react";
import { generateID } from "../../../../utils/generateID";
import Pagination from "../../../../components/paginator";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { OverFlowScrollBar } from "../../../../components/overflowScroll/styles";
import { Link, useNavigate } from "react-router-dom";
import PopOver from "../../../../components/popover/PopOver";
import { EmptyState } from "../../../../components/emptyState";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  source,
  handlePageChange,
  meta,
}) => {
  const navigate = useNavigate();
  const returnTableRow = (data) => {
    const checkVerifed = data.kyc;
    return (
      <tr
        key={data.id}
        onClick={() => navigate(`/dashboard/merchants/${data.id}/compliance`)}
      >
        {source === "approval" && (
          <>
            <Td>
              <div>
                <span>{data?.business_name}</span>
              </div>
            </Td>
            <Td>
              <div>
                <span>{data.business_email}</span>
              </div>
            </Td>
            <Td>
              <div>
                <span>{data.country}</span>
              </div>
            </Td>
            <CustomTd status={checkVerifed}>
              <div>
                <span>{checkVerifed}</span>
              </div>
            </CustomTd>
            <Td>
              <div>
                <span
                  style={
                    data.status === "active"
                      ? { color: "#027200" }
                      : { color: "#CE1A1A" }
                  }
                >
                  {data.status}
                </span>
              </div>
            </Td>
            <CustomTd>
              <Link to={`/dashboard/merchants/${data.id}/compliance`}>
                <div>
                  <p>view details</p>
                </div>
              </Link>
            </CustomTd>
          </>
        )}
        {source === "pending" && checkVerifed === "pending" && (
          <>
            <Td>
              <div>
                <span>{data.business_name}</span>
              </div>
            </Td>
            <Td>
              <div>
                <span>{data.business_email}</span>
              </div>
            </Td>
            <Td>
              <div>
                <span>{data.country}</span>
              </div>
            </Td>
            <CustomTd status={checkVerifed}>
              <div>
                <span>{checkVerifed}</span>
              </div>
            </CustomTd>
            <Td>
              <div>
                <span
                  style={
                    data.status === "active"
                      ? { color: "#027200" }
                      : { color: "#CE1A1A" }
                  }
                >
                  {data.status}
                </span>
              </div>
            </Td>
            <CustomTd>
              <Link to={`/dashboard/merchants/${data.id}/compliance`}>
                <div>
                  <p>view details</p>
                </div>
              </Link>
            </CustomTd>
          </>
        )}
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead.map((head, i) => (
                    <th key={generateID(11)}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination meta={meta} handlePageChange={handlePageChange} />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
};

export default CustomTable;
