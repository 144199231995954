import styled from "styled-components";
// import { Flex } from '../../../../components/box';

export const Styles = styled.div`
  .currencies {
    padding-bottom: 20px;

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #1a1f36;
    }
    button {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      padding: 0;
      text-decoration: underline;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #061f39;
    margin-bottom: 20px;
  }
  .crypto,
  .local {
    .checkbox_conainer {
      /* border: 1px solid black; */
      width: fit-content;
      align-items: flex-start;
      margin-right: 15px;
      > span {
        color: #1a2cce;
        margin-left: 3px;
        /* border-bottom: 1px solid #1a2cce; */
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .ant-checkbox-wrapper {
      .ant-checkbox + span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #061f39;
        padding-right: 0;
      }
      .ant-checkbox-inner {
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background: #1a2cce;
          border-radius: 4px;
        }
      }
    }
  }
  .crypto,
  .local {
    .ant-checkbox-wrapper {
      margin-left: 0;
      margin-bottom: 32px;
      width: 30%;

      @media (max-width: 1200px) {
        width: 50%;
      }
    }
  }
  .local {
    padding-bottom: 40px;
  }

  .local-text {
    padding-top: 40px;
  }

  .btn {
    .add-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      padding: 0;
      text-align: right;
      text-decoration-line: underline;
    }

    .save {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 15px 55px;
    }
  }
`;

export const FormContainer = styled("div")`
  .icon-box {
    position: relative;
    display: flex;
    .avatar {
      width: 70px;
      height: 55px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d7e6ff;
      margin-right: 16px;
    }
    .avatar img {
      max-width: 100%;
      max-height: 100%;
    }
    > input {
      position: absolute;
      border: 1px solid red;
      visibility: hidden;
    }
    h4 {
      text-decoration-line: underline;
      cursor: pointer;
    }
    p {
      margin-left: 5px;
    }
  }
  h3 {
    text-align: left;
  }
  .upload {
    svg {
      margin-right: 24px;
    }

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 0;
      padding-bottom: 1.5px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #797979;
      margin-bottom: 0;
    }

    padding-bottom: 50px;
  }

  .currency,
  .deposit,
  .withdraw,
  .swap {
    border-bottom: 0.3px solid #bfbfbf;

    .ant-form-item {
      width: 392px;
      .ant-input,
      .ant-select .ant-select-selector {
        height: 51.52px;
        background: #ffffff;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 40px;
        padding-top: 5px;
      }
    }
  }
  .deposit,
  .withdraw,
  .swap {
    padding-top: 30px;
    h3 {
      padding-bottom: 1rem;
    }
  }

  .swap {
    border: none;
  }
`;
