import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Checkbox, Switch } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SettingsEditIcon, VerifiedIcon } from '../../../../../assets/';
import profileImg from '../../../../../assets/images/profile.svg';
import { Flex, Frame, Grid } from '../../../../../components/box';
import Button from '../../../../../components/button';
import { Loader } from '../../../../../components/loader';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import {
	disableMerchantCurrency,
	enableMerchantCurrency,
	suspendMerchant,
	unsupendMerchant
} from '../../../../../services/Merchants.service';
import { getMerchantCurrencies } from '../../../../../services/Others.service';
import { formattedDate } from '../../../../../utils/helpers';
import { ToastMessage, type } from '../../../../../utils/toastr';
import AddAssets from './addAsset';
import EditProfileInfo from './editProfileInfo';
import { Box, Container, Container3, ProfileContainer, Styles } from './styles';

const Index = ({ merchant }) => {
	let params = useParams();
	const queryClient = useQueryClient();

	const [action, setAction] = useState();
	const [suspendUser, setSuspendUser] = useState(false);

	const result = useQuery(
		'getMerchantCurrencies',
		async () => await getMerchantCurrencies(params.merchant)
	);

	const enableMutation = useMutation(
		async (data) => {
			await enableMerchantCurrency(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantCurrencies');
				ToastMessage(type.SUCCESS, 'Asset sucessfully enabled');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const disableMutation = useMutation(
		async (data) => {
			await disableMerchantCurrency(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantCurrencies');
				ToastMessage(type.SUCCESS, 'currency sucessfully disabled');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const suspendMutation = useMutation(
		async (data) => {
			await suspendMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getMerchantById']);
				queryClient.invalidateQueries(['getTotalSuspendedMerchantSummary']);
				ToastMessage(type.SUCCESS, 'merchant has been suspended');
				setTimeout(() => {
					setTimeout(() => setSuspendUser(false), 500);
				}, 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const unsuspendMutation = useMutation(
		async (data) => {
			await unsupendMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				queryClient.invalidateQueries(['getTotalSuspendedMerchantSummary']);
				ToastMessage(type.SUCCESS, 'merchant has been unsuspended');
				setTimeout(() => {
					setTimeout(() => setSuspendUser(false), 500);
				}, 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const checkVerifed = merchant?.kyc;
	const currencies = result?.data?.data?.data;

	const handleChange = (e) => {
		const newUnchecked = e.target.name;
		if (e.target.checked === false) {
			disableMutation.mutate({ code: newUnchecked });
		}
		if (e.target.checked === true) {
			enableMutation.mutate({ code: newUnchecked });
		}
	};

	const showSuspendModal = (action) => {
		setSuspendUser(true);
		setAction(action);
	};
	const handleSuspend = () => {
		if (action === 'active') {
			suspendMutation.mutate(params.merchant);
		} else if (action === 'suspended') {
			unsuspendMutation.mutate(params.merchant);
		}
	};
	const handleOk = () => {
		setTimeout(() => {
			setSuspendUser(false);
		}, 2000);
	};

	const handleCancel = () => {
		setSuspendUser(false);
	};
	if (result.isLoading) {
		return <Loader />;
	}

	if (result.isError) {
		return <span>Error: {result?.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container flexDir={'column'} alignItem={'flex-start'}>
					<ProfileContainer
						status={checkVerifed}
						justifyContent={'space-between'}
					>
						<Flex width='max-width'>
							<Flex
								bgColor={'#D7E6FF'}
								className='profile'
								width={'70px'}
								height={'70px'}
							>
								<Frame width={'40px'} height={'40px'}>
									<img src={profileImg} alt={'profile-img'} />
								</Frame>
							</Flex>
							<label htmlFor='img'> {merchant?.business_name}</label>
							<div className='verified_box'>
								{checkVerifed === 'verified' && <VerifiedIcon />}
								<span>{checkVerifed}</span>
							</div>
						</Flex>
						<div className='sideButtons'>
							<EditProfileInfo initialEditValue={merchant} />
							<Button
								text={
									merchant?.status === 'suspended'
										? 'Unsuspend user'
										: 'Suspend user'
								}
								bgColor={'#FFE4E4'}
								color={'#CE1A1A'}
								size='sm'
								borderRadius={'5px'}
								onClick={() => showSuspendModal(merchant?.status)}
							/>
							<Button
								text={<SettingsEditIcon />}
								bgColor={'#EEEEEE'}
								size='sm'
								borderRadius={'5px'}
							/>
						</div>
					</ProfileContainer>
					<Grid
						className='grid_container'
						justifyContent={'flex-start'}
						gap={'40px 60px'}
						gridCol={'repeat(3,1fr)'}
						alignItem={'flex-start'}
						width={'100%'}
					>
						<Box>
							<label htmlFor='fullName'>Business Name</label>
							<span>{merchant?.business_name}</span>
						</Box>
						<Box>
							<label htmlFor='email'>Website</label>
							<button
								target='_blank'
								rel='noreferrer'
								onClick={() => window.open(`https://${merchant?.website}`)}
								to='#'
							>
								view website
							</button>
						</Box>
						<Box>
							<label htmlFor='CreatedOn'>Created on</label>
							<span>{formattedDate(merchant?.created_at)}</span>
						</Box>
						<Box>
							<label htmlFor='Email address'>Email address</label>
							<span>{merchant?.business_email}</span>
						</Box>
						<Box span={'span'}>
							<label htmlFor='Phone number'>Phone number</label>

							<span>{merchant?.phone}</span>
						</Box>
						<Box>
							<label htmlFor='Email address'>Region/ Country</label>
							<span>{merchant?.country}</span>
						</Box>
					</Grid>
					<Box pad={'40px 0px'}>
						<label htmlFor='Email address'>About</label>
						<span>{merchant?.business_description}</span>
					</Box>
					<Container3 flexDir={'column'} alignItem={'flex-start'}>
						<Flex justifyContent='space-between'>
							<h5>Currencies</h5>

							<AddAssets />
						</Flex>
						<p>
							Add or remove supported currency for slected merchant. <br />
							Users can only transact assets on currencies selected.
						</p>
						<Flex
							flexDir={'column'}
							alignItem={'flex-start'}
							className='crpto'
							pad={'20px 0'}
						>
							<h5>Crypto</h5>
							<Flex width='90%' flexWrap={'wrap'} justifyContent={'flex-start'}>
								{currencies?.map(
									(item) =>
										item?.type === 'CRYPTO' && (
											<div key={item.id} className='check_input_container'>
												<Checkbox
													key={item.id}
													defaultChecked={item.status}
													name={item.code}
													onChange={handleChange}
												>
													{item?.name} {`(${item?.code})`}
												</Checkbox>
											</div>
										)
								)}
							</Flex>
						</Flex>
						<Flex
							flexDir={'column'}
							alignItem={'flex-start'}
							className='crpto'
							width='90%'
						>
							<h5> Local Currency</h5>
							<Flex flexWrap={'wrap'} justifyContent={'flex-start'}>
								{currencies?.map(
									(item) =>
										item.type === 'FIAT' && (
											<div key={item.id} className='check_input_container'>
												<Checkbox
													key={item.id}
													defaultChecked={item.status}
													name={item.code}
													onChange={handleChange}
												>
													{item?.name} {`(${item?.code})`}
												</Checkbox>
											</div>
										)
								)}
							</Flex>
						</Flex>
					</Container3>

					<Flex
						className='notification'
						flexDir='column'
						alignItem='start'
						justifyContent='start'
					>
						<div className='info'>
							<h5>Compliance</h5>
							<p>Request compliance for wallet creation</p>
						</div>
						<Flex
							className='info2'
							justifyContent='space-between'
							alignItem='end'
							width='22%'
						>
							<p>Document required</p>
							<Switch
							// checked={true}
							// onChange={handleSwitched}
							/>
						</Flex>
						<Flex
							className='info2'
							justifyContent='space-between'
							alignItem='end'
							width='22%'
						>
							<p>BVN required</p>
							<Switch
							// checked={true}
							// checked={settings?.data?.data?.data?.wallet_compliance_bvn}
							// onChange={handleSwitchedBvn}
							/>
						</Flex>
					</Flex>
				</Container>
			</Styles>
			<ModalFrame
				visible={suspendUser}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleSuspend}
						loading={suspendMutation.isLoading || unsuspendMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>
					{action === 'active' ? 'Suspend user' : 'Unsuspend User'}
				</h3>
				<p>
					{`Are you sure you want to ${
						action === 'active' ? 'suspend' : 'unsuspend'
					} the user`}
				</p>
			</ModalFrame>
		</>
	);
};

export default Index;
