import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
	AcceptTransactionIcon,
	CopyIcon,
	DeleteIcon,
	DisabledIcon
} from '../../../../../assets';
import { EmptyState } from '../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../components/paginator';
import PopOver from '../../../../../components/popover/PopOver';
import { useCopy } from '../../../../../customHooks/useCopyToClipBoard';
import { generateID } from '../../../../../utils/generateID';
import { formattedDate } from '../../../../../utils/helpers';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handlePageChange,
	meta,
	currencies,
	handleDelete,
	handleDisabledOrEnabled,
	merchantName,
	type
}) => {
	const { copyToClipboard, copySuccess } = useCopy();

	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.id}>
				<Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td>

				<Td>
					{/* <div
						style={{
							display: 'flex'
						}}
					>
						<spa
							style={{
								display: 'inline-block',
								width: '6px',
								height: '6px',
								background: data?.status ? 'green' : 'red',
								borderRadius: '100%'
							}}
						></spa> */}
					<span>{formattedDate(data?.created_at)}</span>
					{/* </div> */}
				</Td>
				{type === 'fiat' ? (
					<>
						<Td>
							<span>{data?.account_name}</span>
						</Td>
						<Td>
							<span>{data?.account_number}</span>
							<span
								className='copyBtn'
								onClick={() => copyToClipboard(data?.account_number)}
							>
								<CopyIcon />
							</span>
						</Td>
					</>
				) : (
					<>
						<Td>
							<span>{data?.address?.slice(0, 15)}...</span>
							<span
								className='copyBtn'
								onClick={() => copyToClipboard(data?.wallet_address)}
							>
								<CopyIcon />
							</span>
						</Td>
						<Td>
							<span>{data?.chain}</span>
						</Td>
					</>
				)}
				<Td>
					<span>{data?.currency}</span>
				</Td>
				<Td>
					<span>{data?.account_type}</span>
				</Td>

				<CustomTd>
					<PopOver placement='rightTop' type='wallet'>
						<Link
							to={`#`}
							onClick={() =>
								handleDisabledOrEnabled(
									data?.id,
									data?.status,
									data?.type === 'FIAT' ? true : false
								)
							}
						>
							<div>
								{data?.status ? (
									<DisabledIcon />
								) : (
									<AcceptTransactionIcon width='14' height='14' />
								)}
								<p>{data?.status ? 'Disable' : 'Enable'} Account</p>
							</div>
						</Link>
						<Link
							to={`#`}
							onClick={() =>
								handleDelete(data?.id, data?.type === 'FIAT' ? true : false)
							}
						>
							<div>
								<DeleteIcon color='#979797' />
								<p>Delete Account</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination meta={meta} handlePageChange={handlePageChange} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;
