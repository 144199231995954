import { Divider, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { BankIcon, WalletIcon2 } from '../../../../assets';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import { useCopy } from '../../../../customHooks/useCopyToClipBoard';
import { StylesResponse } from './styles';

const FundAccountResponse = ({ type, changePayment, handleOk }) => {
	const { copyToClipboard, copySuccess } = useCopy();
	const { copySuccess: copySuccessBank, copyToClipboard: copyToClipboardBank } =
		useCopy();
	const {
		copySuccess: copySuccessAcctNumber,
		copyToClipboard: copyToClipboardAcctNumber
	} = useCopy();
	const {
		copySuccess: copySuccessAddress,
		copyToClipboard: copyToClipboardAddress
	} = useCopy();
	return (
		<StylesResponse>
			<h3>Deposit</h3>
			<p className='txt'>
				{type === 'Fiat'
					? 'Make your deposit to the account below'
					: 'Make deposits into your wallet address below'}
			</p>
			<div className='iconText'>
				{type === 'Fiat' ? <BankIcon /> : <WalletIcon2 />}
				<p>{type === 'Fiat' ? 'BANK ACCOUNT' : 'WALLET ADDRESS'}</p>
			</div>
			<div className='mainBox'>
				{type === 'Fiat' ? (
					<>
						<div className='acct_display'>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex flexDir='column' alignItems='flex-start'>
									<p className='mainTxt'>Account name</p>
									<p className='txt'>N/A</p>
								</Flex>
								<div>
									<button onClick={() => copyToClipboard('Christain Jumbo')}>
										{copySuccess}
									</button>
								</div>
							</Flex>
						</div>
						<div className='acct_display'>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex flexDir='column' alignItems='flex-start'>
									<p className='mainTxt'>Bank Name</p>
									<p className='txt'>N/A</p>
								</Flex>
								<div>
									<button onClick={() => copyToClipboardBank('Providus Bank')}>
										{copySuccessBank}
									</button>
								</div>
							</Flex>
						</div>
						<div className='acct_display'>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex flexDir='column' alignItems='flex-start'>
									<p className='mainTxt'>Account Number</p>
									<p className='txt'>N/A</p>
								</Flex>
								<div>
									<button
										onClick={() => copyToClipboardAcctNumber('0083775638')}
									>
										{copySuccessAcctNumber}
									</button>
								</div>
							</Flex>
						</div>

						<div className='acct_display2'>
							<Flex alignItems='baseline' justifyContent='space-between'>
								<p className='mainTxt'>Deposit fees</p>
								<p className='txt'>N/A</p>
							</Flex>
						</div>
					</>
				) : (
					<>
						<p
							style={{
								marginBottom: '6px',
								fontWeight: 500,
								fontSize: '15px',
								lineHeight: '20px',

								letterSpacing: '0.01em',

								color: '#0B0B0B',
								textAlign: 'left'
							}}
						>
							Choose Network
						</p>
						<Select
							placeholder='Select currency type'
							// onChange={(value) => setCurrencyType(value)}
							style={{
								width: '100%'
							}}
							defaultValue='Tron (TRC20)'
						>
							<Select.Option value='Tron (TRC20)'>Tron (TRC20)</Select.Option>
						</Select>

						<div
							style={{
								margin: '0 auto',
								padding: '20px 100px 0 100px'
							}}
						>
							<img
								src='https://res.cloudinary.com/shutterscore/image/upload/v1665024606/juice_master_usdc_trx_wallet_cyb9jg.png'
								alt='Qr'
								width='100%'
							/>
						</div>
						<p>
							<Link
								to='#'
								style={{
									fontStyle: 'normal',
									fontWeight: 500,
									fontSize: '12px',
									lineHeight: '16px',

									textAlign: 'center',

									color: '#4553C7'
								}}
							>
								Send only USDC(TRC20) to this wallet address
							</Link>
						</p>

						<Divider />

						<div className='acct_display'>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex flexDir='column' alignItems='flex-start'>
									<p className='mainTxt'>Network</p>
									<p className='txt'>Tron (TRC20)</p>
								</Flex>
								<Flex flexDir='column' alignItems='flex-end'>
									<p className='mainTxt'>Minimum deposit</p>
									<p className='txt'>N/A</p>
								</Flex>
							</Flex>
						</div>
						<div className='acct_display'>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex flexDir='column' alignItems='flex-start'>
									<p className='mainTxt'>Deposit Address</p>
									<p className='txt'>TFyGJeFY9bTZS7eaqt63WJgXk7WuE3A5Xu</p>
								</Flex>
								<div>
									<button
										onClick={() =>
											copyToClipboardAddress(
												'TFyGJeFY9bTZS7eaqt63WJgXk7WuE3A5Xu'
											)
										}
									>
										{copySuccessAddress}
									</button>
								</div>
							</Flex>
						</div>
					</>
				)}
			</div>
			{type === 'Fiat' && (
				<>
					<Button
						Styles
						bgColor={'#1A2CCE'}
						text='I have done this'
						color={'#FFFFFF'}
						size='md'
						type='bottom'
						borderRadius='5px'
						onClick={handleOk}
						fullwidth
					/>
				</>
			)}
			<Button
				Styles
				text='Change payment method'
				color={'#676767'}
				size='md'
				type='bottom'
				borderRadius='5px'
				onClick={changePayment}
				fullwidth
			/>
		</StylesResponse>
	);
};

export default FundAccountResponse;
