import styled from "styled-components";

export const Styles = styled.div`
  .container {
    // border: 1px solid red;
    border-bottom: 0.3px solid #c4c4c4;
    padding: 20px 5px;
    :last-child {
      border-bottom: none !important;
    }
  }
  .currency_holder {
    margin-left: 20px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      /* Normal-side-panel text */

      color: #1a1f36;

      :last-child {
        margin-top: 5px;
        font-size: 14px;
        line-height: 17px;
        color: #8d8d8d;
      }
    }
  }

  text-align: left;
  .value_holder {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      color: #767676;
    }
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1a2cce !important;
  }

  .ant-tabs-tab:hover {
    color: #1a2cce !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 5px solid #1a2cce;
  }
`;
