import { Input, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Flex, Frame } from "../../../../components/box";
import { Styles } from "./styles";
// import { useAxios } from "../../../../customHooks/useAxios";
import { getSummarySwap } from "../../../../services/Transactions.service";
import { EmptyState } from "../../../../components/emptyState";
import { useQuery } from "react-query";
import { Loader } from "../../../../components/loader";
// import ngn from "../../../../assets/images/ngflag.svg";
import usdt from "../../../../assets/images/usdt.svg";
import { DefaultCurrencyImage } from "../../../../assets";
const { TabPane } = Tabs;

const PaymentSummary = () => {
  const [state, setState] = useState("");

  const result = useQuery(
    ["getSummarySwap"],
    async () => await getSummarySwap(state),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => result.refetch(), 1000);

    return () => clearTimeout(timeoutId);
  }, [state, result]);

  const callback = (key) => {
    result.refetch();
  };

  const handleChange = (e) => {
    setState(e.target.value);
    // setTimeout(() => result.refetch(), 1000);

    // result.refetch();
  };

  if (result.isLoading) {
    return <Loader />;
  }

  // if (result.isFetching) {
  //   return <Loader />;
  // }

  if (result.status === "error") {
    return <span>Error: {result.error}</span>;
  }

  const summarySwap = result?.data?.data?.data;
  return (
    <Styles>
      <Flex
        justifyContent="space-between"
        alignItem="flex-start"
        margin="0 0 20px 0"
      >
        <h3>Payment Summary</h3>
        <Flex
          width="45%"
          flexDir="row"
          justifyContent="flex-end"
          alignItem="flex-end"
        >
          <Input
            placeholder="Input time"
            style={{
              width: "200px",
              height: "40px",
            }}
            size="large"
            onChange={handleChange}
            addonBefore="MINS"
          />
        </Flex>
      </Flex>
      {/* <Tabs defaultActiveKey="1"> */}
      {/* <TabPane tab="Fiat" key="1">
          {summarySwap?.length >= 0 ? (
            summarySwap?.map((item, index) => (
              <div key={index * 40} className="container">
                <Flex flexDir="row" justifyContent="flex-start">
                  <Flex justifyContent="flex-start">
                    <Frame
                      height={"30px"}
                      width={"30px"}
                      style={{
                        background: "#F3F3F3",
                        borderRadius: "16.9228px",
                        padding: "5px",
                      }}
                    >
                      <DefaultCurrencyImage />
                    </Frame>
                    <Flex
                      flexDir="column"
                      justifyContent="flex-start"
                      alignItem="flex-start"
                      className="currency_holder"
                    >
                      <p>{item?.currency}</p>
                    </Flex>
                  </Flex>
                  <Flex justifyContent="flex-end" className="value_box">
                    <p>
                      {item?.currency} {item?.sum}
                    </p>
                  </Flex>
                </Flex>
              </div>
            ))
          ) : (
            <EmptyState />
          )}
        </TabPane> */}
      {/* <TabPane tab="Crypto" key="2"> */}
      {summarySwap?.length > 0 ? (
        summarySwap?.map(
          (item, index) => (
            // item?.currency === "USDT" || item?.currency === "USD" ? (
            <div key={index * 80} className="container">
              <Flex flexDir="row" justifyContent="flex-start">
                <Flex justifyContent="flex-start">
                  <Frame
                    height={"30px"}
                    width={"30px"}
                    style={{
                      background: "#F3F3F3",
                      borderRadius: "16.9228px",
                      padding: "5px",
                    }}
                  >
                    <DefaultCurrencyImage />
                  </Frame>
                  <Flex
                    flexDir="column"
                    justifyContent="flex-start"
                    alignItem="flex-start"
                    className="currency_holder"
                  >
                    <p>{item?.currency}</p>
                  </Flex>
                </Flex>
                <Flex justifyContent="flex-end" className="value_box">
                  <p>
                    {item?.currency} {item?.sum}
                  </p>
                </Flex>
              </Flex>
            </div>
          )
          // ) : (
          //   <EmptyState />
          // )
        )
      ) : (
        <EmptyState />
      )}
      {/* </TabPane> */}
      {/* </Tabs> */}
    </Styles>
  );
};

export default PaymentSummary;
