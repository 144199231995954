import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import Button from "../../../../../../components/button";
import CustomTable from "./table";
import { StylesContainer } from "./styles";
import { Link, useParams } from "react-router-dom";
import ModalFrame from "../../../../../../components/modal/ModalFrame";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { terminateSession } from "../../../../../../services/Settings.service";
import { Loader } from "../../../../../../components/loader";
import { ToastMessage, type } from "../../../../../../utils/toastr";
import { merchantTeamsSession } from "../../../../../../services/Merchants.service";

const ManageSession = () => {
  const queryClient = useQueryClient();
  let { merchant, userId } = useParams();

  const [visible, setVisible] = React.useState(false);
  const [id, setId] = React.useState(null);

  const result = useQuery(
    "getSessions",
    async () => await merchantTeamsSession(merchant, userId)
  );

  const terminateMutation = useMutation(
    async (data) => {
      await terminateSession(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "2FA reset successful");
        setTimeout(() => setVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showTerminate = (id) => {
    setVisible(true);
    setId(id);
  };

  const handleTerminate = () => {
    terminateMutation.mutate(id);
  };

  const handleCancel = () => {
    setVisible(false);
    setId(null);
  };

  if (result.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <StylesContainer>
        <div className="btnContainer">
          <ArrowLeftOutlined className="icon" />
          <Link to={`/dashboard/merchants/${merchant}/team`} className="link">
            Back
          </Link>
        </div>
        {/* <div className="searchContainer">
          <div className="selectWithIcon">
            <Select
              size="large"
              placeholder="Select filter"
              defaultValue={"All Time"}
              style={{
                marginLeft: "10px",
                width: 140,
              }}
              bordered={false}
            >
              <Select.Option value="All Time">All Time</Select.Option>
            </Select>
          </div>
        </div> */}
        <CustomTable
          theadBkColor="#F1F4F8"
          bottomGap="10px"
          tableHead={["OPERATING SYSTEM", "DATE STARTED", "LAST SEEN", ""]}
          tableBody={result?.data.data.data.data}
          rowHovColor="#d2ccc626"
          theadColor="#8e8e8e"
          tbodyColor="#141414"
          handleReadAll={() => []}
          pageSize={2}
          handleTerminate={showTerminate}
        />
      </StylesContainer>
      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Yes, Terminate"}
            bgColor={"#D80027"}
            color="#FFFFFF"
            size="md"
            type="button"
            onClick={handleTerminate}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
            loading={terminateMutation.isLoading}
          />,
        ]}
      >
        <h3
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "23px",
            textAlign: "center",
            color: "#000000",
          }}
        >
          Terminate this session?
        </h3>
        <p
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "21px",
            textAlign: "center",
            color: "#5B5B5B",
          }}
        >
          Log in access to selected device will be revoked once session is
          terminated.
        </p>
      </ModalFrame>
    </>
  );
};

export default ManageSession;
