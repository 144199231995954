import styled from 'styled-components';
import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	padding: 30px 30px 0;
	background-color: #fff;
	.go_back {
		padding: 2em 0;
		cursor: pointer;
	}
	.copy {
		padding: 3px;
		width: 70px;
		border-radius: 16px;
		font-size: 14px;
		background-color: #3ab75d;
		color: #fff;
		margin: 0 10px;
	}
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	.download {
		background: transparent;
		// background: red;
		// color: #000000;
		color: #1a2cce;
		width: 250px !important;
		text-decoration: underline;
	}
`;

export const Container2 = styled(Flex)`
	justify-content: space-between;
	padding: 30px 0 30px 3em;
	align-items: baseline;
	width: 100%;
	border-bottom: 0.3px solid #b8b8b8;
	@media (max-width: 700px) {
		padding: 30px 0;
	}
	span:first-child {
		color: #afafaf;
		font-size: 24px;
		font-weight: 700;
		margin-right: 10px;
		@media (max-width: 850px) {
			font-size: 16px;
			margin-right: 20px;
		}
	}
	span:nth-child(2) {
		color: #000000;
		font-size: 34px;
		margin-right: 15px;
		font-weight: 700;
		@media (max-width: 850px) {
			font-size: 14px;
			margin-right: 20px;
		}
	}
	span:last-child {
		background-color: ${({ status }) =>
			((status === 'pending' || status === 'refunding') && '#FFF2D9') ||
			((status === 'success' || status === 'refunded') && '#C8FFC7') ||
			((status === 'blocked' || status === 'rejected') && '#FF4848') ||
			(status === 'queued' && '#D9EDFF') ||
			(status === 'failed' && '#FFE4E4')};
		color: ${({ status }) =>
			((status === 'pending' || status === 'refunding') && '#9C5502') ||
			((status === 'success' || status === 'refunded') && '#027200') ||
			((status === 'blocked' || status === 'rejected') && '#FFFFFF') ||
			(status === 'queued' && '#1A2CCE') ||
			(status === 'failed' && '#CE1A1A')};
		padding: 3px 20px;
		font-size: 14px;
		text-align: center;
		text-transform: capitalize;
		/* margin-left:20px */
	}
`;

export const Container3 = styled(Flex)`
	padding: 30px 0;
	padding-left: 3em;
	max-width: 1300px;
	justify-content: space-between;
	align-items: flex-start;

	.box:nth-of-type(1) {
		width: 20%;
	}
	.box:nth-of-type(3) {
		width: 40%;
	}
	.box {
		span {
			font-size: 14px;
			font-weight: bold;
			color: #727272;
		}

		> div > span {
			font-size: 14px;
			color: #000000;
			letter-spacing: 0.05em;
		}
		> div > span > small {
			color: #757575;
		}

		button {
			margin: 0;
		}
	}
	> div {
		/* border-right: 0.3px solid #b8b8b8; */
	}
	> span {
		font-size: 14px;
		font-weight: bold;
		color: #727272;
	}
	> div > span {
		font-size: 14px;
		color: #000000;
		letter-spacing: 0.05em;
	}
	> div > span > small {
		color: #757575;
	}

	@media (max-width: 700px) {
		padding-left: 0;
	}
`;

export const TransactionDetailsContainer = styled(Flex)`
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	padding: 10px 0 30px 3em;
	.lightBox {
		color: #1a2cce !important;
		// background:
		margin: 0;
		cursor: pointer;
	}
	.title {
		padding: 0.5em 0;
		border-bottom: 0.1px solid #b8b8b8;
		width: 100%;
		h1 {
			font-size: 18px;
			color: #1a1f36;
		}
		h4 {
			color: #000000;
		}
	}
	.details {
		/* max-width: 1300px; */
		padding: 1.2em 0;
		justify-content: space-between;
		position: relative;
		span {
			color: #1a1f36;
			font-size: 14px;
			width: 20%;
		}
		span:last-child {
			font-weight: 500;
			text-align: right;
			width: 80%;
			text-transform: capitalize;
		}
		.dot {
			border-radius: 50%;
			width: 7px;
			height: 7px;
			position: absolute;
			right: 65px;
			top: 25px;
			background-color: ${({ status }) =>
				(status === 'pending' && '#FFF2D9') ||
				(status === 'success' && '#C8FFC7') ||
				(status === 'failed' && 'red')};
		}
		p {
			padding: 0.5em 0;
			font-size: 14px;
			color: #898989;
		}
	}
	section {
		width: 100%;
		border-bottom: 0.1px solid #b8b8b8;
	}
	@media (max-width: 700px) {
		padding: 30px 0;
		.details {
			width: 100%;
		}
	}
`;
