import { Checkbox, Divider, Form, Input, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Upload } from '../../../../assets/index';
import { Container, Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import {
	getAllCurrencies,
	statusDeposit,
	statusWithdrawal
} from '../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../utils/toastr';

const FormLayout = ({
	action,
	id,
	editMethod,
	initialEditValues,
	depositAddMutation,
	withdrawalAddMutation,
	depositEditMutation,
	withdrawalEditMutation,
	setVisible,
	currencyType
}) => {
	const [fees, setFees] = useState(initialEditValues.fees);
	const [fields, setFields] = useState(initialEditValues.fields);
	const [requirements, setRequirements] = useState(
		initialEditValues.requirements
	);
	const [currencies, setCurrencies] = React.useState([]);
	const [supportedChains, setSupportedChains] = React.useState(
		action === 'edit'
			? initialEditValues?.supported_chains?.join(', ')?.toLocaleString()
			: ''
	);
	const [curType, setCurType] = React.useState(currencyType);
	const [minMaxToggle, setMinMaxToggle] = useState(
		action === 'edit'
			? initialEditValues?.fees[0]?.fee_type === 'PERCENTAGE'
				? 'PERCENTAGE'
				: ''
			: ''
	);
	let queryClient = useQueryClient();

	const { TextArea } = Input;
	const { Option } = Select;
	const checkToken = localStorage.getItem('access_token');
	useQuery(
		['getAllCurrencies', checkToken],
		async () => await getAllCurrencies(),
		{
			onSuccess: (res) => {
				setCurrencies(res?.data?.data);
			},
			onError: (error) => <p> {error.response.data.message}</p>,
			refetchOnMount: 'always',
			enabled: !!checkToken
		}
	);
	const switchDepositMutation = useMutation(
		async (data) => {
			await statusDeposit(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit status changed successfully ');
				setTimeout(setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const switchWithdrawalMutation = useMutation(
		async (data) => {
			await statusWithdrawal(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal status changed successfully ');
				setTimeout(setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const [imgUpload, setImgUpload] = useState(null);
	const ref = React.useRef(null);

	const handleChange = (e) => {
		if (e.target.files) {
			const file = e.target.files[0];
			setImgUpload(file);
		}
	};

	const handleOpenFileUpload = (e) => {
		e.preventDefault();
		ref.current.click();
	};

	const handleSwitched = (checked) => {
		const status = checked === false ? 'disable' : 'enable';
		if (editMethod === 'depositEdit') {
			switchDepositMutation.mutate({ status });
		} else if (editMethod === 'withdrawalEdit') {
			switchWithdrawalMutation.mutate({ status });
		}
	};

	const handleSubmit = (values) => {
		let formData = new FormData();
		formData.append(
			'icon',
			imgUpload !== null ? imgUpload : initialEditValues?.icon
		);

		formData.append('name', values.name);
		formData.append('slug', values.slug);
		formData.append('p_user', values.p_user);
		formData.append('provider', values.provider);
		formData.append('description', values.description);
		formData.append('modal_description', values?.modal_description);
		formData.append('modal_title', values?.modal_title);
		formData.append('model', values?.model);
		formData.append('type', values.type);
		for (var i = 0; i < values.channel.length; i++) {
			formData.append('channel[]', values.channel[i]);
		}

		let feesData = values.fees;
		if (curType !== 'CRYPTO') {
			feesData.forEach((element) => {
				element.chain = null;
			});
		} else {
			feesData.forEach((element) => {
				if (element.hasOwnProperty('chain')) {
					if (element.chain === '' || element.chain === undefined) {
						console.log(element);
						element.chain = null;
					} else {
						return element;
					}
				} else {
					element.chain = null;
				}
			});
		}

		formData.append('env', JSON.stringify(values.env));
		formData.append('fees', JSON.stringify(feesData));
		formData.append('fields', JSON.stringify(values.fields));
		formData.append('requirements', JSON.stringify(values.requirements));
		const chains =
			supportedChains === undefined || '' ? [] : supportedChains?.split(',');
		formData.append('supported_chains', JSON.stringify(chains));

		if (action === 'deposit') {
			depositAddMutation.mutate(formData);
		} else if (action === 'withdrawal') {
			withdrawalAddMutation.mutate(formData);
		} else if (action === 'edit') {
			if (editMethod === 'depositEdit') {
				depositEditMutation.mutate(formData);
			} else if (editMethod === 'withdrawalEdit') {
				withdrawalEditMutation.mutate(formData);
			}
		}
	};
	// console.log(supportedChains);

	const handleCurrencyRemove = (i) => {
		if (fees.length === 1) {
			setFees(initialEditValues.fees.pop());
		} else {
			const removeId = initialEditValues.fees.findIndex(
				(item) => item.currency === i
			);
			setFees(initialEditValues.fees.splice(removeId, 1));
		}
	};

	const handleFieldsRemove = (i) => {
		if (fields.length === 1) {
			setFields(initialEditValues.fields.pop());
		} else {
			const removeId = initialEditValues.fields.findIndex(
				(item) => item.label === i
			);
			setFields(initialEditValues.fields.splice(removeId, 1));
		}
	};

	const handleRequirementsRemove = (i) => {
		if (requirements.length === 1) {
			setFields(initialEditValues.requirements.pop());
		} else {
			const removeId = initialEditValues.requirements.findIndex(
				(item) => item.guide === i
			);
			setRequirements(initialEditValues.requirements.splice(removeId, 1));
		}
	};

	const handleCurTypeSelect = (e) => {
		currencies?.filter((c) => {
			if (c.code === e) {
				setCurType(c.type);
			}
		});
	};

	const displayAction =
		action === 'deposit' || action === 'withdrawal' ? 'Add new' : 'Edit';

	return (
		<React.Fragment key={id}>
			<h3>{`${displayAction} payment method`}</h3>
			<Flex
				width='392px'
				className='upload'
				alignItems='center'
				justifyContent='space-between'
			>
				<Flex
					justifyContent='flex-start'
					alignItem='center'
					className='icon-box'
				>
					{imgUpload ? (
						<div className='avatar'>
							<img alt='' src={URL.createObjectURL(imgUpload)} />
						</div>
					) : action === 'add' ? (
						<Upload />
					) : initialEditValues?.icon !== '' || null ? (
						<div className='avatar'>
							<img alt='' src={initialEditValues?.icon} />
						</div>
					) : (
						<Upload />
					)}

					<input
						text={'Change profile picture'}
						color={'#1A2CCE'}
						size='md'
						type='file'
						ref={ref}
						onChange={handleChange}
					/>

					<Flex flexDir='column' alignItems='flex-start'>
						<h4 onClick={handleOpenFileUpload} href='#'>
							Upload currency logo
						</h4>
						<p>Logo must be 12px by 12px (Max 1mb</p>
					</Flex>
				</Flex>
			</Flex>

			<Form
				preserve={false}
				layout='vertical'
				id='submitForm'
				onFinish={handleSubmit}
				initialValues={{
					layout: 'vertical',
					size: 'large',
					name: action === 'edit' ? initialEditValues?.name : null,
					slug: action === 'edit' ? initialEditValues?.slug : null,
					p_user: action === 'edit' ? initialEditValues?.p_user : null,
					description:
						action === 'edit' ? initialEditValues?.description : null,
					modal_description:
						action === 'edit' ? initialEditValues?.modal_description : null,
					model: action === 'edit' ? initialEditValues?.model : null,
					modal_title:
						action === 'edit' ? initialEditValues?.modal_title : null,
					provider: action === 'edit' ? initialEditValues?.provider : null,
					type: action === 'edit' ? initialEditValues?.type : null,
					channel: action === 'edit' ? initialEditValues?.channel : null,
					env: action === 'edit' ? initialEditValues?.env : null,
					fees: action === 'edit' ? fees : null,
					fields: action === 'edit' ? fields : null,
					requirements: action === 'edit' ? requirements : null,
					supported_chains:
						action === 'edit' ? supportedChains : supportedChains
				}}
				size='large'
			>
				{action === 'edit' && (
					<Flex
						bgColor={
							initialEditValues?.status === false ? '#fff4f3' : '#F4FFEF'
						}
						justifyContent='space-between'
						pad='20px'
						marginBottom='35px'
					>
						<p
							style={{
								color: initialEditValues?.status === false && '#f44336'
							}}
						>
							Payment Status
						</p>
						<Switch
							defaultChecked={initialEditValues?.status}
							onChange={handleSwitched}
						/>
					</Flex>
				)}
				<Container className='payment-details'>
					<Flex justifyContent='space-between'>
						<Form.Item
							rules={[{ required: true, message: 'Name is Required' }]}
							label='Name'
							name='name'
						>
							<Input placeholder='input name' type='text' />
						</Form.Item>
						<Form.Item label='Slug' name='slug'>
							<Input placeholder='input slug' type='text' />
						</Form.Item>
					</Flex>
					<Form.Item label='User' name='p_user' style={{ width: '100%' }}>
						<Input placeholder='input user' type='text' />
					</Form.Item>

					<Form.Item
						label='Description'
						id='desc'
						style={{ width: '100%' }}
						name='description'
					>
						<TextArea
							id='desc'
							rows={3}
							placeholder='Description'
							style={{ height: '100%' }}
						/>
					</Form.Item>
					<Flex justifyContent='space-between'>
						<Form.Item
							rules={[{ required: true, message: 'modal is Required' }]}
							label='Model'
							name='model'
						>
							<Input placeholder='Model' type='text' />
						</Form.Item>
						<Form.Item
							label='Modal title'
							name='modal_title'
							rules={[{ required: true, message: 'modal title is Required' }]}
						>
							<Input
								rules={[{ required: true, message: 'modal is Required' }]}
								placeholder='input Modal title'
								type='text'
							/>
						</Form.Item>
					</Flex>
					<Form.Item
						label='Modal description'
						id='desc'
						style={{ width: '100%' }}
						name='modal_description'
						rules={[
							{ required: true, message: 'modal description is Required' }
						]}
					>
						<TextArea
							id='desc'
							rows={3}
							placeholder='input modal description'
							style={{ height: '100%' }}
						/>
					</Form.Item>
					<Flex justifyContent='space-between'>
						<Form.Item
							rules={[{ required: true, message: 'Provider is Required' }]}
							label='Provider'
							name='provider'
						>
							<Input placeholder='input placeholder' type='text' />
						</Form.Item>
						<Form.Item
							name='type'
							label='Type'
							rules={[{ required: true, message: 'Type is Required' }]}
						>
							<Select placeholder='Select Type'>
								<Option value='MANUAL'>MANUAL</Option>
								<Option value='AUTO'>AUTO</Option>
							</Select>
						</Form.Item>
					</Flex>
				</Container>

				<Container className='currency-settings'>
					<Flex>
						<Flex flexDir='column' justifyContent='flex-start' pad='30px'>
							<Flex justifyContent='flex-start'>
								<h3>
									Channel <span style={{ color: 'red' }}>*</span>
								</h3>
							</Flex>
							<Flex justifyContent='flex-start'>
								<Form.Item name='channel'>
									<Checkbox.Group
										name='channel'
										style={{ width: '100%' }}
										options={['WEB', 'API']}
									/>
								</Form.Item>
							</Flex>
						</Flex>
						<Flex flexDir='column' justifyContent='flex-start' pad='30px'>
							<Flex justifyContent='flex-start'>
								<h3>
									Environment <span style={{ color: 'red' }}>*</span>
								</h3>
							</Flex>
							<Flex justifyContent='flex-start'>
								<Form.Item name='env'>
									<Checkbox.Group
										style={{ width: '100%' }}
										options={['live', 'test']}
									/>
								</Form.Item>
							</Flex>
						</Flex>
					</Flex>
				</Container>
				<Divider />
				<Container className='currency-settings'>
					<Flex flexDir='column' justifyContent='flex-start' pad='30px'>
						<Flex justifyContent='flex-start'>
							<h3>
								Currency Settings <span style={{ color: 'red' }}>*</span>
							</h3>
						</Flex>

						<Form.List name='fees'>
							{(fees, { add, remove }) => {
								return (
									<>
										{fees.map((currencyField, index) => (
											<Flex key={index} flexDir='column'>
												<Flex
													// key={index}
													key={currencyField.key}
													flexWrap='wrap'
													// alignItems='start'
													gap='20px'
													// flexWrap={curType === 'FIAT' ? 'no-wrap' : 'wrap'}
													justifyContent='flex-start'
													// justifyContent='space-between'
												>
													<Form.Item
														// key={currencyField.key}
														name={[index, 'currency']}
														label='Currency'
														rules={[
															{
																required: true,
																message: 'Currency is required'
															}
														]}
													>
														<Select
															placeholder='Select currency'
															showSearch
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}
															onChange={handleCurTypeSelect}
														>
															{currencies?.map((item) => (
																<Select.Option value={item.code}>
																	{item.code}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
													{curType === 'CRYPTO' ? (
														<Form.Item
															label='Chain'
															name={[index, 'chain']}
															// rules={[
															// 	{
															// 		required: true,
															// 		message: 'Chain is required'
															// 	}
															// ]}
														>
															<Input placeholder='Enter chain' type='text' />
														</Form.Item>
													) : null}
													<Form.Item
														label='Fee type'
														name={[index, 'fee_type']}
														rules={[
															{
																required: true,
																message: 'Fee type is required'
															}
														]}
													>
														<Select
															placeholder='Select fee type'
															onChange={(e) => setMinMaxToggle(e)}
														>
															<Select.Option value='FLAT'>FLAT</Select.Option>
															<Select.Option value='PERCENTAGE'>
																PERCENTAGE
															</Select.Option>
														</Select>
													</Form.Item>
													<Form.Item
														name={[index, 'fee']}
														label='Fee'
														rules={[
															{ required: true, message: 'Fee is required' }
														]}
													>
														<Input placeholder='Enter fee' />
													</Form.Item>
													{minMaxToggle === 'PERCENTAGE' ? (
														<>
															<Form.Item
																label='Minimum Fee'
																name={[index, 'min_fee']}
															>
																<Input placeholder='Enter min fee' />
															</Form.Item>
															<Form.Item
																label='Maximum Fee'
																name={[index, 'max_fee']}
															>
																<Input placeholder='Enter max fee' />
															</Form.Item>
														</>
													) : null}
												</Flex>

												<Flex
													justifyContent='flex-start'
													flexDir='column'
													alignItems='flex-start'
												>
													<Button
														Styles
														text='Remove'
														color='#DB2700'
														size='md'
														type='button'
														onClick={() => {
															remove(currencyField.name);
															handleCurrencyRemove(currencyField.name);
														}}
														style={{
															padding: '0',
															textDecoration: 'underline'
														}}
													/>
												</Flex>
												<Divider />
											</Flex>
										))}

										<Flex justifyContent='flex-start'>
											<Button
												Styles
												text='Add new currency setting'
												color={'#1A2CCE'}
												size='md'
												type='button'
												onClick={() => add()}
												style={{
													padding: '0',
													textDecoration: 'underline'
												}}
											/>
										</Flex>
									</>
								);
							}}
						</Form.List>
					</Flex>
				</Container>
				{curType === 'CRYPTO' && (
					<>
						<Divider />
						<Form.Item
							label='Supported Chains'
							name='supported_chains'
							style={{ width: '100%' }}
						>
							<Input
								placeholder='Enter supported chains'
								type='text'
								onChange={(e) => setSupportedChains(e?.target?.value)}
							/>
						</Form.Item>
					</>
				)}
				<Divider />
				<Container className='currency-settings'>
					<Flex flexDir='column' justifyContent='flex-start' pad='30px'>
						<Flex justifyContent='flex-start'>
							<h3>
								Fields Settings <span style={{ color: 'red' }}>*</span>
							</h3>
						</Flex>

						<Form.List name='fields'>
							{(fields, { add, remove }) => {
								return (
									<>
										{fields.map((fieldSetting, index) => (
											<React.Fragment key={index}>
												<Flex
													key={fieldSetting.key}
													justifyContent='space-between'
												>
													<Form.Item
														name={[index, 'label']}
														label='Label'
														rules={[
															{ required: true, message: 'Label is required' }
														]}
													>
														<Input placeholder='Enter label' />
													</Form.Item>
													<Form.Item
														name={[index, 'name']}
														label='Name'
														rules={[
															{ required: true, message: 'Name is required' }
														]}
													>
														<Input placeholder='Enter name' />
													</Form.Item>
													<Form.Item
														name={[index, 'value']}
														label='Value'
														rules={[
															{ required: true, message: 'Value is required' }
														]}
													>
														<Input placeholder='Enter value' />
													</Form.Item>
												</Flex>

												<Flex
													justifyContent='flex-start'
													flexDir='column'
													alignItems='flex-start'
												>
													<Button
														Styles
														text='Remove'
														color='#DB2700'
														size='md'
														type='button'
														onClick={() => {
															remove(fieldSetting.name);
															handleFieldsRemove(fieldSetting.label);
														}}
														style={{
															padding: '0',
															textDecoration: 'underline'
														}}
													/>
												</Flex>
												<Divider />
											</React.Fragment>
										))}

										<Flex justifyContent='flex-start'>
											<Button
												Styles
												text='Add new field setting'
												color={'#1A2CCE'}
												size='md'
												type='button'
												onClick={() => add()}
												style={{
													padding: '0',
													textDecoration: 'underline'
												}}
											/>
										</Flex>
									</>
								);
							}}
						</Form.List>
					</Flex>
				</Container>
				<Divider />
				<Container className='requirement-settings'>
					<Flex flexDir='column' justifyContent='flex-start' pad='30px'>
						<Flex justifyContent='flex-start'>
							<h3>
								Requirement settings <span style={{ color: 'red' }}>*</span>
							</h3>
						</Flex>

						<Form.List name='requirements'>
							{(requirements, { add, remove }) => {
								return (
									<>
										{requirements.map((requirementSetting, index) => (
											<React.Fragment key={index}>
												<Flex flexDir='column' key={requirementSetting.key}>
													<Flex justifyContent='space-between'>
														<Form.Item
															name={[index, 'label']}
															label='Label'
															rules={[
																{
																	required: true,
																	message: 'Label is required'
																}
															]}
														>
															<Input placeholder='Enter label' />
														</Form.Item>
														<Form.Item
															name={[index, 'name']}
															label='Name'
															rules={[
																{ required: true, message: 'Name is required' }
															]}
														>
															<Input placeholder='Enter name' />
														</Form.Item>
													</Flex>
													<Flex justifyContent='space-between'>
														<Form.Item
															name={[index, 'required']}
															label='Required'
															rules={[
																{
																	required: true,
																	message: 'Required is required'
																}
															]}
														>
															<Select placeholder='Select required'>
																<Select.Option value='required'>
																	Required
																</Select.Option>
																<Select.Option value='validate'>
																	Validate
																</Select.Option>
																<Select.Option value='optional'>
																	Optional
																</Select.Option>
															</Select>
														</Form.Item>
														<Form.Item
															name={[index, 'guide']}
															label='Guide'
															rules={[
																{
																	required: true,
																	message: 'Guide is required'
																}
															]}
														>
															<Input placeholder='Enter guide' />
														</Form.Item>
													</Flex>
													<Flex justifyContent='space-between'>
														<Form.Item
															name={[index, 'value']}
															label='Value'
															rules={[
																{
																	required: true,
																	message: 'Value is required'
																}
															]}
														>
															<Input placeholder='Enter value' />
														</Form.Item>
														<Form.Item
															name={[index, 'render']}
															label='Render'
															rules={[
																{
																	required: true,
																	message: 'Render is required'
																}
															]}
														>
															<Select placeholder='Select render'>
																<Select.Option value='text'>Text</Select.Option>
																<Select.Option value='input'>
																	Input
																</Select.Option>
																<Select.Option value='select'>
																	Select
																</Select.Option>
																<Select.Option value='image'>
																	Image
																</Select.Option>
															</Select>
														</Form.Item>
													</Flex>
												</Flex>
												<Flex
													justifyContent='flex-start'
													flexDir='column'
													alignItems='flex-start'
												>
													<Button
														Styles
														text='Remove'
														color='#DB2700'
														size='md'
														type='button'
														onClick={() => {
															remove(requirementSetting.name);
															handleRequirementsRemove(
																requirementSetting.guide
															);
														}}
														style={{
															padding: '0',
															textDecoration: 'underline'
														}}
													/>
												</Flex>
												<Divider />
											</React.Fragment>
										))}

										<Flex justifyContent='flex-start'>
											<Button
												Styles
												text='Add new requirement'
												color={'#1A2CCE'}
												size='md'
												type='button'
												onClick={() => add()}
												style={{
													padding: '0',
													textDecoration: 'underline'
												}}
											/>
										</Flex>
									</>
								);
							}}
						</Form.List>
					</Flex>
				</Container>
			</Form>
		</React.Fragment>
	);
};

export { FormLayout };
