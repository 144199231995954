import { Alert, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalFrame from '../modal/ModalFrame';
// import ProcessorStatusTable from './ProcessorStatusTable';
import OnLoadPopUp from './OnLoadPopUp';
import { Styles } from './styles';
import CustomTable from './table';

const processorData = [
	{ name: 'Kora', balance: 'USD 200,000.04', status: true },
	{
		name: 'Flutterwave',
		balance: 'USD 200,000,000.04',
		status: false
	},
	{
		name: 'Flutterwave',
		balance: 'USD 200,000,000.04',
		status: false
	}
];
export const ProcessorLowBalance = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [visible, setVisible] = useState(false);
	const [visibleOnLoad, setVisibleOnload] = useState(false);
	// const show = () => {
	// 	setVisible(true);
	// };
	const handleOk = () => {
		setVisible(false);
	};
	const handleCancel = () => {
		setVisible(false);
		setVisibleOnload(false);
	};
	const processorName = processorData?.filter((item) => item.status === true);
	// console.log(processorName);

	const handleProcessorDetails = () => {
		setVisibleOnload(false);
		navigate('/dashboard/transactions/provider-status');
		// setVisible(true);
	};
	useEffect(() => {
		if (location?.pathname?.includes('provider-status')) {
			setVisibleOnload(false);
		} else {
			setVisibleOnload(true);
		}
	}, [location?.pathname]);

	const margin = processorName?.length === 0 ? 0 : '15px 25px 25px 25px';
	return (
		<Styles margin={margin}>
			{processorName?.length !== 0 ? (
				<Alert
					message={`${processorName[0]?.name.toUpperCase()} provider balance is low.`}
					type='warning'
					icon={<AiFillWarning />}
					showIcon
					action={
						<Space>
							<Link
								style={{
									fontWeight: 500,
									fontSize: '14px',
									lineHeight: '18px',
									textAlign: 'right',
									textDecoration: 'underline',

									color: '#1A2CCE'
								}}
								to='/dashboard/transactions/provider-status'
							>
								View status
							</Link>
						</Space>
					}
					closable
				/>
			) : null}
			<OnLoadPopUp
				visible={visibleOnLoad}
				handleCancel={handleCancel}
				handleProcessorDetails={handleProcessorDetails}
			/>
			<ProcessorStatusTable
				visible={visible}
				handleCancel={handleCancel}
				handleOk={handleOk}
				// processorData={processorData}
			/>
		</Styles>
	);
};

// export default ProcessorLowBalance;

export const ProcessorStatusTable = ({
	// processorData,
	visible,
	handleCancel,
	handleOk
}) => {
	return (
		<ModalFrame
			visible={visible}
			handleOk={handleOk}
			handleCancel={handleCancel}
			footer={null}
			width={700}
		>
			<h3
				style={{
					textAlign: 'left',
					fontWeight: 700,
					fontSize: '18px',
					lineHeight: '23px',
					color: '#212121'
				}}
			>
				Provider Status
			</h3>
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={['Name', 'Balance', '']}
				tableBody={processorData}
				//   rowHovColor="#d2ccc626"
				//   theadColor="#000A62"
				//   tbodyColor="#141414"
				//   pageSize={10}
				//   paginator
				//   handleApprove={showModal}
				//   handleReject={showModal}
				//   setSelectedIndex={setSelectedIndex}
				//   selectedIndex={selectedIndex}
				//   actionTaken={actionTaken}
				//   setActionTaken={setActionTaken}
				//   setVisible={setVisible}
				//   meta={approvalsCrypto?.data?.data?.data?.meta}
				//   handlePageChange={handlePageChange}
			/>
		</ModalFrame>
	);
};

// <Button onClick={show}>View status</Button>
