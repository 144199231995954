import styled from 'styled-components';

export const Styles = styled.div`
	// margin: 25px 25px 0 25px;
	margin: ${({ margin }) => margin};
	// margin: ${({ margin }) => margin || '25px 25px 0 25px'};

	.ant-alert-warning {
		background: #fffce6;
		border-radius: 5px;
		border: none;
		padding: 14px 24px;

		.ant-alert-icon {
			color: #ce1a1a;
			font-size: 25px;

			animation: blink 2s steps(5, start) infinite;
			-webkit-animation: blink 1s steps(5, start) infinite;
		}
		@keyframes blink {
			to {
				visibility: hidden;
			}
		}
		@-webkit-keyframes blink {
			to {
				visibility: hidden;
			}
		}

		.ant-alert-content {
			flex: 0;

			min-width: fit-content;
			.ant-alert-message {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.04em;

				color: #000000;
			}
		}
		.ant-alert-close-icon {
			margin-left: auto;
		}
		.ant-btn {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #1a2cce;
			background: transparent;
			border: none;
			box-shadow: none;
			span {
				text-decoration: underline;
			}
		}
	}
`;
