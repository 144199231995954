import React from 'react';
import Chart from './chart';
import { Styles, Card } from './styles';
import { Container, Flex } from '../../../../components/box';
import { TriangleIcon } from '../../../../assets';
import { Select } from 'antd';
import { Button } from 'antd';

const Index = () => {
	const buttonNames = [
		{ btnTitle: 'MRR' },
		{ btnTitle: 'ARR' },
		{ btnTitle: 'MRR Growth rate' },
		{ btnTitle: 'Customers' }
	];

	const { Option } = Select;
	function handleChange(value) {
	}

	return (
		<Styles>
			<Container bgColor='#fff' pad='30px'>
				<h3>Metrics</h3>
				<Flex
					className='btn_container'
					justifyContent='flex-start'
					alignItem='flex-start'
					pad='10px 0 20px'
					gap='30px'
				>
					{buttonNames.map(({ btnTitle }) => (
						<Button key={btnTitle}>{btnTitle}</Button>
					))}
				</Flex>
				<Flex justifyContent='space-between'>
					<Card>
						<Flex
							className='container'
							alignItem={'center'}
							justifyContent={'flex-start'}
						>
							<span>MONTHLY RECURRING REVENUE</span>
						</Flex>
						<Flex
							className='viewall'
							alignItem={'flex-start'}
							flexDir={'column'}
						>
							<span>$1,384,5960,696.42</span>
							<span>
								<TriangleIcon /> $45,586.94 from yesterday
							</span>
						</Flex>
					</Card>
					<Select
						defaultValue='This Week '
						style={{
							width: 120,
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
							color: '#33334F'
						}}
						onChange={handleChange}
					>
						<Option value='This Month'>This Month</Option>
					</Select>
				</Flex>
				<div className='chart_container'>
					<Chart />
				</div>
			</Container>
		</Styles>
	);
};

export default Index;
