import {
	DownOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
	UpOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import { AccordionButton, AccordionContent, AccordionStyles } from './styles';

const CustomAccordion = ({ children, pad, padC, title, type }) => {
	const [isActive, setIsActive] = useState(false);
	return (
		<AccordionStyles>
			<AccordionButton
				onClick={() => {
					setIsActive(!isActive);
				}}
				pad={pad}
			>
				<p>{title}</p>
				<p>
					{isActive ? (
						type === 'cross' ? (
							<UpOutlined />
						) : (
							<MinusCircleOutlined />
						)
					) : type === 'cross' ? (
						<DownOutlined />
					) : (
						<PlusCircleOutlined />
					)}
				</p>
			</AccordionButton>
			{isActive && <AccordionContent pad={padC}>{children}</AccordionContent>}
		</AccordionStyles>
	);
};

export default CustomAccordion;
