import PropTypes from "prop-types";
import React, { useState } from "react";
import { generateID } from "../../../../../../utils/generateID";
import Pagination from "../../../../../../components/paginator";
import { CustomTableMain, Td, Paginator } from "./styles";
import { OverFlowScrollBar } from "../../../../../../components/overflowScroll/styles";
import { EmptyState } from "../../../../../../components/emptyState";
import { Link } from "react-router-dom";
import { formattedDate } from "../../../../../../utils/helpers";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  pageSize,
  firstLast,
  prevNext,
  handleTerminate,
}) => {
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (items) => {
    setPageOfItems(items);
  };
  const returnTableRow = (data, i) => {
    return (
      <React.Fragment key={i}>
        <tr key={generateID(10)}>
          <Td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  background: data?.status === true ? "#00A478" : "#C4C4C4",
                  borderRadius: "100%",
                  marginRight: "16px",
                }}
              ></div>
              {`${data?.os_name} ${data?.device_type}/${data?.client_type}/${data?.client_name}/${data?.client_version}`}
            </div>
          </Td>
          <Td>{formattedDate(data?.created_at)}</Td>
          <Td>{formattedDate(data?.updated_at)}</Td>
          <Td>
            <Link
              to={"#!"}
              onClick={() => handleTerminate(data?.id)}
              style={{
                textDecoration: "underline",
                color: "#D80027",
                fontWeight: 500,
                fontSize: " 14px",
                lineHeight: "20px",
              }}
            >
              Terminate
            </Link>
          </Td>
        </tr>
      </React.Fragment>
    );
  };
  return (
    <>
      {tableBody.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead?.map((head, i) => (
                    <th key={i}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {paginator
                  ? pageOfItems?.map((data, idx) => returnTableRow(data, idx))
                  : tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination
              items={tableBody}
              pageSize={pageSize || 5}
              prevNext={prevNext || null}
              onChangePage={onChangePage}
              firstLast={firstLast || null}
            />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
  firstLast: PropTypes.any,
  paginator: PropTypes.any,
  prevNext: PropTypes.any,
};

export default CustomTable;
