import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/loader.json';
import styles from './Loader.module.css';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const Loader = ({ full }) => {
	return (
		<div className={`${styles.loader} ${full ? styles.full : ''}`}>
			<Lottie options={defaultOptions} height={200} width={400} />
			<p>Loading, Please wait...</p>
		</div>
	);
};

export { Loader };
