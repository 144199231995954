import React from "react";
import Layout from "../../../../components/layouts";
import { Spacer } from "../../../../components/spacer/styles";
import { Styles } from "./styles";
import CustomTable from "../table";
import { OverdraftTableHead } from "../../../../utils/constants";
import Overview from "../overview";
import SearchBox from "../../../../components/searchbox";
import { Flex } from "../../../../components/box";
import Button from "../../../../components/button";
import ModalFrame from "../../../../components/modal/ModalFrame";
import {
  changeStatus,
  getActiveSummary,
  getAmountSettledSummary,
  getAmountUsedSummary,
  getLiquidityRequest,
  getRequestSummary,
} from "../../../../services/Liquidity.service";
import { Loader } from "../../../../components/loader";
import { ToastMessage, type } from "../../../../utils/toastr";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Index = () => {
  const queryClient = useQueryClient();

  const [visible, setVisible] = React.useState(false);
  const [Declinevisible, setDeclinevisible] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [filter, setFilter] = React.useState({ page: 1 });

  const liquidityRequest = useQuery(
    "getLiquidityRequest",
    async () => await getLiquidityRequest(filter)
  );

  const requestSummary = useQuery(
    "getRequestSummary",
    async () => await getRequestSummary()
  );
  const activeSummary = useQuery(
    "getActiveSummary",
    async () => await getActiveSummary()
  );

  const amountUsedSummary = useQuery(
    "getAmountUsedSummary",
    async () => await getAmountUsedSummary()
  );

  const amountSettledSummary = useQuery(
    "getAmountSettledSummary",
    async () => await getAmountSettledSummary()
  );

  const setStopedMutation = useMutation(
    async (data) => {
      await changeStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLiquidityRequest");
        queryClient.invalidateQueries("getActiveSummary");
        queryClient.invalidateQueries("getRequestSummary");
        setTimeout(() => setDeclinevisible(false), 1000);
        ToastMessage(type.SUCCESS, "liquidity has been declined");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const setActivateMutation = useMutation(
    async (data) => {
      await changeStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLiquidityRequest");
        queryClient.invalidateQueries("getActiveSummary");
        queryClient.invalidateQueries("getRequestSummary");
        setTimeout(() => setVisible(false), 1000);
        ToastMessage(type.SUCCESS, "Liquidity apporved successfully");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleDecline = (e, status) => {
    setDeclinevisible(true);
    setId(e);
    setStatus(status);
  };

  const handleDeclineStatus = () => {
    if (status === "pending") {
      setStopedMutation.mutate({ status: "stoped" });
    }
  };

  const handleApprove = (e, status) => {
    setVisible(true);
    setId(e);
    setStatus(status);
  };

  const handleChangeStatus = () => {
    if (status === "pending") {
      setActivateMutation.mutate({ status: "active" });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setDeclinevisible(false);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => liquidityRequest.refetch());
  };

  if (liquidityRequest.isLoading) {
    return <Loader full />;
  }

  if (
    amountSettledSummary.status === "error" ||
    amountUsedSummary.status === "error" ||
    liquidityRequest.status === "error" ||
    requestSummary.status === "error"
  ) {
    return (
      <span>
        Error:
        {requestSummary.error.message ||
          amountSettledSummary.error.message ||
          liquidityRequest.error ||
          amountUsedSummary.error.message}
      </span>
    );
  }

  return (
    <>
      <Styles>
        <Layout bgColor="#fff" title={"Liquidity"}>
          <Spacer height="30px" />
          <Flex justifyContent="space-between" alignItems="baseline">
            <h3>Requested Liquidity</h3>
          </Flex>
          <Spacer height="30px" />
          <Overview
            amountSettledSummary={amountSettledSummary?.data?.data?.data}
            amountUsedSummary={amountUsedSummary?.data?.data?.data}
            requestSummary={requestSummary?.data?.data?.data}
            activeSummary={activeSummary?.data?.data?.data}
            loading={
              requestSummary.isLoading ||
              amountUsedSummary.isLoading ||
              amountSettledSummary.isLoading
            }
          />
          <Spacer height="20px" />
          <div style={{ paddingLeft: "25px" }}>
            <SearchBox placeholder="Search asset pair" width="323px" />
          </div>
          <Spacer height="20px" />

          <CustomTable
            source="request"
            theadBkColor="#F1F4F8"
            bottomGap="10px"
            tableHead={OverdraftTableHead}
            tableBody={liquidityRequest?.data?.data?.data?.data}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            handleDecline={handleDecline}
            handleApprove={handleApprove}
            meta={liquidityRequest?.data?.data?.data?.meta}
            handlePageChange={handlePageChange}
            paginator
          />
        </Layout>
      </Styles>
      <ModalFrame
        visible={Declinevisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            loading={setStopedMutation.isLoading}
            onClick={handleDeclineStatus}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <h3>Decline liquidity</h3>
        <p>
          By revoking access, this user will no longer have access to approving
          withdrawals.
        </p>
      </ModalFrame>
      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            onClick={handleChangeStatus}
            loading={setActivateMutation.isLoading}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <h3>approve liquidity</h3>
        <p>
          By confirming approval, this user will have access to this liquidity
          fund.
        </p>
      </ModalFrame>
    </>
  );
};

export default Index;
