import { Checkbox, Form, Input, Select, Upload } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '../../../../../../components/box';
import { Styles } from './styles';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '../../../../../../components/button';
import ModalFrame from '../../../../../../components/modal/ModalFrame';
import { useAuthState } from '../../../../../../context';
import {
	getBankName,
	getBanksGHS,
	getBanksKES,
	getMobileGHS
} from '../../../../../../services/Others.service';
import { createTransactionsForMerchant } from '../../../../../../services/Transactions.service';
import { ToastMessage, type } from '../../../../../../utils/toastr';

const AddNewTransaction = ({ PaymentMethods, currencies, depositMethods }) => {
	const [form] = Form.useForm();
	const { countries } = useAuthState();

	const [visible, setVisible] = useState(false);
	const [leftPair, setLeftPair] = useState('');
	const [currency, setCurrency] = useState('');
	const [method, setMethod] = useState(null);
	const [branches, setBranches] = useState(null);
	const [transactionType, setTransactionType] = useState('DEPOSIT');

	const [applyFee, setApplyFee] = useState(null);
	const [executeWithdrawal, setExecuteWithdrawal] = useState(null);
	const [updateBalance, setUpdateBalance] = useState(null);

	const bankNames = useQuery('getbankName', async () => await getBankName());
	const bankNamesGHS = useQuery(
		'getbankNameGHS',
		async () => await getBanksGHS()
	);
	const bankNamesKES = useQuery(
		'getbankNameKES',
		async () => await getBanksKES()
	);
	const mobileMoneyGHS = useQuery(
		'getMobileMoneyGHS',
		async () => await getMobileGHS()
	);

	const queryClient = useQueryClient();
	let params = useParams();

	const createMutation = useMutation(
		async (data) => {
			await createTransactionsForMerchant(params.merchant, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getTransactionsByMerchantId');
				ToastMessage(type.SUCCESS, 'transaction sucessfully created');
				setTimeout(() => setVisible(false), 500);
				form.resetFields('');
				setCurrency('');
				showToast();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const show = () => setVisible(true);

	const showToast = () => {
		if (applyFee === true) {
			ToastMessage(type.SUCCESS, 'fee sucessfully applied');
		}
		if (executeWithdrawal === true) {
			ToastMessage(type.SUCCESS, 'withdrawal sucessfully executed');
		}
		if (updateBalance === true) {
			ToastMessage(type.SUCCESS, 'balance sucessfully updated');
		}
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}

		return e?.fileList;
	};

	const handleInputValueChange = (e) => {
		const { value } = e;
		setCurrency(value);
		const newPair = value.replace(/\//g, ' ').split(' ');
		setLeftPair(newPair[0]);
	};

	const handlePaymentMethodValueChange = (id) => {
		PaymentMethods?.find((item) => {
			if (item.id === id) {
				setMethod(item);
			}
		});
	};

	const handleMethodValueChange = (id) => {
		depositMethods?.find((item) => {
			if (item.id === id) {
				setMethod(item);
			}
		});
	};

	const handleRenderChange = (code) => {
		bankNamesGHS?.data?.data?.data?.find((item) => {
			if (item?.code === code) {
				setBranches(item?.branches);
				console.log(item, 'item');
			}
		});
	};

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
		setTransactionType('DEPOSIT');
		setCurrency('');
		setBranches(null);
		setMethod(null);
		setLeftPair('');
	};

	const handleSubmit = (values) => {
		let formData = new FormData();
		setApplyFee(values?.apply_fees);
		setExecuteWithdrawal(values?.execute);
		setUpdateBalance(values?.update_balance);
		const apply_fee =
			values?.apply_fees !== undefined ? values.apply_fees : false;
		const update_balance =
			values?.update_balance !== undefined ? values.update_balance : false;
		const execute = values?.execute !== undefined ? values.execute : false;

		if (transactionType === 'SWAP') {
			formData.append('type', values.type);
			formData.append('to_amount', values.to_amount);
			formData.append('from_amount', values.amount);
			formData.append('from_currency', values.currency);
			formData.append('to_currency', values.to_currency);
			formData.append('rate', values.rate);
			formData.append('apply_fees', apply_fee);
			formData.append('update_balance', update_balance);
			formData.append('execute', execute);
			formData.append(
				'twoFactorAuthenticationCode',
				values.twoFactorAuthenticationCode
			);
		}

		if (transactionType === 'DEPOSIT') {
			const file = values?.payment_proof
				? values?.payment_proof[0].originFileObj
				: null;
			const apply_fee =
				values?.apply_fees !== undefined ? values.apply_fees : false;
			const update_balance =
				values?.update_balance !== undefined ? values.update_balance : false;
			const execute = values?.execute !== undefined ? values.execute : false;

			formData.append('type', values.type);
			formData.append('payment_proof', file);
			formData.append('to_amount', values.amount);
			formData.append('to_currency', values.currency);
			formData.append('reference', values.reference);
			formData.append('payment_slug', method?.slug);
			formData.append('apply_fees', apply_fee);
			formData.append('update_balance', update_balance);
			formData.append('execute', execute);
			formData.append(
				'twoFactorAuthenticationCode',
				values.twoFactorAuthenticationCode
			);
		}

		if (transactionType === 'WITHDRAWAL') {
			const beneficiary = { ...values };
			const apply_fee =
				values?.apply_fees !== undefined ? values.apply_fees : false;
			const update_balance =
				values?.update_balance !== undefined ? values.update_balance : false;
			const execute = values?.execute !== undefined ? values.execute : false;

			delete beneficiary.type;
			delete beneficiary.amount;
			delete beneficiary.currency;
			delete beneficiary.method_id;
			delete beneficiary.apply_fees;
			delete beneficiary.twoFactorAuthenticationCode;

			formData.append('type', values.type);
			formData.append('from_amount', values.amount);
			formData.append('from_currency', values.currency);
			formData.append('payment_slug', method.slug);
			formData.append('apply_fees', apply_fee);
			formData.append('update_balance', update_balance);
			formData.append('execute', execute);
			formData.append(
				'twoFactorAuthenticationCode',
				values.twoFactorAuthenticationCode
			);
			formData.append('beneficiary', JSON.stringify(beneficiary));
		}
		// console.log(values);
		createMutation.mutate(formData);
	};
	// console.log(method);

	return (
		<>
			<Button
				text='Add new transaction'
				color='#1A2CCE'
				size='sm'
				borderRadius='5px'
				onClick={show}
				style={{ textDecoration: 'underline' }}
			/>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text='Save'
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						key='submit'
						form='createMerchant'
						loading={createMutation.isLoading}
						style={{ padding: '0.85rem 50px' }}
					/>
				]}
				width={650}
			>
				<Styles>
					<h3 style={{ textAlign: 'left' }}>Add new transaction</h3>
					<Form
						layout='vertical'
						onFinish={handleSubmit}
						id='createMerchant'
						initialValues={{
							layout: 'vertical',
							size: 'large'
						}}
					>
						<Flex flexDir='row' justifyContent='space-between'>
							<Form.Item
								label='Currency'
								name='currency'
								rules={[
									{ required: true, message: 'Currency Name is required' }
								]}
							>
								<Select
									placeholder='Select currency'
									onChange={(e, name) => {
										handleInputValueChange(name);
									}}
								>
									{currencies?.map((item) => (
										<Select.Option key={item.id} value={item.code}>
											{item.code}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Transaction type'
								name='type'
								initialValue={transactionType}
								rules={[
									{
										required: true,
										message: 'Transaction type Name is required'
									}
								]}
							>
								<Select
									placeholder='Select Transaction Type'
									onChange={(value) => setTransactionType(value)}
								>
									<Select.Option value='DEPOSIT'>Deposit</Select.Option>
									<Select.Option value='SWAP'>Swap</Select.Option>
									<Select.Option value='WITHDRAWAL'>Withdrawal</Select.Option>
								</Select>
							</Form.Item>
						</Flex>
						<>
							<Flex className='amountTo'>
								<Form.Item
									label={`Amount${transactionType === 'SWAP' ? ' from' : ''}`}
									name='amount'
									rules={[
										{
											required: true,
											message: ` Amount${
												transactionType === 'SWAP' ? 'from' : ''
											}  is required`
										}
									]}
								>
									<Input addonBefore={leftPair} type='number' />
								</Form.Item>
							</Flex>
						</>
						{transactionType === 'SWAP' && (
							<Flex className='amountTo'>
								<Form.Item
									label='Amount to'
									name={'to_amount'}
									rules={[
										{
											required: true,
											message: 'Amount to is required'
										}
									]}
								>
									<Input
										type='number'
										addonBefore={
											<Form.Item
												noStyle
												name='to_currency'
												initialValue={'USDT'}
											>
												<Select className='select-before'>
													{currencies?.map((item) => (
														<Select.Option key={item.id} value={item.code}>
															{item.code}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										}
									/>
								</Form.Item>
							</Flex>
						)}
						{transactionType === 'SWAP' && (
							<Flex className='amountTo'>
								<Form.Item
									label={`Rate`}
									name='rate'
									rules={[
										{
											required: true,
											message: `Rate  is required`
										}
									]}
								>
									<Input type='number' placeholder='add rate' />
								</Form.Item>
							</Flex>
						)}
						{transactionType === 'DEPOSIT' && (
							<>
								<Flex className='transaction'>
									<Form.Item label='Payment Method' name='method_id'>
										<Select
											placeholder='Select Payment Method'
											onChange={(value) => {
												handleMethodValueChange(value);
											}}
										>
											{depositMethods?.map(
												(item) =>
													item?.currencies[0] === currency && (
														<Select.Option
															children={item?.method}
															value={item?.id}
														>
															{item?.name}:{item?.provider}
														</Select.Option>
													)
											)}
										</Select>
									</Form.Item>
								</Flex>
								<Flex className='transaction'>
									<Form.Item
										label='Transaction / Reference ID'
										name='reference'
									>
										<Input type='text' />
									</Form.Item>
								</Flex>
								<Flex
									className='upload'
									flexDir='column'
									justifyContent='flex-start'
									alignItems='flex-start'
								>
									<Form.Item
										label='Payment upload'
										name='payment_proof'
										valuePropName='fileList'
										getValueFromEvent={normFile}
									>
										<Upload name='payment_proof' maxCount={1} action=''>
											<Button text='Choose document' type='button' />
										</Upload>
									</Form.Item>
								</Flex>
							</>
						)}

						{transactionType === 'WITHDRAWAL' && (
							<>
								<Flex className='withdraw'>
									<Form.Item label='Payment Method' name='method_id'>
										<Select
											placeholder='Select Payment Method'
											onChange={(value) => {
												handlePaymentMethodValueChange(value);
											}}
										>
											{PaymentMethods?.map(
												(item) =>
													item?.status === true &&
													item?.currencies?.includes(currency) && (
														// item?.currencies[0]=== currency && (
														<Select.Option
															children={item?.method}
															value={item?.id}
														>
															{item?.name}:{item?.provider}
														</Select.Option>
													)
											)}
										</Select>
									</Form.Item>
								</Flex>
								{method?.requirements?.map(
									(item) =>
										(item.render === 'input' && (
											<Flex className='withdraw'>
												<Form.Item
													label={item.label}
													name={item.name}
													rules={[
														{
															required:
																item.required === 'required' ? true : false,
															message: `${item.label} is required`
														}
													]}
												>
													<Input type='text' />
												</Form.Item>
											</Flex>
										)) ||
										(item.render === 'select' && (
											<Flex className='withdraw'>
												<Form.Item
													label={item.label}
													name={item.name}
													rules={[
														{
															required:
																item.required === 'required' ? true : false,
															message: `${item.label} is required`
														}
													]}
												>
													<Select
														key={Math.random() * 11}
														onChange={(value) => {
															handleRenderChange(value);
														}}
														placeholder={`Select ${item.label}`}
														showSearch
														filterOption={(input, option) =>
															(option?.children ?? '')
																.toLowerCase()
																.includes(input.toLowerCase())
														}
													>
														{item?.name?.includes('bank') &&
															!method.currencies.includes('GHS') &&
															bankNames?.data?.data?.data?.map((item) => (
																<Select.Option
																	value={item.code}
																	key={Math.random() * 5}
																>
																	{item.name}
																</Select.Option>
															))}
														{method?.currencies.includes('GHS') &&
															item?.name?.includes('bank') &&
															bankNamesGHS?.data?.data?.data.map((item) => (
																<Select.Option
																	key={Math.random() * 8}
																	value={item?.code}
																>
																	{item?.name}
																</Select.Option>
															))}

														{method?.name?.includes('Mobile') &&
															currency === 'GHS' &&
															mobileMoneyGHS?.data?.data?.data.map((item) => (
																<Select.Option
																	key={Math.random() * 6}
																	value={item?.code}
																>
																	{item?.name}
																</Select.Option>
															))}

														{currency === 'KES' &&
															method?.name.includes('Mobile') &&
															bankNamesKES?.data?.data?.data.map((item) => (
																<Select.Option
																	key={Math.random() * 9}
																	value={item?.code}
																>
																	{item?.name}
																</Select.Option>
															))}

														{item?.name?.includes('country') &&
															countries?.map((item) => (
																<Select.Option
																	value={item.name}
																	key={Math.random() * 19}
																>
																	{item.name}
																</Select.Option>
															))}
														{method?.currencies.includes('USDC') &&
															item?.name.includes('network') &&
															USDCData?.map((item) => (
																<Select.Option
																	value={item.value}
																	key={Math.random() * 29}
																>
																	{item.name}
																</Select.Option>
															))}

														{method?.currencies.includes('GHS') &&
															item?.name?.includes('branch') &&
															branches?.map((item) => (
																<Select.Option
																	key={Math.random() * 20}
																	value={item?.swift_code}
																>
																	{item?.branch_name}
																</Select.Option>
															))}
														{method?.currencies.includes('GHS') &&
															// method?.model?.includes("mobile-money") &&
															item?.name.includes('account_type') &&
															accountType?.map((item) => (
																<Select.Option
																	key={Math.random() * 13}
																	value={item?.value}
																>
																	{item?.name}
																</Select.Option>
															))}
													</Select>
												</Form.Item>
											</Flex>
										)) ||
										(item.render.includes('image') && (
											<svg
												width='100%'
												height='148'
												viewBox='0 0 149 148'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
												xmlnsXlink='http://www.w3.org/1999/xlink'
											>
												<rect width='120' height='148' rx='4' fill='white' />
												<rect
													x='16'
													y='16'
													width='113'
													height='114'
													fill='url(#pattern0)'
												/>
												<defs>
													<pattern
														id='pattern0'
														patternContentUnits='objectBoundingBox'
														width='1'
														height='1'
													>
														<use
															xlinkHref='#image0_1369_4394'
															transform='translate(-0.0707965 -0.0789474) scale(0.0490413 0.0486111)'
														/>
													</pattern>
													<image
														id='image0_1369_4394'
														width='24'
														height='24'
														xlinkHref={item?.value}
													/>
												</defs>
											</svg>
										))
								)}
							</>
						)}

						<Flex className='withdraw' marginTop='10px'>
							<Form.Item
								label='Enter 2fa code'
								name='twoFactorAuthenticationCode'
							>
								<Input type='text' />
							</Form.Item>
						</Flex>
						<Form.Item name='apply_fees' valuePropName='checked'>
							<Flex justifyContent='space-between' marginTop='10px'>
								<Checkbox>Apply Fees</Checkbox>
							</Flex>
						</Form.Item>
						<Form.Item name='update_balance' valuePropName='checked'>
							<Flex justifyContent='space-between' marginTop='10px'>
								<Checkbox>Update Balance </Checkbox>
							</Flex>
						</Form.Item>
						{transactionType === 'WITHDRAWAL' && (
							<Form.Item name='execute' valuePropName='checked'>
								<Flex justifyContent='space-between' marginTop='10px'>
									<Checkbox>Execute Transaction</Checkbox>
								</Flex>
							</Form.Item>
						)}
					</Form>
				</Styles>
			</ModalFrame>
		</>
	);
};

const USDCData = [
	{
		value: 'HBAR',
		name: ' Hedera (HBAR)'
	},
	{
		value: 'TRX',
		name: 'Tron (TRX)'
	},
	{
		value: 'ETH',
		name: ' Ethereum (ETH)'
	},
	{
		value: 'SOL',
		name: 'Solana (SOL)'
	}
];

const accountType = [
	{
		value: 'individual',
		name: 'Individual'
	},
	{
		value: 'corporate',
		name: 'Corporate'
	}
];
export default AddNewTransaction;
