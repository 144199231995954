import React from "react";
import { Styles } from "./styles";
import empty from "../../assets/images/empty.svg";

const EmptyState = () => {
  
  const handleClickRefresh = () => {
    window.location.reload(false);
  };

  return (
    <Styles>
      <img src={empty} alt="" />
      <h6 variant="h6">No data here yet</h6>
      <p>it looks like you dont have any data yet</p>
      <span onClick={handleClickRefresh}>Refresh</span>
    </Styles>
  );
};

export { EmptyState };
