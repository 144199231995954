import { Button } from "antd";
import { DefaultCurrencyImage } from "../../../../../../assets";
import { Flex, Frame } from "../../../../../../components/box";
import { truncateStr } from "../../../../../../utils/helpers";
import { StylesAccount } from "../styles";

export const AccountDetails = ({ accountDetails }) => {
  return (
    <>
      <StylesAccount>
        <Flex flexDir="row" justifyContent="space-between">
          <div>
            <h3>Account Name</h3>
            <p>{accountDetails?.account_name}</p>
          </div>
          <div style={{ width: "40%" }}>
            <h3>Bank Name</h3>
            <p>{accountDetails?.bank_name}</p>
          </div>
        </Flex>
        <Flex flexDir="row" justifyContent="space-between" marginTop="40px">
          <div>
            <h3>Account Number</h3>
            <p>{accountDetails?.account_number}</p>
          </div>
          <div style={{ width: "40%" }}>
            <h3>Payment Method</h3>

            <p>{accountDetails?.depositMethod?.provider}</p>
          </div>
        </Flex>
        <Flex flexDir="row" justifyContent="space-between" marginTop="40px">
          <div>
            <h3>Reference Number</h3>
            <p>{truncateStr(accountDetails?.reference_number, 20)}</p>
          </div>
          <div style={{ width: "40%" }}></div>
        </Flex>
      </StylesAccount>
      <Button
        Styles
        text={"Regenerate new account"}
        bgColor={"#1A2CCE"}
        color="#FFFFFF"
        size="md"
        type="button"
        fullwidth
        // loading={deleteMutation.isLoading}
        // onClick={handleDelete}
        style={{
          marginTop: "15px",
        }}
      />
    </>
  );
};

export const CryptoAccountDetails = ({ accountDetails }) => {
  return (
    <>
      <StylesAccount>
        <Flex flexDir="row" justifyContent="space-between">
          <div>
            <svg
              width="149"
              height="148"
              viewBox="0 0 149 148"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="149" height="148" rx="4" fill="white" />
              <rect
                x="16"
                y="16"
                width="113"
                height="114"
                fill="url(#pattern0)"
              />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_1369_4394"
                    transform="translate(-0.0707965 -0.0789474) scale(0.0490413 0.0486111)"
                  />
                </pattern>
                <image
                  id="image0_1369_4394"
                  width="24"
                  height="24"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAfElEQVRIie2UUQrAIAxD49j9L+V2Lv0qjM4Sbd0Ywwd+VGM1IgEWg5wAihr5si5zvXokdUBBm6TWdW3psXc2tC7E9NiMDdOwHFjW9RMx/c3B0dDkxpxX/wP0P4/Wz/8iL/TmwusORrOIOpmVRVbtziLaWPh8FlEn0SxaxKnlnD4pA2fhYgAAAABJRU5ErkJggg=="
                />
              </defs>
            </svg>
          </div>
          <div style={{ width: "50%" }}>
            <div>
              <h3>Network</h3>
              <p>{accountDetails?.wallet_network}</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h3>Deposit Address</h3>
              <p>{accountDetails?.wallet_address}</p>
            </div>
          </div>
        </Flex>
      </StylesAccount>
    </>
  );
};
