import React from "react";
import { Spacer } from "../../../../../components/spacer/styles";
import { FormStyle, Styles } from "./styles";
import CustomTable from "./table";
import { OverdraftTableHead } from "../../../../../utils/constants";
import Overview from "./overview";
import SearchBox from "../../../../../components/searchbox";
import { Flex } from "../../../../../components/box";
import Button from "../../../../../components/button";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import { Form, Select, Input } from "antd";
import TopUp from "../../../../../components/topUp/TopUp";
import { Loader } from "../../../../../components/loader";
import {
  addLiquidity,
  changeStatus,
  getAmountSettledSummaryForMerchant,
  getAmountUsedSummaryForMerchant,
  getMerchantLiquidityById,
  getRequestSummaryForMerchant,
  topUp,
} from "../../../../../services/Liquidity.service";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { useAuthState } from "../../../../../context";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const Liquidity = () => {
  const queryClient = useQueryClient();
  let { merchant } = useParams();

  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [overdraftVisible, setOverdraftVisible] = React.useState(false);
  const [topUpVisible, setTopUpVisible] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [filter, setFilter] = React.useState({ page: 1 });

  const liquidity = useQuery(
    "getMerchantLiquidityById",
    async () => await getMerchantLiquidityById(merchant, filter)
  );

  const requestSummary = useQuery(
    "getRequestSummaryForMerchant",
    async () => await getRequestSummaryForMerchant(merchant)
  );

  const amountUsedSummary = useQuery(
    "getAmountUsedSummaryForMerchant",
    async () => await getAmountUsedSummaryForMerchant(merchant)
  );

  const amountSettledSummary = useQuery(
    "getAmountSettledSummaryForMerchant",
    async () => await getAmountSettledSummaryForMerchant(merchant)
  );

  const setActiveMutation = useMutation(
    async (data) => {
      await changeStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantLiquidityById");
        ToastMessage(
          type.SUCCESS,
          "liquidity status has been successfully changed "
        );
        setTimeout(() => setVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const setPausedMutation = useMutation(
    async (data) => {
      await changeStatus(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantLiquidityById");
        setTimeout(() => setVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const newLiquidityMutation = useMutation(
    async (data) => {
      await addLiquidity(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantLiquidityById");
        setTimeout(() => setOverdraftVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const topupMutation = useMutation(
    async (data) => {
      await topUp(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantLiquidityById");
        setTimeout(() => setTopUpVisible(false), 1000);
        ToastMessage(type.SUCCESS, "liquidity has been updated successfully");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleDecline = (e, status) => {
    setVisible(true);
    setId(e);
    setStatus(status);
  };

  const handleChangeStatus = async () => {
    try {
      if (status === "active") {
        await setActiveMutation.mutate({ status: "paused" });
      } else if (status === "paused" || "stoped") {
        await setPausedMutation.mutate({ status: "active" });
      }
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  const showModal = () => {
    setOverdraftVisible(true);
  };

  const handleEdit = (e) => {
    setTopUpVisible(true);
    setId(e);
  };

  const handleCancel = () => {
    setVisible(false);
    setOverdraftVisible(false);
    setTopUpVisible(false);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => liquidity.refetch());
  };

  if (liquidity.isLoading) {
    return <Loader />;
  }

  if (
    amountSettledSummary.status === "error" ||
    amountUsedSummary.status === "error" ||
    liquidity.status === "error" ||
    requestSummary.status === "error"
  ) {
    return (
      <span>
        Error:
        {requestSummary.error ||
          amountSettledSummary.error ||
          liquidity.error ||
          amountUsedSummary.error}
      </span>
    );
  }

  return (
    <>
      <Styles>
        <Spacer height="30px" />
        <Flex justifyContent="space-between" alignItems="baseline">
          <h3>Liquidity</h3>
          <Button
            Styles
            text={"New Liquidity"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"3px"}
            onClick={showModal}
          />
        </Flex>
        <Spacer height="30px" />
        <Overview
          amountSettledSummary={amountSettledSummary?.data?.data?.data}
          amountUsedSummary={amountUsedSummary?.data?.data?.data}
          requestSummary={requestSummary?.data?.data?.data}
          loading={
            requestSummary.isLoading ||
            amountUsedSummary.isLoading ||
            amountSettledSummary.isLoading
          }
        />
        <Spacer height="20px" />
        <div style={{ paddingLeft: "25px" }}>
          <SearchBox placeholder="Search asset pair" width="323px" />
        </div>
        <Spacer height="20px" />

        <CustomTable
          theadBkColor="#F1F4F8"
          bottomGap="10px"
          tableHead={OverdraftTableHead}
          tableBody={liquidity?.data?.data?.data?.data}
          rowHovColor="#d2ccc626"
          theadColor="#8e8e8e"
          tbodyColor="#141414"
          handleDecline={handleDecline}
          handleEdit={handleEdit}
          handlePageChange={handlePageChange}
          meta={liquidity?.data?.data?.data?.meta}
          pageSize={10}
          paginator
        />
      </Styles>
      <ModalFrame
        visible={overdraftVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            key="submit"
            form="newLiquidity"
            loading={newLiquidityMutation.isLoading}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <FormLayout
          form={form}
          setOverdraftVisible={setOverdraftVisible}
          mutation={newLiquidityMutation}
        />
      </ModalFrame>
      <ModalFrame
        visible={topUpVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            key="submit"
            form="topup"
            loading={topupMutation.isLoading}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <TopUp
          form={form}
          id={id}
          setTopUpVisible={setTopUpVisible}
          mutation={topupMutation}
        />
      </ModalFrame>
      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={
              status === "paused"
                ? "activate liquidity"
                : status === "active"
                ? "pause liquidity"
                : ""
            }
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            onClick={handleChangeStatus}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
            loading={setActiveMutation.isLoading || setPausedMutation.isLoading}
          />,
        ]}
      >
        <h3>
          {status === "paused"
            ? "activate liquidity"
            : status === "active"
            ? "pause liquidity"
            : ""}
        </h3>
        <p>
          {status === "paused"
            ? "By confirming approval, this user will have access to this liquidity funds."
            : status === "active"
            ? "By deactivating this liquidity, this user will no longer have access to make payouts"
            : ""}
        </p>
      </ModalFrame>
    </>
  );
};

export default Liquidity;

const FormLayout = ({ form, mutation }) => {
  let { merchant } = useParams();
  const { currencyPair } = useAuthState();

  const [rightPair, setRightPair] = React.useState("");
  const [leftPair, setLeftPair] = React.useState("");

  const handleInputValueChange = (e) => {
    const { value } = e;
    const newPair = value.replace(/\//g, " ").split(" ");
    setLeftPair(newPair[0]);
    setRightPair(newPair[1]);
  };

  const handleSubmit = async (values) => {
    const payload = {
      currency_pair: values.currency_pair,
      amount: Number(values.amount),
      merchant_id: Number(merchant),
    };
    try {
      await mutation.mutate(payload);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "left" }}>Add Liquidity</h3>
      <FormStyle>
        <Form
          onFinish={handleSubmit}
          id="newLiquidity"
          form={form}
          layout={"vertical"}
          size="large"
        >
          <Form.Item
            label="Currency pair"
            name="currency_pair"
            placeholder="Select currency pair"
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              onChange={(e, name) => {
                handleInputValueChange(name);
              }}
            >
              {currencyPair &&
                currencyPair?.map((item) => (
                  <Select.Option key={item.id} value={item.pair}>
                    {item.pair}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <small>
            You are borrowing <strong>{rightPair}</strong> and making
            settlements in <strong>{leftPair}</strong>
          </small>
          <Form.Item
            label="Credit cap"
            className="amount"
            name="amount"
            rules={[{ required: true, message: "Amount is Required" }]}
          >
            <Input
              addonBefore={leftPair}
              placeholder="0.00"
              type="number"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <small>This is the amount you wish to add to this merchant</small>
        </Form>
      </FormStyle>
    </>
  );
};
