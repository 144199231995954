import * as yup from "yup";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Flex } from "../../../components/box";
import Button from "../../../components/button";
import { resetPassword } from "../../../services/Auth.service";
import { FormContainer } from "./styles";
import { Form, Field, ErrorMessage, Formik } from "formik";
import { HiddenVisibilityIcon, PasswordEyeIcon } from "../../../assets/";
import { ToastMessage, type } from "../../../utils/toastr";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../components/input/styles";

const Fields = () => {
  const [visibility, setVisibility] = useState();
  const [visibility2, setVisibility2] = useState();

  const initialValues = { password: "", confirmPassword: "" };

  const validateSchema = yup.object().shape({
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .required("Please Confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          try {
            const newpassword = {
              password: values.password,
              hash: hash,
            };
            const success = await resetPassword(newpassword);
            if (success.data)
              navigate({
                pathname: "/password-reset/done",
              });
            resetForm();
          } catch (error) {
            ToastMessage(type.ERROR, error.response.data.message);
          }
        }}
        validationSchema={validateSchema}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          dirty,
          values: { password, confirmPassword },
        }) => (
          <Form>
            <Flex flexDir={"column"} gap={"30px"}>
              <InputStyle>
                <Label htmlFor="password">Enter new password </Label>
                <FieldWrapper btn={"btn"}>
                  <Field
                    type={visibility ? "text" : "password"}
                    onChange={handleChange}
                    name={"password"}
                    value={password}
                    onBlur={handleBlur}
                    id="password"
                  />
                  <button onClick={() => setVisibility(!visibility)}>
                    {visibility ? (
                      <PasswordEyeIcon width={"20px"} height={"20px"} />
                    ) : (
                      <HiddenVisibilityIcon width={"20px"} height={"20px"} />
                    )}
                  </button>
                </FieldWrapper>
                <ErrorMessage name={"password"} component={"div"} />
              </InputStyle>
              <InputStyle>
                <Label htmlFor="confirmPassword">Confirm password</Label>
                <FieldWrapper btn={"btn"}>
                  <Field
                    type={visibility2 ? "text" : "password"}
                    onChange={handleChange}
                    name={"confirmPassword"}
                    value={confirmPassword}
                    id="confirmPassword"
                    onBlur={handleBlur}
                  />
                  <button onClick={() => setVisibility2(!visibility2)}>
                    {visibility2 ? (
                      <PasswordEyeIcon width={"20px"} height={"20px"} />
                    ) : (
                      <HiddenVisibilityIcon width={"20px"} height={"20px"} />
                    )}
                  </button>
                </FieldWrapper>
                <ErrorMessage name={"confirmPassword"} component={"div"} />
              </InputStyle>
              <Button
                text={"Reset Password"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type="submit"
                borderRadius={"5px"}
                loading={isSubmitting}
              />
            </Flex>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default Fields;
