import styled from "styled-components";
import { Flex } from "../box";

const variantSize = (size) =>
  size
    ? size === "sm"
      ? "14px"
      : size === "md"
      ? "16px"
      : size === "lg"
      ? "18px"
      : ""
    : "14px";

const variantPadding = (size, hasIcon) => {
  if ((size && hasIcon) || hasIcon) {
    return "0.8rem 1rem";
  } else {
    return size === "sm"
      ? "0.6rem 1.25rem"
      : size === "md"
      ? "0.85rem 2.2rem"
      : size === "lg"
      ? "1rem 2.2rem"
      : "0.8rem 2rem";
  }
};
const tabVariantPadding = (size, hasIcon) => {
  if ((size && hasIcon) || hasIcon) {
    return "0.8rem 1rem";
  } else {
    return size === "sm"
      ? "0.3rem 1rem"
      : size === "md"
      ? "0.6rem 2rem"
      : size === "lg"
      ? "1rem 2.2rem"
      : "0.5rem .7rem";
  }
};
const mobileVariantPadding = (size, hasIcon) => {
  if ((size && hasIcon) || hasIcon) {
    return "0.8rem 1rem";
  } else {
    return size === "sm"
      ? "0.3rem 0.6rem"
      : size === "md"
      ? "0.4rem .7rem"
      : size === "lg"
      ? ".5rem 1.5rem"
      : "0.6rem 1rem";
  }
};

export const ButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  background: ${({ bgColor, loading, disabled }) =>
    loading || disabled ? "#CDCDCD" : bgColor};
  color: ${({ color, loading, disabled }) =>
    loading || disabled ? "#CDCDCD" : color};
  border: ${({ border }) => border};
  font-size: ${({ size }) => variantSize(size)};
  width: ${({ fullwidth }) => (fullwidth ? "100%" : "max-content")};
  padding: ${({ size, hasIcon }) => variantPadding(size, hasIcon)};
  @media (max-width: 750px) {
    padding: ${({ size, hasIcon }) => tabVariantPadding(size, hasIcon)};
  }
  @media (max-width: 600px) {
    padding: ${({ size, hasIcon }) => mobileVariantPadding(size, hasIcon)};
  }
  border-radius: ${({ borderRadius }) => borderRadius};
  font-weight: 400;
  cursor: ${({ loading, disabled }) =>
    loading || disabled ? "not-allowed" : "pointer"};

  opacity: 1;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    color: #6c6c6c;
  }
`;

export const Loader = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  margin: auto;
`;
