import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/login';
import ResetPassword from '../pages/auth/reset-password';
import ForgotPassword from '../pages/auth/forgot-password';
import ForgotPasswordSent from '../pages/auth/verify/forgot-password-sent';
import PasswordResetSuccessful from '../pages/auth/verify/reset-password-successful';
import TwoFA from '../pages/auth/2fa-auth';

const Index = () => {
	return (
		<Routes>
			<Route index path={'/'} element={<Login />} />
			<Route path={'/2fa-auth'} element={<TwoFA />} />

			<Route path={'/forgot-password'} element={<ForgotPassword />} />
			<Route path={'/forgotpassword/sent'} element={<ForgotPasswordSent />} />
			<Route path={'/reset-password'} element={<ResetPassword />} />
			<Route
				path={'/password-reset/done'}
				element={<PasswordResetSuccessful />}
			/>
		</Routes>
	);
};

export default Index;
