import React, { useState } from 'react';
import { BackIcon } from '../../../../assets';
import { Flex } from '../../../../components/box';
import Layout from '../../../../components/layouts';
import { Spacer } from '../../../../components/spacer/styles';
import { Styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	activateOrDeactivateProviderStatus,
	getProviderStatus
} from '../../../../services/Transactions.service';
import { ToastMessage, type } from '../../../../utils/toastr';
import CustomTable from './table';
// import { Container1 } from '../../../../components/search/styles';
import CustomDropdown from './customdropdown/CustomDropdown';
import { Loader } from '../../../../components/loader';

const ProviderStatus = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [actionTaken, setActionTaken] = useState('Activate all');
	const [id, setId] = React.useState(null);

	const [visible, setVisible] = React.useState(false);
	const [status, setStatus] = React.useState('');
	const [selectedIndex, setSelectedIndex] = useState([]);
	const result = useQuery(
		'getProviderStatus',
		async () => await getProviderStatus(),
		{
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const ProviderStatusMutation = useMutation(
		async (data) => {
			await activateOrDeactivateProviderStatus(data);
		},
		{
			onSuccess: (data, variables, context) => {
				queryClient.invalidateQueries('getProviderStatus');
				ToastMessage(
					type.SUCCESS,
					`${
						variables?.provider !== undefined
							? variables?.provider
							: 'All providers'
					} ${
						variables?.status === true ? 'activated' : 'deactivated'
					} successfully`
				);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
	};
	const handleToggle = (value) => {
		setActionTaken(value);
	};

	const handleChangeAll = () => {
		console.log(selectedIndex, actionTaken);
		if (actionTaken === 'Activate all') {
			ProviderStatusMutation.mutate({ status: true });
		} else {
			ProviderStatusMutation.mutate({ status: false });
		}
	};
	const handleProviderStatus = (e) => {
		// setSingleProviderName(e.provider);
		const payload = {
			provider: e?.provider,
			p_user: e?.user,
			status: !e?.status
		};
		ProviderStatusMutation.mutate(payload);
	};
	if (result.isLoading) {
		return <Loader />;
	}

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}

	return (
		<Styles>
			<Layout title={'Provider Status'}>
				<Flex
					onClick={() => navigate('/dashboard/transactions')}
					className='go_back'
					alignItems='center'
					justifyContent='flex-start'
				>
					<BackIcon color='#1A2CCE' />
					<span style={{ paddingLeft: '20px' }}>Go Back</span>
				</Flex>
				<Spacer height='5px' />
				<Flex justifyContent='end'>
					<CustomDropdown
						showModal={showModal}
						handleChangeAll={handleChangeAll}
						handleToggle={handleToggle}
						setVisible={setVisible}
						actionTaken={actionTaken}
						selectedIndex={selectedIndex}
					/>
				</Flex>
				<Spacer height='30px' />

				<CustomTable
					theadBkColor='#F1F4F8'
					bottomGap='10px'
					tableHead={dataHead}
					tableBody={result?.data?.data?.data}
					rowHovColor='#d2ccc626'
					theadColor='#8e8e8e'
					tbodyColor='#141414'
					handleReadAll={() => []}
					pageSize={10}
					paginator
					handleApprove={showModal}
					handleReject={showModal}
					setSelectedIndex={setSelectedIndex}
					selectedIndex={selectedIndex}
					actionTaken={actionTaken}
					setActionTaken={setActionTaken}
					setVisible={setVisible}
					handleActivateAndDeactivate={handleProviderStatus}
					// handlePageChange={handlePageChange}
					// meta={[]}
				/>
			</Layout>
		</Styles>
	);
};

export default ProviderStatus;

const dataHead = ['', 'provider', 'account name', 'currency', 'Note', ''];
