import { Skeleton } from 'antd';
import React from 'react';

import { Link } from 'react-router-dom';
import { TriangleIcon } from '../../../../assets';
import { Flex, Grid } from '../../../../components/box';
import Button from '../../../../components/button';
import { Spacer } from '../../../../components/spacer/styles';
import { Card, Styles } from './styles';

const Index = ({ totalTransaction, merchantsCount, loading, fundAccount }) => {
	return (
		<Styles>
			<Flex
				className='header-container'
				justifyContent={'space-between'}
				flexDir={'row'}
			>
				<Flex flexB={'70%'} justifyContent='flex-start'>
					<h3>Summary</h3>
				</Flex>
			</Flex>
			<Spacer height='20px' />
			<Grid
				gap='10px'
				gridCol='repeat(auto-fit, minmax(270px, 1fr))'
				bgColor='#fff'
			>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Total Transacted Volume</span>
						<span>
							<Link to={'/dashboard/balances'}>View balances</Link>
						</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>
								{totalTransaction?.currency?.symbol}
								{totalTransaction?.result?.toLocaleString()}
							</span>
						)}

						<span>
							<TriangleIcon /> $45,586.94 from last week
						</span>
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Total Merchants</span>
						<span>
							<Link to={'/dashboard/merchants'}>View all</Link>
						</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{merchantsCount?.result}</span>
						)}
						<span>
							<TriangleIcon /> + 20 from last week
						</span>
					</Flex>
				</Card>
				<Card style={{ borderRight: 'none' }}>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>ACCOUNTS</span>
					</Flex>
					<Flex
						className='viewall'
						alignItem='flex-end'
						justifyContent='flex-start'
					>
						<Button
							Styles
							text='Fund an account'
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='button'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							style={{ marginTop: '13px' }}
							borderRadius={'5px'}
							onClick={fundAccount}
						/>
					</Flex>
				</Card>
			</Grid>
		</Styles>
	);
};

export default Index;
