import React from "react";
import { Styles, Card, IconAndExport } from "./styles";
import { Flex, Grid } from "../../../../../components/box";
import { Spacer } from "../../../../../components/spacer/styles";
import usdt from "../../../../../assets/images/usdt.svg";
import ngflag from "../../../../../assets/images/ngflag.svg";
import Button from "../../../../../components/button";

const Index = ({ handleDownload, liquidity, handleDelete }) => {
  return (
    <>
      <Styles>
        <IconAndExport>
          <div className="icon_box_container">
            <div className="icon_box">
              <div className="flag">
                <img src={usdt} alt="flag" />
              </div>
              <div className="flag">
                <img src={ngflag} alt="flag" />
              </div>
            </div>
            <p className="icon_box_text">{liquidity?.currency_pair}</p>
          </div>
          <div>
            <Button
              Styles
              text={"Export Report"}
              color={"#1A2CCE"}
              size="md"
              type="submit"
              style={{ padding: 0 }}
              onClick={handleDownload}
            />
            <Button
              Styles
              text={"Delete Liquidity"}
              color={"#1A2CCE"}
              type="button"
              onClick={handleDelete}
            />
          </div>
        </IconAndExport>

        <Spacer height="10px" />
        <Grid
          justifyContent={"space-around"}
          gridCol="repeat(auto-fit, minmax(270px, 1fr))"
          bgColor="#eef2f7"
          pad="20px"
        >
          <Card color="#fff" className="credit-cap">
            <Flex
              className="container"
              alignItem={"center"}
              justifyContent={"space-between"}
            >
              <span>DEPOSITS</span>
            </Flex>
            <Flex
              className="viewall"
              alignItem={"flex-start"}
              flexDir={"column"}
            >
              <span className="deposit_cash">
                {liquidity?.to_currency}{" "}
                {liquidity?.credit_cap.toLocaleString()}
              </span>
            </Flex>
          </Card>
          <Card>
            <Flex
              className="container"
              alignItem={"center"}
              justifyContent={"space-between"}
            >
              <span>AVALIABLE</span>
            </Flex>
            <Flex
              className="viewall"
              alignItem={"flex-start"}
              flexDir={"column"}
            >
              <span>
                {liquidity?.to_currency} {liquidity?.balance.toLocaleString()}
              </span>
            </Flex>
          </Card>
          <Card>
            <Flex
              className="container"
              alignItem={"center"}
              justifyContent={"space-between"}
            >
              <span>PAYOUTS </span>
            </Flex>
            <Flex
              className="viewall"
              alignItem={"flex-start"}
              flexDir={"column"}
            >
              <span>
                {liquidity?.to_currency}{" "}
                {liquidity?.amount_used.toLocaleString()}
              </span>
            </Flex>
          </Card>
          <Card>
            <Flex
              className="container"
              alignItem={"center"}
              justifyContent={"space-between"}
            >
              <span>SETTLEMENT</span>
            </Flex>
            <Flex
              className="viewall"
              alignItem={"flex-start"}
              flexDir={"column"}
            >
              <span>
                {liquidity?.to_currency}{" "}
                {liquidity?.amount_settled.toLocaleString()}
              </span>
            </Flex>
          </Card>
        </Grid>
      </Styles>
    </>
  );
};

export default Index;
