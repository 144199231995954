// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customStyle_select_date__7A8ge = {\n\tmargin-top: 10px;\n}\n.customStyle_select_date__7A8ge p {\n\tmargin-bottom: 0;\n}\n.customStyle_picker_holder__7fioG {\n\tbackground: #ffffff;\n\tborder: 0.7px solid #cfcfcf;\n\tbox-sizing: border-box;\n\tbox-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);\n\tborder-radius: 3px;\n\theight: 44px;\n\n\tpadding: 10px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/customDatePicker/customStyle.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,mBAAmB;CACnB,2BAA2B;CAC3B,sBAAsB;CACtB,2CAA2C;CAC3C,kBAAkB;CAClB,YAAY;;CAEZ,wBAAwB;AACzB","sourcesContent":[".select_date = {\n\tmargin-top: 10px;\n}\n.select_date p {\n\tmargin-bottom: 0;\n}\n.picker_holder {\n\tbackground: #ffffff;\n\tborder: 0.7px solid #cfcfcf;\n\tbox-sizing: border-box;\n\tbox-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);\n\tborder-radius: 3px;\n\theight: 44px;\n\n\tpadding: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_date": "customStyle_select_date__7A8ge",
	"picker_holder": "customStyle_picker_holder__7fioG"
};
export default ___CSS_LOADER_EXPORT___;
