import React from 'react';
import { CloseIcon, SearchIcon } from '../../assets';
import { Container, Flex } from '../../components/box';
import {
	Container1,
	Container2,
	Container3,
	Input,
	SearchContainer
} from './styles';

const Search = ({
	placeholder,
	setShowSearch,
	showSearch,
	setInput,
	input,
	handleSearch
}) => {
	const handleClearInput = () => setInput('');
	return (
		<Container1 height='max-content'>
			<Container2
				margin='0 30px 0 auto'
				height='max-content'
				justifyContent='flex-end'
			>
				<Container3>
					<Flex bgColor={'#fff'} wrap='nowrap'>
						<Container width='max-content' height='max-content' pad='10px'>
							<SearchIcon
								width='14px'
								height='14px'
								// color={""}
							/>
						</Container>

						<SearchContainer height='max-content'>
							<Input
								tye='text'
								value={input}
								onChange={(e) => {
									console.log(e.target.value);
									handleSearch(e);
								}}
								placeholder={placeholder}
							/>
						</SearchContainer>

						{input?.length ? (
							<Container
								width='max-content'
								height='max-content'
								pad='10px'
								onClick={handleClearInput}
								cursor='pointer'
							>
								<CloseIcon
									width='14px'
									height='14px'
									// color={["grey", "1500", theme]}
								/>
							</Container>
						) : null}
					</Flex>
				</Container3>
			</Container2>
		</Container1>
	);
};
export default Search;
