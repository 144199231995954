import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const Styles = styled.div`
  .select {
    .ant-select-selector {
      color: #000000;
      border: none;
    }
  }
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .fiter_container {
    background: #f8f9;
  }
`;

export const MainContainer = styled("div")`
  background: #f8f9fd;
  padding: 46px 61px;
  @media (max-width: 1200px) {
    padding: 20px 30px;
  }
`;

export const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const InputFlexBox = styled(Flex)`
  justify-content: flex-start;
  align-items: center;

  .icon-box {
    position: relative;
    display: flex;
    .avatar {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d7e6ff;
      margin-right: 16px;
    }
    .avatar img {
      max-width: 99%;
      max-height: 99%;
      border-radius: 50%;
    }
    > input {
      position: absolute;
      border: 1px solid red;
      visibility: hidden;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16.5529px;
      line-height: 22px;
      text-decoration-line: underline;
      color: #1a2cce;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .holder {
    padding-top: 3rem;
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .box {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .box1 {
      flex-direction: column;
    }
  }
  @media (max-width: 1200px) {
    justify-content: center;
    .holder {
      width: fit-content;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
