import { Form, Select } from 'antd';
import React, { useState } from 'react';
import Button from '../../../../components/button';
import { Styles } from './styles';

const FundAccountForm = ({ handleSubmit }) => {
	const [currencyType, setCurrencyType] = useState('Fiat');
	return (
		<Styles>
			<h3>Fund an account</h3>
			<Form
				layout='vertical'
				onFinish={handleSubmit}
				id='fundAccount'
				initialValues={{
					layout: 'vertical',
					size: 'large',
					currency_type: currencyType
				}}
			>
				<Form.Item label='Select currency type' name='currency_type'>
					<Select
						placeholder='Select currency type'
						onChange={(value) => setCurrencyType(value)}
					>
						<Select.Option value='Fiat'>Fiat</Select.Option>
						<Select.Option value='Crypto'>Crypto</Select.Option>
					</Select>
				</Form.Item>

				{currencyType === 'Fiat' ? (
					<Form.Item label='Choose Currency' name='currencyFiat'>
						<Select placeholder='Choose Currency'>
							<Select.Option value='USD'>USD</Select.Option>\
						</Select>
					</Form.Item>
				) : (
					<Form.Item label='Choose Currency' name='currencyCrypto'>
						<Select placeholder='Choose Currency'>
							<Select.Option value='USDC'>USDC</Select.Option>
						</Select>
					</Form.Item>
				)}
				<Form.Item label='Select service provider' name='provider'>
					<Select placeholder='Select service provider'>
						<Select.Option value='Juice'>Juice</Select.Option>
					</Select>
				</Form.Item>
				<Button
					Styles
					bgColor={'#1A2CCE'}
					text={`Generate ${
						currencyType === 'Fiat' ? 'account' : 'wallet'
					} details`}
					color={'#FFFFFF'}
					size='md'
					type='submit'
					fullwidth
				/>
			</Form>
		</Styles>
	);
};

export default FundAccountForm;
