import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileIcon } from '../../assets/index';
import { useAuthState } from '../../context';
import { Flex } from '../box';
import { HeaderStyles } from './styles';

const Index = ({ title }) => {
	const [showDropDown, setShowDropDown] = React.useState(false);
	const { handleLogout } = useAuthState();
	const navigate = useNavigate();

	return (
		<HeaderStyles>
			<Flex alignItems={'flex-start'} justifyContent={'space-between'}>
				<Flex
					justifyContent={'flex-start'}
					alignItems={'center'}
					width={'fit'}
					className='logo_container'
				>
					<h2>{title}</h2>
				</Flex>
				<div className='icon_box'>
					{showDropDown && (
						<div
							onClick={() => {
								handleLogout();
								navigate('/');
							}}
							className='profile_dropdown'
						>
							logout
						</div>
					)}

					<div
						className='icon_div'
						onClick={() => {
							setShowDropDown(!showDropDown);
						}}
					>
						<ProfileIcon color={'#1A2CCE'} />
					</div>
				</div>
			</Flex>
		</HeaderStyles>
	);
};

export default Index;
