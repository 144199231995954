import styled from 'styled-components';

export const Styles = styled.div`
	.select {
		.ant-select-selector {
			color: #000000;
			border: none;
		}
	}
`;

export const Container1 = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	.fiter_container {
		gap: 30px;
		justify-content: space-between;
	}
	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 20px 10px;
		.fiter_container {
			width: 100%;
			justify-content: space-between;
			overflow: auto;
			flex-wrap: wrap;
			gap: 15px 5px;
			/* border: 1px solid red; */
		}
		.input_container {
			width: 100%;
		}
	}
`;

export const StylesAccount = styled.div`
	background: #f3f6fb;
	border-radius: 4px;
	padding: 30px 35px;
	display: flex;
	flex-direction: column;

	h3 {
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;

		color: #000000;
		text-align: left;
		padding-bottom: 10px;
	}
	p {
		margin: 0;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		color: #000000;
		align-items: center;
		svg {
			margin-right: 10px;
		}
	}
`;
