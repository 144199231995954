import styled from "styled-components";

export const Styles = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  width: calc(100% - 240px);
  margin: 0 0 0 auto;
  background-color: ${({ bgColor }) => bgColor};
  > section {
    padding: 40px 0;
    margin: 30px auto;
  }

  @media (max-width: 700px) {
    width: 100%;
    > section {
      margin: 40px 0 40px;
    }
  }
`;
