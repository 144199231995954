export const chartData = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100
	},
	{
		name: 'Page H',
		uv: 4790,
		pv: 4800,
		amt: 2100
	},
	{
		name: 'Page H',
		uv: 4490,
		pv: 4800,
		amt: 2100
	},
	{
		name: 'Page I',
		uv: 1490,
		pv: 8000,
		amt: 2100
	}
];

export const OverviewPendingKYCTableHead = [
	'BUSINESS NAME',
	'EMAIL ADDRESS',
	'COUNTRY/REGION',
	'ACTION'
];

export const FiatTableHead = [
	'',
	'DATE',
	'BUSINESS NAME',
	'AMOUNT ',
	'WITHDRAWAL TO',
	'STATUS',
	''
	// ''
];
export const PendingApprovalsTableHead = [
	'',
	'DATE',
	'BUSINESS',
	'AMOUNT ',
	'TYPE',
	'Message',
	// 'WITHDRAWAL TO',
	'STATUS',
	''
];

export const TransactionTableHeadSwap = [
	'',
	'Date',
	'BUSINESS',
	'Currency',
	'SETTLEMENT',
	'EXPIRES IN',
	'STATUS',
	''
];

export const TransactionTableHeadMerchant = [
	'',
	'Date',
	'BUSINESS',
	'Currency',
	'SETTLEMENT',
	'STATUS',
	'TYPE',
	''
];

export const TransactionTableHeadDeposit = [
	'',
	'DATE',
	'DEPOSITED BY',
	'AMOUNT DEPOSITED',
	'Payment method',
	'STATUS',
	''
];

export const TransactionTableHeadWithdrawal = [
	'',
	'DATE',
	'WITHDRAWN BY',
	'AMOUNT WITHDRAWN',
	'Payment method',
	'STATUS',
	''
];

export const MarketsTableHead = [
	'Currency',
	'BUYING',
	'SELLING',
	'TYPE',
	'',
	'',
	'',
	''
];

export const settlementHead = [
	'DATE',
	'TNX REFERENCE',
	'AMOUNT',
	'STATUS',
	'TYPE'
];

export const MerchantTableHead = [
	'BUSINESS NAME',
	'EMAIL ADDRESS',
	'COUNTRY/ REGION',
	'KYC/AML',
	'STATUS',
	''
];

export const balanceTableHead = [
	'Currency',
	'Avaliable',
	'Liquidity Balance',
	'Pending Balance',
	''
];

export const ComplianceTableHead = [
	'BUSINESS NAME',
	'EMAIL ADDRESS',
	'COUNTRY/ REGION',
	'KYC/AML',
	'STATUS',
	''
];

export const TransactionsKYCTableHead = [
	'',
	'FULL NAME',
	'BUSINESS NAME',
	'EMAIL ADDRESS',
	'ACTION',
	'STATUS',
	''
];

export const UsersRolesTableHead = ['NAME', 'EMAIL', 'ROLE', 'STATUS', '', ''];

export const paymentMethodsHead = [
	'Name',
	'Method',
	'Provider',
	'Currencies',
	'Fee Type',
	'Fee Amount',
	''
];

export const OverdraftTableHead = [
	'Currency',
	'Business name',
	'date',
	'amount',
	'',
	'',
	''
];
export const BalancesTableHead = ['Currency', 'Available Amount', ''];
export const WalletTableHead = [
	'DATE CREATED',
	'MERCHANT BUSINESS NAME',
	'WALLET NAME',
	'WALLET REFERENCE No',
	// 'WALLET TYPE',
	// 'CURRENCY',
	''
];
export const MerchantWalletTableHead = [
	'',
	'DATE CREATED',
	'MERCHANT BUSINESS NAME',
	'WALLET NAME',
	'WALLET REFERENCE No',

	''
];
export const MerchantFiatAccounTableHead = [
	'',
	'DATE CREATED',
	'Account NAME',
	'Account Number',
	'CURRENCY',
	'TYPE',
	''
];
export const MerchantCryptoAccounTableHead = [
	'',
	'DATE CREATED',
	'wallet address',
	'network',
	'CURRENCY',
	'TYPE',
	''
];

export const WalletTransactionTableHead = [
	'DATE',
	'AMOUNT',
	'depositor',
	'TRANSACTION TYPE',
	'STATUS'
];

export const RefundsTableHead = [
	'',
	'Date',
	'currency',
	'merchant',
	'amount',
	'refund status',
	''
];
export const CrossFeesTableHead = [
	'MERCHANT',
	'TRANSACTION TYPE',
	'FROM',
	'TO',
	'FEE',
	'ENV',
	'PAYMENT METHOD',
	''
];
export const XchangeConnectTableHead = [
	'interconnected name',
	'interconnected reference',
	''
];
