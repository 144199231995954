import styled from 'styled-components';
import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	width: 100%;
	padding: 30px 40px 0 37px;

	@media (max-width: 700px) {
		padding: 20px 4%;
	}
`;

export const ProfileContainer = styled(Flex)`
	position: relative;
	.profile {
		border-radius: 50%;
		margin-right: 13px;
	}
	label {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		color: #1a1f36;
	}
	.verified_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 100px;
		padding: 5px 15px;
		border-radius: 3px;
		margin-left: 11px;
		background-color: ${({ status }) =>
			(status === 'unverified' && '#FFE4E4') ||
			(status === 'verified' && '#C8FFC7') ||
			(status === 'rejected' && '#FFE4E4') ||
			(status === 'pending' && '#FFF2D9')};
		span {
			color: ${({ status }) =>
				(status === 'unverified' && '#CE1A1A') ||
				(status === 'rejected' && 'red') ||
				(status === 'verified' && '#027200') ||
				(status === 'pending' && '#9C5502')};
		}
	}

	.sideButtons {
		display: flex;
		justify-self: flex-end;
		gap: 0 40px;
	}
`;
