import React, { useState } from "react";
import { useQuery } from "react-query";

import CustomTable from "../table";
import { Styles, Container1 } from "./styles";
import Button from "../../../../components/button";
import Search from "../../../../components/search";
import { Container } from "../../../../components/box";
import { Loader } from "../../../../components/loader";
import Filters from "../../../../components/filters/Filters";
import ModalFrame from "../../../../components/modal/ModalFrame";
import { ComplianceTableHead } from "../../../../utils/constants";
import { getComplianceSuspended } from "../../../../services/Compliance.service";

const Index = () => {
  const [statusValue, setStatusValue] = useState("All");
  const [countryValue, setCountryValue] = useState("All");
  const [clicked, setClicked] = useState("false");
  const [suspendUser, setSuspendUser] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [input, setInput] = useState("");
  const [filter, setFilter] = useState({ page: 1 });

  const suspended = useQuery(
    "getComplianceSuspended",
    async () => await getComplianceSuspended(filter)
  );

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setSuspendUser(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleSelectStatus = (e) => {
    setStatusValue(e);
    setFilter({ ...filter, status: e });
  };

  const handleSelectCountry = (e) => {
    setCountryValue(e);
    setFilter({ ...filter, country: e });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setInput(value);
    setFilter({ ...filter, search: value });
  };

  const handleSubmit = () => {
    if (filter.status === "All") {
      delete filter.status;
    }
    if (filter.country === "All") {
      delete filter.country;
    }
    setClicked("true");
    suspended.refetch(filter);
  };

  const handleCancel = () => {
    setSuspendUser(false);
    setConfirmLoading(false);
  };

  const showSuspendModal = () => {
    setSuspendUser(true);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => suspended.refetch());
  };

  if (suspended.isLoading) {
    return <Loader />;
  }

  if (suspended.status === "error") {
    return <span>Error: {suspended.error.message}</span>;
  }

  return (
    <Styles>
      <Container1>
        <Container className="input_container" width={"20%"}>
          <Search
            placeholder={"Search by name."}
            input={input}
            setInput={setInput}
            handleSearch={handleSearch}
          />
        </Container>
        <Filters
          source={"compliance"}
          handleSelectCountry={handleSelectCountry}
          onChangeStatus={handleSelectStatus}
          refetch={handleSubmit}
          statusValue={statusValue}
          countryValue={countryValue}
          loading={clicked === "true" && suspended.isRefetching}
        />
      </Container1>
      <CustomTable
        theadBkColor="#F1F4F8"
        bottomGap="10px"
        tableHead={ComplianceTableHead}
        tableBody={suspended?.data?.data?.data?.data}
        rowHovColor="#d2ccc626"
        theadColor="#8e8e8e"
        tbodyColor="#141414"
        paginator
        source="approval"
        showSuspendModal={showSuspendModal}
        suspended
        handlePageChange={handlePageChange}
        meta={suspended?.data?.data?.data?.meta}
      />
      <ModalFrame
        visible={suspendUser}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#A50000"}
            color="#FFFFFF"
            size="md"
            type="button"
            onClick={handleOk}
          />,
        ]}
      >
        <h3 style={{ textAlign: "center" }}>Activate user</h3>
        <p>Are you sure you want to Activate the user</p>
      </ModalFrame>
    </Styles>
  );
};

export default Index;
