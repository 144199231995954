import styled from "styled-components";

export const CustomTableMain = styled.section`
  .container {
    // height: 65vh;
    overflow: auto;
  }
  background: white;
  padding-bottom: ${({ paginator }) => !paginator && "20px"};
  width: 100%;
  table {
    background: white;
    border-collapse: collapse;
    text-align: start;
    width: 100%;
    overflow: auto;
    @media (max-width: 600px) {
      width: 700px;
    }
    thead {
      background: ${({ theadBkColor }) => theadBkColor || "#fff"};

      tr {
        th {
          text-align: start;
          color: #000a62;
          font-size: 12px;
          padding: 2em 0 1rem 1.8rem;
          min-width: 160px;
        }
      }
    }
    tbody {
      tr {
        background: white;
        border: none;
        overflow: auto;
        border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
      }
    }
  }
`;

export const Td = styled.td`
  padding: 1.2rem 1.8rem;
  font-weight: 400;
  font-size: 14px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      margin-right: 15px;
      border-radius: 16.9px;
      padding: 2px 5px;
      background-color: #f0f0f0;
    }
    > span:first-child {
      margin-right: 5px;
      color: #7d7d7d;
    }
  }
  span {
    font-size: 0.9rem;
    color: ${({ tbodyColor }) => tbodyColor || "#1A1F36"};
  }

  color: ${({ tbodyColor }) => tbodyColor || "#1A1F36"};
`;
export const CustomTd = styled.td`
  padding: 1.2rem 1.8rem;
  div {
    > span:first-child {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f0f0;
    }
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon_box {
      margin-right: 10px;
    }
  }
`;

export const Paginator = styled("div")`
  background: white;
  padding: 1em 5px;
  display: ${({ paginator }) => (paginator ? "flex" : "none")};
  justify-content: space-between;
  ul {
    justify-content: flex-end;
  }
`;
