import styled from "styled-components";
import { Flex } from "../../../components/box";

export const Styles = styled.div`
  .select {
    .ant-select-selector {
      color: #000000;
      border: none;
    }
  }
  .checkbox {
    margin-top: 20px;
  }
  margin: auto 35px;
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .fiter_container {
    gap: 30px;
    justify-content: space-between;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px 10px;
    .fiter_container {
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      flex-wrap: wrap;
      gap: 15px 5px;
    }
    .input_container {
      width: 100%;
    }
  }
`;

export const SelectContainer = styled(Flex)`
  justify-content: space-between;
  background-color: #fff;
  padding: 0 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 0.7px solid #dfdfdf;
  > span {
    font-size: 12px;
    color: #969696;
  }
`;

export const FormStyle = styled.div`
  .currency {
    margin-bottom: 0;
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 5px;
      height: 46px;
      padding: 2px 11px;
    }
  }

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5b5b5b;
  }

  .amount {
    margin-top: 20px;
    margin-bottom: 0;

    .ant-input-number-group {
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      border-left-style: none !important;
      border-right-style: none !important;
    }
    .ant-input-number-lg input {
      background: #ffffff;
      box-sizing: border-box;
      height: 46px;
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      border-top-style: none !important;
      border-bottom-style: none !important;
      border-left-style: none !important;
    }

    .ant-input-number-group-addon {
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      border-top-style: none !important;
      border-bottom-style: none !important;
      padding: 12px 15px !important;
      margin-right: 5px;
    }
  }
  .rate {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .fund {
    margin-top: 20px;
    margin-bottom: 0;

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #1a2cce;
        :after {
          background: #1a2cce;
        }
      }
    }
  }
`;
