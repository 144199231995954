import React, { useState } from 'react';
import { StepOne, StepThree, StepTwo } from './stepformComp';

const MultiStepForm = ({
	current,
	prevStep,
	nextStep,
	backThreeStep,
	finish,
	tab,
	merchantID,
	setAddVisible,
	handleCancel,
	bvn
}) => {
	// const { cryptoData } = useContext(Store);
	const [formData, setFormData] = useState({
		email: '',
		wallet_ref: '',
		phone: '',
		firstname: '',
		lastname: '',
		document_type: '',
		document_no: '',
		document_file_type: '',
		bvn: '',
		name: '',
		bPhone: '',
		address: '',
		city: '',
		state: '',
		country: '',
		reg_number: ''
	});

	const handleInputData = (input) => (e) => {
		const { value } = e.target;
		// console.log(value);
		// if (input === 'FiatAmount') {
		// 	setFormData({
		// 		...formData,
		// 		[input]: Intl.NumberFormat('en-US').format(value)
		// 	});
		// }
		setFormData((prevState) => ({
			...prevState,
			[input]: value
		}));
	};
	const handleSelectData = (select) => (e) => {
		setFormData((prevState) => ({
			...prevState,
			[select]: e
		}));
	};

	// console.log(formData);

	switch (current) {
		case 1:
			return (
				<StepOne
					tab={tab}
					nextStep={nextStep}
					handleFormData={handleInputData}
					handleSelectFormData={handleSelectData}
					values={formData}
					handleCancel={handleCancel}
					bvn={bvn}
				/>
			);
		case 2:
			return (
				<StepTwo
					nextStep={nextStep}
					prevStep={prevStep}
					handleFormData={handleInputData}
					handleSelectFormData={handleSelectData}
					values={formData}
					// finished={finish}
					// merchantID={merchant dID}
					handleCancel={handleCancel}
				/>
			);
		case 3:
			return (
				<StepThree
					nextStep={nextStep}
					prevStep={prevStep}
					handleFormData={handleInputData}
					handleSelectFormData={handleSelectData}
					values={formData}
					finished={finish}
					merchantID={merchantID}
					handleCancel={handleCancel}
				/>
			);
		default:
			break;
	}
};

export default MultiStepForm;
