import PropTypes from "prop-types";
import React from "react";
import Pagination from "../../../../../components/paginator";
import { generateID } from "../../../../../utils/generateID";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { formattedDate } from "../../../../../utils/helpers";
import { OverFlowScrollBar } from "../../../../../components/overflowScroll/styles";
import { EmptyState } from "../../../../../components/emptyState";
import Button from "../../../../../components/button";
import { DefaultCurrencyImage } from "../../../../../assets";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  handlePageChange,
  meta,
  handleViewDetailsCrypto,
  handleDefault,
}) => {
  const returnTableRow = (data, i) => {
    return (
      <tr key={i}>
        <Td>
          <div>
            <input type={"checkbox"} key={i} />
          </div>
        </Td>
        <Td>
          <div>
            <strong>{data?.currency}</strong>
          </div>
        </Td>
        <Td theadColor={theadColor} tbodyColor={tbodyColor}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <strong style={{ color: "#1A1F36" }}>
              {formattedDate(data?.created_at)}
              {data.date}
            </strong>
          </div>
        </Td>
        <Td>
          <div>
            <span style={{ color: "#1A1F36" }}>{data.label}</span>
          </div>
        </Td>
        <CustomTd status={data?.default === true ? "success" : "pending"}>
          <div>{data?.default === true ? "Default" : "Not Set"}</div>
        </CustomTd>
        <CustomTd>
          <Button
            Styles
            text="View details"
            color={"#1A2CCE"}
            type="button"
            style={{
              padding: 0,
              textDecoration: "underline",
            }}
            onClick={() => handleViewDetailsCrypto(data?.id)}
          />
        </CustomTd>
        <CustomTd>
          <Button
            Styles
            text="Set as default"
            color={"#1A2CCE"}
            type="button"
            style={{
              padding: 0,
              textDecoration: "underline",
            }}
            onClick={() => handleDefault(data?.id)}
          />
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead?.map((head, i) => (
                    <th key={generateID(11)}>
                      {head === "" && i === 0 && <input type={"checkbox"} />}
                      {head.toUpperCase()}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination meta={meta} handlePageChange={handlePageChange} />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
};

export default CustomTable;
