import { Form, Input, Select } from "antd";
import { useAuthState } from "../../../../../../context/context";
import { Flex } from "../../../../../../components/box";

const FormLayout = ({ action, initialEditValues, handleSubmit }) => {
  const { currencies } = useAuthState();

  const fee_type = ["PERCENTAGE", "FLAT"];
  return (
    <>
      <h3
        style={{
          textAlign: "left",
          padding: "0 20px",
        }}
      >
        {action === "add" ? " Add new currency pair" : "Edit currency pair"}
      </h3>
      <>
        <Form
          id="currencyPair"
          onFinish={handleSubmit}
          // form={form}
          initialValues={{
            layout: "vertical",
            size: "large",
            to_currency:
              action === "edit" ? initialEditValues?.to_currency : null,
            from_currency:
              action === "edit" ? initialEditValues?.from_currency : null,
            buy_fee_type:
              action === "edit" ? initialEditValues?.buy_fee_type : null,
            sell_fee_type:
              action === "edit" ? initialEditValues?.sell_fee_type : null,
            sell_fee: action === "edit" ? initialEditValues?.sell_fee : null,
            buy_fee: action === "edit" ? initialEditValues?.buy_fee : null,
          }}
          layout={"vertical"}
        >
          <Flex justifyContent="space-between" padding="20px">
            <Form.Item
              label="Base currency"
              name="to_currency"
              rules={[
                {
                  required: true,
                  message: "base currency is required!",
                },
              ]}
            >
              <Select
                placeholder="Select currency to"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {currencies?.map((item) => (
                  <Select.Option value={item.code}>{item.code}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Quote currency"
              name="from_currency"
              rules={[
                {
                  required: true,
                  message: "quote currency is required!",
                },
              ]}
            >
              <Select
                placeholder="Select currency to"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {currencies?.map((item) => (
                  <Select.Option value={item.code}>{item.code}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>

          <Flex
            style={{
              background: "#F9F9F9",
              borderRadius: "0.863169px",
              marginTop: "10px",
            }}
            flexDir="column"
            alignItems="flex-start"
            padding="20px"
          >
            <h4>Fees</h4>
            <Flex justifyContent="space-between">
              <Form.Item
                label="Buying fee type"
                name="buy_fee_type"
                rules={[
                  {
                    required: true,
                    message: "buying fee type is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Select fee type"
                >
                  {fee_type.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Fee amount" name="sell_fee">
                <Input type="number" />
              </Form.Item>
            </Flex>
            <Flex justifyContent="space-between">
              <Form.Item
                label="Selling fee type"
                name="sell_fee_type"
                rules={[
                  {
                    required: true,
                    message: "selling fee type is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Select fee type"
                >
                  {fee_type.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Fee amount" name="buy_fee">
                <Input type="number" />
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      </>
    </>
  );
};

export { FormLayout };
