import * as yup from "yup";
import React, { useState } from "react";
import { Form, Field, ErrorMessage, Formik } from "formik";

import { Flex } from "../../../../components/box";
import Button from "../../../../components/button";
import {
  ActivityLog,
  SecureIcon,
  SecureIcon2,
  SessionIcon,
} from "../../../../assets";
import { ToastMessage, type } from "../../../../utils/toastr";
import ModalFrame from "../../../../components/modal/ModalFrame";
import {
  MainContainer,
  FormContainer,
  InputFlexBox,
  StylesContainer,
} from "./styles";
import { changePassword } from "../../../../services/Settings.service";
import { HiddenVisibilityIcon, PasswordEyeIcon } from "../../../../assets/";

import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../../components/input/styles";
import { Link } from "react-router-dom";

const Index = () => {
  const [visible, setVisible] = useState(false);
  const [visibility, setVisibility] = useState();
  const [visibility2, setVisibility2] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const initialValues = { password: "", confirmPassword: "" };

  const validateSchema = yup.object().shape({
    password: yup.string().required("Please enter old password"),
    confirmPassword: yup
      .string()
      .required("Please enter new password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = (values) => {
    const payload = {
      old_password: values.password,
      new_password: values.confirmPassword,
    };
    changePassword(payload)
      .then(() => {
        ToastMessage(type.SUCCESS, "password succesfully changed");
      })
      .catch((error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      })
      .finally(() => {
        setVisible(false);
      });
  };

  return (
    <>
      <StylesContainer>
        <MainContainer>
          <FormContainer>
            <h3>Secure your Account</h3>
            <InputFlexBox>
              <Flex className="holder">
                <Flex flexDir="row" className="box">
                  <SecureIcon />

                  <Flex
                    flexDir="column"
                    className="fa-box"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDir="row"
                      justifyContent="flex-start"
                      className="fa-text"
                    >
                      <p>Two-factor Authentication</p>
                      <p>Enabled</p>
                    </Flex>
                    <p>
                      Add extra security on your account to
                      <br /> authenticate your login and transactions.
                    </p>
                  </Flex>
                </Flex>
                <Flex
                  className="button-box"
                  flexDir="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="30%"
                >
                  <Button
                    Styles
                    text={"Disable 2FA"}
                    color={"#A50000"}
                    bgColor={"#FFD3D3"}
                    size="md"
                    border-radius="5px"
                    type="button"
                    onClick={() => {
                      ToastMessage(type.INFO, "2FA can not be disabled");
                    }}
                  />
                </Flex>
              </Flex>
              <Flex className="holder">
                <Flex flexDir="row" className="box-password">
                  <SecureIcon2 />
                  <Flex
                    flexDir="column"
                    className="fa-box"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDir="row"
                      justifyContent="flex-start"
                      className="fa-text"
                    >
                      <p>Change password</p>
                    </Flex>
                    <p>
                      Add extra security on your account to
                      <br /> authenticate your login and transactions.
                    </p>
                  </Flex>
                </Flex>
                <Flex
                  className="button-box-password"
                  flexDir="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="30%"
                >
                  <Button
                    Styles
                    text={"Change password"}
                    color={"#FFFFFF"}
                    bgColor={"#1A2CCE"}
                    size="md"
                    border-radius="5px"
                    type="button"
                    onClick={showModal}
                  />
                </Flex>
              </Flex>
            </InputFlexBox>
          </FormContainer>
        </MainContainer>
        <MainContainer>
          <FormContainer>
            <InputFlexBox>
              <Flex className="holder">
                <Flex flexDir="row" className="box">
                  <ActivityLog />

                  <Flex
                    flexDir="column"
                    className="fa-box"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDir="row"
                      justifyContent="flex-start"
                      className="fa-text"
                    >
                      <p>Activities</p>
                    </Flex>
                    <p>
                      Monitor and manage all activities that
                      <br />
                      has been performed on your account.
                    </p>
                  </Flex>
                </Flex>
                <Flex
                  className="button-box"
                  flexDir="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="30%"
                >
                  <Link
                    to="/dashboard/settings/security/activities"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#1A2CCE",
                      color: "#FFFFFF",
                      padding: "16px 28px",
                      width: "182px",
                      height: "50px",
                    }}
                  >
                    View activities
                  </Link>
                </Flex>
              </Flex>
              <Flex className="holder">
                <Flex flexDir="row" className="box-password">
                  <SessionIcon />
                  <Flex
                    flexDir="column"
                    className="fa-box"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDir="row"
                      justifyContent="flex-start"
                      className="fa-text"
                    >
                      <p>Sessions</p>
                    </Flex>
                    <p>
                      Track and manage your sessions and
                      <br />
                      connected devices.
                    </p>
                  </Flex>
                </Flex>
                <Flex
                  className="button-box-password"
                  flexDir="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="30%"
                >
                  <Link
                    to="/dashboard/settings/security/session"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#1A2CCE",
                      color: "#FFFFFF",
                      padding: "16px 28px",
                      width: "182px",
                      height: "50px",
                    }}
                  >
                    Manage sessions
                  </Link>
                </Flex>
              </Flex>
            </InputFlexBox>
          </FormContainer>
        </MainContainer>
      </StylesContainer>
      <ModalFrame
        visible={visible}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        footer={null}
        okButtonProps={{
          form: "change-password",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <FormContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values: { password, confirmPassword },
            }) => (
              <Form>
                <Flex flexDir={"column"} gap={"30px"}>
                  <InputStyle>
                    <Label htmlFor="password">Enter old password </Label>
                    <FieldWrapper btn={"btn"}>
                      <Field
                        type={visibility ? "text" : "password"}
                        onChange={handleChange}
                        name={"password"}
                        value={password}
                        onBlur={handleBlur}
                        id="password"
                      />
                      <button onClick={() => setVisibility(!visibility)}>
                        {visibility ? (
                          <PasswordEyeIcon width={"20px"} height={"20px"} />
                        ) : (
                          <HiddenVisibilityIcon
                            width={"20px"}
                            height={"20px"}
                          />
                        )}
                      </button>
                    </FieldWrapper>
                    <ErrorMessage name={"password"} component={"div"} />
                  </InputStyle>
                  <InputStyle>
                    <Label htmlFor="confirmPassword">Enter new password </Label>
                    <FieldWrapper btn={"btn"}>
                      <Field
                        type={visibility2 ? "text" : "password"}
                        onChange={handleChange}
                        name={"confirmPassword"}
                        value={confirmPassword}
                        id="confirmPassword"
                        onBlur={handleBlur}
                      />
                      <button onClick={() => setVisibility2(!visibility2)}>
                        {visibility2 ? (
                          <PasswordEyeIcon width={"20px"} height={"20px"} />
                        ) : (
                          <HiddenVisibilityIcon
                            width={"20px"}
                            height={"20px"}
                          />
                        )}
                      </button>
                    </FieldWrapper>
                    <ErrorMessage name={"confirmPassword"} component={"div"} />
                  </InputStyle>
                  <Button
                    text={"Change Password"}
                    bgColor={"#1A2CCE"}
                    color={"#fff"}
                    size="md"
                    fullwidth
                    type="submit"
                    borderRadius={"5px"}
                    loading={isSubmitting}
                  />
                </Flex>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </ModalFrame>
    </>
  );
};

export default Index;
