import React from "react";
import { useQuery } from "react-query";

import { Wrapper } from "../styles";
import CustomTable from "../../table";
import Search from "../../../../../components/search";
import { FiatTableHead } from "../../../../../utils/constants";
import { Flex, Container } from "../../../../../components/box";
import ModalFrame from "../../../../../components/modal/ModalFrame";

import {
  ApproveTransactionSummary,
  QRScreen,
} from "../../approvalTransactionSummary/ApproveTransactionSummary";
import { Loader } from "../../../../../components/loader";
import { getRejectedApprovalsCrypto } from "../../../../../services/Approval.service";

const Index = () => {
  const [visible, setVisible] = React.useState(false);
  const [qrCode, setQrCode] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [input, setInput] = React.useState("");

  const [filter, setFilter] = React.useState({ page: 1 });

  const approvalsCrypto = useQuery(
    "getRejectedApprovalsCrypto",
    async () => await getRejectedApprovalsCrypto(filter)
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setInput(value);
    setFilter({ ...filter, ref: value });

    setTimeout(() => approvalsCrypto.refetch());
  };

  const showModal = (e, status) => {
    setVisible(true);
    setId(e);
    setStatus(status);
  };

  const handleqrCode = () => {
    setQrCode(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleCancelqr = () => {
    setQrCode(false);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => approvalsCrypto.refetch());
  };

  if (approvalsCrypto.isLoading) {
    return <Loader />;
  }

  if (approvalsCrypto.status === "error") {
    return <span>Error: {approvalsCrypto.error.message}</span>;
  }

  return (
    <>
      <Wrapper>
        <Flex
          justifyContent="space-between"
          pad="20px 0"
          className="input_container"
        >
          <Container width={"30%"}>
            <Search
              placeholder={"Search reference no."}
              input={input}
              setInput={setInput}
              handleSearch={handleSearch}
            />
          </Container>
        </Flex>
        <CustomTable
          paginator
          bottomGap="10px"
          theadBkColor="#F1F4F8"
          rowHovColor="#d2ccc626"
          theadColor="#000A62"
          tbodyColor="#141414"
          handleApprove={showModal}
          handleReject={showModal}
          handlePageChange={handlePageChange}
          tableHead={FiatTableHead}
          meta={approvalsCrypto?.data?.data?.data?.meta}
          tableBody={approvalsCrypto?.data?.data?.data?.data}
        />
      </Wrapper>

      <ModalFrame
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={null}
      >
        <ApproveTransactionSummary
          approvalsFiat={approvalsCrypto}
          id={id}
          handleQrScreen={handleqrCode}
          status={status}
          setVisible={setVisible}
        />
      </ModalFrame>
      <ModalFrame
        visible={qrCode}
        handleOk={handleOk}
        handleCancel={handleCancelqr}
        footer={null}
      >
        <QRScreen />
      </ModalFrame>
    </>
  );
};

export default Index;
