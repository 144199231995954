import styled from "styled-components";

export const Styles = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 30px 0px;
  @media (max-width: 700px) {
    padding: 20px 4%;
  }
`;
