import { InputNumber, Radio, Select, Space } from "antd";
import React from "react";
import { RightArrowIcon } from "../../assets";
import { toTitleCase } from "../../utils/helpers";
import { Flex } from "../box";
import Button from "../button";
import PopOver from "../popover/PopOver";
import { PopDetails, Styles, SelectContainer } from "./styles";

const BulkAction = ({
  selectedItemCount,
  handleSelectedClear,
  onChangeTransaction,
  transactionStatusValue,
  onChangeAction,
  actionValue,
  onChangeExport,
  onChangeRate,
  marketRate,
  fileExport,
  approval,
  setVisible,
}) => {
  return (
    <Styles>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        className="selection"
      >
        <p>
          {selectedItemCount} item{selectedItemCount > 1 && "s"} selected
        </p>

        <Button
          Styles
          text={"Clear selection"}
          color={"#0019ff"}
          size="md"
          type="button"
          onClick={handleSelectedClear}
        />
      </Flex>
      <PopOver
        type="multiSelect"
        title="Date"
        placement="top"
        selected={
          (actionValue === "" && "Bulk Action") ||
          (actionValue === "transaction" &&
            toTitleCase(actionValue) + " (" + transactionStatusValue + ")") ||
          (actionValue === "market rate" &&
            toTitleCase(actionValue) + " (" + marketRate + ")") ||
          (actionValue === "export" &&
            toTitleCase(actionValue) + " (" + fileExport + ")")
        }
      >
        <PopDetails>
          <Flex
            flexDir="column"
            alignItem="flex-start"
            justifyContent="flex-start"
          >
            <Radio.Group
              onChange={onChangeAction}
              value={actionValue}
              size="large"
            >
              <Flex
                justifyContent="space-between"
                style={{ marginBottom: "20px" }}
              >
                <Radio value="transaction" size="large">
                  Set transaction as
                </Radio>
                <SelectContainer width="auto">
                  <p
                    className={
                      (transactionStatusValue === "Approve" && "success") ||
                      (transactionStatusValue === "Reject" && "failed")
                    }
                  >
                    {transactionStatusValue}
                  </p>

                  <PopOver type="set">
                    <PopDetails width="fit-content">
                      <Flex flexDir="column" alignItem="flex-start" id="status">
                        <Radio.Group
                          onChange={onChangeTransaction}
                          value={transactionStatusValue}
                          size="large"
                        >
                          <Space direction="vertical" size="middle">
                            <Radio value="Approve" id="success">
                              Approve
                            </Radio>
                            <Radio value="Reject" id="failed">
                              Reject
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Flex>
                    </PopDetails>
                  </PopOver>
                </SelectContainer>
              </Flex>
              {!approval &
              (
                <>
                  <Flex
                    justifyContent="space-between"
                    style={{ marginBottom: "20px" }}
                  >
                    <Radio value="market rate" size="large">
                      Set market rate
                    </Radio>
                    <InputNumber
                      defaultValue={marketRate}
                      onChange={onChangeRate}
                    />
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Radio value="export" size="large">
                      Export as
                    </Radio>
                    <Flex width="50%" justifyContent="flex-end">
                      <Select
                        defaultValue={fileExport}
                        bordered={false}
                        showArrow={false}
                        width="100%"
                        onChange={onChangeExport}
                      >
                        <Select.Option value="CSV">CSV</Select.Option>
                        <Select.Option value="PDF">PDF</Select.Option>
                      </Select>
                      <RightArrowIcon width={"20"} height={"10"} />
                    </Flex>
                  </Flex>
                </>
              )}
            </Radio.Group>
          </Flex>
        </PopDetails>
      </PopOver>

      <Button
        Styles
        text={"Apply "}
        bgColor={"#E7F1FF"}
        color={"#1A2CCE"}
        size="sm"
        type="button"
        onClick={() => setVisible(true)}
        className="apply-action"
      />
    </Styles>
  );
};

export default BulkAction;
