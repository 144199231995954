import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { Flex } from '../../../../components/box';
import { ToastMessage, type } from '../../../../utils/toastr';
import { Styles } from './styles';

const CustomDropdown = ({
	showModal,
	actionTaken,
	handleToggle,
	selectedIndex
}) => {
	return (
		<>
			<Styles width='70%'>
				<Flex alignItems='flex-end' justifyContent='flex-end'>
					<Dropdown.Button
						onClick={
							selectedIndex?.length > 0
								? showModal
								: () => ToastMessage(type.INFO, 'Please select transactions')
						}
						sizes='large'
						overlay={
							<Menu>
								<Menu.Item key='0' onClick={() => handleToggle('approve')}>
									Approve
								</Menu.Item>
								<Menu.Item key='1' onClick={() => handleToggle('reject')}>
									Reject
								</Menu.Item>
							</Menu>
						}
						placement='bottom'
						trigger={['click']}
						icon={<DownOutlined />}
					>
						{actionTaken === '' ? 'approve' : actionTaken}
					</Dropdown.Button>
				</Flex>
			</Styles>
		</>
	);
};

export default CustomDropdown;
