import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { generateID } from "../../../../../../utils/generateID";
import Pagination from "../../../../../../components/paginator";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { OverFlowScrollBar } from "../../../../../../components/overflowScroll/styles";
import { EyeIcon, TopUpIcon, EditIcon } from "../../../../../../assets";
import { EmptyState } from "../../../../../../components/emptyState";
import { formattedDate } from "../../../../../../utils/helpers";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  handleDecline,
  handleEdit,
  source,
  handleApprove,
  handlePageChange,
  meta,
  pageSize,
  firstLast,
  prevNext,
}) => {
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (items) => {
    setPageOfItems(items);
  };
  const returnTableRow = (data, i) => {
    return (
      <tr key={data.id}>
        <Td>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "row",
              // justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDir: "row",
                justifyContent: "center",
                background: "#F3F3F3",
                borderRadius: "100%",
                width: "25px",
                height: "25px",
                marginRight: "16px",
              }}
            >
              {/* <img
                src={require("../../../../assets/images/" + data.flag)}
                alt=""
                srcset=""
              /> */}
            </div>
            {data.currency_pair}
          </div>
        </Td>
        <Td>{data.merchant.business_name}</Td>
        <Td>{formattedDate(data.created_at)}</Td>
        <Td>{data.credit_cap}</Td>
        <CustomTd>
          <Link to={`/dashboard/liquidity/${data.id}`}>
            <div>
              <div className="icon">
                <EyeIcon width="14" height="14" />
              </div>
              View info
            </div>
          </Link>
        </CustomTd>
        {/* <CustomTd status={data.status}>
          <div className="status">
            <span>{data.status}</span>
          </div>
        </CustomTd> */}
        <CustomTd>
          <Link
            to={`#`}
            onClick={() =>
              source === "request"
                ? handleApprove(data.id, data.status)
                : handleEdit(data.id)
            }
          >
            <div>
              <div className="icon">
                <TopUpIcon />
              </div>
              {source === "request" ? "Approve" : "Top Up"}
            </div>
          </Link>
        </CustomTd>
        <CustomTd>
          <Link
            to={`#`}
            onClick={() =>
              source === "request"
                ? handleDecline(data.id, data.status)
                : handleDecline(data.id, data.status)
            }
          >
            <div>
              <div className="icon">
                <EditIcon width="14" height="14" color="#8D8EA2" />
              </div>
              {source === "request"
                ? "Decline"
                : data.status === "active"
                ? "pause"
                : data.status === "pause" || "stoped"
                ? "activate"
                : null}
            </div>
          </Link>
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead?.map((head, i) => (
                    <th key={generateID(11)}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination
              // items={tableBody}
              // pageSize={pageSize || 5}
              // prevNext={prevNext || null}
              // onChangePage={onChangePage}
              // firstLast={firstLast || null}
              meta={meta}
              handlePageChange={handlePageChange}
            />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
  firstLast: PropTypes.any,
  paginator: PropTypes.any,
  prevNext: PropTypes.any,
};

export default CustomTable;
