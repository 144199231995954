import styled from 'styled-components';
import { Flex } from '../../../../components/box';

export const Styles = styled(Flex)`
	// .ant-btn-group
	.ant-dropdown-button {
		button {
			height: 44px;
			background: #1a2cce;
			color: #ffffff;
			border: none;
			border-left: 1px solid #5157eb;
		}

		span {
			text-transform: capitalize;
		}
	}
`;
