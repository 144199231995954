import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '../../box';
import { ListStyles } from '../styles';

import { useLocation } from 'react-router-dom';
import {
	ApiLogsIcon,
	ApprovalIcon,
	BalanceIcon,
	ComplianceIcon,
	DashboardIcon,
	DownloadIcon,
	FeesIcon,
	FxOpsIcon,
	MarketsIcon,
	MerchantsIcon,
	SettingsIcon,
	SettlementsIcon,
	TransactionIcon,
	Wallet
} from '../../../assets';

export const Overview = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles active={path === '/dashboard'}>
			<Link to={'/dashboard'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<DashboardIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Overview</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Balances = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles active={path === '/dashboard/balances'}>
			<Link to={'/dashboard/balances'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<BalanceIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Balances</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Approvals = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/approvals/pending' ||
				path.includes('/dashboard/approvals/pending/') ||
				path.includes('/dashboard/approvals/approved/') ||
				path.includes('/dashboard/approvals/rejected/')
			}
		>
			<Link to={'/dashboard/approvals/pending'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<ApprovalIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Approvals</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};
export const Refunds = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/refund' || path.includes('/dashboard/refund/')
			}
		>
			<Link to={'/dashboard/refund'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<DownloadIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Refund</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};
export const Wallets = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/wallets' || path.includes('/dashboard/wallets/')
			}
		>
			<Link to={'/dashboard/wallets'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<Wallet width='14px' height='14px' />
					</span>
					<span className='drawerText'>Wallets</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Transactions = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/transactions' ||
				path.includes('/dashboard/transactions/')
			}
		>
			<Link to={'/dashboard/transactions'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<TransactionIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Transactions</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Markets = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/markets' || path.includes('/dashboard/markets/')
			}
		>
			<Link to={'/dashboard/markets'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<MarketsIcon Icon width='20px' height='20px' />
					</span>
					<span className='drawerText'>Markets</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};
export const Fees = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={path === '/dashboard/fees' || path.includes('/dashboard/fees/')}
		>
			<Link to={'/dashboard/fees'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<FeesIcon width='20px' height='20px' />
					</span>
					<span className='drawerText'>Fees</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Liquidity = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/liquidity' || path.includes('/dashboard/liquidity')
			}
		>
			<Link to={'/dashboard/liquidity'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<SettlementsIcon Icon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Liquidity</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const FxOps = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={path === '/dashboard/fxOps' || path.includes('/dashboard/fxOps')}
		>
			<Link to={'/dashboard/fxOps'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<FxOpsIcon Icon width='20' height='20' />
					</span>
					<span className='drawerText'>Fx Ops</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Merchants = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/merchants' ||
				path.includes('/dashboard/merchants/')
			}
		>
			<Link to={'/dashboard/merchants'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<MerchantsIcon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Merchants</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};

export const Compliance = () => {
	let path = useLocation().pathname;
	const [collapsed, setCollapsed] = useState(false);
	const toggleCollapsed = () => setCollapsed(!collapsed);

	useEffect(() => {
		if (
			path === '/dashboard/compliance' ||
			path.includes('/dashboard/compliance/') ||
			path === '/dashboard/wallets-compliance' ||
			path.includes('/dashboard/wallets-compliance/')
		) {
			setCollapsed(true);
		}
	}, [path]);

	return (
		<ListStyles
			active={
				path === '/dashboard/compliance' ||
				path.includes('/dashboard/compliance/') ||
				path === '/dashboard/wallets-compliance' ||
				path.includes('/dashboard/wallets-compliance/')
			}
			style={{
				marginBottom: '36px'
			}}
			onClick={() => setCollapsed(!collapsed)}
		>
			<button onClick={toggleCollapsed}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<ComplianceIcon Icon width='14px' height='14px' />
					</span>
					<span className='drawerText' style={{ marginRight: '30px' }}>
						Compliance
					</span>
					<svg
						className='drawerText'
						width='11'
						height='7'
						viewBox='0 0 11 7'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clip-rule='evenodd'
							d='M0.292787 0.91564C0.480314 0.728169 0.734622 0.622853 0.999786 0.622853C1.26495 0.622853 1.51926 0.728169 1.70679 0.91564L4.99979 4.20864L8.29279 0.91564C8.38503 0.82013 8.49538 0.743947 8.61738 0.691538C8.73939 0.639129 8.87061 0.611543 9.00339 0.610389C9.13616 0.609236 9.26784 0.634537 9.39074 0.684818C9.51364 0.735099 9.62529 0.809352 9.71918 0.903245C9.81307 0.997137 9.88733 1.10879 9.93761 1.23169C9.98789 1.35458 10.0132 1.48626 10.012 1.61904C10.0109 1.75182 9.9833 1.88304 9.93089 2.00504C9.87848 2.12705 9.8023 2.23739 9.70679 2.32964L5.70679 6.32964C5.51926 6.51711 5.26495 6.62243 4.99979 6.62243C4.73462 6.62243 4.48031 6.51711 4.29279 6.32964L0.292787 2.32964C0.105316 2.14211 0 1.8878 0 1.62264C0 1.35748 0.105316 1.10317 0.292787 0.91564Z'
							fill='currentColor'
						/>
					</svg>
				</Flex>
			</button>
			{collapsed && (
				<Flex
					flexDir='column'
					style={{
						marginTop: '8px',
						marginLeft: '60px',
						alignItems: 'start',
						width: 'fit-content'
					}}
				>
					<Link to={'/dashboard/compliance'}>
						<Flex className='link_container' justifyContent='flex-start'>
							<span
								className='subDrawerText'
								style={{
									fontWeight:
										(path === '/dashboard/compliance' ||
											path.includes('/dashboard/compliance/')) &&
										700,
									color:
										(path === '/dashboard/compliance' ||
											path.includes('/dashboard/compliance/')) &&
										'#1A2CCE							'
								}}
							>
								Merchants
							</span>
						</Flex>
					</Link>
					<Link
						to={'/dashboard/wallets-compliance'}
						style={{
							marginTop: '8px'
						}}
					>
						<Flex className='link_container' justifyContent='flex-start'>
							<span
								className='subDrawerText'
								style={{
									fontWeight:
										(path === '/dashboard/wallets-compliance' ||
											path.includes('/dashboard/wallets-compliance/')) &&
										700,
									color:
										(path === '/dashboard/wallets-compliance' ||
											path.includes('/dashboard/wallets-compliance/')) &&
										'#1A2CCE'
								}}
							>
								Wallets
							</span>
						</Flex>
					</Link>
				</Flex>
			)}
		</ListStyles>
	);
};

export const Settings = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/settings' || path.includes('/dashboard/settings/')
			}
		>
			<Link to={'/dashboard/settings'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<SettingsIcon Icon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Settings</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};
export const Apilog = () => {
	let path = useLocation().pathname;

	return (
		<ListStyles
			active={
				path === '/dashboard/apilog' || path.includes('/dashboard/apilog/')
			}
		>
			<Link to={'/dashboard/apilog'}>
				<Flex className='link_container' justifyContent='flex-start'>
					<span className='icon_container'>
						<ApiLogsIcon Icon width='14px' height='14px' />
					</span>
					<span className='drawerText'>Api Logs</span>
				</Flex>
			</Link>
		</ListStyles>
	);
};
