import apiClient from './axios';

const EndPoints = {
	totalWalletBalance(merchantId) {
		let query = `/admin/wallets/summary?column=amount&math=sum`;

		if (merchantId) {
			query += `&merchantId=${merchantId}`;
		}
		return query;
	},
	totalWalletTransactions(merchantId) {
		let query = `/admin/wallets/summary?column=amount&math=count`;

		if (merchantId) {
			query += `&merchantId=${merchantId}`;
		}
		return query;
	},
	allFiatAccount(ref) {
		return `/admin/wallets/${ref}/accounts?order=DESC&page=1&take=10&type=fiat`;
	},
	allCryptoAccount(ref) {
		return `/admin/wallets/${ref}/accounts?order=DESC&page=1&take=10&type=crypto`;
	},
	allWallet(filterData) {
		let query = `/admin/wallets?order=DESC&take=10`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.take) {
		// 	query += `&take=${filterData.take}`;
		// }
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}

		return query;
	},
	allWalletMerchant(merchantId, filterData) {
		let query = `/admin/wallets?order=DESC&take=10`;
		if (merchantId) {
			query += `&merchantId=${merchantId}`;
		}

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		// if (filterData.take) {
		// 	query += `&take=${filterData.take}`;
		// }
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.from) {
			query += `&from=${filterData.from}`;
		}
		if (filterData.to) {
			query += `&to=${filterData.to}`;
		}
		if (filterData.currency) {
			query += `&currency=${filterData.currency}`;
		}

		return query;
	},
	createWallet(merchantID) {
		return `/admin/wallets?merchantId=${merchantID}`;
	},
	updateWallet(walletRef) {
		return `/admin/wallets/${walletRef}/kyc`;
	},
	approveRejectCompliance(walletRef) {
		return `/admin/wallets/${walletRef}/compliance/status`;
	},
	createAccount(wallet_reference) {
		return `/admin/wallets/${wallet_reference}`;
	},
	walletByRef(wallet_reference) {
		return `/admin/wallets/${wallet_reference}`;
	},
	getWalletTransactionValue(walletID) {
		return `/admin/wallets/${walletID}/summary?column=amount&type=WITHDRAWAL&math=sum`;
		// https://apistaging.swapsta.com/admin/wallets/2nsodq5b67prv776tw0g9/summary?column=amount&type=WITHDRAWAL&math=sum
	},
	wallet(walletID) {
		return `/admin/wallets/${walletID}`;
	},
	walletTransactionCount(walletID) {
		return `/admin/wallets/${walletID}/summary?column=amount&math=count`;
	},
	walletTransactions(walletID) {
		return `/admin/wallets/${walletID}/summary?column=amount&type=WITHDRAWAL&math=sum`;
	},
	walletTransactionsDeposit(walletID) {
		return `/admin/wallets/${walletID}/summary?column=amount&type=DEPOSIT&math=sum`;
	}
};

export const getTotalWalletBalance = async (merchantID) => {
	const res = await apiClient.get(EndPoints.totalWalletBalance(merchantID));
	return res;
};

export const getAllWalletMerchant = async (merchantID, filter) => {
	const res = await apiClient.get(
		EndPoints.allWalletMerchant(merchantID, filter)
	);
	return res;
};
export const getWalletByRef = async (ref) => {
	const res = await apiClient.get(EndPoints.walletByRef(ref));
	return res;
};
export const getAllWallet = async (filter) => {
	const res = await apiClient.get(EndPoints.allWallet(filter));
	return res;
};
export const getAllWalletCount = async (merchantID) => {
	const res = await apiClient.get(EndPoints.allWallet(merchantID));
	return res;
};
export const getWalletTransactionValue = async (walletID) => {
	const res = await apiClient.get(
		EndPoints.getWalletTransactionValue(walletID)
	);
	return res;
};
export const getWalletById = async (walletID) => {
	const res = await apiClient.get(EndPoints.wallet(walletID));
	return res;
};
export const getWalletTransaction = async (walletID) => {
	const res = await apiClient.get(EndPoints.walletTransactions(walletID));
	return res;
};
export const getWalletTransactionDeposit = async (walletID) => {
	const res = await apiClient.get(
		EndPoints.walletTransactionsDeposit(walletID)
	);
	return res;
};
export const getWalletTransactionCount = async (walletID, filterData) => {
	const res = await apiClient.get(
		EndPoints.walletTransactionCount(walletID, filterData)
	);
	return res;
};

export const getTotalTransactions = async (merchantID) => {
	const res = await apiClient.get(
		EndPoints.totalWalletTransactions(merchantID)
	);
	return res;
};
export const createWallet = async (merchantID, payload) => {
	const res = await apiClient.post(EndPoints.createWallet(merchantID), payload);
	return res;
};
export const updateWalletKYC = async (walletRef, payload) => {
	const res = await apiClient.post(EndPoints.updateWallet(walletRef), payload);
	return res;
};
export const approveRejectWalletCompliance = async (walletRef, payload) => {
	const res = await apiClient.post(
		EndPoints.approveRejectCompliance(walletRef),
		payload
	);
	return res;
};
export const createAccount = async (wallet_reference, payload) => {
	const res = await apiClient.post(
		EndPoints.createAccount(wallet_reference),
		payload
	);
	return res;
};

export const getAllFiatAccounts = async (ref) => {
	const res = await apiClient.get(EndPoints.allFiatAccount(ref));
	return res;
};
export const getAllCryptoAccounts = async (ref) => {
	const res = await apiClient.get(EndPoints.allCryptoAccount(ref));
	return res;
};
