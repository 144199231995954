import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import React from 'react';
import { generateID } from '../../../../../utils/generateID';
import Pagination from '../../../../../components/paginator';
import { CustomTableMain, Td, Paginator, CustomTd } from './styles';
import { OverFlowScrollBar } from '../../../../../components/overflowScroll/styles';
import {
	AcceptTransactionIcon,
	// AddSettlementIcon,
	// Approve,
	DotIcon,
	RefreshTransactionIcon,
	// Reject,
	RejectTransactionIcon,
	UserAvatar,
	ViewTransactionIcon
} from '../../../../../assets';
import PopOver from '../../../../../components/popover/PopOver';
import { Link, useLocation } from 'react-router-dom';

import { formattedDate, formattedTime } from '../../../../../utils/helpers';
import { EmptyState } from '../../../../../components/emptyState';
import Button from '../../../../../components/button';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	tableHead2,
	widthdrawnTo,
	handleAddSettlementTime,
	merchantTran,
	handleReject,
	handleApprove,
	setSelectedIndex,
	selectedIndex,
	setActionTaken,
	actionTaken,
	setVisible,
	meta,
	handlePageChange,
	handleActivateAndDeactivate
}) => {
	// const swapPath = '/dashboard/transactions';
	// const withdrawalsPath = '/dashboard/transactions/withdraws';
	// const depositPath = '/dashboard/transactions/deposits';

	// let path = useLocation().pathname;
	// let pathLocator =
	// 	path.split('/')[3] === undefined ? 'swap' : path.split('/')[3];

	const handleSelectedItem = (e, index) => {
		console.log(index);
		const prevId = selectedIndex.filter((id) => id === index);
		if (e.target.checked && index !== prevId) {
			setSelectedIndex((prevSelectedIndex) => [...prevSelectedIndex, index]);
		} else {
			setSelectedIndex(selectedIndex.filter((item) => item !== index));
		}
	};

	const returnTableRow = (data, i) => {
		// console.log(data);
		// const customId = data?.reference?.substring(
		// 	data?.reference.indexOf('-') + 1
		// );
		// console.log(
		// 	data
		// 	// // data.reference.substring(data.reference.indexOf('-') + 1),
		// 	// // customId?.replace('-', '')
		// 	// // .
		// 	// // startsWith('')
		// 	// encodeURIComponent(data?.id),
		// 	// window.btoa(data?.id)
		// );

		return (
			<tr key={i}>
				<Td>
					<div>
						<input
							type={'checkbox'}
							onChange={(e) => handleSelectedItem(e, i)}
							// onChange={(e) => handleSelectedItem(e, data?.id)}
							key={i}
							// checked={selectedIndex?.includes(i) ? true : false}
							// checked={selectedIndex?.includes(data?.id) ? true : false}
						/>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.provider}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.user}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.currency.join(', ')}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.note}</span>
					</div>
				</Td>
				{tableHead.includes('expires in') ? (
					<Td>
						<span style={{ color: i === 0 && 'red' }}>{data?.expiresIn}</span>
					</Td>
				) : null}
				<CustomTd status={data?._status}>
					<Button
						Styles
						text={data?.status ? 'Deactivate' : 'Activate'}
						// bgColor={'#E7F1FF'}
						color={data?.status ? '#C12300' : '#1A2CCE'}
						size='sm'
						fullwidth
						style={{
							textDecoration: 'underline'
						}}
						type='button'
						onClick={() => handleActivateAndDeactivate(data)}
						// loading={loading}
					/>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{(tableHead2 ? tableHead2 : tableHead).map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
