import apiClient from './axios';

const EndPoints = {
	getRequestSummary: `/admin/summary/liquidity?status=pending&math=count`,
	getActiveSummary: `/admin/summary/liquidity?status=active&math=count`,
	getAmountUsedSummary: `/admin/summary/liquidity?column=amount_used&math=sum`,
	getAmountSettledSummary: `/admin/summary/liquidity?column=amount_settled&math=sum`,
	addLiquidity: `/admin/liquidity`,

	getLiquidity(filterData) {
		let query = `/admin/liquidity?status=active,stoped,paused&take=10`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}

		if (filterData.pair) {
			query += `&pair=${filterData.pair}`;
		}
		return query;
	},

	getLiquidityRequest(filterData) {
		let query = `/admin/liquidity?status=pending`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.pair) {
			query += `&pair=${filterData.pair}`;
		}
		return query;
	},

	getLiquidityById(id, filterData) {
		let query = `/admin/liquidity/${id}&take=10`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.pair) {
			query += `&pair=${filterData.pair}`;
		}
		return query;
	},

	getMerchantLiquidityById(id, filterData) {
		let query = `/admin/liquidity?merchantId=${id}&status=active,stoped,paused`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.pair) {
			query += `&pair=${filterData.pair}`;
		}
		return query;
	},

	getLiquidityReport(id, from, to) {
		return `/admin/liquidity/${id}/reports?from=${from}&to=${to}`;
	},

	topUp(id) {
		return `/admin/liquidity/topup/${id}`;
	},

	changeStatus(id) {
		return `/admin/liquidity/status/${id}`;
	},

	getRequestSummaryForMerchant(id) {
		return `/admin/summary/liquidity?status=pending&math=count&merchantId=${id} `;
	},

	getAmountUsedSummaryForMerchant(id) {
		return `/admin/summary/liquidity?column=amount_used&math=sum&merchantId=${id} `;
	},

	getAmountSettledSummaryForMerchant(id) {
		return `/admin/summary/liquidity?column=amount_settled&math=sum&merchantId=${id} `;
	}
};

export const getLiquidity = async (filterData) => {
	const res = await apiClient.get(EndPoints.getLiquidity(filterData));
	return res;
};

export const getLiquidityRequest = async (filterData) => {
	const res = await apiClient.get(EndPoints.getLiquidityRequest(filterData));
	return res;
};

export const getActiveSummary = async () => {
	const res = await apiClient.get(EndPoints.getActiveSummary);
	return res;
};

export const getLiquidityById = async (id, filterData) => {
	const res = await apiClient.get(EndPoints.getLiquidityById(id, filterData));
	return res;
};

export const getMerchantLiquidityById = async (id, filterData) => {
	const res = await apiClient.get(
		EndPoints.getMerchantLiquidityById(id, filterData)
	);
	return res;
};

export const getRequestSummaryForMerchant = async (id) => {
	const res = await apiClient.get(EndPoints.getRequestSummaryForMerchant(id));
	return res;
};

export const getAmountUsedSummaryForMerchant = async (id) => {
	const res = await apiClient.get(
		EndPoints.getAmountUsedSummaryForMerchant(id)
	);
	return res;
};

export const getAmountSettledSummaryForMerchant = async (id) => {
	const res = await apiClient.get(
		EndPoints.getAmountSettledSummaryForMerchant(id)
	);
	return res;
};

export const topUp = async (id, payload) => {
	const res = await apiClient.post(EndPoints.topUp(id), payload);
	return res;
};

export const changeStatus = async (id, payload) => {
	const res = await apiClient.post(EndPoints.changeStatus(id), payload);
	return res;
};

export const addLiquidity = async (payload) => {
	const res = await apiClient.post(EndPoints.addLiquidity, payload);
	return res;
};

export const getRequestSummary = async () => {
	const res = await apiClient.get(EndPoints.getRequestSummary);
	return res;
};

export const getAmountUsedSummary = async () => {
	const res = await apiClient.get(EndPoints.getAmountUsedSummary);
	return res;
};

export const getAmountSettledSummary = async () => {
	const res = await apiClient.get(EndPoints.getAmountSettledSummary);
	return res;
};

export const getLiquidityReport = async (id, from, to) => {
	const res = await apiClient.get(EndPoints.getLiquidityReport(id, from, to));
	return res;
};
