import {
	Card,
	Col,
	Form,
	Button as GenButton,
	Input,
	Row,
	Statistic,
	Typography,
	Button as WalletButton
} from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader } from '../../../../../components/loader';
import { Spacer } from '../../../../../components/spacer/styles';
import {
	FilterContainerStyles,
	InputStyles,
	StatisticStyle,
	Styles
} from './styles';
import CustomTable from './table';

import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { CiSearch } from 'react-icons/ci';
import Button from '../../../../../components/button';
import ModalFrame from '../../../../../components/modal/ModalFrame';
import { MerchantWalletTableHead } from '../../../../../utils/constants';

import { Flex } from '../../../../../components/box';
import {
	getAllCurrencies,
	getSettings
} from '../../../../../services/Settings.service';
import {
	createWallet,
	getAllWalletMerchant,
	getTotalTransactions,
	getTotalWalletBalance
} from '../../../../../services/Wallet.service';
import { ToastMessage, type } from '../../../../../utils/toastr';
import MultiStepForm from './MutiStepForm';
const { Text } = Typography;
const Wallets = ({ merchant, merchantID }) => {
	const [form] = Form.useForm();
	// const [input, setInput] = useState('');
	const [refValue, setRefValue] = useState('');
	const [filter, setFilter] = useState({ page: 1 });
	const [addVisible, setAddVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [deleteSuccess, setDeleteSuccess] = useState(false);
	const [success, setSuccess] = useState(false);
	const [currency, setCurrency] = useState('');
	const [current, setCurrent] = useState(1);
	const [currencies, setCurrencies] = React.useState([]);

	const checkToken = localStorage.getItem('access_token');
	const settings = useQuery(['getSettings'], async () => await getSettings(), {
		onError: (error) => <p> {error.response.data.message}</p>,
		enabled: !!checkToken,
		refetchOnMount: 'always',
		refetchOnWindowFocus: false
	});

	useQuery('getAllCurrencies', async () => await getAllCurrencies(), {
		onSuccess: (res) => {
			setCurrencies(res?.data?.data);
		},
		onError: (error) => <p> {error.response.data.mesaage}</p>,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!checkToken
	});

	const nextStep = () => {
		// increaseBar();
		setCurrent((current) => current + 1);
	};
	const prevStep = () => {
		// decreaseBar();
		setCurrent((current) => current - 1);
	};
	const queryClient = useQueryClient();

	const merchantWallets = useQuery(
		'getMerchantWallets',
		async () => await getAllWalletMerchant(merchantID, filter),
		{
			// refetchOnMount: false,
			refetchOnWindowFocus: false
		}
	);
	const merchantTotalBalance = useQuery(
		'getMerchantWalletBalance',
		async () => await getTotalWalletBalance(merchantID),
		{
			// refetchOnMount: false,
			refetchOnWindowFocus: false
		}
	);
	const merchantTotalTransactions = useQuery(
		'getMerchantTotalTransactions',
		async () => await getTotalTransactions(merchantID),
		{
			// refetchOnMount: false,

			refetchOnWindowFocus: false
		}
	);

	const createMutation = useMutation(
		async (data) => {
			await createWallet(merchantID, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantWallets');
				queryClient.invalidateQueries('getMerchantWalletBalance');
				queryClient.invalidateQueries('getMerchantTotalTransactions');
				queryClient.invalidateQueries('getSettings');

				setTimeout(
					() => {
						setAddVisible(false);
						ToastMessage(type.SUCCESS, 'Wallet successfully created');
						// setSuccess(true);
					},

					500
				);
				form.resetFields('');
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	// createWallet
	const handleSetDelete = (id) => {
		setDeleteVisible(true);
		// setMarketRateId(id);
	};
	const handleCancel = () => {
		setAddVisible(false);
		setDeleteVisible(false);
		setDeleteSuccess(false);
		setSuccess(false);
		setRefValue('');
		setCurrency('');
		setCurrent(1);
	};

	const handleDelete = async () => {
		setDeleteVisible(false);
		setDeleteSuccess(true);
		// try {
		//   await deleteMutation.mutate(marketRateId);
		// } catch (error) {
		//   ToastMessage(type.ERROR, error.response.data.message);
		// }
	};

	const handleSubmit = (values) => {
		values.wallet_reference = refValue;
		delete values.ref;
		// console.log(values);
		createMutation.mutate(values);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => merchantWallets.refetch());
	};

	if (
		merchantWallets.status === 'loading' ||
		merchantTotalTransactions.status === 'loading' ||
		merchantTotalBalance.status === 'loading'
	) {
		return <Loader />;
	}

	if (merchantWallets.status === 'error') {
		return <span>Error: {merchantWallets.error.message}</span>;
	}

	// console.log(settings?.data?.data?.data);
	return (
		<>
			<Styles>
				<Spacer height='32px' />
				<StatisticStyle>
					<Row
					// gutter={{
					// 	xs: 8,
					// 	sm: 16,
					// 	md: 24,
					// 	lg: 32
					// }}
					//  justify='space-between'
					>
						<Col>
							<Card>
								<Statistic
									title='Total Wallets Created'
									value={merchantWallets?.data?.data?.data?.meta?.itemCount}
								/>
							</Card>
						</Col>
						<Col>
							<Card>
								<Statistic
									title='Total Number of Transactions'
									value={merchantTotalTransactions?.data?.data?.data?.result}
								/>
							</Card>
						</Col>
					</Row>
				</StatisticStyle>
				<Spacer height='44px' />
				<FilterContainerStyles>
					<InputStyles>
						<Text>Search for a wallet</Text>
						<div className='input-box'>
							<Input placeholder='Search wallet' prefix={<CiSearch />} />
						</div>
					</InputStyles>

					<InputStyles>
						<WalletButton
							size='large'
							icon={<PlusOutlined />}
							onClick={() => setAddVisible(true)}
						>
							New wallet
						</WalletButton>
					</InputStyles>
				</FilterContainerStyles>

				<Spacer height='32px' />

				<CustomTable
					theadBkColor='#F1F4F8'
					bottomGap='10px'
					tableHead={MerchantWalletTableHead}
					tableBody={merchantWallets?.data?.data?.data?.data}
					rowHovColor='#d2ccc626'
					theadColor='#000A62'
					tbodyColor='#141414'
					handleDelete={handleSetDelete}
					merchantName={merchant}
					pageSize={10}
					paginator
					meta={merchantWallets?.data?.data?.data.meta}
					handlePageChange={handlePageChange}
				/>
			</Styles>

			<ModalFrame
				visible={addVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				{(current === 2 || current === 3) && (
					<WalletButton
						type='link'
						Icon={<ArrowLeftOutlined />}
						onClick={() => prevStep()}
					>
						<ArrowLeftOutlined /> Back
					</WalletButton>
				)}
				<p
					style={{
						textAlign: 'center',
						fontWeight: 500,
						fontSize: '18px',
						lineHeight: '23px',
						letterSpacing: '0.01em',
						color: '#000000',
						margin: 0,
						padding: 0
					}}
				>
					Add a new wallet
				</p>
				{settings?.data?.data?.data?.validate_wallet_compliance === true && (
					<>
						<p
							style={{
								textAlign: 'center',
								fontWeight: 400,
								fontSize: '12px',
								lineHeight: '16px',

								letterSpacing: '0.01em',

								color: '#666666',
								paddingTop: '10px'
							}}
						>
							{`Step ${current} of 3`}
						</p>
					</>
				)}
				<Spacer height='40px' />
				{settings?.data?.data?.data?.validate_wallet_compliance !== true ? (
					<FormNoCompliance
						handleCancel={handleCancel}
						createMutation={createMutation}
						handleSubmit={handleSubmit}
						refValue={refValue}
						setRefValue={setRefValue}
						form={form}
						bvn={settings?.data?.data?.data?.wallet_compliance_bvn}
					/>
				) : (
					<MultiStepForm
						merchantID={merchantID}
						current={current}
						prevStep={prevStep}
						nextStep={nextStep}
						finish={setAddVisible}
						handleCancel={handleCancel}
						bvn={settings?.data?.data?.data?.wallet_compliance_bvn}
					/>
				)}
			</ModalFrame>
			<ModalFrame
				visible={deleteVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Yes, delete'}
						bgColor={'#D80027'}
						color='#FFFFFF'
						size='md'
						type='button'
						// loading={deleteMutation.isLoading}
						onClick={handleDelete}
					/>
				]}
			>
				<h3
					style={{
						textAlign: 'left',
						fontWeight: 600,
						fontSize: '18.1764px',
						lineHeight: '22px',
						color: '#000A62'
					}}
				>
					Delete this wallet?
				</h3>
				<p
					style={{
						textAlign: 'left',

						fontWeight: 400,
						fontSize: '15px',
						lineHeight: '24px',

						letterSpacing: '0.02em',

						color: '#1A1F36'
					}}
				>
					Funds stored in deleted wallets cannot be recovered.
				</p>
			</ModalFrame>
		</>
	);
};

export default Wallets;

const FormNoCompliance = ({
	createMutation,
	currencies,
	handleCancel,
	handleSubmit,
	setRefValue,
	refValue,
	tab,
	form,
	bvn
}) => {
	const generateRef = () => {
		const result = [...Array(2)]
			.map((value) => (Math.random() * 1000).toString(36).replace('.', ''))
			.join('');

		setRefValue(result);
	};
	return (
		<>
			<Form
				layout='vertical'
				onFinish={handleSubmit}
				// id='createWallet'
				initialValues={{
					layout: 'vertical',
					size: 'large'
				}}
				form={form}
			>
				<Form.Item
					label='Email'
					name='email'
					rules={[{ required: true, message: 'Email  is required' }]}
				>
					<Input placeholder='Enter Email' type='email' />
				</Form.Item>
				{bvn === true && (
					<Form.Item
						label='BVN'
						name='bvn'
						rules={[{ required: true, message: 'BVN  is required' }]}
					>
						<Input placeholder='Enter BVN' type='text' />
					</Form.Item>
				)}
				<Form.Item
					label='Phone number'
					name='phone'
					rules={[{ required: true, message: 'Phone number is required' }]}
				>
					<Input placeholder='Enter Phone number' />
				</Form.Item>

				<Form.Item
					label='Reference'
					name='wallet_reference'
					// rules={[{ required: true, message: 'Reference No. is required' }]}
				>
					<Flex
						// compact
						style={{
							border: '1px solid #D7D7D7',
							borderRadius: '5px'
						}}
					>
						<Input
							style={{
								width: 'calc(100% - 98px)'
							}}
							placeholder='Enter or generate reference no.'
							bordered={false}
							// value={values.wallet_ref === '' ? refValue : values.wallet_ref}
							// value={refValue !== '' ? holdValue?.wallet_ref : refValue}
							value={refValue}
							// onChange={handleOnChange}
							// onChange={handleOnChange}
						/>

						<GenButton
							style={{
								width: '98px',
								height: '46px',
								background: '#F1F6FE',
								borderRadius: '0px 5px 5px 0px',
								border: 'none'
							}}
							onClick={generateRef}
						>
							Generate
						</GenButton>
					</Flex>
				</Form.Item>
				<Flex justifyContent='flex-end' marginTop='40px'>
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>

					<Button
						Styles
						text={'Submit'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='submit'
						key='submit'
						// form='createWallet'
						borderRadius='5px'
						loading={createMutation?.isLoading}
					/>
				</Flex>
			</Form>
		</>
	);
};
