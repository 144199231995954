import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const Styles = styled.div`
  .select {
    .ant-select-selector {
      color: #000000;
      border: none;
    }
  }
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .fiter_container {
    gap: 30px;
    justify-content: space-between;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    /* Normal-side-panel text */

    color: #1a1f36;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px 10px;
    .fiter_container {
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      flex-wrap: wrap;
      gap: 15px 5px;
      /* border: 1px solid red; */
    }
    .input_container {
      width: 100%;
    }
  }

  input {
    z-index: 3	;
  }
`;

export const SelectContainer = styled(Flex)`
  justify-content: space-between;
  background-color: #fff;
  padding: 0 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 0.7px solid #dfdfdf;
  > span {
    font-size: 12px;
    color: #969696;
  }
`;

export const FormContainer = styled("div")`
  // padding: 2rem;
  // h3 {
  // 	ont-style: normal;
  // 	font-weight: 400;
  // 	font-size: 18px;
  // 	line-height: 23px;
  // 	text-align: center;
  // 	color: #000000;
  // }
  // p {
  // 	font-family: 'DM Sans';
  // 	font-style: normal;
  // 	font-weight: 400;
  // 	font-size: 16px;
  // 	line-height: 21px;
  // 	text-align: center;
  // 	color: #5b5b5b;
  // 	margin-bottom: 0;
  // }
  .ant-input,
  .ant-select .ant-select-selector {
    height: 46px !important;
    border-radius: 5px;
    // font-size: 16px;
    padding: 2px 11px !important;
  }
`;
