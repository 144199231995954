import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Card,
	Col,
	Form,
	Input,
	Row,
	Select,
	Statistic,
	Tabs,
	Typography,
	Button as WalletButton
} from 'antd';
import React, { useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import Layout from '../../../../components/layouts';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { Spacer } from '../../../../components/spacer/styles';
import {
	getAllCurrencies,
	getSettings
} from '../../../../services/Settings.service';
import {
	createAccount,
	getAllCryptoAccounts,
	getAllFiatAccounts,
	getWalletById,
	getWalletByRef,
	getWalletTransaction,
	// getWalletTransactionCount,
	getWalletTransactionDeposit
	// getWalletTransactionValue
} from '../../../../services/Wallet.service';
import {
	MerchantCryptoAccounTableHead,
	MerchantFiatAccounTableHead
} from '../../../../utils/constants';
import { ToastMessage, type } from '../../../../utils/toastr';
import Overview from './overveiw/index';
import {
	FilterContainerStyles,
	InputStyles,
	StatisticStyle,
	Styles,
	TabStyles,
	TabStyles2
} from './styles';
import CustomTable from './table';
const { Text } = Typography;

const Wallet = () => {
	const { walletID } = useParams();
	const queryClient = useQueryClient();
	const [form] = Form.useForm();
	const merchantName = useLocation()?.state?.merchantName;
	const navigate = useNavigate();
	const [refValue, setRefValue] = useState(false);
	const [addVisible, setAddVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [disableOrEnableVisible, setDisableOrEnableVisible] = useState(false);
	const [toggleStatus, setToggleStatus] = useState(false);
	const [currencies, setCurrencies] = React.useState([]);
	const [accountDeleteId, setAccountDeleteId] = useState(Number);
	const [accountDisabledOrEnabledId, setAccountDisabledOrEnabledId] =
		useState(Number);
	const [filter, setFilter] = useState({ page: 1 });

	const checkToken = localStorage.getItem('access_token');

	useQuery(['getSettings'], async () => await getSettings(), {
		// onSuccess: (res) => {
		// 	setComplianceRequest(res?.data?.data?.validate_wallet_compliance);
		// },
		onError: (error) => <p> {error.response.data.message}</p>,
		enabled: !!checkToken,
		refetchOnMount: false,
		refetchOnWindowFocus: false
	});

	useQuery('getAllCurrencies', async () => await getAllCurrencies(), {
		onSuccess: (res) => {
			setCurrencies(res?.data?.data);
		},
		onError: (error) => <p> {error.response.data.message}</p>,
		// refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!checkToken
	});

	// const walletTransactionValue = useQuery(
	// 	'getWalletTransactionValue',
	// 	async () => await getWalletTransactionValue(walletID),
	// 	{
	// 		refetchOnMount: 'always'
	// 	}
	// );
	const wallet = useQuery(
		'getWallet',
		async () => await getWalletById(walletID),
		{
			refetchOnMount: 'always'
		}
	);
	// const merchantTotalBalance = useQuery(
	// 	'getMerchantWalletBalance',
	// 	async () => await getTotalWalletBalance(merchantID),
	// 	{
	// 		// onSuccess: (res) => {
	// 		// 	setBalance(res.data.data.result);
	// 		// 	setCurrencySign(res.data.data.currency.code);
	// 		// 	console.log(res);
	// 		// }
	// 	}
	// );
	// const walletTransaction = useQuery(
	// 	'getWalletTransactionCount',
	// 	async () => await getWalletTransactionCount(walletID)
	// );

	const walletTransactionsWithdrawn = useQuery(
		'getWalletTransactionWithdrawn',
		async () => await getWalletTransaction(walletID)
	);
	const walletTransactionsDeposit = useQuery(
		'getWalletTransactionDeposit',
		async () => await getWalletTransactionDeposit(walletID)
	);

	const walletInfo = useQuery(
		'getWalletInfo',
		async () => await getWalletByRef(walletID),
		{
			refetchOnWindowFocus: false
		}
	);

	const merchantFiatAccounts = useQuery(
		'getMerchantFiatAccounts',
		async () => await getAllFiatAccounts(walletID),
		{
			refetchOnWindowFocus: false
			// enabled: !!walletData?.ref
		}
	);
	const merchantCryptoAccounts = useQuery(
		'getMerchantCryptoAccounts',
		async () => await getAllCryptoAccounts(walletID),
		{
			// refetchOnMount: 'always',
			refetchOnWindowFocus: false
			// enabled: !!walletData?.ref
		}
	);

	const createMutation = useMutation(
		async (data) => {
			await createAccount(walletID, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getWalletInfo');
				queryClient.invalidateQueries('getMerchantFiatAccounts');
				queryClient.invalidateQueries('getMerchantCryptoAccounts');
				queryClient.invalidateQueries('getMerchantTotalTransactions');
				queryClient.invalidateQueries('getSettings');

				setTimeout(
					() => {
						setAddVisible(false);
						ToastMessage(type.SUCCESS, 'Account successfully created');
						// setSuccess(true);
					},

					500
				);
				form.resetFields('');
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const handlePageChangeF = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => merchantFiatAccounts.refetch());
	};
	const handlePageChangeC = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => merchantCryptoAccounts.refetch());
	};

	const handleSetDelete = (id, type) => {
		setDeleteVisible(true);
		setAccountDeleteId(id);
		console.log(id, type);
	};
	const handleSetDisabledOrEnabled = (id, status, type) => {
		setToggleStatus(status);
		setDisableOrEnableVisible(true);
		setAccountDisabledOrEnabledId(id);
		console.log(id, status, type);
	};

	const handleCancel = () => {
		setAddVisible(false);
		setDeleteVisible(false);
		setDisableOrEnableVisible(false);
	};

	const handleDelete = async () => {
		// setDeleteVisible(false);
		// setDeleteSuccess(true);
		// try {
		//   await deleteMutation.mutate(marketRateId);
		// } catch (error) {
		//   ToastMessage(type.ERROR, error.response.data.message);
		// }
	};

	const handleSubmit = (values) => {
		createMutation.mutate(JSON.stringify(values));
	};

	// walletTransactionsDeposit.isFetching ||
	// walletTransactionsWithdrawn.isFetching ||

	if (wallet.isFetching) {
		return <Loader />;
	}

	if (wallet.status === 'error') {
		return <span>Error: {wallet.error.message}</span>;
	}
	return (
		<Layout title={merchantName}>
			<Styles>
				<Spacer height='48px' />
				<Flex justifyContent='space-between'>
					<div
						className='backBtn'
						style={{
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '18px',

							color: '#000000',
							cursor: 'pointer'
						}}
						onClick={() => navigate(-1)}
						// to='/dashboard/merchants'
					>
						<ArrowLeftOutlined
							style={{
								marginRight: '9px'
							}}
						/>
						<span>Go Back To Wallets</span>
					</div>
					<Button
						Styles
						text='Delete wallet'
						color='#D80027'
						// color='#FFFFFF'
						type='button'
						// loading={deleteMutation.isLoading}
						// onClick={handleSetDelete}
						style={{
							padding: 0,
							textDecoration: 'underline'

							// paddingLeft: '50px',
							// paddingRight: '50px'
						}}
					/>
				</Flex>
				<Spacer height='56px' />

				<Spacer height='32px' />
				<StatisticStyle>
					<Row
					// gutter={{
					// 	xs: 8,
					// 	sm: 16,
					// 	md: 24,
					// 	lg: 32
					// }}
					//  justify='space-between'
					>
						<Col>
							<Card>
								<Statistic
									title='Total Volume Transacted'
									value={`$ ${(
										walletTransactionsDeposit?.data?.data?.data?.result +
										walletTransactionsWithdrawn?.data?.data?.data?.result
									)?.toLocaleString('en-US')}`}
								/>
							</Card>
						</Col>
						<Col>
							<Card>
								<Statistic
									title='Total Amount Deposited'
									value={`$ ${walletTransactionsDeposit?.data?.data?.data?.result?.toLocaleString(
										'en-US'
									)}`}
								/>
							</Card>
						</Col>
						<Col>
							<Card>
								<Statistic
									title='Total Amount Withdrawn'
									value={`$ ${walletTransactionsWithdrawn?.data?.data?.data?.result?.toLocaleString(
										'en-US'
									)}`}
								/>
							</Card>
						</Col>
					</Row>
				</StatisticStyle>

				<Spacer height='32px' />

				<Overview walletInfo={walletInfo?.data?.data?.data} />
				<Spacer height='44px' />
				<FilterContainerStyles>
					<InputStyles>
						<Text>Search for an account</Text>
						<div className='input-box'>
							<Input placeholder='Reference No.' prefix={<CiSearch />} />
						</div>
					</InputStyles>

					<InputStyles>
						<WalletButton
							size='large'
							icon={<PlusOutlined />}
							onClick={() => setAddVisible(true)}
						>
							New Account
						</WalletButton>
					</InputStyles>
				</FilterContainerStyles>
				<Spacer height='32px' />
				<TabStyles>
					<Tabs centered>
						<Tabs.TabPane tab='Fiat Accounts' key='1'>
							<Spacer height='32px' />
							{merchantFiatAccounts?.isFetching ? (
								<Loader />
							) : (
								<CustomTable
									theadBkColor='#F1F4F8'
									bottomGap='10px'
									tableHead={MerchantFiatAccounTableHead}
									tableBody={merchantFiatAccounts?.data?.data?.data?.data}
									rowHovColor='#d2ccc626'
									theadColor='#000A62'
									tbodyColor='#141414'
									pageSize={10}
									type='fiat'
									paginator
									meta={merchantFiatAccounts?.data?.data?.data.meta}
									handleDelete={handleSetDelete}
									handleDisabledOrEnabled={handleSetDisabledOrEnabled}
									handlePageChange={handlePageChangeF}
								/>
							)}
						</Tabs.TabPane>
						<Tabs.TabPane tab='Crypto Accounts' key='2'>
							<Spacer height='32px' />
							{merchantCryptoAccounts?.isFetching ? (
								<Loader />
							) : (
								<CustomTable
									theadBkColor='#F1F4F8'
									bottomGap='10px'
									tableHead={MerchantCryptoAccounTableHead}
									tableBody={merchantCryptoAccounts?.data?.data?.data?.data}
									rowHovColor='#d2ccc626'
									theadColor='#000A62'
									tbodyColor='#141414'
									pageSize={10}
									type='crypto'
									paginator
									meta={merchantCryptoAccounts?.data?.data?.data.meta}
									handleDelete={handleSetDelete}
									handleDisabledOrEnabled={handleSetDisabledOrEnabled}
									handlePageChange={handlePageChangeC}
								/>
							)}
						</Tabs.TabPane>
					</Tabs>
				</TabStyles>
			</Styles>
			<ModalFrame
				visible={addVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<p
					style={{
						textAlign: 'center',
						fontWeight: 500,
						fontSize: '18px',
						lineHeight: '23px',
						letterSpacing: '0.01em',
						color: '#000000',
						margin: 0,
						padding: 0
					}}
				>
					Create a new virtual account
				</p>
				<p
					style={{
						textAlign: 'center',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '24px',
						letterSpacing: '0.01em',
						color: '#8F8F8F',
						margin: 0,
						padding: 0
					}}
				>
					Create a new crypto or fiat virtual account
				</p>

				<Spacer height='40px' />
				<TabStyles2>
					<Tabs>
						<Tabs.TabPane tab='Crypto' key='1'>
							<NewAccountForm
								tab='Crypto'
								handleCancel={handleCancel}
								createMutation={createMutation}
								currencies={currencies}
								handleSubmit={handleSubmit}
								refValue={refValue}
								setRefValue={setRefValue}
								form={form}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab='Fiat' key='2'>
							<NewAccountForm
								tab='Fiat'
								handleCancel={handleCancel}
								createMutation={createMutation}
								currencies={currencies}
								handleSubmit={handleSubmit}
								refValue={refValue}
								setRefValue={setRefValue}
								form={form}
							/>
						</Tabs.TabPane>
					</Tabs>
				</TabStyles2>
			</ModalFrame>
			<ModalFrame
				visible={deleteVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Yes, delete'}
						bgColor={'#D80027'}
						color='#FFFFFF'
						size='md'
						type='button'
						// loading={deleteMutation.isLoading}
						onClick={handleDelete}
					/>
				]}
			>
				<h3
					style={{
						textAlign: 'left',
						fontWeight: 600,
						fontSize: '18.1764px',
						lineHeight: '22px',
						color: '#000A62'
					}}
				>
					Delete this account?
				</h3>
				<p
					style={{
						textAlign: 'left',

						fontWeight: 400,
						fontSize: '15px',
						lineHeight: '24px',

						letterSpacing: '0.02em',

						color: '#1A1F36'
					}}
				>
					Money stored in deleted accounts cannot be recovered.
				</p>
			</ModalFrame>
			<ModalFrame
				visible={disableOrEnableVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={`Yes, ${toggleStatus ? 'disable' : 'enable'}`}
						bgColor={toggleStatus ? '#121212' : '#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='button'
						// loading={deleteMutation.isLoading}
						// onClick={handleDelete}
					/>
				]}
			>
				<h3
					style={{
						textAlign: 'left',
						fontWeight: 600,
						fontSize: '18.1764px',
						lineHeight: '22px',
						color: '#000A62'
					}}
				>
					{toggleStatus ? 'Disable' : 'Enable'} this account?
				</h3>
				<p
					style={{
						textAlign: 'left',

						fontWeight: 400,
						fontSize: '15px',
						lineHeight: '24px',

						letterSpacing: '0.02em',

						color: '#1A1F36'
					}}
				>
					Money stored in{' '}
					{toggleStatus
						? 'disabled accounts will be temporarily frozen.'
						: 'in this account will be unfrozen.'}
				</p>
			</ModalFrame>
		</Layout>
	);
};

export default Wallet;

const NewAccountForm = ({
	createMutation,
	currencies,
	handleCancel,
	handleSubmit,
	setRefValue,
	refValue,
	tab,
	form
}) => {
	let currencyPerTab =
		tab === 'Crypto' ? currencies?.slice(5) : currencies?.slice(0, 5);

	return (
		<>
			<Form
				layout='vertical'
				onFinish={handleSubmit}
				form={form}
				// id='createWallet'
				initialValues={{
					layout: 'vertical',
					size: 'large'
				}}
			>
				<Form.Item
					label='Reference ID'
					name='account_reference'
					// rules={[{ required: true, message: 'Reference No. is required' }]}
				>
					<Input
						placeholder='Enter Reference ID'
						// onChange={handleOnChange}
						// onChange={handleOnChange}
					/>
				</Form.Item>
				<Form.Item
					label='Currency'
					name='currency'
					// rules={[
					// 	{
					// 		required: true,
					// 		message: 'Currency is required'
					// 	}
					// ]}
				>
					<Select
						placeholder={'Choose currency'}
						style={{
							width: '100%'
						}}
						// bordered={false}
						// defaultValue={values.currency}
						// onChange={handleSelectFormData('currency')}
					>
						{currencyPerTab?.map((item, i) => (
							<Select.Option className='select' value={item?.code} key={i}>
								{item?.code}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				{tab === 'Crypto' && (
					<Form.Item
						label='Network'
						name='chain'
						// rules={[{ required: true, message: 'Network is required' }]}
					>
						<Select
							placeholder='Choose network'
							style={{
								width: '100%'
							}}
						>
							<Select.Option value='TRX'>TRON</Select.Option>
							<Select.Option value='ETH'>ETH</Select.Option>
						</Select>
					</Form.Item>
				)}
				{tab === 'Fiat' && (
					<Form.Item
						label='Account Type'
						name='account_type'
						// rules={[{ required: true, message: 'Account Type is required' }]}
					>
						<Select
							placeholder='Choose account type'
							style={{
								width: '100%'
							}}
							onChange={(e) => {
								if (e !== 'static') {
									setRefValue(true);
								} else {
									setRefValue(false);
								}
							}}
						>
							<Select.Option value='static'>Static</Select.Option>
							<Select.Option value='dynamic'>Dynamic</Select.Option>
						</Select>
					</Form.Item>
				)}

				{refValue === true ? (
					<Form.Item
						label='Amount to be collected'
						name='amount'
						// rules={[{ required: true, message: 'Amount is required' }]}
					>
						<Input placeholder='USD 200' />
					</Form.Item>
				) : null}

				<Flex justifyContent='flex-end' marginTop='40px'>
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>

					<Button
						Styles
						text={'Create account'}
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='submit'
						key='submit'
						// form='createWallet'
						borderRadius='5px'
						loading={createMutation?.isLoading}
					/>
				</Flex>
			</Form>
		</>
	);
};
