import React, { useState } from "react";
import { Spacer } from "../../../../../components/spacer/styles";
import { Styles } from "./styles";
import CustomTable from "./table";

import Button from "../../../../../components/button";
import ModalFrame from "../../../../../components/modal/ModalFrame";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { FormLayout } from "./form";
import { useParams } from "react-router-dom";
import {
  merchantClearTeamsLimit,
  merchantRemoveTeams2fa,
  merchantTeams,
  merchantTeamsDelete,
  merchantTeamsResetPassword,
} from "../../../../../services/Merchants.service";
import { Loader } from "../../../../../components/loader";
import { Form, Input } from "antd";
import { ToastMessage, type } from "../../../../../utils/toastr";

const Team = () => {
  const queryClient = useQueryClient();
  let { merchant } = useParams();

  const [visible, setVisible] = React.useState(false);
  const [twoFaVisible, setTwoFaFaVisible] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [actionType, setActionType] = React.useState(null);
  const [initialEditValues, setInitialEditValues] = useState({});

  const result = useQuery(
    "merchantTeams",
    async () => await merchantTeams(merchant)
  );

  console.log(result?.data?.data?.data?.data, "");
  
  const reset2faMutation = useMutation(
    async (data) => {
      await merchantRemoveTeams2fa(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "2FA reset successful");
        setTimeout(() => setTwoFaFaVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const clearlimitMutation = useMutation(
    async (data) => {
      await merchantClearTeamsLimit(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "limit cleared successfully");
        setTimeout(() => setTwoFaFaVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const passwordResetMutation = useMutation(
    async (data) => {
      await merchantTeamsResetPassword(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "password reset successful");
        setTimeout(() => setTwoFaFaVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const deleteMutation = useMutation(
    async (data) => {
      await merchantTeamsDelete(merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("merchantTeams");
        ToastMessage(type.SUCCESS, "Team member deleted successfully");
        setTimeout(() => setTwoFaFaVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showEdit = (id) => {
    setVisible(true);
    result?.data?.data?.data?.find(
      (item) => item.id === id && setInitialEditValues(item)
    );
  };

  const showPassReset = (id) => {
    setTwoFaFaVisible(true);
    setUserId(id);
    setActionType("passwordReset");
  };

  const showReset2fa = (id) => {
    setTwoFaFaVisible(true);
    setUserId(id);
    setActionType("reset2fa");
  };

  const showClearLimit = (id) => {
    setTwoFaFaVisible(true);
    setActionType("clearLimit");
    setUserId(id);
  };

  const showRemoveUser = (id) => {
    setTwoFaFaVisible(true);
    setActionType("removeUser");
    setUserId(id);
  };

  const handleCancel = () => {
    setVisible(false);
    setTwoFaFaVisible(false);
    setActionType(null);
    setUserId(null);
  };

  const handleSubmit = (values) => {
    const payload = { ...values, user_id: userId };

    if (actionType === "reset2fa") {
      reset2faMutation.mutate(payload);
    }
    if (actionType === "clearLimit") {
      clearlimitMutation.mutate(payload);
    }
    if (actionType === "passwordReset") {
      passwordResetMutation.mutate(payload);
    }
    if (actionType === "removeUser") {
      deleteMutation.mutate(payload);
    }
  };

  if (result.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Styles>
        <Spacer height="30px" />
        <div className="table-container">
          <h3>Manage merchant team</h3>

          <CustomTable
            theadBkColor="#F1F4F8"
            bottomGap="10px"
            tableHead={TeamsTableHead}
            tableBody={result?.data?.data?.data}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            handleReadAll={() => []}
            pageSize={5}
            handleEdit={showEdit}
            showClearLimit={showClearLimit}
            handleReset2fa={showReset2fa}
            handlePasswordReset={showPassReset}
            handleRemove={showRemoveUser}
          />
        </div>
      </Styles>
      <ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
        <h3
          style={{
            textAlign: "left",
          }}
        >
          Edit team member
        </h3>
        <FormLayout
          initialEditValues={initialEditValues}
          setVisible={setVisible}
        />
      </ModalFrame>
      <ModalFrame
        visible={twoFaVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            form="teams"
            loading={
              clearlimitMutation.isLoading ||
              reset2faMutation.isLoading ||
              deleteMutation.isLoading ||
              passwordResetMutation.isLoading
            }
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <h3
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "23px",
            textAlign: "center",
            color: "#000000",
          }}
        >
          Enter 2FA Code
        </h3>
        <Form
          layout={"vertical"}
          id="teams"
          onFinish={handleSubmit}
          size="large"
          initialValues={{
            layout: "vertical",
            size: "large",
          }}
        >
          <Form.Item
            label="Enter 2FA"
            name="twoFactorAuthenticationCode"
            rules={[
              {
                required: true,
                message: "Please input 2fa code!",
              },
            ]}
          >
            <Input placeholder="Enter 2FA Code" type="text" />
          </Form.Item>
        </Form>
      </ModalFrame>
    </>
  );
};

export default Team;

const TeamsTableHead = ["Team Member", "Email Address", "Last Login", ""];
