import React, { useState } from 'react';
import { Box, Container, Styles } from './styles';

import { isArray } from 'lodash';
import { Flex, Grid } from '../../../../../../components/box';

const Index = ({ merchant }) => {
	const [toggler, setToggler] = useState(false);
	const [productIndex, setProductIndex] = useState(0);
	const [source, setSource] = useState('');
	const labelData = [
		{
			label: 'Certificate of Incorporation',
			source: merchant?.document_incorporation
		},
		{ label: 'Register of Members', source: merchant?.register_of_members },
		{ label: 'Register of Directors', source: merchant?.register_of_directors },
		{
			label: 'Proof of Address of Company',
			source: merchant?.proof_of_address
		},
		{ label: 'ID of Directors', source: merchant?.id_directors },
		{ label: 'ID of Shareholders', source: merchant?.id_shareholders },
		{
			label: 'Memorandum and Articles of Association',
			source: merchant?.document_memorandum
		},
		{
			label: 'Letter of Authorization',
			source: merchant?.letter_of_authorization
		},
		{ label: 'AML form', source: merchant?.document_aml }
	];

	return (
		<Styles>
			<Container>
				<Flex flexDir='column' alignItem='flex-start'>
					<h4>Document Upload</h4>
					<p>Approve Merchant KYC/AML Documents</p>
				</Flex>
			</Container>
			<Grid
				className='grid_container'
				justifyContent={'flex-start'}
				gap={'40px 60px'}
				gridCol={'repeat(2,1fr)'}
				alignItem={'flex-start'}
				width={'100%'}
			>
				<Box>
					<label>Certificate of Incorporation</label>
					{merchant?.document_incorporation === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.document_incorporation}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label>Register of Members</label>
					{merchant?.register_of_members === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.register_of_members}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label>Register of Directors</label>
					{merchant?.register_of_directors === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.register_of_directors}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label>Proof of Address of Company</label>
					{merchant?.proof_of_address === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.proof_of_address}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label>ID of Directors</label>
					{merchant?.id_directors === null ? (
						<p>No Image upload</p>
					) : (
						<>
							{isArray(merchant?.id_directors) ? (
								merchant?.id_directors?.map((item) => (
									<a
										href={item}
										target='_blank'
										rel='noreferrer'
										// className='lightBox'
										// onClick={() => {
										// 	// setSource(item?.source);
										// 	// setProductIndex(index);
										// 	setToggler(!toggler);
										// }}
										style={{
											color: '#1a2cce',
											cursor: 'pointer'
										}}
									>
										view document
									</a>
								))
							) : (
								<a
									href={merchant?.id_directors}
									target='_blank'
									rel='noreferrer'
									// className='lightBox'
									// onClick={() => {
									// 	// setSource(item?.source);
									// 	// setProductIndex(index);
									// 	setToggler(!toggler);
									// }}
									style={{
										color: '#1a2cce',
										cursor: 'pointer'
									}}
								>
									view document
								</a>
							)}
						</>
					)}
				</Box>
				<Box>
					<label>ID of Shareholders</label>
					{merchant?.id_shareholders === null ? (
						<p>No Image upload</p>
					) : (
						<>
							{isArray(merchant?.id_shareholders) ? (
								merchant?.id_shareholders?.map((item) => (
									<a
										href={item}
										target='_blank'
										rel='noreferrer'
										// className='lightBox'
										// onClick={() => {
										// 	// setSource(item?.source);
										// 	// setProductIndex(index);
										// 	setToggler(!toggler);
										// }}
										style={{
											color: '#1a2cce',
											cursor: 'pointer'
										}}
									>
										view document
									</a>
								))
							) : (
								<a
									href={merchant?.id_shareholders}
									target='_blank'
									rel='noreferrer'
									// className='lightBox'
									// onClick={() => {
									// 	// setSource(item?.source);
									// 	// setProductIndex(index);
									// 	setToggler(!toggler);
									// }}
									style={{
										color: '#1a2cce',
										cursor: 'pointer'
									}}
								>
									view document
								</a>
							)}
						</>
					)}
				</Box>

				<Box>
					<label> Memorandum and Articles of Association</label>
					{merchant?.document_memorandum === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.document_memorandum}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label> Letter of Authorization</label>
					{merchant?.letter_of_authorization === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.letter_of_authorization}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				<Box>
					<label> AML form</label>
					{merchant?.document_aml === null ? (
						<p>No Image upload</p>
					) : (
						<a
							href={merchant?.document_aml}
							target='_blank'
							rel='noreferrer'
							// className='lightBox'
							// onClick={() => {
							// 	// setSource(item?.source);
							// 	// setProductIndex(index);
							// 	setToggler(!toggler);
							// }}
							style={{
								color: '#1a2cce',
								cursor: 'pointer'
							}}
						>
							view document
						</a>
					)}
				</Box>
				{/* {labelData?.map((item, index) => (
					<ViewDocs data={item} key={index} />
				))} */}
			</Grid>
		</Styles>
	);
};

export default Index;
