import React from "react";
import { Styles, Card } from "./styles";
import { Flex, Grid } from "../../../../components/box";
import { Skeleton } from "antd";

const Index = ({ swap, deposit, withdrawal, feeCollected, loading, error }) => {
  if (error) {
    <Flex>error</Flex>;
  }
  return (
    <Styles>
      <Grid
        justifyContent={"space-around"}
        gap="10px"
        gridCol="repeat(auto-fit, minmax(200px, 1fr))"
        bgColor="#fff"
        pad="30px 20px"
      >
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>DEPOSITS</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>
                {deposit?.currency?.symbol}
                {deposit?.result?.toLocaleString()}
              </span>
            )}
          </Flex>
        </Card>
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>WITHDRAWALS</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>
                {withdrawal?.currency?.symbol}
                {withdrawal?.result?.toLocaleString()}
              </span>
            )}
          </Flex>
        </Card>
        <Card>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>SWAP </span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>
                {swap?.currency?.symbol}
                {swap?.result?.toLocaleString()}
              </span>
            )}
          </Flex>
        </Card>
        <Card style={{ borderRight: "none" }}>
          <Flex
            className="container"
            alignItem={"center"}
            justifyContent={"space-between"}
          >
            <span>FEES COLLECTED</span>
          </Flex>
          <Flex className="viewall" alignItem={"flex-start"} flexDir={"column"}>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>
                {feeCollected?.currency?.symbol}
                {feeCollected?.result?.toLocaleString()}
              </span>
            )}
          </Flex>
        </Card>
      </Grid>
    </Styles>
  );
};

export default Index;
