import styled from 'styled-components';
import { Flex } from '../../../../../components/box';

export const Styles = styled(Flex)`
	justify-content: space-between;
	padding-top: 4em;
	// border-top: 0.1px solid #c4c4c4;
	/* background-color: #f8f9fd; */
	padding-left: 3rem;
	padding-right: 1.5em;
	.Text {
		padding: 0.5em 0;
		color: #000000;
	}
	.sub_text {
		padding: 0.5em;
		color: #4b4b4b;
		font-size: 14px;
	}
	.container {
		border-bottom: none;
		// @media (max-width: 1200px) {
		// 	flex-direction: column-reverse;
		// 	div:first-child {
		// 		width: 100%;
		// 	}
		// }
	}
	@media (max-width: 900px) {
		padding: 20px 4%;
	}
	.revoke {
		padding-top: 25px;
		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			text-decoration: underline;
		}
	}
`;

export const VerificationProgress = styled(Flex)`
	flex-direction: column;
	height: 350px;
	justify-content: space-between;
	align-items: flex-start;
	background-color: #fff;
	border: 1px solid #f0f0f0;
	margin-top: 2em;
	padding: 1.5em 2em;
	border-radius: 5px;
	max-width: 450px;
	span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #4d4d4d;
	}
	h5 {
		font-style: normal;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}

	Button {
		text-decoration: none;
		// padding: 10px 15px !important;
		// text-align: center;
		// width: 100%;
	}
`;
