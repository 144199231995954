import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../../components//layouts';
import RouterTabs from '../../../components/Tabs/routerTab';
import { Spacer } from '../../../components/spacer/styles';
import { useAuthState } from '../../../context';
import { RoleCheck } from '../../../routes/privateRoute';
import CrossFees from './cross-fees';
import General from './general';
import PaymentMethods from './payment-method';
import Profile from './profile';
import Security from './security';
import ActivitiesLog from './security/activitesLog/ActivitiesLog';
import ManageSession from './security/sessionLog/ManageSession';
import { Styles } from './styles';
import UserRoles from './user-roles';
import XchangeConnect from './xchange-connect';

const Index = () => {
	const { state } = useAuthState();
	const location = useLocation();

	const [role, setRole] = useState();
	const [permissions, setPermissions] = useState();

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setPermissions(state?.user?.role?.permitions);
	}, [state]);

	const links = [
		{
			path: '/dashboard/settings',
			title: 'Profile'
		},
		{
			path: '/dashboard/settings/general',
			title: 'General'
		},
		// {
		// 	path: '/dashboard/settings/payment-methods',
		// 	title: 'Payment methods'
		// },
		{
			path: '/dashboard/settings/teams',
			title: 'Teams'
		},
		// {
		// 	path: '/dashboard/settings/cross-fees',
		// 	title: 'Cross Fees'
		// },
		{
			path: '/dashboard/settings/xchange-connect',
			title: 'XchangeConnect'
		},
		{
			path: '/dashboard/settings/security',
			title: 'Security'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Profile />
		},
		{
			path: '/general',
			component: (
				<RoleCheck role={role !== 'Super Admin' ? false : true}>
					<General />
				</RoleCheck>
			)
		},
		{
			path: '/payment-methods',
			component: (
				<RoleCheck role={role !== 'Super Admin' ? false : true}>
					<PaymentMethods />
				</RoleCheck>
			)
		},
		{
			path: '/teams',
			component: (
				<RoleCheck
					role={role === 'Super Admin' || role === 'Admin' ? true : false}
				>
					<UserRoles />
				</RoleCheck>
			)
		},
		{
			path: '/cross-fees',
			component: <CrossFees />
		},
		{
			path: '/xchange-connect',
			component: <XchangeConnect />
		},
		{
			path: '/security',
			component: <Security />
		},
		{
			path: '/security/activities',
			component: <ActivitiesLog />
		},
		{
			path: '/security/session',
			component: <ManageSession />
		}
	];

	return (
		<Layout
			title={
				location?.pathname.includes('activities')
					? 'Manage activities'
					: location?.pathname.includes('session')
					? 'Manage sessions'
					: 'Settings'
			}
		>
			<Styles>
				<Spacer height='30px' />

				{role === 'Super Admin' && permissions?.includes('all') ? (
					<RouterTabs type='primary-tab' routes={routes} links={links} />
				) : null}
				{role === 'Admin' && permissions?.includes('merchants') ? (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[2], links[4]]}
					/>
				) : null}
				{role === 'Treasury' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Finance' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Compliance' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Support' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
			</Styles>
		</Layout>
	);
};

export default Index;
