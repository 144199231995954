import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
const Index = ({ handleChange }) => (
  <Input
    size="large"
    placeholder="Search currency pair"
    prefix={<SearchOutlined />}
    onChange={handleChange}
  />
);

export default Index;
