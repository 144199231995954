import styled from "styled-components";

export const Styles = styled.div`
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 15.537px;
    line-height: 20px;
    color: #000a62;
    margin-bottom: 22px;
  }

  .ant-form-item {
    width: 250px;
  }

  .amount,
  .transaction,
  .withdraw {
    .ant-form-item {
      width: 100%;

      .ant-form-item-control {
        background: #ffffff;
        border: 1px solid #d7d7d7;

        border-radius: 4.31584px;
      }
      .ant-input {
        border: none;
      }
      .ant-input-group-addon {
        background: #eaeaea;
        border: none;
      }
    }
  }
  .amountTo {
    .ant-form-item {
      width: 100%;
    }
  }
  .upload {
    .ant-form-item {
      margin-bottom: 10px;
      width: 100%;
      .ant-form-item-control {
        background: #ffffff;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        // width: 100%;
        padding: 2px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .ant-form-item-control-input-content {
        span {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;

          .ant-upload-span {
            .ant-upload-text-icon {
              display: none;
            }
          }
          .ant-upload-list-item-name {
            text-overflow: ellipsis !important;
          }

          button {
            background: #c6ddff;
            border-radius: 5px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: #1a2cce;
          }
        }
      }
    }
  }

  .ant-select-selector {
    height: 46px !important;
    padding: 5px 11px !important;
  }

  .ant-switch {
    width: 37.89px;
    height: 22.33px;
  }

  .ant-switch-checked {
    background: #69ce1a;
    border-radius: 24.2105px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #166f00;
  }
`;
