import { useState } from "react";

export const useCopy = () => {
  const [copySuccess, setCopySuccess] = useState("copy");

  const copyToClipboard = async (copy) => {
    try {
      await navigator.clipboard.writeText(copy);
      setCopySuccess("copied");
    } catch (error) {
      setCopySuccess("Failed to copy!");
    }
    setTimeout(() => setCopySuccess("copy"), 5000);
  };
  return { copyToClipboard, copySuccess };
};
