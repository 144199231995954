import React from "react";
import { Form, Input, Select } from "antd";
import { RateStyle } from "./styles";
import { Flex } from "../../../../../components/box";

function EditFeesForm({ handelSubmitEditFee, initialEditValues }) {
  return (
    <Form
      id="editForm"
      onFinish={handelSubmitEditFee}
      layout={"vertical"}
      initialValues={{
        buy_fee_type: initialEditValues?.buy_fee_type,
        buy_fee: initialEditValues?.buy_fee,
        sell_fee: initialEditValues?.sell_fee,
        sell_fee_type: initialEditValues?.sell_fee_type,
      }}
    >
      <h3
        style={{
          textAlign: "left",
        }}
      >
        Set Fees
      </h3>
      <RateStyle>
        <Flex justifyContent="space-between">
          <Form.Item
            label="Buying Fee Type"
            name={"buy_fee_type"}
            // rules={[{ required: true, message: "buying fee type is Required" }]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              title="buy_fee_type"
              placeholder="select fee type"
            >
              <Select.Option value={"FLAT"}>FLAT</Select.Option>
              <Select.Option value={"PERCENTAGE"}>PERCENTAGE</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"buy_fee"}
            type="text"
            label="Fee amount"
            // rules={[
            //   { required: true, message: "buying fee amount is Required" },
            // ]}
          >
            <Input type="number" placeholder="Enter Fee Amount" />
          </Form.Item>
        </Flex>
        <Flex justifyContent="space-between">
          <Form.Item
            label="Selling Fee Type"
            name={"sell_fee_type"}
            // rules={[
            //   { required: true, message: "selling fee type is Required" },
            // ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              title="selling_fee_type"
              placeholder="select fee type"
            >
              <Select.Option value={"FLAT"}>FLAT</Select.Option>
              <Select.Option value={"PERCENTAGE"}>PERCENTAGE</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"sell_fee"}
            type="text"
            label="Fee Amount"
            // rules={[{ required: true, message: "sell fee amount is Required" }]}
          >
            <Input type="number" placeholder="Enter Fee Amount" />
          </Form.Item>
        </Flex>
      </RateStyle>
    </Form>
  );
}

export { EditFeesForm };
