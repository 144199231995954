import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	AcceptTransactionIcon,
	// AddSettlementIcon,
	// Approve,
	DotIcon,
	RefreshTransactionIcon,
	// Reject,
	RejectTransactionIcon,
	UserAvatar,
	ViewTransactionIcon
} from '../../../../assets';
import { OverFlowScrollBar } from '../../../../components/overflowScroll/styles';
import Pagination from '../../../../components/paginator';
import PopOver from '../../../../components/popover/PopOver';
import { generateID } from '../../../../utils/generateID';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

import { EmptyState } from '../../../../components/emptyState';
import { useAuthState } from '../../../../context';
import { formattedDate, formattedTime } from '../../../../utils/helpers';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	tableHead2,
	widthdrawnTo,
	handleAddSettlementTime,
	merchantTran,
	handleReject,
	handleApprove,
	meta,
	handlePageChange,
	handleRequeue,
	setSelectedIndex,
	selectedIndex
}) => {
	const swapPath = '/dashboard/transactions';
	const withdrawalsPath = '/dashboard/transactions/withdrawals';
	const depositPath = '/dashboard/transactions/deposits';
	const { state } = useAuthState();
	const [role, setRole] = useState();
	const [mode, setMode] = useState();
	const [permissions, setPermissions] = useState();
	const [approver, setApprover] = useState();
	const [isCheckAll, setIsCheckAll] = useState(false);

	let path = useLocation().pathname;
	let pathLocator =
		path.split('/')[3] === undefined ? 'swap' : path.split('/')[3];

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setMode(state?.user?.mode);
		setPermissions(state?.user?.role?.permitions);
		setApprover(state?.user?.approver);
	}, [state, mode, permissions, role, approver]);

	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setSelectedIndex(tableBody.map((li) => li.id));
	};

	const handleSelectedItem = (e, index) => {
		const prevId = selectedIndex.filter((id) => id === index);
		if (e.target.checked && index !== prevId) {
			setSelectedIndex((prevSelectedIndex) => [...prevSelectedIndex, index]);
		} else {
			setSelectedIndex(selectedIndex.filter((item) => item !== index));
		}
	};
	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.id}>
				{/* <Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td> */}
				<Td>
					<div>
						<input
							type={'checkbox'}
							onChange={(e) => handleSelectedItem(e, data?.id)}
							key={i}
							checked={selectedIndex?.includes(data?.id) ? true : false}
						/>
					</div>
				</Td>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'column'
						}}
					>
						<strong style={{ color: '#1A1F36' }}>
							{formattedDate(data?.created_at)}
						</strong>
						<span style={{ color: '#6F6F6F' }}>
							{formattedTime(data?.created_at)}
						</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>
							{tableHead || merchantTran
								? widthdrawnTo
									? data?.from_merchant?.business_name
									: data.to_merchant?.business_name
								: data?.from_merchant?.business_name}
						</span>
					</div>
				</Td>
				{path === swapPath ? (
					<Td>
						<div>
							<div
								style={{
									borderRadius: '50px',
									width: 8,
									height: 8,
									marginRight: 2,
									background: data?._withdrawal_id !== '' ? 'orange' : '#6F6F6F'
								}}
							></div>
							<span style={{ color: '#767676' }}>
								{`${data?.from_currency !== null ? data?.from_currency : ''} ${
									data.from_currency !== null
										? '-'
										: '' || data?.to_currency !== null
										? '-'
										: ''
								} ${data?.to_currency !== null ? data?.to_currency : ''}`}
							</span>
						</div>
					</Td>
				) : null}

				<Td>
					{tableHead || merchantTran ? (
						widthdrawnTo ? (
							<>
								<span>{data.to_currency}</span>
								<strong>{data.amount.toLocaleString()}</strong>
							</>
						) : (
							<div>
								<span>{data.to_currency}</span>
								<strong>{data.to_amount.toLocaleString()}</strong>
							</div>
						)
					) : (
						<div>
							<span>{data.to_currency}</span>
							<strong>{data.amount.toLocaleString()}</strong>
						</div>
					)}
				</Td>
				{path === swapPath ? (
					<Td>
						<span style={{ color: 'red' }}>
							<Countdown
								date={
									data.settlement_date === null
										? '1918-09-08T09:55:54.000Z'
										: data?.settlement_date
								}
							/>
						</span>
					</Td>
				) : null}
				{path === depositPath || path === withdrawalsPath ? (
					<Td>
						<span>{data?.payment_provider}</span>
					</Td>
				) : null}
				<CustomTd status={data?._status}>
					<div>
						<span>{data?._status}</span>
					</div>
				</CustomTd>
				<CustomTd>
					<PopOver
						placement='rightTop'
						main={
							<div className='icon_box'>
								<DotIcon />
							</div>
						}
					>
						<Link to={`/dashboard/transactions/${pathLocator}/${data.id}`}>
							<div>
								<ViewTransactionIcon />
								<p>Transaction details</p>
							</div>
						</Link>
						<Link
							to={`/dashboard/merchants/${
								data?.to_merchantId !== null
									? data?.to_merchantId
									: data?.from_merchantId
							}`}
						>
							<div>
								<UserAvatar />
								<p>Merchant info</p>
							</div>
						</Link>
						{path === withdrawalsPath && (
							<>
								{data?._status === 'pending' && (
									<Link
										to={'#'}
										onClick={() => handleAddSettlementTime(data?.id)}
									>
										<div>
											<RefreshTransactionIcon />
											<p>Refresh transaction</p>
										</div>
									</Link>
								)}

								{data?._status === 'blocked' && (
									<Link to={'#'} onClick={() => handleRequeue(data?.id)}>
										<div>
											<RefreshTransactionIcon />
											<p>Re-queue</p>
										</div>
									</Link>
								)}
							</>
						)}

						{data?._status === 'success' && (
							<>
								<Link
									to={'#'}
									// onClick={() => handleApprove(data?.id, 'super-approve')}
								>
									<div>
										<RejectTransactionIcon />
										<p>Reject Transaction</p>
									</div>
								</Link>
							</>
						)}
						{role === 'Super Admin' && permissions?.includes('all') ? (
							<>
								{(data?._status === 'pending' ||
									data?._status === 'queued' ||
									data?._status === 'blocked') && (
									<>
										<Link
											to={'#'}
											onClick={() => handleApprove(data?.id, 'super-approve')}
										>
											<div>
												<AcceptTransactionIcon />
												<p>Super Approve</p>
											</div>
										</Link>
									</>
								)}
								{data?._status === 'success' && (
									<>
										<Link
											to={'#'}
											// onClick={() => handleApprove(data?.id, 'super-approve')}
										>
											<div>
												<AcceptTransactionIcon />
												<p>Reverse Transaction</p>
											</div>
										</Link>
									</>
								)}
								{(data?._status === 'queued' ||
									data?._status === 'blocked') && (
									<>
										<Link
											to={'#'}
											onClick={() => handleApprove(data?.id, 'accept')}
										>
											<div>
												<AcceptTransactionIcon />
												<p>Accept transaction</p>
											</div>
										</Link>
										<Link
											to={'#'}
											onClick={() => handleReject(data?.id, 'reject')}
										>
											<div>
												<RejectTransactionIcon />
												<p>Reject transaction</p>
											</div>
										</Link>
									</>
								)}
							</>
						) : null}
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && (
												<input
													type={'checkbox'}
													checked={isCheckAll}
													onChange={handleSelectAll}
												/>
											)}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
