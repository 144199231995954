import { Checkbox, Divider, Form, Input, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import CustomAccordion from '../../../../components/customAccordion/CustomAccordion';
import { getMerchantSearch } from '../../../../services/Others.service';
import DebounceSelect from './DebounceSelect';
import { OptionalStyle } from './styles';
const FormLayout = ({
	action,
	initialEditValues,
	paymentMethod,
	currencies,
	handleSubmit,
	onChange,
	setMerchantId
}) => {
	const [curType, setCurType] = useState('');

	const [minMaxToggle, setMinMaxToggle] = useState(
		action === 'edit'
			? initialEditValues?.fees[0]?.fee_type === 'PERCENTAGE'
				? 'PERCENTAGE'
				: ''
			: ''
	);
	const [optionalMinMaxToggle, setOptionalMinMaxToggle] = useState(
		action === 'edit'
			? JSON.parse(initialEditValues?.optional_fees)[0]?.fee_type ===
			  'PERCENTAGE'
				? 'PERCENTAGE'
				: ''
			: ''
	);
	const [optional, setOptional] = useState(action === 'edit' ? true : false);
	const [checkMethod, setCheckMethod] = useState();
	const [value, setValue] = useState([]);
	const [formPaymentMethod, setFormPaymentMethod] = useState([]);
	const [optionalPaymentMethod, setOptionalPaymentMethod] = useState([]);

	const handleInputChange = (value) => {
		// setCurrency(value);

		currencies?.filter((c) => {
			if (c.code === value) {
				setCurType(c.type);
				// currencyType(c.type);
			}
		});
	};

	const fetchMerchantsList = (username) => {
		console.log('fetching user', username);
		if (username !== undefined || null || '') {
			return getMerchantSearch(username);
		} else {
			return [];
		}
	};

	useEffect(() => {
		if (action === 'edit') {
			handleInputChange(initialEditValues?.fee_currency);
		}
	}, [action]);

	return (
		<>
			<Form
				layout={'vertical'}
				onFinish={handleSubmit}
				size='large'
				initialValues={{
					layout: 'vertical',
					size: 'large',
					merchant: action === 'edit' ? initialEditValues?.business_name : null,
					transaction_type:
						action === 'edit'
							? initialEditValues?.transaction_type === 'withdrawal'
								? 'Withdraw'
								: 'Deposit'
							: null,
					payment_method:
						action === 'edit'
							? Number(initialEditValues?.payment_methods)
							: null,
					fee_currency:
						action === 'edit' ? initialEditValues?.fee_currency : null,
					from_currency:
						action === 'edit' ? initialEditValues?.from_currency : null,
					to_currency:
						action === 'edit' ? initialEditValues?.to_currency : null,
					fee_type:
						action === 'edit' ? initialEditValues?.fees[0]?.fee_type : null,
					fee: action === 'edit' ? initialEditValues?.fees[0]?.fee : null,
					chain: action === 'edit' ? initialEditValues?.fees[0]?.chain : null,
					min: action === 'edit' ? initialEditValues?.fees[0]?.min_fee : null,
					max: action === 'edit' ? initialEditValues?.fees[0]?.max_fee : null,
					optional_transaction_type:
						action === 'edit'
							? initialEditValues?.optional_transaction_type === null
								? null
								: initialEditValues?.optional_transaction_type === 'withdrawal'
								? 'Withdraw'
								: 'Deposit'
							: null,
					optional_payment_method:
						action === 'edit'
							? initialEditValues?.optional_payment_methods === '[null]'
								? null
								: Number(
										JSON.parse(initialEditValues?.optional_payment_methods)
								  )
							: null,
					optional_fee_amount:
						action === 'edit'
							? JSON.parse(initialEditValues?.optional_fees)[0]?.fee
							: null,
					optional_fee_type:
						action === 'edit'
							? JSON.parse(initialEditValues?.optional_fees)[0]?.fee_type
							: null,
					optional_chain:
						action === 'edit'
							? JSON.parse(initialEditValues?.optional_fees)[0]?.chain
							: null,
					optional_min:
						action === 'edit'
							? JSON.parse(initialEditValues?.optional_fees)[0]?.min_fee
							: null,
					optional_max:
						action === 'edit'
							? JSON.parse(initialEditValues?.optional_fees)[0]?.max_fee
							: null,
					status: action === 'edit' ? initialEditValues?.status : null,
					env: action === 'edit' ? JSON.parse(initialEditValues?.env) : null
				}}
				id='cross-fee'
			>
				{action === 'edit' && (
					<Flex
						// bgColor={
						// 	initialEditValues?.status === false ? '#fff4f3' : '#F4FFEF'
						// }
						justifyContent='space-between'
						pad='20px'
						marginBottom='35px'
					>
						<p
							style={{
								color: initialEditValues?.status === false && '#f44336'
							}}
						>
							Status
						</p>
						<Form.Item name='status'>
							<Switch
								// defaultChecked={true}
								defaultChecked={initialEditValues?.status}
								// onChange={handleSwitched}
							/>
						</Form.Item>
					</Flex>
				)}
				<Form.Item
					label='Merchant'
					name='merchant'
					// rules={[
					// 	{
					// 		required: true,
					// 		message: 'Please input full name!'
					// 	}
					// ]}
				>
					<DebounceSelect
						value={value}
						placeholder='Select merchant'
						fetchOptions={fetchMerchantsList}
						onChange={(newValue) => {
							setValue(newValue?.label);
							setMerchantId(newValue?.value);
						}}
						style={{
							width: '100%'
						}}
					/>
				</Form.Item>
				<Form.Item
					label='Transaction Type'
					name={'transaction_type'}
					style={{ width: '100%' }}
					// value={item?.label}
				>
					<Select
						placeholder='Select transaction type'
						onChange={(val) => {
							const methods = paymentMethod?.filter(
								({ method }) => method?.toLowerCase() === val
							);
							// const mVal = methods === 'withdraw' ? 'withdrawal' : methods;
							// console.log(val);
							setCheckMethod(val);
							setFormPaymentMethod(methods);
						}}
						options={[
							{
								value: 'deposit',
								label: 'Deposit'
							},
							{
								value: 'withdraw',
								label: 'Withdraw'
							}
						]}
					/>
				</Form.Item>
				<Form.Item
					label='Payment Method'
					name={'payment_method'}
					style={{ width: '100%' }}
				>
					<Select placeholder='Select payment method' showSearch>
						{formPaymentMethod?.map((item) => (
							<Select.Option
								children={item?.method}
								value={item?.id}
								key={item?.id}
							>
								{item.name}:{item?.provider}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name='fee_currency' label='Fee Currency'>
					<Select
						placeholder='Select fee currency'
						// showSearch
						// filterOption={(input, option) =>
						// 	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						// }
						onChange={handleInputChange}
					>
						{currencies?.map((item) => (
							<Select.Option value={item.code} key={item?.id}>
								{item.code}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Flex justifyContent='space-between' width='100%' gap='20px'>
					<Form.Item
						label='From Currency'
						name='from_currency'
						style={{
							width: '80%'
						}}
					>
						<Select
							placeholder='Select from currency'
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{currencies?.map((item) => (
								<Select.Option value={item.code} key={item?.id}>
									{item.code}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Flex width='20%'>
						<Divider
							style={{
								// marginLeft: 8,
								// width: '150px',
								background: 'black'
							}}
						/>
					</Flex>
					<Form.Item
						label='To Currency'
						name='to_currency'
						style={{
							width: '80%'
						}}
					>
						<Select
							placeholder='Select to currency'
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{currencies?.map((item) => (
								<Select.Option value={item.code} key={item.id}>
									{item.code}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Flex>
				<CustomAccordion title='Add Fees Settings' type='cross'>
					<Flex justifyContent='start' gap='20px' flexWrap='wrap'>
						<Form.Item
							label='Fee type'
							name='fee_type'
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Fee type is required'
							// 	}
							// ]}
						>
							<Select
								placeholder='Select fee type'
								onChange={(e) => setMinMaxToggle(e)}
							>
								<Select.Option value='FLAT'>FLAT</Select.Option>
								<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item name='fee' label='Fee Amount'>
							<Input placeholder='Enter fee' />
						</Form.Item>
						{curType === 'CRYPTO' ? (
							<Form.Item label='Chain' name='chain'>
								<Input placeholder='Enter chain' type='text' />
							</Form.Item>
						) : null}
						{minMaxToggle === 'PERCENTAGE' ? (
							<>
								<Form.Item label='Minimum Fee' name='min'>
									<Input placeholder='Enter min fee' />
								</Form.Item>
								<Form.Item label='Maximum Fee' name='max'>
									<Input placeholder='Enter max fee' />
								</Form.Item>
							</>
						) : null}
					</Flex>
				</CustomAccordion>

				{optional ? (
					<>
						<OptionalStyle>
							<p>Add Optional Transaction</p>
							<Form.Item
								label='Transaction Type'
								name={'optional_transaction_type'}
								// initialValue={item?.label}
								// defaultValue={item?.label}
								style={{ width: '100%' }}
								// value={item?.label}
							>
								<Select
									placeholder='Select transaction type'
									onChange={(val) => {
										const methods = paymentMethod?.filter(
											({ method }) => method?.toLowerCase() === val
										);
										setOptionalPaymentMethod(methods);
									}}
									options={
										checkMethod === 'deposit' ||
										initialEditValues?.transaction_type === 'deposit'
											? [
													{
														value: 'withdraw',
														label: 'Withdraw'
													}
											  ]
											: [
													{
														value: 'deposit',
														label: 'Deposit'
													},
													{
														value: 'withdraw',
														label: 'Withdraw'
													}
											  ]
									}
								/>
							</Form.Item>
							<Form.Item
								label='Payment Method'
								name={'optional_payment_method'}
								// initialValue={item?.label}
								// defaultValue={item?.label}
								style={{ width: '100%' }}
								// value={item?.label}
							>
								<Select placeholder='Select payment method' showSearch>
									{optionalPaymentMethod?.map((item) => (
										<Select.Option
											children={item?.method}
											value={item?.id}
											key={item?.id}
										>
											{item.name}:{item?.provider}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Flex justifyContent='start' gap='20px' flexWrap='wrap'>
								<Form.Item label='Fee type' name='optional_fee_type'>
									<Select
										placeholder='Select fee type'
										onChange={(e) => setOptionalMinMaxToggle(e)}
									>
										<Select.Option value='FLAT'>FLAT</Select.Option>
										<Select.Option value='PERCENTAGE'>PERCENTAGE</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item name='optional_fee_amount' label='Fee Amount'>
									<Input placeholder='Enter fee' />
								</Form.Item>
								{curType === 'CRYPTO' ? (
									<Form.Item label='Chain' name='optional_chain'>
										<Input placeholder='Enter chain' type='text' />
									</Form.Item>
								) : null}
								{optionalMinMaxToggle === 'PERCENTAGE' ? (
									<>
										<Form.Item label='Minimum Fee' name='optional_min'>
											<Input placeholder='Enter min fee' />
										</Form.Item>
										<Form.Item label='Maximum Fee' name='optional_max'>
											<Input placeholder='Enter max fee' />
										</Form.Item>
									</>
								) : null}
							</Flex>
						</OptionalStyle>
						{/* {action !== 'edit' ? ( */}
						<Button
							Styles
							text='Remove optional setting'
							color='#DB2700'
							size='md'
							type='button'
							onClick={() => {
								setOptional(false);
								// remove(currencyField.name);
								// handleFieldsRemove(fieldSetting.label);
							}}
							style={{
								marginTop: '15px',
								padding: '0',
								textDecoration: 'underline'
							}}
						/>
						{/* ) : null} */}
					</>
				) : (
					<Button
						Styles
						text='Add Optional Transaction'
						color={'#1A2CCE'}
						size='md'
						type='button'
						// onClick={() => add()}
						onClick={() => setOptional(true)}
						style={{
							padding: '0',
							textDecoration: 'underline',
							marginTop: '15px'
						}}
					/>
				)}

				<Flex
					flexDir='row'
					justifyContent='flex-start'
					// pad='30px'
					alignItems='baseline'
					gap={'20px'}
					width='100%'
					marginTop='10px'
				>
					<p style={{ fontSize: '15px', lineHeight: '20px' }}>
						Set Environment
					</p>
					<Form.Item name='env'>
						<Checkbox.Group
							style={{ width: '100%' }}
							options={['live', 'test']}
							// defaultValue={initialEditValues}
						/>
					</Form.Item>
				</Flex>
			</Form>
		</>
	);
};

export { FormLayout };
