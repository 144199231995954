import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Spacer } from '../../../../components/spacer/styles';

import { Styles, Container1, SearchInputStyles } from './styles';
// import Search from '../../../../components/search';
import { Container } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
// import ModalFrame from '../../../../components/modal/ModalFrame';
import {
	// refundRequest,
	getRequestedRefundDeposit,
	refundCompleted
} from '../../../../services/Transactions.service';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { formatType } from '../../../../utils/helpers';
import CustomTable from '../table';
import { RefundsTableHead } from '../../../../utils/constants';
import { Loader } from '../../../../components/loader';
import { ToastMessage, type } from '../../../../utils/toastr';

const Index = () => {
	const queryClient = useQueryClient();

	const [value, setValue] = React.useState('All');
	const [currency, setCurrency] = React.useState('All');
	const [statusValue, setStatusValue] = React.useState('All');
	const [selectedDateTo, setSelectedDateTo] = React.useState('All');
	const [selectedDateFrom, setSelectedDateFrom] = React.useState('All');
	const [filter, setFilter] = React.useState({});
	const [clicked, setClicked] = React.useState('false');
	const [search, setSearch] = React.useState('');

	const result = useQuery(
		'getRequestedRefundDeposit',
		async () => await getRequestedRefundDeposit(search, filter),
		{
			refetchOnWindowFocus: false
		}
	);

	// const createRequestMutation = useMutation(
	// 	async (transId) => {
	// 		await refundRequest(transId);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('getRequestedRefundDeposit');

	// 			ToastMessage(type.SUCCESS, 'Refund requested successfully!');
	// 		},
	// 		onError: (error) => {
	// 			console.log(error);
	// 			ToastMessage(type.ERROR, error.response.data.message);
	// 		}
	// 	}
	// );

	const createCompleteMutation = useMutation(
		async (transId) => {
			await refundCompleted(transId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getRequestedRefundDeposit');
				ToastMessage(type.SUCCESS, 'Refund completed successfully!');
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const handleSearch = (e) => {
		const { value } = e.target;
		// console.log('value:', value);
		// setInput(value);
		setFilter({ ...filter, ref: value });
		setSearch(value);
	};

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const onChangeStatus = (e) => {
		const { value } = e.target;
		setStatusValue(value);
		setFilter({ ...filter, status: value });
	};

	const handleCurrencyChange = (e) => {
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.status === 'All') {
			delete filter.status;
		}
		setClicked('true');

		result.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	// const requestRefund = (id) => {
	// 	createRequestMutation.mutate(id);
	// 	// console.log(id);
	// };
	const refundComplete = (id) => {
		createCompleteMutation.mutate(id);
		// console.log(id);
	};

	if (result.status === 'error') {
		return <span>Error: {result.error.message}</span>;
	}

	// console.log(result?.data?.data, result?.data?.data?.data?.data);
	return (
		<>
			<Styles>
				<Container1>
					<Container className='input_container' width={'20%'}>
						<SearchInputStyles>
							<Input
								// size='large'
								placeholder='Search reference no.'
								bordered={false}
								style={{
									border: '0.7px solid #dfdfdf',
									boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
									height: '45px',
									background: 'transparent'
								}}
								onChange={handleSearch}
								onPressEnter={() => result?.refetch(search)}
								prefix={
									<SearchOutlined
										style={{
											padding: 0
										}}
										onClick={() => result?.refetch(search)}
									/>
								}
							/>
						</SearchInputStyles>
					</Container>
					<Filters
						source='refund'
						currency={currency}
						setCurrency={setCurrency}
						statusValue={statusValue}
						value={value}
						handleSelectFromChange={handleSelectFromChange}
						handleSelectToChange={handleSelectToChange}
						onChange={onChange}
						onChangeStatus={onChangeStatus}
						selectedDateFrom={selectedDateFrom}
						selectedDateTo={selectedDateTo}
						setFilter
						handleCurrencyChange={handleCurrencyChange}
						refetch={handleSubmit}
						loading={clicked === 'true' && result.isRefetching}
					/>
				</Container1>

				<Spacer height='20px' />
				{result?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={RefundsTableHead}
						tableBody={result?.data?.data?.data?.data}
						// tableBody={tableBody}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={10}
						paginator
						meta={result?.data?.data?.data?.meta}
						handlePageChange={handlePageChange}
						// handleRequestRefund={requestRefund}
						handleRefundComplete={refundComplete}
					/>
				)}
			</Styles>
			{/* 
			<ModalFrame
				visible={swapOverview}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
				width='615px'
			>
				<PaymentSummary />
			</ModalFrame>
			 */}
		</>
	);
};

export default Index;
