import styled from "styled-components";
import { Flex } from "../../../../../components/box";

export const Styles = styled.div`
  .select {
    .ant-select-selector {
      color: #000000;
      border: none;
    }
  }
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .fiter_container {
    gap: 30px;
    justify-content: space-between;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px 10px;
    .fiter_container {
      width: 100%;
      justify-content: space-between;
      overflow: auto;
      flex-wrap: wrap;
      gap: 15px 5px;
    }
    .input_container {
      width: 100%;
    }
  }
`;

export const SelectContainer = styled(Flex)`
  justify-content: space-between;
  background-color: #fff;
  padding: 0 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 0.7px solid #dfdfdf;
  max-width: 200px;
  > span {
    font-size: 12px;
    color: #969696;
  }
`;
