import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const Styles = styled.div`
  width: 100%;
  background-color: #eef2f7;
  padding: 25px;
  border-radius: 5px;
  height: 450px;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #1a1f36;
    padding-bottom: 10px;
  }
`;

export const Sidebar = styled(Flex)`
  height: 450px;
  background-color: #1a2cce;
  padding: 30px;
  border-radius: 3.59155px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding-bottom: 10px;
  }
  div {
    padding: 10px 0;
    width: 100%;
    border-bottom: 0.376731px solid rgba(255, 255, 255, 0.35);
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 28.7324px;
      line-height: 34px;
      color: #ffffff;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      opacity: 0.8;
    }
    svg {
      cursor: pointer;
    }
  }
  div:last-child {
    border-bottom: none;
  }
`;

export const TableContainer = styled(Flex)`
  background-color: #fff;
  height: 450px;
  .kyc {
    padding: 15px 0 0 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #8e8e8e;
    }
  }
`;
