import React, { useState } from 'react';
import { useQuery } from 'react-query';

import RouterTabs from '../../../components/Tabs/routerTab';
import DownloadReport from '../../../components/downloadReport/DownloadReport';
import Layout from '../../../components/layouts';
import ModalFrame from '../../../components/modal/ModalFrame';
import { ProcessorStatusTable } from '../../../components/processor/ProcessorLowBalance';
import { Spacer } from '../../../components/spacer/styles';
import {
	getTransactionsReport,
	getTransactionsReportType,
	getTransactionsSummaryDeposit,
	getTransactionsSummaryFeeCollected,
	getTransactionsSummarySwap,
	getTransactionsSummaryWithdrawal
} from '../../../services/Transactions.service';
import { ToastMessage, type } from '../../../utils/toastr';
import Deposits from './deposits';
import Overview from './overveiw';
import PaymentSummary from './paymentSummary/PaymentSummary';
import { Styles } from './styles';
import Swap from './swap';
import Withdraws from './withdraws';

const Index = () => {
	const [toDate, setToDate] = useState('');
	const [merchant, setMerchant] = useState(Number);
	const [swapOverview, setSwapOverview] = useState(false);
	const [fileType, setFileType] = useState('');
	const [fromDate, setFromDate] = useState('');
	const [transactionType, setTransactionType] = useState('');
	const [expectedTrans, setExpectedTrans] = useState('');
	const [downloadVisible, setDownloadVisible] = useState(false);
	const [processorVisible, setProcessorVisible] = useState(false);

	const swap = useQuery(
		'getTransactionsSummarySwap',
		async () => await getTransactionsSummarySwap()
	);

	const withdrawal = useQuery(
		'getTransactionsSummaryWithdrawal',
		async () => await getTransactionsSummaryWithdrawal()
	);

	const deposit = useQuery(
		'getTransactionsSummaryDeposit',
		async () => await getTransactionsSummaryDeposit()
	);

	const feeCollected = useQuery(
		'getTransactionsSummaryFeeCollected',
		async () => await getTransactionsSummaryFeeCollected()
	);

	const handleDownload = () => {
		setDownloadVisible(true);
	};
	const handleSwapOverview = () => {
		setSwapOverview(true);
	};

	const handleProcessorStatus = () => {
		setProcessorVisible(true);
	};

	const handleOk = async () => {
		if (transactionType === 'All') {
			getTransactionsReport(fromDate, toDate, merchant)
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(`download`, `report.${'csv'}`);
					document.body.appendChild(link);
					link.click();
					setDownloadVisible(false);
					setMerchant(Number);
				})
				.catch((error) => {
					setDownloadVisible(false);
					setMerchant(Number);
					console.log(error?.response);
					ToastMessage(type.ERROR, error.response.data.message);

					if (error.response) {
					} else if (error.request) {
					} else {
					}
				});
		} else {
			getTransactionsReportType(
				fromDate,
				toDate,
				transactionType,
				expectedTrans,
				merchant
			)
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(`download`, `report.${'csv'}`);
					document.body.appendChild(link);
					link.click();
					setDownloadVisible(false);
					setMerchant(Number);
					setTransactionType('');
					setExpectedTrans('');
				})
				.catch((error) => {
					setDownloadVisible(false);
					setMerchant(Number);
					setTransactionType('');
					setExpectedTrans('');
					console.log(error?.response);
					ToastMessage(type.ERROR, error.response.data.message);
					if (error.response) {
					} else if (error.request) {
					} else {
					}
				});
		}
	};

	const handleCancel = () => {
		setDownloadVisible(false);
		setMerchant(Number);
		setTransactionType('');
		setExpectedTrans('');
		setSwapOverview(false);
		setProcessorVisible(false);
	};

	const links = [
		{
			path: '/dashboard/transactions',
			title: 'Swap'
		},
		{
			path: '/dashboard/transactions/deposits',
			title: 'Deposits'
		},

		{
			path: '/dashboard/transactions/withdrawals',
			title: 'Withdrawals'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Swap />
		},
		{
			path: '/deposits',
			component: <Deposits />
		},
		{
			path: '/withdrawals',
			component: <Withdraws />
		}
	];

	// if (deposit.isError || withdrawal.isError || swap.isError) {
	//   return <Flex>Unexpected Error please check</Flex>;
	// }

	// console.log(merchant);
	return (
		<>
			<Styles>
				<Layout title={'Transactions'}>
					<Spacer height='30px' />
					<Overview
						swap={swap?.data?.data?.data}
						deposit={deposit?.data?.data?.data}
						withdrawal={withdrawal?.data?.data?.data}
						feeCollected={feeCollected?.data?.data?.data}
						loading={
							deposit?.isLoading ||
							deposit?.isLoading ||
							feeCollected?.isLoading
						}
						error={
							deposit?.error?.message ||
							swap?.error?.message ||
							feeCollected?.error?.message ||
							withdrawal?.error?.message
						}
					/>
					<Spacer height='30px' />
					<RouterTabs
						routes={routes}
						links={links}
						type='transactions'
						handleDownload={handleDownload}
						handleProcessorStatus={handleProcessorStatus}
						handleSwapOverview={handleSwapOverview}
					/>
				</Layout>
			</Styles>
			<ModalFrame
				visible={downloadVisible}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
			>
				<h3 style={{ textAlign: 'left' }}>Download Report</h3>
				<DownloadReport
					setFileType={setFileType}
					handleOk={handleOk}
					setFromDate={setFromDate}
					setToDate={setToDate}
					setTransactionType={setTransactionType}
					setExpectedTrans={setExpectedTrans}
					merchant={setMerchant}
				/>
			</ModalFrame>
			<ModalFrame
				visible={swapOverview}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
				width='615px'
			>
				<PaymentSummary />
			</ModalFrame>
			<ProcessorStatusTable
				visible={processorVisible}
				handleCancel={handleCancel}
			/>
		</>
	);
};

export default Index;
