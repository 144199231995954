import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const Styles = styled.div`
  width: 100%;
  background-color: #f3f6fb;
  padding: 25px;
`;

export const Card = styled(Flex)`
  padding: 10px;
  border-right: 1px solid rgba(223, 223, 223, 0.39);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .container {
    span:first-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #7171a6;
      padding-bottom: 20px;
    }
  }
  .viewall {
    span:first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #33334f;
      padding-bottom: 20px;
    }
    span:last-child {
      > svg {
        margin-right: 3px;
      }
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: #575757;
    }
  }
`;
