import { Checkbox } from "antd";
import React, { useState } from "react";
import { Container, Flex } from "../../../../../components/box";
import Button from "../../../../../components/button";
import { Styles, FormContainer } from "./styles";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import {
  disableAllCurrencies,
  disableCurrency,
  enableCurrency,
  getAllCurrencies,
  getpaymentMethodDeposit,
  getpaymentMethodWithdrawal,
} from "../../../../../services/Settings.service";
import { Loader } from "../../../../../components/loader";
import { AddNew } from "../../../../../assets";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormLayout from "./form";

const Index = () => {
  const queryClient = useQueryClient();
  const [checked, setChecked] = useState([]);
  const [unchecked, setUnchecked] = useState([]);
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState("");
  const [initialEditValues, setInitialEditValues] = useState({});
  const [id, setId] = useState("");

  const result = useQuery(
    "getAllCurrencies",
    async () => await getAllCurrencies()
  );

  const depositMethod = useQuery(
    "getpaymentMethodDeposit",
    async () => await getpaymentMethodDeposit()
  );
  const withdrawMethod = useQuery(
    "getpaymentMethodWithdrawal",
    async () => await getpaymentMethodWithdrawal()
  );

  const disableMutation = useMutation(
    async (data) => {
      await disableAllCurrencies(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllCurrencies");
        ToastMessage(type.SUCCESS, "All currencies disabled successfully");
        setChecked("");
        setUnchecked("");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const uncheckedMutation = useMutation(
    async (data) => {
      await disableCurrency(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllCurrencies");
        ToastMessage(type.SUCCESS, "Currencies disabled successfully");
        setChecked("");
        setUnchecked("");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const checkedMutation = useMutation(
    async (data) => {
      await enableCurrency(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllCurrencies");
        ToastMessage(type.SUCCESS, "Currencies enabled successfully ");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showModal = (method, id) => {
    // setConfirmLoading(true);
    setTimeout(() => setVisible(true), 500);
    setId(id);
    setAction(method);
    if (method === "edit") {
      result?.data?.data?.data?.find(
        (item) => item?.id === id && setInitialEditValues(item)
      );
      setTimeout(() => setVisible(true), 500);
    }
  };

  const handleDisableAll = () => {
    disableMutation.mutate();
  };

  const handleCancel = () => {
    setVisible(false);
    setAction("");
    setId("");
  };

  const handleChange = (e) => {
    const newChecked = e.target.name;
    if (e.target.checked === true) {
      const prevId = checked.find((id) => id === newChecked);
      if (newChecked !== prevId) setChecked([...checked, newChecked]);
    } else if (e.target.checked === false) {
      const prevId = unchecked.find((id) => id === newChecked);
      if (newChecked !== prevId) setUnchecked([...unchecked, newChecked]);
    }
  };

  const handleSubmit = () => {
    if (checked.length >= 1) {
      checkedMutation.mutate({ ids: checked });
    }
    if (unchecked.length >= 1) {
      uncheckedMutation.mutate({ ids: unchecked });
    }
  };

  if (result.status === "loading") {
    return <Loader />;
  }

  if (result.status === "error") {
    return <span>{result.error.message}</span>;
  }
  const currencies = result?.data?.data?.data;
  return (
    <>
      <Styles>
        <Container>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="baseline"
            className="currencies"
          >
            <h3>Manage supported currencies </h3>
            <Button
              Styles
              text={"Disable all currencies"}
              color={"#D60000"}
              size="md"
              onClick={handleDisableAll}
              type="button"
            />
          </Flex>
          <h3>Crypto</h3>
          <Flex
            flexDir="row"
            justifyContent="flex-start"
            className="crypto"
            flexWrap="wrap"
          >
            {currencies &&
              currencies.map(
                (item) =>
                  item.type === "CRYPTO" && (
                    <Flex
                      // width="max-content"
                      alignItem="center"
                      className="checkbox_conainer"
                    >
                      <Checkbox
                        key={item.id}
                        defaultChecked={item.status}
                        name={item.id}
                        onChange={handleChange}
                        style={{
                          width: "max-content",
                        }}
                      >
                        {item.name}
                      </Checkbox>
                      <span onClick={() => showModal("edit", item?.id)}>
                        (edit)
                      </span>
                    </Flex>
                  )
              )}
          </Flex>
          <h3 className="local-text">Local currency</h3>
          <Flex
            flexDir="row"
            justifyContent="flex-start"
            className="local"
            flexWrap="wrap"
          >
            {currencies &&
              currencies?.map(
                (item) =>
                  item.type === "FIAT" && (
                    <Flex alignItem="center" className="checkbox_conainer">
                      <Checkbox
                        key={item.id}
                        defaultChecked={item.status}
                        name={item.id}
                        onChange={handleChange}
                        style={{
                          width: "max-content",
                        }}
                      >
                        {item.name}
                      </Checkbox>
                      <span onClick={() => showModal("edit", item?.id)}>
                        (edit)
                      </span>
                    </Flex>
                  )
              )}
          </Flex>
          <Flex flexDir="row" justifyContent="space-between" className="btn">
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              width="40%"
              className="add-btn"
            >
              <AddNew />
              <Button
                Styles
                text={"Add new currency"}
                color={"#1A2CCE"}
                size="md"
                type="button"
                onClick={() => showModal("add")}
              />
            </Flex>

            <Button
              Styles
              text={"Save changes"}
              bgColor={"#1A2CCE"}
              color={"#FFFFFF"}
              size="md"
              type="submit"
              className="save"
              loading={checkedMutation.isLoading || uncheckedMutation.isLoading}
              onClick={handleSubmit}
            />
          </Flex>
        </Container>
      </Styles>
      <ModalFrame
        visible={visible}
        destroyOnClose={true}
        handleCancel={handleCancel}
        footer={null}
        width={941}
        pad="2rem 2rem 6rem 2rem"
      >
        <FormContainer>
          <FormLayout
            setVisible={setVisible}
            initialEditValues={initialEditValues}
            action={action}
            id={id}
            depositMethod={depositMethod?.data?.data?.data}
            withdrawMethod={withdrawMethod?.data?.data?.data}
          />
        </FormContainer>
      </ModalFrame>
    </>
  );
};

export default Index;
