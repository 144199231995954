import styled from "styled-components";

export const CustomTableMain = styled.section`
  .container {
    // height: 450px;
    // overflow: auto;
    /* @media (max-width: 600px) {
      height: 350px;
    } */
  }
  background: #fff;
  padding-bottom: ${({ paginator }) => !paginator && "20px"};
  width: 100%;
  table {
    background: #fff;
    border-collapse: collapse;
    text-align: start;
    width: 100%;
    overflow: auto;
    @media (max-width: 600px) {
      width: 700px;
    }
    thead {
      background: ${({ theadBkColor }) => theadBkColor || "#F1F4F8"};
      position: sticky;
      top: 0;
      z-index: 1;
      tr {
        th {
          text-align: start;
          color: #000a62;
          font-size: 12px;
          padding: 11px 22px;
        }
      }
    }
    tbody {
      tr {
        background: white;
        border: none;
        overflow: auto;
        border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
      }
    }
  }
`;

export const Td = styled.td`
  padding: 1.8rem 1.2rem 1.2rem 1.2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    strong {
      font-style: normal;
      font-weight: 4w00;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  span {
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
    color: ${({ tbodyColor }) => tbodyColor || "#1A1F36"};
  }
`;

export const CustomTd = styled.td`
  padding: 1rem;

  .status {
    padding: 7px;
    text-align: center;
    background-color: ${({ status }) =>
      (status === "pending" && "#FFF2D9") ||
      (status === "active" && "#C8FFC7")};
    color: ${({ status }) =>
      (status === "pending" && "#9C5502") ||
      (status === "active" && "#027200")};
    > span {
      font-size: 14px;
    }
  }
  div {
    display: flex;
    align-items: flex-end;
    .icon {
      width: 24px;
      height: 24px;
      background: #f0f0f0;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 12px;
    }
  }
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }
`;

export const Paginator = styled("div")`
  background: white;
  padding: 1em 5px;
  display: ${({ paginator }) => (paginator ? "block" : "none")};
  ul {
    justify-content: flex-end;
  }
`;
