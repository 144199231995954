import styled from 'styled-components';

export const FormContainer = styled('div')`
	padding: ${({ pad }) => pad || '2rem'};

	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 23px;
		text-align: center;
		color: #000000;
		margin: 0;
		padding-bottom: 20px;
	}
	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
		color: #5b5b5b;
		margin-bottom: 0;
	}
`;
