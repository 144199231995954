import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { FilterIcon } from '../../../../../assets';
import { Loader } from '../../../../../components/loader';
import { getActivities } from '../../../../../services/Settings.service';
import { StylesContainer } from './styles';
import CustomTable from './table';

const ActivitiesLog = () => {
	const [filter, setFilter] = useState({ page: 1 });

	const result = useQuery(
		'getActivities',
		async () => await getActivities(filter)
	);

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	if (result.isLoading) {
		return <Loader />;
	}

	//   console.log(result?.data?.data?.data?.meta, "data");

	return (
		<StylesContainer>
			<div className='btnContainer'>
				<ArrowLeftOutlined className='icon' />
				<Link to='/dashboard/settings/security' className='link'>
					Back
				</Link>
			</div>
			<div className='searchContainer'>
				<div className='selectWithIcon'>
					<FilterIcon className='filterIcon' />
					<Select
						size='large'
						// showSearch
						placeholder='Select filter'
						// optionFilterProp='children'
						// onChange={onChange}
						// onSearch={onSearch}
						defaultValue={'Events'}
						// filterOption={(input, option) =>
						// 	option.children.toLowerCase().includes(input.toLowerCase())
						// }
						style={{
							marginLeft: '10px',
							width: 140,
							borderRadius: '0 5px  5px 0'
						}}
						bordered={false}
					>
						<Select.Option value='Events'>Events</Select.Option>
					</Select>
				</div>
				<div
					style={{
						marginRight: 'auto',
						width: '50%',
						border: '0.7px solid #dfdfdf',
						borderRadius: '5px'
					}}
				>
					<Input
						bordered={false}
						style={{
							width: '100%'
						}}
						prefix={<SearchOutlined />}
					/>
				</div>

				<div className='selectWithIcon'>
					<Select
						size='large'
						placeholder='Select filter'
						// optionFilterProp='children'
						// onChange={onChange}
						// onSearch={onSearch}
						defaultValue={'Today'}
						style={{
							marginLeft: '10px',
							width: 140
						}}
						bordered={false}
					>
						<Select.Option value='Today'>Today</Select.Option>
					</Select>
				</div>
			</div>
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={['TIMESTAMP', 'EVENT', 'CHANNEL']}
				tableBody={result?.data?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#8e8e8e'
				tbodyColor='#141414'
				handleReadAll={() => []}
				pageSize={10}
				handlePageChange={handlePageChange}
				meta={result?.data?.data?.data?.meta}
				paginator
			/>
		</StylesContainer>
	);
};

export default ActivitiesLog;
