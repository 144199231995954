import styled from "styled-components";
import { Container } from "../../../../components/box";

export const StylesContainer = styled(Container)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f6fb;
  @media screen and (max-width: 600px) {
    padding: 20px;
  }
  .flex_container {
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
  h3 {
    color: #000000;
  }
  p {
    text-align: center;
    color: #3d3d3d;
    padding: 30px 5px 50px;
  }
  .link_box {
    background-color: #1a2cce;
    width: 100%;
    text-align: center;
    padding: 15px;
    color: #fff;
    border-radius: 5px;
  }
`;
