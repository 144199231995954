import styled from 'styled-components';
import { Flex } from '../../../../../components/box';

export const Styles = styled.div`
	width: 100%;
	padding-left: 3rem;
	padding-top: 2em;
	/* background-color: #f8f9fd; */
	// border-top: 0.1px solid #c4c4c4;
	@media (max-width: 900px) {
		padding: 20px 4%;
	}

	.notification {
		margin-top: 2em;
		// display: flex;
		// justify-content: space-between;
		// flex-direction: column;

		margin-bottom: 20.5px;
		// background: red;

		.info {
			// width: 300px;
			h5 {
				padding: 5px 0;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 21px;
				color: #061f39;
			}
			p {
				margin-top: 8px;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #575757;
			}
		}
		.info2 {
			display: flex;
			justify-content: space-between;
			// width: 300px;
			h3 {
				font-weight: 500;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #000a62;
			}
			p {
				margin-top: 8px;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #575757;
			}
		}
		.ant-switch {
			width: 37.89px;
			height: 22.33px;
		}

		.ant-switch-checked {
			background: #69ce1a;
			border-radius: 24.2105px;
		}
	}
`;

export const Container = styled(Flex)`
	.grid_container {
		padding-top: 2em;
		@media (max-width: 700px) {
			padding-top: 2em;
			grid-template-columns: repeat(1, 1fr);
		}
	}
`;
export const ProfileContainer = styled(Flex)`
	position: relative;
	.profile {
		border-radius: 50%;
		margin-right: 15px;
	}
	label {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		color: #1a1f36;
	}
	.verified_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 100px;
		padding: 5px 15px;
		border-radius: 3px;
		margin-left: 30px;
		background-color: ${({ status }) =>
			(status === 'unverified' && '#FFE4E4') ||
			(status === 'verified' && '#C8FFC7') ||
			(status === 'rejected' && '#FFE4E4') ||
			(status === 'pending' && '#FFF2D9')};
		span {
			color: ${({ status }) =>
				(status === 'unverified' && '#CE1A1A') ||
				(status === 'rejected' && 'red') ||
				(status === 'verified' && '#027200') ||
				(status === 'pending' && '#9C5502')};
		}
	}

	.sideButtons {
		display: flex;
		justify-self: flex-end;
		gap: 0 40px;
	}
`;

export const Box = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	> label {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #676767;
		padding: 10px 0;
	}
	> span {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		padding: 10px 0;
	}
	> button {
		color: #1a2cce;
	}
`;

export const Container3 = styled(Flex)`
	padding-top: 2em;
	h5 {
		padding: 5px 0;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		color: #061f39;
	}
	p {
		padding: 5px 0;
		font-size: 14px;
		line-height: 20px;
		color: #363636;
	}
	.check_input_container {
		margin-right: 10px;
		margin-bottom: 20px;
		.ant-checkbox-checked {
			.ant-checkbox-inner {
				background: #1a2cce;
				border-radius: 4px;
			}
		}
	}
`;
