import React, { useState } from 'react';
import { useQuery } from 'react-query';

import Layout from '../../../components/layouts';
import { Spacer } from '../../../components/spacer/styles';
import {
	getTotalMerchantSummaryCount,
	getTotalSuspendedMerchantSummary
} from '../../../services/Merchants.service';
import Compliance from './compliance';
import Merchants from './merchants';
import Metrics from './metrics';
import Summary from './summary';
import { Styles } from './summary/styles';
import Transactions from './transactions';

import ModalFrame from '../../../components/modal/ModalFrame';
import {
	getCompliancePendingRecent,
	getComplianceSummaryPending,
	getComplianceSummaryRejected,
	getComplianceSummaryVerified
} from '../../../services/Compliance.service';
import { getTransactionsSummaryTotalTransaction } from '../../../services/Transactions.service';

import FundAccountForm from './fundAccountForm/FundAccountForm';
import FundAccountResponse from './fundAccountForm/FundAccountResponse';

const Index = () => {
	const [visible, setVisible] = useState(false);
	const [visibleResponse, setVisibleResponse] = useState(false);
	const [type, setType] = useState('Fiat');

	// const swap = useQuery(
	// 	'getTransactionsSummarySwap',
	// 	async () => await getTransactionsSummarySwap()
	// );

	// const withdrawal = useQuery(
	// 	'getTransactionsSummaryWithdrawal',
	// 	async () => await getTransactionsSummaryWithdrawal()
	// );

	// const deposit = useQuery(
	// 	'getTransactionsSummaryDeposit',
	// 	async () => await getTransactionsSummaryDeposit()
	// );

	const totalTransaction = useQuery(
		'getTransactionsSummaryTotalTransaction',
		async () => await getTransactionsSummaryTotalTransaction()
	);

	const merchantsCount = useQuery(
		'getTotalMerchantSummaryCount',
		async () => await getTotalMerchantSummaryCount()
	);

	const complianceApproved = useQuery(
		'getComplianceSummaryVerified',
		async () => await getComplianceSummaryVerified()
	);

	const compliancePending = useQuery(
		'getComplianceSummaryPending',
		async () => await getComplianceSummaryPending()
	);

	const complianceSummaryRejected = useQuery(
		'getComplianceSummaryRejected',
		async () => await getComplianceSummaryRejected()
	);

	const pendingComplianceRecent = useQuery(
		'getCompliancePendingRecent',
		async () => await getCompliancePendingRecent()
	);

	// const merchantsCount = useQuery(
	// 	'getTotalMerchantSummaryCount',
	// 	async () => await getTotalMerchantSummaryCount()
	// );

	const merchantsSuspended = useQuery(
		'getTotalSuspendedMerchantSummary',
		async () => await getTotalSuspendedMerchantSummary()
	);
	const showFundAccountForm = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setVisibleResponse(false);
	};

	const handleOk = () => {
		setVisibleResponse(false);
	};
	const handleSubmit = (values) => {
		new Promise((resolve, reject) => {
			setTimeout(() => {
				console.log(values);

				resolve('Success!'); // Yay! Everything went well!
				setVisible(false);
				if (values?.currency_type === 'Crypto') setType(values?.currency_type);
				else setType(values?.currency_type);
				setVisibleResponse(true);
			}, 250);
		});
	};
	const handleChange = () => {
		new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve('Success!'); // Yay! Everything went well!
				setVisibleResponse(false);
				setVisible(true);
			}, 250);
		});
	};

	return (
		<>
			<Styles>
				<Layout title={'Overview'} bgColor='#eef2f7'>
					<Summary
						totalTransaction={totalTransaction?.data?.data?.data}
						merchantsCount={merchantsCount?.data?.data?.data}
						loading={totalTransaction.isLoading || merchantsCount.isLoading}
						fundAccount={showFundAccountForm}
					/>
					<Spacer heigth='20px' />
					<Transactions />
					<Spacer heigth='20px' />
					<Merchants
						active={merchantsCount?.data?.data?.data}
						verified={complianceApproved?.data?.data?.data}
						suspended={merchantsSuspended?.data?.data?.data}
						loading={
							merchantsCount.isLoading ||
							complianceApproved.isLoading ||
							merchantsSuspended.isLoading
						}
					/>
					<Spacer heigth='20px' />
					<Metrics />
					<Spacer heigth='20px' />
					<Compliance
						compliancePending={compliancePending?.data?.data?.data}
						complianceApproved={complianceApproved?.data?.data?.data}
						pendingCompliance={pendingComplianceRecent?.data?.data?.data}
						complianceSummaryRejected={
							complianceSummaryRejected?.data?.data?.data
						}
						loading={
							complianceApproved.isLoading ||
							compliancePending.isLoading ||
							complianceSummaryRejected.isLoading
						}
						loadingPendingRecentCompliance={pendingComplianceRecent.isLoading}
					/>
				</Layout>
			</Styles>
			<ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
				<FundAccountForm handleSubmit={handleSubmit} />
			</ModalFrame>
			<ModalFrame
				visible={visibleResponse}
				handleCancel={handleCancel}
				footer={null}
			>
				<FundAccountResponse
					type={type}
					changePayment={handleChange}
					handleOk={handleOk}
				/>
			</ModalFrame>
		</>
	);
};

export default Index;
