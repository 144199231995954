import apiClient from './axios';

const EndPoints = {
	getMarketRates: `/admin/market`,
	addNewMarketRate: `/admin/market`,

	getRatesFromSource(from, to) {
		return `/admin/market/rates?to_currency=${to}&from_currency=${from}&org=true`;
	},

	editMarketRate(id) {
		return `/admin/market/${id}`;
	},

	deleteMarketRate(id) {
		return `/admin/market/${id}`;
	},

	// market rates api for each merchant//
	getMarketRatesForMerchant(id) {
		return `/admin/market?merchantId=${id}`;
	},

	addMarketRatesForMerchant(id) {
		return `/admin/market?merchantId=${id}`;
	}
};

export const getMarketRates = async () => {
	const res = await apiClient.get(EndPoints.getMarketRates);
	return res;
};

export const addNewMarketRate = async (payload) => {
	const res = await apiClient.post(EndPoints.addNewMarketRate, payload);
	return res;
};

export const deleteMarketRatesById = async (id) => {
	const res = await apiClient.delete(EndPoints.deleteMarketRate(id));
	return res;
};

export const getRatesFromSource = async (from, to) => {
	const res = await apiClient.get(EndPoints.getRatesFromSource(from, to));
	return res;
};

export const editMarketRate = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.editMarketRate(id), payload);
	return res;
};

// market rates for each merchant//
export const getMarketRatesForMerchant = async (id) => {
	const res = await apiClient.get(EndPoints.getMarketRatesForMerchant(id));
	return res;
};

export const addMarketRatesForMerchant = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.addMarketRatesForMerchant(id),
		payload
	);
	return res;
};

// export const EditMarketRatesForMerchant = async (id, payload) => {
//   const res = await apiClient.patch(
//     EndPoints.addMarketRatesForMerchant(id),
//     payload
//   );
//   return res;
// };
