import styled from 'styled-components';
import { Flex } from '../box';

export const PopoverStyle = styled('div')`
	padding-top: 8px;

	a {
		padding: 15px 46px 15px 20px;
		display: flex;
		align-items: flex-start;
		div {
			// width: 180px;
			display: flex;
			align-items: center;
			// justify-content: space-between;
			svg {
				width: 25px;
			}
			p {
				margin-left: 20px;
				text-align: left;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #434343;
				margin-bottom: 0;
			}
		}
	}
`;

export const BulkContainer = styled(Flex)`
	.select-content {
		margin-left: 15px;
		// width: 220px;
		justify-content: center;
		align-items: center;

		padding: 14px 15px;
		background: #fafafa;
		border: 0.7px solid #c5c5c5;
		box-sizing: border-box;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
		border-radius: 3px;

		span {
			margin-right: 15px;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #2b2b2b;
		}

		.formatText {
			width: fit-content;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

export const SelectContainer = styled(Flex)`
	width: 100%;
	cursor: pointer;
	// width: 150px;

	// width: 150px;
	justify-content: space-between;
	gap: 20px;

	@media (max-width: 1200px) {
		.select-content {
			// align-self: flex-end;
			// width: 0;
			// justify-content: flex-end !important;
		}
	}

	align-items: center;
	background: #ffffff;
	border: 0.7px solid #dfdfdf;
	box-sizing: border-box;
	padding: 12px 15px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
	> span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #969696;
		// margin-right: 10px;
	}

	.select-content {
		width: 220px;
		justify-content: flex-end !important;
		// margin-left: 10px;
		span {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
			margin-right: 15px;
		}

		.formatText {
			width: fit-content;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;
