import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
	DefaultCurrencyImage,
	DeleteIcon,
	DisableIcon,
	DuplicateIcon,
	EditIcon
} from '../../../../../assets';
import { Flex } from '../../../../../components/box';
import { EmptyState } from '../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../components/paginator';
import PopOver from '../../../../../components/popover/PopOver';
import { generateID } from '../../../../../utils/generateID';
import { BottomPanel } from '../../cross-fees/table/styles';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	showDelete,
	handleEdit,
	showDuplicate,
	handleDisable
}) => {
	const [pageOfItems, setPageOfItems] = useState([]);

	const onChangePage = (items) => {
		setPageOfItems(items);
	};
	const returnTableRow = (data, i) => {
		return (
			<React.Fragment key={i}>
				<tr
					key={generateID(10)}
					style={{
						textDecoration: data?.status === false ? 'line-through' : null
					}}
				>
					<Td>
						<Flex alignItems='flex-start' justifyContent='flex-start'>
							{data.icon === '' || null ? (
								<DefaultCurrencyImage />
							) : data.icon ? (
								<img src={data.icon} alt='' />
							) : (
								<DefaultCurrencyImage />
							)}
							<span style={{ marginLeft: '10px' }}> {data?.name}</span>
						</Flex>
					</Td>
					<Td>{data?.method}</Td>
					<Td>{data?.provider}</Td>
					<Td>{data?.currencies?.join(', ')}</Td>
					<Td>{data?.type}</Td>
					<Td>
						{data?.fees.slice(0, 1).map((item, i) => (
							<span key={i * data.id}>
								{`${item?.fee}${item?.fee_type === 'FLAT' ? '' : '%'}`}
							</span>
						))}
					</Td>
					<CustomTd>
						<PopOver key={data?.id} placement='leftTop' i={data?.id}>
							<Link
								to={`#`}
								onClick={() => {
									data.method === 'Deposit'
										? handleEdit(data?.id, 'depositEdit', data?.name)
										: handleEdit(data?.id, 'withdrawalEdit', data.name);
								}}
							>
								<div>
									<EditIcon />
									<p>Edit method</p>
								</div>
							</Link>
							<Link
								to={`#`}
								onClick={() =>
									data.method === 'Deposit'
										? handleDisable(data?.id, 'depositDisable', data.status)
										: handleDisable(data?.id, 'withdrawalDisable', data.status)
								}
							>
								<div>
									<DisableIcon />
									<p>{data.status === true ? 'Disable' : 'Enable'}</p>
								</div>
							</Link>
							<Link
								to={`#`}
								onClick={() =>
									data.method === 'Deposit'
										? showDuplicate(data?.id, 'depositDuplicate')
										: showDuplicate(data?.id, 'withdrawaldepositDuplicate')
								}
							>
								<div>
									<DuplicateIcon />
									<p>Duplicate</p>
								</div>
							</Link>
							<BottomPanel>
								<Link
									to={`#`}
									onClick={() =>
										data.method === 'Deposit'
											? showDelete(data?.id, 'depositDelete')
											: showDelete(data?.id, 'withdrawalDelete')
									}
								>
									<div>
										<DeleteIcon color={'#C10606'} />

										<p
											style={{
												color: '#C10606'
											}}
										>
											Delete
										</p>
									</div>
								</Link>
							</BottomPanel>
						</PopOver>
					</CustomTd>
				</tr>
			</React.Fragment>
		);
	};
	return (
		<>
			{tableBody.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={i}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{paginator
									? pageOfItems?.map((data, idx) => returnTableRow(data, idx))
									: tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination
							items={tableBody}
							pageSize={pageSize || 5}
							prevNext={prevNext || null}
							onChangePage={onChangePage}
							firstLast={firstLast || null}
						/>
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
