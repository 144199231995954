import styled from 'styled-components';

export const TabWrapper = styled('div')`
	width: 100%;
	.tab-button {
		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			text-decoration: underline;
		}
	}
	.tab-list {
		padding-left: 0;
		display: flex;
		width: fit-content;
		background-color: #fff;
		border-bottom: 0.1px solid #959595 !important;
		border-radius: 4px;
		// height: auto;
		align-items: center;
		// justify-content: space-between;
		transition: 0.3s ease-out background;
		overflow-x: hidden;
		scrollbar-color: #e3e3e3 transparent;
		scrollbar-width: none;
		// margin-bottom: 0;

		@media (max-width: 1191px) {
			width: 100%;
		}
	}

	.tab-list-item {
		list-style: none;
		margin-right: 2.75rem;
		padding: 0.8rem;
		flex: 1;
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 0.05rem;
		height: 100%;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #7d7d7d;
		cursor: pointer;
		border-bottom: 3px solid transparent;

		line-height: 21px;
	}

	.tab-list-active {
		position: relative;
		color: #1a2cce;
		border-bottom: 4px solid #1a2cce;
		font-weight: 600;
		::after {
			content: '';
			width: 50%;
			height: 100%;
			border-radius: 0 4px 4px 0;
			background: #fafafa;
			opacity: 0.2;
			position: absolute;
			left: 50%;
			animation: left 0.8s ease-in-out 1 alternate forwards;
		}
		::before {
			content: '';
			width: 50%;
			height: 100%;
			border-radius: 4px 0 0 4px;
			background: #fafafa;
			opacity: 0.2;
			position: absolute;
			right: 50%;
			animation: left 0.8s ease-in-out 1 forwards;
		}
	}

	@media (max-width: 1240px) and (min-width: 920px) {
		.tab-list {
			li {
				padding: 0.7rem 4.125rem 0.7rem 0.4rem;
				font-size: 16px;
			}
		}
	}
	@media (max-width: 920px) {
		.tab-list li {
			font-size: 14px;
			padding: 0 10px 10px;
		}
	}
`;
export const TabWrapperMerchant = styled('div')`
	// width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: flex-start;
	margin-bottom: 0.8rem;

	.tab-list {
		padding-left: 0;
		padding-bottom: 0;
		display: flex;
		width: fit-content;
		// border-radius: 4px;

		// height: auto;
		overflow: hidden;
		border-bottom: 0.1px solid #959595 !important;

		align-items: center;
		justify-content: space-between;
		transition: 0.3s ease-out background;
		// overflow-x: scroll;
		// scrollbar-color: #e3e3e3 transparent;
		// scrollbar-width: 0.1px;
		margin-bottom: 0px !important;

		// ::-webkit-scrollbar {
		// 	// overflow: hidden;
		// 	// width: 0.1px;
		// 	// scrollbar-width: 0.1px;
		// 	// background: transparent;
		// 	// // background: #fafafa ;
		// 	// margin-bottom: 0px !important;
		// }
		@media (max-width: 1191px) {
			width: 100%;
		}
	}

	.tab-list-item {
		list-style: none;
		margin-right: 2.75rem;
		padding: 0 0.8rem 0 0.8rem;
		// padding: 0.8rem 0.8rem 0 0.8rem;
		// flex: 1;
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 0.05rem;
		// background: #fafafa;

		// height: 100%;
		// white-space: nowrap;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		color: #7d7d7d;
		cursor: pointer;
		border-bottom: 3px solid transparent;
		line-height: 21px;
	}

	.tab-list-active {
		position: relative;
		color: #1a2cce;
		border-bottom: 4px solid #1a2cce;
		font-weight: 600;
		::after {
			content: '';
			width: 50%;
			// height: 100%;
			border-radius: 0 4px 4px 0;
			background: #fafafa;
			opacity: 0.2;
			position: absolute;
			left: 50%;
			animation: left 0.8s ease-in-out 1 alternate forwards;
		}
		::before {
			content: '';
			width: 50%;
			// height: 100%;
			border-radius: 4px 0 0 4px;
			background: #fafafa;
			opacity: 0.2;
			position: absolute;
			right: 50%;
			animation: left 0.8s ease-in-out 1 forwards;
		}
	}

	@media (max-width: 1240px) and (min-width: 920px) {
		.tab-list {
			li {
				padding: 0.7rem 4.125rem 0.7rem 0.4rem;
				font-size: 16px;
			}
		}
	}
	@media (max-width: 920px) {
		.tab-list li {
			font-size: 14px;
			padding: 0 10px 10px;
		}
	}
`;
