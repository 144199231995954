import styled from "styled-components";

export const Styles = styled.div`
  .container {
    padding: 20px 5px;
    cursor: pointer;
    :last-child {
      border-bottom: none !important;
    }
  }
  .ant-input {
    border: none;
  }
  .ant-input-group-addon {
    background: transparent;
    border: none !important;
  }
  .currency_holder {
    margin-left: 20px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #1a1f36;

      :last-child {
        margin-top: 5px;
        font-size: 14px;
        line-height: 17px;
        color: #8d8d8d;
      }
    }
  }

  text-align: left;
  .value_holder {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #767676;
    }
  }

  .ant-tabs-nav-wrap {
    justify-content: center !important;
  }
  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0 30px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1a2cce !important;
  }

  .ant-tabs-tab:hover {
    color: #1a2cce !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 5px solid #1a2cce;
  }
  .spin {
    padding: 40px 0 20px 0;
  }
`;
