import React from "react";
import { StylesContainer } from "./styles";
import { Flex, Frame } from "../../../../components/box";
import passwordReset from "../../../../assets/images/passwordReset.svg";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <StylesContainer margin={"auto"}>
      <Flex
        className="flex_container"
        width={"550px"}
        pad={"4em"}
        flexDir={"column"}
        bgColor={"#ffff"}
      >
        <Frame height={"90px"} width={"90px"} margin={"0px 0px 40px"}>
          <img alt="email-svg" src={passwordReset} />
        </Frame>
        <h3>Password reset successful</h3>
        <p>
          Your password has been reset successfully. Access your account your
          new password
        </p>
        <div className="link_box">
          <Link to={"/"}>Go to Login</Link>
        </div>
      </Flex>
    </StylesContainer>
  );
};

export default Index;
