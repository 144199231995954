import React from "react";
import Fiat from "./Fiat";
import Crypto from "./crypto";
import { Styles } from "./styles";
import Overview from ".././overveiw";
import Layout from "../../../../components/layouts";
import { Spacer } from "../../../../components/spacer/styles";
import RouterTabs from "../../../../components/Tabs/routerTab";
import {
  getApprovalSummaryApproved,
  getApprovalSummaryFeeCollected,
  getApprovalSummaryPending,
  getApprovalSummaryRejected,
} from "../../../../services/Approval.service";
import { useQuery } from "react-query";

const Index = () => {
  const pending = useQuery(
    "getApprovalSummaryPending",
    async () => await getApprovalSummaryPending()
  );

  const approved = useQuery(
    "getApprovalSummaryApproved",
    async () => await getApprovalSummaryApproved()
  );

  const rejected = useQuery(
    "getApprovalSummaryRejected",
    async () => await getApprovalSummaryRejected()
  );

  const feeCollected = useQuery(
    "getApprovalSummaryFeeCollected",
    async () => await getApprovalSummaryFeeCollected()
  );

  const links = [
    {
      path: "/dashboard/approvals/approved/",
      title: "Fiat",
    },
    {
      path: "/dashboard/approvals/approved/crypto",
      title: "Crypto",
    },
  ];

  const routes = [
    {
      path: "/",
      component: <Fiat />,
    },
    {
      path: "/crypto",
      component: <Crypto />,
    },
  ];

  return (
    <Layout title={"Approvals"}>
      <Styles>
        <Spacer height="30px" />
        <Overview
          pending={pending?.data?.data?.data}
          approved={approved?.data?.data?.data}
          rejected={rejected?.data?.data?.data}
          feeCollected={feeCollected?.data?.data?.data}
          loading={
            pending.isLoading ||
            rejected.isLoading ||
            feeCollected.isLoading ||
            approved.isLoading
          }
        />
        <Spacer height="25px" />
        <h2>Approved Approvals</h2>
        <Spacer height="20px" />
        <RouterTabs type="primary-tab" routes={routes} links={links} />
      </Styles>
    </Layout>
  );
};

export default Index;
