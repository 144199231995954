import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DefaultCurrencyImage, DotIcon } from '../../../../../../assets';
import { Frame } from '../../../../../../components/box';
import { OverFlowScrollBar } from '../../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../../components/paginator';
import PopOver from '../../../../../../components/popover/PopOver';
import { generateID } from '../../../../../../utils/generateID';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	showFreeze,
	showUnfreeze
}) => {
	const [pageOfItems, setPageOfItems] = useState([]);

	const onChangePage = (items) => {
		setPageOfItems(items);
	};

	const returnTableRow = (data) => {
		return (
			<tr key={generateID(17)}>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div>
						<Frame height={'30px'} width={'30px'}>
							{data?.icon ? (
								<img src={data.icon} alt='' />
							) : (
								<DefaultCurrencyImage />
							)}
						</Frame>
						{data?.name}
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.symbol}</span>
						<span>{data?.balance?.toLocaleString()}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.symbol}</span>
						<span>{data?.liquidity_balance?.toLocaleString()}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span>{data?.symbol}</span>
						<span>{data?.pending_balance?.toLocaleString()}</span>
					</div>
				</Td>
				<CustomTd>
					<PopOver
						placement='rightTop'
						main={
							<div className='icon_box'>
								<DotIcon />
							</div>
						}
					>
						<Link to='#' onClick={() => showFreeze(data, 'freeze')}>
							<div>
								{/* <ViewTransactionIcon />  */}
								<p>Freeze amount</p>
							</div>
						</Link>
						<Link to='#' onClick={() => showUnfreeze(data, 'unfreeze')}>
							<div>
								{/* <UserAvatar /> */}
								<p>Unfreeze amount</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{paginator
									? pageOfItems?.map((data, idx) => returnTableRow(data))
									: tableBody?.map((data, idx) => returnTableRow(data))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination
							items={tableBody}
							pageSize={pageSize || 5}
							prevNext={prevNext || null}
							onChangePage={onChangePage}
							firstLast={firstLast || null}
						/>
					</Paginator>
				</CustomTableMain>
			) : (
				<p>No data</p>
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
