import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { Styles } from './styles';

import { useMutation } from 'react-query';
import Button from '../../../../components/button';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { inviteMerchant } from '../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../utils/toastr';

const InviteMerchant = () => {
	const [visible, setVisible] = useState(false);

	const [form] = Form.useForm();

	const inviteMutation = useMutation(
		async (data) => {
			await inviteMerchant(data);
		},
		{
			onSuccess: () => {
				ToastMessage(type.SUCCESS, 'Invite send successfully');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const show = () => setVisible(true);

	const handleCancel = () => {
		form.resetFields();
		setVisible(false);
	};

	const handleSubmit = (values) => {
		inviteMutation.mutate(values);
	};
	return (
		<>
			<Button
				text='Invite a merchant'
				color='#1A2CCE'
				size='sm'
				borderRadius='5px'
				onClick={show}
				style={{ textDecoration: 'underline' }}
			/>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text='Invite merchant'
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						key='submit'
						form='inviteMerchant'
						loading={inviteMutation.isLoading}
						style={{ padding: '0.85rem 50px' }}
					/>
				]}
				width={450}
			>
				<Styles>
					<h3 style={{ textAlign: 'left' }}>Invite a merchant</h3>
					<Form
						form={form}
						layout='vertical'
						onFinish={handleSubmit}
						id='inviteMerchant'
						initialValues={{
							layout: 'vertical',
							size: 'large'
						}}
					>
						<Form.Item
							label='Email Address'
							name='email'
							rules={[
								{ required: true, message: ' Email Address is required' }
							]}
							style={{
								width: '100%'
							}}
						>
							<Input type='email' />
						</Form.Item>
					</Form>
				</Styles>
			</ModalFrame>
		</>
	);
};

export default InviteMerchant;
