import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { DisableIcon } from '../../../../../assets';
import { EmptyState } from '../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../components/paginator';
import PopOver from '../../../../../components/popover/PopOver';
import { generateID } from '../../../../../utils/generateID';
import {
	CustomTableMain,
	CustomTd,
	// PopoverStyle,
	// BottomPanel
	Paginator,
	Td
} from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	handleEdit,
	handleSuspend,
	handleDelete,
	handleReset2fa,
	handleChangePassword,
	handlePageChange,
	meta,
	handleEnableDisable,
	setMerchantId
}) => {
	const returnTableRow = (data, i) => {
		return (
			<tr key={i}>
				<Td>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px'
						}}
					>
						<img
							src={data.logo}
							alt='merchant_logo'
							style={{
								maxWidth: '32px',
								maxHeight: '32px'
							}}
						/>

						<span style={{ color: '#1A1F36' }}>{data.name}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>{data.reference}</span>
					</div>
				</Td>

				<CustomTd>
					<PopOver key={data?.i}>
						<Link
							to={`#`}
							onClick={() =>
								handleEnableDisable(data?.merchantId, data?.status)
							}
						>
							<div>
								<DisableIcon />
								<p>{data.status === true ? 'Disable' : 'Enable'}</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length >= 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
