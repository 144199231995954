import styled from 'styled-components';

export const Styles = styled.div`
	h3 {
		font-style: normal;
		font-weight: 500;
		font-size: 15.537px;
		line-height: 20px;

		/* text color */

		color: #000a62;
		margin-bottom: 22px;
	}

	.ant-form-item {
		width: 250px;
	}

	.ant-select-selector {
		height: 46px !important;
		padding: 5px 11px !important;
	}
`;
