import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Collapse, Divider, Form, Input, Select } from 'antd';
import Button from '../../../../../../../components/button';
import { useQuery } from 'react-query';
import { getSingleCurrencyPair } from '../../../../../../../services/Others.service';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

const FormLayout = ({ action, handleSubmit, initialEditValues }) => {
	const singleCurrencyPair = useQuery(
		'getSingleCurrencyPair',
		async () => await getSingleCurrencyPair()
	);

	const { Panel } = Collapse;

	const pair = initialEditValues?.currency_pair?.replace(/\//g, ' ').split(' ');

	const [form] = useForm();
	const [leftPair, setLeftPair] = useState(pair[0]);

	const currencyPair = singleCurrencyPair?.data?.data?.data;

	const handleInputValueChange = (e) => {
		const { value } = e;
		const newPair = value.replace(/\//g, ' ').split(' ');
		setLeftPair(newPair[0]);
	};

	const onChange = (value, key) => {
		const fields = form.getFieldsValue();
		const { settlementTimeList } = fields;
		// Object.assign(settlementTimeList[key], { type: value });
		// form.setFieldsValue({ settlementTimeList });
		const updatedFields = Object.keys(settlementTimeList[key])
			.filter((name) => form.getFieldError(name).length)
			.map((name) => ({ name, errors: [] }));
		form.setFieldsValue(updatedFields);
	};

	async function formValidator(values, fieldName) {
		// Some validate logic to get errors
		const fieldsErrors = { field1: ['23333'], field5: ['6666'] };
		const errors = fieldsErrors[fieldName];
		return errors ? Promise.reject(errors) : Promise.resolve();
	}

	return (
		<>
			<h3 style={{ textAlign: 'left' }}>
				{action === 'add' ? ' Add new settlement time' : 'Edit settlement time'}
			</h3>
			<>
				<Form
					id='submitForm'
					onFinish={handleSubmit}
					form={form}
					// onValuesChange={onChange}
					layout={'vertical'}
					initialValues={{
						currency_pair:
							action === 'edit' ? initialEditValues?.currency_pair : null,
						settlementTimeList:
							action === 'edit' ? initialEditValues.settings : null
					}}
				>
					<Form.Item
						label='Currency pair'
						name='currency_pair'
						rules={[
							{
								required: true,
								message: 'currency pair is required!'
							}
						]}
					>
						<Select
							size='large'
							style={{ width: '100%' }}
							showSearch
							onChange={(e, name) => {
								handleInputValueChange(name);
							}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder='Select currency pair'
						>
							{currencyPair?.map((item) => (
								<Select.Option key={item} value={item}>
									{item}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.List name='settlementTimeList'>
						{(settlementTimeList, { add, remove }) => {
							return (
								<>
									{settlementTimeList.map((settlementTime) => (
										<Collapse
											defaultActiveKey={0}
											key={settlementTime.key}
											bordered={false}
											destroyinactivepanel={true}
											expandiconposition={'right'}
											expandIcon={({ isActive }) =>
												isActive ? (
													<DownOutlined />
												) : (
													<MinusCircleOutlined
														style={{ fontSize: '20px' }}
														onClick={(e) => {
															e.stopPropagation();
															remove(settlementTime.name);
														}}
													/>
												)
											}
											className='site-collapse-custom-collapse'
										>
											<Panel
												// header={`${min?.map((id) => index === id)}-${max?.map(
												//   (id) => index === id
												// )}`}
												key={settlementTime.key}
												className='site-collapse-custom-panel'
											>
												<Form.Item
													label='Minimum amount'
													// onChange={(e) => onChange(e, settlementTime.key)}
													name={[settlementTime.key, 'minimum_amount']}
													rules={[
														{
															required: true,
															message: 'Minimum amount is required!',
															min: 1
														}
													]}
												>
													<Input
														// onChange={(e, index) => handleChangeMin(e, index)}
														type='number'
														min={1}
														placeholder='input minimum amount'
														addonAfter={leftPair !== '' ? leftPair : ''}
													/>
												</Form.Item>
												<Form.Item
													label='Maxmum amount'
													name={[settlementTime.key, 'maximum_amount']}
													// dependencies={[settlementTime.key, "minimum_amount"]}
													// validateTrigger="onSubmit"
													// onChange={(e) => onChange(e, settlementTime.key)}
													// hasFeedback
													rules={[
														{
															required: true,
															message: 'maximum amount is required!',
															min: 1
														}
														// ({ getFieldValue }) => ({
														//   validator(_, value) {
														//     if (
														//       !value ||
														//       value >
														//         getFieldValue([
														//           settlementTime.key,
														//           "minimum_amount",
														//         ])
														//     ) {
														//       return Promise.resolve();
														//     } else {
														//       return Promise.reject(
														//         "maximum value must be greater than minimum value"
														//       );
														//     }
														//   },
														// }),
													]}
												>
													<Input
														type='number'
														placeholder='input maximum amount'
														addonAfter={leftPair !== '' ? leftPair : ''}
														min={1}

														// onChange={(e, index) => handleChangeMax(e, index)}
													/>
												</Form.Item>
												<Form.Item
													label='Settlement time'
													name={[settlementTime.key, 'settlement_time']}
													rules={[
														{
															required: true,
															message: 'Settlement time is required!'
														}
													]}
												>
													<Input
														type='number'
														placeholder='input settlement amount'
														addonAfter='mins'
													/>
												</Form.Item>
											</Panel>
											<Divider />
										</Collapse>
									))}
									<Button
										Styles
										text={'Add new settlement time'}
										color={'#1A2CCE'}
										size='md'
										type='button'
										onClick={() => {
											add();
										}}
										style={{ padding: '0', textDecoration: 'underline' }}
									/>
								</>
							);
						}}
					</Form.List>
				</Form>
			</>
		</>
	);
};

export default FormLayout;
