import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px 30px 0px;
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	h3 {
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
		/* identical to box height */

		color: #1a1f36;
		margin-bottom: 30px;
	}
`;

export const StylesAccount = styled.div`
	background: #f3f6fb;
	border-radius: 4px;
	padding: 30px 35px;

	h3 {
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;

		color: #000000;
		text-align: left;
		padding-bottom: 0;
	}
	p {
		margin: 0;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		color: #000000;
		align-items: center;
		svg {
			margin-right: 10px;
		}
	}
`;
