import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px 30px 0px;
	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		color: #1a1f36;
	}
	.download {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-align: right;
		text-decoration-line: underline;
		color: #1a2cce;
		margin-left: 20px;
		cursor: pointer;
	}
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	// .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector {
		// width: 220px;
		padding: 2px 11px;
		height: 46px !important;
	}
`;

export const RateStyle = styled.div`
	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		color: #000000;
		text-align: left;
		margin-bottom: 11px;

		:nth-child(3) {
			text-align: right;
		}
	}
	.display {
		margin-top: 10px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		// width: 220px;
		padding: 5px 11px !important;
		height: 46px !important;
	}
	.ant-input {
		// width: 220px;
	}

	.currency-settings {
		background: #f9f9f9;

		border-radius: 1px;

		h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
		}
		// .ant-select
		.ant-input,
		.ant-select-selector {
			width: 100% !important;
			// width: 186px;
			// height: 52px;
			background: #ffffff;
			border: 1px solid #d7d7d7;
			box-sizing: border-box;
			border-radius: 5px;
			line-height: 40px;
			padding-top: 5px;
		}
	}
`;

export const InputHolderStyle = styled.div`
	background: #f8f8f8;
	padding: 20px;
	margin-top: 30px;

	.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
		.ant-select-selector {
		padding: 2px 11px;
		height: 46px !important;
	}
`;
