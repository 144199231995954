import { Radio, Select, Space } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useAuthState } from '../../context';
import { getAllCurrencies } from '../../services/Settings.service';
import { formatType } from '../../utils/helpers';
import { Container, Flex } from '../box';
import Button from '../button';
import { CustomPicker } from '../customDatePicker/CustomPicker';
import PopOver from '../popover/PopOver';
import { PopDetails, SelectContainer } from './styles';
const { Option } = Select;

const Filters = ({
	selectedDateFrom,
	selectedDateTo,
	value,
	onChange,
	handleSelectFromChange,
	handleSelectToChange,
	currency,
	statusValue,
	onChangeStatus,
	source,
	handleCurrencyChange,
	refetch,
	handleSelectCountry,
	handleSelectKyc,
	loading,
	merchantTransactions,
	onChangeType,
	typeValue,
	handlePendingBalanceChange,
	options,
	merchantList
}) => {
	const { countries } = useAuthState();
	const [currencies, setCurrencies] = React.useState([]);

	const checkToken = localStorage.getItem('access_token');
	useQuery('getAllCurrencies', async () => await getAllCurrencies(), {
		onSuccess: (res) => {
			setCurrencies(res?.data?.data);
		},
		onError: (error) => <p> {error.response.data.mesaage}</p>,
		refetchOnMount: 'always',
		enabled: !!checkToken
	});

	const date = new Date();
	const y = date.getFullYear();
	const m = date.getMonth();

	const filterByStatus = ['Active', 'Supended'];
	const filterByKYC = ['Verified', 'Pending', 'Rejected'];

	const firstMonthDay = new Date(y, m, 1);
	const lastMonthDay = new Date(y, m + 1, 0);
	const firstWeekDay = new Date(date.setDate(date.getDate() - date.getDay()));
	const lastWeekDay = new Date(
		date.setDate(date.getDate() - date.getDay() + 6)
	);
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	return (
		<>
			<Flex
				className='fiter_container'
				width={
					(source === 'compliance' && '45%') ||
					(source === 'overview' && '50%') ||
					(source === 'wallet-compliance' && '45%') ||
					(source === 'transaction' && ' 70%') ||
					(source === 'refund' && ' 50%') ||
					(source === 'balance' && '70%') ||
					(source === 'merchants' && '60%')
				}
				justifyContent='flex-end'
				gap={source === 'overview' && 20}
			>
				{source === 'compliance' && (
					<>
						<SelectContainer>
							<span>Country</span>
							<Select
								className='select'
								defaultValue='All'
								width='40%'
								bordered={false}
								onChange={handleSelectCountry}
							>
								<>
									<Option value='All'>All</Option>
									{countries?.map((item, i) => (
										<Option className='select' value={item?.name} key={i}>
											{item.name}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
						<SelectContainer>
							<span>Status</span>
							<Select
								className='select'
								defaultValue='All'
								bordered={false}
								value={statusValue}
								onChange={onChangeStatus}
							>
								<>
									<Option value='All'>All</Option>
									{filterByStatus.map((item, i) => (
										<Option className='select' value={item} key={i}>
											{item}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
					</>
				)}
				{source === 'wallet-compliance' && (
					<>
						{/* <SelectContainer>
							<span>Country</span>
							<Select
								className='select'
								defaultValue='All'
								width='40%'
								bordered={false}
								onChange={handleSelectCountry}
							>
								<>
									<Option value='All'>All</Option>
									{countries?.map((item, i) => (
										<Option className='select' value={item?.name} key={i}>
											{item.name}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer> */}
						<SelectContainer>
							<span>Status</span>
							<Select
								className='select'
								defaultValue='All'
								bordered={false}
								value={statusValue}
								onChange={onChangeStatus}
							>
								<>
									<Option value='All'>All</Option>
									{filterByKYC.map((item, i) => (
										<Option className='select' value={item} key={i}>
											{item}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
					</>
				)}
				{source === 'balance' && (
					<PopOver
						type='filter'
						placement='bottom'
						title='Transaction Type'
						selected={options}
					>
						<PopDetails width='fit-content'>
							<Flex flexDir='column' alignItem='flex-start'>
								<Radio.Group
									onChange={handlePendingBalanceChange}
									value={options}
									size='large'
									defaultValue={'All'}
								>
									<Space direction='vertical' size='middle'>
										<Radio value='All'>All</Radio>
										<Radio value='Swap'>Swap</Radio>
										<Radio value='Withdrawal'>Withdrawal</Radio>
									</Space>
								</Radio.Group>
							</Flex>
						</PopDetails>
					</PopOver>
				)}
				{source === 'transaction' && (
					<>
						{merchantTransactions && (
							<PopOver
								type='filter'
								placement='bottom'
								title='Type'
								selected={typeValue}
							>
								<PopDetails width='fit-content'>
									<Flex flexDir='column' alignItem='flex-start' id='status'>
										<Radio.Group
											onChange={onChangeType}
											value={typeValue}
											size='large'
											defaultValue={'All'}
										>
											<Space direction='vertical' size='middle'>
												<Radio value='All'>All</Radio>
												<Radio value='swap' id='pending'>
													Swap
												</Radio>
												<Radio value='deposit' id='success'>
													Deposit
												</Radio>
												<Radio value='withdrawal' id='failed'>
													Withdrawal
												</Radio>
											</Space>
										</Radio.Group>
									</Flex>
								</PopDetails>
							</PopOver>
						)}

						<PopOver
							type='filter'
							title='Date'
							placement='bottom'
							selected={
								value === 'Select date'
									? `${
											selectedDateFrom === '' && selectedDateTo === ''
												? 'Select date'
												: `${selectedDateFrom} - ${selectedDateTo}`
									  }`
									: value
							}
						>
							<PopDetails>
								<Flex flexDir='column' alignItem='flex-start'>
									<Radio.Group
										onChange={onChange}
										value={value}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='large'>
											<Radio value='All'>All</Radio>
											<Radio
												value={`${formatType(today)} - ${formatType(today)}`}
											>
												Today
											</Radio>
											<Radio
												value={`${formatType(firstWeekDay)} - ${formatType(
													lastWeekDay
												)}`}
											>
												This week
											</Radio>
											<Radio
												value={`${formatType(firstMonthDay)} - ${formatType(
													lastMonthDay
												)}`}
											>
												This month
											</Radio>
											<Radio value='Select date'>Select date</Radio>
										</Space>
									</Radio.Group>
									{value === 'Select date' && (
										<CustomPicker
											source='transaction'
											handleSelectFromChange={handleSelectFromChange}
											handleSelectToChange={handleSelectToChange}
										/>
									)}
								</Flex>
							</PopDetails>
						</PopOver>
						<PopOver
							type='filter'
							placement='bottom'
							selected={currency}
							title='Currency'
						>
							<PopDetails>
								<Flex flexDir='column' alignItem='flex-start'>
									<Radio.Group
										onChange={(e) => handleCurrencyChange(e)}
										value={currency}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>
											{currencies?.map((item, i) => (
												<Radio key={item.code} value={item.code}>
													{item.code}
												</Radio>
											))}
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
						<PopOver
							type='filter'
							placement='bottom'
							title='Status'
							selected={statusValue}
						>
							<PopDetails width='fit-content'>
								<Flex flexDir='column' alignItem='flex-start' id='status'>
									<Radio.Group
										onChange={onChangeStatus}
										value={statusValue}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>
											<Radio value='pending' id='pending'>
												Pending
											</Radio>
											<Radio value='success' id='success'>
												Success
											</Radio>
											<Radio value='queued' id='queued'>
												Queued
											</Radio>
											<Radio value='blocked' id='blocked'>
												Blocked
											</Radio>
											<Radio value='failed' id='failed'>
												Failed
											</Radio>
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
					</>
				)}
				{source === 'refund' && (
					<>
						<PopOver
							type='filter'
							title='Date'
							placement='bottom'
							selected={
								value === 'Select date'
									? `${
											selectedDateFrom === '' && selectedDateTo === ''
												? 'Select date'
												: `${selectedDateFrom} - ${selectedDateTo}`
									  }`
									: value
							}
						>
							<PopDetails>
								<Flex flexDir='column' alignItem='flex-start'>
									<Radio.Group
										onChange={onChange}
										value={value}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='large'>
											<Radio value='All'>All</Radio>
											<Radio
												value={`${formatType(today)} - ${formatType(today)}`}
											>
												Today
											</Radio>
											<Radio
												value={`${formatType(firstWeekDay)} - ${formatType(
													lastWeekDay
												)}`}
											>
												This week
											</Radio>
											<Radio
												value={`${formatType(firstMonthDay)} - ${formatType(
													lastMonthDay
												)}`}
											>
												This month
											</Radio>
											<Radio value='Select date'>Select date</Radio>
										</Space>
									</Radio.Group>
									{value === 'Select date' && (
										<CustomPicker
											source='transaction'
											handleSelectFromChange={handleSelectFromChange}
											handleSelectToChange={handleSelectToChange}
										/>
									)}
								</Flex>
							</PopDetails>
						</PopOver>
						<PopOver
							type='filter'
							placement='bottom'
							selected={currency}
							title='Currency'
						>
							<PopDetails>
								<Flex flexDir='column' alignItem='flex-start'>
									<Radio.Group
										onChange={(e) => handleCurrencyChange(e)}
										value={currency}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>
											{currencies?.map((item, i) => (
												<Radio key={item.code} value={item.code}>
													{item.code}
												</Radio>
											))}
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
					</>
				)}
				{source === 'crossFee' && (
					<>
						<PopOver
							type='filter'
							placement='bottom'
							title='Env'
							selected={typeValue}
						>
							<PopDetails width='fit-content'>
								<Flex
									flexDir='column'
									alignItem='flex-start'
									// id='status'
								>
									<Radio.Group
										onChange={onChangeType}
										value={typeValue}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>

											<Radio value='live' id='success'>
												Live
											</Radio>
											<Radio value='test' id='failed'>
												Test
											</Radio>
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
						<PopOver
							type='filter'
							placement='bottom'
							title='Type'
							selected={typeValue}
						>
							<PopDetails width='fit-content'>
								<Flex
									flexDir='column'
									alignItem='flex-start'
									// id='status'
								>
									<Radio.Group
										onChange={onChangeType}
										value={typeValue}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>

											<Radio value='deposit' id='success'>
												Deposit
											</Radio>
											<Radio value='withdrawal' id='failed'>
												Withdrawal
											</Radio>
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
						<PopOver
							type='filter'
							placement='bottom'
							selected={currency}
							title='Currency'
						>
							<PopDetails>
								<Flex flexDir='column' alignItem='flex-start'>
									<Radio.Group
										onChange={(e) => handleCurrencyChange(e)}
										value={currency}
										size='large'
										defaultValue={'All'}
									>
										<Space direction='vertical' size='middle'>
											<Radio value='All'>All</Radio>
											{currencies?.map((item, i) => (
												<Radio key={item.code} value={item.code}>
													{item.code}
												</Radio>
											))}
										</Space>
									</Radio.Group>
								</Flex>
							</PopDetails>
						</PopOver>
					</>
				)}

				{source === 'merchants' && (
					<>
						<SelectContainer>
							<span>Country</span>
							<Select
								className='select'
								defaultValue='All'
								width='40%'
								bordered={false}
								onChange={handleSelectCountry}
							>
								<>
									<Option value='All'>All</Option>
									{countries?.map((item, i) => (
										<Option className='select' value={item?.name} key={i}>
											{item.name}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
						<SelectContainer>
							<span>KYC</span>
							<Select
								className='select'
								defaultValue='All'
								bordered={false}
								onChange={handleSelectKyc}
							>
								<>
									<Option value='All'>All</Option>
									{filterByKYC?.map((item, i) => (
										<Option value={item} className='select' key={i}>
											{item}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
						<SelectContainer>
							<span>Status</span>
							<Select
								className='select'
								defaultValue='All'
								bordered={false}
								value={statusValue}
								onChange={onChangeStatus}
							>
								<>
									<Option value='All'>All</Option>
									{filterByStatus.map((item, i) => (
										<Option className='select' value={item} key={i}>
											{item}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
					</>
				)}
				{source === 'approval' && (
					<PopOver
						type='filter'
						title='Date'
						placement='bottom'
						selected={
							value === 'Select date'
								? `${
										selectedDateFrom === '' && selectedDateTo === ''
											? 'Select date'
											: `${selectedDateFrom} - ${selectedDateTo}`
								  }`
								: value
						}
					>
						<PopDetails>
							<Flex flexDir='column' alignItem='flex-start'>
								<Radio.Group onChange={onChange} value={value} size='large'>
									<Space direction='vertical' size='large'>
										<Radio value='Today'>Today</Radio>
										<Radio value='This Week'>This week</Radio>
										<Radio value='This Month'>This month</Radio>
										<Radio value='Last 3 months'>Last 3 months</Radio>
										<Radio value='Select date'>Select date</Radio>
									</Space>
								</Radio.Group>
								{value === 'Select date' && (
									<CustomPicker
										source='transaction'
										handleSelectFromChange={handleSelectFromChange}
										handleSelectToChange={handleSelectToChange}
									/>
								)}
							</Flex>
						</PopDetails>
					</PopOver>
				)}

				{source === 'overview' && (
					<>
						<Flex width='200px'>
							<PopOver
								type='filter'
								title='Date'
								placement='bottom'
								selected={
									value === 'Select date'
										? `${
												selectedDateFrom === '' && selectedDateTo === ''
													? 'Select date'
													: `${selectedDateFrom} - ${selectedDateTo}`
										  }`
										: value
								}
								// style={{
								// 	width: '200px'
								// }}
							>
								<PopDetails>
									<Flex flexDir='column' alignItem='flex-start'>
										<Radio.Group
											onChange={onChange}
											value={value}
											size='large'
											defaultValue={'All'}
										>
											<Space direction='vertical' size='large'>
												<Radio value='All'>All</Radio>
												<Radio
													value={`${formatType(today)} - ${formatType(today)}`}
												>
													Today
												</Radio>
												<Radio
													value={`${formatType(firstWeekDay)} - ${formatType(
														lastWeekDay
													)}`}
												>
													This week
												</Radio>
												<Radio
													value={`${formatType(firstMonthDay)} - ${formatType(
														lastMonthDay
													)}`}
												>
													This month
												</Radio>
												<Radio value='Select date'>Select date</Radio>
											</Space>
										</Radio.Group>
										{value === 'Select date' && (
											<CustomPicker
												source='transaction'
												handleSelectFromChange={handleSelectFromChange}
												handleSelectToChange={handleSelectToChange}
											/>
										)}
									</Flex>
								</PopDetails>
							</PopOver>
						</Flex>
						<SelectContainer>
							<span>Merchant</span>
							<Select
								className='select'
								defaultValue='All'
								bordered={false}
								value={statusValue}
								onChange={onChangeStatus}
							>
								<>
									<Option value='All'>All</Option>
									{merchantList?.map((item, i) => (
										<Option className='select' value={item?.id} key={i}>
											{item?.business_name}
										</Option>
									))}
								</>
							</Select>
						</SelectContainer>
					</>
				)}
				<Container
					width={
						source === 'compliance'
							? '25%'
							: source === 'overview'
							? '35%'
							: '20%'
					}
				>
					<Button
						Styles
						text={'Apply filters'}
						bgColor={'#E7F1FF'}
						color={'#1A2CCE'}
						size='sm'
						fullwidth
						type='button'
						border={'0.4px solid #1A2CCE'}
						boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
						borderRadius={'5px'}
						onClick={() => refetch()}
						loading={loading}
					/>
				</Container>
			</Flex>
		</>
	);
};

export default Filters;

const Styles = styled(Flex)`
	width: 70%;
	justify-content: space-between;
	padding: 6px 8px;
	// padding: 12px 15px;
	border: 0.7px solid #dfdfdf;
	box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
	border-radius: 3px;
	margin-right: 20px;
	span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #969696;
	}
`;
