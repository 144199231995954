import { Form, Select } from 'antd';
import React, { useState } from 'react';
import DebounceSelect from '../../pages/dashboard/fees/cross-fees/DebounceSelect';
import { getMerchantSearch } from '../../services/Others.service';
import Button from '../button/index';
import { CustomPicker } from '../customDatePicker/CustomPicker';
import styles from './DownloadReport.module.css';
const { Option } = Select;

const DownloadReport = ({
	handleOk,
	setFromDate,
	setToDate,
	setFileType,
	setTransactionType,
	setExpectedTrans,
	type,
	merchant
}) => {
	const [form] = Form.useForm();
	const [value, setValue] = useState([]);

	const handleChange = (e) => {
		setFileType(e);
		// console.log(e);
	};

	const handleSelectType = (e) => {
		setTransactionType(e);
		console.log(e);
	};

	const handleSelectExpected = (e) => {
		setExpectedTrans(e);
		console.log(e);
	};

	const fetchMerchantsList = (username) => {
		console.log('fetching user', username);
		if (username !== undefined || null || '') {
			return getMerchantSearch(username);
		} else {
			return [];
		}
	};

	return (
		<Form
			layout={'vertical'}
			form={form}
			initialValues={{
				layout: 'vertical'
			}}
		>
			<Form.Item label='File type'>
				<Select
					className={styles.select}
					placeholder='Select file type'
					size='large'
					style={{ width: '100%' }}
					onChange={handleChange}
					defaultValue={'CSV'}
				>
					<Option value='CSV'>CSV</Option>
				</Select>
			</Form.Item>
			{/* <Spacer height='10px' /> */}
			<Form.Item label='Transaction type'>
				{type === 'fxOps' ? (
					<Select
						className={styles.select}
						placeholder='Select transaction type'
						size='large'
						style={{ width: '100%' }}
						onChange={handleSelectType}
					>
						<Option value='PAYOUT'>PAYOUT</Option>
						<Option value='DEPOSIT'>DEPOSIT</Option>
					</Select>
				) : (
					<Select
						className={styles.select}
						placeholder='Select transaction type'
						size='large'
						style={{ width: '100%' }}
						onChange={handleSelectType}
						// defaultValue={'All'}
					>
						<Option value='All'>All</Option>
						<Option value='swap'>SWAP</Option>
						<Option value='deposit'>DEPOSIT</Option>
						<Option value='withdrawal'>WITHDRAWAL</Option>
					</Select>
				)}
			</Form.Item>

			<Form.Item
				label='Merchant'
				name='merchant'
				// rules={[
				// 	{
				// 		required: true,
				// 		message: 'Please input full name!'
				// 	}
				// ]}
			>
				<DebounceSelect
					value={value}
					placeholder='Select merchant'
					fetchOptions={fetchMerchantsList}
					onChange={(newValue) => {
						console.log(newValue);
						setValue(newValue?.label);
						merchant(newValue?.value);
					}}
					style={{
						width: '100%'
					}}
				/>
			</Form.Item>
			<Form.Item label='Expected Transactions' style={{ width: '100%' }}>
				<Select
					className={styles.select}
					placeholder='Select Expected Transactions'
					size='large'
					style={{ width: '100%' }}
					onChange={handleSelectExpected}
					// defaultValue={'All'}
				>
					<Option value='All'>All</Option>
					<Option value='actual'>Actual transactions</Option>
					<Option value='refunded'>Refunded transactions</Option>
				</Select>
			</Form.Item>

			<div className={styles.text}>
				<p>Choose a timeframe for statement</p>
			</div>
			<div className={styles.input_boxes}>
				<CustomPicker
					source='download'
					handleSelectFromChange={(e, dateString) => setFromDate(dateString)}
					handleSelectToChange={(e, dateString) => setToDate(dateString)}
				/>
			</div>
			<Button
				text={'Export Report'}
				bgColor={'#1A2CCE'}
				color={'#fff'}
				fullwidth
				size='default'
				type='button'
				borderRadius={'5px'}
				onClick={handleOk}
			/>
		</Form>
	);
};

export default DownloadReport;
