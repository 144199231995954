import { Form, Input, Select, Tabs, Typography } from 'antd';
// import { message, Upload } from 'antd';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { LinkIcon, UploadIcon } from '../../../../../../assets';
import fileUploadIcon from '../../../../../../assets/uploadImg.png';
import { Flex } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
import { createWallet } from '../../../../../../services/Wallet.service';
// import { InboxOutlined } from '@ant-design/icons';
import { ToastMessage, type } from '../../../../../../utils/toastr';

// const { Text } = Typography;

const StepThreeForm = ({
	handleFormData,
	handleSelectFormData,
	nextStep,
	values,
	merchantID,
	finished,
	handleCancel
}) => {
	const [form] = Form.useForm();

	const queryClient = useQueryClient();

	const fileTypes = ['JPEG', 'JPG', 'PNG', 'PDF', 'DOC', 'DOCX'];
	const [file, setFile] = useState('');
	const [fileError, setFileError] = useState('');
	const [document_file_type, setDocument_file_type] = useState('FILE');
	const [document_file, setDocument_file] = useState('');
	const [document_link, setDocument_link] = useState('');
	const [extension, setExtension] = useState('');

	const [compliance, setCompliance] = useState([
		{
			document_type: '',
			document_no: '',
			document_file: '',
			document_file_type: ''
		}
	]);

	const createMutation = useMutation(
		async (data) => {
			await createWallet(merchantID, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantWallets');
				queryClient.invalidateQueries('getMerchantWalletBalance');
				queryClient.invalidateQueries('getMerchantTotalTransactions');

				// setAddVisible(false);
				setTimeout(
					() => {
						finished(false);
						// setSuccess(true);
					},

					500
				);
				ToastMessage(type.SUCCESS, 'Wallet successfully created');
				// form.resetFields('');
			},
			onError: (error) => {
				// console.log(error);
				// if (error?.status === 413) {
				// 	ToastMessage(type.ERROR, 'File size should be less than 4mb');
				// } else {
				ToastMessage(type.ERROR, error.response.data.message);
				// }
			}
		}
	);

	const handleFileChange = (file) => {
		const extension = file?.name?.split('.')?.pop();
		setExtension(extension);
		const fsize = file?.size;
		const fileSizeCheck = fsize / 1024;

		console.log(file, file?.name);
		console.log(extension);
		// console.log(fileSizeCheck);
		if (fileSizeCheck >= 500) {
			// console.log('File larger than 500kb');
			setFileError(
				'File size larger than 500kb, Please select a file or image less than 500kb'
			);
		} else {
			setFileError('');
			const reader = new FileReader();
			reader.readAsDataURL(file);
			setFile(file.name);
			reader.onload = () => {
				const base64 = reader?.result?.split(',')?.pop();
				// const base64 = reader.result.replace('data:', '');

				setDocument_file(base64);
				// const values = [...compliance];
				// const updatedValue = 'document_file';
				// values[index][updatedValue] = base64;
				// setCompliance(values);
				// setFile((prev) => [...prev, base64]);
			};

			reader.onerror = (error) => {
				console.log(error);
			};
		}
		// }
	};

	const handleInputChange2 = (event) => {
		const d = event === '1' ? 'FILE' : 'LINK';
		setDocument_file_type(d);
		// console.log(event);
	};
	const handleInputChangeLink = (event) => {
		// console.log(event);
		const values = event?.target?.value;
		setDocument_link(values);
	};

	// const handleSelectChange = (index, event) => {
	// 	const values = [...compliance];
	// 	// const check = event?.type !== 'change' ? event?.target?.type : event?.type;
	// 	// console.log(event);

	// 	const updatedValue = 'document_type';
	// 	values[index][updatedValue] = event;
	// 	const updatedValue2 = 'document_file_type';
	// 	values[index][updatedValue2] = 'FILE';

	// 	setCompliance(values);
	// };

	// console.log(compliance);

	const handleSubmit = (data) => {
		const file = document_file_type === 'FILE' ? document_file : document_link;
		const ext =
			document_file_type === 'FILE'
				? extension
				: document_link?.split('.')?.pop();
		data.compliance = {
			phone: values.phone,
			firstname: values?.firstname,
			lastname: values?.lastname,
			email: values?.email,
			bvn: values?.bvn,
			// values?.name === ''
			// 	? {}
			business: {
				name: values?.name,
				phone: values?.bPhone,
				address: values?.address,
				city: values?.city,
				state: values?.state,
				country: values?.country,
				reg_number: values?.reg_number
			},
			documents: [
				{
					document_type: values?.document_type,
					document_no: values?.document_no,
					document_ext: ext,
					document_file: file,
					document_file_type
				}
			]
		};
		data.wallet_reference = values.wallet_ref;
		delete data.document_file;
		delete data.document_no;
		delete data.document_type;
		delete data.firstname;
		delete data.lastname;

		console.log({ data });
		createMutation.mutate(data);
	};

	return (
		<>
			<Form layout='vertical' form={form} onFinish={handleSubmit}>
				<Form.Item
					label='First Name'
					name='firstname'
					rules={[
						{
							required: true,
							message: 'First Name  is required'
						},
						{ min: 3, message: 'First Name must be minimum 3 characters.' }
					]}
				>
					<Input
						placeholder='Enter First Name'
						defaultValue={values.firstname}
						onChange={handleFormData('firstname')}
					/>
				</Form.Item>
				<Form.Item
					label='Last Name'
					name='lastname'
					rules={[
						{
							required: true,
							message: 'Last Name is required'
						},
						{ min: 3, message: 'Last Name must be minimum 3 characters.' }
					]}
				>
					<Input
						placeholder='Enter Last Name'
						defaultValue={values.lastname}
						onChange={handleFormData('lastname')}
					/>
				</Form.Item>

				<Form.Item
					label='Verification ID'
					// name='doc_type'
					name='document_type'
					rules={[{ required: true, message: 'Verification ID is required' }]}
				>
					<Select
						placeholder='Choose ID type'
						style={{
							width: '100%'
						}}
						defaultValue={values.document_type}
						onChange={handleSelectFormData('document_type')}
					>
						<Select.Option className='select' value='INTERNATIONAL_PASSPORT'>
							INTERNATIONAL PASSPORT
						</Select.Option>
						<Select.Option className='select' value='DRIVERS_LICENSE'>
							DRIVERS LICENSE
						</Select.Option>
						<Select.Option className='select' value='UTILITY_BILL'>
							UTILITY BILL
						</Select.Option>
						<Select.Option className='select' value='NATIONAL_CARD'>
							NATIONAL ID CARD
						</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					label='ID number'
					name={'document_no'}
					rules={[
						{ required: true, message: 'ID number  is required' },

						{
							min: 10,
							message: 'ID number must be minimum 10 characters.'
						}
					]}
				>
					<Input
						placeholder='Enter ID number'
						defaultValue={values.document_no}
						onChange={handleFormData('document_no')}
					/>
				</Form.Item>

				<div>
					<StylesTab>
						<Tabs
							onTabClick={(key, event) => {
								handleInputChange2(key);
							}}
						>
							<Tabs.TabPane
								tab={
									<>
										<UploadIcon />
										<p>Upload ID</p>
									</>
								}
								key='1'
							>
								<Form.Item label='Upload ID'>
									<FileUploadStyle
										border={
											fileError
												? '1px dashed #d80027'
												: file
												? '1px dashed #1A2CCE'
												: '1px dashed #AAAAAA'
										}
									>
										<FileUploader
											handleChange={(event) => {
												// console.log({ event });
												handleFileChange(event);
											}}
											name='file'
											types={fileTypes}
											multiple={false}
											// maxSize={0.5}

											children={
												<>
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															marginBottom: '18px'
														}}
													>
														<img src={fileUploadIcon} alt='file upload' />
													</div>

													<Typography
														style={{
															color: '#1A2CCE'
														}}
													>
														{fileError !== ''
															? ''
															: file
															? file?.name
															: 'Select a JPEG, JPG, PNG, DOC, DOCX or PDF file to upload'}
													</Typography>
													<Typography
														style={{
															textAlign: 'center',
															color: fileError !== '' ? '#d80027' : 'black'
														}}
													>
														{fileError !== ''
															? fileError
															: file
															? 'Upload completed!'
															: 'or drag and drop it here'}
													</Typography>
												</>
											}
											className='image'
										/>
									</FileUploadStyle>
								</Form.Item>
							</Tabs.TabPane>
							<Tabs.TabPane
								tab={
									<>
										<LinkIcon />
										<p>Provide Link</p>
									</>
								}
								key='2'
							>
								<Form.Item
									label='Document link'
									name={'document_file'}
									// document_no
									// name='idNumber'
									// name={`document_file_${index}`}
									// defaultValue={field.document_file}
									rules={[
										{
											required: true,
											message: 'Document link  is required'
										},
										{
											type: 'url',
											message: 'Please enter a valid url.'
										}
									]}
									onChange={(event) => handleInputChangeLink(event)}
								>
									<Input
										placeholder='https://'
										// type='text'
										// type='url'
										// defaultValue={values.lastname}
										// onChange={handleFormData('document_file')}
									/>
								</Form.Item>
							</Tabs.TabPane>
						</Tabs>
					</StylesTab>
				</div>

				<Flex justifyContent='flex-end' marginTop='40px'>
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>

					<Button
						Styles
						text='Submit'
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='submit'
						// form='createWallet'
						borderRadius='5px'
						// disabled={disabled}
						loading={createMutation.isLoading}
					/>
				</Flex>
			</Form>
		</>
	);
};

export default StepThreeForm;

const StylesTab = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.ant-tabs {
		width: 100%;
	}
	.ant-tabs-top > .ant-tabs-nav:before {
		border: none;
	}
	// background: red;
	.ant-tabs-nav-list {
		width: 100%;
		padding: 0;
		background: transparent;
		// min-width: 200px;
		// width: fit-content;
		justify-content: space-around;
		height: 100px;
	}
	.ant-tabs-tab-btn {
		// text-align: center;
		width: 100px;
		// padding: 0 75px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// width: 20px;
		// width: fit-content;

		height: 84px;
		// padding: 16px 16px;
		// padding: 16px 21.5p÷x;
		background: #fafafa;
		border: 1px solid #d7d7d7;
		border-radius: 8px;

		cursor: pointer;
		p {
			padding-top: 7px;
		}
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab-btn:hover {
		color: #000 !important;
		background: #ffffff;
		border: 1px solid #1a2cce;

		// color: #1a2cce;
		// width: 100%;
	}
	.ant-tabs-ink-bar,
	.ant-tabs-ink-bar:hover {
		background: #1a2cce;
		border: 2px solid #1a2cce;
	}

	.ant-select,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 46px;
		// width: 100%;

		background: #ffffff;
		border: 0.7px solid #dfdfdf;
		border-radius: 5px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 46px;
	}
`;

const FileUploadStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 217px;
	border: ${({ border }) => border};

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.02em;
		margin-bottom: 0;
	}

	p:last-child {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.02em;
		color: #727272;
	}

	.ant-form input[type='file'],
	.ant-form-item-control-input-content input {
		display: hidden !important;
	}

	// input[name='file'],
	// .image
	.ant-form-item-control-input-content label input {
		display: hidden !important;
	}
`;

export const AddMoreStyle = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	margin: 10px 0 10px 0;
	.ant-btn-link {
		color: #1a2cce;
	}
	.danger {
		color: #d80027;
	}
`;
