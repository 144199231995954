import styled from 'styled-components';

export const Styles = styled.div`
	h3 {
		font-style: normal;
		font-weight: 500;
		font-size: 15.537px;
		line-height: 20px;
		/* text color */
		color: #000a62;
		margin-bottom: 20px;
	}

	.ant-form-item {
		width: 250px;
	}

	.ant-select-selector {
		height: 46px !important;
		padding: 5px 11px !important;
	}

	.ant-switch {
		width: 37.89px;
		height: 22.33px;
	}

	.ant-switch-checked {
		background: #69ce1a;
		border-radius: 24.2105px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		/* identical to box height */
		color: #166f00;
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #1a2cce;
		border-color: #1a2cce;
	}
`;
