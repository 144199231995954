import React, { useState } from "react";
import { Form } from "antd";
import Button from "../../../../../components/button";
import { Container, Flex } from "../../../../../components/box";
import CustomTable from "./table/";
import SearchBox from "./searchbox/";
import { Styles, FormContainer } from "./styles";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import {
  addsettlementTime,
  deleteSettlementTime,
  editsettlementTime,
  getSettlementTime,
} from "../../../../../services/Settings.service";
import { Loader } from "../../../../../components/loader";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddNew } from "../../../../../assets";
import Add from "./forms/add";
import Edit from "./forms/edit";

const Index = () => {
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [action, setAction] = useState("add");
  const [id, setId] = useState("");
  const [initialEditValues, setInitialEditValues] = useState({});

  const [form] = Form.useForm();

  const result = useQuery(
    "getSettlementTime",
    async () => await getSettlementTime()
  );

  const deleteMutation = useMutation(
    async (data) => {
      await deleteSettlementTime(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getSettlementTime");
        ToastMessage(type.SUCCESS, "settlement time deleted successfully");
        setTimeout(() => {
          setDeleteVisible(false);
          setId("");
          form.resetFields();
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editMutation = useMutation(
    async (data) => {
      await editsettlementTime(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getSettlementTime"]);
        ToastMessage(type.SUCCESS, "settlement time edited successfully");
        setTimeout(() => {
          setEditVisible(false);
          setId("");
          form.resetFields();
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const addMutation = useMutation(
    async (data) => {
      await addsettlementTime(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getSettlementTime"]);
        ToastMessage(type.SUCCESS, "settlement time added successfully");
        setTimeout(() => {
          setVisible(false);
          setId("");
          form.resetFields();
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSubmit = async (values) => {
    const payload = {
      currency_pair: values.currency_pair,
      settings: values.settlementTimeList,
    };
    const check = payload.settings.filter(
      (item) => Number(item.maximum_amount) <= Number(item.minimum_amount)
    );

    if (action === "add") {
      if (check.length) {
        ToastMessage(
          type.WARN,
          "maximum amount must be greater than minimum amount"
        );
      } else {
        addMutation.mutate(payload);
      }
    } else if (action === "edit") {
      if (check.length) {
        ToastMessage(
          type.WARN,
          "maximum amount must be greater than minimum amount"
        );
      } else {
        editMutation.mutate(payload);
      }
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
      setEditVisible(false);
      setDeleteVisible(false);
    }, 1000);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setEditVisible(false);
    setDeleteVisible(false);
  };

  const showEdit = (id) => {
    setAction("edit");
    setId(id);
    result?.data?.data?.data?.find(
      (item) => item.id === id && setInitialEditValues(item)
    );
    setTimeout(() => setEditVisible(true), 500);
  };

  const showDelete = (i) => {
    setDeleteVisible(true);
    setId(i);
  };

  const handleDelete = () => {
    deleteMutation.mutate(id);
  };

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>;
  }
  const settlementTime = result?.data?.data?.data;
  return (
    <>
      <Styles>
        <Container>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="baseline"
            className="currencies settlement"
          >
            <h3>Add settlement times </h3>
            <div className="search">
              <SearchBox handleChange />
            </div>
          </Flex>

          <CustomTable
            theadBkColor="#F1F4F8"
            bottomGap="10px"
            tableHead={dataHead}
            tableBody={settlementTime}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            handleEdit={showEdit}
            showDelete={showDelete}
          />
          <Flex flexDir="row" justifyContent="space-between" className="btn">
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              width="40%"
              className="add-btn"
            >
              <AddNew />
              <Button
                Styles
                text={"Add new settlement time"}
                color={"#1A2CCE"}
                size="md"
                type="button"
                onClick={showModal}
              />
            </Flex>
          </Flex>
        </Container>
      </Styles>
      <ModalFrame
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={action === "add" ? "Save" : "Update"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="submitForm"
            // onClick={handleOk}
            style={{ padding: "0.85rem 50px" }}
            loading={addMutation.isLoading}
          />,
        ]}
      >
        <FormContainer>
          <Add action={action} id={id} handleSubmit={handleSubmit} />
        </FormContainer>
      </ModalFrame>

      <ModalFrame
        visible={editVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={action === "add" ? "Save" : "Update"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="submitForm"
            // onClick={handleOk}
            style={{ padding: "0.85rem 50px" }}
            loading={editMutation.isLoading}
          />,
        ]}
      >
        <FormContainer>
          <Edit
            action={action}
            id={id}
            form={form}
            handleSubmit={handleSubmit}
            initialEditValues={initialEditValues}
          />
        </FormContainer>
      </ModalFrame>
      <ModalFrame
        visible={deleteVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="button"
            key="submit"
            onClick={handleDelete}
            style={{ padding: "0.85rem 50px" }}
            loading={deleteMutation.isLoading}
          />,
        ]}
      >
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete this</p>
      </ModalFrame>
    </>
  );
};

export default Index;

const dataHead = ["Asset", "", "", ""];
