import styled from 'styled-components';

export const CustomTableMain = styled.section`
	.container {
		// height: 55vh;
		overflow: auto;
		/* @media (max-width: 600px) {
      height: 350px;
    } */
	}
	background: #fff;
	padding-bottom: ${({ paginator }) => !paginator && '20px'};
	width: 100%;
	table {
		background: #fff;
		border-collapse: collapse;
		text-align: start;
		width: 100%;
		overflow: auto;
		@media (max-width: 600px) {
			width: 700px;
		}
		thead {
			background: ${({ theadBkColor }) => theadBkColor || '#F1F4F8'};
			position: sticky;
			top: 0;
			z-index: 1;
			tr {
				th {
					text-align: start;
					color: #000a62;
					font-size: 12px;
					padding: 2em 0 1rem 1.8rem;
					/* min-width: 160px; */
				}
			}
		}
		tbody {
			tr {
				background: white;
				border: none;
				overflow: auto;
				border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
				/* border-bottom: ${({ bottomGap }) =>
					bottomGap ? `${bottomGap} solid   #fff` : '5px solid  #fff '}; */
			}
		}
	}
`;

export const Td = styled.td`
	padding: 1.2rem 1.8rem;
	font-weight: 400;
	font-size: 14px;
	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		strong {
			font-style: normal;
			font-weight: 4w00;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
		}
	}
	span {
		font-size: 0.9rem;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		margin-right: 5px;
		color: ${({ tbodyColor }) => tbodyColor || '#1A1F36'};
	}
`;

export const CustomTd = styled.td`
	padding: 1.2rem 1.8rem;
	div {
		padding: 7px;
		text-align: center;
		background-color: ${({ status }) =>
			(status === 'pending' && '#FFF2D9') ||
			(status === 'success' && '#C8FFC7') ||
			(status === 'blocked' && '#FF4848') ||
			(status === 'queued' && '#D9EDFF') ||
			(status === 'failed' && '#FFE4E4')};
		color: ${({ status }) =>
			(status === 'pending' && '#9C5502') ||
			(status === 'success' && '#027200') ||
			(status === 'blocked' && '#FFFFFF') ||
			(status === 'queued' && '#1A2CCE') ||
			(status === 'failed' && '#CE1A1A')};
		> span {
			font-size: 14px;
			text-transform: capitalize;
		}
	}
	a {
		text-decoration: underline;
		color: #1a2cce;
	}
	.icon_box {
		border: 0.7px solid #e7e7e7;
		box-sizing: border-box;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		min-height: 34px;
		width: 40px;
		cursor: pointer;
	}
`;

export const Paginator = styled('div')`
	background: white;
	padding: 1em 5px;
	display: ${({ paginator }) => (paginator ? 'flex' : 'none')};
	justify-content: space-between;
	ul {
		justify-content: flex-end;
	}
`;

export const BottomPanel = styled('div')`
	background: #f9f9f9;
	a {
		div {
			border-radius: 3px;

			p {
				color: #c10606;
			}
		}
	}
`;
