import { Form, Input } from 'antd';
import React from 'react';
import QRCode from 'react-qr-code';
import { useQueryClient } from 'react-query';
import { Flex } from '../../../../components/box';
import CustomButton from '../../../../components/button';
import { ApprovedStyle } from './styles';

const ApproveTransactionSummary = ({
	handleQrScreen,
	id,
	setVisible,
	status,
	actionTaken,
	selectedIndex,
	swapAction
}) => {
	const queryClient = useQueryClient();

	const [form] = Form.useForm();
	const { TextArea } = Input;

	// const ApprovalMutation = useMutation(
	//   async (data) => {
	//     await approveTransaction(data);
	//   },
	//   {
	//     onSuccess: () => {
	//       queryClient.invalidateQueries(["getPendingApprovalsFiat"]);
	//       queryClient.invalidateQueries(["getPendingApprovalsCrypto"]);
	//       queryClient.invalidateQueries("getApprovalSummaryPending");
	//       queryClient.invalidateQueries("getApprovalSummaryRejected");
	//       queryClient.invalidateQueries("getApprovalSummaryApproved");
	//       ToastMessage(type.SUCCESS, "withdrawal sucessfully approved");
	//       form.resetFields();
	//       setTimeout(() => {
	//         setVisible(false);
	//       }, 1000);
	//       form.resetFields();
	//     },
	//     onError: (error) => {
	//       ToastMessage(type.ERROR, error.response.data.message);
	//     },
	//   }
	// );

	// const ApprovalSwapMutation = useMutation(
	//   async (data) => {
	//     await approveTransaction(data);
	//   },
	//   {
	//     onSuccess: () => {
	//       queryClient.invalidateQueries("getTransactionsSwap");
	//       ToastMessage(type.SUCCESS, "swap sucessfully approved");
	//       setTimeout(() => {
	//         setVisible(false);
	//       }, 1000);
	//       form.resetFields();
	//     },
	//     onError: (error) => {
	//       ToastMessage(type.ERROR, error.response.data.message);
	//     },
	//   }
	// );

	// const RejectSwapMutation = useMutation(
	//   async (data) => {
	//     await rejectTransaction(data);
	//   },
	//   {
	//     onSuccess: () => {
	//       queryClient.invalidateQueries("getTransactionsSwap");
	//       ToastMessage(type.SUCCESS, "swap sucessfully rejected");
	//       setTimeout(() => {
	//         setVisible(false);
	//       }, 1000);
	//       form.resetFields();
	//     },
	//     onError: (error) => {
	//       ToastMessage(type.ERROR, error.response.data.message);
	//     },
	//   }
	// );

	// const RejectMutation = useMutation(
	//   async (data) => {
	//     await rejectTransaction(data);
	//   },
	//   {
	//     onSuccess: () => {
	//       queryClient.invalidateQueries(["getPendingApprovalsCrypto"]);
	//       queryClient.invalidateQueries(["getPendingApprovalsFiat"]);
	//       queryClient.invalidateQueries("getApprovalSummaryPending");
	//       queryClient.invalidateQueries("getApprovalSummaryRejected");
	//       queryClient.invalidateQueries("getApprovalSummaryApproved");
	//       ToastMessage(type.SUCCESS, "withdrawal sucessfully rejected");
	//       setTimeout(() => {
	//         setVisible(false);
	//       }, 1000);
	//       form.resetFields();
	//     },
	//     onError: (error) => {
	//       ToastMessage(type.ERROR, error.response.data.message);
	//     },
	//   }
	// );

	// const handleSubmit = (values) => {
	//   const payloadForId = {
	//     twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
	//     ids: [id],
	//     description: values.description,
	//   };
	//   const payloadForIds = {
	//     twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
	//     ids: selectedIndex,
	//     description: values.description,
	//   };
	//   if (status === "approve") {
	//     ApprovalMutation.mutate(payloadForId);
	//   } else if (actionTaken === "Approve") {
	//     ApprovalMutation.mutate(payloadForIds);
	//   } else if (status === "reject") {
	//     RejectMutation.mutate(payloadForId);
	//   } else if (actionTaken === "Reject") {
	//     RejectMutation.mutate(payloadForIds);
	//   } else if (swapAction === "swapApprove") {
	//     ApprovalSwapMutation.mutate(payloadForId);
	//   } else if (swapAction === "swapReject") {
	//     RejectSwapMutation.mutate(payloadForId);
	//   }
	// };

	return (
		<ApprovedStyle>
			<Flex justifyContent='space-between' alignItems='baseline'>
				<h3>Approve Transactons</h3>
			</Flex>

			<p>
				{` To be able to
        approve, you need to enter the verification
        code generated on the app or scan the QR code`}
			</p>

			<Form
				style={{ width: '100%' }}
				form={form}
				layout='vertical'
				// onFinish={handleSubmit}
				justifyContent='space-between'
			>
				<Flex flexDir='column' style={{ width: '100%' }}>
					<Flex style={{ width: '100%' }}>
						<Form.Item
							label='Paste approval code'
							name='twoFactorAuthenticationCode'
							style={{ width: '100%' }}
							// rules={[
							// 	{
							// 		required: true,
							// 		message: '2FA code is Required'
							// 	}
							// ]}
						>
							<Input bordered={false} />
						</Form.Item>
						{/* <Tooltip title='scan QR code'>
							<Button
								icon={<ScanQRCordIcon width='16' />}
								onClick={handleQrScreen}
								width='10%'
								type='button'
							/>
						</Tooltip> */}
					</Flex>
					{swapAction === 'swapReject' && (
						<Form.Item
							label='State reason for rejection'
							rules={[
								{
									required: true,
									message: 'State reason for rejection is Required'
								}
							]}
							id='desc'
							style={{ width: '100%' }}
							name='description'
						>
							<TextArea
								id='desc'
								rows={3}
								placeholder='Description'
								style={{ height: '100%' }}
							/>
						</Form.Item>
					)}
				</Flex>

				<CustomButton
					Styles
					text={'Verify code'}
					bgColor={'#1A2CCE'}
					color={'#FFFFFF'}
					size='md'
					fullwidth
					type='submit'
					// loading={
					// 	ApprovalMutation.isLoading ||
					// 	RejectMutation.isLoading ||
					// 	ApprovalSwapMutation.isLoading ||
					// 	RejectSwapMutation.isLoading
					// }
				/>
			</Form>
		</ApprovedStyle>
	);
};

const QRScreen = () => (
	<ApprovedStyle>
		<Flex justifyContent='space-between' alignItems='baseline'>
			<h3>Scan QR code</h3>
			<h4>10:00</h4>
		</Flex>
		<Flex className='code'>
			<QRCode value={'184bhjds5676329090721'} />
		</Flex>
	</ApprovedStyle>
);

export { ApproveTransactionSummary, QRScreen };
