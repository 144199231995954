import styled from 'styled-components';

export const CustomTableMain = styled.section`
	.container {
		/* height: 450px; */
		overflow: auto;
	}
	background: #fff;
	padding-bottom: ${({ paginator }) => !paginator && '20px'};
	width: 100%;
	table {
		background: #fff;
		border-collapse: collapse;
		text-align: start;
		width: 100%;
		overflow: auto;
		@media (max-width: 600px) {
			width: 700px;
		}
		thead {
			background: ${({ theadBkColor }) => theadBkColor || '#F1F4F8'};
			position: sticky;
			top: 0;
			z-index: 1;
			tr {
				th {
					text-align: start;
					color: #000a62;
					font-size: 12px;
					padding: 2em 0 1rem 1.8rem;
				}
			}
		}
		tbody {
			tr {
				background: white;
				border: none;
				overflow: auto;
				border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
			}
		}
	}
`;

export const Td = styled.td`
	padding: 1.2rem 1.8rem;
	font-weight: 400;
	font-size: 14px;

	div {
		display: flex;
		flex-direction: row;
		align-items: center;

		.frame {
			border-radius: 16.9px;
			/* padding: 2px 5px; */
			background-color: #;
			// background-color: #f0f0f0;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		strong {
			font-style: normal;
			font-weight: 4w00;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
		}
	}
	span {
		font-size: 0.9rem;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		margin-right: 5px;
		color: ${({ tbodyColor }) => tbodyColor || '#1A1F36'};
		text-transform: capitalize;
	}

	.copyBtn {
		margin-left: 10px;
		cursor: pointer;
	}
`;

export const CustomTd = styled.td`
	padding: 1.2rem 1.8rem;
	div {
		padding: 7px;
		text-align: center;
		background-color: ${({ status }) =>
			(status === 'pending' && '#FFF2D9') ||
			(status === 'success' && '#C8FFC7') ||
			(status === 'failed' && '#FFE4E4')};
		color: ${({ status }) =>
			(status === 'pending' && '#9C5502') ||
			(status === 'success' && '#027200') ||
			(status === 'failed' && '#CE1A1A')};
		> span {
			font-size: 14px;
		}
	}
	a {
		text-decoration: underline;
		color: #1a2cce;
	}
	.icon_box {
		border: 0.7px solid #e7e7e7;
		background: #f3f6fb;
		border-radius: 4px;
		box-sizing: border-box;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		min-height: 40px;
		cursor: pointer;
		transform: rotate(90deg);
	}
`;

export const Paginator = styled('div')`
	background: white;
	padding: 1em 5px;
	display: ${({ paginator }) => (paginator ? 'flex' : 'none')};
	justify-content: space-between;
	ul {
		justify-content: flex-end;
	}
`;
