import apiClient from './axios';

const EndPoints = {
	login: `/admin/auth/login`,
	forgotPassword: `/admin/auth/recover/password`,
	resetPassword: `/admin/auth/forgot/password`,
	twoFA: `/admin/auth/2fa/authenticate`,
	env: `/admin/environment`
};

export const login = async (payload) => {
	const res = await apiClient.post(EndPoints.login, payload);
	return res;
};

export const forgotPassword = async (payload) => {
	const res = await apiClient.post(EndPoints.forgotPassword, payload);
	return res;
};

export const resetPassword = async (payload) => {
	const res = await apiClient.post(EndPoints.resetPassword, payload);
	return res;
};

export const twoFA = async (payload) => {
	const res = await apiClient.post(EndPoints.twoFA, payload);
	return res;
};

export const env = async (payload) => {
	const res = await apiClient.post(EndPoints.env, payload);
	return res;
};
