import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	DeleteIcon,
	// DotIcon,
	EyeIcon,
	SuspendIcon
	// UserAvatar
} from '../../../../../assets';
import { EmptyState } from '../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../components/paginator';
import PopOver from '../../../../../components/popover/PopOver';
import { useAuthState } from '../../../../../context';
import { generateID } from '../../../../../utils/generateID';
import {
	CustomTableMain,
	CustomTd,
	// PopoverStyle,
	// BottomPanel
	Paginator,
	Td
} from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	handleEdit,
	handleSuspend,
	handleDelete,
	handleReset2fa,
	handleChangePassword,
	handlePageChange,
	meta
}) => {
	const [pageOfItems, setPageOfItems] = useState([]);

	const { state } = useAuthState();
	const [role, setRole] = useState();
	const [mode, setMode] = useState();
	const [permissions, setPermissions] = useState();
	const [approver, setApprover] = useState();
	//   const [tableData] = useState(tableBody);

	const onChangePage = (items) => {
		setPageOfItems(items);
	};
	useEffect(() => {
		setRole(state?.user?.role?.name);
		setMode(state?.user?.mode);
		setPermissions(state?.user?.role?.permitions);
		setApprover(state?.user?.approver);
	}, [state, mode, permissions, role, approver]);

	const returnTableRow = (data, i) => {
		return (
			<tr key={generateID(17)}>
				<Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>{data.full_name}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>{data.email}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span style={{ color: '#1A1F36' }}>{data.role.name}</span>
					</div>
				</Td>
				<Td>
					<div>
						<span
							style={{ color: data.status === true ? '#027200' : '#CE1A1A' }}
						>
							{data.status === true ? 'active' : 'suspended'}
						</span>
					</div>
				</Td>
				<CustomTd>
					{(role === 'Super Admin' && permissions?.includes('all')) ||
					role === 'Admin' ? (
						<Link to={`#`} onClick={(e) => handleEdit(data.id)}>
							Edit user
						</Link>
					) : null}
				</CustomTd>
				<CustomTd>
					<PopOver key={data?.id}>
						<Link to={'#'} onClick={() => handleReset2fa(data.id)}>
							<div>
								<EyeIcon />
								<p>Reset 2FA</p>
							</div>
						</Link>

						<Link to={'#'} onClick={() => handleSuspend(data.id, data.status)}>
							<div>
								{data.status ? (
									<DeleteIcon color='#C10606' />
								) : (
									<SuspendIcon color='#12B76A' />
								)}
								<p
									style={{
										color: data.status ? '#C10606' : '#12B76A'
									}}
								>
									{data.status ? 'Suspend user' : 'Unsuspend user'}
								</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length >= 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									<th>
										<input type={'checkbox'} />
									</th>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination handlePageChange={handlePageChange} meta={meta} />
					</Paginator>
					{/* <Paginator className='paginator' paginator={paginator}>
						<Pagination
							items={tableBody}
							pageSize={pageSize || 5}
							prevNext={prevNext || 'PrevNext'}
							onChangePage={onChangePage}
							firstLast={firstLast || null}
						/>
					</Paginator> */}
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;

// const content = (userId) => {
//   return (
//     <PopoverStyle>
//       <Link to={`${userId}`}>
//         <div>
//           <svg
//             width="20"
//             height="20"
//             viewBox="0 0 20 20"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               fillRule="evenodd"
//               clip-rule="evenodd"
//               d="M19.9497 10.1936C19.9497 12.7808 18.9219 15.262 17.0925 17.0915C15.263 18.9209 12.7818 19.9487 10.1946 19.9487C7.60734 19.9487 5.12609 18.9209 3.29666 17.0915C1.46722 15.262 0.439453 12.7808 0.439453 10.1936C0.439453 7.60636 1.46722 5.12512 3.29666 3.29568C5.12609 1.46624 7.60734 0.438477 10.1946 0.438477C12.7818 0.438477 15.263 1.46624 17.0925 3.29568C18.9219 5.12512 19.9497 7.60636 19.9497 10.1936ZM12.6333 6.53541C12.6333 7.18222 12.3764 7.80253 11.919 8.25989C11.4617 8.71725 10.8414 8.97419 10.1946 8.97419C9.54775 8.97419 8.92744 8.71725 8.47008 8.25989C8.01272 7.80253 7.75578 7.18222 7.75578 6.53541C7.75578 5.88861 8.01272 5.2683 8.47008 4.81094C8.92744 4.35358 9.54775 4.09664 10.1946 4.09664C10.8414 4.09664 11.4617 4.35358 11.919 4.81094C12.3764 5.2683 12.6333 5.88861 12.6333 6.53541ZM10.1946 11.413C9.02711 11.4127 7.88415 11.7477 6.90149 12.378C5.91884 13.0083 5.13781 13.9075 4.65122 14.9687C5.33734 15.7669 6.18798 16.4073 7.1448 16.8459C8.10162 17.2845 9.14199 17.5111 10.1946 17.5099C11.2471 17.5111 12.2875 17.2845 13.2443 16.8459C14.2011 16.4073 15.0518 15.7669 15.7379 14.9687C15.2513 13.9075 14.4703 13.0083 13.4876 12.378C12.505 11.7477 11.362 11.4127 10.1946 11.413Z"
//               fill="#9C9C9C"
//             />
//           </svg>
//           <p>View member info</p>
//         </div>
//       </Link>
//       <Link to={`${userId}`}>
//         <div>
//           <svg
//             width="25"
//             height="25"
//             viewBox="0 0 25 25"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M12.1946 14.6324C12.8414 14.6324 13.4618 14.3755 13.9191 13.9181C14.3765 13.4608 14.6334 12.8405 14.6334 12.1937C14.6334 11.5469 14.3765 10.9265 13.9191 10.4692C13.4618 10.0118 12.8414 9.75488 12.1946 9.75488C11.5478 9.75488 10.9275 10.0118 10.4702 10.4692C10.0128 10.9265 9.75586 11.5469 9.75586 12.1937C9.75586 12.8405 10.0128 13.4608 10.4702 13.9181C10.9275 14.3755 11.5478 14.6324 12.1946 14.6324Z"
//               fill="#9C9C9C"
//             />
//             <path
//               fillRule="evenodd"
//               clip-rule="evenodd"
//               d="M0.558594 12.1939C2.11209 7.24686 6.73357 3.6582 12.194 3.6582C17.6544 3.6582 22.2759 7.24686 23.8294 12.1939C22.2759 17.141 17.6544 20.7296 12.194 20.7296C6.73357 20.7296 2.11209 17.141 0.558594 12.1939ZM17.0715 12.1939C17.0715 13.4875 16.5577 14.7281 15.6429 15.6429C14.7282 16.5576 13.4876 17.0715 12.194 17.0715C10.9004 17.0715 9.65976 16.5576 8.74504 15.6429C7.83032 14.7281 7.31644 13.4875 7.31644 12.1939C7.31644 10.9003 7.83032 9.65969 8.74504 8.74497C9.65976 7.83025 10.9004 7.31637 12.194 7.31637C13.4876 7.31637 14.7282 7.83025 15.6429 8.74497C16.5577 9.65969 17.0715 10.9003 17.0715 12.1939Z"
//               fill="#9C9C9C"
//             />
//           </svg>

//           <p>Reset 2FA</p>
//         </div>
//       </Link>
//       <BottomPanel>
//         <Link to={`${userId}`}>
//           <div>
//             <svg
//               width="19"
//               height="20"
//               viewBox="0 0 19 20"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 fillRule="evenodd"
//                 clip-rule="evenodd"
//                 d="M8.26555 0.438477C8.03915 0.438597 7.81726 0.501742 7.62473 0.620839C7.43219 0.739935 7.27661 0.910281 7.17541 1.1128L6.29258 2.87725H2.16861C1.8452 2.87725 1.53505 3.00572 1.30637 3.2344C1.07769 3.46308 0.949219 3.77324 0.949219 4.09664C0.949219 4.42004 1.07769 4.7302 1.30637 4.95888C1.53505 5.18756 1.8452 5.31603 2.16861 5.31603V17.5099C2.16861 18.1567 2.42555 18.777 2.88291 19.2344C3.34027 19.6917 3.96058 19.9487 4.60738 19.9487H14.3625C15.0093 19.9487 15.6296 19.6917 16.087 19.2344C16.5443 18.777 16.8013 18.1567 16.8013 17.5099V5.31603C17.1247 5.31603 17.4348 5.18756 17.6635 4.95888C17.8922 4.7302 18.0206 4.42004 18.0206 4.09664C18.0206 3.77324 17.8922 3.46308 17.6635 3.2344C17.4348 3.00572 17.1247 2.87725 16.8013 2.87725H12.6773L11.7945 1.1128C11.6933 0.910281 11.5377 0.739935 11.3451 0.620839C11.1526 0.501742 10.9307 0.438597 10.7043 0.438477H8.26555ZM5.82677 7.7548C5.82677 7.4314 5.95524 7.12124 6.18392 6.89257C6.4126 6.66389 6.72276 6.53541 7.04616 6.53541C7.36956 6.53541 7.67972 6.66389 7.90839 6.89257C8.13707 7.12124 8.26555 7.4314 8.26555 7.7548V15.0711C8.26555 15.3945 8.13707 15.7047 7.90839 15.9334C7.67972 16.162 7.36956 16.2905 7.04616 16.2905C6.72276 16.2905 6.4126 16.162 6.18392 15.9334C5.95524 15.7047 5.82677 15.3945 5.82677 15.0711V7.7548ZM11.9237 6.53541C11.6003 6.53541 11.2902 6.66389 11.0615 6.89257C10.8328 7.12124 10.7043 7.4314 10.7043 7.7548V15.0711C10.7043 15.3945 10.8328 15.7047 11.0615 15.9334C11.2902 16.162 11.6003 16.2905 11.9237 16.2905C12.2471 16.2905 12.5573 16.162 12.7859 15.9334C13.0146 15.7047 13.1431 15.3945 13.1431 15.0711V7.7548C13.1431 7.4314 13.0146 7.12124 12.7859 6.89257C12.5573 6.66389 12.2471 6.53541 11.9237 6.53541Z"
//                 fill="#C10606"
//               />
//             </svg>

//             <p>Suspend user</p>
//           </div>
//         </Link>
//       </BottomPanel>
//     </PopoverStyle>
//   );
// };
