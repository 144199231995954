import { useLocation, Navigate } from "react-router-dom";

export function RequireAuth({ children, isPrivate = true }) {
  const token = localStorage.getItem("access_token");

  let location = useLocation();

  if (isPrivate && !token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

export function RoleCheck({ children, role }) {
  let location = useLocation();

  if (role !== true) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
