import styled from 'styled-components';

export const Styles = styled.div`
	// display: flex;
	// flex-direction: column;
	// justify-content: flex-start;
	.notification-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40.5px;

		.info {
			h3 {
				font-weight: 500;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #000a62;
			}
			p {
				margin-top: 8px;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #575757;
			}
		}
	}
	.notification {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		margin-bottom: 20.5px;
		// background: red;

		.info {
			// width: 300px;
			h3 {
				font-weight: 500;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #000a62;
			}
			p {
				margin-top: 8px;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #575757;
			}
		}
		.info2 {
			display: flex;
			justify-content: space-between;
			// width: 300px;
			h3 {
				font-weight: 500;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #000a62;
			}
			p {
				margin-top: 8px;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.02em;
				color: #575757;
			}
		}
		.ant-switch {
			width: 37.89px;
			height: 22.33px;
		}

		.ant-switch-checked {
			background: #69ce1a;
			border-radius: 24.2105px;
		}
	}

	.ant-divider {
		border: 0.3px solid #c4c4c4;
	}

	.ant-select .ant-select-selector,
	.ant-input {
		height: 46px !important;
		border: 0.7px solid #d6d6d6;
		border-radius: 5px;
	}
	.ant-select .ant-select-selector .ant-select-selection-item,
	.ant-select-selection-placeholder {
		line-height: 46px;
	}
	// .ant-select-selection-placeholder {
	// 	padding-top: 8px;
	// }

	.ant-form-item-label {
		label {
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;

			letter-spacing: 0.02em;

			color: #666666;
		}
	}

	.ant-input-number {
		width: 150px;
		.ant-input-number-input {
			height: 48px;
		}
	}
`;

export const ButtonStyles = styled.div`
	// background: red;
	// width: 41.875rem;
	width: 670px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.ant-btn-link {
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		text-decoration: underline;
		color: #1a2cce;
		// margin:right:40px;
	}

	.anticon {
		font-size: 20px;
		line-height: 21px;
		margin-right: 14px;
		color: #1a2cce;
	}
	.save {
		background: #1a2cce;
		border-radius: 5px;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: #ffffff;
		width: 130px;
		height: 48px;
		// margin-left: ;
	}
`;

export const DeleteStyle = styled.div`
	text-align: left;
	h3 {
		font-weight: 500;
		font-size: 18.1764px;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: left;

		/* text color */

		color: #000a62;
		padding-bottom: 8px;
	}
	p {
		text-align: left;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
		/* or 160% */

		letter-spacing: 0.02em;

		color: #1a1f36;
	}
`;
