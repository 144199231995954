import PropTypes from "prop-types";
import React, { useState } from "react";
import { generateID } from "../../../../../utils/generateID";
import Pagination from "../../../../../components/paginator";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { OverFlowScrollBar } from "../../../../../components/overflowScroll/styles";
import { EmptyState } from "../../../../../components/emptyState";
import { Link } from "react-router-dom";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  pageSize,
  firstLast,
  prevNext,
  type,
}) => {
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (items) => {
    setPageOfItems(items);
  };

  const returnTableRow = (data, i) => {
    return (
      <tr key={generateID(17)}>
        <Td>
          <div>
            <span>{data?.business_name}</span>
          </div>
        </Td>
        <Td>
          <div>
            <span>{data?.business_email}</span>
          </div>
        </Td>
        <Td>
          <div>
            <span>{data?.country}</span>
          </div>
        </Td>

        <CustomTd>
          <Link to={`/dashboard/merchants/${data.id}`}>view details</Link>
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody?.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead.map((head, i) => (
                    <th key={generateID(11)}>{head.toUpperCase()}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {paginator
                  ? pageOfItems?.map((data, idx) => returnTableRow(data, idx))
                  : tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination
              items={tableBody}
              pageSize={pageSize || 5}
              prevNext={prevNext || "prevNext"}
              onChangePage={onChangePage}
              firstLast={firstLast || null}
              type={type || null}
            />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
  firstLast: PropTypes.any,
  paginator: PropTypes.any,
  prevNext: PropTypes.any,
};

export default CustomTable;
