import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	// padding: 30px 30px 0;
	padding: 0 35px;
	h2 {
		color: #000000;
	}
	@media (max-width: 700px) {
		padding: 20px 4%;
	}

	.wallet-owner {
		display: flex;
		align-items: baseline;
		.icon-box {
			display: flex;
			width: 43px;
			height: 43px;
			border-radius: 100%;
			background: #d7e6ff;
			justify-content: center;
			align-items: center;
			color: #1a2cce;
		}

		p {
			margin-left: 13px;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.02em;

			/* Normal-side-panel text */

			color: #1a1f36;
		}
	}

	.card-container {
		// background: red;
		.ant-col {
			width: 320px;
			// margin-right: 43px;

			// :last-child {
			// 	margin-right: 0px;
			// }
		}

		.transaction-value {
			.ant-card-bordered {
				border: 1px solid transparent;
			}
			.ant-statistic {
				.ant-statistic-title {
					color: #dee1fc;
				}

				.ant-statistic-content {
					color: #ffffff;
				}
			}
		}
		.ant-card-bordered {
			border: 1px solid #dfdfdf;
			border-radius: 6px;
			.ant-card-body {
				padding: 36px;
			}
		}
		.ant-statistic {
			.ant-statistic-title {
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;

				letter-spacing: 0.02em;

				color: #6f6f6f;
				margin-bottom: 20px;
			}

			.ant-statistic-content {
				font-weight: 700;
				font-size: 24px;
				line-height: 31px;
				letter-spacing: 0.06em;
				color: #0f0f0f;
			}
		}
	}
	.table-caption {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		/* identical to box height */

		letter-spacing: 0.02em;

		color: #6f6f6f;
	}
`;

export const Wrapper = styled.div`
	.select {
		.ant-select-selector {
			background-color: #1a2cce;
		}
		.ant-select-arrow {
			color: #fff;
		}
	}
`;

export const StatisticStyle = styled.div`
	// padding: 30px;
	// background: #ececec;

	.ant-col {
		width: 340px;
		margin-right: 43px;

		:last-child {
			margin-right: 0px;
		}
	}
	.ant-card-bordered {
		border: 1px solid #dfdfdf;
		border-radius: 6px;

		.ant-card-body {
			padding: 24px 32px;
		}
	}

	.ant-statistic {
		.ant-statistic-title {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;

			letter-spacing: 0.02em;

			color: #6f6f6f;
			margin-bottom: 19px;
		}

		.ant-statistic-content {
			font-weight: 700;
			font-size: 23px;
			line-height: 30px;
			letter-spacing: 0.02em;
			color: #0f0f0f;
		}
	}
`;
