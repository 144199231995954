import React from 'react';
import { Flex, Grid } from '../../../../../../components/box';
import { Box, Container, Styles } from './styles';

const Index = ({ merchant }) => {
	return (
		<Styles>
			<Container>
				<Flex flexDir='column' alignItem='flex-start'>
					<h4>Business Information</h4>
					<p>Approve Merchant Business Information</p>
				</Flex>
			</Container>
			<Grid
				className='grid_container'
				justifyContent={'flex-start'}
				gap={'40px 60px'}
				gridCol={'repeat(2,1fr)'}
				alignItem={'flex-start'}
				width={'100%'}
			>
				<Box>
					<label htmlFor='fullName'>Business Name</label>
					<span>{merchant?.business_name}</span>
				</Box>
				<Box>
					<label htmlFor='Phone number'>Business Address</label>
					<span>{merchant?.address}</span>
				</Box>
				<Box>
					<label htmlFor='fullName'>Trading Name</label>
					<span>{merchant?.trading_name}</span>
				</Box>
				<Box>
					<label htmlFor='email'>Contact Email</label>
					<span>{merchant?.business_email}</span>
				</Box>
				<Box>
					<label htmlFor='email'>Authorized Representative/Signatory</label>
					<span>{merchant?.authorized_signatory}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>Country</label>
					<span>{merchant?.country}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>
						Name of Ultimate Beneficial Owner (UBO)
					</label>
					<span>{merchant?.ultimate_beneficial_owner}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>Expected Monthly Inflow</label>
					<span>{merchant?.expected_monthly_inflow}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>
						Is your business licensable in your country of operation?{' '}
					</label>
					<span>{merchant?.licensable_in_country}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>Do you have a license?</label>
					<span>{merchant?.has_license}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>
						Are any of your shareholders, directors or officers a Politically
						Exposed Person?
					</label>
					<span>{merchant?.has_license}</span>
				</Box>
				<Box>
					<label htmlFor='Email address'>
						Name of Politically Exposed Person
					</label>
					<span>{merchant?.politically_exposed_person}</span>
				</Box>
				<Box pad={'40px 0px'}>
					<label htmlFor='Email address'>Business description</label>
					<span>{merchant?.business_description}</span>
				</Box>
			</Grid>
		</Styles>
	);
};

export default Index;
