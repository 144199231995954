import React, { useState } from 'react';
import CustomTable from '../table';

import { Container1, Styles } from './styles';
// import Button from '../../../../components/button';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { getTransactionsDeposit } from '../../../../services/Transactions.service';
import { TransactionTableHeadDeposit } from '../../../../utils/constants';
import { formatType } from '../../../../utils/helpers';
import {
	ApproveTransactionSummary,
	QRScreen
} from '../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import { SearchInputStyles } from '../withdraws/styles';

const Index = () => {
	let location = useLocation();
	const [id, setId] = React.useState(null);
	const [status, setStatus] = React.useState('');
	const [qrCode, setQrCode] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [selectedCurrency, setSelectedCurrency] = React.useState(
		location?.state?.selectedCurrency
	);
	const [value, setValue] = React.useState('All');
	const [currency, setCurrency] = React.useState('All');
	const [statusValue, setStatusValue] = React.useState('All');
	const [selectedDateTo, setSelectedDateTo] = React.useState('All');
	const [selectedDateFrom, setSelectedDateFrom] = React.useState('All');
	const [search, setSearch] = React.useState('');
	const [filter, setFilter] = React.useState(
		selectedCurrency !== undefined
			? {
					page: 1,
					currency: selectedCurrency
			  }
			: {
					page: 1
			  }
	);
	const [clicked, setClicked] = React.useState('false');
	const [selectedIndex, setSelectedIndex] = useState([]);

	const result = useQuery(
		'getTransactionsDeposit',
		async () => await getTransactionsDeposit(search, filter),
		{
			refetchOnWindowFocus: false
		}
	);

	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
	};

	const handleCancelqr = () => {
		setVisible(false);
	};

	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
	};

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const onChangeStatus = (e) => {
		const { value } = e.target;
		setStatusValue(value);
		setFilter({ ...filter, status: value });
	};

	const handleCurrencyChange = (e) => {
		setSelectedCurrency();
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.status === 'All') {
			delete filter.status;
		}
		setClicked('true');
		result.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	const handleCancel = () => {
		setVisible(false);
	};

	// if (result.isLoading) {
	// 	return <Loader />;
	// }

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}

	// console.log(selectedCurrency);
	return (
		<>
			<Styles>
				<Container1>
					<Container className='input_container' width={'20%'}>
						<SearchInputStyles>
							<Input
								// size='large'
								placeholder='Search reference no.'
								bordered={false}
								style={{
									border: '0.7px solid #dfdfdf',
									boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
									height: '45px',
									background: 'transparent'
								}}
								onChange={handleSearch}
								onPressEnter={() => result?.refetch(search)}
								prefix={
									<SearchOutlined
										style={{
											padding: 0
										}}
										onClick={() => result?.refetch(search)}
									/>
								}
							/>
						</SearchInputStyles>
					</Container>
					<Filters
						source='transaction'
						currency={selectedCurrency || currency}
						setCurrency={setCurrency}
						statusValue={statusValue}
						value={value}
						handleSelectFromChange={handleSelectFromChange}
						handleSelectToChange={handleSelectToChange}
						onChange={onChange}
						onChangeStatus={onChangeStatus}
						selectedDateFrom={selectedDateFrom}
						selectedDateTo={selectedDateTo}
						setFilter
						handleCurrencyChange={handleCurrencyChange}
						refetch={handleSubmit}
						loading={clicked === 'true' && result.isRefetching}
					/>
				</Container1>
				{result?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={TransactionTableHeadDeposit}
						tableBody={result?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handlePageChange={handlePageChange}
						meta={result?.data?.data?.data?.meta}
						paginator
						handleApprove={showModal}
						handleReject={showModal}
						setSelectedIndex={setSelectedIndex}
						selectedIndex={selectedIndex}
					/>
				)}
			</Styles>
			<ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
				<ApproveTransactionSummary
					id={id}
					handleQrScreen={handleqrCode}
					swapAction={status}
					setVisible={setVisible}
				/>
				<ModalFrame
					visible={qrCode}
					handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame>
		</>
	);
};

export default Index;
