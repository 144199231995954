import { Button, Popover } from 'antd';
import React from 'react';
import { DotIcon, DownArrow, RightArrowIcon } from '../../assets';
import { Flex } from '../box';

import { BulkContainer, PopoverStyle, SelectContainer } from './styles';

const PopOver = (props, ref) => {
	return (
		<Popover
			placement={props.placement}
			trigger='click'
			content={<PopOverContent {...props} />}
			// ref={ref}
		>
			{props.type === 'filter' && (
				<SelectContainer>
					<span>{props.title}</span>
					<Flex className='select-content'>
						<span className={props.title === 'Date' && 'formatText'}>
							{props.selected}
						</span>
						<DownArrow />
					</Flex>
				</SelectContainer>
			)}
			{props.type === undefined && (
				<div className='icon_box'>
					<DotIcon />
				</div>
			)}
			{props.type === 'wallet' && (
				<div className='icon_box'>
					<DotIcon />
				</div>
			)}
			{props.type === 'approvals' && (
				<Button
					type='button'
					style={{
						height: '44px',
						background: '#1A2CCE',
						color: '#FFFFFF',
						border: 'none',
						borderLeft: '1px solid #5157EB'
					}}
					icon={<DownArrow width='40' height='10' />}
					size={'large'}
				/>
			)}

			{props.type === 'multiSelect' && (
				<BulkContainer
					className='select-content'
					alignItems='center'
					justifyContent='flex-start'
					// width='10%'
				>
					<span className={'formatText'}>{props.selected}</span>
					<DownArrow />
				</BulkContainer>
			)}

			{props.type === 'set' && <RightArrowIcon width={'20'} height={'10'} />}
		</Popover>
	);
};

export default React.forwardRef(PopOver);

const PopOverContent = (props) => {
	return <PopoverStyle>{props.children}</PopoverStyle>;
};
