import styled from 'styled-components';
import { Flex } from '../box';

export const Styles = styled(Flex)`
	// .ant-btn-group
	justify-content: flex-end;
	.ant-dropdown-button {
		left: 0;
		button {
			height: 44px;
			background: #1a2cce;
			color: #ffffff;
			border: none;
			border-left: 1px solid #5157eb;
		}
	}
`;
