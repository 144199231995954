import styled from 'styled-components';

export const Styles = styled.div`
	h3 {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 23px;
		letter-spacing: 0.01em;

		color: #000000;
		margin-bottom: 22px;
		text-align: left;
	}

	.ant-form-item {
		// width: 250px;
	}

	.ant-select-selector {
		height: 46px !important;
		padding: 5px 11px !important;
	}

	.ant-switch {
		width: 37.89px;
		height: 22.33px;
	}

	.ant-switch-checked {
		background: #69ce1a;
		border-radius: 24.2105px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		/* identical to box height */
		color: #166f00;
	}
`;

export const StylesResponse = styled.div`
	h3 {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 23px;
		letter-spacing: 0.01em;
		color: #000000;
		padding-bottom: 0;
		margin-bottom: 6px;
		text-align: left;
	}

	.txt {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		// margin-bottom: 22px;
		color: #686868;
		text-align: left;
	}
	.iconText {
		margin-top: 30px;
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		p:last-child {
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.145em;
			margin-left: 13px;
			color: #000a62;
		}
	}
	.mainBox {
		background: #f3f6fb;
		border-radius: 4px;
		padding: 26px 20px;
		margin-bottom: 19px;

		button {
			padding: 3px;
			width: 70px;
			border-radius: 16px;
			font-size: 14px;
			background-color: #3ab75d;
			color: #fff;
			margin: 0 10px;
		}
		.acct_display {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 30px;
			.mainTxt {
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;

				color: #000000;
				margin-bottom: 16px;
			}
			.txt {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				text-align: right;

				color: #000000;
			}
		}
		.acct_display2 {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			.mainTxt {
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;

				color: #000000;
				// margin-bottom: 16px;
			}
			.txt {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				text-align: right;

				color: #000000;
			}
		}
	}
`;
