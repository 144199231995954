import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ApprovedAprrovals from '../pages/dashboard/approvals/approved';
import PendingAprrovals from '../pages/dashboard/approvals/pending';
import RejectedAprrovals from '../pages/dashboard/approvals/rejected';
import Overview from '../pages/dashboard/overview';

import { useAuthState } from '../context';
import Balances from '../pages/dashboard/balances';
import Compliance from '../pages/dashboard/compliance';
import Fees from '../pages/dashboard/fees/index';
import FxOps from '../pages/dashboard/fxOps';
import FXOpsTransactionsDetails from '../pages/dashboard/fxOps/transactions/transaction/index';
import Liquiditys from '../pages/dashboard/liquidity';
import LiquidityDetail from '../pages/dashboard/liquidity/liquidityDetail';
import LiquidityRequest from '../pages/dashboard/liquidity/liquidityRequest';
import Markets from '../pages/dashboard/markets';
import Merchants from '../pages/dashboard/merchants';
import Merchant from '../pages/dashboard/merchants/merchant';
import WalletCompliance from '../pages/dashboard/merchants/merchant/wallets/WalletCompliance';
import Refunds from '../pages/dashboard/refunds';
import Settings from '../pages/dashboard/settings';
import Transactions from '../pages/dashboard/transactions';
import ProviderStatus from '../pages/dashboard/transactions/providerStatus/ProviderStatus';
import TransactionsDetails from '../pages/dashboard/transactions/transaction';
import WalletsCompliance from '../pages/dashboard/wallet-compliance';
import Wallets from '../pages/dashboard/wallets/Wallets';
import Wallet from '../pages/dashboard/wallets/wallet/Wallet';
import { RoleCheck } from './privateRoute';
// import MerchantWallets from '../pages/dashboard/merchants/merchant/wallets/MerchantWallets';

const Index = () => {
	const { state } = useAuthState();

	const [role, setRole] = useState();
	const [permissions, setPermissions] = useState();

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setPermissions(state?.user?.role?.permitions);
	}, [state]);

	return (
		<Routes>
			<Route index path={'/'} element={<Overview />} />
			<Route path={'/balances/*'} element={<Balances />} />
			<Route path={'/approvals/pending/*'} element={<PendingAprrovals />} />
			<Route path={'/approvals/approved/*'} element={<ApprovedAprrovals />} />
			<Route path={'/approvals/rejected/*'} element={<RejectedAprrovals />} />
			<Route path={'/wallets'} element={<Wallets />} />

			<Route
				path='/merchants/wallets/:ref/wallet-compliance'
				element={<WalletCompliance />}
			/>

			<Route path={'/merchants/wallets/:walletID'} element={<Wallet />} />

			<Route path={'/refund/*'} element={<Refunds />} />
			{/* transactions & refunds */}
			{role === 'Super Admin' ? (
				<Route
					path={'/transactions/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Transactions />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<>
					<Route
						path={'/transactions/*'}
						element={
							<RoleCheck role={permissions?.includes('transactions')}>
								<Transactions />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/transactions/*'}
					element={
						<RoleCheck role={permissions?.includes('transactions')}>
							<Transactions />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/transactions/*'}
					element={
						<RoleCheck role={permissions?.includes('transactions')}>
							<Transactions />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/transactions/*'}
					element={
						<RoleCheck role={permissions?.includes('transactions')}>
							<Transactions />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/transactions/*'}
					element={
						<RoleCheck role={permissions?.includes('transactions')}>
							<Transactions />
						</RoleCheck>
					}
				/>
			) : null}

			<Route
				path={'/transactions/provider-status/*'}
				element={<ProviderStatus />}
			/>
			<Route
				path={'/transactions/:pathLocator/:transactionID'}
				element={<TransactionsDetails />}
			/>
			{/* <Route path={'/wallets-compliance/*'} element={<WalletsCompliance />} /> */}

			{/* merkets */}
			{role === 'Super Admin' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Super Admin' ? (
				<Route
					path={'/fees/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Fees />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('markets')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('markets')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('markets')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('markets')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/markets/*'}
					element={
						<RoleCheck role={permissions?.includes('markets')}>
							<Markets />
						</RoleCheck>
					}
				/>
			) : null}

			{/* compliance */}
			{role === 'Super Admin' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('all')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('all')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Admin' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Treasury' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Finance' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliancecompliance')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Compliance' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('compliance')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}
			{role === 'Support' ? (
				<>
					<Route
						path={'/compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('all')}>
								<Compliance />
							</RoleCheck>
						}
					/>
					<Route
						path={'/wallets-compliance/*'}
						element={
							<RoleCheck role={permissions?.includes('all')}>
								<WalletsCompliance />
							</RoleCheck>
						}
					/>
				</>
			) : null}

			{/* merchants */}
			{role === 'Super Admin' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/merchants/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchants />
						</RoleCheck>
					}
				/>
			) : null}

			{/* merchant id */}
			{role === 'Super Admin' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/merchants/:merchant/*'}
					element={
						<RoleCheck role={permissions?.includes('merchants')}>
							<Merchant />
						</RoleCheck>
					}
				/>
			) : null}

			{/* liquidity */}
			{role === 'Super Admin' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/liquidity/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}

			{/*liquidity request */}
			<Route path={'/liquidity/request/'} element={<LiquidityRequest />} />
			{role === 'Super Admin' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<Liquiditys />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<LiquidityRequest />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<LiquidityRequest />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<LiquidityRequest />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<LiquidityRequest />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/liquidity/request/'}
					element={
						<RoleCheck role={permissions?.includes('liquidity')}>
							<LiquidityRequest />
						</RoleCheck>
					}
				/>
			) : null}
			<Route path={'/liquidity/:overdraft/'} element={<LiquidityDetail />} />
			<Route
				path={'/liquidity/:overdraft/transaction/:transactionId'}
				element={<TransactionsDetails />}
			/>

			{/* fxOps */}
			{role === 'Super Admin' && permissions?.includes('all') ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('all')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Admin' ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('fxops')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Treasury' ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('fxops')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Finance' ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('fxops')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Compliance' ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('fxops')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			{role === 'Support' ? (
				<Route
					path={'/fxOps/*'}
					element={
						<RoleCheck role={permissions?.includes('fxops')}>
							<FxOps />
						</RoleCheck>
					}
				/>
			) : null}
			<Route
				path={'/fxOps/:fxOpsTransactionID/transaction'}
				element={<FXOpsTransactionsDetails />}
			/>

			{/* settings  */}
			<Route path={'/settings/*'} element={<Settings />} />
		</Routes>
	);
};

export default Index;
