import { useState } from "react";
import { Container } from "../../../../components/box";
import Filters from "../../../../components/filters/Filters";
import Search from "../../../../components/search";
import CustomTable from "./table";
import { Container1 } from "./styles";
import ModalFrame from "../../../../components/modal/ModalFrame";
import Button from "../../../../components/button";
import FxOpsForm from "../form";
import FxOpsAccountDetails from "../FxOpsAccountDetails";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getFxOpsAccounts,
  makePayout,
  setDefaultFxOpsAccount,
} from "../../../../services/fxOps.services";
import { ToastMessage, type } from "../../../../utils/toastr";
import { formatType } from "../../../../utils/helpers";
import { Loader } from "../../../../components/loader";

const Index = () => {
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [visibleDefault, setVisibleDefault] = useState(false);
  const [visiblePayout, setVisiblePayout] = useState(false);
  const [id, setId] = useState(null);

  const [value, setValue] = useState("All");
  const [currency, setCurrency] = useState("All");
  const [statusValue, setStatusValue] = useState("All");
  const [selectedDateTo, setSelectedDateTo] = useState("All");
  const [selectedDateFrom, setSelectedDateFrom] = useState("All");
  const [clicked, setClicked] = useState("false");

  const [filter, setFilter] = useState({ page: 1 });
  const [input, setInput] = useState("");

  const FxOpsAccounts = useQuery(
    "getFxOpsAccounts",
    async () => await getFxOpsAccounts(filter)
  );

  const FxOpsPayout = useMutation(
    async (data) => {
      await makePayout(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAccounts");
        queryClient.invalidateQueries("fxOpsPayout");
        ToastMessage(type.SUCCESS, "Payout successfully added");
        setVisiblePayout(false);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const setDefaultFxOpsAccountMutation = useMutation(
    async (data) => {
      await setDefaultFxOpsAccount(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFxOpsAccounts");
        ToastMessage(type.SUCCESS, "Account successfully set as default");
        setVisibleDefault(false);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showDetails = (id) => {
    setVisible(true);
    setId(id);
  };

  const showDefault = (id) => {
    setVisibleDefault(true);
    setId(id);
  };

  const showPayout = (id) => {
    setVisiblePayout(true);
    setId(id);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleDefault(false);
    setVisiblePayout(false);
  };

  const handlePayout = (values) => {
    const payload = {
      amount: +values.amount,
      account_id: id,
      fee: +values.fee,
      fee_type: values.fee_type,
    };
    FxOpsPayout.mutate(payload);
  };

  const handleSetDefault = () => {
    setDefaultFxOpsAccountMutation.mutate(id);
  };

  const onChange = (e) => {
    const { value } = e.target;
    const splitDate = value.split(" - ");
    setValue(value);
    setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
  };

  const onChangeStatus = (e) => {
    const { value } = e.target;
    setStatusValue(value);
    setFilter({ ...filter, status: value });
  };

  const handleSelectFromChange = (e, dateString) => {
    setSelectedDateFrom(formatType(dateString));
    setFilter({ ...filter, from: formatType(dateString) });
  };

  const handleSelectToChange = (e, dateString) => {
    setSelectedDateTo(formatType(dateString));
    setFilter({ ...filter, to: formatType(dateString) });
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    setCurrency(value);
    setFilter({ ...filter, currency: value });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setInput(value);
    setFilter({ ...filter, ref: value });
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => FxOpsAccounts.refetch());
  };

  const handleSubmit = () => {
    if (filter.from === "All" || undefined) {
      delete filter.from;
    }
    if (filter.to === "All" || undefined) {
      delete filter.to;
    }
    if (filter.currency === "All") {
      delete filter.currency;
    }
    if (filter.status === "All") {
      delete filter.status;
    }
    setClicked("true");
    FxOpsAccounts.refetch(filter);
  };

  if (FxOpsAccounts.isLoading) {
    return <Loader />;
  }

  if (FxOpsAccounts.isError) {
    return <span>Error: {FxOpsAccounts?.error.message}</span>;
  }

  return (
    <>
      <Container1>
        <Container className="input_container" width="20%">
          <Search
            placeholder={"Search reference no."}
            input={input}
            setInput={setInput}
            handleSearch={handleSearch}
          />
        </Container>
        <Filters
          source="transaction"
          currency={currency}
          setCurrency={setCurrency}
          statusValue={statusValue}
          value={value}
          handleSelectFromChange={handleSelectFromChange}
          handleSelectToChange={handleSelectToChange}
          onChange={onChange}
          onChangeStatus={onChangeStatus}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          setFilter
          handleCurrencyChange={handleCurrencyChange}
          refetch={handleSubmit}
          loading={clicked === "true" && FxOpsAccounts.isRefetching}
          handlePageChange={handlePageChange}
        />
      </Container1>
      <CustomTable
        theadBkColor="#F1F4F8"
        bottomGap="10px"
        tableHead={tableHead}
        tableBody={FxOpsAccounts?.data?.data?.data?.data}
        rowHovColor="#d2ccc626"
        theadColor="#000A62"
        tbodyColor="#141414"
        pageSize={10}
        meta={FxOpsAccounts?.data?.data?.data?.meta}
        paginator
        handleViewDetails={showDetails}
        handlePayout={showPayout}
        handleSetDefault={showDefault}
      />

      <ModalFrame
        visible={visiblePayout}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
            borderRadius="5px"
          />,
          <Button
            Styles
            text="Confirm"
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="editMerchant"
            loading={FxOpsPayout.isLoading}
            style={{ padding: "0.85rem 50px" }}
            borderRadius="5px"
          />,
        ]}
        pad="1rem"
      >
        <FxOpsForm handleSubmit={handlePayout} type="payout" isSaved={true} />
      </ModalFrame>
      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
        width="550px"
      >
        <FxOpsAccountDetails type="details" id={id} />
      </ModalFrame>
      <ModalFrame
        visible={visibleDefault}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
            borderRadius="5px"
          />,
          <Button
            Styles
            text="Confirm"
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="editMerchant"
            loading={setDefaultFxOpsAccountMutation.isLoading}
            style={{ padding: "0.85rem 50px" }}
            borderRadius="5px"
            onClick={handleSetDefault}
          />,
        ]}
        pad="1rem"
      >
        <h2
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "21px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Confirm
        </h2>
        <p>Are you sure you want to set account or wallet as default.</p>
      </ModalFrame>
    </>
  );
};

export default Index;

const tableHead = [
  "",
  "currency",
  "date created",
  "label",
  "Default",
  "",
  "",
  "",
];
