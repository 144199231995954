import { Input } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { Styles } from './styles';
const Index = ({ handleChange, placeholder, width }) => (
	<Styles width={width}>
		<Input
			size='large'
			placeholder={placeholder}
			prefix={<SearchOutlined />}
			onChange={handleChange}
		/>
	</Styles>
);

export default Index;
