import React from 'react';
import RouterTabs from '../../../components/Tabs/routerTab';
import Layout from '../../../components/layouts';
import { Spacer } from '../../../components/spacer/styles';
import Pending from './pending';
import { Styles } from './styles';
import Approved from './verified';

const Index = () => {
	const links = [
		{
			path: '/dashboard/wallets-compliance',
			title: 'Personal'
		},
		{
			path: '/dashboard/wallets-compliance/business',
			title: 'Business'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Pending />
		},
		{
			path: '/business',
			component: <Approved />
		}
		// {
		// 	path: '/suspended',
		// 	component: <Suspended />
		// }
	];

	return (
		<Layout title={'Wallets Compliance'}>
			<Styles>
				<Spacer height='20px' />
				<RouterTabs routes={routes} links={links} type='primary-tab' />
			</Styles>
		</Layout>
	);
};

export default Index;
