import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';

const DebounceSelect = ({ fetchOptions, debounceTimeout = 1000, ...props }) => {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = useRef(0);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			fetchOptions(value).then((newOptions) => {
				console.log(
					newOptions?.data?.data?.map((merchant) => ({
						label: `${merchant.business_name}`,
						value: merchant.id
					}))
				);
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}
				setOptions(
					newOptions?.data?.data?.map((merchant) => ({
						label: `${merchant.business_name}`,
						value: merchant.id
					}))
				);
				setFetching(false);
			});
		};
		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	// const options = this.state.data.map((d) => (
	// 	<Option key={d.value} value={d.value}>
	// 		{d.text}
	// 	</Option>
	// ));
	return (
		<Select
			labelInValue
			filterOption={false}
			showSearch
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size='small' /> : null}
			{...props}
			options={options}
		/>
	);
};

export default DebounceSelect;
