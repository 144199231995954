import { MinusCircleOutlined, DownOutlined } from "@ant-design/icons";
import { Collapse, Divider, Form, Input, Select } from "antd";
import Button from "../../../../../../components/button";
import { useQuery } from "react-query";
import { getSingleCurrencyPair } from "../../../../../../services/Others.service";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";

const FormLayout = ({ action, handleSubmit, form }) => {
  const singleCurrencyPair = useQuery(
    "getSingleCurrencyPair",
    async () => await getSingleCurrencyPair()
  );

  const { Panel } = Collapse;
  // const [form] = useForm();
  const [leftPair, setLeftPair] = useState("");

  const currencyPair = singleCurrencyPair?.data?.data?.data;

  const handleInputValueChange = (e) => {
    const { value } = e;
    const newPair = value.replace(/\//g, " ").split(" ");
    setLeftPair(newPair[0]);
  };

  return (
    <>
      <h3 style={{ textAlign: "left" }}>
        {action === "add" ? " Add new settlement time" : "Edit settlement time"}
      </h3>
      <>
        <Form
          id="submitForm"
          onFinish={handleSubmit}
          form={form}
          layout={"vertical"}
        >
          <Form.Item
            label="Currency pair"
            name="currency_pair"
            rules={[
              {
                required: true,
                message: "currency pair is required!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              showSearch
              onChange={(e, name) => {
                handleInputValueChange(name);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select currency pair"
            >
              {currencyPair?.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name="settlementTimeList">
            {(settlementTimeList, { add, remove }) => {
              return (
                <>
                  {settlementTimeList.map((settlementTime, index) => (
                    <Collapse
                      defaultActiveKey={0}
                      key={settlementTime.key}
                      destroyinactivepanel={true}
                      bordered={false}
                      expandiconposition={"right"}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <DownOutlined />
                        ) : (
                          <MinusCircleOutlined
                            style={{ fontSize: "20px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              remove(settlementTime.name);
                            }}
                          />
                        )
                      }
                      className="site-collapse-custom-collapse"
                    >
                      <Panel
                        id={index}
                        // header={`${min?.map((id) => index === id)}-${max?.map(
                        //   (id) => index === id
                        // )}`}
                        // key={index}
                        className="site-collapse-custom-panel"
                      >
                        <Form.Item
                          label="Minimum amount"
                          name={[index, "minimum_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Minimum amount is required!",
                            },
                          ]}
                        >
                          <Input
                            // onChange={(e, index) => handleChangeMin(e, index)}
                            type="number"
                            placeholder="input minimum amount"
                            addonAfter={leftPair !== "" ? leftPair : ""}
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Maximum amount"
                          name={[index, "maximum_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "maximum amount is required!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (value < getFieldValue("minimum_amount")) {
                                  return Promise.reject(
                                    "maximum amount must be greater than minimum amount"
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="input maximum amount"
                            addonAfter={leftPair !== "" ? leftPair : ""}
                            min={1}
                            // onChange={(e, index) => handleChangeMax(e, index)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Settlement time"
                          name={[index, "settlement_time"]}
                          rules={[
                            {
                              required: true,
                              message: "Settlement time is required!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="input settlement amount"
                            addonAfter="mins"
                          />
                        </Form.Item>
                      </Panel>
                      <Divider />
                    </Collapse>
                  ))}
                  <Button
                    Styles
                    text={"Add new settlement time"}
                    color={"#1A2CCE"}
                    size="md"
                    type="button"
                    onClick={() => {
                      add();
                    }}
                    style={{ padding: "0", textDecoration: "underline" }}
                  />
                </>
              );
            }}
          </Form.List>
        </Form>
      </>
    </>
  );
};

export default FormLayout;
