import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { EyeIcon } from '../../../../../../assets';
import { EmptyState } from '../../../../../../components/emptyState';
import { OverFlowScrollBar } from '../../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../../components/paginator';
import PopOver from '../../../../../../components/popover/PopOver';
import { generateID } from '../../../../../../utils/generateID';
import { formattedDate } from '../../../../../../utils/helpers';
import { CustomTableMain, CustomTd, Paginator, Td } from './styles';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	handlePageChange,
	meta,
	currencies,
	handleDelete,
	merchantName
}) => {
	const returnTableRow = (data, i) => {
		return (
			<tr key={data?.id}>
				<Td>
					<div>
						<input type={'checkbox'} />
					</div>
				</Td>

				<Td>
					<span>{formattedDate(data?.created_at)}</span>
				</Td>

				<Td>
					<span>{data?.business_name}</span>
				</Td>
				<Td>
					<span>{data?.full_name}</span>
				</Td>
				<Td>
					<span>{data?.wallet_reference}</span>
				</Td>

				<CustomTd>
					<PopOver placement='rightTop' type='wallet'>
						<Link
							// ${data?.merchantId}/
							to={`/dashboard/merchants/wallets/${data?.wallet_reference}`}
							state={{
								merchantName
							}}
						>
							<div>
								<EyeIcon />
								<p>View wallet</p>
							</div>
						</Link>
					</PopOver>
				</CustomTd>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>
											{head === '' && i === 0 && <input type={'checkbox'} />}
											{head.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{tableBody?.map((data, idx) => returnTableRow(data, idx))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination meta={meta} handlePageChange={handlePageChange} />
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string
};

export default CustomTable;
