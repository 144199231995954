import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { chartData } from "../../../../utils/constants";

export default class Example extends PureComponent {
  static demoUrl = "https://codesandbox.io/s/simple-area-chart-4ujxw";

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={chartData}
          margin={{
            top: 10,
            // right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            contentStyle={{ backgroundColor: "#F3F6FB", color: "#1B1B1B" }}
            itemStyle={{ color: "#1B1B1B" }}
            cursor={false}
          />
          <Area dataKey="uv" strokeWidth={3   } stroke="#7E8BFF" fill="#E2E9FF" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
