import React from "react";
import { Flex } from "../../../components/box";
import { ResetPasswordContainer, ResetPasswordText, SubText } from "./styles";
import Fields from "./fields";

const Signup = () => {
  return (
    <ResetPasswordContainer>
      <Flex
        className="form-container"
        width={"530px"}
        flexDir={"column"}
        margin={"auto"}
      >
        <ResetPasswordText>Reset Password</ResetPasswordText>
        <SubText>Enter a new password to secure your account </SubText>
        <Fields />
      </Flex>
    </ResetPasswordContainer>
  );
};

export default Signup;
