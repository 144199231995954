import PropTypes from "prop-types";
import React, { useState } from "react";
// import { generateID } from '../../../../../utils/generateID';
import Pagination from "../../../../../../components/paginator";
import { CustomTableMain, Td, Paginator, CustomTd } from "./styles";
import { OverFlowScrollBar } from "../../../../../../components/overflowScroll/styles";
import Button from "../../../../../../components/button";
import { toTitleCase } from "../../../../../../utils/helpers";
import { EmptyState } from "../../../../../../components/emptyState";

const CustomTable = ({
  tableBody,
  tableHead,
  theadColor,
  theadBkColor,
  tbodyColor,
  rowHovColor,
  bottomGap,
  paginator,
  pageSize,
  firstLast,
  prevNext,
  handleEdit,
  showDelete,
}) => {
  const [pageOfItems, setPageOfItems] = useState([]);

  //   const [tableData] = useState(tableBody);

  const onChangePage = (items) => {
    setPageOfItems(items);
  };

  const returnTableRow = (data, i) => {
    return (
      <tr key={data.id}>
        <Td>{data.currency_pair}</Td>
        {/* <Td>
          {data.settings.map((item) => (
            <span>{`${item.minimum_amount} ${item.maximum_amount}`}</span>
          ))}
        </Td> */}
        <CustomTd>
          {/* <Link to={`${i}`}>Edit user</Link> */}
          <Button
            Styles
            size="md"
            text={toTitleCase("edit")}
            color={"#1A2CCE"}
            type="button"
            onClick={() => handleEdit(data.id)}
          />
        </CustomTd>
        <CustomTd>
          <Button
            Styles
            size="md"
            text={toTitleCase("delete")}
            color={"#D50000"}
            type="button"
            onClick={() => showDelete(data.id)}
          />
        </CustomTd>
      </tr>
    );
  };
  return (
    <>
      {tableBody.length !== 0 ? (
        <CustomTableMain
          theadColor={theadColor}
          theadBkColor={theadBkColor}
          bottomGap={bottomGap}
          tbodyColor={tbodyColor}
          rowHovColor={rowHovColor}
          paginator={paginator}
        >
          <OverFlowScrollBar className="container">
            <table>
              <thead>
                <tr>
                  {tableHead.map((head, i) => (
                    <th key={i}>{toTitleCase(head)}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {paginator
                  ? pageOfItems.map((data, idx) => returnTableRow(data, idx))
                  : tableBody?.map((data, idx) => returnTableRow(data, idx))}
              </tbody>
            </table>
          </OverFlowScrollBar>

          <Paginator className="paginator" paginator={paginator}>
            <Pagination
              items={tableBody}
              pageSize={pageSize || 5}
              prevNext={prevNext || null}
              onChangePage={onChangePage}
              firstLast={firstLast || null}
            />
          </Paginator>
        </CustomTableMain>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

CustomTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  theadColor: PropTypes.string,
  theadBkColor: PropTypes.string,
  rowClick: PropTypes.func,
  tbodyColor: PropTypes.string,
  rowHovColor: PropTypes.string,
  bottomGap: PropTypes.string,
  pageSize: PropTypes.number,
  firstLast: PropTypes.any,
  paginator: PropTypes.any,
  prevNext: PropTypes.any,
};

export default CustomTable;
