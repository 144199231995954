import styled from "styled-components";
import { Flex } from "../../../../components/box";

export const Styles = styled.div`
  width: 100%;
  background-color: #f3f6fb;
  padding: 25px;
  border-radius: 5px;
`;

export const Card = styled(Flex)`
  padding: 10px 40px;
  border-right: 1px solid rgba(223, 223, 223, 0.39);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    color: #1a2cce;
  }
  .container {
    span:first-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #7171a6;
      padding-bottom: 20px;
    }
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      text-decoration-line: underline;
      color: #1a2cce;
    }
  }
  .viewall {
    span:first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #33334f;
      padding-bottom: 20px;
    }
    span:last-child {
    }
  }
`;
