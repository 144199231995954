import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { VerifiedIcon } from '../../../../assets';
import profileImg from '../../../../assets/images/profile.svg';
import RouterTabs from '../../../../components/Tabs/routerTab';
import { Flex } from '../../../../components/box';
import Layout from '../../../../components/layouts';
import { Loader } from '../../../../components/loader';
import { Spacer } from '../../../../components/spacer/styles';
import { useAuthState } from '../../../../context';
import { RoleCheck } from '../../../../routes/privateRoute';
import { getMerchantById } from '../../../../services/Merchants.service';
import Account from './account';
import Balances from './balances';
import Compliance from './compliance';
import Fees from './fees';
import Liquidity from './liquidity';
import Market from './market';
import Overview from './overview';
import Preferences from './preferences/Preferences';
import { ProfileContainer, Styles } from './styles';
import Team from './team';
import SessionLog from './team/sessionLog/ManageSession';
import Transaction from './transaction';
import MerchantWallets from './wallets/MerchantWallets';

const Index = () => {
	let params = useParams();
	const { state } = useAuthState();

	const [role, setRole] = useState();

	const merchant = useQuery(
		'getMerchantById',
		async () => await getMerchantById(params.merchant),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: 'always'
		}
	);

	useEffect(() => {
		setRole(state?.user?.role?.name);
	}, [state]);

	const checkVerified = merchant?.data?.data?.data?.kyc;
	const links = [
		{
			path: `/dashboard/merchants/${params.merchant}`,
			title: 'Overview'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/compliance`,
			title: 'Compliance'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/account`,
			title: 'Account'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/wallets`,
			title: 'Wallets'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/balances`,
			title: 'Balances'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/transactions`,
			title: 'Transactions'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/markets`,
			title: 'Markets'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/fees`,
			title: 'Fees'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/team`,
			title: 'Team'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/liquidity`,
			title: 'Liquidity'
		},
		{
			path: `/dashboard/merchants/${params.merchant}/preferences`,
			title: 'Preferences'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Overview merchant={merchant?.data?.data?.data} />
		},
		{
			path: `/compliance`,
			component: (
				<RoleCheck
					role={
						(role === 'Support' && false) ||
						(role === 'Finance' && false) ||
						(role === 'Treasury' && false) ||
						(role === 'Super Admin' && true) ||
						(role === 'Admin' && true) ||
						(role === 'Compliance' && true)
					}
				>
					<Compliance merchant={merchant?.data?.data?.data} />,
				</RoleCheck>
			)
		},
		{
			path: '/Account',
			component: (
				<RoleCheck role={role === 'Compliance' ? false : true}>
					<Account />
				</RoleCheck>
			)
		},
		{
			path: '/Wallets',

			component: (
				<RoleCheck role={role === 'Compliance' ? false : true}>
					<MerchantWallets
						merchant={merchant?.data?.data?.data?.business_name}
						merchantID={params.merchant}
					/>
				</RoleCheck>
			)
		},
		{
			path: '/balances',
			component: (
				<RoleCheck role={role === 'Compliance' ? false : true}>
					<Balances />
				</RoleCheck>
			)
		},
		{
			path: '/transactions',
			component: (
				<RoleCheck role={role === 'Compliance' ? false : true}>
					<Transaction />
				</RoleCheck>
			)
		},
		{
			path: '/markets',
			component: (
				<RoleCheck
					role={
						role === 'Super Admin' || role === 'Admin' || role === 'Treasury'
							? true
							: false
					}
				>
					<Market />
				</RoleCheck>
			)
		},
		{
			path: '/fees',
			component: (
				<RoleCheck
					role={
						role === 'Super Admin' || role === 'Admin' || role === 'Treasury'
							? true
							: false
					}
				>
					<Fees />
				</RoleCheck>
			)
		},
		{
			path: '/team',
			component: (
				<RoleCheck
					role={(role === 'Super Admin' && true) || (role === 'Admin' && true)}
				>
					<Team />
				</RoleCheck>
			)
		},
		{
			path: '/team/sessions/:userId',
			component: <SessionLog />
		},
		{
			path: '/liquidity',
			component: (
				<RoleCheck role={role !== 'Super Admin' ? false : true}>
					<Liquidity />
				</RoleCheck>
			)
		},
		{
			path: '/preferences',
			component: <Preferences />
		}
	];

	if (merchant?.status === 'loading') {
		return <Loader />;
	}

	if (merchant.status === 'error') {
		return <span>Error: {merchant.error}</span>;
	}

	return (
		<Layout>
			<Styles>
				<Spacer height='20px' />
				<Link
					className='backBtn'
					style={{
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '18px',

						color: '#000000'
						// cursor: 'pointer'
					}}
					to='/dashboard/merchants'
				>
					<ArrowLeftOutlined
						style={{
							marginRight: '9px'
						}}
					/>
					<span>Back</span>
				</Link>
				<Spacer height='40px' />

				<ProfileContainer
					status={checkVerified}
					justifyContent={'space-between'}
				>
					<Flex width='max-width'>
						<Flex
							bgColor={'#D7E6FF'}
							className='profile'
							width={'43px'}
							height={'43px'}
						>
							<img src={profileImg} alt={'profile-img'} />
						</Flex>
						<label for='img'>{merchant?.data?.data?.data?.business_name}</label>
						<div className='verified_box'>
							{checkVerified === 'verified' && <VerifiedIcon />}
							<span>{checkVerified}</span>
						</div>
					</Flex>
				</ProfileContainer>
				<Spacer height='48px' />
				{role === 'Super Admin' && (
					<RouterTabs type='merchant' routes={routes} links={links} />
				)}
				{role === 'Admin' && (
					<RouterTabs type='merchant' routes={routes} links={links} />
				)}
				{role === 'Treasury' && (
					<RouterTabs
						type='merchant'
						routes={routes}
						links={[
							links[0],
							links[2],
							links[3],
							links[4],
							links[5],
							links[6],
							links[7]
						]}
					/>
				)}
				{role === 'Finance' && (
					<RouterTabs
						type='merchant'
						routes={routes}
						links={[links[0], links[2], links[3], links[4]]}
					/>
				)}
				{role === 'Compliance' && (
					<RouterTabs
						type='merchant'
						routes={routes}
						links={[links[0], links[1]]}
					/>
				)}
				{role === 'Support' && (
					<RouterTabs
						type='merchant'
						routes={routes}
						links={[links[0], links[2], links[3], links[4]]}
					/>
				)}
			</Styles>
		</Layout>
	);
};

export default Index;
