import React from 'react';
import { Flex, Grid } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
import { useBankName } from '../../../../../../customHooks/useBankName';
import { useCopy } from '../../../../../../customHooks/useCopyToClipBoard';
import { Box, Container, Styles } from './styles';
const Index = ({ merchant }) => {
	const { copySuccess, copyToClipboard } = useCopy();
	const { handleSelectBankName } = useBankName();

	return (
		<Styles>
			<Container>
				<Flex flexDir='column' alignItem='flex-start'>
					<h4>Bank Information</h4>
					<p>Approve Bank Information</p>
				</Flex>
			</Container>
			<Grid
				className='grid_container'
				justifyContent={'flex-start'}
				gap={'40px 60px'}
				gridCol={'repeat(2,1fr)'}
				alignItem={'flex-start'}
				width={'100%'}
			>
				<Box>
					<label>BANK Details</label>
					<span>{merchant?.bank_details}</span>
				</Box>
				<Box>
					<label>BANK NAME</label>
					<span>{handleSelectBankName(merchant?.bank_code)}</span>
				</Box>
				<Box>
					<label>ACCOUNT NUMBER</label>
					<span>{merchant?.account_number}</span>
				</Box>
				<Box>
					<label>ACCOUNT NAME</label>
					<span>{merchant?.account_name}</span>
				</Box>
				<Box>
					<label>BVN</label>
					<span>{merchant?.bvn}</span>
				</Box>
				<Box>
					<label>Name on BVN</label>
					<span>{merchant?.bvn_name}</span>
				</Box>
			</Grid>
			<Button
				Styles
				text={copySuccess}
				bgColor={'#00B428'}
				color={'#fff'}
				size='sm'
				onClick={() => copyToClipboard(merchant?.bvn)}
				style={{
					border: '1px solid #3AB75D',
					boxSizing: 'border-box',
					borderRadius: '15px',
					padding: '5px 10px',
					textDecoration: 'none',
					marginTop: '10px'
				}}
			/>
		</Styles>
	);
};

export default Index;
