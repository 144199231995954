import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Container, Flex } from '../../../../../components/box';
import Filters from '../../../../../components/filters/Filters';
import { Loader } from '../../../../../components/loader';
import Search from '../../../../../components/search';
import { Spacer } from '../../../../../components/spacer/styles';
import { useAuthState } from '../../../../../context';
import { getMerchantCurrencies } from '../../../../../services/Others.service';
import {
	getUserprofile,
	getpaymentMethodDeposit,
	getpaymentMethodWithdrawal
} from '../../../../../services/Settings.service';
import {
	getMerchantTransactionsSummaryDeposit,
	getMerchantTransactionsSummarySwap,
	getMerchantTransactionsSummaryWithdrawal,
	getTransactionsByMerchantId,
	getTransactionsCount
} from '../../../../../services/Transactions.service';
import { TransactionTableHeadMerchant } from '../../../../../utils/constants';
import { formatType } from '../../../../../utils/helpers';
import AddNewTransaction from './addNewTransaction';
import Overview from './overveiw';
import { Container1, Styles } from './styles';
import CustomTable from './table';

const Index = () => {
	let params = useParams();
	const { state } = useAuthState();

	const [approver, setApprover] = useState();

	const [filter, setFilter] = useState({});
	const [input, setInput] = useState('');
	const [clicked, setClicked] = useState('false');
	const [value, setValue] = useState('All');
	const [typeValue, setTypeValue] = useState('All');
	const [currency, setCurrency] = useState('All');
	const [statusValue, setStatusValue] = useState('All');
	const [selectedDateTo, setSelectedDateTo] = useState('All');
	const [selectedDateFrom, setSelectedDateFrom] = useState('All');

	// useEffect(() => {
	// 	setApprover(state?.initiator);
	// }, [state, approver]);

	const result = useQuery(
		'getTransactionsByMerchantId',
		async () => await getTransactionsByMerchantId(params.merchant, filter)
	);

	const PaymentMethods = useQuery(
		'merchantPaymentMethod',
		async () => await getpaymentMethodWithdrawal()
	);

	// console.log(PaymentMethods?.data?.data?.data);
	const depositMethods = useQuery(
		'getpaymentMethodDeposit',
		async () => await getpaymentMethodDeposit()
	);

	const merchantCurrency = useQuery(
		'getMerchantCurrencies',
		async () => await getMerchantCurrencies(params.merchant)
	);

	const swap = useQuery(
		'getMerchantTransactionsSummarySwapById',
		async () =>
			await getMerchantTransactionsSummarySwap({ merchantId: params.merchant })
	);

	const withdrawal = useQuery(
		'getMerchantTransactionsSummaryWithdrawalById',
		async () =>
			await getMerchantTransactionsSummaryWithdrawal({
				merchantId: params.merchant
			})
	);

	const deposit = useQuery(
		'getMerchantTransactionsSummaryDepositById',
		async () =>
			await getMerchantTransactionsSummaryDeposit({
				merchantId: params.merchant
			})
	);
	const transactionCount = useQuery(
		'getTransactionCount',
		async () => await getTransactionsCount(params.merchant)
	);

	useQuery(
		['getUserprofile', localStorage.getItem('access_token')],
		async () => await getUserprofile(),
		{
			onSuccess: (res) => {
				console.log(res?.data?.data?.initiator);
				setApprover(res?.data?.data?.initiator);
			},
			refetchOnMount: 'always',
			enabled: !!localStorage.getItem('access_token')
		}
	);

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const onChangeStatus = (e) => {
		const { value } = e.target;
		setStatusValue(value);
		setFilter({ ...filter, status: value });
	};

	const onChangeType = (e) => {
		const { value } = e.target;
		setTypeValue(value);
		setFilter({ ...filter, type: value });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const handleCurrencyChange = (e) => {
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, ref: value });
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.status === 'All') {
			delete filter.status;
		}
		if (filter.type === 'All') {
			delete filter.type;
		}

		setClicked('true');
		result.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	if (result.isLoading) {
		return <Loader />;
	}

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}

	return (
		<Styles>
			<Spacer height='20px' />
			<Flex justifyContent='flex-end'>
				{/* {approver === true ? ( */}
				<AddNewTransaction
					PaymentMethods={PaymentMethods?.data?.data?.data}
					currencies={merchantCurrency?.data?.data?.data}
					depositMethods={depositMethods?.data?.data?.data}
				/>
				{/* ) : null} */}
			</Flex>
			<Spacer height='10px' />
			<Overview
				swap={swap?.data?.data?.data}
				deposit={deposit?.data?.data?.data}
				withdrawal={withdrawal?.data?.data?.data}
				transactionCount={transactionCount?.data?.data?.data}
				loading={
					deposit?.isFetching ||
					withdrawal?.isFetching ||
					swap?.isFetching ||
					transactionCount?.isFetching
				}
				error={
					deposit?.error?.message ||
					swap?.error?.message ||
					withdrawal?.error?.message ||
					transactionCount?.error?.message
				}
			/>

			<Spacer height='10px' />

			<Container1>
				<Container className='input_container' width={'20%'}>
					<Search
						placeholder={'Search by name.'}
						input={input}
						setInput={setInput}
						handleSearch={handleSearch}
					/>
				</Container>
				<Filters
					source='transaction'
					merchantTransactions
					currency={currency}
					setCurrency={setCurrency}
					statusValue={statusValue}
					typeValue={typeValue}
					value={value}
					handleSelectFromChange={handleSelectFromChange}
					handleSelectToChange={handleSelectToChange}
					onChange={onChange}
					onChangeType={onChangeType}
					onChangeStatus={onChangeStatus}
					selectedDateFrom={selectedDateFrom}
					selectedDateTo={selectedDateTo}
					setFilter
					handleCurrencyChange={handleCurrencyChange}
					refetch={handleSubmit}
					loading={clicked === 'true' && result.isRefetching}
				/>
			</Container1>

			<Spacer height='30px' />
			<Spacer height='20px' />
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={TransactionTableHeadMerchant}
				tableBody={result?.data?.data?.data?.data}
				rowHovColor='#d2ccc626'
				theadColor='#8e8e8e'
				tbodyColor='#141414'
				paginator
				meta={result?.data?.data?.data?.meta}
				handlePageChange={handlePageChange}
			/>
		</Styles>
	);
};

export default Index;
