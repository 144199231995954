import { Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Styles } from './styles';

const AccountForm = ({
	action,
	depositMethod,
	handleSubmit,
	initialEditValues,
	bankNames
}) => {
	const { merchant } = useParams();
	const [method, setMethod] = useState('FIAT');
	return (
		<>
			<Styles>
				<h3 style={{ textAlign: 'left' }}>
					{action === 'add' ? 'Add new account' : 'Edit Account'}
				</h3>
				<Form
					layout='vertical'
					onFinish={handleSubmit}
					id='editMerchant'
					initialValues={{
						layout: 'vertical',
						size: 'large',
						account_type: action === 'Edit' ? method : null,
						account_number:
							action === 'Edit' ? initialEditValues?.account_number : null,
						account_name:
							action === 'Edit' ? initialEditValues?.account_name : null,
						bank_name: action === 'Edit' ? initialEditValues?.bank_name : null,
						wallet_address:
							action === 'Edit' ? initialEditValues?.wallet_address : null,
						wallet_network:
							action === 'Edit' ? initialEditValues?.wallet_network : null,
						merchant_id: merchant,
						deposit_method_id:
							action === 'Edit'
								? initialEditValues?.depositMethod?.provider
								: null
					}}
				>
					<Form.Item label='Account Type' name='account_type'>
						<Select
							placeholder='Select Account Type'
							onChange={(value) => setMethod(value)}
						>
							<Select.Option value={'FIAT'}>FIAT</Select.Option>
							<Select.Option value={'CRYPTO'}>CRYPTO</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item
						label='Payment Method'
						name='deposit_method_id'
						rules={[{ required: true, message: 'Payment Method is required' }]}
					>
						<Select
							placeholder='Select Payment Method'
							disabled={action === 'Edit' && true}
						>
							{action !== 'Edit' &&
								depositMethod?.map((item) => (
									<Select.Option value={item.id}>{item.provider}</Select.Option>
								))}
						</Select>
					</Form.Item>
					{method !== 'CRYPTO' ? (
						<>
							<Form.Item
								label='Account Name'
								name='account_name'
								rules={[
									{ required: true, message: 'Account Name is required' }
								]}
							>
								<Input type='text' />
							</Form.Item>
							<Form.Item
								label='Account Number'
								name='account_number'
								rules={[
									{ required: true, message: 'Account Number is required' }
								]}
							>
								<Input type='number' />
							</Form.Item>
							<Form.Item
								label='Bank Name'
								name='bank_name'
								rules={[{ required: true, message: 'Bank Name is required' }]}
							>
								<Select placeholder='Select Bank Name'>
									{bankNames?.data?.data?.data?.map((item) => (
										<Select.Option value={item.name}>{item.name}</Select.Option>
									))}
								</Select>
							</Form.Item>
						</>
					) : (
						<>
							<Form.Item
								label='Wallet Address'
								name='wallet_address'
								rules={[
									{ required: true, message: 'Wallet Address is required' }
								]}
							>
								<Input type='text' />
							</Form.Item>
							<Form.Item
								label='Network'
								name='wallet_network'
								rules={[{ required: true, message: 'Network is required' }]}
							>
								<Input type='text' />
							</Form.Item>
						</>
					)}
				</Form>
			</Styles>
		</>
	);
};

export default AccountForm;
