import { REQUEST_LOGIN, LOGIN_SUCCESS, LOGOUT, ERROR } from './types';

export const initialState = {
	user: {},
	loading: false,
	errorMessage: '',
	token: ''
};

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_LOGIN:
			return {
				...state,
				loading: true
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload.user || action.payload,
				token: localStorage.getItem('access_token')
			};
		case LOGOUT:
			return {
				...state,
				user: {},
				token: ''
			};
		case ERROR:
			return {
				...state,
				errorMessage: action.payload
			};
		default:
			return {
				initialState
			};
	}
};
