import { Input, InputNumber, Select, Form } from "antd";
import React, { useState } from "react";
import { Container, Flex } from "../../../../../components/box";
import Button from "../../../../../components/button";
import { Styles } from "./styles";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import {
  addRateSource,
  getRateSource,
  refreshRate,
  setRateSource,
} from "../../../../../services/Settings.service";
import { Loader } from "../../../../../components/loader";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddNew } from "../../../../../assets";

const Index = ({ settings }) => {
  const result = useQuery("getRateSource", async () => await getRateSource());

  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [refresh, setRefresh] = useState(settings?.rate_refresh);

  const refreshMutation = useMutation(
    async (data) => {
      await refreshRate(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getSettings"]);
        ToastMessage(type.SUCCESS, "Admin rate refresh time successfully set");
        setTimeout(() => {
          setTimeout(() => setVisible(false), 500);
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const defualtSourceMutation = useMutation(
    async (data) => {
      await setRateSource(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getSettings"]);
        ToastMessage(type.SUCCESS, "Default rate source set successfully");
        setTimeout(() => {
          setTimeout(() => setVisible(false), 500);
        }, 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleChange = (e) => {
    setRefresh(e);
  };

  const handleSubmitRefreshRate = async () => {
    try {
      await refreshMutation.mutate({
        value: Number(refresh),
      });
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  const handleOk = (values) => {
    defualtSourceMutation.mutate(values.id);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result?.error.message}</span>;
  }
  const source = result?.data?.data?.data;
  const defaultSource = source?.find((item) =>
    item.default === true ? item.default : null
  );
  return (
    <>
      <Styles>
        <Container>
          <Flex flexDir="row" justifyContent="space-between" className="rate">
            <div>
              <h3>Market refresh rate</h3>
              <p>Add rate refresh for asset swap</p>
            </div>
            <div>
              <InputNumber
                className="refresh-rate"
                min={1}
                defaultValue={refresh && refresh}
                type="number"
                onChange={handleChange}
                addonAfter="Secs"
              />
            </div>
          </Flex>
          <Flex flexDir="row" justifyContent="space-between" className="source">
            <div>
              <h3>Default rate source</h3>
              <p>Choose a default market rate source for users</p>
            </div>
            <div>
              <Form onValuesChange={handleOk} form={form}>
                <Form.Item name="id" initialValue={defaultSource?.name}>
                  <Select size="large" style={{ width: "100%" }}>
                    {source?.map((item) => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </Flex>

          <Flex flexDir="row" justifyContent="space-between" className="btn">
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              width="40%"
              className="add-btn"
            >
              <AddNew />

              <Button
                Styles
                text={"Add new rate source"}
                color={"#1A2CCE"}
                size="md"
                type="button"
                onClick={showModal}
              />
            </Flex>

            <Button
              Styles
              text={"Save changes"}
              bgColor={"#1A2CCE"}
              color={"#FFFFFF"}
              onClick={handleSubmitRefreshRate}
              size="md"
              type="submit"
              className="save"
              loading={refreshMutation.isLoading}
            />
          </Flex>
        </Container>
      </Styles>
      <ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
        <FormLayout setVisible={setVisible} />
      </ModalFrame>
    </>
  );
};

export default Index;

const FormLayout = ({ setVisible }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const mutation = useMutation(
    async (data) => {
      await addRateSource(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getRateSource"]);
        ToastMessage(type.SUCCESS, "Source added successfully");
        setTimeout(() => setVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleSubmit = (values) => {
    mutation.mutate(values);
  };

  return (
    <>
      <h3 style={{ textAlign: "left" }}>Add new rate source</h3>
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          size: "large",
        }}
        size="large"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Name of source"
          name="name"
          rules={[{ required: true, message: "Name is Required" }]}
        >
          <Input placeholder="input placeholder" type="text" />
        </Form.Item>
        <Form.Item
          label="Public API key"
          name="public_key"
          rules={[{ required: true, message: "Public API key is Required" }]}
        >
          <Input placeholder="input placeholder" type="text" />
        </Form.Item>
        <Form.Item
          label="Private API key"
          name="private_key"
          rules={[{ required: true, message: "private API key is Required" }]}
        >
          <Input placeholder="input placeholder" type="text" />
        </Form.Item>
        <Button
          Styles
          text={"Add source"}
          bgColor={"#1A2CCE"}
          color={"#FFFFFF"}
          fullwidth
          size="md"
          type="submit"
          border={"0.7px solid #BFBFBF"}
          boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
          borderRadius={"3px"}
          loading={mutation.isLoading}
        />
      </Form>
    </>
  );
};
