import styled from 'styled-components';

export const Styles = styled.div`
	// width: 100%;
	// padding: 30px 30px 0;
	/* background-color: #f3f6fb; */
	// @media (max-width: 700px) {
	// 	padding: 20px 4%;
	// }
`;

export const Container1 = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	.fiter_container {
		gap: 30px;
		justify-content: space-between;
	}
	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 20px 10px;
		.fiter_container {
			width: 100%;
			justify-content: space-between;
			overflow: auto;
			flex-wrap: wrap;
			gap: 15px 5px;
			/* border: 1px solid red; */
		}
		.input_container {
			width: 100%;
		}
	}
`;

export const SearchInputStyles = styled.div`
	.ant-input {
		background: transparent;
	}
	.ant-input-affix-wrapper-lg,
	.ant-input-affix-wrapper {
		padding: 0 11px;
	}
`;
