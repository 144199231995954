import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	padding: 30px 30px 0;
	/* background-color: #f3f6fb; */
	@media (max-width: 700px) {
		padding: 20px 4%;
	}
`;
