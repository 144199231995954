import { Form, Button as GenButton, Input } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Flex } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
import { useAuthState } from '../../../../../../context';
import { getAllCurrencies } from '../../../../../../services/Settings.service';

const StepOneForm = ({
	tab,
	handleFormData,
	handleSelectFormData,
	nextStep,
	values,
	bvn
}) => {
	const [holdValue, setHoldValue] = useState(values.wallet_ref);
	const state = useAuthState();
	const checkToken = localStorage.getItem('access_token');
	const cureencies = useQuery(
		'getAllCurrencies',
		async () => await getAllCurrencies(),
		{
			onSuccess: (res) => {
				// setCurrencies(res?.data?.data);
			},
			onError: (error) => <p> {error.response.data.mesaage}</p>,
			refetchOnMount: 'always',
			enabled: !!checkToken
		}
	);
	let currencyPerTab =
		tab === 'Crypto'
			? cureencies?.data?.data?.data?.slice(5)
			: cureencies?.data?.data?.data?.slice(0, 5);

	// console.log({ values });
	const [form] = Form.useForm();
	const [refValue, setRefValue] = useState('');
	const [disabled, setDisabled] = React.useState(true);

	const generateRef = () => {
		const result = [...Array(2)]
			.map((value) => (Math.random() * 1000).toString(36).replace('.', ''))
			.join('');

		setRefValue(result);
	};

	const handleOnChange = (e) => {
		let { value } = e.target;
		// handleFormData()
		setRefValue(value);
	};
	const handleSubmit = () => {
		// setLoading(true);
		// console.log(data);
		// if (values.email !== undefined || values.email !== '') {
		// 	nextStep();
		// } else {
		// values.email = data.email;
		// values.currency = data.currency;
		// values.chain = data.chain;
		values.wallet_ref = refValue === '' ? holdValue : refValue;
		// values.phone = data.phone;

		nextStep();
		// }
	};

	const onValuesChange = (changedValues, allValues) => {
		if (tab === 'Crypto') {
			if (
				allValues.email !== undefined &&
				allValues.phone !== undefined &&
				allValues.currency !== undefined &&
				allValues.chain !== undefined
				// &&
				// refValue !== ''
			) {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
		} else {
			if (
				allValues.email !== undefined &&
				allValues.phone !== undefined &&
				allValues.currency !== undefined
			) {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
		}
		// console.log(allValues);
	};

	return (
		<>
			<Form layout='vertical' onFinish={handleSubmit} id='createWallet'>
				<Form.Item
					label='Email'
					name='email'
					rules={[{ required: true, message: 'Email  is required' }]}
				>
					<Input
						placeholder='Enter Email'
						defaultValue={values.email}
						type='email'
						// value={}
						onChange={handleFormData('email')}
					/>
				</Form.Item>
				{bvn === true && (
					<Form.Item
						label='BVN'
						name='bvn'
						rules={[
							{ required: true, message: 'BVN  is required' },
							{ min: 11, message: 'Bvn must be at least 11 characters long.' }
						]}
					>
						<Input
							placeholder='Enter BVN'
							defaultValue={values.bvn}
							type='text'
							onChange={handleFormData('bvn')}
						/>
					</Form.Item>
				)}
				<Form.Item
					label='Phone number'
					name='phone'
					rules={[{ required: true, message: 'Phone number is required' }]}
				>
					<Input
						placeholder='Enter Phone number'
						defaultValue={values.phone}
						onChange={handleFormData('phone')}
					/>
				</Form.Item>

				<Form.Item
					label='Reference'
					name='ref'
					// rules={[{ required: true, message: 'Reference No. is required' }]}
				>
					<Flex
						style={{
							border: '1px solid #D7D7D7',
							borderRadius: '5px'
						}}
					>
						<Input
							style={{
								width: 'calc(100% - 98px)'
							}}
							placeholder='Enter or generate reference no.'
							bordered={false}
							value={values.wallet_ref === '' ? refValue : values.wallet_ref}
							onChange={handleOnChange}
						/>

						<GenButton
							style={{
								width: '98px',
								height: '46px',
								background: '#F1F6FE',
								borderRadius: '0px 5px 5px 0px',
								border: 'none'
							}}
							onClick={generateRef}
						>
							Generate
						</GenButton>
					</Flex>
				</Form.Item>

				<Flex justifyContent='flex-end' marginTop='40px'>
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						// onClick={handleCancel}
					/>

					<Button
						Styles
						text='Next - Business'
						bgColor={'#1A2CCE'}
						color='#FFFFFF'
						size='md'
						type='submit'
						// form='createWallet'
						borderRadius='5px'
						// disabled={disabled}
						// loading={createMutation.isLoading}
					/>
				</Flex>
			</Form>
		</>
	);
};

export default StepOneForm;
