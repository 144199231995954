import apiClient from './axios';

const EndPoints = {
	getTotalMerchantSummaryCount: `/admin/summary/merchants?math=count`,
	getTotalSuspendedMerchantSummary: `/admin/summary/merchants?math=count&status=suspended`,
	createMerchant: `/admin/merchants/create`,
	inviteMerchant: `/admin/auth/generate-invite`,
	createMerchantAccount: `/admin/accounts/create`,
	getSettlementTime(merchantId) {
		return `/admin/settings/settlement-time?merchantId=${merchantId}`;
	},
	addsettlementTime: `/admin/settings/settlement-time`,
	rateRefresh(merchantId) {
		return `/admin/merchants/${merchantId}/settings?merchantId=${merchantId}`;
	},
	updateRateRefresh(merchantId) {
		return `/admin/merchants/${merchantId}/settings?merchantId=${merchantId}`;
	},
	updateMerchantDynamicAccountLimit(merchantId) {
		return `/admin/merchants/${merchantId}/settings?merchantId=${merchantId}`;
	},
	getAllMerchants(filterData) {
		let query = `/admin/merchants?order=DESC&take=10`;

		if (filterData.page) {
			query += `&page=${filterData.page}`;
		}
		if (filterData.kyc) {
			query += `&kyc=${filterData.kyc}`;
		}
		if (filterData.country) {
			query += `&country=${filterData.country}`;
		}
		if (filterData.status) {
			query += `&status=${filterData.status}`;
		}
		if (filterData.search) {
			query += `&search=${filterData.search}`;
		}
		return query;
	},

	getAllMerchantsName: `/admin/merchants?order=DESC&take=5000&page=1`,

	getTotalMerchantSignup(from, to) {
		return `/admin/summary/merchants?math=count&to=${to}&from=${from}`;
	},

	getMerchantById(id) {
		return `/admin/merchants/${id}`;
	},
	getMerchantSettingsById(id) {
		return `/admin/wallets/settings?merchantId=${id}`;
	},
	updateWalletLimit(id) {
		return `/admin/wallets/settings?merchantId=${id}`;
	},

	editMerchantById(id) {
		return `/admin/merchants/${id}`;
	},

	getMerchantAccount(id) {
		return `/admin/accounts/${id}`;
	},

	editMerchantAccount(id) {
		return `/admin/accounts/${id}/update`;
	},

	generateMerchantAccount(id) {
		return `/admin/accounts/${id}/generate`;
	},

	deleteMerchantAccount(id) {
		return `/admin/accounts/${id}/`;
	},

	suspendMerchant(id) {
		return `/admin/merchants/${id}/suspend`;
	},

	clearLimitMerchant(id) {
		return `/admin/merchants/${id}/clear-limit`;
	},

	resetLoginMerchant(id) {
		return `/admin/merchants/${id}/reset`;
	},
	resendEVM(id) {
		return `/admin/merchants/${id}/verify-email`;
	},
	unsupendMerchant(id) {
		return `/admin/merchants/${id}/unsuspend`;
	},

	enableMerchantCurrency(id) {
		return `/admin/merchants/${id}/currencies/enable`;
	},

	disableMerchantCurrency(id) {
		return `/admin/merchants/${id}/currencies/disable`;
	},

	merchantFees(id) {
		return `/admin/merchants/${id}/fees`;
	},

	editMerchantFees(merchantId, feeid) {
		return `/admin/merchants/${merchantId}/fees/${feeid}
`;
	},

	deleteMerchantFees(id) {
		return `/admin/merchants/fees/${id}`;
	},

	merchantSwapFees(id) {
		return `/admin/merchants/${id}/swap-fees`;
	},

	merchantTeams(id) {
		return `/admin/merchants/${id}/team`;
	},

	merchantRemoveTeams2fa(id) {
		return `/admin/merchants/${id}/team/remove-2fa`;
	},

	merchantClearTeamsLimit(id) {
		return `/admin/merchants/${id}/team/remove-limit`;
	},

	merchantTeamsDelete(id) {
		return `/admin/merchants/${id}/team/delete`;
	},

	merchantTeamsResetPassword(id) {
		return `/admin/merchants/${id}/team/reset-password`;
	},

	merchantTeamsSession(id, userId) {
		return `/admin/merchants/${id}/team/sessions?user_id=${userId}`;
	}
};

export const getAllMerchantsName = async () => {
	const res = await apiClient.get(EndPoints.getAllMerchantsName);
	return res;
};
export const getAllMerchants = async (filterData) => {
	const res = await apiClient.get(EndPoints.getAllMerchants(filterData));
	return res;
};

export const createMerchant = async (payload) => {
	const res = await apiClient.post(EndPoints.createMerchant, payload);
	return res;
};
export const inviteMerchant = async (payload) => {
	const res = await apiClient.post(EndPoints.inviteMerchant, payload);
	return res;
};

export const createMerchantAccount = async (payload) => {
	const res = await apiClient.post(EndPoints.createMerchantAccount, payload);
	return res;
};

export const editMerchantAccount = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.editMerchantAccount(id), payload);
	return res;
};

export const deleteMerchantAccount = async (id) => {
	const res = await apiClient.delete(EndPoints.deleteMerchantAccount(id));
	return res;
};
export const generateMerchantAccount = async (id) => {
	const res = await apiClient.get(EndPoints.generateMerchantAccount(id));
	return res;
};

export const getTotalMerchantSummaryCount = async () => {
	const res = await apiClient.get(EndPoints.getTotalMerchantSummaryCount);
	return res;
};

export const getTotalSuspendedMerchantSummary = async () => {
	const res = await apiClient.get(EndPoints.getTotalSuspendedMerchantSummary);
	return res;
};

export const getMerchantById = async (id) => {
	const res = await apiClient.get(EndPoints.getMerchantById(id));
	return res;
};
export const getMerchantSettingsById = async (id) => {
	const res = await apiClient.get(EndPoints.getMerchantSettingsById(id));
	return res;
};

export const updateWalletLimit = async (id, payload) => {
	const res = await apiClient.post(EndPoints.updateWalletLimit(id), payload);
	return res;
};
export const updateDynamicAccountLimit = async (id, payload) => {
	const res = await apiClient.patch(
		EndPoints.updateMerchantDynamicAccountLimit(id),
		payload
	);
	return res;
};
export const editMerchantById = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.getMerchantById(id), payload);
	return res;
};

export const getMerchantAccount = async (id) => {
	const res = await apiClient.get(EndPoints.getMerchantAccount(id));
	return res;
};

export const getTotalMerchantSignup = async (from, to) => {
	const res = await apiClient.get(EndPoints.getTotalMerchantSignup(from, to));
	return res;
};

export const suspendMerchant = async (id) => {
	const res = await apiClient.get(EndPoints.suspendMerchant(id));
	return res;
};

export const clearLimitMerchant = async (id) => {
	const res = await apiClient.get(EndPoints.clearLimitMerchant(id));
	return res;
};

export const unsupendMerchant = async (id) => {
	const res = await apiClient.get(EndPoints.unsupendMerchant(id));
	return res;
};

export const resetLoginMerchant = async (id, payload) => {
	const res = await apiClient.post(EndPoints.resetLoginMerchant(id), payload);
	return res;
};

export const resendEVMMerchant = async (id, payload) => {
	// console.log(id, payload);
	const res = await apiClient.patch(EndPoints.resendEVM(id), payload);
	return res;
};

export const enableMerchantCurrency = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.enableMerchantCurrency(id),
		payload
	);
	return res;
};

export const disableMerchantCurrency = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.disableMerchantCurrency(id),
		payload
	);
	return res;
};

export const merchantFees = async (id) => {
	const res = await apiClient.get(EndPoints.merchantFees(id));
	return res;
};

export const deleteMerchantFees = async (id) => {
	const res = await apiClient.delete(EndPoints.deleteMerchantFees(id));
	return res;
};

export const addMerchantFees = async (id, payload) => {
	const res = await apiClient.post(EndPoints.merchantFees(id), payload);
	return res;
};

export const editMerchantFees = async (merchantid, feeId, payload) => {
	const res = await apiClient.patch(
		EndPoints.editMerchantFees(merchantid, feeId),
		payload
	);
	return res;
};

export const merchantSwapFees = async (id, payload) => {
	const res = await apiClient.post(EndPoints.merchantSwapFees(id), payload);
	return res;
};

export const merchantTeams = async (id) => {
	const res = await apiClient.get(EndPoints.merchantTeams(id));
	return res;
};
export const merchantTeamsSession = async (id, userId) => {
	const res = await apiClient.get(EndPoints.merchantTeamsSession(id, userId));
	return res;
};

export const merchantEditTeams = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.merchantTeams(id), payload);
	return res;
};

export const merchantRemoveTeams2fa = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.merchantRemoveTeams2fa(id),
		payload
	);
	return res;
};

export const merchantClearTeamsLimit = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.merchantClearTeamsLimit(id),
		payload
	);
	return res;
};

export const merchantTeamsResetPassword = async (id, payload) => {
	const res = await apiClient.post(
		EndPoints.merchantTeamsResetPassword(id),
		payload
	);
	return res;
};

export const merchantTeamsDelete = async (id, payload) => {
	const res = await apiClient.delete(EndPoints.merchantTeamsDelete(id), {
		data: payload
	});
	return res;
};

export const merchantRateRefresh = async (id) => {
	const res = await apiClient.get(EndPoints.rateRefresh(id));
	return res;
};
export const getMerchantSettlementTime = async (id) => {
	const res = await apiClient.get(EndPoints.getSettlementTime(id));
	return res;
};

export const merchantRateRefreshUpdate = async (id, payload) => {
	const res = await apiClient.patch(EndPoints.updateRateRefresh(id), payload);
	return res;
};

export const addSettlementTime = async (id, payload) => {
	const res = await apiClient.post(EndPoints.getSettlementTime(id), payload);
	return res;
};
