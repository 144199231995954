import styled from 'styled-components';

export const CustomTableMain = styled.section`
	.container {
		// height: 60vh;
		// overflow: auto;
		/* @media (max-width: 600px) {
      height: 350px;
    } */
	}
	background: #fff;
	padding-bottom: ${({ paginator }) => !paginator && '20px'};
	width: 100%;
	table {
		background: #fff;
		border-collapse: collapse;
		text-align: start;
		width: 100%;
		overflow: auto;
		@media (max-width: 600px) {
			width: 700px;
		}
		thead {
			background: ${({ theadBkColor }) => theadBkColor || '#F1F4F8'};
			position: sticky;
			top: 0;
			z-index: 1;
			tr {
				th {
					text-align: start;
					color: #000a62;
					font-size: 12px;
					padding: 0.7rem 1.8rem;
					/* min-width: 160px; */
				}
			}
		}
		tbody {
			tr {
				background: white;
				cursor: pointer;
				border: none;
				overflow: auto;
				border-bottom: 0.4px solid rgba(198, 198, 198, 0.52);
			}
		}
	}
`;

export const Td = styled.td`
	padding: 0.8rem 1.8rem;
	font-weight: 400;
	font-size: 14px;

	svg {
		margin-right: 25px;
	}

	Button {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		text-decoration-line: underline;
	}
	// div {
	//   display: flex;
	//   flex-direction: row;
	//   align-items: center;
	//   strong {
	//     font-style: normal;
	//     font-weight: 4w00;
	//     font-size: 14px;
	//     line-height: 17px;
	//     color: #000000;
	//   }
	// }
	// span {
	//   font-size: 0.9rem;
	//   font-style: normal;
	//   font-weight: 400;
	//   font-size: 14px;
	//   margin-right: 5px;
	//   color: ${({ tbodyColor }) => tbodyColor || '#1A1F36'};
	// }
`;

export const CustomTd = styled.td`
	padding: 0.6rem 0;
	Button {
		text-decoration: underline;
	}
	// /*
	div {
		padding: 7px 0;
		text-align: center;
		background-color: ${({ status }) =>
			(status === 'unverified' && '#FFE4E4') ||
			(status === 'verified' && '#C8FFC7')};
		color: ${({ status }) =>
			(status === 'unverified' && '#CE1A1A') ||
			(status === 'verified' && '#027200')};
		> span {
			font-size: 14px;
		}
	}
	// */
	/* a {
		text-decoration: underline;
		color: #1a2cce;
	} */
	/* .icon_box {
		border: 0.7px solid #e7e7e7;
		box-sizing: border-box;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		min-height: 34px;
		width: 40px;
		cursor: pointer;
	} */
`;

export const Paginator = styled('div')`
	background: white;
	padding: 1em 5px;
	display: ${({ paginator }) => (paginator ? 'block' : 'none')};
	ul {
		justify-content: flex-end;
	}
`;
