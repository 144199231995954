import { Dropdown, Form, Input, Menu, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Container, Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { useAuthState } from '../../../../context';
import {
	addPaymentMethodWithdrawal,
	addpaymentMethodDeposit,
	deletePaymentMethodDeposit,
	deletePaymentMethodWithdrawal,
	disablePaymentMethodDeposit,
	disablePaymentMethodWithdrawal,
	duplicatePaymentMethodDeposit,
	duplicatePaymentMethodWithdrawal,
	editpaymentMethodDeposit,
	editpaymentMethodWithdrawal,
	getpaymentMethodDeposit,
	getpaymentMethodWithdrawal
} from '../../../../services/Settings.service';
import { paymentMethodsHead } from '../../../../utils/constants';
import { ToastMessage, type } from '../../../../utils/toastr';
import { FormLayout } from './formLayout';
import { PaymentMethod, Styles } from './styles';
import CustomTable from './table';

const { Option } = Select;

const Index = () => {
	const { currencies } = useAuthState();

	const queryClient = useQueryClient();
	const [visible, setVisible] = useState(false);
	const [duplicateVisible, setDuplicateVisible] = useState(false);
	const [addMethodVisible, setAddMethodVisible] = useState(false);

	const [action, setAction] = useState('');
	const [editMethod, setEditMethod] = useState('');
	const [deleteMethod, setDeleteMethod] = useState('');
	const [duplicateMethod, setDuplicateMethod] = useState('');
	const [id, setId] = useState('');
	const [currencyType, setCurrencyType] = useState('');
	const [initialEditValues, setInitialEditValues] = useState({});
	const [methods, setMethods] = useState([]);
	const [deposit, setDeposit] = useState([]);
	const [withdraw, setWithdraw] = useState([]);

	const [form] = Form.useForm();

	const depositMethod = useQuery(
		'getpaymentMethodDeposit',
		async () => await getpaymentMethodDeposit(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Deposit'));
				setDeposit(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);

	const withdrawMethod = useQuery(
		'getpaymentMethodWithdrawal',
		async () => await getpaymentMethodWithdrawal(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Withdraw'));
				setWithdraw(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		setMethods([...deposit, ...withdraw]);
	}, [deposit, withdraw]);

	const depositDuplicateMutation = useMutation(
		async (data) => {
			await duplicatePaymentMethodDeposit(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit method has been duplicated');
				setTimeout(() => setDuplicateVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const withdrawalDuplicateMutation = useMutation(
		async (data) => {
			await duplicatePaymentMethodWithdrawal(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal method has been duplicated');
				setTimeout(() => setDuplicateVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const depositDeleteMutation = useMutation(
		async (data) => {
			await deletePaymentMethodDeposit(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit method has been deleted');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const withdrawalDeleteMutation = useMutation(
		async (data) => {
			await deletePaymentMethodWithdrawal(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal method has been deleted');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const depositDisableMutation = useMutation(
		async (data) => {
			await disablePaymentMethodDeposit(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit method has been updated');
				setTimeout(() => setDuplicateVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const withdrawalDisableMutation = useMutation(
		async (data) => {
			await disablePaymentMethodWithdrawal(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal method has been updated');
				setTimeout(() => setVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const depositAddMutation = useMutation(
		async (data) => {
			await addpaymentMethodDeposit(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit method has been added');
				setTimeout(() => setAddMethodVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const withdrawalAddMutation = useMutation(
		async (data) => {
			await addPaymentMethodWithdrawal(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal method has been added');
				setAction('');
				setId('');
				setEditMethod('');
				setTimeout(() => setAddMethodVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const depositEditMutation = useMutation(
		async (data) => {
			await editpaymentMethodDeposit(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodDeposit');
				ToastMessage(type.SUCCESS, 'deposit method has been edited');
				setAction('');
				setId('');
				setEditMethod('');
				setTimeout(() => setAddMethodVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const withdrawalEditMutation = useMutation(
		async (data) => {
			await editpaymentMethodWithdrawal(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getpaymentMethodWithdrawal');
				ToastMessage(type.SUCCESS, 'withdrawal method has been edited');
				setTimeout(() => setAddMethodVisible(false), 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const showDelete = (e, method) => {
		setVisible(true);
		setId(e);
		setDeleteMethod(method);
	};

	const showDuplicate = (e, method) => {
		setDuplicateVisible(true);
		setId(e);
		setDuplicateMethod(method);
	};

	const handleDuplicate = () => {
		if (duplicateMethod === 'depositDuplicate') {
			depositDuplicateMutation.mutate(id);
		} else if (duplicateMethod === 'withdrawaldepositDuplicate') {
			withdrawalDuplicateMutation.mutate(id);
		}
	};

	const handleDelete = () => {
		if (deleteMethod === 'depositDelete') {
			depositDeleteMutation.mutate(id);
		} else if (deleteMethod === 'withdrawalDelete') {
			withdrawalDeleteMutation.mutate(id);
		}
	};

	const handleDisable = (id, method, status) => {
		const payload = status === false ? 'enable' : 'disable';
		setId(id);
		if (method === 'depositDisable') {
			setTimeout(() => depositDisableMutation.mutate({ status: payload }));
		} else if (method === 'withdrawalDisable') {
			setTimeout(() => {
				withdrawalDisableMutation.mutate({ status: payload });
			});
		}
	};

	const showAddMethod = (method) => {
		setAddMethodVisible(true);
		setAction(method);
	};

	const handleEdit = (id, method) => {
		setAction('edit');
		setId(id);
		setEditMethod(method);
		if (method === 'depositEdit') {
			depositMethod?.data?.data?.data?.find(
				(item) => item.id === id && setInitialEditValues(item)
			);
			depositMethod?.data?.data?.data?.find(
				(item) => item.id === id && setCurrencyType(item)
			);
			setTimeout(() => setAddMethodVisible(true), 500);
		}
		if (method === 'withdrawalEdit') {
			withdrawMethod?.data?.data?.data?.find(
				(item) => item.id === id && setInitialEditValues(item)
			);
			withdrawMethod?.data?.data?.data?.find(
				(item) => item.id === id && setCurrencyType(item)
			);
			setTimeout(() => setAddMethodVisible(true), 500);
		}
		const code =
			method === 'withdrawalEdit'
				? withdrawMethod?.data?.data?.data?.find(
						(item) => item.id === id && item
				  )
				: depositMethod?.data?.data?.data?.find(
						(item) => item.id === id && item
				  );

		currencies?.filter((c) => {
			if (c?.code === code.currencies[0]) {
				setCurrencyType(c?.type);
			}
		});
	};

	const handleOk = () => {
		if (depositAddMutation.isSuccess) {
			setVisible(false);
		}
		if (withdrawalAddMutation.isSuccess) {
			setVisible(false);
		}
		if (depositEditMutation.isSuccess) {
			setVisible(false);
		}
		if (withdrawalEditMutation.isSuccess) {
			setVisible(false);
		}
	};

	const handleCancel = () => {
		setVisible(false);
		setAddMethodVisible(false);
		setEditMethod('');
		setInitialEditValues({});
		setDuplicateVisible(false);
		setAction('');
		setId('');
		setCurrencyType('');
	};

	if (withdrawMethod.isFetching || depositMethod.isFetching) {
		return <Loader />;
	}
	// if (
	// 	withdrawMethod.status === 'loading' ||
	// 	depositMethod.status === 'loading'
	// ) {
	// 	return <Loader />;
	// }

	if (depositMethod.status === 'error' || withdrawMethod.status === 'error') {
		return (
			<span>
				Error: {depositMethod.error.message || withdrawMethod.error.message}
			</span>
		);
	}

	return (
		<>
			<Styles>
				<Container>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='baseline'
						style={{
							marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Input
							placeholder='Search'
							size='large'
							style={{
								width: '271px'
							}}
						/>
						<Flex width='max-width' justifyContent='flex-end'>
							<Dropdown
								trigger={['click']}
								overlay={
									<Menu
										style={{
											padding: '20px'
										}}
									>
										<Menu.Item key='0' onClick={() => showAddMethod('deposit')}>
											Deposit method
										</Menu.Item>
										<Menu.Item
											key='1'
											onClick={() => showAddMethod('withdrawal')}
										>
											Withdrawal method
										</Menu.Item>
									</Menu>
								}
							>
								<Button
									Styles
									text={'Add new method'}
									color={'#1A2CCE'}
									size='sm'
									fullwidth
								/>
							</Dropdown>

							{/* <Input.Group compact>
                <Input placeholder="Sort By" disabled width="40" />
                <Select defaultValue="All">
                  <Option value="All">All</Option>
                </Select>
              </Input.Group> */}
						</Flex>
					</Flex>

					<CustomTable
						theadBkColor='#F1F4F8'
						tableHead={paymentMethodsHead}
						tableBody={methods}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						pageSize={5}
						showDelete={showDelete}
						handleEdit={handleEdit}
						showDuplicate={showDuplicate}
						type='deposit'
						key={'deposit'}
						handleDisable={handleDisable}
					/>
				</Container>
			</Styles>
			<ModalFrame
				visible={visible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Delete'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='button'
						onClick={handleDelete}
						loading={
							depositDeleteMutation.isLoading ||
							withdrawalDeleteMutation.isLoading
						}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Delete payment method?</h3>
				<p>
					By deleting this method , all transactions via
					<br /> this means would no longer function
				</p>
			</ModalFrame>
			<ModalFrame
				visible={duplicateVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Duplicate'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='button'
						onClick={handleDuplicate}
						loading={
							depositDuplicateMutation.isLoading ||
							withdrawalDuplicateMutation.isLoading
						}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Duplicate payment method?</h3>
				<p>Are you sure you want to duplicate this payment method?</p>
			</ModalFrame>
			<ModalFrame
				visible={addMethodVisible}
				handleOk={handleOk}
				handleCancel={handleCancel}
				destroyOnClose={true}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						key='back'
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Save'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='submit'
						key='submit'
						form='submitForm'
						loading={
							depositAddMutation.isLoading ||
							withdrawalAddMutation.isLoading ||
							depositEditMutation.isLoading ||
							withdrawalEditMutation.isLoading
						}
					/>
				]}
				width='773px'
			>
				<PaymentMethod key={id}>
					<FormLayout
						form={form}
						action={action}
						setVisible={setAddMethodVisible}
						id={id}
						editMethod={editMethod}
						initialEditValues={initialEditValues}
						depositAddMutation={depositAddMutation}
						withdrawalAddMutation={withdrawalAddMutation}
						depositEditMutation={depositEditMutation}
						withdrawalEditMutation={withdrawalEditMutation}
						currencyType={currencyType}
					/>
				</PaymentMethod>
			</ModalFrame>
		</>
	);
};

export default Index;
