import { Form, Input, Select } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import Button from '../../../../components/button';
import { addStaff, editStaff } from '../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../utils/toastr';

const FormLayout = ({
	action,
	userId,
	setEditVisible,
	setVisible,
	showConfirmModal,
	initialEditValues,
	roles,
	setFormData,
	setOtpVisible,
	setRequiredAction
}) => {
	const { Option } = Select;

	const addUser = (values) => {
		if (action === 'add') {
			setFormData(values);
			setRequiredAction('add');
			setVisible(false);
			setOtpVisible(true);
		} else if (action === 'edit') {
			setFormData(values);
			setRequiredAction('edit');
			setEditVisible(false);
			setOtpVisible(true);
		}
	};

	return (
		<>
			<Form
				layout={'vertical'}
				onFinish={addUser}
				size='large'
				initialValues={{
					layout: 'vertical',
					size: 'large',
					full_name: action === 'edit' ? initialEditValues?.full_name : null,
					email: action === 'edit' ? initialEditValues?.email : null,
					phone: action === 'edit' ? initialEditValues?.phone : null,
					role_id: action === 'edit' ? initialEditValues?.role?.name : null
				}}
			>
				<Form.Item
					label='Full Name'
					name='full_name'
					rules={[
						{
							required: true,
							message: 'Please input full name!'
						}
					]}
				>
					<Input placeholder='input placeholder' type='text' />
				</Form.Item>
				<Form.Item
					label='Email Address'
					name='email'
					rules={[
						{
							required: true,
							message: 'Please input Email Address!'
						}
					]}
				>
					<Input placeholder='input placeholder' type='email' />
				</Form.Item>
				<Form.Item
					label='Phone Number'
					name='phone'
					rules={[
						{
							required: true,
							message: 'Please input Phone Number!'
						}
					]}
				>
					<Input placeholder='input placeholder' type='number' />
				</Form.Item>
				<Form.Item
					name='role_id'
					label='Role'
					rules={[
						{
							required: true,
							message: 'Please input Role!'
						}
					]}
				>
					<Select placeholder='Select role'>
						{roles.map(
							(role) =>
								role.name !== 'User' && (
									<Option value={role?.id} key={role?.id}>
										{role?.name}
									</Option>
								)
						)}
					</Select>
				</Form.Item>
				<Button
					Styles
					text={action === 'add' ? 'Send Invite' : 'Update'}
					bgColor={'#1A2CCE'}
					color={'#FFFFFF'}
					fullwidth
					size='md'
					type='submit'
					border={'0.7px solid #BFBFBF'}
					boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
					borderRadius={'3px'}
					// loading={addMutation.isLoading || editStaffMutation.isLoading}
				/>
			</Form>
		</>
	);
};

export { FormLayout };
