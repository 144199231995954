import React from "react";
import { StylesContainer } from "./styles";
import { Flex, Frame } from "../../../../components/box";
import email from "../../../../assets/images/email2.svg";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  const [searchParams] = useSearchParams();

  return (
    <StylesContainer margin={"auto"}>
      <Flex
        className="flex_container"
        width={"550px"}
        pad={"4em"}
        flexDir={"column"}
        bgColor={"#ffff"}
      >
        <Frame height={"90px"} width={"90px"} margin={"0px 0px 40px"}>
          <img alt="email-svg" src={email} />
        </Frame>
        <h3>Email reset activated</h3>
        <p>{searchParams?.get("query")}</p>
        <div className="link_box">Check Your Mail</div>
      </Flex>
    </StylesContainer>
  );
};

export default Index;
