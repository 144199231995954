import styled from 'styled-components';
import { Flex } from '../../../../components/box';

export const Styles = styled.div`
	.method {
		padding-bottom: 20px;
		h3 {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 23px;
			color: #1a1f36;
		}
		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-decoration-line: underline;
			color: #1a2cce;
			padding: 0;
		}

		.ant-input-group .ant-select .ant-select-selector {
			height: 46px !important;
			border-radius: 0 5px 5px 0;
			line-height: 40px !important;
			border-left-style: none;
		}
		.ant-select ant-select-single ant-select-show-arrow:hover {
			border: none;
		}

		.ant-input-group
			.ant-select
			.ant-select-selector
			.ant-select-selection-item {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			color: #000000;
			padding: 2px 45px 2px 0;
			line-height: 40px !important;
		}

		.ant-input-group .ant-input {
			line-height: 40px !important;
			height: 46px !important;
			width: 79px;
			/* border-left-radius: 10px; */
			border-radius: 5px 0 0 5px;
			background: white;
		}
	}
	.withdraw {
		padding-top: 20px;
	}
`;

export const Container1 = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	.fiter_container {
		gap: 30px;
		justify-content: space-between;
	}
	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 20px 10px;
		.fiter_container {
			width: 100%;
			justify-content: space-between;
			overflow: auto;
			flex-wrap: wrap;
			gap: 15px 5px;
		}
		.input_container {
			width: 100%;
		}
	}
`;

export const SelectContainer = styled(Flex)`
	justify-content: space-between;
	background-color: #fff;
	padding: 0 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
	border: 0.7px solid #dfdfdf;
	> span {
		font-size: 12px;
		color: #969696;
	}
`;

export const PaymentMethod = styled.div`
	.icon-box {
		position: relative;
		display: flex;
		.avatar {
			width: 70px;
			height: 55px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #d7e6ff;
			margin-right: 16px;
		}
		.avatar img {
			max-width: 99%;
			max-height: 99%;
			border-radius: 50%;
		}
		> input {
			position: absolute;
			border: 1px solid red;
			visibility: hidden;
		}
		h4 {
			text-decoration-line: underline;
			cursor: pointer;
		}
		p {
			margin-left: 5px;
		}
	}

	.upload {
		svg {
			margin-right: 24px;
		}

		h4 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
			margin-bottom: 0;
			padding-bottom: 1.5px;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #797979;
			margin-bottom: 0;
		}

		padding-bottom: 50px;
	}
	.outline {
		border-bottom: 1px solid #c4c4c4;
		height: 1px;
		width: 100%;
		margin-bottom: 10px;
	}

	h3 {
		text-align: left;
	}
	.ant-switch {
		width: 37.89px;
		height: 22.33px;
	}

	.ant-switch-checked {
		background: #69ce1a;
		border-radius: 24.2105px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		/* identical to box height */

		color: #166f00;
	}

	hr {
		border: 0.3px solid #c4c4c4;
		margin: 15px 0;
	}
	.payment-details .ant-form-item,
	.requirement-settings .ant-form-item {
		// width: 48%;
		width: 280px;
		.ant-input,
		.ant-select .ant-select-selector {
			height: 52px;
			background: #ffffff;
			border: 1px solid #d7d7d7;
			box-sizing: border-box;
			border-radius: 5px;
			line-height: 40px;
			padding-top: 5px;
		}
	}
	#desc {
		.ant-input {
			max-width: 100%;
			width: 638px !important;
		}
	}

	.currency-settings {
		background: #f9f9f9;

		border-radius: 1px;

		h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
		}
		.ant-input,
		.ant-select .ant-select-selector {
			width: 186px;
			height: 52px;
			background: #ffffff;
			border: 1px solid #d7d7d7;
			box-sizing: border-box;
			border-radius: 5px;
			line-height: 40px;
			padding-top: 5px;
		}
	}

	.requirement-settings {
		background: #f9f9f9;

		border-radius: 1px;
	}
`;
