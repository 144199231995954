import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CustomTable from '../table';
import { Container1, SearchInputStyles, Styles } from './styles';
// import Search from '../../../../components/search';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Container } from '../../../../components/box';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import {
	getTransactionsWithdrawers,
	refreshTransactionTime,
	requeueTransaction
} from '../../../../services/Transactions.service';
import { TransactionTableHeadWithdrawal } from '../../../../utils/constants';
import { formatType } from '../../../../utils/helpers';
import { ToastMessage, type } from '../../../../utils/toastr';
import {
	ApproveTransactionSummary,
	QRScreen
} from '../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
const Index = () => {
	const queryClient = useQueryClient();

	const [id, setId] = React.useState(null);
	const [status, setStatus] = React.useState('');
	const [qrCode, setQrCode] = React.useState(false);
	const [visible, setVisible] = React.useState(false);

	const [value, setValue] = React.useState('All');
	const [currency, setCurrency] = React.useState('All');
	const [statusValue, setStatusValue] = React.useState('All');
	const [selectedDateTo, setSelectedDateTo] = React.useState('All');
	const [selectedDateFrom, setSelectedDateFrom] = React.useState('All');
	const [input, setInput] = React.useState('');
	const [filter, setFilter] = React.useState({});
	const [clicked, setClicked] = React.useState('false');
	const [search, setSearch] = React.useState('');
	const [selectedIndex, setSelectedIndex] = useState([]);

	const result = useQuery(
		'getTransactionsWithdrawers',
		async () => await getTransactionsWithdrawers(search, filter),
		{
			refetchOnWindowFocus: false
			// enabled: Boolean(filter)
		}
	);

	const mutation = useMutation(
		async (transId) => {
			await refreshTransactionTime(transId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getTransactionsWithdrawers');
				queryClient.invalidateQueries('getTransactionsSwap');
				ToastMessage(type.SUCCESS, 'settlement time has been added');
				// setTimeout(() => {
				// 	setSettlementVisible(false);
				// }, 1000);
				// form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const RequeueMutation = useMutation(
		async (data) => {
			await requeueTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getTransactionsWithdrawers');
				// queryClient.invalidateQueries('getPendingApprovalsCrypto');
				// queryClient.invalidateQueries('getApprovalSummaryPending');
				// queryClient.invalidateQueries('getApprovalSummaryRejected');
				// queryClient.invalidateQueries('getApprovalSummaryApproved');
				queryClient.invalidateQueries('getTransactionsDeposit');
				ToastMessage(type.SUCCESS, 'Transaction re-queued successfully');
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const showModal = (e, status) => {
		setVisible(true);
		setId(e);
		setStatus(status);
	};

	const handleRequeue = (id) => {
		RequeueMutation.mutate(id);
	};

	const handleRefreshTime = (e) => {
		mutation.mutate(e);

		console.log(e);
	};

	const handleCancelqr = () => {
		setVisible(false);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		console.log('value:', value);
		// setInput(value);
		// setFilter({ ...filter, ref: value });
		setSearch(value);
	};

	const onChange = (e) => {
		const { value } = e.target;
		const splitDate = value.split(' - ');
		setValue(value);
		setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
	};

	const onChangeStatus = (e) => {
		const { value } = e.target;
		setStatusValue(value);
		setFilter({ ...filter, status: value });
	};

	const handleCurrencyChange = (e) => {
		const { value } = e.target;
		setCurrency(value);
		setFilter({ ...filter, currency: value });
	};

	const handleSelectFromChange = (e, dateString) => {
		setSelectedDateFrom(formatType(dateString));
		setFilter({ ...filter, from: formatType(dateString) });
	};

	const handleSelectToChange = (e, dateString) => {
		setSelectedDateTo(formatType(dateString));
		setFilter({ ...filter, to: formatType(dateString) });
	};

	const handleSubmit = () => {
		if (filter.from === 'All' || undefined) {
			delete filter.from;
		}
		if (filter.to === 'All' || undefined) {
			delete filter.to;
		}
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.status === 'All') {
			delete filter.status;
		}
		setClicked('true');

		result.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => result.refetch());
	};

	// if (result.isLoading) {
	// 	return <Loader />;
	// }

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}

	// console.log(result?.data?.data?.data?.data);

	return (
		<>
			<Styles>
				<Container1>
					<Container className='input_container' width={'20%'}>
						<SearchInputStyles>
							<Input
								// size='large'
								placeholder='Search reference no.'
								bordered={false}
								style={{
									border: '0.7px solid #dfdfdf',
									boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
									height: '45px',
									background: 'transparent'
								}}
								onChange={handleSearch}
								onPressEnter={() => result?.refetch(search)}
								prefix={
									<SearchOutlined
										style={{
											padding: 0
										}}
										onClick={() => result?.refetch(search)}
									/>
								}
							/>
						</SearchInputStyles>
					</Container>
					<Filters
						source='transaction'
						currency={currency}
						setCurrency={setCurrency}
						statusValue={statusValue}
						value={value}
						handleSelectFromChange={handleSelectFromChange}
						handleSelectToChange={handleSelectToChange}
						onChange={onChange}
						onChangeStatus={onChangeStatus}
						selectedDateFrom={selectedDateFrom}
						selectedDateTo={selectedDateTo}
						setFilter
						handleCurrencyChange={handleCurrencyChange}
						refetch={handleSubmit}
						loading={clicked === 'true' && result.isRefetching}
					/>
				</Container1>
				{result?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={TransactionTableHeadWithdrawal}
						tableBody={result?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={10}
						paginator
						widthdrawnTo
						meta={result?.data?.data?.data?.meta}
						handleAddSettlementTime={handleRefreshTime}
						handlePageChange={handlePageChange}
						handleApprove={showModal}
						handleReject={showModal}
						handleRequeue={handleRequeue}
						selectedIndex={selectedIndex}
						setSelectedIndex={setSelectedIndex}
					/>
				)}
			</Styles>
			<ModalFrame visible={visible} handleCancel={handleCancel} footer={null}>
				<ApproveTransactionSummary
					id={id}
					handleQrScreen={handleqrCode}
					swapAction={status}
					setVisible={setVisible}
				/>
				<ModalFrame
					visible={qrCode}
					handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame>
		</>
	);
};

export default Index;
