import React, { useState } from 'react';
import { CustomTableMain, Td, Paginator, CustomTd } from './styles';
import { generateID } from '../../../../../../utils/generateID';
import PropTypes from 'prop-types';
import { OverFlowScrollBar } from '../../../../../../components/overflowScroll/styles';
import Pagination from '../../../../../../components/paginator';
import { DisableIcon, DefaultCurrencyImage } from '../../../../../../assets';
import { Frame } from '../../../../../../components/box';
import Button from '../../../../../../components/button';
import { EmptyState } from '../../../../../../components/emptyState';

const CustomTable = ({
	tableBody,
	tableHead,
	theadColor,
	theadBkColor,
	tbodyColor,
	rowHovColor,
	bottomGap,
	paginator,
	pageSize,
	firstLast,
	prevNext,
	type,
	showDelete,
	showEdit,
	showDetails,
	handleRegenerate
}) => {
	const [pageOfItems, setPageOfItems] = useState([]);

	const onChangePage = (items) => {
		setPageOfItems(items);
	};

	const returnTableRow = (data) => {
		// console.log(data);
		return (
			<tr key={data.id}>
				<Td theadColor={theadColor} tbodyColor={tbodyColor}>
					<div>{data?.depositMethod?.currencies?.join(', ')}</div>
				</Td>
				<Td>
					<div>
						<Frame height={'30px'} width={'30px'}>
							{data?.depositMethod.icon ? (
								<img src={data?.depositMethod.icon} alt='' />
							) : (
								<DefaultCurrencyImage />
							)}
						</Frame>
						<span>{data?.depositMethod.provider}</span>
					</div>
				</Td>
				<Td style={{ float: 'right' }}>
					<div>
						<Button
							Styles
							text={'View details'}
							color='#1A2CCE'
							border-radius='3px'
							style={{
								textDecoration: 'underline'
							}}
							size='md'
							type='button'
							onClick={() => showDetails(data.id)}
						/>
						{type === 'manual' ? (
							<>
								<Button
									Styles
									text={'Edit'}
									color='#1A2CCE'
									border-radius='3px'
									style={{
										textDecoration: 'underline'
									}}
									size='md'
									type='button'
									onClick={() => showEdit(data?.id)}
								/>
								<Button
									Styles
									text={'Delete'}
									color='#CE1A1A'
									border-radius='3px'
									style={{
										textDecoration: 'underline'
									}}
									size='md'
									type='button'
									onClick={() => showDelete(data?.id)}
								/>
							</>
						) : (
							<>
								<Button
									Styles
									text={'Regenerate'}
									color='#1A2CCE'
									border-radius='3px'
									style={{
										textDecoration: 'underline'
									}}
									size='md'
									type='button'
									onClick={() => handleRegenerate(data?.id)}
								/>
							</>
						)}
					</div>
				</Td>
			</tr>
		);
	};
	return (
		<>
			{tableBody?.length !== 0 ? (
				<CustomTableMain
					theadColor={theadColor}
					theadBkColor={theadBkColor}
					bottomGap={bottomGap}
					tbodyColor={tbodyColor}
					rowHovColor={rowHovColor}
					paginator={paginator}
				>
					<OverFlowScrollBar className='container'>
						<table>
							<thead>
								<tr>
									{tableHead?.map((head, i) => (
										<th key={generateID(11)}>{head.toUpperCase()}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{paginator
									? pageOfItems?.map((data, idx) => returnTableRow(data))
									: tableBody?.map((data, idx) => returnTableRow(data))}
							</tbody>
						</table>
					</OverFlowScrollBar>

					<Paginator className='paginator' paginator={paginator}>
						<Pagination
							items={tableBody}
							pageSize={pageSize || 5}
							prevNext={prevNext || null}
							onChangePage={onChangePage}
							firstLast={firstLast || null}
						/>
					</Paginator>
				</CustomTableMain>
			) : (
				<EmptyState />
			)}
		</>
	);
};

CustomTable.propTypes = {
	tableHead: PropTypes.array.isRequired,
	theadColor: PropTypes.string,
	theadBkColor: PropTypes.string,
	rowClick: PropTypes.func,
	tbodyColor: PropTypes.string,
	rowHovColor: PropTypes.string,
	bottomGap: PropTypes.string,
	pageSize: PropTypes.number,
	firstLast: PropTypes.any,
	paginator: PropTypes.any,
	prevNext: PropTypes.any
};

export default CustomTable;
