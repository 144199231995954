import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from '../context';
import Auth from './auth';
import Dashboard from './dashboard';
import { RequireAuth } from './privateRoute';

const Index = () => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: { refetchOnWindowFocus: false, refetchOnMount: true }
		}
	});

	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<BrowserRouter>
					<Routes>
						<Route path={'/*'} element={<Auth />} />
						<Route
							path={'/dashboard/*'}
							element={
								<RequireAuth>
									<Dashboard />
								</RequireAuth>
							}
						/>
					</Routes>
				</BrowserRouter>
			</AuthProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};

export default Index;
