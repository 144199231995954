import { ButtonStyle, Loader } from "./styles";
import { LoaderIcon } from "../../assets";

const Button = (props) => {
  return (
    <ButtonStyle type={props.type} disabled={props.loading} {...props}>
      {props.loading ? (
        <Loader>
          <LoaderIcon color={"#1A2CCE"} width="30px" height="30px" />
        </Loader>
      ) : null}
      {props.text}
    </ButtonStyle>
  );
};
export default Button;
