import React, { useEffect } from 'react';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { CrossFeesTableHead } from '../../../../utils/constants';
import { Container1, FormContainer, Styles } from './styles';
import CustomTable from './table';
// import { ToastMessage, type } from '../../../../utils/toastr';
import { Input, Select } from 'antd';
import { useState } from 'react';
import {
	useMutation,
	useQuery,
	useQueryClient
	// useQueryClient
} from 'react-query';
import Filters from '../../../../components/filters/Filters';
import { Loader } from '../../../../components/loader';
import { useAuthState } from '../../../../context';
import { getMerchantSearch } from '../../../../services/Others.service';
import {
	addCrossFee,
	deleteCrossFee,
	editCrossFee,
	getCrossFee,
	// deleteStaff,
	getRoles,
	getStaffs,
	getpaymentMethodDeposit,
	getpaymentMethodWithdrawal
} from '../../../../services/Settings.service';
import { ToastMessage, type } from '../../../../utils/toastr';
import { FormLayout } from './form';

const { Option } = Select;
const Index = () => {
	const { currencies } = useAuthState();

	const queryClient = useQueryClient();
	const crossFee = useQuery('crossfees', async () => await getCrossFee());
	// const lsitOfMerchants = useQuery('list of merchants', async () => await getCrossFee());
	const result = useQuery(
		'getStaffs',
		async () => await getStaffs(search, filter)
	);
	const roles = useQuery('getRoles', async () => await getRoles());
	const [action, setAction] = React.useState('add');
	const [feeId, setFeeId] = React.useState('');
	const [visible, setVisible] = React.useState(false);
	const [otpVisible, setOtpVisible] = React.useState(false);
	const [currency, setCurrency] = React.useState('All');

	const [editVisible, setEditVisible] = React.useState(false);
	const [resetVisible, setResetVisible] = React.useState(false);
	const [deleteVisible, setDeleteVisible] = React.useState(false);
	const [suspendVisible, setSuspendVisible] = React.useState(false);
	const [confirmVisible, setConfirmVisible] = React.useState(false);
	const [initialEditValues, setInitialEditValues] = React.useState({});
	const [requiredAction, setRequiredAction] = React.useState('');
	const [formData, setFormData] = React.useState({});
	const [suspendAction, setSuspendAction] = useState('');
	const [isActive, setIsActive] = useState();
	const [filter, setFilter] = React.useState({
		page: 1
	});
	const [search, setSearch] = React.useState('');
	const [deposit, setDeposit] = useState([]);
	const [withdraw, setWithdraw] = useState([]);
	const [methods, setMethods] = useState([]);
	const [optionValue, setOptionValue] = useState([]);

	const deleteCrossFeeMutation = useMutation(
		async (data) => {
			await deleteCrossFee(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('crossfees');
				ToastMessage(type.SUCCESS, 'cross fee successfully deleted');
				// setDeleteVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const crossFeesMutation = useMutation(
		async (data) => {
			await addCrossFee(data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully created');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const editCrossFeesMutation = useMutation(
		async (data) => {
			await editCrossFee(feeId, data);
		},
		{
			onSuccess: (c, res, d) => {
				queryClient.invalidateQueries('crossfees');
				setTimeout(() => setEditVisible(false), 500);
				ToastMessage(type.SUCCESS, 'cross fee successfully updated');
			},
			onError: (error) => {
				// console.log()
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	useQuery(
		'getpaymentMethodDeposit',
		async () => await getpaymentMethodDeposit(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Deposit'));
				setDeposit(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);

	useQuery(
		'getpaymentMethodWithdrawal',
		async () => await getpaymentMethodWithdrawal(),
		{
			onSuccess: (res) => {
				res.data.data.forEach((e) => (e.method = 'Withdraw'));
				setWithdraw(res.data.data);
			},
			refetchOnWindowFocus: false
		}
	);
	const merchant = useQuery(
		'getMerchant',
		async () => await getMerchantSearch()
	);

	useEffect(() => {
		setOptionValue(
			merchant?.data?.data?.data?.data?.map((item) => {
				return {
					id: item?.id,
					value: item?.business_name,
					label: item?.business_name
				};
			})
		);
		// merchant?.refetch(search);
	}, [merchant?.data?.data?.data?.data, setOptionValue]);
	useEffect(() => {
		setMethods([...deposit, ...withdraw]);
	}, [deposit, withdraw]);

	const showModal = () => {
		setVisible(true);
	};

	const handleOk = () => {
		if (resetVisible === true) {
			setRequiredAction('reset');
			setResetVisible(false);
			setOtpVisible(true);
		} else if (suspendVisible === true) {
			setRequiredAction(suspendAction);
			setSuspendVisible(false);
			setOtpVisible(true);
		} else if (deleteVisible === true) {
			setRequiredAction('password');
			setDeleteVisible(false);
			setOtpVisible(true);
		}
		setAction('add');
	};

	const handleCancel = () => {
		setVisible(false);
		setConfirmVisible(false);
		setEditVisible(false);
		setSuspendVisible(false);
		setResetVisible(false);
		setDeleteVisible(false);
		setOtpVisible(false);
		setAction('add');
	};

	const handleEdit = (id) => {
		setAction('edit');
		setFeeId(id);

		crossFee?.data?.data?.data?.find(
			(item) => item.id === id && setInitialEditValues(item)
		);

		setTimeout(() => setEditVisible(true), 500);
	};

	const handleSuspend = (id, userStatus) => {
		setSuspendVisible(true);
		if (userStatus === false) {
			setIsActive(userStatus);
			setSuspendAction('unsuspend');
		} else if (userStatus === true) {
			setIsActive(userStatus);
			setSuspendAction('suspend');
		}

		// setUserId(id);
	};

	const handleReset2fa = (id) => {
		setResetVisible(true);
		// setUserId(id);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		// setTimeout(() => result.refetch());
	};
	const handleDelete = (id) => {
		console.log(id);
		deleteCrossFeeMutation.mutate(id);
		// setDeleteVisible(true);
		setFeeId(id);
	};

	// if (result.isLoading && roles.isLoading) {
	// 	return <Loader />;
	// }

	const handleSubmit = (values) => {
		console.log(
			values?.transaction_type === 'withdraw' ? 'withdrawal' : 'deposit'
		);
		const status = action === 'edit' && values?.status;
		const transactionType =
			values?.transaction_type === 'withdraw' ? 'withdrawal' : 'deposit';
		const optionalTransactionType =
			values?.optional_transaction_type === 'withdraw'
				? 'withdrawal'
				: 'deposit';
		// const data = {
		// 	status,
		// 	merchantId: values?.merchant,
		// 	to_currency: values?.to_currency,
		// 	from_currency: values?.from_currency,
		// 	transaction_type:
		// 		values?.transaction_type === 'withdraw' ? 'withdrawal' : 'deposit',
		// 	payment_methods: [values?.payment_method?.toString()],

		// 	fees: [
		// 		{
		// 			fee_type: values?.fee_type,
		// 			fee: values?.fee
		// 		}
		// 	],
		// 	fee_currency: values?.fee_currency,
		// 	optional_transaction_type:
		// 		values?.optional_transaction_type === undefined
		// 			? values?.transaction_type === 'withdraw'
		// 				? 'withdrawal'
		// 				: 'deposit'
		// 			: values?.optional_transaction_type === 'withdraw'
		// 			? 'withdrawal'
		// 			: 'deposit',
		// 	optional_payment_methods: [values?.optional_payment_method?.toString()],

		// 	optional_fees: [
		// 		{
		// 			fee_type:
		// 				values?.optional?.fee_type === undefined
		// 					? values?.fee_type
		// 					: values?.optional?.fee_type,
		// 			fee:
		// 				values?.optional?.fee_amount === undefined
		// 					? values?.fee
		// 					: values?.optional?.fee_amount
		// 		}
		// 	],
		// 	optional_fee_currency:
		// 		values?.optional_fee_currency === undefined
		// 			? values?.fee_currency
		// 			: values?.optional_fee_currency
		// };
		if (action === 'add') {
			console.log(optionalTransactionType, transactionType, values);
			// crossFeesMutation.mutate(data);
			// setFormData(values);
			// setRequiredAction('add');
			// setVisible(false);
			// setOtpVisible(true);
		} else if (action === 'edit') {
			console.log(action, values);
			// editCrossFeesMutation.mutate(data);
			// setFormData(values);
			// setRequiredAction('edit');
			// setEditVisible(false);
			// setOtpVisible(true);
		}
	};

	if (crossFee.status === 'error') {
		return <span>Error: {crossFee.error.message}</span>;
	}

	console.log(crossFee, initialEditValues);
	return (
		<>
			<Styles>
				<Container1>
					<Flex
						justifyContent='end'
						flex-direction='row'
						style={{
							// marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Button
							Styles
							text={'New Fee'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='submit'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							borderRadius={'3px'}
							onClick={showModal}
						/>
					</Flex>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='center'
						style={{
							// marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Flex
							justifyContent='start'
							gap={40}
							// width='auto'
						>
							<Input
								placeholder='Search'
								size='large'
								style={{
									width: '271px'
								}}
							/>
							{/* <Flex width='25%'>
								<Input.Group
									compact
									style={{
										width: '271px'
									}}
								>
									<Input
										placeholder='Sort By'
										disabled
										style={{
											width: '30%'
										}}
									/>
									<Select
										defaultValue='All'
										style={{
											height: '46px !important',
											minWidth: '60%',
											maxWidth: '60%'
										}}
									>
										<Option
											style={{
												height: '46px !important'
											}}
											value=''
										>
											All
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='transaction_type'
										>
											Transaction Type
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='fee_currency'
										>
											Fee Currency
										</Option>
									</Select>
								</Input.Group>
							</Flex> */}
						</Flex>
						<Filters
							source='crossFee'
							currency={currency}
							setCurrency={setCurrency}
							// statusValue={statusValue}
							// value={value}
							// handleSelectFromChange={handleSelectFromChange}
							// handleSelectToChange={handleSelectToChange}
							// onChange={onChange}
							// onChangeStatus={onChangeStatus}
							// selectedDateFrom={selectedDateFrom}
							// selectedDateTo={selectedDateTo}
							setFilter
							// handleCurrencyChange={handleCurrencyChange}
							// refetch={handleSubmit}
							// loading={clicked === 'true' && result.isRefetching}
						/>
					</Flex>
					{/* <Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='center'
						style={{
							marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Flex justifyContent='start' gap={40}>
							<Input
								placeholder='Search'
								size='large'
								style={{
									width: '271px'
								}}
							/>
							<Flex width='25%'>
								<Input.Group
									compact
									style={{
										width: '271px'
									}}
								>
									<Input
										placeholder='Sort By'
										disabled
										style={{
											width: '30%'
										}}
									/>
									<Select
										defaultValue='All'
										style={{
											height: '46px !important',
											minWidth: '60%',
											maxWidth: '60%'
										}}
									>
										<Option
											style={{
												height: '46px !important'
											}}
											value=''
										>
											All
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='transaction_type'
										>
											Transaction Type
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='fee_currency'
										>
											Fee Currency
										</Option>
									</Select>
								</Input.Group>
							</Flex>
						</Flex>
						<Button
							Styles
							text={'New Fee'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='submit'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							borderRadius={'3px'}
							onClick={showModal}
						/>
					</Flex> */}
				</Container1>
				{/* <Container1>
					<Container className='input_container' width={'26%'}>
						<Search placeholder={'Search reference no.'} />
						<Search
							placeholder={'Search by name.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>
				</Container1>
				<Container className='input_container' width={'20%'}>
					</Container> */}

				{crossFee?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={CrossFeesTableHead}
						tableBody={crossFee?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={5}
						handleEdit={handleEdit}
						handleSuspend={handleSuspend}
						handleReset2fa={handleReset2fa}
						handlePageChange={handlePageChange}
						paginator
						meta={crossFee?.data?.data?.data?.meta}
						handleDelete={handleDelete}

						// merchantNames={optionValue}
						// handleChangePassword={handleDelete}
					/>
				)}
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : editVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
						key='back'
					/>,
					<Button
						Styles
						text={action === 'add' ? 'Confirm' : 'Update'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						// fullwidth
						size='md'
						type='submit'
						border={'0.7px solid #BFBFBF'}
						boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
						borderRadius={'3px'}
						id='cross-fee'
						key='submit'
						form='cross-fee'
						loading={
							crossFeesMutation.isLoading || editCrossFeesMutation.isLoading
						}
					/>
				]}
				destroyOnClose={true}
				width={'750px'}
			>
				<FormContainer>
					<h3 style={{ textAlign: 'left' }}>
						{action === 'add' ? 'Add New Cross Fees' : 'Edit Cross Fees'}
					</h3>
					<FormLayout
						action={action}
						initialEditValues={initialEditValues}
						paymentMethod={methods}
						currencies={currencies}
						handleSubmit={handleSubmit}
						optionValue={optionValue}
					/>
				</FormContainer>
			</ModalFrame>
			{/* <ModalFrame
				visible={confirmVisible}
				handleOk={(e) => setConfirmVisible(false)}
				handleCancel={handleCancel}
				footer={null}
			>
				<FormContainer>
					<ConfirmMessage />
				</FormContainer>
			</ModalFrame>
			<ModalFrame
				visible={
					suspendVisible === true
						? suspendVisible
						: resetVisible === true
						? resetVisible
						: deleteVisible
				}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleOk}
					/>
				]}
			>
				{suspendVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>
							{isActive === true ? 'Suspend user' : 'Unsuspend User'}
						</h3>
						<p>
							{`Are you sure you want to ${
								isActive === true ? 'suspend' : 'unsuspend'
							} the user`}
						</p>
					</>
				) : resetVisible ? (
					<>
						<h3 style={{ textAlign: 'center' }}>Reset User 2FA</h3>
						<p>Are you sure you want to reset user 2fa</p>
					</>
				) : (
					<>
						<h3 style={{ textAlign: 'center' }}>Change User Password</h3>
						<p>Are you sure you want to change this user's password </p>
					</>
				)}
			</ModalFrame>
			<ModalFrame
				visible={otpVisible}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					id={userId}
					// handleQrScreen={handleqrCode}
					// swapAction={status}
					status={requiredAction}
					setVisible={setOtpVisible}
					addEditData={formData}
					showConfirmModal={showConfirmModal}
				/>
				 <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame> */}
		</>
	);
};

export default Index;

// const ConfirmMessage = () => (
// 	<>
// 		<Container>
// 			<Flex flexDir='column' justifyContent='center' alignItems='center'>
// 				<h3>Invite sent</h3>
// 				<p>
// 					Your new team member will receive
// 					<br /> your invite and a 2FA code generated
// 					<br /> for them
// 				</p>
// 			</Flex>
// 		</Container>
// 	</>
// );

const dummyData = [
	{
		merchant: 'Ovex subsidiaries',
		type: 'Deposit',
		from_currency: 'NGN',
		to_currency: 'USD',
		amount: 'USD 10',
		payment_method: 'Binance Pay',
		status: true
	},
	{
		merchant: 'Ovex subsidiaries',
		type: 'Withdrawal',
		from_currency: 'NGN',
		to_currency: 'USD',
		amount: 'USD 10',
		payment_method: 'Binance Pay',
		status: true
	},
	{
		merchant: 'Ovex subsidiaries',
		type: 'Deposit',
		from_currency: 'NGN',
		to_currency: 'USD',
		amount: 'USD 10',
		payment_method: 'Binance Pay',
		status: true
	},
	{
		merchant: 'Ovex subsidiaries',
		type: 'Withdrawal',
		from_currency: 'NGN',
		to_currency: 'USD',
		amount: 'USD 10',
		payment_method: 'Binance Pay',
		status: true
	},
	{
		merchant: 'Ovex subsidiaries',
		type: 'Deposit',
		from_currency: 'NGN',
		to_currency: 'USD',
		amount: 'USD 10',
		payment_method: 'Binance Pay',
		status: true
	}
];
