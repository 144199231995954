// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_loader__Iv4Mh {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n}\n.Loader_full__Gpwi\\+ {\n\theight: calc(100vh - 70px);\n}\n.Loader_loader__Iv4Mh p {\n\tfont-size: 18px;\n\tfont-weight: 800;\n\tcolor: rgb(63, 58, 58);\n}\n", "",{"version":3,"sources":["webpack://./src/components/loader/Loader.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;AACxB;AACA;CACC,0BAA0B;AAC3B;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,sBAAsB;AACvB","sourcesContent":[".loader {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n}\n.full {\n\theight: calc(100vh - 70px);\n}\n.loader p {\n\tfont-size: 18px;\n\tfont-weight: 800;\n\tcolor: rgb(63, 58, 58);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Loader_loader__Iv4Mh",
	"full": "Loader_full__Gpwi+"
};
export default ___CSS_LOADER_EXPORT___;
