import { Form, Input, Select, Upload } from "antd";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Container, Flex } from "../../../../../components/box";
import Button from "../../../../../components/button";
import {
  addCurrency,
  editCurrency,
} from "../../../../../services/Settings.service";
import { ToastMessage, type } from "../../../../../utils/toastr";
const { Option } = Select;

const FormLayout = ({
  setVisible,
  initialEditValues,
  action,
  id,
  depositMethod,
  withdrawMethod,
}) => {
  const [imgUpload, setImgUpload] = useState(null);
  const ref = React.useRef(null);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data) => {
      await addCurrency(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllCurrencies"]);
        ToastMessage(type.SUCCESS, "new currency added successfully");
        setTimeout(() => setVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );
  const editMutation = useMutation(
    async (data) => {
      await editCurrency(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllCurrencies"]);
        ToastMessage(type.SUCCESS, "currency edited successfully");
        setTimeout(() => setVisible(false), 1000);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImgUpload(file);
    }
  };
  const handleOpenFileUpload = (e) => {
    e.preventDefault();
    ref.current.click();
  };

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("file", imgUpload);
    formData.append("code", values.code);
    formData.append("code_position", values.code_position);
    formData.append("name", values.name);
    formData.append("symbol", values.symbol);
    formData.append("symbol_position", values.symbol_position);
    formData.append("min_deposit", values.min_deposit);
    formData.append("min_withdrawal", values.min_withdrawal);
    formData.append("min_swap", values.min_swap);
    formData.append("max_deposit", values.max_deposit);
    formData.append("max_withdrawal", values.max_withdrawal);
    formData.append("max_swap", values.max_swap);
    formData.append("min_approval", values.min_approval);
    formData.append("type", values.type);
    formData.append("default_withdrawal", values.default_withdrawal);
    formData.append("default_deposit", values.default_deposit);
    if (action === "add") {
      mutation.mutate(formData);
    }
    if (action === "edit") {
      editMutation.mutate(formData);
    }
  };
  console.log(initialEditValues, "ini");

  return (
    <>
      <h3>{action === "add" ? "Add currency" : "Edit currency"}</h3>
      <Flex
        width="392px"
        className="upload"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItem="center"
          className="icon-box"
        >
          {imgUpload ? (
            <div className="avatar">
              <img alt="" src={URL.createObjectURL(imgUpload)} />
            </div>
          ) : action === "add" ? (
            <Upload />
          ) : initialEditValues?.icon !== "" || null ? (
            <div className="avatar">
              <img alt="" src={initialEditValues?.icon} />
            </div>
          ) : (
            <Upload />
          )}

          <input
            text={"Change profile picture"}
            color={"#1A2CCE"}
            size="md"
            type="file"
            ref={ref}
            onChange={handleChange}
          />

          <Flex flexDir="column" alignItems="flex-start">
            <h4 onClick={handleOpenFileUpload} href="#">
              Upload currency logo
            </h4>
            <p>Logo must be 12px by 12px (Max 1mb</p>
          </Flex>
        </Flex>
      </Flex>
      <Form
        layout={"vertical"}
        initialValues={{
          layout: "vertical",
          size: "large",
          file: action === "edit" ? initialEditValues?.file : null,
          code: action === "edit" ? initialEditValues?.code : null,
          code_position:
            action === "edit" ? initialEditValues?.code_position : null,
          name: action === "edit" ? initialEditValues?.name : null,
          symbol: action === "edit" ? initialEditValues?.symbol : null,
          symbol_position:
            action === "edit" ? initialEditValues?.symbol_position : null,
          min_deposit:
            action === "edit" ? initialEditValues?.min_deposit : null,
          min_withdrawal:
            action === "edit" ? initialEditValues?.min_withdrawal : null,
          min_swap: action === "edit" ? initialEditValues?.min_swap : null,
          max_deposit:
            action === "edit" ? initialEditValues?.max_deposit : null,
          max_swap: action === "edit" ? initialEditValues?.max_swap : null,
          min_approval:
            action === "edit" ? initialEditValues?.min_approval : null,
          max_withdrawal:
            action === "edit" ? initialEditValues?.max_withdrawal : null,
          type: action === "edit" ? initialEditValues?.type : null,
          default_withdrawal:
            action === "edit" ? initialEditValues?.default_withdrawal : null,
          default_deposit:
            action === "edit" ? initialEditValues?.default_deposit : null,
        }}
        size="large"
        onFinish={handleSubmit}
      >
        <Container className="currency">
          <Flex justifyContent="space-between">
            <Form.Item
              label="Currency name"
              name="name"
              rules={[{ required: true, message: "Name key is required" }]}
            >
              <Input placeholder="Currency name" type="text" />
            </Form.Item>
            <Form.Item
              name="type"
              label="Currency type"
              rules={[{ required: true, message: "Currency type is required" }]}
            >
              <Select placeholder="Select currency type">
                <Option value="FIAT">FIAT</Option>
                <Option value="CRYPTO">CRYPTO</Option>
              </Select>
            </Form.Item>
          </Flex>
        </Container>

        <Container className="deposit">
          <Flex justifyContent="space-between">
            <Form.Item
              label="Currency symbol"
              name="symbol"
              rules={[
                { required: true, message: "Currency symbol is required" },
              ]}
            >
              <Input placeholder="input currency position" type="text" />
            </Form.Item>
            <Form.Item
              name="symbol_position"
              label="Currency symbol position"
              rules={[
                {
                  required: true,
                  message: "Currency symbol position is required",
                },
              ]}
            >
              <Select placeholder="Select currency position">
                <Option value="left">LEFT</Option>
                <Option value="right">RIGHT</Option>
              </Select>
            </Form.Item>
          </Flex>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Currency code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "code is required",
                },
              ]}
            >
              <Input placeholder="input currency code" type="text" />
            </Form.Item>
            <Form.Item
              name="code_position"
              label="Currency code position"
              rules={[
                {
                  required: true,
                  message: "Currency code postion is required",
                },
              ]}
            >
              <Select placeholder="Select currency code position">
                <Option value="left">LEFT</Option>
                <Option value="right">RIGHT</Option>
              </Select>
            </Form.Item>
          </Flex>
        </Container>

        <Container className="deposit">
          <h3>Deposit settings</h3>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Minimum amount"
              name="min_deposit"
              rules={[
                {
                  required: true,
                  message: "Minimum amount is required",
                },
              ]}
            >
              <Input placeholder="input minimum amount" type="number" />
            </Form.Item>
            <Form.Item
              label="Maximum amount"
              name="max_deposit"
              rules={[
                {
                  required: true,
                  message: "Maximum amount is required",
                },
              ]}
            >
              <Input placeholder="input maximum amount" type="number" />
            </Form.Item>
          </Flex>
        </Container>
        <Container className="withdraw">
          <h3>Withdrawal settings</h3>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Minimum amount"
              name="min_withdrawal"
              rules={[
                {
                  required: true,
                  message: "Minimum amount is required",
                },
              ]}
            >
              <Input placeholder="input minimum amount" type="text" />
            </Form.Item>
            <Form.Item
              label="Maximum amount"
              name="max_withdrawal"
              rules={[
                {
                  required: true,
                  message: "Maximum amount is required",
                },
              ]}
            >
              <Input placeholder="input maximum amount" type="number" />
            </Form.Item>
          </Flex>
        </Container>
        <Container className="swap">
          <h3>Swap settings</h3>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Minimum amount"
              name="min_swap"
              rules={[
                {
                  required: true,
                  message: "Minimum amount is required",
                },
              ]}
            >
              <Input placeholder="input minimum amount" type="number" />
            </Form.Item>
            <Form.Item
              label="Maximum amount"
              name="max_swap"
              rules={[
                {
                  required: true,
                  message: "Maximum amount is required",
                },
              ]}
            >
              <Input placeholder="input maximum amount" type="number" />
            </Form.Item>
          </Flex>
        </Container>
        <Container className="swap">
          <h3>Default Withdrawal And Deposit Method</h3>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Defauilt withdrawal method"
              name="default_withdrawal"
              rules={[
                {
                  required: true,
                  message: "Default withdrawal method is required",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                title="currency_pair"
                placeholder="select default withdrawal method"
              >
                {withdrawMethod?.map((item) => (
                  <Select.Option key={item.id} value={item.slug}>
                    {item.name}:{item?.provider}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Defauilt deposit method"
              name="default_deposit"
              rules={[
                {
                  required: true,
                  message: "Default deposit method is required",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="select default deposit method"
              >
                {depositMethod?.map((item) => (
                  <Select.Option key={item.id} value={item.slug}>
                    {item.name}:{item?.provider}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Container>
        <Container className="swap">
          <h3>Approval settings</h3>
          <Flex justifyContent="space-between">
            <Form.Item
              label="Minimum approval"
              name="min_approval"
              rules={[
                {
                  required: true,
                  message: "Minimum approval is required",
                },
              ]}
            >
              <Input placeholder="input minimum approval" type="number" />
            </Form.Item>
          </Flex>
        </Container>
        <Button
          Styles
          text={"Save Changes"}
          bgColor={"#1A2CCE"}
          color={"#FFFFFF"}
          fullwidth
          size="md"
          type="submit"
          border={"0.7px solid #BFBFBF"}
          boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
          borderRadius={"3px"}
          loading={mutation.isLoading || editMutation.isLoading}
          style={{ float: "right", marginTop: "20px", width: "392px" }}
        />
      </Form>
    </>
  );
};

export default FormLayout;
