import { Skeleton } from 'antd';
import React from 'react';
import { Flex, Grid } from '../../../../components/box';
import { Spacer } from '../../../../components/spacer/styles';
import InviteMerchant from '../../merchants/inviteMerchant/InviteMerchant';
import { Card, Styles } from './styles';

const Index = ({ active, verified, suspended, loading }) => {
	return (
		<Styles>
			<Flex
				className='header-container'
				// justifyContent={'flex-start'}
				justifyContent='space-between'
			>
				<h3>Merchants</h3>
				<InviteMerchant />
			</Flex>
			<Spacer height='10px' />
			<Grid
				justifyContent={'space-around'}
				gap='10px'
				gridCol='repeat(auto-fit, minmax(270px, 1fr))'
				bgColor='#fff'
				pad='30px 20px'
			>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Active merchants</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>
								{active?.result?.toLocaleString() -
									suspended?.result?.toLocaleString()}
							</span>
						)}
						<span></span>
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Verified merchant</span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{verified?.result?.toLocaleString()}</span>
						)}
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Suspended merchants </span>
					</Flex>
					<Flex className='viewall' alignItem={'flex-start'} flexDir={'column'}>
						{loading ? (
							<Skeleton paragraph={{ rows: 0 }} />
						) : (
							<span>{suspended?.result?.toLocaleString()}</span>
						)}
					</Flex>
				</Card>
			</Grid>
		</Styles>
	);
};

export default Index;
