import styled from 'styled-components';
import { Flex } from '../../../../../components/box';

export const Styles = styled.div`
	width: 100%;
	border: 1px solid #dfdfdf;
	border-radius: 8px;
	// background-color: #f3f6fb;
	// padding: 25px;
	/* border-radius: 5px; */
`;

export const Card = styled(Flex)`
	// padding: 10px;
	border-right: 1px solid rgba(223, 223, 223, 0.39);
	:last-child {
		border-right: none;
	}
	// box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	// align-items: flex-start;
	justify-content: flex-start;
	height: 70px;
	.container {
		span:first-child {
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.02em;
			color: #6f6f6f;
			padding-bottom: 10px;
		}
	}
	.viewall {
		padding: 0;
		span:first-child {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: #1a1f36;
		}
		span:last-child {
			/* padding-bottom: 20px; */
			> a {
				text-decoration: underline;
				font-size: 14px;
				font-weight: 400;
				color: #1a2cce;
			}
		}
		.ant-btn-icon-only {
			padding: 0 !important;
		}
		.ant-btn {
			height: 0;
		}
	}

	.cryptodetails {
		padding-top: 4px;
		span {
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.02em;
			color: #6f6f6f;
		}
		span:first-child {
			margin-right: 16px;
		}
	}

	.copyBtn {
		margin-left: 10px;
		cursor: pointer;
	}
`;
