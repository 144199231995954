import { login } from '../services/Auth.service';
import { ToastMessage, type } from '../utils/toastr';
import { ERROR, LOGIN_SUCCESS, LOGOUT, REQUEST_LOGIN } from './types';

export async function loginUser(dispatch, loginPayload) {
	try {
		dispatch({ type: REQUEST_LOGIN });
		const response = await login(loginPayload);

		if (response.data.data) {
			dispatch({ type: LOGIN_SUCCESS, payload: response?.data?.data });
			localStorage.setItem('access_token', response?.data?.data?.access_token);
			return response;
		}
		return;
	} catch (error) {
		await dispatch({
			type: ERROR,
			payload: error.response.data.message
		});
		await ToastMessage(type.ERROR, error.response.data.message);
	}
}

export async function logout(dispatch) {
	dispatch({ type: LOGOUT });
	localStorage.removeItem('access_token');
}
