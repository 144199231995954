import { Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '../../../../../../components/button';
import { Loader } from '../../../../../../components/loader';
import {
	getMerchantSettingsById,
	merchantRateRefresh,
	updateDynamicAccountLimit,
	updateWalletLimit
} from '../../../../../../services/Merchants.service';
import { ToastMessage, type } from '../../../../../../utils/toastr';
import { Styles } from './styles';
const Wallet = ({ merchantID }) => {
	const queryClient = useQueryClient();
	const [expireAt, setExpireAt] = useState(Number);
	const [fiatLimit, setFiatLimit] = useState(Number);
	const [fiatAmountLimit, setFiatAmountLimit] = useState(Number);
	const [cryptoAmountLimit, setCryptoAmountLimit] = useState(Number);
	const [cryptoLimit, setCryptoLimit] = useState(Number);
	const checkToken = localStorage.getItem('access_token');
	const settings = useQuery(
		['getMerchantWalletSetting', checkToken],
		async () => await getMerchantSettingsById(merchantID),
		{
			onError: (error) => <p> {error.response.data.message}</p>,
			enabled: !!checkToken,
			refetchOnMount: 'always',
			refetchOnWindowFocus: false
		}
	);

	const allSettings = useQuery(
		'getMerchantSettings',
		async () => await merchantRateRefresh(merchantID),
		{
			onError: (error) => <p> {error.response.data.message}</p>
		}
	);

	const updateWalletLimitMutation = useMutation(
		'updateWalletLimit',
		async (data) => await updateWalletLimit(merchantID, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantWalletSetting');
				setTimeout(() => {
					ToastMessage(type.SUCCESS, 'Wallet settings updated successfully');
				});
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const updateDynamicAccountLimitMutation = useMutation(
		'updateMerchantDALimit',
		async (data) => await updateDynamicAccountLimit(merchantID, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantSettings');
				setTimeout(() => {
					ToastMessage(
						type.SUCCESS,
						'Dynamic Accounts timer updated successfully'
					);
				}, 500);
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const handleDAChange = () => {
		updateDynamicAccountLimitMutation.mutate({
			dynamic_account_expire_min: expireAt
		});
	};

	const handleFiatChange = () => {
		updateWalletLimitMutation.mutate({
			fiat_account_limit: fiatLimit,
			crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
			fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
			fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
			crypto_personal_limit: settings?.data?.data?.data?.crypto_personal_limit,
			crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
		});
	};

	const handleChange = (name) => {
		if (name === 'fiat_personal') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: fiatAmountLimit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'crypto_personal') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit: cryptoAmountLimit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'fiat_business') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: fiatAmountLimit,
				crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
			});
		}
		if (name === 'crypto_business') {
			updateWalletLimitMutation.mutate({
				fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
				crypto_account_limit: settings?.data?.data?.data?.crypto_account_limit,
				fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
				crypto_personal_limit:
					settings?.data?.data?.data?.crypto_personal_limit,
				fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
				crypto_business_limit: cryptoAmountLimit
			});
		}
	};

	const handleCryptoChange = () => {
		updateWalletLimitMutation.mutate({
			fiat_account_limit: settings?.data?.data?.data?.fiat_account_limit,
			crypto_account_limit: cryptoLimit,
			fiat_personal_limit: settings?.data?.data?.data?.fiat_personal_limit,
			fiat_business_limit: settings?.data?.data?.data?.fiat_business_limit,
			crypto_personal_limit: settings?.data?.data?.data?.crypto_personal_limit,
			crypto_business_limit: settings?.data?.data?.data?.crypto_business_limit
		});
	};
	if (settings.isFetching) {
		return <Loader />;
	}

	if (settings.isError) {
		return <span>Error: {settings.error.message}</span>;
	}
	return (
		<Styles>
			<div className='notification-block'>
				<div className='info'>
					<h3>Dynamic Accounts timer</h3>
					<p>Set the limit for dynamic accounts in minutes</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							defaultValue={
								allSettings?.data?.data?.data?.dynamic_account_expire_min
							}
							style={{
								height: '50px !important'
							}}
							type='number'
							onChange={(e) => setExpireAt(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleDAChange}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Fiat Account Limit</h3>
					<p>Set limit for amount of fiat account created</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={3}
							defaultValue={settings?.data?.data?.data?.fiat_account_limit}
							type='number'
							onChange={(e) => setFiatLimit(e)}
							// name='fiat'
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleFiatChange}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Crypto Account Limit</h3>
					<p>Set limit for amount of crypto account created</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={3}
							defaultValue={settings?.data?.data?.data?.crypto_account_limit}
							type='number'
							onChange={(e) => setCryptoLimit(e)}
							// name='fiat'
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={handleCryptoChange}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Fiat Personal Limit</h3>
					<p>Set limit for amount in a personal fiat account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.fiat_personal_limit}
							type='number'
							onChange={(e) => setFiatAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('fiat_personal')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Crypto Personal Limit</h3>
					<p>Set limit for amount in a personal crypto account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.crypto_personal_limit}
							type='number'
							onChange={(e) => setCryptoAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('crypto_personal')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Fiat Business Limit</h3>
					<p>Set limit for amount in a business fiat account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.fiat_business_limit}
							type='number'
							onChange={(e) => setFiatAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('fiat_business')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
			<div className='notification'>
				<div className='info'>
					<h3>Crypto Business Limit</h3>
					<p>Set limit for amount in a business crypto account</p>
				</div>
				<div>
					<Input.Group
						compact
						style={{
							width: '100%'
						}}
					>
						<InputNumber
							min={0}
							defaultValue={settings?.data?.data?.data?.crypto_business_limit}
							type='number'
							onChange={(e) => setCryptoAmountLimit(e)}
						/>
						<Button
							Styles
							text='Save'
							color={'#ffffff'}
							bgColor='#1A2CCE'
							size='md'
							type='button'
							onClick={() => handleChange('crypto_business')}
							style={{
								height: '49px'
							}}
						/>
					</Input.Group>
				</div>
			</div>
		</Styles>
	);
};

export default Wallet;
