import { Button, Form, Input } from 'antd';
import { useRef, useState } from 'react';
import { Flex } from '../../../../components/box';
import { getMerchantSearch } from '../../../../services/Others.service';
import DebounceSelect from '../../fees/cross-fees/DebounceSelect';
import { FormStyles } from './styles';
const FormLayout = ({ handleSubmit, setImgUpload, setMerchantId, iRefVal }) => {
	const ref = useRef(null);
	const [value, setValue] = useState([]);
	const [refValue, setRefValue] = useState('');

	const handleOpenFileUpload = (e) => {
		e.preventDefault();
		ref.current.click();
	};

	const handleChange = (e) => {
		if (e.target.files) {
			const file = e.target.files[0];
			setImgUpload(file);
		}
	};
	const generateRef = () => {
		const result = [...Array(2)]
			.map((value) => (Math.random() * 1000).toString(36).replace('.', ''))
			.join('');
		iRefVal(result);
		setRefValue(result);
	};
	const fetchMerchantsList = (username) => {
		console.log('fetching user', username);
		if (username !== undefined || null || '') {
			return getMerchantSearch(username);
		} else {
			return [];
		}
	};
	const handleOnChange = (e) => {
		let { value } = e.target;
		// handleFormData()
		// console.log(value);
		iRefVal(value);
		setRefValue(value);
	};

	return (
		<FormStyles>
			<Form
				layout={'vertical'}
				onFinish={handleSubmit}
				size='large'
				// initialValues={{
				// 	layout: 'vertical',
				// 	size: 'large'
				// }}
				id='xchange'
			>
				<Form.Item label='Merchant Name' name='name'>
					<DebounceSelect
						// mode='multiple'
						value={value}
						placeholder='Search merchant'
						fetchOptions={fetchMerchantsList}
						onChange={(newValue) => {
							setValue(newValue?.label);
							setMerchantId(newValue?.value);
						}}
						style={{
							width: '100%'
						}}
					/>
				</Form.Item>
				<Form.Item label='Logo' name='logo'>
					<Input type='file' name='logo' ref={ref} onChange={handleChange} />
				</Form.Item>
				<Form.Item label='Reference' name='reference'>
					<Flex
						style={{
							border: '1px solid #D7D7D7',
							borderRadius: '5px'
						}}
					>
						<Input
							style={{
								width: 'calc(100% - 98px)'
							}}
							placeholder='Enter or generate reference no.'
							bordered={false}
							value={refValue}
							onChange={handleOnChange}
						/>

						<Button
							style={{
								width: '98px',
								height: '46px',
								background: '#F1F6FE',
								borderRadius: '0px 5px 5px 0px',
								border: 'none',
								color: '#1A2CCE'
							}}
							onClick={generateRef}
						>
							Generate
						</Button>
					</Flex>
					{/* <Input
						placeholder='Enter or generate reference no.'
						value={refValue}
					/> */}
				</Form.Item>
			</Form>
		</FormStyles>
	);
};

export { FormLayout };
